export enum StateOfferShipOwner{
  NONE,
  WAIT_RESPONSE,
  REFUSED,
  RECAP_CHARTERER_PENDING,
  RECAP_SHIPOWNER_PENDING,
  RECAP_CONFIRMED,
  RECAP,
  BIDED, //armador envia indicacion
  AGREEMENT_STARTED, //cargador crea oferta firme
  AGREEMENT_PREVALIDATED, //armador contraoferta clausulas textarea
  AGREEMENT_VALIDATED, // clh add clauses armador
  CHARTERER_COUNTER_OFFER,
  SHIPOWNER_COUNTER_OFFER,
  CANCELLED,
  COMPLETED_CHARTERER_PENDING,
  COMPLETED_SHIPOWNER_PENDING,
  COMPLETED,
}