<p-confirmDialog
  styleClass="containerConfirmDialog"
  acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"
></p-confirmDialog>
<div class="containerTabviewCharger" *ngIf="loadedPorts">
  <ngx-spinner [fullScreen]="false" name="routeSpinner"></ngx-spinner>
  <div class="m-auto">
    <div class="table-responsive" *ngIf="loadedPorts">
      <p-table
        [value]="selectedRoutes"
        styleClass="p-datatable-striped listTableRoutes tables-maintenance"
        responsiveLayout="scroll"
        [columns]="columnsSchemaRoute"
        [responsive]="true"
        [autoLayout]="true"
        #tableRoutes
        [globalFilterFields]="['originPortsValues','destinationPortsValues']"
        [paginator]="true"
        paginatorDropdownAppendTo="body"
        [rowsPerPageOptions]="[10,30,50]"
        [showCurrentPageReport]="true"
        [rows]="10"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
      >
        <ng-template pTemplate="caption">
          <div class="divSearch">
            <div class="d-flex flex-row">
              <div class="w-100">
                <span class="p-input-icon-right" style="width: 100%">
                  <i class="pi pi-search" style="top: 35%"></i>
                  <input
                    style="margin-right: 1em"
                    class="w-100"
                    pInputText
                    type="text"
                    (input)="tableRoutes.filterGlobal($any($event.target).value,'contains')"
                  />
                </span>
              </div>
              <div style="margin-left: 3em" *ngIf="!readonly">
                <button
                  pButton
                  pRipple
                  type="button"
                  icon="pi pi-plus"
                  class="p-button-rounded addButtonRounded"
                  [pTooltip]="'ACTION.ADD'| translate"
                  tooltipPosition="bottom"
                  tooltipStyleClass="tooltipCLH"
                  (click)="addNewRoute()"
                  *ngIf="!isEditting && !isNewRoute"
                ></button>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns"><b>{{col.label | translate}}</b></th>
            <th *ngIf="!readonly"><b>{{'ROUTES.FORM.ACTIONS' | translate}}</b></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-route>
          <ng-container *ngIf="route.isNew else other">
            <tr class="campo" *ngIf="isNewRoute">
              <ng-container [formGroup]="form">
                <td>
                  <div class="p-3 col-12">
                    <overa-autocomplete
                    [formGroup]="form"
                    [controlName]="'loadingPorts'"
                    [options]="ports"
                    [selectedItems]="selectedLoadingPorts"
                    [filterStartIndex]="3"
                    [multiple]="true"
                    [validators]="[arrValidator]"
                    [required]="true"
                    [readonly]="false"

                  ></overa-autocomplete>
                    <!-- <overa-autocomplete
                      [form]="form"
                      *ngIf="ports"
                      [readonly]="false"
                      [name]="'loadingPorts'"
                      style="width: 100%"
                      [required]="true"
                      [items]="ports"
                      [multiple]="true"
                      [placeholder]="'ROUTES.FORM.LOADING_PORTS' | translate"
                      [validators]="[arrValidator]"
                      [selectedItems]="selectedLoadingPorts"
                      [filterStartIndex]="3"
                    >
                    </overa-autocomplete> -->
                    <div *ngIf="form.get('loadingPorts').touched  && form.get('loadingPorts')?.errors" class="error-label labelError">
                      {{ form.get('loadingPorts')?.errors?.required ? ('MESSAGES.VALIDATION.REQUIRED_FIELD' | translate) :
                      ('MESSAGES.VALIDATION.PORTS_INVALID' | translate)}}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="p-3 col-12">
                    <overa-autocomplete
                    [formGroup]="form"
                    [controlName]="'dischargingPorts'"
                    [options]="portsD"
                    [selectedItems]="selectedDischargingPorts"
                    [filterStartIndex]="3"
                    [multiple]="true"
                    [validators]="[arrValidator]"
                    [required]="true"
                    [readonly]="false"

                  ></overa-autocomplete>
                    <!-- <overa-autocomplete
                      [form]="form"
                      *ngIf="portsD"
                      [readonly]="false"
                      [name]="'dischargingPorts'"
                      style="width: 100%"
                      [required]="true"
                      [items]="portsD"
                      [multiple]="true"
                      [placeholder]="'ROUTES.FORM.DISCHARGING_PORTS' | translate"
                      [validators]="[arrValidator]"
                      [selectedItems]="selectedDischargingPorts"
                      [filterStartIndex]="3"
                    >
                    </overa-autocomplete> -->
                    <div *ngIf="form.get('dischargingPorts').touched  && form.get('dischargingPorts')?.errors" class="error-label labelError">
                      {{ form.get('dischargingPorts')?.errors?.required ? ('MESSAGES.VALIDATION.REQUIRED_FIELD' | translate) :
                      ('MESSAGES.VALIDATION.PORTS_INVALID' | translate)}}
                    </div>
                  </div>
                </td>
                <td class="td-edit" *ngIf="!readonly">
                  <div class="d-flex flex-row justify-content-start align-items-center">
                    <button
                      pButton
                      pRipple
                      type="button"
                      icon="pi pi-save"
                      class="p-button-rounded saveButtonRounded"
                      [disabled]="!form.valid"
                      (click)="editRoute(route)"
                      [pTooltip]="'ACTION.SAVE'| translate"
                      tooltipPosition="bottom"
                      tooltipStyleClass="tooltipCLH"
                    ></button>
                    <button
                      pButton
                      pRipple
                      type="button"
                      icon="pi pi-times"
                      style="margin-left: 15%"
                      [pTooltip]="'ACTION.CANCEL'| translate"
                      tooltipPosition="bottom"
                      tooltipStyleClass="tooltipCLH"
                      class="p-button-rounded cancelButtonRounded"
                      (click)="cancelEdit(route)"
                    ></button>
                  </div>
                </td>
              </ng-container>
            </tr>
          </ng-container>
          <ng-template #other>
            <tr class="campo">
              <ng-container *ngIf="!route.editable">
                <td>{{route.originPortsValues.join(',')}}</td>
                <td>{{route.destinationPortsValues.join(',')}}</td>
                <td class="td-edit" *ngIf="!readonly">
                  <ng-container *ngIf="!isNewRoute && !isEditting">
                    <button
                      pButton
                      pRipple
                      type="button"
                      icon="pi pi-pencil"
                      class="p-button-rounded editButtonRounded"
                      [pTooltip]="'ACTION.EDIT'| translate"
                      tooltipPosition="bottom"
                      tooltipStyleClass="tooltipCLH"
                      (click)="setEditable(route)"
                      [disabled]="isNewRoute"
                    ></button>
                    <button
                      pButton
                      pRipple
                      type="button"
                      icon="pi pi-trash"
                      style="margin-left: 15%"
                      [pTooltip]="'ACTION.DELETE'| translate"
                      tooltipPosition="bottom"
                      tooltipStyleClass="tooltipCLH"
                      class="p-button-rounded deleteButtonRounded"
                      (click)="deleteRoute(route)"
                      [disabled]="isNewRoute"
                    ></button>
                  </ng-container>
                </td>
              </ng-container>
              <ng-container *ngIf="route.editable" [formGroup]="formTable">
                <td>
                  <div class="p-3 col-12">
                    <overa-autocomplete
                      [form]="formTable"
                      *ngIf="ports"
                      [readonly]="false"
                      [name]="'loadingPorts'"
                      style="width: 100%"
                      [required]="true"
                      [items]="ports"
                      [multiple]="true"
                      [placeholder]="'ROUTES.FORM.LOADING_PORTS' | translate"
                      [validators]="[arrValidator]"
                      [selectedItems]="selectedLoadingPortsEditting"
                      [filterStartIndex]="3"
                    >
                    </overa-autocomplete>
                    <div *ngIf="formTable.get('loadingPorts').touched  && formTable.get('loadingPorts')?.errors" class="error-label labelError">
                      {{ formTable.get('loadingPorts')?.errors?.required ? ('MESSAGES.VALIDATION.REQUIRED_FIELD' | translate) :
                      ('MESSAGES.VALIDATION.PORTS_INVALID' | translate)}}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="p-3 col-12">
                    <overa-autocomplete
                      [form]="formTable"
                      *ngIf="portsD"
                      [readonly]="false"
                      [name]="'dischargingPorts'"
                      style="width: 100%"
                      [required]="true"
                      [items]="portsD"
                      [multiple]="true"
                      [placeholder]="'ROUTES.FORM.DISCHARGING_PORTS' | translate"
                      [validators]="[arrValidator]"
                      [selectedItems]="selectedDischargingPortsEditting"
                      [filterStartIndex]="3"
                    >
                    </overa-autocomplete>
                    <div
                      *ngIf="formTable.get('dischargingPorts').touched  && formTable.get('dischargingPorts')?.errors"
                      class="error-label labelError"
                    >
                      {{formTable.get('dischargingPorts')?.errors?.required ? ('MESSAGES.VALIDATION.REQUIRED_FIELD' | translate) :
                      ('MESSAGES.VALIDATION.PORTS_INVALID' | translate)}}
                    </div>
                  </div>
                </td>
                <td class="td-edit" *ngIf="!isNewRoute && isEditting">
                  <div class="d-flex flex-row justify-content-start align-items-center">
                    <button
                      pButton
                      pRipple
                      type="button"
                      icon="pi pi-save"
                      class="p-button-rounded saveButtonRounded"
                      [disabled]="!formTable.valid"
                      (click)="editRoute(route)"
                      [pTooltip]="'ACTION.SAVE'| translate"
                      tooltipPosition="bottom"
                      tooltipStyleClass="tooltipCLH"
                    ></button>
                    <button
                      pButton
                      pRipple
                      type="button"
                      icon="pi pi-times"
                      style="margin-left: 15%"
                      [pTooltip]="'ACTION.CANCEL'| translate"
                      tooltipPosition="bottom"
                      tooltipStyleClass="tooltipCLH"
                      class="p-button-rounded cancelButtonRounded"
                      (click)="cancelEdit(route)"
                    ></button>
                  </div>
                </td>
              </ng-container>
            </tr>
          </ng-template>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
