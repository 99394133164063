import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, ReplaySubject } from "rxjs";
import { ConfigsService } from "@overa/shared";
import { LanguageCode } from "../models/enum/language-code.model";
import { Country } from "../models/country/country.model";
import { CLHBaseService } from "./clh-base.service";

@Injectable({ providedIn: "root" })
export class CountryService extends CLHBaseService {
  private shipownerSubject: ReplaySubject<boolean> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public shipownerObservable: Observable<boolean> =
    this.shipownerSubject.asObservable();

  private routeShipownerSubject: ReplaySubject<string> = new ReplaySubject(1);
  public routeShipownerObservable: Observable<string> =
    this.routeShipownerSubject.asObservable();

  getCountriesByCode(code: LanguageCode) {
    return this.http.get<Country[]>(
      `${this.baseUrl}/country/getByCode/${code}`
    );
  }
  getCountriesES(): Observable<Country[]> {
    let code = LanguageCode.ES;
    return this.http
      .get<Country[]>(`${this.baseUrl}/country/getByCode/${code}`)
      .pipe(
        map((res: Country[]) => {
          //db.countriesES.clear();
          //res.forEach(country => {
          //    country={id:country.id,name:country.name};
          //    db.countriesES.put(country)});
          // set local storage validation for one day
          // localStorage.setItem('cacheExpirationDate', (new Date().getTime() + 86400000).toString());
          return res;
        })
      );
  }
  getCountriesEN(): Observable<Country[]> {
    let code = LanguageCode.EN;
    return this.http
      .get<Country[]>(`${this.baseUrl}/country/getByCode/${code}`)
      .pipe(
        map((res: Country[]) => {
          //db.countriesEN.clear();
          //res.forEach(country =>{
          //    country={id:country.id,name:country.name};
          //    db.countriesEN.put(country)});
          // set local storage validation for one day
          // localStorage.setItem('cacheExpirationDate', (new Date().getTime() + 86400000).toString());
          return res;
        })
      );
  }
  async getCachedCountriesES() {
    //var countriesES =  db.countriesES.toArray();
    //return countriesES;
  }
  async getCachedCountriesEN() {
    //var countriesEN =  db.countriesEN.toArray();
    //return countriesEN;
  }
}
