import { Injectable } from "@angular/core";
import {
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmationService } from "primeng/api";
import { Observable } from "rxjs/internal/Observable";

export interface ComponentCanDeactivate {
  checkCanDeactive(
    targetUrl?: string
  ): boolean | Observable<boolean> | Promise<boolean>;
}

@Injectable({
  providedIn: "root",
})
export class CanDeactivateGuard
  implements CanDeactivate<ComponentCanDeactivate>
{
  constructor(private confirmationService: ConfirmationService) {}

  canDeactivate(
    component: ComponentCanDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    const targetUrl = nextState ? nextState.url : "";
    console.log("NEXT URL: ", targetUrl);
    if (targetUrl.includes("access-denied")) return true;
    if (component) {
      return component.checkCanDeactive(targetUrl);
    } else {
      return true;
    }
  }
}
