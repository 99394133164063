import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AccountService } from "@overa/security";
import { NgxTranslateService } from "@overa/shared";
import { MenuItem, MessageService } from "primeng/api";
import { forkJoin, of, switchMap } from "rxjs";
import { ChatGroup } from "src/app/models/chat/chatGroup.model";
import { ChatMessage } from "src/app/models/chat/chatMessage.model";
import { ChatParticipant } from "src/app/models/chat/chatParticipant.model";
import { OfferShipOwner } from "src/app/models/offers/offer-shipowner.model";
import { ChatHelper } from "src/app/utils/chat-helper.utils";
import { CLHGlobals } from "src/app/utils/globals";
@Component({
  selector: "app-steps-offer-request",
  templateUrl: "./steps-offer-request.component.html",
  styleUrls: ["./steps-offer-request.component.scss"],
  providers: [MessageService],
})
export class StepsOfferRequestComponent implements OnInit {
  items: MenuItem[];

  activeIndex: number = 0;
  chatValues: Array<{
    offerShipOwnerId: string;
    currentParticipant: ChatParticipant;
    group: ChatGroup;
    windowName: string;
    historicMessages: ChatMessage[];
  }> = [];
  isClh: boolean = false;
  idOffer: any;
  // subscription: Subscription;
  constructor(
    public messageService: MessageService,
    private translate: NgxTranslateService,
    public router: Router
  ) {}

  ngOnInit() {
    let translation = this.translate.get("VIEWS.STEPS_OFFER_REQUEST");
    let label0 = translation.LABEL_0;
    let label1 = translation.LABEL_1;
    let label2 = translation.LABEL_2;

    this.items = [
      {
        label: label0,
        routerLink: "cover",
        command: (event: any) => {
          this.activeIndex = 0;
          this.messageService.add({
            severity: "info",
            summary: "First Step",
            detail: event.item.label,
          });
        },
      },
      {
        label: label1,
        routerLink: "shipownersSelector",
        command: (event: any) => {
          this.activeIndex = 1;
          this.messageService.add({
            severity: "info",
            summary: "Second Step",
            detail: event.item.label,
          });
        },
        // routerLink:''
      },
      {
        label: label2,
        routerLink: "offerFinalView",
        command: (event: any) => {
          this.activeIndex = 2;
          //this.messageService.add({ severity: 'info', summary: 'Second Step', detail: event.item.label });
        },
        // routerLink:''
      },
    ];
  }
  // ngOnDestroy() {
  //   // if (this.subscription) {
  //   //   this.subscription.unsubscribe();
  //   // }
  // }
}
