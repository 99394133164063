import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AccountService } from "@overa/security";
import { OveraUtils } from "@overa/shared";
import { map, Observable, of } from "rxjs";
import { OfferService } from "../services/offer.service";
import { CLHGlobals } from "./globals";

@Injectable({ providedIn: "root" })
export class PermissionsShipownerGuard implements CanActivate, CanActivate {
  constructor(
    private router: Router,
    private offerService: OfferService,
    private utils: OveraUtils,
    private globals: CLHGlobals
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    var url = route.url && route.url.length ? route.url[0].toString() : "";
    var fullUrl = route.pathFromRoot
      .map((v) => v.url.map((segment) => segment.toString()).join("/"))
      .join("/")
      .split("?")[0];
    console.log(fullUrl);
    var lastSegment = fullUrl.split("/")[fullUrl.split("/").length - 1];
    if (this.utils.isGuid(lastSegment)) {
      url = fullUrl.split("/").pop() || "";
    }
    console.log("canActivate");
    if (this.globals.isClh) {
      return of(true);
    } else {
      if (this.globals.currentShipOwner) {
        if (this.globals.currentShipOwner?.contacts?.length) {
          var contact = this.globals.currentShipOwner.contacts[0];

          var lastSegment = fullUrl.split("/")[fullUrl.split("/").length - 1];
          if (this.utils.isGuid(lastSegment)) {
            var offerShipownerId = lastSegment;
            return this.offerService
              .checkAccessByShipownerContact(offerShipownerId, contact.id || "")
              .pipe(
                map((r) => {
                  if (r) {
                    return true;
                  } else {
                    this.router.navigateByUrl("security/access-denied");
                    return false;
                  }
                })
              );
          } else {
            console.error("not id: " + lastSegment);
            return of(false);
          }
        } else {
          return of(false);
        }
      } else {
        return of(true);
      }
    }
  }
}
