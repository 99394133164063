import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';


@Component({
  selector: 'app-new-charger-list-dialog',
  templateUrl: './new-list-dialog.component.html',
  styleUrls: ['./new-list-dialog.component.scss'],
  providers: [DialogService]
})
export class NewListDialogComponent implements OnInit {
  listName: string = '';

  constructor(
    private ref: DynamicDialogRef,
  ) { }
  ngOnInit(): void {

  }

  addList() {
    this.close(this.listName);
  }

  close(listName?: string) {

    this.ref.close({ name: listName });
  }

}
