<ngx-spinner></ngx-spinner>
<div class="container-fluid">
  <div class="card cardChargerList">
    <div class="card-header">
      <div class="card-text">
        <div class="row">
          <div class="col align-self-start">
            <h4 class="card-tittle tittleListCharger">{{'CHARTERER_LIST.TITLE' | translate}}</h4>
          </div>
          <div class="col">
            <div class="d-flex justify-content-end">
              <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded addButtonRounded"
                *ngIf="showButton" (click)="isNew('new')" [pTooltip]="'ACTION.ADD'| translate" tooltipPosition="bottom"
                tooltipStyleClass="tooltipCLH"></button>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
    <div class="card-body cardBodyChargerList">
      <p-table *ngIf="showTable" [columns]="columns" [value]="chargers"
        styleClass="p-datatable-striped listTableCharger"
        [globalFilterFields]="['refCharger','name','cif','dischargeDate']" [responsive]="true" responsiveLayout="stack"
        breakpoint="960px" [autoLayout]="true" [paginator]="true" [rows]="10"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
        [rowsPerPageOptions]="[10,30,50]" [showCurrentPageReport]="true" paginatorDropdownAppendTo="body" sortField="Id"
        [sortOrder]="-1">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <!--pSortableColumn="{{col.sort}}"  -->
            <th overaellipsis *ngFor="let col of columns" [ngClass]="'th-' +col.sort" class="line-border-right">{{col.id
              |
              translate}}</th>
          </tr>
          <tr>
            <th *ngFor="let col of columns" class="filterTh table-filters">
              <p-columnFilter *ngIf="col.sort === 'dischargeDate' " type="date" field="{{col.sort}}"
                matchMode="contains">
                <ng-template pTemplate="filter" let-filter="filterCallback">
                  <p-calendar (onSelect)="filter($event)" dateFormat="dd/mm/yy" appendTo="body"></p-calendar>
                </ng-template>
              </p-columnFilter>
              <p-columnFilter *ngIf="col.sort != 'dischargeDate'" type="text" field="{{col.sort}}"
                class="colFiltercharger"></p-columnFilter>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-charger>
          <tr class="campo" (dblclick)="selectCharger(charger)">
            <ng-container *ngFor="let col of columns">
              <td overaellipsis *ngIf="col.sort != 'dischargeDate'" class="{{col.sort}}">
                <span class="p-column-title">{{col.id | translate}}</span>{{charger[col.sort]}}
              </td>
              <td *ngIf="col.sort == 'dischargeDate'">
                <span class="p-column-title">{{col.id | translate}}</span>{{charger[col.sort] | date: 'dd/MM/yy'}}
              </td>
            </ng-container>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>