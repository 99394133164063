import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ChargerService } from "../../services/charger.service";
import { ChooseUserService } from "../../services/choose-user.service";
import { ShipownerService } from "../../services/shipowner.service";
import { CLHGlobals } from "src/app/utils/globals";

@Component({
  selector: "app-choose-user",
  templateUrl: "./choose-user.component.html",
  styleUrls: ["./choose-user.component.css"],
})
export class ChooseUserComponent implements OnInit {
  entityName: string = "";
  isShipowner = false;
  constructor(
    protected activatedRoute: ActivatedRoute,
    protected chargerService: ChargerService,
    protected shipownerService: ShipownerService,
    protected router: Router,
    protected chooseUserService: ChooseUserService,
    protected globals: CLHGlobals
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next: (params) => {
        this.entityName = params["entityName"];
        if (this.entityName == "shipowner") {
          this.isShipowner = true;
        } else if (this.entityName == "null") {
          if (
            this.globals.user.roles
              .map((r: any) => r.name.toLowerCase())
              .includes(this.globals.clhRoleName.toLowerCase()) ||
            this.globals.user.isGlobalAdmin
          ) {
            //this.globals.currentCharger = null;
            //this.globals.currentShipOwner = null;

            this.chooseUserService.setNull();
            this.redirectToHome();
          }
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
  selectCharger(event: any) {
    console.log(event);

    //var user = event.contacts.find(x => x.userId);
    //var userId = user.userId;
    this.chargerService.getById(event.id).subscribe({
      next: (charger) => {
        //localStorage.setItem('currentEntity', JSON.stringify(charger));
        //this.globals.currentCharger = charger;
        //this.globals.currentShipOwner = null;
        this.chooseUserService.setCharger(charger);

        this.redirectToHome();
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
  selectShipowner(event: any) {
    //var user = event.contacts.find(x => x.userId);
    //var userId = user.userId;
    this.shipownerService.getById(event.id).subscribe({
      next: (shipowner) => {
        //this.globals.currentShipOwner = shipowner;
        //this.globals.currentCharger = null;
        this.chooseUserService.setShipOwner(shipowner);

        this.redirectToHome();
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  redirectToHome() {
    this.router.navigate(["home"], { queryParams: { hideMenu: false } });
  }
}
