import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { OveraMessagesService } from "@overa/components/info-dialog";
import { NgxTranslateService } from "@overa/shared";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmationService, FilterMatchMode } from "primeng/api";
import { forkJoin } from "rxjs";
import { GenericConditions } from "src/app/models/clause/generic-conditions.model";
import { GenericConditionsService } from "src/app/services/generic-conditions.service";
import { PermissionsService } from "src/app/services/permissions.service";
import { CLHGlobals } from "src/app/utils/globals";

@Component({
  selector: "app-generic-conditions",
  templateUrl: "./generic-conditions-view.component.html",
  styleUrls: ["./generic-conditions-view.component.scss"],
  providers: [ConfirmationService],
})
export class GenericConditionsViewComponent implements OnInit {
  @Input()
  type: string;
  columns: any[];
  terms: GenericConditions[] = [];
  termsInitial: GenericConditions[];
  itemsTemp: GenericConditions[] = [];
  showTable: boolean = false;
  messagesService = inject(OveraMessagesService);
  @Output() onSelectTerms: EventEmitter<any> = new EventEmitter();

  readonly: boolean = false;
  isEditting: boolean = false;
  isNewTerm: boolean = false;
  isEmptyNewTerm: boolean = false;
  newTerm: GenericConditions;
  form: FormGroup;
  filterDate: Date;
  matchMode: string;
  storageId: string = "";
  globalFilterFields = [];

  constructor(
    private translate: NgxTranslateService,
    public router: Router,
    private globals: CLHGlobals,
    private termsService: GenericConditionsService,
    private cd: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private permissionsService: PermissionsService,
    private confirmationService: ConfirmationService
  ) {
    this.globalFilterFields =
      this.type == "terms"
        ? ["validityDate", "terms", "termsEN"]
        : ["validityDate", "policy", "policyEN"];
  }

  ngOnInit() {
    this.storageId = this.globals.storageId;
    this.showTable = false;
    this.initialForm();
    this.readonly = !this.permissionsService.hasPermissionToEdit(this.route);
    this.columns = [
      { id: "TERMS_CONDITIONS.TABLE.HEAD_COL1", sort: "validityDate" },
      { id: "TERMS_CONDITIONS.TABLE.HEAD_COL1_B", sort: "sendDate" },
      this.type == "terms"
        ? { id: "TERMS_CONDITIONS.TABLE.HEAD_COL2", sort: "terms" }
        : { id: "PRIVACY_POLICIES.TABLE.HEAD_COL2", sort: "policy" },
      this.type == "terms"
        ? { id: "TERMS_CONDITIONS.TABLE.HEAD_COL3", sort: "termsEN" }
        : { id: "PRIVACY_POLICIES.TABLE.HEAD_COL3", sort: "policyEN" },

      { id: "COMMONS.FILE_ES", sort: "docEs" },
      { id: "COMMONS.FILE_EN", sort: "docEn" },
    ];
    if (!this.readonly) {
      this.columns.push({
        id: "TERMS_CONDITIONS.TABLE.HEAD_COL4",
        sort: "modify",
      });
    }
    this.loadList();
  }

  initialForm() {
    this.form = new FormGroup({
      newDatePicker: new FormControl(new Date(), [Validators.required]),
      newTermTextarea: new FormControl("", [Validators.required]),
      newTermENTextarea: new FormControl("", [Validators.required]),
      newDocES: new FormControl("", [Validators.required]),
      newDocEN: new FormControl("", [Validators.required]),
    });
  }
  sendTerm(term: GenericConditions) {
    const askMessage =
      this.type == "terms"
        ? "TERMS_MAINTENANCE.ASK_SEND"
        : "PRIVACY_POLICIES_MAINTENANCE.ASK_SEND";
    const keysToTranslate = [askMessage, "ACTION.YES"];
    const translated = keysToTranslate.map((key) => this.translate.get(key));
    const trmessage = translated[0];
    const messageAccept = translated[1];

    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.spinner.show("termsSpinner");
        this.termsService.send(term, this.type).subscribe({
          next: (value) => {
            console.log("sended term ", value);
            this.spinner.hide("termsSpinner");
            this.loadList();
            window.scrollTo({ top: 0 });
          },
          error: (err) => {
            console.log("error sending term", err);
            this.spinner.hide("termsSpinner");
          },
        });
        //TODO
        //IT IS PENDING TO INDIVIDUALIZE THE DELETION UNTIL IT IS READY BACK
        // this.sendListClauses.emit(this.listClauses);
      },
      reject: () => {
        console.log("reject");
      },
    });
  }
  getDocumentGuid(controlName: string): string {
    const control = this.form.get(controlName);
    if (control && control.value) {
      return JSON.parse(this.form.get(controlName).value).guid;
    }
    return "";
  }
  saveTerm(term?: GenericConditions) {
    console.log(this.isNewTerm, term);
    this.spinner.show("termsSpinner");
    let subscription;
    let termTmp = term;

    if (this.isNewTerm) {
      this.isNewTerm = false;
      const addTerm = { ...this.newTerm };
      delete addTerm.sendDate;

      addTerm.validityDate = this.form.get("newDatePicker").value;
      if (this.type == "terms") {
        addTerm["terms"] = this.form.get("newTermTextarea").value;
        addTerm["termsEN"] = this.form.get("newTermENTextarea").value;
        addTerm["docTermsEN"] = this.getDocumentGuid("newDocEN");
        addTerm["docTermsES"] = this.getDocumentGuid("newDocES");
      } else {
        addTerm["policy"] = this.form.get("newTermTextarea").value;
        addTerm["policyEN"] = this.form.get("newTermENTextarea").value;

        addTerm["docPolicyEN"] = this.getDocumentGuid("newDocEN");
        addTerm["docPolicyES"] = this.getDocumentGuid("newDocES");
      }

      const indice = this.terms.indexOf(this.newTerm);
      this.terms.splice(indice, 1);
      this.newTerm = null;
      console.log("ADDTERM", addTerm);
      termTmp = addTerm;

      subscription = this.termsService.add(addTerm, this.type);
    }
    const namePropertyFiles =
      this.type == "terms" ? "docTermsES" : "docPolicyES";
    if (this.isEditting) {
      this.isEditting = false;
      term.isEdit = false;
      const controlDate = term.id + "Datepicker";

      term.validityDate = new Date(this.form.get(controlDate).value);
      if (this.type == "terms") {
        term["terms"] = this.form.get(term.nameControl).value;
        term["termsEN"] = this.form.get(term.nameControlEN).value;
        term["docTermsEN"] = this.getDocumentGuid(term.nameControlDocEn);
        term["docTermsES"] = this.getDocumentGuid(term.nameControlDocEs);
      } else {
        term["policy"] = this.form.get(term.nameControl).value;
        term["policyEN"] = this.form.get(term.nameControlEN).value;
        term["docPolicyEN"] = this.getDocumentGuid(term.nameControlDocEn);
        term["docPolicyES"] = this.getDocumentGuid(term.nameControlDocEs);
      }

      console.log("TERM", term);
      subscription = this.termsService.edit(term, this.type);
    }

    subscription.subscribe({
      next: (value: GenericConditions) => {
        console.log(value);
        value.nameControl = value.id + "Textarea";
        value.nameControlEN = value.id + "ENTextarea";
        value.nameControlDate = value.id + "Datepicker";
        value.nameControlDocEs = value.id + "docEs";
        value.nameControlDocEn = value.id + "docEn";
        value.isEdit = false;
        const valueValueES =
          this.type == "terms" ? value["docTermsES"] : value["docPolicyES"];
        const valueValueEN =
          this.type == "terms" ? value["docTermsEN"] : value["docPolicyEN"];
        const fileEN = valueValueEN;
        const fileES = valueValueES;
        value.fileDataEN = fileEN;
        value.fileDataES = fileES;
        this.verifyExistenceControl(value.nameControlDate, value.validityDate, [
          Validators.required,
        ]);
        this.verifyExistenceControl(
          value.nameControl,
          this.type == "terms" ? value["terms"] : value["policy"],
          [Validators.required]
        );
        this.verifyExistenceControl(
          value.nameControlEN,
          this.type == "terms" ? value["termsEN"] : value["policyEN"],
          [Validators.required]
        );
        this.verifyExistenceControl(value.nameControlDocEn, fileEN, [
          Validators.required,
        ]);
        this.verifyExistenceControl(value.nameControlDocEs, fileES, [
          Validators.required,
        ]);
        const arrNameControls = [value.nameControl, value.nameControlEN];
        this.resetFormControlsState(arrNameControls);
        const index = this.terms.findIndex((x) => x.id === value.id);
        if (index >= 0) {
          this.terms[index] = value;
        } else {
          this.terms.push(value);
        }
        this.terms = [...this.terms];
        this.cd.detectChanges();
        this.spinner.hide("termsSpinner");
      },
      error: (err) => {
        console.log("termsSpinner", err);
        this.spinner.hide("termsSpinner");
      },
    });
  }

  editTerm(term: GenericConditions) {
    this.isEditting = true;
    term.isEdit = true;
    term.valueInitial = this.type == "terms" ? term["terms"] : term["policy"];
    term.valueInitialEN =
      this.type == "terms" ? term["termsEN"] : term["policyEN"];
    if (this.type == "terms") {
      term.valueInitialDocEn = term["docTermsEN"];
      term.valueInitialDocEs = term["docTermsES"];
    } else {
      term.valueInitialDocEn = term["docPolicyEN"];
      term.valueInitialDocEs = term["docPolicyES"];
    }
    this.terms = [...this.terms];
  }

  addRow() {
    this.setValueFormControl("newDatePicker", new Date());
    this.setValueFormControl("newTermTextarea", "");
    this.setValueFormControl("newTermENTextarea", "");
    this.setValueFormControl("newDocES", "");
    this.setValueFormControl("newDocEN", "");
    this.isNewTerm = true;
    this.newTerm = new GenericConditions();
    this.newTerm.isNew = true;
    this.newTerm.isEmpty = true;
    this.isEmptyNewTerm = false;
    this.newTerm.validityDate = new Date();
    this.newTerm.sendDate = new Date();
    if (this.type == "terms") {
      this.newTerm["terms"] = "";
      this.newTerm["termsEN"] = "";
      this.newTerm["docTermsES"] = "";
      this.newTerm["docTermsEN"] = "";
    } else {
      this.newTerm["policy"] = "";
      this.newTerm["policyEN"] = "";
      this.newTerm["docPolicyES"] = "";
      this.newTerm["docPolicyEN"] = "";
    }
    this.terms.unshift(this.newTerm);
    this.terms = [...this.terms];
  }

  selectTerm(term: GenericConditions) {
    console.log(term);
    this.onSelectTerms.emit(term);
  }
  isNew(string: string) {
    console.log("new");
    this.onSelectTerms.emit(string);
  }

  cancelEditTerm(term?: GenericConditions) {
    if (this.isNewTerm) {
      this.isNewTerm = false;
      const indice = this.terms.indexOf(this.newTerm);
      this.terms.splice(indice, 1);
      this.newTerm = null;
      this.resetStateForm();
    }
    if (this.isEditting) {
      this.isEditting = false;
      term.isEdit = false;
      if (this.type == "terms") {
        term["terms"] = term.valueInitial;
        term["termsEN"] = term.valueInitialEN;
        term["docTermsEN"] = term.valueInitialDocEn;
        term["docTermsES"] = term.valueInitialDocEs;
      } else {
        term["policy"] = term.valueInitial;
        term["policyEN"] = term.valueInitialEN;
        term["docPolicyEN"] = term.valueInitialDocEn;
        term["docPolicyES"] = term.valueInitialDocEs;
      }
      this.setValueFormControl(term.nameControlDate, term.validityDate);
      if (this.type == "terms") {
        this.setValueFormControl(term.nameControl, term["terms"]);
        this.setValueFormControl(term.nameControlEN, term["termsEN"]);
        this.setValueFormControl(term.nameControlDocEs, term["docTermsES"]);
        this.setValueFormControl(term.nameControlDocEn, term["docTermsEN"]);
      } else {
        this.setValueFormControl(term.nameControl, term["policy"]);
        this.setValueFormControl(term.nameControlEN, term["policyEN"]);
        this.setValueFormControl(term.nameControlDocEs, term["docPolicyES"]);
        this.setValueFormControl(term.nameControlDocEn, term["docPolicyEN"]);
      }
      const arrNameControls = [term.nameControl, term.nameControlEN];
      this.resetFormControlsState(arrNameControls);
      term.isEmpty = false;
    }
    this.terms = [...this.terms];
  }

  onValueChanged(value: any) {
    console.log(value);
    if (this.isNewTerm) {
      this.newTerm.validityDate = value.validityDate;
      if (this.type == "terms") {
        this.newTerm["termsEN"] = value.termsEN;
        this.newTerm["terms"] = value.terms;
      } else {
        this.newTerm["policyEN"] = value.policyEN;
        this.newTerm["policy"] = value.policy;
      }
    }
    if (this.isEditting) {
      console.log(value);
    }
  }

  deleteTerm(term: GenericConditions) {
    let messageAccept = "";
    let trmessage = "";
    const keysToTranslate = ["TERMS_MAINTENANCE.ASK_DELETE", "ACTION.YES"];
    const observables = keysToTranslate.map((key) => this.translate.get(key));
    forkJoin(observables).subscribe((translations: string[]) => {
      trmessage = translations[0];
      messageAccept = translations[1];
    });
    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.spinner.show("termsSpinner");
        this.termsService.delete(term.id, this.type).subscribe({
          next: () => {
            const indice = this.terms.indexOf(term);
            this.terms.splice(indice, 1);
            this.terms = [...this.terms];
            this.cd.detectChanges();
            this.spinner.hide("termsSpinner");
          },
          error: (err) => {
            console.log("errorDeleteTerm", err);
            this.spinner.hide("termsSpinner");
          },
        });
        //TODO
        //IT IS PENDING TO INDIVIDUALIZE THE DELETION UNTIL IT IS READY BACK
        // this.sendListClauses.emit(this.listClauses);
      },
      reject: () => {
        console.log("reject");
      },
    });
  }

  loadList() {
    this.spinner.show("termsSpinner");
    this.termsService.get(this.type).subscribe({
      next: (value) => {
        if (value) {
          this.terms = value;

          this.termsInitial =
            this.termsInitial === undefined || this.termsInitial == null
              ? value
              : this.termsInitial;
          this.terms.forEach((item) => {
            item.nameControl = item.id + "Textarea";
            item.nameControlEN = item.id + "ENTextarea";
            item.nameControlDate = item.id + "Datepicker";
            item.nameControlDocEs = item.id + "docEs";
            item.nameControlDocEn = item.id + "docEn";
            item.valueInitialDate = item.validityDate;
            item.isEdit = false;
            item.sendDateTmp = item.sendDate;
            const valueValueES =
              this.type == "terms" ? item["docTermsES"] : item["docPolicyES"];
            const valueValueEN =
              this.type == "terms" ? item["docTermsEN"] : item["docPolicyEN"];

            item["fileDataEN"] = valueValueEN ? valueValueEN : null;
            item["fileDataES"] = valueValueES ? valueValueES : null;
            this.addFormControl(item["nameControlDate"], item["validityDate"], [
              Validators.required,
            ]);
            this.addFormControl(
              item["nameControl"],
              this.type == "terms" ? item["terms"] : item["policy"],
              [Validators.required]
            );
            this.addFormControl(
              item["nameControlEN"],
              this.type == "terms" ? item["termsEN"] : item["policyEN"],
              [Validators.required]
            );
            //  if (valueValueES) {
            this.addFormControl(item.nameControlDocEs, valueValueES, [
              Validators.required,
            ]);
            // }
            // if (valueValueEN) {
            this.addFormControl(item.nameControlDocEn, valueValueEN, [
              Validators.required,
            ]);
            // }
          });
          this.terms = [...this.terms];
          this.cd.detectChanges();
          this.showTable = true;
          this.resetStateForm();
          this.spinner.hide("termsSpinner");
          console.log(this.terms);
        } else {
          this.spinner.hide("termsSpinner");
        }
      },
      error: (err) => {
        console.error(err);
        this.spinner.hide("termsSpinner");
      },
    });
  }

  onFilterDate(event, matchMode) {
    console.log(event);
    if (event != null && event != undefined && event instanceof Date) {
      this.terms = this.termsInitial;
      this.cd.detectChanges();
      this.filterDate = event;
      this.matchMode = matchMode;

      this.itemsTemp = [];
      this.terms.forEach((item: GenericConditions) => {
        if (this.matchMode == "dateIs") {
          const date = new Date(this.filterDate);
          if (
            new Date(date.getTime() + 86400000) >=
              new Date(item.validityDate) &&
            new Date(date.getTime() - 86400000) <= new Date(item.validityDate)
          ) {
            this.itemsTemp.push(item);
          }
        } else if (
          this.matchMode == "dateIsNot" &&
          new Date(this.filterDate) != new Date(item.validityDate)
        )
          this.itemsTemp.push(item);
        else if (
          this.matchMode == "dateBefore" &&
          new Date(this.filterDate) >= new Date(item.validityDate)
        )
          this.itemsTemp.push(item);
        else if (
          this.matchMode == "dateAfter" &&
          new Date(this.filterDate) <= new Date(item.validityDate)
        )
          this.itemsTemp.push(item);
        else if (this.matchMode == "noFilter") this.itemsTemp.push(item);
      });
      this.terms = this.itemsTemp;
      this.cd.detectChanges();
    }
  }
  addFormControl(name: string, value: any, options: ValidatorFn[]) {
    this.form.addControl(name, new FormControl(value, options));
  }
  setValueFormControl(name: string, value: any) {
    if (value) {
      this.form.get(name).setValue(value);
    } else {
      if (name.includes("Textarea")) {
        this.form.get(name).setValue("");
      }
    }
  }
  resetFormControlsState(controls: string[]) {
    if (controls.length > 0) {
      controls.forEach((name) => {
        const control = this.form.get(name);
        if (control) {
          control.markAsPristine();
          control.markAsUntouched();
        }
      });
    }
  }
  resetStateForm() {
    this.form.markAsPristine();
    this.form.markAsUntouched();
  }
  verifyExistenceControl(
    controlName: string,
    value: any,
    options: ValidatorFn[]
  ) {
    if (this.form.get(controlName) !== null) {
      this.setValueFormControl(controlName, value);
    } else {
      this.addFormControl(controlName, value, options);
    }
  }
}
