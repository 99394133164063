<div class="chargerFormContainer">
  <form [formGroup]="form" *ngIf="form" class="formContainer">
    <!-- COMPANY NAME -->
    <div class="formGroup">
      <label for="float-inputCompanyNameResponse" class="formLabel">{{'CONTACTS.FORM_REQUIRED.COMPANY_NAME' |
        translate}}</label>
      <!-- <input id="float-inputCompanyNameResponse" type="text" pInputText formControlName="companyName"
        class="formInput" /> -->
      <p-autoComplete id="float-inputCompanyNameResponse" formControlName="companyName" appendTo="body"
        [suggestions]="listAllShipownersNames" (completeMethod)="filterChartering($event)" [minLength]="1"
        [dropdown]="true" styleClass="listAutocomplete formInput w-100"
        panelStyleClass="panelListAutocomplete"></p-autoComplete>
      <div *ngIf="form.get('companyName').touched && form.get('companyName').hasError('required')" class="formError">
        {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
      </div>
    </div>

    <!-- REMARKS -->
    <div class="formGroup">
      <label for="float-inputRemarksResponse" class="formLabel">{{'CONTACTS.FORM.COMMENT' | translate}}</label>
      <textarea id="float-inputRemarksResponse" pInputTextarea formControlName="remarks"
        class="formTextarea"></textarea>
    </div>
    <!-- E-MAIL -->
    <div class="formGroup">
      <label for="float-inputEmailResponse" class="formLabel">{{'CONTACTS.FORM_REQUIRED.EMAIL' | translate}}</label>
      <input id="float-inputEmailResponse" type="text" pInputText formControlName="email" class="formInput" />
      <div *ngIf="form.get('email').touched && form.get('email').hasError('required')" class="formError">
        {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
      </div>
    </div>

    <!-- NAME -->
    <div class="formGroup">
      <label for="float-inputNameResponse" class="formLabel">{{'CONTACTS.FORM_REQUIRED.NAME' | translate}}</label>
      <input id="float-inputNameResponse" type="text" pInputText formControlName="name" class="formInput" />
      <div *ngIf="form.get('name').touched && form.get('name').hasError('required')" class="formError">
        {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
      </div>
    </div>

    <!-- LAST NAME -->
    <div class="formGroup">
      <label for="float-inputLastNameResponse" class="formLabel">{{'CONTACTS.FORM_REQUIRED.LAST_NAME' |
        translate}}</label>
      <input id="float-inputLastNameResponse" type="text" pInputText formControlName="lastName" class="formInput" />
      <div *ngIf="form.get('lastName').touched && form.get('lastName').hasError('required')" class="formError">
        {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
      </div>
    </div>

    <!-- PHONE -->
    <div class="formGroup">
      <label for="float-inputPhoneResponse" class="formLabel">{{'CONTACTS.FORM_REQUIRED.PHONE' | translate}}</label>
      <input id="float-inputPhoneResponse" type="text" pInputText formControlName="phone" class="formInput" />
      <div *ngIf="form.get('phone').touched && form.get('phone').hasError('required')" class="formError">
        {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
      </div>
      <div *ngIf="form.get('phone').touched && form.get('phone').value && form.get('phone').hasError('pattern')"
        class="formError">
        {{'MESSAGES.VALIDATION.PHONE' | translate}}
      </div>
    </div>


    <!-- BUTTON -->
    <button pButton pRipple type="button" icon="pi pi-save" class="formButton" (click)="save()" [disabled]="isreadonly">
      Save
    </button>
  </form>
</div>