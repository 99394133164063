<p-toast></p-toast>
<p-confirmDialog styleClass="containerConfirmDialog" acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"></p-confirmDialog>
<div [formGroup]="form">
  <p-table [value]="offers" responsiveLayout="stack" [breakpoint]="'959px'"
    styleClass="p-datatable-striped tableOffer tableofferBinding" [responsive]="true" [autoLayout]="true"
    [columns]="columns" *ngIf="showTable">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" class="styleTextTable thOfferResponse">
          <ng-container *ngIf="col.colName !== '' else lastColumnHeader"> {{col.colName | translate}} </ng-container>
          <ng-template #lastColumnHeader>
            <div class="row">
              <div class="col text-left">
                <div *ngIf="offer!=null">{{offer.refOffer}}</div>
              </div>
              <!--<div *ngIf="!readonly" class="col text-end mr-0"><i class="pi pi-paperclip"></i></div>-->
            </div>
          </ng-template>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-offer>
      <tr *ngFor="let title of columnsTitle;let index = index">
        <!-- ROW 0 -->
        <ng-container *ngIf="index === 0  else otherRows">
          <ng-container *ngIf="!showAddressCom">
            <ng-container *ngFor="let col of columns">
              <td *ngIf="col.colName==='STEP1_OFFER.TABLE.HEAD.HEAD_1' && isScreenLarge && !showAddressCom"
                class="styleTextTable">{{index}}</td>
              <td *ngIf="col.colName==='STEP1_OFFER.TABLE.HEAD.HEAD_1' && !isScreenLarge && !showAddressCom"
                class="styleTextTable">
                {{index}} -
                <p [escape]="false" [pTooltip]="title.tooltip| translate" tooltipPosition="bottom"
                  tooltipStyleClass="tooltipCLH" [showDelay]="1000" [hideDelay]="1000" *ngIf="!showAddressCom">
                  {{title.field.charterer | translate}}
                </p>
                <!-- <p *ngIf="showAddressCom">{{title.field.shipowner | translate}}</p> -->
              </td>

              <td *ngIf="col.colName==='STEP1_OFFER.TABLE.HEAD.HEAD_2' && isScreenLarge && !showAddressCom"
                class="styleTextTable">
                <p [escape]="false" [pTooltip]="title.tooltip| translate" tooltipPosition="bottom"
                  tooltipStyleClass="tooltipCLH" [showDelay]="1000" [hideDelay]="1000" *ngIf="!showAddressCom">
                  {{title.field.charterer | translate}}
                </p>
                <!-- <p *ngIf="showAddressCom">{{title.field.shipowner | translate}}</p> -->
              </td>
              <td *ngIf="col.colName==='' && !showAddressCom" class="styleTextTable">
                <ng-container *ngIf="!readonly else isReadonlyTable">
                  <input type="text" formControlName="{{title.control}}" pInputText placeholder="" class="input100" />
                </ng-container>
                <ng-template #isReadonlyTable>
                  <label> {{offer[title.label.charterer]}}</label>
                </ng-template>

                <!-- <ng-template #rowshipowner> <label> {{offer[title.label.shipowner]}}</label> </ng-template> -->
              </td>
            </ng-container>
          </ng-container>
        </ng-container>
        <!-- END ROW 0 -->
        <ng-template #otherRows>
          <ng-container *ngFor="let col of columns">
            <!-- ALL ROWS EXCEPT ADDRESCOM -->
            <ng-container *ngIf="title.control != 'addressComm' else templateA">
              <td *ngIf="col.colName==='STEP1_OFFER.TABLE.HEAD.HEAD_1' && isScreenLarge" class="styleTextTable">
                {{index}}</td>
              <td *ngIf="col.colName==='STEP1_OFFER.TABLE.HEAD.HEAD_1' && !isScreenLarge && title.control != 'quantity'"
                class="styleTextTable">
                {{index}} -
                <p [escape]="false" [pTooltip]="!showAddressCom  ? ( title.tooltip| translate) : ''"
                  tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" [showDelay]="1000" [hideDelay]="1000">
                  {{title.field | translate}}
                </p>
              </td>
              <td
                *ngIf="col.colName==='STEP1_OFFER.TABLE.HEAD.HEAD_1' && !isScreenLarge && title.control === 'quantity'"
                class="styleTextTable">
                {{index}} -
                <p [escape]="false" [pTooltip]="!showAddressCom ? (title.tooltip| translate) : ''"
                  tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" [showDelay]="1000" [hideDelay]="1000">
                  {{title.moreFields.quantity | translate}} / {{title.moreFields.stowage | translate}} /
                  {{title.moreFields.commodity | translate}}
                </p>
              </td>
              <td *ngIf="col.colName==='STEP1_OFFER.TABLE.HEAD.HEAD_2' && isScreenLarge" class="styleTextTable">
                <ng-container *ngIf="title.control != 'quantity' else templateQuantity">
                  <p [escape]="false" [pTooltip]="!showAddressCom ? (title.tooltip| translate) : ''"
                    tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" [showDelay]="1000" [hideDelay]="1000">
                    {{title.field | translate}}
                  </p>
                </ng-container>
                <ng-template #templateQuantity>
                  <p [escape]="false" [pTooltip]="!showAddressCom ? (title.tooltip| translate) : ''"
                    tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" [showDelay]="1000" [hideDelay]="1000">
                    {{title.moreFields.quantity | translate}} / {{title.moreFields.stowage | translate}} /
                    {{title.moreFields.commodity | translate}}
                  </p>
                </ng-template>
              </td>
              <td *ngIf="col.colName===''" class="styleTextTable">
                <!-- ROWS IF NOT READONLY -->
                <ng-container *ngIf="!readonly else isReadonlyTable">
                  <!-- ROWS ONLY WITH INPUT OR TEXTAREA  -->
                  <ng-container
                    *ngIf="(title.control != 'dischargingPorts' && title.control != 'loadingPorts' &&  title.control != 'rangeDates' && title.control != 'flete' && title.control != 'quantity') else templateFieldWithMoreInputs">
                    <ng-container
                      *ngIf="title.control != 'aditionalRequirements' && title.control != 'restrictionsRegarding' ">
                      <div class="d-flex flex-column justify-content-start">
                        <div class="d-flex justify-content-start">
                          <input type="text" formControlName="{{title.control}}" pInputText size="100" placeholder=""
                            class="w-100 inputName" />
                        </div>
                        <div
                          *ngIf="form.get(title.control).touched  && !form.get(title.control).value  && form.get(title.control).hasError('required')"
                          class="error-label labelError">
                          {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                        </div>
                      </div>
                    </ng-container>
                    <ng-container
                      *ngIf="title.control ==='aditionalRequirements' || title.control ==='restrictionsRegarding' ">
                      <clh-textarea [form]="form" [nameControl]="title.control" [errorLabel]="''" [placeholder]="''"
                        [label]="title.labelTextarea" [idTextarea]="title.idTextarea"
                        [classTextarea]="title.classTextarea" [required]="title.required"
                        [showLabelFloat]="false"></clh-textarea>
                    </ng-container>
                  </ng-container>
                  <!-- END ROWS ONLY WITH INPUT OR TEXTAREA  -->
                  <!-- ROWS WITH DIFFERENT CONDITIONS  -->
                  <ng-template #templateFieldWithMoreInputs>
                    <!-- ROW PORTS -->
                    <ng-container *ngIf="title.control === 'dischargingPorts' ">
                      <div class="d-flex flex-column justify-content-start w-100">
                        <div class="d-flex justify-content-start w-100">
                          <overa-autocomplete [formGroup]="form" [controlName]="title.control" [options]="portsD"
                            [selectedItems]="selectedDischargingPorts" [filterStartIndex]="3" [multiple]="true"
                            (selectedItemsChange)="selectedDischargingPortChange($event)" [validators]="[arrValidator]"
                            [required]="title.required" [readonly]="readonly"
                            [isDisabled]="readonly"></overa-autocomplete>

                          <!-- <overa-autocomplete-old
                            [form]="form"
                            *ngIf="ports"
                            [readonly]="readonly"
                            (onSelectionChange)="selectedDischargingPortChange($event)"
                            [name]="title.control"
                            style="width: 100%"
                            [required]="title.required"
                            [items]="portsD"
                            [selectedItems]="selectedDischargingPorts"
                            [multiple]="true"
                            [filterStartIndex]="3"
                            [validators]="[arrValidator]"
                          >
                          </overa-autocomplete-old> -->

                        </div>
                        <div *ngIf="form.get(title.control).touched  && form.get(title.control).hasError('required')"
                          class="error-label labelError">
                          {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                        </div>
                        <div *ngIf="form.get(title.control).touched && form.get(title.control).hasError('notInList')"
                          class="error-label labelError">
                          {{'MESSAGES.VALIDATION.PORTS_INVALID' | translate}}
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="title.control === 'loadingPorts'">
                      <div class="d-flex flex-column justify-content-start w-100">
                        <div class="d-flex justify-content-start w-100">
                          <overa-autocomplete [formGroup]="form" [controlName]="title.control" [options]="ports"
                            [selectedItems]="selectedDischargingPorts" [filterStartIndex]="3" [multiple]="true"
                            (selectedItemsChange)="selectedLoadingPortChange($event)" [validators]="[arrValidator]"
                            [required]="title.required" [readonly]="readonly"
                            [isDisabled]="readonly"></overa-autocomplete>
                          <!-- <overa-autocomplete-old
                            [form]="form"
                            *ngIf="ports"
                            [readonly]="readonly"
                            [name]="title.control"
                            style="width: 100%"
                            [required]="title.required"
                            [items]="ports"
                            [selectedItems]="selectedLoadingPorts"
                            (onSelectionChange)="selectedLoadingPortChange($event)"
                            [multiple]="true"
                            [filterStartIndex]="3"
                            [validators]="[arrValidator]"
                          >
                          </overa-autocomplete-old> -->
                        </div>
                        <div *ngIf="form.get(title.control).touched  && form.get(title.control).hasError('required')"
                          class="error-label labelError">
                          {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                        </div>
                        <div *ngIf="form.get(title.control).touched && form.get(title.control).hasError('notInList')"
                          class="error-label labelError">
                          {{'MESSAGES.VALIDATION.PORTS_INVALID' | translate}}
                        </div>
                      </div>
                    </ng-container>
                    <!--END ROW PORTS -->
                    <!-- ROW QUANTITY COMMODITY -->
                    <ng-container *ngIf="title.control === 'quantity'">
                      <div class="d-flex justify-content-start">
                        <div class="field divQuantityCommodity">
                          <div class="d-flex flex-wrap flex-row justify-content-start">
                            <div class="divInputQuantity">
                              <p-inputNumber formControlName="{{title.moreControls.quantity}}"
                                class="inputNumberQuantity" locale="de-DE"
                                placeholder="{{title.placeholder.quantity | translate}}">
                              </p-inputNumber>
                              <div
                                *ngIf="form.get(title.moreControls.quantity).touched  && !form.get(title.moreControls.quantity).value  && form.get(title.moreControls.quantity).hasError('required')"
                                class="error-label labelError">
                                {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                              </div>
                            </div>

                            <div class="divInputQuantityType">
                              <p-dropdown class="dropdownQuantityType" [options]="quantityTypes"
                                formControlName="{{title.moreControls.quantityType}}" appendTo="body"></p-dropdown>
                            </div>
                            <div class="divLabelBarra">
                              <label> / </label>
                            </div>
                            <div class="divInputStowage">
                              <!-- <span class="p-float-label spanMargin"> -->
                              <input type="text" formControlName="{{title.moreControls.stowage}}" pInputText size="30"
                                placeholder="{{title.placeholder.stowage | translate}}"
                                class="inputStowage shading-quantity" id="{{title.idInputTextarea.stowage}}" />
                              <!-- <label for="{{title.idInputTextarea.stowage}}">{{title.placeholder.stowage | translate}}</label>
                              </span> -->
                            </div>
                          </div>
                          <div class="field divInputCommodity">
                            <clh-textarea [form]="form" [nameControl]="title.moreControls.commodity"
                              [style]="'width:100%;'" [errorLabel]="'MESSAGES.VALIDATION.REQUIRED_FIELD'"
                              placeholder="{{title.placeholder.commodity | translate}}"
                              [label]="title.placeholder.commodity" [idTextarea]="title.idInputTextarea.commodity"
                              [classTextarea]="'inputCommodity clhTextarea'" [required]="true"
                              [showLabelFloat]="false"></clh-textarea>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <!--END ROW QUANTITY COMMODITY -->
                    <!-- ROW FLETE -->
                    <ng-container *ngIf="title.control === 'flete'">
                      <div class="d-flex flex-wrap flex-row justify-content-start align-items-center containerFlete">
                        <p-dropdown class="dropdownCoin" [options]="coins"
                          formControlName="{{title.moreControls.currency}}" appendTo="body"></p-dropdown>
                        <p-inputNumber class="inputFreightTargetQuantity"
                          formControlName="{{title.moreControls.quantity}}" mode="decimal" [minFractionDigits]="2"
                          [maxFractionDigits]="2" locale="de-DE"></p-inputNumber>
                        <p-dropdown class="dropdownFlete" [options]="fletes"
                          formControlName="{{title.moreControls.type}}" appendTo="body">
                        </p-dropdown>
                      </div>
                    </ng-container>
                    <!--END ROW FLETE -->
                    <!--ROW RANGEDATES -->
                    <ng-container *ngIf="title.control === 'rangeDates'">
                      <div class="d-flex justify-content-start">
                        <p-calendar dateFormat="dd/mm/yy" formControlName="{{title.control}}" selectionMode="range"
                          [utc]="true" inputId="range" styleClass="calendarTableOffer"></p-calendar>
                      </div>
                      <div class="d-flex justify-content-start" *ngIf="!readonly">
                        <div *ngIf="form.get(title.control).touched  && form.get(title.control).hasError('required')"
                          class="error-label labelError">
                          {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                        </div>
                        <div *ngIf="form.get(title.control).hasError('errorDates') " class="error-label labelError">
                          {{'MESSAGES.VALIDATION.RANGE_DATES' | translate}}
                        </div>
                      </div>
                    </ng-container>
                    <!--END ROW RANGEDATES -->
                  </ng-template>
                  <!-- END ROWS WITH DIFFERENT CONDITIONS  -->
                </ng-container>
                <!-- END ROWS IF NOT READONLY -->
                <!-- ROWS IF READONLY -->
                <ng-template #isReadonlyTable>
                  <label
                    *ngIf="title.control != 'dischargingPorts' && title.control != 'loadingPorts' &&  title.control != 'rangeDates' && title.control != 'flete' && title.control != 'quantity'">
                    {{offer[title.label]}}</label>
                  <label
                    *ngIf="title.control === 'dischargingPorts' || title.control === 'loadingPorts'">{{offer[title.label].join(',')}}</label>
                  <label *ngIf="title.control === 'rangeDates'">{{offer[title.label.fieldFrom] | date: 'dd/MM/yy'}} -
                    {{offer[title.label.fieldTo] | date: 'dd/MM/yy'}}</label>
                  <label
                    *ngIf="title.control === 'flete' && offer[title.moreLabels.quantity] > 0">{{targetCurrencyLabel}}
                    {{offer[title.moreLabels.quantity] | number: '1.2-2'}} {{targetTypeLabel}}</label>
                  <label *ngIf="title.control === 'quantity'">{{offer[title.moreLabels.quantity] | number: '1.0-0' }}
                    {{quantityTypeLabel}} / {{offer[title.moreLabels.stowage]}} /
                    {{offer[title.moreLabels.commodity]}}</label>
                </ng-template>
                <!-- END ROWS IF READONLY -->
              </td>
            </ng-container>
            <!--END ALL ROWS EXCEPT ADDRESCOM -->
            <!-- ROW ADDRESSCOM -->
            <ng-template #templateA>
              <ng-container *ngIf="showAddressCom">
                <td *ngIf="col.colName==='STEP1_OFFER.TABLE.HEAD.HEAD_1' && isScreenLarge" class="styleTextTable">A</td>
                <td *ngIf="col.colName==='STEP1_OFFER.TABLE.HEAD.HEAD_1' && !isScreenLarge" class="styleTextTable">
                  A - {{title.field | translate}}
                </td>
                <td *ngIf="col.colName==='STEP1_OFFER.TABLE.HEAD.HEAD_2' && isScreenLarge" class="styleTextTable">
                  {{title.field | translate}}</td>
                <td *ngIf="col.colName===''" class="styleTextTable">
                  <ng-container *ngIf="!readonly else isReadonlyTable">
                    <ng-container>
                      <input type="text" formControlName="{{title.control}}" pInputText size="100" placeholder=""
                        class="w-100 inputName" />
                    </ng-container>
                  </ng-container>
                  <ng-template #isReadonlyTable>
                    <label> {{offer[title.label]}} %</label>
                  </ng-template>
                </td>
              </ng-container>
            </ng-template>
            <!-- END ROW ADDRESSCOM -->
          </ng-container>
        </ng-template>
      </tr>
    </ng-template>
  </p-table>
  <ng-container *ngIf="readonly">
    <div class="container-fluid mt-3 mb-3 additionalComments">
      <label> <strong>{{'STEP1_OFFER.ADDITIONAL_COMMENTS'| translate}} : </strong>{{offer.comments}}</label>
    </div>
  </ng-container>
</div>