import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AccountService } from "@overa/security";
import { OveraMessagesService } from "@overa/components/info-dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";
import { catchError, forkJoin, Observable, of, switchMap } from "rxjs";
import { MailTemplateTypes } from "src/app/models/enum/mail-template-types";
import { Offer } from "src/app/models/offers/offer.model";
import { OfferDecline } from "src/app/models/offers/offer-decline.model";
import { OfferShipOwner } from "src/app/models/offers/offer-shipowner.model";
import { OfferService } from "src/app/services/offer.service";
import { ShipownerService } from "src/app/services/shipowner.service";
import { CLHGlobals } from "src/app/utils/globals";
import { ChatHelper } from "src/app/utils/chat-helper.utils";
import { ChatGroup } from "src/app/models/chat/chatGroup.model";
import { ChatMessage } from "src/app/models/chat/chatMessage.model";
import { ChatParticipant } from "src/app/models/chat/chatParticipant.model";
import { StateOfferShipOwner } from "src/app/models/enum/state-offer-shipowner.model";
import { BreadcrumbService } from "src/app/services/breadcrumb.service";
import { FormGroup } from "@angular/forms";
import { Constants } from "src/app/utils/constants";
import { PermissionsService } from "src/app/services/permissions.service";
import { NgxTranslateService } from "@overa/shared";
import { mapTemplate } from "src/app/utils/template-helper";
@Component({
  selector: "app-offer-received",
  templateUrl: "./offer-received.component.html",
  styleUrls: ["./offer-received.component.scss"],
  providers: [MessageService],
})
export class OfferReceivedComponent implements OnInit {
  selectedOffer: Offer;
  shipownerId: string = "";
  isShow: boolean = false;
  idOffer: string = "";
  expirationDate: Date;
  offerShipOwnerToExpiration: OfferShipOwner;
  chatValues: Array<{
    offerShipOwnerId: string;
    currentParticipant: ChatParticipant;
    group: ChatGroup;
    windowName: string;
    historicMessages: ChatMessage[];
    unReadMessages: number;
  }> = [];
  isClh: boolean = false;
  isReadonly: boolean = false;
  breadcrumb: MenuItem[];
  form: FormGroup = new FormGroup({});
  allMethodsCompleted: boolean = false;
  constructor(
    public router: Router,
    private offerService: OfferService,
    private accountService: AccountService,
    private shipOwnerService: ShipownerService,
    private globals: CLHGlobals,
    private spinner: NgxSpinnerService,
    public messageService: OveraMessagesService,
    private confirmationService: ConfirmationService,
    private translate: NgxTranslateService,
    private chatHelper: ChatHelper,
    private breadcrumbService: BreadcrumbService,
    private permissionsService: PermissionsService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.idOffer = "";
    this.shipownerId = "";
    this.idOffer = this.router.url.toString().split("/").pop();
    console.log("id", this.idOffer);
    if (this.globals.currentShipOwner) {
      this.shipownerId = this.globals.currentShipOwner.id;
    }
    if (
      !this.globals.currentCharger &&
      !this.globals.currentShipOwner &&
      this.accountService.currentUser.roles
        .map((x) => x.name)
        .includes("CLH Admin")
    ) {
      this.isClh = true;
    }
    this.spinner.show();
    this.loadData(this.idOffer).subscribe({
      next: (offer) => {
        this.selectedOffer = offer;
        // console.log(values,this.selectedOffer);

        this.breadcrumbService.getBreadcrumb().subscribe((items) => {
          if (!this.breadcrumb) {
            this.breadcrumb = items;
          }
        });
        this.breadcrumb[0].label =
          this.breadcrumb[0].label +
          "    >    " +
          this.selectedOffer.charger.name;
        this.breadcrumbService.setBreadcrumb(this.breadcrumb);

        if (this.shipownerId) {
          console.log("THIS", this);
          this.offerShipOwnerToExpiration =
            this.selectedOffer.offerShipOwners.find(
              (item) => item.shipOwnerId == this.shipownerId
            );
          this.setChatOptions(this.offerShipOwnerToExpiration);
          this.expirationDate = this.offerShipOwnerToExpiration.expirationDate;
          console.log("expiration", this.expirationDate);
        }
        this.isReadonly =
          this.offerShipOwnerToExpiration?.state ===
          StateOfferShipOwner.WAIT_RESPONSE
            ? false
            : true;
        if (!this.permissionsService.hasPermissionToEdit(this.activatedRoute)) {
          this.isReadonly = true;
        }
        this.isShow = true;
        this.spinner.hide();
      },
      error: (err) => {
        //TODO add logic when request fails
        this.spinner.hide();
      },
    });
  }

  setChatOptions(offerBinding: OfferShipOwner) {
    const isClh = this.accountService.currentUser.roles
      .map((x) => x.name)
      .includes("CLH Admin");

    console.log("this.globals", this.globals);
    console.log("this.isClh", this.isClh);
    if (isClh) {
      // En esta pantall no deberia de poder entrar un cargador, ya que a cargador va a firmOffer
      //TODO: comprobar si borrar, clh tampoco tiene acceso a esta pantalla, va desde firmoffer
      const clhChargerSubscriptions = this.chatHelper
        .getChatGroupChargerCLH(offerBinding.offerId)
        .pipe(
          switchMap((group) => {
            return forkJoin({
              windowName: this.selectedOffer.charger.name,
              currentParticipant: this.chatHelper.getCurrentParticipant(
                this.globals.user
              ),
              group: of(group),
              historicMessages: this.chatHelper.getMessageHistory(group.id),
            });
          })
        );
      clhChargerSubscriptions.subscribe(
        ({ windowName, currentParticipant, group, historicMessages }) => {
          if (windowName && currentParticipant && group && historicMessages) {
            const obj = {
              offerShipOwnerId: offerBinding.id,
              currentParticipant: currentParticipant,
              windowName: this.selectedOffer.charger.name,
              group: group,
              historicMessages: historicMessages.map((s) =>
                this.chatHelper.setReplyBasedOnCurrentUser(s)
              ),
              unReadMessages: historicMessages
                .map((s) => this.chatHelper.setReplyBasedOnCurrentUser(s))
                .filter(
                  (s) => s.participantId != currentParticipant.id && !s.seen
                ).length,
            };
            this.chatValues.push(obj);
            this.allMethodsCompleted = true;
          } else {
            this.allMethodsCompleted = false;
          }
        }
      );
    } else if (this.globals.currentShipOwner) {
      //Chat shipownerClh
      const clhShipownerSubscriptions = this.chatHelper
        .getChatGroupCLHOfferShipOwner(offerBinding.id)
        .pipe(
          switchMap((group) => {
            return forkJoin({
              windowName: "CLH",
              currentParticipant: this.chatHelper.getCurrentParticipant(
                this.globals.user
              ),
              group: of(group),
              historicMessages: this.chatHelper.getMessageHistory(group.id),
            });
          })
        );
      clhShipownerSubscriptions.subscribe(
        ({ windowName, currentParticipant, group, historicMessages }) => {
          if (windowName && currentParticipant && group && historicMessages) {
            const obj = {
              offerShipOwnerId: offerBinding.id,
              currentParticipant: currentParticipant,
              windowName: "CLH",
              group: group,
              historicMessages: historicMessages.map((s) =>
                this.chatHelper.setReplyBasedOnCurrentUser(s)
              ),
              unReadMessages: historicMessages
                .map((s) => this.chatHelper.setReplyBasedOnCurrentUser(s))
                .filter(
                  (s) => s.participantId != currentParticipant.id && !s.seen
                ).length,
            };
            this.chatValues.push(obj);
            this.allMethodsCompleted = true;
          } else {
            this.allMethodsCompleted = false;
          }
        }
      );
    }
  }

  loadData(idOffer: string): Observable<any> {
    return this.offerService.getShipownerResponse(idOffer);
  }

  declineOffer() {
    const trmessage = this.translate.get("COMMONS.ASK_DECLINED");
    const messageAccept = this.translate.get("ACTION.YES");

    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        const offerDecline: OfferDecline = new OfferDecline();
        offerDecline.offerId = this.selectedOffer.id;
        offerDecline.shipOwnerId = this.shipownerId;
        const offerShipOwner: OfferShipOwner =
          this.selectedOffer.offerShipOwners.filter(
            (f) => f.shipOwnerId === this.shipownerId
          )[0];
        offerShipOwner.offer = this.selectedOffer;
        offerShipOwner.shipOwner = this.globals.currentShipOwner;
        offerShipOwner["termsConditions"] = this.globals.termsConditions.terms;
        offerShipOwner["legalAdvice"] = Constants.LEGAL_ADVICE.es;
        offerShipOwner["dataProtection"] =
          this.globals.dataProtection.policy + Constants.DATA_PROTECTION.es;
        offerDecline.templateName = MailTemplateTypes.OFFER_SHIPOWNER_REJECT;

        this.spinner.show();
        this.offerService.declineOfferSendEmail(offerDecline).subscribe({
          next: (item) => {
            this.messageService.showToast(
              "INFO",
              "MESSAGES.SUCCESS.UPDATE_EMAIL",
              "success",
              ""
            );
            this.spinner.hide();
            //TODO add toast showing succesfull decline

            this.router.navigate(["home"]);
          },
          error: (err) => {
            //TODO add logic when email fails
            this.spinner.hide();
            this.messageService.showToast(
              "ERROR",
              "MESSAGES.ERROR.NO_SEND_EMAIL",
              "error",
              ""
            );
          },
        });
      },
      reject: () => {
        console.log("reject");
      },
    });
  }
  acceptOffer() {
    this.router.navigate(["offerResponse", this.offerShipOwnerToExpiration.id]);
  }
}
