import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TabViewModule } from "primeng/tabview";
import { TranslateService } from "@ngx-translate/core";
import { SearcherService } from "../../../services/searcher.service";
import { KanbanTask } from "../../../models/kanban-task.model";

@Component({
  selector: "app-tab-view-home",
  templateUrl: "./tab-view-home.component.html",
  styleUrls: ["./tab-view-home.component.scss"],
})
export class TabViewHomeComponent implements OnInit {
  @Output()
  onSelectKanban: EventEmitter<string> = new EventEmitter<string>();
  @Input()
  markers = [];
  @Input()
  selectedKanban: any;
  @Input()
  kanbanTasks: KanbanTask[] = [];
  kanbanData: KanbanTask[] = [];
  imos: any[] = [];
  constructor(private searcherService: SearcherService) {}
  ngOnInit() {
    this.searcherService.selectKanbanMap.subscribe((imoSelected) => {
      console.log(imoSelected);
      this.selectedKanban = imoSelected;
    });
    this.searcherService.dataSearchResults.subscribe((results) => {
      console.log(results);
      this.imos = [];
      let imos: any[] = [];
      this.selectedKanban = null;
      if (results) {
        results.forEach((res: any) => {
          if (res.imo) {
            imos.push(res);
            this.kanbanData.push(res);
          } else if (res.offerShipOwners) {
            res.offerShipOwners.forEach((os: any) => {
              if (os.imo) {
                os.offer = res;
                imos.push(os);
                this.kanbanData.push(os);
              }
            });
          }
        });
      }
      this.imos = [...imos];
    });
  }

  unSelectKanban(element: any) {
    console.log(element);
    this.selectedKanban = null;
    this.markers = [];
  }
  associateOfferToKanban() {}
}
