<!--  -->
<ngx-spinner></ngx-spinner>
<p-toast></p-toast>
<p-confirmDialog
  [styleClass]="containerConfirmDialog"
  [acceptButtonStyleClass]="styleButtonYesConfirmDialog"
  [rejectButtonStyleClass]="styleButtonNoConfirmDialog"
></p-confirmDialog>
<div class="chargerFormContainer">
  <div class="card chargerForm">
    <div class="card-header chargerFormCardHeader">
      <!--  N 04-05-2023 -->

      <div class="container container-header">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="d-flex flex-row justify-content-left flex-wrap div-header-block">
              <div>
                <div class="text-header-charger">{{'CHARTERER_DATA_SHEET.CHARGER' | translate}}</div>
              </div>
              <div>
                <div class="text-header-charger-date">
                  {{'CHARTERER_DATA_SHEET.DISCHARGE_DATE' | translate}}: <span class="grey-header-date">08/02/2023</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  Content -->
      <div class="card-body chargerFormCardBody">
        <ng-container class="chargerFormContainer" [formGroup]="form">
          <!-- BOOSTRAP -->
          <!-- N CONTENEDOR -->
          <div class="container">
            <div class="row centered-row gx-5">
              <!-- COLUMNA IZQUIERDA -->
              <div class="col-md-5 border-container">
                <div class="row centered-row mb-5">
                  <!--NOMBRE EMPRESA-->
                  <div class="col-md-12 co12-sm-12 col-12">
                    <div class="shadow-data-block">
                      <label>{{'CHARTERER_DATA_SHEET.CHARGER' |translate}}</label>
                      <input
                        type="text"
                        id="float-inputName"
                        formControlName="name"
                        pInputText
                        size="150"
                        placeholder=""
                        style="width: 100%"
                        class="inputNameCharger"
                      />
                      <div
                        class="col-12"
                        *ngIf="form.get('name').touched && !form.get('name').value && form.get('name').hasError('required')"
                        class="error-label labelError"
                      >
                        {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                      </div>
                    </div>
                  </div>
                  <!--FIN NOMBRE EMPRESA-->
                </div>

                <div class="row centered-row mb-3">
                  <!--Cif -->
                  <div [ngClass]="isClh ? 'col-md-6 col-sm-6 col-6' : 'col-md-12 col-sm-12 col-12' ">
                    <div class="shadow-data-block">
                      <label for="float-inputCifVat">{{'CHARTERER_DATA_SHEET.CIF_CHARGER' |translate}}</label>
                      <input
                        type="text"
                        id="float-inputNifCharger"
                        formControlName="cif"
                        pInputText
                        size="150"
                        placeholder=""
                        style="width: 100%"
                        class="inputNifCharger"
                      />
                      <div
                        class="col-12"
                        *ngIf="form.get('cif').touched && form.get('cif').value && form.get('cif').hasError('pattern')"
                        class="error-label labelError"
                      >
                        {{'MESSAGES.VALIDATION.CIF_INVALID' | translate}}
                      </div>
                      <div
                        class="col-12"
                        *ngIf="form.get('cif').touched && !form.get('cif').value && form.get('cif').hasError('required')"
                        class="error-label labelError"
                      >
                        {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                      </div>
                    </div>
                  </div>
                  <!--Fin Cif -->
                  <!--IMO-->
                  <div class="col-md-6 col-sm-6 col-6" *ngIf="isClh">
                    <div class="shadow-data-block">
                      <label overaellipsis for="float-inputAddressComm" class="w-100">{{'CHARTERER_DATA_SHEET.ADDRESS_COM' | translate}}</label>
                      <div class="d-flex justify-content-center align-items-center">
                        <span class="p-input-icon-right">
                          <p-inputNumber
                            class="inputAddressComm"
                            formControlName="addressComm"
                            mode="decimal"
                            [minFractionDigits]="2"
                            [maxFractionDigits]="2"
                            locale="de-DE"
                          ></p-inputNumber>
                          <i class="pi pi-percentage"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <!--FIN IMO-->
                </div>

                <div class="row centered-row mb-3">
                  <!--DIRECCION-->
                  <div class="col-md-12 co12-sm-12 col-12">
                    <div class="shadow-data-block">
                      <label>{{'CHARTERER_DATA_SHEET.ADDRESS' | translate}}</label>
                      <input
                        type="text"
                        id="float-inputAddressCharger"
                        formControlName="address"
                        pInputText
                        size="150"
                        placeholder=""
                        style="width: 100%"
                        class="inputFormCharger inputAddressCharger"
                      />
                      <div
                        class="col-12"
                        *ngIf="form.get('address').touched && !form.get('address').value && form.get('address').hasError('required')"
                        class="error-label labelError"
                      >
                        {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                      </div>
                    </div>
                  </div>
                  <!--FIN DIRECCION-->
                </div>

                <div class="row centered-row mb-3">
                  <!--POBLACION -->
                  <div class="col-md-6 col-sm-6 col-6">
                    <div class="shadow-data-block">
                      <label for="float-inputTown">{{'CHARTERER_DATA_SHEET.TOWN' | translate}}</label>
                      <input
                        pInputText
                        type="text"
                        formControlName="town"
                        inputId="float-inputTown"
                        class="inputFormCharger inputCodePostalCharger"
                        style="width: 100%"
                      />
                      <div
                        class="col-12"
                        *ngIf="form.get('town').touched && !form.get('town').value && form.get('town').hasError('required')"
                        class="error-label labelError"
                      >
                        {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                      </div>
                    </div>
                  </div>
                  <!--FIN POBLACION -->
                  <!--CP-->
                  <div class="col-md-6 col-sm-6 col-6">
                    <div class="shadow-data-block">
                      <label for="float-inputCodePostalCharger">{{'CHARTERER_DATA_SHEET.CP' | translate}}</label>
                      <input
                        pInputText
                        type="text"
                        formControlName="postalCode"
                        inputId="float-inputCodePostalCharger"
                        class="inputFormCharger inputCodePostalCharger"
                        style="width: 100%"
                      />

                      <div
                        class="col-12"
                        *ngIf="form.get('postalCode').touched && !form.get('postalCode').value && form.get('postalCode').hasError('required')"
                        class="error-label labelError"
                      >
                        {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                      </div>
                    </div>
                  </div>
                  <!--FIN CP-->
                </div>

                <div class="row centered-row mb-3">
                  <!--PROVINCIA -->
                  <div class="col-md-6 col-sm-6 col-6">
                    <div class="shadow-data-block">
                      <label for="float-inputProvinciaCharger">{{'CHARTERER_DATA_SHEET.PROVINCE' | translate}}</label>
                      <input
                        type="text"
                        id="float-inputProvinciaCharger"
                        formControlName="province"
                        pInputText
                        size="150"
                        placeholder=""
                        autocomplete="new-password"
                        class="inputFormCharger inputProvinceCharger"
                      />
                      <div
                        class="col-12"
                        *ngIf="form.get('province').touched && !form.get('province').value && form.get('province').hasError('required')"
                        class="error-label labelError"
                      >
                        {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                      </div>
                    </div>
                  </div>
                  <!--FIN PROVINCIA -->
                  <!--PAIS-->
                  <div class="col-md-6 col-sm-6 col-6">
                    <div class="shadow-data-block">
                      <label for="float-inputCountryCharger">{{'CHARTERER_DATA_SHEET.COUNTRIES' | translate}}</label>
                      <p-autoComplete
                        id="float-inputCountryCharger"
                        formControlName="country"
                        appendTo="body"
                        [suggestions]="listCountries"
                        (completeMethod)="filterlistCountries($event)"
                        [minLength]="1"
                        (onSelect)="onCountrySelect($event)"
                        [dropdown]="true"
                        styleClass="listAutocomplete"
                        panelStyleClass="panelListAutocomplete"
                        field="name"
                        autocomplete="new-password"
                      ></p-autoComplete>
                      <div
                        class="col-12"
                        *ngIf="form.get('country').touched && !form.get('country').value && form.get('country').hasError('required')"
                        class="error-label labelError"
                      >
                        {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                      </div>
                    </div>
                  </div>
                  <!--FIN PAIS-->
                </div>

                <div class="row centered-row mb-3">
                  <!--PAGINA WEB ABAJO OCULTA POR SI SE QUIERE PONER-->
                  <!--
                                                    <div class="col-md-12 co12-sm-12 col-12">
                                                      <div class="shadow-data-block">
                                                        <label>{{'CHARTERER_DATA_SHEET.WEB' | translate}}</label>
                                                        <input
                                                          type="text"
                                                          id="float-inputWebCharger"
                                                          formControlName="web"
                                                          pInputText
                                                          size="150"
                                                          placeholder=""
                                                          style="width: 100%"
                                                          class="inputFormCharger inputWebCharger"
                                                        />
                                                        <div
                                                          class="col-12"
                                                          *ngIf="form.get('web').touched && !form.get('web').value && form.get('web').hasError('required')"
                                                          class="error-label labelError"
                                                        >
                                                          {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                                                        </div>
                                                        <div
                                                          class="col-12"
                                                          *ngIf="form.get('web').touched && form.get('web').value && form.get('web').hasError('pattern')"
                                                          class="error-label labelError"
                                                        >
                                                          {{'MESSAGES.VALIDATION.ERROR_LABEL' | translate}}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    -->
                  <!--FIN PAGINA WEB ABAJO OCULTA POR SI SE QUIERE PONER-->
                </div>
              </div>
              <!-- FIN COLUMNA IZQUIERA -->
              <!-- COLUMNA DERECHA -->

              <div class="col-md-5 border-container">
                <div class="row centered-row mb-5">
                  <!--LOGO-->
                  <div class="col-md-12 co12-sm-12 col-12">
                    <div class="shadow-data-block center">
                      <input class="input-file" #fileInput type="file" (change)="onFileSelected($event)" accept="image/*" />
                      <div class="logo-charger" (click)="!readonly ? fileInput.click() : null">
                        <img [src]="chargerLogoPhoto" *ngIf="chargerLogoPhoto" />
                      </div>
                    </div>
                  </div>
                  <!--FIN LOGO-->
                </div>

                <div class="row centered-row mb-3">
                  <!--PAG WEB-->
                  <div class="col-md-12 co12-sm-12 col-12">
                    <div class="shadow-data-block">
                      <label>{{'CHARTERER_DATA_SHEET.WEB' | translate}}</label>
                      <input
                        type="text"
                        id="float-inputWebCharger"
                        formControlName="web"
                        pInputText
                        size="150"
                        placeholder=""
                        style="width: 100%"
                        class="inputFormCharger inputWebCharger"
                      />
                      <div
                        class="col-12"
                        *ngIf="form.get('web').touched && !form.get('web').value && form.get('web').hasError('required')"
                        class="error-label labelError"
                      >
                        {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                      </div>
                      <div
                        class="col-12"
                        *ngIf="form.get('web').touched && form.get('web').value && form.get('web').hasError('pattern')"
                        class="error-label labelError"
                      >
                        {{'MESSAGES.VALIDATION.ERROR_LABEL' | translate}}
                      </div>
                    </div>
                  </div>
                  <!--FIN PAG WEB-->
                </div>
              </div>

              <!-- FIN COLUMNA DERECHA -->
            </div>
            <div class="containerButtonDeleteLeft" *ngIf="!readonly">
              <div class="d-flex flex-row justify-content-center flex-wrap div-buttons-form">
                <div *ngIf="!isNew">
                  <button
                    pButton
                    pRipple
                    *ngIf="!isNew && isClh"
                    label="{{'ACTION.DELETE' | translate}}"
                    (click)="deleteCharger()"
                    class="borrar p-ripple"
                    [disabled]="!showButtonAfterSave"
                  ></button>
                </div>
              </div>
              <div class="d-flex flex-row justify-content-center flex-wrap div-buttons-form">
                <div>
                  <button
                    pButton
                    label="{{'ACTION.CANCEL' | translate}}"
                    (click)="cancel()"
                    class="cancelar p-ripple"
                    [disabled]="!showButtonAfterSave"
                  ></button>
                </div>
                <div *ngIf="isNew && !isUpdate">
                  <button
                    pButton
                    pRipple
                    *ngIf="isNew && !isUpdate"
                    label="{{'COMMONS.CREATE' | translate}}"
                    (click)="!form.invalid ? createCharger() : null "
                    class="crear p-ripple"
                    [disabled]="!form.valid || !showButtonAfterSave"
                  ></button>
                </div>

                <div *ngIf="!isNew && isUpdate">
                  <button
                    pButton
                    pRipple
                    *ngIf="!isNew && isUpdate"
                    label="{{'ACTION.SAVE' | translate}}"
                    (click)="updateCharger()"
                    class="actualizar p-ripple"
                    [disabled]="!form.valid || !showButtonAfterSave"
                  ></button>
                </div>
              </div>
            </div>
          </div>

          <!-- FIN NI CONTENEDOR -->
          <!-- FIN BOOSTRAP -->
        </ng-container>
      </div>

      <!--  end Content -->
      <!--  N 04-05-2023 -->
    </div>
  </div>
  <div class="tabviewChargerContainer">
    <p-tabView class="tabviewCharger" *ngIf="!isNew && canViewPanels" (onChange)="changeTab($event)">
      <p-tabPanel>
        <ng-template pTemplate="header">
          <span
            [escape]="false"
            [pTooltip]="'TOOLTIP.USERS_CONTACT' | translate"
            tooltipPosition="right"
            tooltipStyleClass="tooltipCLH"
            [showDelay]="1000"
            [hideDelay]="1000"
          >
            {{'CONTACTS.HEADER'|translate}}
          </span>
        </ng-template>
        <ng-container *ngIf="chargerToForm">
          <app-contact-new [itemToUse]="chargerToForm" [enabled]="this.form.enabled" [dataSheet]="'charger'"></app-contact-new>
        </ng-container>
      </p-tabPanel>
      <p-tabPanel class="containerPanelChargerShipowner">
        <ng-template pTemplate="header">
          <span> {{'CHARTERER_DATA_SHEET.MAINTENANCE_OWNERS.HEADER'|translate}} </span>
        </ng-template>
        <ng-container *ngIf="chargerToForm">
          <app-shipowners [charger]="chargerToForm" [enabled]="this.form.enabled"></app-shipowners>
        </ng-container>
      </p-tabPanel>
      <p-tabPanel *ngIf="chargerToForm">
        <ng-template pTemplate="header">
          <span
            [escape]="false"
            [pTooltip]="'TOOLTIP.CLAUSES_CLAUSESLIST' | translate"
            tooltipPosition="top"
            tooltipStyleClass="tooltipCLH"
            [showDelay]="1000"
            [hideDelay]="1000"
          >
            {{'CLAUSES_MAINTENANCE.HEADER'|translate}}
          </span>
        </ng-template>
        <ng-container>
          <app-clauses *ngIf="loadedClauses" [charger]="chargerToForm" [enabled]="this.form.enabled"> </app-clauses>
        </ng-container>
      </p-tabPanel>
      <p-tabPanel>
        <ng-template pTemplate="header">
          <span
            [escape]="false"
            [pTooltip]="'TOOLTIP.ROUTES_ROUTESLIST' | translate"
            tooltipPosition="top"
            tooltipStyleClass="tooltipCLH"
            [showDelay]="1000"
            [hideDelay]="1000"
            >{{'ROUTES.HEADER'|translate}}</span
          >
        </ng-template>
        <ng-container *ngIf="chargerToForm">
          <app-routes [charger]="chargerToForm"></app-routes>
        </ng-container>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
