import { NegotiationFieldState } from "../offers/negotiation-field-state.model";
import { OfferShipOwner } from "../offers/offer-shipowner.model";
import { UserType } from "../enum/user-type.model";
import { OfferShipownerLineHistory } from "./offer-shipowner-line-history.model";

export class OfferShipownerLine {
  id?: string;
  label?: string;
  value?: string;
  indicationDate?: Date;
  ownerType?: UserType; //propietario de las lineas
  userType?: UserType; //para identificar quien hace los cambios
  offerShipOwnerId?: string;
  offerShipOwner?: OfferShipOwner;
  histories?: OfferShipownerLineHistory[];
  state?: NegotiationFieldState;
  isRefused?: boolean;
  loadingPorts?: string[];
  dischargingPorts?: string[];

  constructor(obj?: Partial<OfferShipownerLine>) {
    this.id = (obj && obj.id) || undefined;
    this.label = (obj && obj.label) || undefined;
    this.value = (obj && obj.value) || undefined;
    this.indicationDate = (obj && obj.indicationDate) || new Date();
    this.offerShipOwnerId = (obj && obj.offerShipOwnerId) || undefined;
    this.offerShipOwner = (obj && obj.offerShipOwner) || undefined;
    this.histories = (obj && obj.histories) || [];
  }
}
