<p-confirmDialog styleClass="containerConfirmDialog" acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"></p-confirmDialog>
<ngx-spinner></ngx-spinner>
<p-toast></p-toast>
<div class="p-3 d-flex flex-row chargerShipownerContainer">
  <div class="col-3 containerLeftList" [pTooltip]="'TOOLTIP.NAME_OF_LIST_SHIPOWNERSLIST'| translate" [escape]="false"
    tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" [showDelay]="1000" [hideDelay]="1000">
    <div class="d-flex flex-column">
      <!-- <div class="list-indication">{{'SHIPOWNER_LIST.INDICATIONS' | translate}}</div> -->

      <div class="col-12 p-2 d-flex justify-content-between align-items-center" *ngIf="!readonly">
        <!-- <input class="w-100" pInputText type="text" [(ngModel)]="listName"
          [placeholder]="listName=='' ? ('SHIPOWNER_DATA.NAME_LIST' | translate ): '' " [escape]="false"
          [pTooltip]="'TOOLTIP.NAME_OF_LIST_SHIPOWNERSLIST'| translate" tooltipPosition="bottom"
          tooltipStyleClass="tooltipCLH" [showDelay]="1000" [hideDelay]="1000" />
        <button *ngIf="!readonly" pButton pRipple type="button" icon="pi pi-plus"
          class="p-button-rounded addButtonRounded" [pTooltip]="'ACTION.ADD'| translate" tooltipPosition="bottom"
          (click)="addList()" tooltipStyleClass="tooltipCLH" [disabled]="listName == ''"></button> -->
        <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded addButtonRounded"
          [disabled]="listName" (click)="showListDialog()" *ngIf="!readonly">{{'SHIPOWNER_DATA.NEW_LIST' |
          translate}}</button>
      </div>
      <div class="col-12 mt-2 left-data-list" [formGroup]="formFilter">
        <div class="ml-2">
          <h6>{{'SHIPOWNER_DATA.LIST' | translate}}</h6>
        </div>
        <input pInputText formControlName="filter" type="text" #inputsearch class="input-search"
          placeholder="{{ 'COMMONS.SEARCH_PLACEHOLDER' | translate }}" class="w-75 ml-2" />
        <div class="div-lists mt-2" #listsContainer>
          <div class="mt-2 list-item" *ngFor="let list of (filteredLists)" pDroppable="contacts"
            [class.droppable]="list.name != 'TODOS'" (onDrop)="drop($event, list)">
            <h6 class="contacts-list-header">
              <div class="d-flex flex-row align-items-center">
                <button type="button" pButton pRipple (click)="list.expanded = !list.expanded"
                  class="p-button-text p-button-rounded p-button-plain button-expand"
                  [icon]="'pi pi-chevron-down'"></button>
                <span *ngIf="!list.editable" class="list-name">
                  {{ (list.name === 'TODOS' ? ('COMMONS.ALL' | translate) : list.name) + ' (' + list.counterContacts +
                  ')' }}</span>
                <input *ngIf="list.editable" pInputText type="text" formControlName="listName" size="150" placeholder=""
                  class="inputMaintenanceShipowner w-100" />
                <button type="button" pButton pRipple *ngIf="!list.editable && !list.isListOfAll && !readonly"
                  (click)="setEditableList(list)" class="p-button-text p-button-rounded p-button-plain buttonLightBlue"
                  [icon]="'pi pi-pencil'"></button>
                <button type="button" pButton pRipple *ngIf="list.editable && !readonly" (click)="list.editable = false"
                  class="p-button-text p-button-rounded p-button-plain" [icon]="'pi pi-times'"></button>
                <button type="button" pButton pRipple *ngIf="!list.editable && !list.isListOfAll && !readonly"
                  (click)="deleteList(list)" class="p-button-text p-button-rounded p-button-plain buttonRed"
                  [icon]="'pi pi-trash'"></button>
                <button type="button" pButton pRipple *ngIf="list.editable && !readonly" (click)="editList(list)"
                  class="p-button-text p-button-rounded p-button-plain buttonLightBlue" [icon]="'pi pi-save'"
                  [disabled]="!formFilter.get('listName').value"></button>
              </div>
            </h6>
            <div class="contacts-list-body" *ngIf="list.expanded">
              <div *ngFor="let shipowner of (list.filteredShipOwners)" class="div-shipowners-list">
                <div class="d-flex align-items-center shipowner-name-title">
                  <i class="pi pi-circle-fill icon-list"></i>
                  <div class="shipowner-name-list d-flex align-items-center px-2">
                    {{shipowner.charteringCompany}}
                    <button type="button" pButton pRipple (click)="deleteShipownerList(list, shipowner)"
                      class="p-button-text p-button-rounded p-button-plain buttonRed" [icon]="'pi pi-trash'"
                      *ngIf="!list.isListOfAll && !readonly"></button>
                  </div>
                </div>

                <div class="d-flex align-items-center px-5 contact-name"
                  *ngFor="let contact of (shipowner.filteredContacts)">
                  {{contact.email}}
                  <button type="button" pButton pRipple (click)="deleteContactList(contact, list)"
                    class="p-button-text p-button-rounded p-button-plain buttonRed" [icon]="'pi pi-trash'"
                    *ngIf="!list.isListOfAll && !readonly"></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-9 containerTabviewCharger">
    <p-table *ngIf="listShipowners.length>=0" [columns]="columns" [value]="listShipowners" dataKey="id"
      styleClass="p-datatable-striped listTableChargerShipowner clh-table-grouped" responsiveLayout="stack"
      #tableShipowners [globalFilterFields]="['charteringCompany','dischargeDate','comment']" [responsive]="true"
      [autoLayout]="true" [breakpoint]="'959px'" [paginator]="true" [rows]="10"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
      [rowsPerPageOptions]="[10,30,50]" [showCurrentPageReport]="true" paginatorDropdownAppendTo="body">
      <ng-template pTemplate="caption">
        <div class="table-header">
          <div class="d-flex flex-row">
            <div class="w-100">
              <span class="p-input-icon-right" style="width: 100%">
                <i class="pi pi-search"></i>
                <input style="margin-right: 1em" [(ngModel)]="filteredTextShipowners" class="w-100" pInputText
                  type="text" (input)="filterData($event)" />
              </span>
            </div>
            <div style="margin-left: 3em" *ngIf="form">
              <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded addButtonRounded"
                [pTooltip]="'ACTION.ADD'| translate" tooltipPosition="bottom" tooltipStyleClass="tooltipCLH"
                (click)="addNewShipowner()" *ngIf="!isNewShipowner && !isEditingShipowner && !readonly"></button>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th></th>
          <th [pTooltip]="col.tooltip | translate" tooltipPosition="top" tooltipStyleClass="tooltipCLH"
            [ngClass]="col.key + ' font-weight-bold header'" *ngFor="let col of columns" [showDelay]="1000"
            [hideDelay]="1000">
            {{col.id | translate}}
          </th>
        </tr>

        <tr>
          &nbsp;
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-shipowner let-expanded="expanded">
        <ng-container *ngIf="shipowner.isNew else other">
          <tr *ngIf="isNewShipowner" [formGroup]="form">
            <td>
              <button type="button" pButton pRipple [pRowToggler]="shipowner"
                class="p-button-text p-button-rounded p-button-plain"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <td colspan="2">
              <span class="p-float-label">
                <p-autoComplete id="float-inputcharteringCompany" formControlName="charteringCompany" appendTo="body"
                  [suggestions]="listAllShipownersNames" (completeMethod)="filterChartering($event)" [minLength]="1"
                  [dropdown]="true" styleClass="listAutocomplete"
                  panelStyleClass="panelListAutocomplete"></p-autoComplete>

                <label for="float-inputcharteringCompany">{{'SHIPOWNER_DATA.SHIPOWNER_FORM.HEAD_COL0' |
                  translate}}</label>
              </span>
              <div
                *ngIf="form.get('charteringCompany').touched  && !form.get('charteringCompany').value  && form.get('charteringCompany').hasError('required')"
                class="error-label labelError">
                {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
              </div>
            </td>
            <!--<td>-->
            <!-- <p-calendar dateFormat="dd/mm/yy" formControlName="dischargeDate"
                                [disabled]="isNewShipowner" inputId="range" appendTo="body"
                                styleClass="inputMaintenanceShipowner"></p-calendar> -->
            <!--</td>-->
            <td>
              <p-checkbox formControlName="isSelected" [binary]="true" inputId="isSelected"
                class="checkShipownerCharger"> </p-checkbox>
              <label for="isSelected">{{'SHIPOWNER_DATA.SHIPOWNER_FORM.HEAD_COL2' | translate}}</label>
            </td>

            <td>
              <!-- <span class="p-float-label"> -->
              <input pInputText id="float-inputcomment" type="text" formControlName="comment" size="150" placeholder=""
                class="inputMaintenanceShipowner w-100" />
              <!-- <label for="float-inputcomment">{{'SHIPOWNER_DATA.SHIPOWNER_FORM.HEAD_COL3' | translate}}</label>
              </span> -->
            </td>
            <td></td>
            <td>
              <div class="d-flex flex-row">
                <button pButton pRipple type="button" icon="pi pi-save" *ngIf="shipowner.isNew"
                  class="p-button-rounded saveButtonRounded" [pTooltip]="'ACTION.SAVE'| translate"
                  tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" (click)="saveNewShipowner()"
                  [disabled]="!form.get('charteringCompany').valid"></button>
                <button pButton pRipple type="button" icon="pi pi-times" *ngIf="shipowner.isNew"
                  [pTooltip]="'ACTION.CANCEL'| translate" tooltipPosition="bottom" tooltipStyleClass="tooltipCLH"
                  (click)="cancelNewShipowner()" class="p-button-rounded cancelButtonRounded"></button>
              </div>
            </td>
          </tr>
        </ng-container>
        <ng-template #other>
          <tr class="campo">
            <td>
              <button type="button" pButton pRipple [pRowToggler]="shipowner"
                class="p-button-text p-button-rounded p-button-plain"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <ng-container *ngFor="let col of columns">
              <td class="{{col.sort}}" *ngIf="col.sort === 'check' "></td>
              <td class="{{col.sort}}" *ngIf="col.sort === 'charteringCompany' ">{{shipowner[col.sort]}}</td>
              <td class="{{col.sort}}" *ngIf="col.sort === 'dischargeDate' ">{{shipowner['createdDate'] | date:
                'dd/MM/yy'}}</td>
              <td class="{{col.sort}} selectedcheck" *ngIf="col.sort === 'isSelected' ">
                <div class="field-checkbox">
                  <p-checkbox [(ngModel)]="shipowner.isSelected" [binary]="true" inputId="checkedDefault"
                    class="checkShipownerCharger" [readonly]="!shipowner.isEdit">
                  </p-checkbox>
                  <!-- <label for="checkedDefault">{{'SHIPOWNER_DATA.SHIPOWNER_FORM.HEAD_COL2' | translate}}</label> -->
                </div>
              </td>
              <td class="{{col.sort}}" *ngIf="col.sort === 'comment' ">
                <!-- <span class="p-float-label"> -->
                <input id="float-input" type="text" pInputText [(ngModel)]="shipowner.comment" style="width: 100%"
                  [readonly]="!shipowner.isEdit" />
                <!-- <label for="float-input">{{'SHIPOWNER_DATA.SHIPOWNER_FORM.HEAD_COL3' | translate}}</label>
                </span> -->
              </td>
              <td class="{{col.sort}}" *ngIf="col.sort === 'counterContacts' ">{{shipowner[col.sort]}}</td>
              <td class="{{col.sort}}" *ngIf="col.sort === 'modify' ">
                <div class="d-flex flex-row" *ngIf="!isNewShipowner && !isEdittingContacts">
                  <button pButton pRipple type="button" icon="pi pi-pencil"
                    *ngIf="!shipowner.isEdit && !isEditingShipowner" class="p-button-rounded editButtonRounded"
                    [pTooltip]="'ACTION.EDIT'| translate" tooltipPosition="bottom" tooltipStyleClass="tooltipCLH"
                    (click)="editShipowner(shipowner)"></button>
                  <button pButton pRipple type="button" icon="pi pi-trash"
                    *ngIf="!shipowner.isEdit && !isEditingShipowner && !readonly"
                    class="p-button-rounded deleteButtonRounded" [pTooltip]="'ACTION.DELETE'| translate"
                    tooltipPosition="bottom" tooltipStyleClass="tooltipCLH"
                    (click)="deleteShipowner(shipowner)"></button>
                  <button pButton pRipple type="button" icon="pi pi-user-plus"
                    *ngIf="!shipowner.isEdit && !isEditingShipowner  && !readonly"
                    class="p-button-rounded inviteButtonRounded" [pTooltip]="'ACTION.ADD_CONTACT'| translate"
                    tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" (click)="addNewContact(shipowner)"></button>
                  <button pButton pRipple type="button" icon="pi pi-save" *ngIf="shipowner.isEdit"
                    class="p-button-rounded saveButtonRounded" [pTooltip]="'ACTION.SAVE'| translate"
                    tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" (click)="saveShipowner(shipowner)"></button>
                  <button pButton pRipple type="button" icon="pi pi-times" *ngIf="shipowner.isEdit"
                    [pTooltip]="'ACTION.CANCEL'| translate" tooltipPosition="bottom" tooltipStyleClass="tooltipCLH"
                    (click)="cancelShipowner(shipowner)" class="p-button-rounded cancelButtonRounded"></button>
                </div>
              </td>
            </ng-container>
          </tr>
        </ng-template>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-shipowner>
        <tr>
          <td colspan="7" class="inner-background-expanded">
            <div class="p-3">
              <p-table [value]="shipowner.chargerShipOwnerContacts" dataKey="email" styleClass="datatable-expansion"
                [globalFilterFields]="['email','name','phone', 'lastName']">
                <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="name">{{'CONTACTS.FORM.NAME'| translate}} <p-sortIcon field="name"></p-sortIcon></th>
          <th pSortableColumn="lastName">{{'USERS.FORM.LAST_NAME'| translate }} <p-sortIcon
              field="lastName"></p-sortIcon></th>
          <th pSortableColumn="email">{{'CONTACTS.FORM.EMAIL'| translate }} <p-sortIcon field="email"></p-sortIcon></th>

          <th pSortableColumn="phone">{{'CONTACTS.FORM.PHONE'| translate}} <p-sortIcon field="phone"></p-sortIcon></th>
          <th pSortableColumn="comment">
            {{'CHARTERER_DATA_SHEET.MAINTENANCE_OWNERS.COMMENT' | translate}} <p-sortIcon field="amount"></p-sortIcon>
          </th>
          <th colspan="2" *ngIf="!readonly">{{'SHIPOWNER_DATA.SHIPOWNER_FORM.HEAD_COL5' | translate}}</th>
          <!-- <th ></th> -->
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-contact>
        <tr *ngIf="!contact.editable" pDraggable="contacts" (onDragStart)="dragStart($event, contact)"
          [pTooltip]="'TOOLTIP.SHIPOWNER_DRAG' | translate" [class.dragging]="contact.isDragging"
          tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" (onDragEnd)="dragEnd($event, contact)"
          [showDelay]="1000" class="example-list">
          <td>
            {{contact.name}}</td>
          <td>{{contact.lastName}}</td>
          <td>{{contact.email}}</td>
          <td>{{contact.phone}}</td>
          <td>{{contact.comment}}</td>

          <td *ngIf="!isEdittingContacts && !readonly">
            <button type="button" icon="pi pi-pencil" pButton pRipple class="p-button-rounded editButtonRounded"
              [pTooltip]="'ACTION.EDIT'| translate" tooltipPosition="bottom" tooltipStyleClass="tooltipCLH"
              (click)="setEditableContact(contact)"></button>
          </td>
          <td *ngIf="!isEdittingContacts && !readonly">
            <button type="button" icon="pi pi-trash" pButton pRipple class="p-button-rounded deleteButtonRounded"
              [pTooltip]="'ACTION.DELETE'| translate" tooltipPosition="bottom" tooltipStyleClass="tooltipCLH"
              (click)="deleteContact(contact, shipowner)"></button>
          </td>
        </tr>
        <tr *ngIf="contact.editable" [formGroup]="formContacts">
          <td><input id="float-input" type="text" pInputText formControlName="name" style="width: 100%" /></td>
          <td><input id="float-input" type="text" pInputText formControlName="lastName" style="width: 100%" /></td>
          <td *ngIf="contact.id">{{contact.email}}</td>
          <td *ngIf="!contact.id"><input id="float-input" type="text" pInputText formControlName="email"
              style="width: 100%" /></td>
          <td><input id="float-input" type="text" pInputText formControlName="phone" style="width: 100%" /></td>

          <td><input id="float-input" type="text" pInputText formControlName="comment" style="width: 100%" /></td>
          <td>
            <button pButton pRipple *ngIf="contact.editable" [disabled]="!(formContacts?.valid)" type="button"
              icon="pi pi-save" class="p-button-rounded saveButtonRounded" (click)="saveContact(shipowner, contact)"
              [pTooltip]="'ACTION.SAVE'| translate" tooltipPosition="bottom" tooltipStyleClass="tooltipCLH"></button>
          </td>
          <td>
            <button pButton pRipple *ngIf="contact.editable" type="button" icon="pi pi-times"
              class="p-button-rounded cancelButtonRounded" (click)="cancelEditContact(contact, shipowner)"
              [pTooltip]="'ACTION.CANCEL'| translate" tooltipPosition="bottom" tooltipStyleClass="tooltipCLH"></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="6"></td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  </td>
  </tr>
  </ng-template>
  </p-table>
</div>
</div>