import { AddresseeType } from "../enum/addresseeType.model";

export class MailDTO {
  id?: string;
  subject?: string;
  body?: string;
  templateName?: string;

  offerShipOwnerId: string;
  addresseeType: AddresseeType;
  cLHUsers: string[];
}
