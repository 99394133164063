import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { OveraMessagesService } from "@overa/components/info-dialog";
import { NgxTranslateService } from "@overa/shared";
import { ToastrService } from "ngx-toastr";
import { catchError, Observable, throwError } from "rxjs";

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {


  constructor(private messageService: ToastrService, private translate: NgxTranslateService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // if (!this.messageService) {
    //   this.messageService = this.injector.get(OveraMessagesService);
    // }
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = "";
        if (error.status === 409) {
          errorMessage = this.translate.get(error.error);
          this.messageService.show("ERROR", errorMessage, null, 'error');
        }
        return throwError(() => error);
      })
    );
  }
}
