import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, ReplaySubject } from "rxjs";
import { ConfigsService } from "@overa/shared";
import { Shipowner } from "../models/shipowner/shipowner.model";
import { OfferShipOwner } from "../models/offers/offer-shipowner.model";
import { LanguageCode } from "../models/enum/language-code.model";
import { Country } from "../models/country/country.model";
import { OfferFilter } from "../models/searcher/offer-filter.model";
import { CLHBaseService } from "./clh-base.service";

@Injectable({ providedIn: "root" })
export class ShipownerService extends CLHBaseService {
  private shipownerSubject: ReplaySubject<boolean> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public shipownerObservable: Observable<boolean> =
    this.shipownerSubject.asObservable();

  private routeShipownerSubject: ReplaySubject<string> = new ReplaySubject(1);
  public routeShipownerObservable: Observable<string> =
    this.routeShipownerSubject.asObservable();

  get(pageIndex = -1, pageSize = -1) {
    return this.http
      .get<Shipowner[]>(
        `${this.baseUrl}/shipowner?pageIndex=${pageIndex}&pageSize=${pageSize}`
      )
      .pipe(
        map((res: Shipowner[]) => {
          res.forEach(
            (item) =>
              (item.dischargeDate = new Date(item.dischargeDate || new Date()))
          );
          return res;
        })
      );
  }
  getVerified() {
    return this.http
      .get<Shipowner[]>(`${this.baseUrl}/shipowner/verified`)
      .pipe(
        map((res: Shipowner[]) => {
          res.forEach(
            (item) =>
              (item.dischargeDate = new Date(item.dischargeDate || new Date()))
          );
          return res;
        })
      );
  }

  getById(id: string) {
    return this.http.get<any>(`${this.baseUrl}/shipowner/${id}`).pipe(
      map((item: any) => {
        item.dischargeDate = new Date(item.dischargeDate || new Date());
        return item;
      })
    );
  }
  getByIdAnn(id: string) {
    return this.http
      .get<any>(`${this.baseUrl}/shipowner/register?shipOwnerId=${id}`)
      .pipe(
        map((item: any) => {
          item.dischargeDate = new Date(item.dischargeDate || new Date());
          return item;
        })
      );
  }

  add(shipowner: Shipowner) {
    const endpointUrl = `${this.baseUrl}/shipowner`;
    return this.http.post<Shipowner>(endpointUrl, shipowner);
  }

  edit(shipowner: Shipowner) {
    const endpointUrl = `${this.baseUrl}/shipowner`;
    return this.http.put<Shipowner>(endpointUrl, shipowner);
  }

  delete(id: string) {
    const endpointUrl = `${this.baseUrl}/shipowner/${id}`;
    return this.http.delete(endpointUrl);
  }

  getShipOwnerByUserId(id: string): Observable<Shipowner> {
    const endpointUrl = `${this.baseUrl}/ShipOwnerContact/getByUser/${id}`;

    return this.http.get<Shipowner>(endpointUrl);
  }

  getOffershipownersByUserId(id: string): Observable<OfferShipOwner[]> {
    const endpointUrl = `${this.baseUrl}/shipowner/${id}/offers`;
    return this.http.get<OfferShipOwner[]>(endpointUrl);
  }
  getOffersByShipownerId(
    id: string,
    searchString: OfferFilter
  ): Observable<OfferShipOwner[]> {
    const endpointUrl = `${this.baseUrl}/shipowner/${id}/offers`;
    return this.http.post<OfferShipOwner[]>(endpointUrl, searchString);
  }

  checkUserAvailability(userId: string): Observable<boolean> {
    const endpointUrl = `${this.baseUrl}/shipowneruser/${userId}/checkAvailability`;
    return this.http.get<boolean>(endpointUrl);
  }
  getCountriesByCode(code: LanguageCode) {
    return this.http.get<Country[]>(
      `${this.baseUrl}/country/getByCode/${code}`
    );
  }
  GetEmailPrincipalContact(id: string): Observable<string> {
    const endpointUrl = `${this.baseUrl}/shipowner/emailPrincipal`;
    return this.http.get<string>(endpointUrl, {
      params: { shipOwnerId: id }
    });
  }
}
