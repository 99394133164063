import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { TermsAndConditions } from 'src/app/models/clause/terms-and-conditions.model';
import { TermsAndConditionsService } from 'src/app/services/terms-and-conditions.service';

@Component({
  selector: 'app-terms-conditions-view-simple',
  templateUrl: './terms-conditions-view-simple.component.html',
  styleUrls: ['./terms-conditions-view-simple.component.scss']
})
export class TermsConditionsViewSimpleComponent implements OnInit {

  languageNavigator: string;
  termsConditions: TermsAndConditions;
  termsConditionsLanguage:string;
  termsConditionsLanguageProcessed:string;
  constructor( 
    private termsConditionsService:TermsAndConditionsService,
    private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.languageNavigator=navigator.language;
    this.spinner.show('termsConditionsSpinner');
    this.termsConditionsService.getLast().subscribe({
      next: (value) => {
        if (value) {
          this.termsConditions = value;
          if (this.languageNavigator.includes('es')) {
            this.termsConditionsLanguage=this.termsConditions.terms;
          } else {
            this.termsConditionsLanguage=this.termsConditions.termsEN;  
          }
          this.spinner.hide('termsConditionsSpinner');
          //reemplaza /n por salto de linea 
          this.termsConditionsLanguageProcessed = this.termsConditionsLanguage.replace(/\n/g, '<br>');
          // Pone las mayúsculas en negrita
          this.termsConditionsLanguageProcessed = this.termsConditionsLanguageProcessed.replace(/([A-ZÁÉÍÓÚÜ])/g, '<strong>$1</strong>');
          console.log(this.termsConditions,this.termsConditionsLanguage);
        } else {
          this.spinner.hide('termsConditionsSpinner');
        }
      },
      error: (err) => {
        console.error(err);
        this.spinner.hide('termsConditionsSpinner');
      },
    });
   
  }

}
