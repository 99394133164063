import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AccountService } from "@overa/security";
import { ChargerService } from "src/app/services/charger.service";
import { CLHIdentityService } from "src/app/services/identity.service";
import { OfferService } from "src/app/services/offer.service";
import { ShipownerService } from "src/app/services/shipowner.service";
import { CLHGlobals } from "src/app/utils/globals";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  selectedKanban: any;
  markers: any[] = [];
  imos: number[] = [];
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private globals: CLHGlobals,
    private accountService: AccountService,
    private chargerService: ChargerService,
    private identityService: CLHIdentityService,
    private shipOwnerService: ShipownerService
  ) {}

  ngOnInit() {}
  onSelectKanban(element: any) {
    console.log(this.selectedKanban);
    this.selectedKanban = { ...element };
    var markers: any[] = [];
    var offerShipownerId = element.offerShipOwnerId;
    if (!offerShipownerId) {
    }
    console.log(offerShipownerId);
    const locationIcon = "assets/icons/location.svg";
    const locationIconDischarging = "assets/icons/location-orange.svg";

    if (element.offer.loadingPorts != null) {
      element.offer.loadingPorts.forEach((port: any) => {
        var markerCat = this.globals.catalogue.find(
          (x) => x.value.trim() == port.trim()
        );
        if (markerCat) {
          const marker = {
            // The below line is equivalent to writing:
            // position: new google.maps.LatLng(-34.397, 150.644)
            position: { lat: markerCat.latitude, lng: markerCat.longitude },
            options: {
              border: "",
              clickable: true,
              icon: locationIcon,
              strokeColor: "blue",
              glyphColor: "blue",
            },
          };
          markers.push(marker);
        }
      });
    }
    if (element.offer.dischargingPorts != null) {
      element.offer.dischargingPorts.forEach((port: any) => {
        var markerCat = this.globals.catalogue.find(
          (x) => x.value.trim() == port.trim()
        );
        if (markerCat) {
          const marker = {
            // The below line is equivalent to writing:
            // position: new google.maps.LatLng(-34.397, 150.644)
            position: { lat: markerCat.latitude, lng: markerCat.longitude },
            options: {
              label: "",
              icon: locationIconDischarging,
            },
          };
          markers.push(marker);
        }
      });
    }
    this.markers = markers;

    window.scrollTo(0, 0);
  }
  unSelectKanban(element: any) {
    this.selectedKanban = null;
    this.selectedKanban = { ...this.selectedKanban };
    this.markers = [];
  }
}
