import { HttpClient } from "@angular/common/http";
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from "@microsoft/signalr";

import { CLHGlobals } from "src/app/utils/globals";
import { ChatParticipant } from "../models/chat/chatParticipant.model";
import { ChatGroup } from "src/app/models/chat/chatGroup.model";
import { ChatMessage } from "src/app/models/chat/chatMessage.model";
import { AccountService } from "@overa/security";
import { Constants } from "../utils/constants";
import { ChatMessageService } from "./chatMessage.service";
import { CLHBaseService } from "./clh-base.service";
import { inject } from "@angular/core";
import { ConfigsService } from "@overa/shared";

export class SignalRGroupAdapterService {
  public connectionId?: string;
  private hubConnection!: HubConnection;

  hubName: string = "groupchat";
  isCLH: boolean = false;

  get baseUrl() {
    // return this.config.getModuleConfig("overa-security", "apiBaseUrl") + "/api/";
    return this.config.getModuleConfig("overa-security", "apiBaseUrl").replace('/v1', '/');
  }
  constructor(
    private offerShipOwnerId: string,
    protected globals: CLHGlobals,
    protected chatMessageService: ChatMessageService,
    private group: ChatGroup,
    private currentParticipant: ChatParticipant,
    public messages: ChatMessage[],
    private accountService: AccountService,
    private config: ConfigsService
  ) {
    this.initializeConnection();
    if (
      this.accountService.currentUser.roles
        .map((x) => x.name)
        .includes("CLH Admin")
    ) {
      this.isCLH = true;
    }
  }
  //**********INIT ADAPTER AND CHAT ROOM***************//

  private initializeConnection(): void {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(`${this.baseUrl + this.hubName}`, {
        transport: HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect([0, 2000, 10000, 30000, 60000, 90000])
      .build();
    this.hubConnection
      .start()
      .then(async () => {
        this.initializeListeners();
        this.groupCreated(this.group);
        this.joinRoom();
      })
      .catch((err) =>
        console.log(`Error while starting SignalR connection: ${err}`)
      );
  }

  private initializeListeners(): void {
    this.hubConnection.onreconnected(async () => {
      this.groupCreated(this.group);
      this.joinRoom();
    });

    this.hubConnection.on(
      Constants.CHAT_METHODS.GENERATED_CHAT_PARTICIPANT,
      (connectionId: string) => {
        this.connectionId = connectionId;
        this.currentParticipant.connectionId = connectionId;
      }
    );

    this.hubConnection.on(
      Constants.CHAT_METHODS.MESSAGE_RECEIVED,
      (message: ChatMessage) => {
        this.setReplyBasedOnCurrentUser(message);
        if (message.groupId == this.group.id) {
          console.log("chatMessageReceived", message);
          this.messages.push(message);
        }
      }
    );
  }
  //**********CODE LOGIC***************//
  setReplyBasedOnCurrentUser(s: ChatMessage): any {
    const isCharger: boolean =
      this.globals.currentCharger != null ? true : false;
    if (this.isCLH) {
      if (s.isCLH) {
        s.reply = true;
      } else {
        s.reply = false;
      }
    } else {
      if (isCharger) {
        if (s.isCharger) {
          s.reply = true;
        } else {
          s.reply = false;
        }
      } else {
        if (s.isCLH || s.isCharger) {
          s.reply = false;
        } else {
          s.reply = true;
        }
      }
    }
    return s;
  }
  //**********HUBS ACTIONS***************//
  closeConnection(): void {
    if (this.hubConnection) {
      this.hubConnection.stop();
    }
  }

  joinRoom(): void {
    if (
      this.hubConnection &&
      this.hubConnection.state == HubConnectionState.Connected
    ) {
      this.hubConnection.send(
        Constants.CHAT_METHODS.JOIN,
        this.currentParticipant,
        this.offerShipOwnerId
      );
    }
  }

  sendMessage(message: ChatMessage): void {
    console.log(message);
    // si la conexion esta cerrada forzamos la inicializacion
    // esto sucede cuando el Hub deja de responder durante mas de 90 segundos
    // al reiniciar la conexion el usuario podra enviar mensajes pero el destinatario no los recibira
    // hasta que responda o actualiza la pantalla.
    if (
      !(
        this.hubConnection &&
        this.hubConnection.state == HubConnectionState.Connected
      )
    ) {
      this.hubConnection.stop();
      this.initializeConnection();
    }
    this.messages.push(message);
    this.hubConnection.send(Constants.CHAT_METHODS.SEND_MESSAGE, message);
    this.chatMessageService.add(message).subscribe();
  }

  groupCreated(group: ChatGroup): void {
    this.hubConnection.send(Constants.CHAT_METHODS.GROUP_CREATED, group);
  }
}
