<div class="comments-dialog">
  <form [formGroup]="formComment" *ngIf="formComment">
    <!-- <p-editor size="150" placeholder="" formControlName="comment" [ngClass]="isreadonly ? 'editorCommentReadOnly' : 'editorComment'"
            [readonly]="isreadonly"></p-editor> -->
    <clh-textarea
      [form]="formComment"
      [nameControl]="'comment'"
      [errorLabel]="''"
      [idTextarea]="'step2commentsEmail'"
      [placeholder]=""
      [readOnly]="isreadonly"
      [classTextarea]="'textAreaCommentEmail clhTextarea'"
      [style]="'width:100%; min-height:300px;'"
      [label]="'STEP1_OFFER.ADDITIONAL_COMMENTS'"
      [minHeight]="300"
      [showLabelFloat]="false"
      [required]="false"
    ></clh-textarea>
    <button
      pButton
      pRipple
      type="button"
      icon="pi pi-save"
      class="p-button-rounded saveButtonRounded"
      [pTooltip]="'ACTION.SAVE'| translate"
      tooltipPosition="bottom"
      tooltipStyleClass="tooltipCLH"
      (click)="save()"
      [disabled]="isreadonly"
    ></button>
  </form>
</div>
