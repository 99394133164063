import { Component, Input, OnInit } from '@angular/core';
import { NegotiationOfferShipOwner } from 'src/app/models/offers/negotiation-offer-shipowner.model';
import { FreightTargetCurrency } from 'src/app/models/enum/freight-target-currency.model';
import { FreightTargetType } from 'src/app/models/enum/freight-target-type.model';
import { DemurrageType } from 'src/app/models/enum/demurrage-type.model';
import { QuantityType } from 'src/app/models/enum/quantity-type.model';
@Component({
  selector: 'app-table-offershipowner',
  templateUrl: './table-offershipowner.component.html',
  styleUrls: ['./table-offershipowner.component.scss'],
})
export class TableOffershipownerComponent implements OnInit {
  @Input() offerBinding: NegotiationOfferShipOwner;
  @Input() isScreenLarge: boolean;
  @Input() isClh: boolean;
  @Input() isShipowner: boolean;
  @Input() isCharterer: boolean;
  offers: any[] = [];
  showTable: boolean = false;
  columns: any[];
  columnsTitle: any[];
  countClausesCharger: number;
  readonly QuantityType = QuantityType;
  readonly FreightTargetCurrency = FreightTargetCurrency;
  readonly FreightTargetType = FreightTargetType;
  readonly DemurrageType = DemurrageType;
  constructor() {}

  ngOnInit() {
    console.log(this.offerBinding, this.isScreenLarge);
    this.countClausesCharger = this.offerBinding.chargerClauses.length;
    this.offerBinding.shipOwnerRecapDate = new Date(this.offerBinding.shipOwnerRecapDate);

    // this.offerBinding.shipOwnerRecapDate = new Date(
    //   this.offerBinding.shipOwnerRecapDate.getUTCFullYear(),
    //   this.offerBinding.shipOwnerRecapDate.getUTCMonth(),
    //   this.offerBinding.shipOwnerRecapDate.getUTCDate(),
    //   this.offerBinding.shipOwnerRecapDate.getUTCHours(),
    //   this.offerBinding.shipOwnerRecapDate.getUTCMinutes(),
    //   this.offerBinding.shipOwnerRecapDate.getUTCSeconds()
    // );
    // this.offerBinding.chargerRecapDate = new Date(this.offerBinding.chargerRecapDate);
    // this.offerBinding.chargerRecapDate = new Date(
    //   this.offerBinding.chargerRecapDate.getUTCFullYear(),
    //   this.offerBinding.chargerRecapDate.getUTCMonth(),
    //   this.offerBinding.chargerRecapDate.getUTCDate(),
    //   this.offerBinding.chargerRecapDate.getUTCHours(),
    //   this.offerBinding.chargerRecapDate.getUTCMinutes(),
    //   this.offerBinding.chargerRecapDate.getUTCSeconds()
    // );
    this.offers.push(this.offerBinding);
    this.assignedColumnsAndFieldsToTable();
    this.showTable = true;
  }
  assignedColumnsAndFieldsToTable() {
    this.columns = [{ colName: 'TABLE.NUMBER' }, { colName: 'TABLE.REF_HEADER' }, { colName: '' }];
    this.columnsTitle = [
      // { field: 'STEP1_OFFER.TABLE.ROW.ROW_CODE', control: 'code', label: 'code' },
      { field: 'TABLE.NAME', control: 'chargerName', label: 'chargerName' },
      { field: 'TABLE.NAME_SHIPOWNER', control: 'shipOwnerName', label: 'shipOwnerName' },
      {
        field: 'quantity',
        moreFields: {
          quantity: 'TABLE.CARGO_QUANTITY',
          stowage: 'TABLE.STOWAGE',
          commodity: 'TABLE.COMODITY',
        },
        control: 'quantity',
        label: 'quantity',
        moreLabels: { quantity: 'quantity', quantityType: 'quantityType', stowage: 'stowage', commodity: 'commodity' },
        moreControls: { quantity: 'quantity', quantityType: 'quantityType', stowage: 'stowage', commodity: 'commodity' },
      },
      { field: 'TABLE.LOADING', control: 'loadingPorts', label: 'loadingPorts', required: true },
      {
        field: 'TABLE.LOADING_CONDITIONS',
        control: 'loadingPortConditions',
        label: 'loadingPortConditions',
      },
      { field: 'TABLE.DISCHARGING', control: 'dischargingPorts', label: 'dischargingPorts' },
      {
        field: 'TABLE.DISCHARGING_CONDITIONS',
        control: 'dischargingPortConditions',
        label: 'dischargingPortConditions',
      },
      { field: 'TABLE.LAYCAN', control: 'rangeDates', label: { fieldFrom: 'layCanFrom', fieldTo: 'layCanTo' } },
      {
        field: 'TABLE.CHARTERING',
        control: 'chargeringTerm',
        label: 'chargeringTerm',
      },
      {
        field: 'TABLE.LAYTIME',
        control: 'layTimeTerm',
        label: 'layTimeTerm',
      },
      {
        field: 'TABLE.CP',
        control: 'cpForm',
        label: 'cpForm',
      },
      {
        field: 'TABLE.RESTRICTIONS',
        control: 'restrictionsRegarding',
        label: 'restrictionsRegarding',
      },
      {
        field: 'TABLE.ADD_REQUIREMENTS',
        control: 'aditionalRequirements',
        label: 'aditionalRequirements',
      },
      {
        field: 'TABLE.FLETE',
        control: 'flete',
        label: 'flete',
        moreLabels: { currency: 'freightTargetCurrency', quantity: 'freightTargetQuantity', type: 'freightTargetType' },
        moreControls: { currency: 'freightTargetCurrency', quantity: 'freightTargetQuantity', type: 'freightTargetType' },
      },
      {
        field: 'TABLE.DEMURRAGE',
        control: 'demurrage',
        label: 'demurrage',
        moreLabels: { currency: 'demurrageCurrency', quantity: 'demurrageQuantity', type: 'demurrageType' },
        moreControls: { currency: 'demurrageCurrency', quantity: 'demurrageQuantity', type: 'demurrageType' },
      },
      {
        field: 'TABLE.DEMURRAGE_CONDITIONS',
        control: 'demurrageConditions',
        label: 'demurrageConditions',
      },
      {
        field: 'TABLE.VESSELNAME',
        control: 'vesselName',
        label: 'vesselName',
      },
      {
        field: 'TABLE.REMARKS',
        control: 'remarks',
        label: 'remarks',
      },
      {
        field: 'TABLE.ADDRESS_COM',
        control: 'addressComm',
        label: 'addressComm',
      },
    ];
    let fieldClausesCharterer = {
      field: 'TABLE.CLAUSES_CHARTERER',
      control: 'chargerClauses',
      label: 'chargerClauses',
    };
    let fieldClausesShipowner = {
      field: 'TABLE.CLAUSES_SHIPOWNER',
      control: 'shipOwnerClauses',
      label: 'shipOwnerClauses',
    };
    if (this.isCharterer || this.isClh) {
      let field = { field: 'TABLE.CODE', control: 'code', label: 'code' };
      this.columnsTitle.unshift(field);
      this.columnsTitle.push(fieldClausesCharterer, fieldClausesShipowner);
    }
    if (this.isShipowner) {
      //let field = { field: 'TABLE.ADDRESS_COM', control: 'addressComm', label: 'addressComm' };
      //this.columnsTitle.push(field);
      this.columnsTitle.push(fieldClausesCharterer, fieldClausesShipowner);
    }
  }
}
