import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Charger } from "src/app/models/charger/charger.model";
import { Route } from "src/app/models/charger/route.model";
import { CatalogueType } from "src/app/models/enum/catalogue-type";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ClhValidators } from "src/app/utils/validators";
import { ConfirmationService } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { RouteService } from "src/app/services/route.service";
import { PermissionsService } from "../../../services/permissions.service";
import { ActivatedRoute } from "@angular/router";
import { CLHGlobals } from "src/app/utils/globals";
import { NgxTranslateService } from "@overa/shared";
const COLUMNS_SCHEMA_ROUTE = [
  {
    key: "originPortsValues",
    type: "text",
    label: "ROUTES.FORM.LOADING_PORTS",
  },
  {
    key: "destinationPortsValues",
    type: "text",
    label: "ROUTES.FORM.DISCHARGING_PORTS",
  },
];
@Component({
  selector: "app-routes",
  templateUrl: "./routes.component.html",
  styleUrls: ["./routes.component.scss"],
  providers: [ConfirmationService],
})
export class RoutesComponent implements OnInit {
  @Input() charger!: Charger;
  readonly arrValidator = this.validators.portsValidator;

  // hiddenButtonAdd: boolean = false;
  selectedRoutesInitial: Route[] = [];
  // isNew: boolean = false;
  // openCell: boolean = false;
  isNewRoute: boolean = false;
  newRoute?: Route;
  isEditting: boolean = false;
  selectedRoutes: Route[] = [];
  selectedRoute?: Route;
  form: FormGroup = new FormGroup({
    loadingPorts: new FormControl([], [Validators.required, this.arrValidator]),
    dischargingPorts: new FormControl(
      [],
      [Validators.required, this.arrValidator]
    ),
  });
  loadedPorts = false;
  selectedLoadingPorts = [];
  selectedDischargingPorts = [];
  selectedLoadingPortsEditting: any[] = [];
  selectedDischargingPortsEditting: any[] = [];
  readonly = false;
  ports: string[] = [];
  portsD: string[] = [];
  allPorts: string[] = [];
  formTable: FormGroup = new FormGroup({
    loadingPorts: new FormControl([], [Validators.required, this.arrValidator]),
    dischargingPorts: new FormControl(
      [],
      [Validators.required, this.arrValidator]
    ),
  });
  columnsSchemaRoute: any = COLUMNS_SCHEMA_ROUTE;
  constructor(
    private globals: CLHGlobals,
    private confirmationService: ConfirmationService,
    private validators: ClhValidators,
    private translate: NgxTranslateService,
    private spinner: NgxSpinnerService,
    private permissionsService: PermissionsService,
    private activatedRoute: ActivatedRoute,
    private routeService: RouteService
  ) {}

  ngOnInit(): void {
    this.readonly = !this.permissionsService.hasPermissionToEdit(
      this.activatedRoute
    );
    this.allPorts = this.globals.catalogue
      .filter((x) => x.type == CatalogueType.PORT)
      .map((x) => x.value);
    this.ports = [...this.allPorts];
    this.portsD = [...this.ports];
    if (!this.charger) {
      this.charger = this.globals.currentCharger || new Charger();
    }
    if (this.charger.id) {
      this.routeService.getRoutesByChargerId(this.charger.id).subscribe({
        next: (routes) => {
          this.selectedRoutesInitial = [...routes];
          this.selectedRoutes = [...routes];
          this.loadedPorts = true;
        },
        error: (err) => {
          console.error(err);
        },
      });
    }
    //this.selectedRoutesInitial = this.charger?.routes;

    //if (this.selectedRoutes) {
    //  this.selectedRoutesInitial.forEach(item => {
    //    this.selectedRoutes.push(item);
    //  })
    //}
    //this.loadedPorts = true;
  }

  deleteRoute(route: Route) {
    let trmessage = this.translate.get("COMMONS.ASK_DELETE_RECORD");
    let messageAccept = this.translate.get("ACTION.YES");

    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.spinner.show("routeSpinner");
        this.routeService.deleteRoute(route.id || "").subscribe({
          next: () => {
            var index = this.selectedRoutes.indexOf(route);
            this.selectedRoutes.splice(index, 1);
            this.selectedRoutes = [...this.selectedRoutes];
            this.spinner.hide("routeSpinner");
          },
          error: (err) => {
            console.log("errorDeleteRoute", err);
            this.spinner.hide("routeSpinner");
          },
        });
      },
      reject: () => {},
    });
  }
  resetForm() {
    this.form.reset();
    this.ports = [...this.allPorts];
    this.portsD = [...this.ports];
    this.selectedLoadingPorts = [];
    this.selectedDischargingPorts = [];
  }

  selectedLoadingPortChange(event: string[]) {
    console.log(event);
    // this.chargerToForm.loadingPorts = event;
  }
  selectedDischargingPortChange(event: string[]) {
    console.log(event);
    // this.chargerToForm.dischargingPorts = event;
  }
  setEditable(route: Route) {
    this.isNewRoute = false;
    this.isEditting = true;
    route.editable = true;
    this.selectedLoadingPortsEditting = route.originPortsValues;
    this.selectedDischargingPortsEditting = route.destinationPortsValues;
    this.ports = this.allPorts.filter(
      (p) => !route.originPortsValues.includes(p)
    );
    this.portsD = this.allPorts.filter(
      (p) => !route.destinationPortsValues.includes(p)
    );
    this.formTable.setValue({
      loadingPorts: route.originPortsValues,
      dischargingPorts: route.destinationPortsValues,
    });
    this.selectedRoute = route;
  }
  cancelEdit(route: Route) {
    route.editable = false;
    this.resetFormTable();
    this.isEditting = this.isEditting ? false : false;
    if (this.isNewRoute) {
      this.newRoute = undefined;
      let indice = this.selectedRoutes.findIndex((r) => r.isNew === true);
      this.selectedRoutes.splice(indice, 1);
      this.isNewRoute = this.isNewRoute ? false : false;
    }
  }
  editRoute(route: Route) {
    console.log(route, this.selectedRoutes);
    if (this.isNewRoute) {
      let indice = this.selectedRoutes.findIndex((r) => r.isNew === true);
      this.selectedRoutes.splice(indice, 1);
      this.selectedRoutes = [...this.selectedRoutes];
      this.newRoute = undefined;
      var routeNew = new Route();
      routeNew.originPortsValues = this.form.get("loadingPorts")?.value;
      routeNew.destinationPortsValues =
        this.form.get("dischargingPorts")?.value;
      routeNew.editable = false;
      routeNew.chargerId = this.charger.id;
      this.spinner.show("routeSpinner");
      this.routeService.addRoute(routeNew).subscribe({
        next: (value) => {
          this.selectedRoutes.push(value);
          this.resetForm();
          this.isNewRoute = false;
          this.selectedRoutes = [...this.selectedRoutes];
          console.log(value, this.selectedRoutes);
          this.spinner.hide("routeSpinner");
        },
        error: (err) => {
          console.log("errorCreateRoute", err);
          this.spinner.hide("routeSpinner");
        },
      });
    } else {
      route.originPortsValues = this.formTable.get("loadingPorts")?.value;
      route.destinationPortsValues =
        this.formTable.get("dischargingPorts")?.value;
      route.editable = false;
      this.spinner.show("routeSpinner");
      this.routeService.editRoute(route).subscribe({
        next: (value) => {
          if (this.selectedRoute) {
            var index = this.selectedRoutes.indexOf(this.selectedRoute);
            this.isEditting = false;
            this.selectedRoutes[index] = value;
            this.resetForm();
            this.resetFormTable();
            this.selectedRoutes = [...this.selectedRoutes];
            this.spinner.hide("routeSpinner");
          }
        },
        error: (err) => {
          console.log("errorEditRoute", err);
          this.spinner.hide("routeSpinner");
        },
      });
    }
  }
  resetFormTable() {
    this.formTable.reset();
    this.selectedLoadingPortsEditting = [];
    this.selectedDischargingPortsEditting = [];
  }
  addNewRoute() {
    this.resetForm();
    this.isNewRoute = true;
    this.newRoute = new Route();
    this.newRoute.isNew = true;
    this.selectedRoutes.unshift(this.newRoute);
    this.selectedRoutes = [...this.selectedRoutes];
  }
  filterPortsTable(event: any) {
    console.log(event);
  }
}
