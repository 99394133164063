import { Charger } from "../charger/charger.model";
import { ContactListChargerShipownerContact } from "./contact-list-charger-shipowner-contact.model";

export class ContactList {
    name?: string;
    chargerId?: string;
    charger?: Charger;
    contacts?: ContactListChargerShipownerContact[];
    
    id?: string;   
    expanded?: boolean;
    shipOwners?: any[];
    filteredShipOwners?: any[];
    editable?: boolean;
    counterContacts?: number;
    isListOfAll?:boolean;
    constructor() {
    }
}