import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable, of, Subject, ReplaySubject, map } from "rxjs";
import { NegotiationOfferShipOwner } from "../models/offers/negotiation-offer-shipowner.model";
import { ComparativeOffer } from "../models/offers/comparative-offer.model";
import { Offer } from "../models/offers/offer.model";
import { OfferDecline } from "../models/offers/offer-decline.model";
import { OfferShipOwnerState } from "../models/offers/offer-shipowner-state.model";
import { OfferStateDto } from "../models/offers/offer-state-dto.model";
import { OfferShipownerLine } from "../models/offerShipOwnerLine/offer-shipowner-line.model";
import { Clause } from "../models/clause/clause.model";
import { NegotiationShipownerClausesValidation } from "../models/clause/negotiation-shipowner-clauses-validation.model";
import { ExpirationDate } from "src/app/models/offers/expiration-date.model";
import { OfferShipOwner } from "../models/offers/offer-shipowner.model";
import { OfferContactsDto } from "../models/offers/offer-contacts-dto.model";
import { OfferShipownerContacts } from "../models/offers/offer-shipowner-contacts.model";
import { ChargerShipOwnerContact } from "../models/charger-shipowner/charger-shipowner-contact.model";
import { NegotiationClauses } from "../models/offers/negotiation-clauses.model";
import { OfferFilter } from "../models/searcher/offer-filter.model";
import { OfferComplete } from "../models/offers/offer-complete.model";
import { AnyCatcher } from "rxjs/internal/AnyCatcher";
import { Recap } from "../models/offers/recap.model";
import { ConfigsService } from "@overa/shared";
import { CLHBaseService } from "./clh-base.service";

@Injectable({ providedIn: "root" })
export class OfferService extends CLHBaseService {
  private offerSubject: ReplaySubject<Offer[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public offerObservable: Observable<Offer[]> =
    this.offerSubject.asObservable();

  private routeOfferSubject: ReplaySubject<string> = new ReplaySubject(1);
  public routeOfferObservable: Observable<string> =
    this.routeOfferSubject.asObservable();

  get(searchString: OfferFilter) {
    const endpointUrl = `${this.baseUrl}/offer/getAll`;
    return this.http.post<Offer[]>(endpointUrl, searchString);
  }

  getById(id: string) {
    return this.http.get<Offer>(`${this.baseUrl}/offer/${id}`);
  }

  getShipownerResponse(offerShipownerId: string) {
    return this.http.get<Offer>(
      `${this.baseUrl}/offer/getIndication/${offerShipownerId}`
    );
  }
  add(offer: Offer) {
    const endpointUrl = `${this.baseUrl}/offer`;
    this.setPorts(offer);
    return this.http.post<Offer>(endpointUrl, offer);
  }

  edit(offer: Offer) {
    const endpointUrl = `${this.baseUrl}/offer`;
    const offerToSend = this.prepareToSend(offer);
    return this.http.put<Offer>(endpointUrl, offerToSend);
  }

  delete(id: string) {
    console.log("delete");
    const endpointUrl = `${this.baseUrl}/offer/${id}`;
    return this.http.delete(endpointUrl);
  }

  notifyupdate(offers: Offer[]) {
    this.offerSubject.next(offers);
  }

  notifyupdateRoute(id: string) {
    this.routeOfferSubject.next(id);
  }
  sendEmail(id: string) {
    return this.http.post(`${this.baseUrl}/offer/${id}/sendmails`, {});
  }
  prepareToSend(offer: Offer): Offer {
    const offerResult = { ...offer };
    if (offerResult.charger && !offerResult.chargerId) {
      offerResult.chargerId = offerResult.charger.id;
    }
    offerResult.charger = undefined;
    offerResult.offerShipOwners?.forEach((s: OfferShipOwner) => {
      s.shipOwnerId = s.shipOwnerId || s.shipOwner?.id;
      s.shipOwner = undefined;
      s.offerId = offer?.id;
      if (s.offer) {
        s.offer = undefined;
      }
    });
    this.setPorts(offer);
    console.log(offerResult);
    return offerResult;
  }
  setPorts(offer: any) {
    if (!offer.loadingPorts) {
      offer.loadingPorts = [];
    }
    if (!offer.dischargingPorts) {
      offer.dischargingPorts = [];
    }
  }
  declineOfferSendEmail(offerDecline: OfferDecline) {
    const endpointUrl = `${this.baseUrl}/offershipowner/decline`;
    return this.http.post(endpointUrl, offerDecline);
  }

  updateOfferShipOwnerWithLine(offerShipOwnerLine: OfferShipownerLine[]) {
    const endpointUrl = `${this.baseUrl}/offershipownerline/newindication`;
    return this.http.post(endpointUrl, offerShipOwnerLine);
  }
  //changeStateInOfferShipOwnner(offerShipOwnerstateDto:OfferShipOwnerState){
  //  const endpointUrl = `${this.baseUrl}/offershipowner/changeStatus`;
  //  return this.http.post(endpointUrl, offerShipOwnerstateDto);
  //}
  getOfferShipOwnerDtoForComparative(id: string) {
    return this.http.get<ComparativeOffer>(
      `${this.baseUrl}/offer/${id}/comparative`
    );
  }
  getShipOwnerNegotiation(id: string) {
    return this.http.get<NegotiationOfferShipOwner>(
      `${this.baseUrl}/offershipowner/${id}/negotiation`
    );
  }
  getClausesByChargerId(id: string) {
    return this.http.get<Clause[]>(`${this.baseUrl}/clause/getByCharger/${id}`);
  }
  createShipOwnerNegotiation(negotiation: NegotiationOfferShipOwner) {
    const endpointUrl = `${this.baseUrl}/offershipowner/negotiation`;
    return this.http.post(endpointUrl, negotiation);
  }
  sendClausesCLHNegotiation(
    negotiation: NegotiationShipownerClausesValidation
  ) {
    const endpointUrl = `${this.baseUrl}/offershipowner/clausesReview`;
    return this.http.post(endpointUrl, negotiation);
  }
  //updateState(offerStateDto:OfferStateDto){
  //  const endpointUrl = `${this.baseUrl}/offer/updateState`;
  //  return this.http.post(endpointUrl, offerStateDto);
  //}
  getShipOwnerRecap(id: string) {
    return this.http.get<NegotiationOfferShipOwner>(
      `${this.baseUrl}/offershipowner/${id}/recap`
    );
  }
  confirmOfferShipOwnerToRecap(offerShipOwnerstateDto: OfferShipOwnerState) {
    const endpointUrl = `${this.baseUrl}/offershipowner/confirm`;
    return this.http.post(endpointUrl, offerShipOwnerstateDto);
  }
  checkCodeOffer(code: string) {
    return this.http.get<boolean>(`${this.baseUrl}/offer/checkCode/${code}`);
  }
  setExpirationDate(expirationDate: ExpirationDate) {
    const endpointUrl = `${this.baseUrl}/offershipowner/expirationDate`;
    return this.http.post(endpointUrl, expirationDate);
  }

  getOfferShipOwnerById(id: string): Observable<OfferShipOwner> {
    return this.http.get<OfferShipOwner>(
      `${this.baseUrl}/offershipowner/${id}`
    );
  }

  getOfferContactListById(id: string) {
    return this.http.get<OfferContactsDto>(
      `${this.baseUrl}/offer/${id}/getContactList`
    );
  }
  updateOfferContactList(offerContactList: OfferContactsDto) {
    const endpointUrl = `${this.baseUrl}/offer/updateContacts`;
    return this.http.put(endpointUrl, offerContactList);
  }
  getContactstById(id: string) {
    return this.http.get<OfferShipownerContacts>(
      `${this.baseUrl}/offershipowner/${id}/getContacts`
    );
  }
  addChargerShipownerContact(
    id: string,
    chargerShipownerContact: ChargerShipOwnerContact
  ) {
    const endpointUrl = `${this.baseUrl}/offershipowner/${id}/addContact`;
    return this.http.post(endpointUrl, chargerShipownerContact);
  }
  deleteContactToChargerShipownerList(
    idOffer: string,
    idChargerShipowner: string
  ) {
    const endpointUrl = `${this.baseUrl}/offershipowner/${idOffer}/deleteContact/${idChargerShipowner}`;
    return this.http.put(endpointUrl, {});
  }
  getPreviousChargerClausesById(id: string) {
    return this.http.get<NegotiationClauses>(
      `${this.baseUrl}/offershipowner/${id}/previousClauses`
    );
  }
  completeOfferShipOwner(offerComplete: OfferComplete) {
    const endpointUrl = `${this.baseUrl}/offershipowner/complete`;
    return this.http.post(endpointUrl, offerComplete);
  }
  cancelOffer(id: string) {
    const endpointUrl = `${this.baseUrl}/offer/${id}/cancel`;
    return this.http.put(endpointUrl, {});
  }
  cancelOfferShipowner(id: string) {
    const endpointUrl = `${this.baseUrl}/offershipowner/${id}/cancel`;
    return this.http.put(endpointUrl, {});
  }
  checkAccessByShipownerContact(offerShipownerId: string, contactId: string) {
    return this.http.get<boolean>(
      `${this.baseUrl}/offershipowner/${offerShipownerId}/check/${contactId}`
    );
  }
  getRecapDoc(id: string): Observable<any> {
    console.log(id);
    return this.http.get(`${this.baseUrl}/offershipowner/${id}/recap/doc`, {
      responseType: "arraybuffer",
      observe: "response",
    });
  }

  updateOfferShipowner(
    offerShipowner: OfferShipOwner
  ): Observable<OfferShipOwner> {
    const endpointUrl = `${this.baseUrl}/offershipowner`;
    return this.http.put<OfferShipOwner>(endpointUrl, offerShipowner);
  }
}
