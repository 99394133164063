import { Injectable } from "@angular/core";
import { MenuItem } from "primeng/api";
import { Breadcrumb } from "primeng/breadcrumb";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { CLHBaseService } from "./clh-base.service";

@Injectable({
  providedIn: "root",
})
export class BreadcrumbService extends CLHBaseService {
  private breadcrumbSubject = new BehaviorSubject<MenuItem[]>([]);
  breadcrumb = this.breadcrumbSubject.asObservable();

  setBreadcrumb(breadcrumb: MenuItem[]): void {
    console.log(breadcrumb);
    const modifiedBreadcrumb = [...breadcrumb];
    this.breadcrumbSubject.next(modifiedBreadcrumb);
  }

  getBreadcrumb(): Observable<MenuItem[]> {
    return this.breadcrumbSubject.asObservable();
  }
}
