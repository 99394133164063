import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ConfigsService } from "@overa/shared";
import { ChargerUser } from "../models/charger/charger-user.model";

@Injectable({
  providedIn: "root",
})
export abstract class CLHBaseService {
  config = inject(ConfigsService);

  get baseUrl() {
    return this.config.getModuleConfig("overa-security", "apiBaseUrl") + "/api";
  }

  constructor(protected http: HttpClient) { }
}
