<!--<ngx-spinner></ngx-spinner>-->

<div class="-kanban-container" style="position: relative">
  <p-toast></p-toast>
  <ngx-spinner [fullScreen]="false" size="medium" name="layout-spinner"></ngx-spinner>
  <div class="container-fluid">
    <div class="row kanban-headers">
      <div class="col-xl-4 col-md-6 col-xxl-3 mt-3 mb-3 align-middle text-center">
        <div class="kanban-card-header-1">
          <span
            class="mr-2"
            overaellipsis
            [escape]="false"
            [pTooltip]="!isShipowner ? ('TOOLTIP.RECEPTION_KANBAN'| translate) : ('TOOLTIP.RECEPTION_KANBAN_SHIPOWNER'| translate)"
            tooltipPosition="bottom"
            tooltipStyleClass="tooltipCLH"
            [showDelay]="1000"
            [hideDelay]="1000"
          >
            {{'KANVAN.RECEPTION_OF_OFFERS_AND_COMPARISON' | translate}}
          </span>
          <div class="arrow-right">
            <a><img class="icon-kanban-header-arrow" src="./assets/icons/icon-kanban-header-arrow.svg" /></a>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-xxl-3 mt-3 mb-3 align-middle text-center">
        <div class="kanban-card-header-2">
          <span
            class="mr-2"
            overaellipsis
            [escape]="false"
            [pTooltip]="!isShipowner ? ('TOOLTIP.NEGOTIATION_KANBAN'| translate) : ''"
            tooltipPosition="bottom"
            tooltipStyleClass="tooltipCLH"
            [showDelay]="1000"
            [hideDelay]="1000"
          >
            {{'KANVAN.FIRM_NEGOTIATION' | translate}}
          </span>
          <div class="arrow-right">
            <a><img class="icon-kanban-header-arrow" src="./assets/icons/icon-kanban-header-arrow.svg" /></a>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-xxl-3 mt-3 mb-3 align-middle text-center">
        <div class="kanban-card-header-3">
          <span
            class="mr-2"
            overaellipsis
            [escape]="false"
            [pTooltip]=" !isShipowner ? ('TOOLTIP.OPERATIVE_KANBAN'| translate) : ''"
            tooltipPosition="bottom"
            tooltipStyleClass="tooltipCLH"
            [showDelay]="1000"
            [hideDelay]="1000"
          >
            {{'KANVAN.OPERATIVE' | translate}}
          </span>
          <div class="arrow-right">
            <a><img class="icon-kanban-header-arrow" src="./assets/icons/icon-kanban-header-arrow.svg" /></a>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-xxl-3 mt-3 mb-3 align-middle text-center">
        <div class="kanban-card-header-4">
          <span
            class="mr-2"
            overaellipsis
            [escape]="false"
            [pTooltip]="!isShipowner ? ('TOOLTIP.HISTORIC_KANBAN'| translate) : ''"
            tooltipPosition="bottom"
            tooltipStyleClass="tooltipCLH"
            [showDelay]="1000"
            [hideDelay]="1000"
          >
            {{'KANVAN.ARCHIVE' | translate}}
          </span>
          <div class="arrow-right">
            <a><img class="icon-kanban-header-arrow" src="./assets/icons/icon-kanban-header-arrow.svg" /></a>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-4 col-md-6 col-xxl-3 mt-1 mb-1 align-middle text-center kanban-card-1">
        <div class="kanban-card-header-content">
          <div class="row kanban-card-header-content-inner">
            <div
              class="col-xl-12 col-md-12 col-xxl-12 align-middle text-left kanban-head-mobile"
              [escape]="false"
              [pTooltip]="!isShipowner ? ('TOOLTIP.RECEPTION_KANBAN'| translate) : ('TOOLTIP.RECEPTION_KANBAN_SHIPOWNER'| translate)"
              tooltipPosition="bottom"
              tooltipStyleClass="tooltipCLH"
              [showDelay]="1000"
              [hideDelay]="1000"
            >
              {{'KANVAN.RECEPTION_OF_OFFERS_AND_COMPARISON' | translate}}
            </div>
            <app-kanban-element
              [cssClass]="'c1-border'"
              [backgroundClass]="'c1-background'"
              [taskList]="taskListReception"
              (onSelectKanban)="selectKanban($event)"
            ></app-kanban-element>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-md-6 col-xxl-3 mt-1 mb-1 align-middle text-center kanban-card-2">
        <div class="kanban-card-header-content">
          <div class="row kanban-card-header-content-inner">
            <div
              class="col-xl-12 col-md-12 col-xxl-12 align-middle text-left kanban-head-mobile"
              [escape]="false"
              [pTooltip]=" !isShipowner ? ('TOOLTIP.OPERATIVE_KANBAN'| translate) : ''"
              tooltipPosition="bottom"
              tooltipStyleClass="tooltipCLH"
              [showDelay]="1000"
              [hideDelay]="1000"
            >
              {{'KANVAN.FIRM_NEGOTIATION' | translate}}
            </div>
            <app-kanban-element
              [cssClass]="'c1-border'"
              [backgroundClass]="'c1-background'"
              [taskList]="taskListNegotiation"
              (onSelectKanban)="selectKanban($event)"
            ></app-kanban-element>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-md-6 col-xxl-3 mt-1 mb-1 align-middle text-center kanban-card-3">
        <div class="kanban-card-header-content">
          <div class="row kanban-card-header-content-inner">
            <div
              class="col-xl-12 col-md-12 col-xxl-12 align-middle text-left kanban-head-mobile"
              [escape]="false"
              [pTooltip]=" !isShipowner ? ('TOOLTIP.OPERATIVE_KANBAN'| translate) : ''"
              tooltipPosition="bottom"
              tooltipStyleClass="tooltipCLH"
              [showDelay]="1000"
              [hideDelay]="1000"
            >
              {{'KANVAN.OPERATIVE' | translate}}
            </div>
            <app-kanban-element
              [cssClass]="'c1-border'"
              [backgroundClass]="'c1-background'"
              [taskList]="taskListOperative"
              (onSelectKanban)="selectKanban($event)"
            ></app-kanban-element>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-md-6 col-xxl-3 mt-1 mb-1 align-middle text-center kanban-card-4">
        <div class="kanban-card-header-content">
          <div class="row kanban-card-header-content-inner">
            <div
              class="col-xl-12 col-md-12 col-xxl-12 align-middle text-left kanban-head-mobile"
              [escape]="false"
              [pTooltip]="!isShipowner ? ('TOOLTIP.HISTORIC_KANBAN'| translate) : ''"
              tooltipPosition="bottom"
              tooltipStyleClass="tooltipCLH"
              [showDelay]="1000"
              [hideDelay]="1000"
            >
              {{'KANVAN.ARCHIVE' | translate}}
            </div>
            <app-kanban-element
              [cssClass]="'c1-border'"
              [backgroundClass]="'c1-background'"
              [taskList]="taskListHistory"
              (onSelectKanban)="selectKanban($event)"
            ></app-kanban-element>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
