import { ShipOwnerContact } from "./shipowner-contact.model";
import { ShipOwnerUser } from "./shipowner-user.model";
import { ChargerShipOwnerContact } from "../charger-shipowner/charger-shipowner-contact.model";
import { Country } from "../country/country.model";
export class Shipowner {
  id?: string;
  refShipOwner?: number;
  charteringCompany?: string;
  companyIMONumber?: string;
  dischargeDate?: Date;
  account?: string;
  web?: string;
  address?: string;
  postalCode?: string;
  province?: string;
  country?: Country;
  countryName?: string;
  town?: string;
  countryId?: string;
  geoAreas?: string[];
  vesselRangeFrom?: number;
  vesselRangeTo?: number;
  isSelected?: boolean;
  comment?: string;
  shipOwnerChargerId?: string;
  contacts?: ShipOwnerContact[];
  logoId?: string;
  logo?: any;
  isBanned?: boolean;
  cifVat?: string;
  genericEmail?: string;
  alias?: string;

  users?: ShipOwnerUser[];
  chargerShipOwnerContacts?: ChargerShipOwnerContact[];

  constructor(obj?: Partial<Shipowner>) {
    this.refShipOwner = (obj && obj.refShipOwner) || undefined;
    this.id = (obj && obj.id) || undefined;
    this.charteringCompany = (obj && obj.charteringCompany) || undefined;
    this.companyIMONumber = (obj && obj.companyIMONumber) || undefined;
    this.dischargeDate = (obj && obj.dischargeDate) || new Date();
    this.web = (obj && obj.web) || undefined;
    this.address = (obj && obj.address) || undefined;
    this.account = (obj && obj.account) || undefined;
    this.country = (obj && obj.country) || undefined;
    this.geoAreas = (obj && obj.geoAreas) || [];
    this.vesselRangeFrom = (obj && obj.vesselRangeFrom) || undefined;
    this.vesselRangeTo = (obj && obj.vesselRangeTo) || undefined;
    this.contacts = (obj && obj.contacts) || [];
    this.logoId = (obj && obj.logoId) || undefined;
    this.users = (obj && obj.users) || [];
    this.chargerShipOwnerContacts = (obj && obj.chargerShipOwnerContacts) || [];
  }
}
