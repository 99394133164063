import { ShipOwnerContact } from "../shipowner/shipowner-contact.model";
import { ChargerShipOwner } from "./charger-shipowner.model";

export class ChargerShipOwnerContact {
  [x: string]: any;
  id?: string;
  chargerShipOwnerId?: string;
  chargerShipOwner?: ChargerShipOwner;
  name?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  comment?: string;
  editable?: boolean;
  isBanned?: boolean;
  shipOwnerContactId?: string;
  shipOwnerContact?: ShipOwnerContact;
  constructor() {}
}
