<nb-layout>
  <nb-layout-column>
    <div class="chat-wrapper" *ngIf="signalRAdapter!=null">
      <nb-chat title="{{windowName}}" size="large">
        <nb-chat-message
          *ngFor="let msg of signalRAdapter.messages;let i = index"
          #chatMessage
          [id]="'chatMessage-' + i"
          [type]="'text'"
          [message]="msg.message"
          [reply]="msg.reply"
          [sender]="msg.participant.userName"
          [date]="msg.date"
          dateFormat='medium'
        >
        </nb-chat-message>
        <nb-chat-form (send)="sendMessage($event)" [dropFiles]="false"> </nb-chat-form>
      </nb-chat>
    </div>
  </nb-layout-column>
</nb-layout>
