<p-toast></p-toast>
<div class="d-flex flex-column justify-content-between divContentAll">

    <ng-container *ngIf="form">
        <div class="d-flex align-items-start  withAll">
            <div class="withAll" [formGroup]="form">
                <div class="row withAll">
                    <h4>{{'EXPIRATIONDATE.HEADER' | translate}}</h4>
                </div>
                <div class="row withAll">
                    <div class="withAll d-flex justify-content-center">
                        <h6><strong>{{'BINDING_OFFER.DEADLINE_LABEL' | translate}}</strong><p-calendar
                                formControlName="expirationDate" dateFormat="dd/mm/yy"
                                styleClass="calendarBinding calendarBindingMargin"
                                [disabled]="!form.get('checkedExpirationDate').value" [showIcon]="true"
                                [showTime]="true" [showSeconds]="false" [minDate]="today"></p-calendar><p-inputSwitch
                                formControlName="checkedExpirationDate" styleClass="switchExpiration"></p-inputSwitch>
                        </h6>
                    </div>
                    <div class="withAll d-flex d-flex justify-content-center">
                        <div *ngIf="form.get('expirationDate').touched   && form.errors?.['expirationDate']"
                            class="error-label labelError">
                            {{'MESSAGES.VALIDATION.EXPIRATION_DATE' | translate}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="d-flex justify-content-center align-items-end">
        <ng-container>
            <div class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center" align="center">
                    <p-button pButtonCan label="{{'ACTION.CANCEL' | translate}}" styleClass="p-button btn-back"
                        (click)="back()"></p-button>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center" align="center">
                    <p-button pButtonSig label="{{'ACTION.SEND' | translate}}" styleClass="p-button btn-continue"
                        [disabled]="isLoading" (onClick)="continue()"></p-button>
                </div>
            </div>
        </ng-container>
    </div>
</div>