import { ChangeDetectorRef, Component, inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { OveraMessagesService } from '@overa/components/info-dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { Charger } from 'src/app/models/charger/charger.model';
import { MailTemplateTypes } from 'src/app/models/enum/mail-template-types';
import { Offer } from 'src/app/models/offers/offer.model';
import { OfferShipOwner } from 'src/app/models/offers/offer-shipowner.model';
import { StateOfferShipOwner } from 'src/app/models/enum/state-offer-shipowner.model';
import { Shipowner } from 'src/app/models/shipowner/shipowner.model';
import { ChargerShipOwner } from 'src/app/models/charger-shipowner/charger-shipowner.model';
import { ChargerService } from 'src/app/services/charger.service';
import { OfferService } from 'src/app/services/offer.service';
import { ShipownerService } from 'src/app/services/shipowner.service';
import { mapTemplate } from 'src/app/utils/template-helper';
import { CommentsDialogComponent } from '../../comments-dialog/comments-dialog.component';
import { ChargerShipOwnerContact } from 'src/app/models/charger-shipowner/charger-shipowner-contact.model';
import { ContactListCSContactDto } from 'src/app/models/charger-shipowner/contact-list-cs-contact-dto.model';
import { ShipownerCSContactDto } from 'src/app/models/charger-shipowner/shipowner-cs-contact-dto.model';
import { ChargerShipOwnerContactDto } from 'src/app/models/charger-shipowner/charger-shipowner-contact-dto.model';
import { ChargerShipownerService } from 'src/app/services/charger-shipowner.service';
import { OfferContactsDto } from 'src/app/models/offers/offer-contacts-dto.model';
import { Table } from 'primeng/table';
import { Filters } from 'src/app/utils/filters';
import { utils } from 'xlsx';
import { Utils } from '../../../../utils/utils';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { PermissionsService } from '../../../../services/permissions.service';
import { Constants } from 'src/app/utils/constants';
import { MessageTemplate, MessageTemplateService } from '@overa/notification';
import { CLHGlobals } from 'src/app/utils/globals';
import { AccountService } from '@overa/security';

@Component({
  selector: 'app-step2-offer',
  templateUrl: './step2-offer.component.html',
  styleUrls: ['./step2-offer.component.scss'],
})
export class Step2OfferComponent implements OnInit {
  offer: Offer;
  shipownersOriginal: ChargerShipOwner[];
  shipowners: OfferShipOwner[];
  isFiltering: boolean = false;
  filterName: string;
  filterContact: string;
  filterEmail: string;
  filterPhone: string;
  charger: Charger;
  commentKey = '${comment}';
  selectedShipOwner: ShipownerCSContactDto;
  mailTemplates: MessageTemplate[];
  previousElement: HTMLTableColElement;
  template: string;
  subjectTemplate: string;
  statusNone = [StateOfferShipOwner.NONE];
  readonlyStep2: boolean = false;
  // commentsAdd: string = '';
  columns: any[];
  contactsListCS: ContactListCSContactDto[]; // lista de contactos chargerShipowner
  shipownerlistTable: ShipownerCSContactDto[] = []; // lista de shipowner para mostrar en la tabla
  contactslistTable: ChargerShipOwnerContactDto[] = [];
  listAllListNames: string[] = []; // lista de nombres de listas de contactos
  textAutocomplete: string; // variable para el dropdown de listas de contactos
  selectedContactListCS: ContactListCSContactDto; // lista seleccionada en la lista de contactos chargerShipowner
  offerShipownerOriginal: OfferShipOwner; // offershipowner para mostrar datos al renderizar el email
  offerContacts: OfferContactsDto;
  isLoaded: boolean = false;
  form: FormGroup;
  canDeleted: boolean = true;
  showPreviewEmail: boolean = false;
  showEmailToDefault: boolean = true;
  translateAllList: string = '';
  private accountsService: AccountService = inject(AccountService);

  @ViewChild('tableShipownersStep2') tableShipownersStep2: Table;
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private chargerService: ChargerService,
    private offerService: OfferService,
    private mailTemplateService: MessageTemplateService,
    protected dialogService: DialogService,
    private globals: CLHGlobals,
    public messagesService: OveraMessagesService,
    private spinner: NgxSpinnerService,
    private chargerShipownerService: ChargerShipownerService,
    private filtersTable: Filters,
    private utils: Utils,
    private confirmationService: ConfirmationService,
    private permissionsService: PermissionsService,
    private translate: TranslateService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.resetVariables();
    this.initialForm();
    this.spinner.show();
    var id = this.router.url.toString().split('/').pop();

    this.columns = [
      // { id: "", sort: "check" },
      { id: 'STEP2_OFFER.TABLE_COLUMN_SELECT', sort: 'select' },
      { id: '', sort: 'expandedRow' },
      { id: 'STEP2_OFFER.TABLE_COLUMN_NAME', sort: 'charteringCompany' },
      { id: 'STEP2_OFFER.TABLE_COLUMN_COMMENT', sort: 'commentEmail', tooltip: 'TOOLTIP.COMMENT_STEP2' },
    ];
    this.translate.get('COMMONS.ALL').subscribe((a: string) => {
      this.translateAllList = a;
    });
    this.offerService.getById(id).subscribe({
      next: (x) => {
        this.offer = x;
        this.mailTemplateService.readAll().subscribe({
          next: (templates) => {
            this.mailTemplates = templates;
            let template = this.mailTemplates.find((x) => x.name == MailTemplateTypes.OFFER_SEND_CHARGER);
            if (template) {
              this.template = template.content;
              this.subjectTemplate = template.subject;
            }
            this.mapOffershipowner();

            if (this.offer.comments) {
              /* this.offer.comments.replace(/\\n/g, "\r\n'<br>'").replace(/\\r/g, "\r\n'<br>'") */
              this.offer.comments = this.offer.comments.replace(/<br>/g, '\n');
              this.form.get('commentsAdd').setValue(this.offer.comments);
            }
            if (!this.permissionsService.hasPermissionToEdit()) {
              this.readonlyStep2 = true;
            }
            if (this.offer.offerShipOwners?.length) {
              this.offer.offerShipOwners.forEach((itemShipOwner) => {
                if (!this.statusNone.includes(itemShipOwner.state)) {
                  this.readonlyStep2 = true;
                  this.canDeleted = false;
                }
              });
            }
            this.contactsListCS = this.globals.currentCharger?.contactLists?.length > 0 ? [...this.globals.currentCharger?.contactLists] : [];

            this.offerService.getOfferContactListById(this.offer.id).subscribe({
              next: (offerContact) => {
                this.offerContacts = { ...offerContact };
                if (this.offerContacts?.contactList) {
                  this.selectedContactListCS = { ...this.offerContacts.contactList };
                  if (this.readonlyStep2) {
                    this.contactsListCS = [];
                    this.contactsListCS.push(this.selectedContactListCS);
                  }
                  this.assignListOffer();
                  this.assignEmailVariables();

                  this.refactorAndFilterLists();
                  this.isLoaded = true;
                  this.spinner.hide();
                } else {
                  this.refactorAndFilterLists();
                  this.assignTheAllListByDefault();

                  this.isLoaded = true;
                  this.spinner.hide();
                }
                if (!this.permissionsService.hasPermissionToEdit(this.route)) {
                  this.readonlyStep2 = true;
                  this.canDeleted = false;
                }
                this.cdRef.detectChanges();
              },
              error: (err) => {
                console.log(err);
                this.spinner.hide();
              },
            });
          },
          error: (err) => {
            console.error(err);
          },
        });
      },
      error: (err) => {
        console.log(err);
        this.spinner.hide();
      },
    });
  }
  assignTheAllListByDefault() {
    var list = [...this.contactsListCS];
    this.textAutocomplete = this.translateAllList;
    this.selectedContactListCS = list.filter((x) => x.name === this.textAutocomplete)[0];
    let event;
    this.showEmailToDefault = true;
    this.onItemSelect(event);
  }
  initialForm() {
    this.form = new FormGroup({
      commentsAdd: new FormControl('', [Validators.required]),
    });
  }
  resetVariables() {
    this.listAllListNames = [];
    this.selectedContactListCS = null;
    this.contactsListCS = [];
    this.shipownerlistTable = [];
    this.selectedContactListCS = null;
    this.offerContacts = null;
  }
  assignEmailVariables() {
    this.contactsListCS.forEach((list) => {
      if (list?.shipOwners) {
        list.shipOwners.forEach((shipownerCSContact) => {
          if (shipownerCSContact) {
            shipownerCSContact.emailPreview = shipownerCSContact.email;
          }
        });
      }
    });
  }
  refactorAndFilterLists() {
    //remove list all and create the dropdown list of list names
    // let index = this.contactsListCS.findIndex((x) => x.name === 'TODOS');
    // if (index >= 0) {
    //   this.contactsListCS.splice(index, 1);
    // }
    console.log(this.translateAllList);
    this.contactsListCS.forEach((x) => {
      if (x.name === 'TODOS' || x.name === 'ALL') {
        x.name = this.translateAllList;
      }
    });
    this.listAllListNames = [...this.contactsListCS.map((x) => x.name)];
    if (this.selectedContactListCS != null) {
      var list = [...this.listAllListNames];
      this.listAllListNames = [...list.filter((x) => x.toLowerCase() != this.selectedContactListCS.name.toLowerCase())];
    }
    this.listAllListNames = [...this.listAllListNames];
    this.cdRef.detectChanges();
  }
  filterlistShipowners(event) {
    this.listAllListNames = [...this.listAllListNames];
    let contactListCSContact = this.contactsListCS.map((x) => x.name);
    this.listAllListNames = [...contactListCSContact];
    var list = [...this.listAllListNames];
    this.listAllListNames = [...list.filter((x) => x.toLowerCase().includes(event.query.toLowerCase()))];
    this.listAllListNames = [...this.listAllListNames];
  }
  onItemSelect(event) {
    this.changeSelectedShipownerAndShowPreviewEmail();
    this.shipownerlistTable = [];
    if (this.textAutocomplete) {
      var list = [...this.contactsListCS];
      this.selectedContactListCS = list.filter((x) => x.name === this.textAutocomplete)[0];
    }
    this.assignedContactsToSelectedContactListCS();
  }
  assignedContactsToSelectedContactListCS() {
    if (this.selectedContactListCS?.id) {
      this.spinner.show();
      this.chargerShipownerService.getWithContacts(this.selectedContactListCS.id).subscribe({
        next: (shipownersWithContacts) => {
          if (shipownersWithContacts) {
            this.selectedContactListCS.shipOwners = shipownersWithContacts.shipOwners?.length > 0 ? [...shipownersWithContacts.shipOwners] : [];
          }
          if (this.selectedContactListCS.shipOwners?.length > 0) {
            this.selectedContactListCS.shipOwners.forEach((item) => {
              if (item.isSelected) {
                this.changeIsSelected(true, item);
              } else {
                this.changeIsSelected(false, item);
              }

              this.shipownerlistTable.push(item);
            });
            if (this.showEmailToDefault && this.showEmailToDefault === true) {
              let shipowner;
              let listShipowners = [...this.selectedContactListCS.shipOwners];
              shipowner = listShipowners.find((x) => x.isSelected === true);
              if (shipowner) {
                this.selectedShipOwner = shipowner;
                this.offer.comments = this.form.get('commentsAdd').value;
                this.setEmail(this.selectedShipOwner);
                this.showPreviewEmail = true;
                this.showEmailToDefault = false;
              }
            }
          } else {
            this.messagesService.showToast('ERROR', 'MESSAGES.ERROR.EMPTY_LIST', 'error', '');
          }

          this.shipownerlistTable = [...this.shipownerlistTable];
          // this.getMailTemplate();

          this.spinner.hide();
        },
        error: (err) => {
          this.spinner.hide();
          console.error(err);
        },
      });
    }
  }
  assignListOffer() {
    if (this.selectedContactListCS?.id) {
      this.shipownerlistTable = [...this.selectedContactListCS.shipOwners];
      if (this.readonlyStep2) {
        this.shipownerlistTable.forEach((item, index) => {
          if (item.isSelected) {
            item.contacts.forEach((contact, indice) => {
              if (!contact.isSelected) {
                item.contacts.splice(indice, 1);
              }
            });
          } else {
            this.shipownerlistTable.splice(index, 1);
          }
        });
      }
      this.textAutocomplete =
        this.selectedContactListCS.name === 'TODOS' || this.selectedContactListCS.name === 'ALL '
          ? this.translateAllList
          : this.selectedContactListCS.name;
      if (this.selectedContactListCS.name === 'TODOS' || this.selectedContactListCS.name === 'ALL ') {
        this.selectedContactListCS.name = this.translateAllList;
      }
      this.getMailTemplate();
    } else {
      this.textAutocomplete = '';
    }
  }

  showComment(shipowner: ShipownerCSContactDto) {
    shipowner.comment = shipowner.comment ? shipowner.comment.replace(/<br>/g, '\n') : shipowner.comment;
    var ref = this.dialogService.open(CommentsDialogComponent, {
      styleClass: 'no-header-title',
      data: {
        comment: shipowner.comment,
        readonlyStep2: this.readonlyStep2,
      },
    });
    ref.onClose.subscribe((comment) => {
      if (comment != null) {
        shipowner.isModified = true;
        shipowner.comment = comment;
        this.setEmail(shipowner);
      }
    });
  }
  setEmail(shipOwner: ShipownerCSContactDto) {
    this.offerShipownerOriginal.shipOwner.charteringCompany = shipOwner.charteringCompany;
    this.offerShipownerOriginal.comment = shipOwner.comment;
    shipOwner.comment = shipOwner.comment ? shipOwner.comment.replace(/\r\n|\r|\n|<br>/g, '<br>') : shipOwner.comment;
    var offerMail = { ...this.offerShipownerOriginal };
    console.log('offerMail', offerMail);
    // offerMail['userId'] = encodeURIComponent(this.utils.encrypt(this.globals.user.id));
    offerMail['tenantId'] = encodeURIComponent(this.utils.encrypt(this.accountsService.currentTenant.id));
    offerMail['shipownerId'] = shipOwner.id;
    offerMail['termsConditions'] = this.globals.termsConditions.termsEN;
    offerMail['legalAdvice'] = Constants.LEGAL_ADVICE.en;
    offerMail['dataProtection'] = this.globals.dataProtection.policyEN + Constants.DATA_PROTECTION.en;
    offerMail.offerId = encodeURIComponent(this.utils.encrypt(this.offer.id));
    offerMail['listEmails'] = [];
    offerMail['listNameShipowners'] = [];
    if ((shipOwner.contacts.length > 0) && (shipOwner.genericEmail !== '' && shipOwner.genericEmail !== null)) {
      offerMail['listEmails'].push(shipOwner.genericEmail);
      offerMail['listNameShipowners'].push(shipOwner.alias);
    } else {
      shipOwner.contacts.forEach((element) => {
        if (element.isSelected) {
          offerMail['listEmails'].push(element.email);
          if (element.name && element.name != '') offerMail['listNameShipowners'].push(element.name);
        }
      });
    }
    shipOwner.subject = mapTemplate(this.subjectTemplate, offerMail, ['comment', 'redirectUrl']);
    shipOwner.email = mapTemplate(this.template, offerMail, ['comment', 'redirectUrl']);
    // var dominio = window.location.href.split('//')[1].split('/')[0];
    // var redirectUrl = 'https://' + dominio + '/security/login?redirectUrl=firmOffer/cover/' + this.offer.id;
    // // var redirectUrl = 'https://localhost:5001/security/login?redirectUrl=firmOffer/cover/' + this.offer.id;
    // let link = '<a href="' + redirectUrl + '">Acceder</a>';
    let commentEmail = '<p>' + shipOwner.comment + '</p>';
    shipOwner.emailPreview = shipOwner.email.replace(/\${comment}/g, commentEmail || '');
  }
  getMailTemplate() {
    this.mailTemplateService.readAll().subscribe({
      next: (templates) => {
        this.mailTemplates = templates;
        let template = this.mailTemplates.find((x) => x.name == MailTemplateTypes.OFFER_SEND_CHARGER);
        if (template) {
          this.template = template.content;
          this.subjectTemplate = template.subject;
        }
        this.mapOffershipowner();
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
  mapOffershipowner() {
    this.offerShipownerOriginal = new OfferShipOwner();
    this.offerShipownerOriginal.offerId = this.offer.id;
    this.offerShipownerOriginal.offer = this.offer;
    this.offerShipownerOriginal.email = '';
    this.offerShipownerOriginal.comment = '';
    this.offerShipownerOriginal.subject = '';
    this.offerShipownerOriginal.emailPreview = '';
  }
  getShipOwner(event: any, shipowner: ShipownerCSContactDto) {
    let tr: HTMLTableColElement = event.target;
    if (tr.childNodes.length == 0) {
      return;
    }
    this.selectedShipOwner = shipowner;
    this.modifyEmailAndPreviusElement(this.selectedShipOwner, tr);
  }
  modifyEmailAndPreviusElement(shipowner: ShipownerCSContactDto, tr: HTMLTableColElement) {
    this.setEmail(this.selectedShipOwner);

    if (this.previousElement && this.previousElement != tr) {
      this.previousElement.parentElement.classList.remove('bold');
    }
    tr.parentElement.classList.add('bold');
    this.previousElement = tr;
  }
  changeSelectSendEmail(shipowner: ShipownerCSContactDto) {
    if (shipowner.isSelected) {
      this.changeIsSelected(true, shipowner);
    } else {
      this.changeIsSelected(false, shipowner);
    }
  }
  changeIsSelected(selected: boolean, shipowner: ShipownerCSContactDto) {
    shipowner.contacts.forEach((item) => {
      item.isSelected = selected;
      item.isSelectedShipownerCS = selected;
    });
  }

  back() {
    if (!this.readonlyStep2) {
      let isTrueSave = false;
      if (this.selectedContactListCS != null && this.selectedContactListCS.shipOwners?.length > 0) {
        isTrueSave = this.searchForASelected();
      }
      if (isTrueSave) {
        this.sendOfferContact('back');
      } else {
        this.offer.comments = this.form.get('commentsAdd').value;
        this.offer.comments = this.offer.comments.replace(/<br>/g, '\n');
        this.offerService.edit(this.offer).subscribe((offerEdited) => {
          this.offer = offerEdited;
          this.router.navigateByUrl('firmOffer/cover/' + this.offer.id);
          this.resetVariables();
        });
      }
    } else {
      this.router.navigateByUrl('firmOffer/cover/' + this.offer.id);
    }
  }
  searchForASelected(): boolean {
    let isTrue = false;
    this.selectedContactListCS.shipOwners.forEach((item) => {
      if (item.isSelected) {
        this.setEmail(item);
        item.email = item.emailPreview;
        item.contacts.forEach((contact) => {
          if (contact.isSelected) {
            isTrue = true;
          }
        });
      }
    });
    return isTrue;
  }
  sendOfferContact(route: string) {
    if (this.selectedContactListCS.name === 'TODOS' || this.selectedContactListCS.name === 'ALL') {
      this.selectedContactListCS.name === 'TODOS';
    }
    this.offerContacts.contactList = this.selectedContactListCS;
    this.offerContacts.contactList.shipOwners = this.offerContacts.contactList.shipOwners.map((item) => {
      return item.isSelected ? { ...item, comment: item.comment && item.comment != '' ? item.comment.replace(/<br>/g, '\n') : '' } : item;
    });
    this.offerContacts.comments = this.form.get('commentsAdd').value;
    this.offerContacts.id = this.offer.id;

    this.offerService.updateOfferContactList(this.offerContacts).subscribe({
      next: (item) => {
        this.spinner.hide();
        var id = this.router.url.toString().split('/').pop();
        this.offerService.getById(id).subscribe({
          next: (x) => {
            this.offerContacts.contactList.shipOwners;
          },
          error: (err) => {
            console.log(err);
            this.spinner.hide();
          },
        });
        if (route === 'continue') {
          this.router.navigateByUrl('firmOffer/offerFinalView/' + this.offer.id);
        } else if (route === 'back') {
          this.router.navigateByUrl('firmOffer/cover/' + this.offer.id);
        }
      },
      error: (err) => {
        console.log(err);
        this.spinner.hide();
      },
    });
  }
  continue() {
    let messageContact = 'MESSAGES.ERROR.NO_SELECTED_CONTACT';
    let messageShipowner = 'MESSAGES.ERROR.NO_SELECTED_SHIPOWNER';
    if (!this.readonlyStep2) {
      this.spinner.show();
      if (this.selectedContactListCS != null && this.selectedContactListCS.shipOwners?.length > 0) {
        let isTrueSend = false;
        isTrueSend = this.searchForASelected();
        if (isTrueSend) {
          this.sendOfferContact('continue');
        } else {
          let isTrueShip = false;
          this.selectedContactListCS.shipOwners.forEach((item) => {
            if (item.isSelected) {
              isTrueShip = true;
              item.comment = item.comment.replace(/<br>/g, '\n');
            }
          });
          let message = isTrueShip ? messageContact : messageShipowner;
          this.spinner.hide();
          this.messagesService.showToast('ERROR', message, 'error', '');
        }
      } else {
        this.spinner.hide();
        this.messagesService.showToast('ERROR', 'MESSAGES.ERROR.NO_SELECTED_LIST', 'error', '');
      }
    } else {
      this.router.navigateByUrl('firmOffer/offerFinalView/' + this.offer.id);
    }
  }
  changeCheckContact(event, contact, shipowner) {
    if (event.checked) {
      shipowner.isSelected = true;
    } else {
      if (!shipowner.contacts.filter((x) => x.isSelected).length) {
        shipowner.isSelected = false;
      }
    }
    this.setEmail(shipowner);
  }

  filterData(event) {
    this.tableShipownersStep2.filteredValue = this.filtersTable.chargerShipownerContactFilter(
      event,
      this.shipownerlistTable,
      this.tableShipownersStep2,
      'step2'
    );
  }
  deleteOffer() {
    if (this.canDeleted) {
      let messageAccept = '';
      let trmessage = '';
      let trans = '';
      this.translate.get('COMMONS.ASK_DELETE_OFFER').subscribe((a: string) => {
        trmessage = a;
      });
      this.translate.get('ACTION.YES').subscribe((a: string) => {
        messageAccept = a;
      });
      this.confirmationService.confirm({
        message: trmessage,
        header: '',
        acceptLabel: messageAccept,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.spinner.show();
          this.offerService.delete(this.offer.id).subscribe({
            next: () => {
              this.spinner.hide();
              this.messagesService.showToast('OK', 'MESSAGES.SUCCESS.DELETE', 'success', '');
              this.router.navigateByUrl('home');
            },
            error: (err) => {
              console.log(err);
              this.spinner.hide();
            },
          });
        },
        reject: () => { },
      });
    }
  }
  showEmail() {
    this.showPreviewEmail = true;
  }
  changeSelectedShipownerAndShowPreviewEmail() {
    this.showPreviewEmail = !this.showPreviewEmail;
    this.selectedShipOwner = null;
  }
  onTextareaCommentsBlur() {
    this.offer.comments = this.form.get('commentsAdd').value;
    this.offer.comments = this.offer.comments.replace(/<br>/g, '\n');
    this.modifyEmailAndPreviusElement(this.selectedShipOwner, this.previousElement);
    this.showEmail();
  }
}
