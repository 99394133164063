import { Document } from './document.model'

export class FileElement {
  id!: string
  documentId?: string
  isFolder: boolean = false
  name!: string
  documentName!: string
  parentId!: string
  size!: string
  updatedDate!: string
  isVersion: boolean = false
  version!: string
  documentVersionId!: string
  extension?: string;
  timestamp?: any[];

  public mapDocument(document: Document){
    this.id = document.id;
    this.name = document.name;
    this.documentName = document.documentName;
    this.parentId = document.documentFolderId;
    this.documentId = document.documentGuid;
    this.isFolder = false;
    this.isVersion = document.isVersion;
    this.version = document.versionNumber;
    this.documentVersionId = document.documentVersionId;
    this.updatedDate = document.updatedDate;
    this.timestamp = document.timestamp;
    this.extension = document.documentName.split('.').pop();
  };
}