import { OfferShipownerLine } from "../offerShipOwnerLine/offer-shipowner-line.model";
import { Shipowner } from "../shipowner/shipowner.model";
import { Offer } from "./offer.model";
import { StateOfferShipOwner } from "../enum/state-offer-shipowner.model";
import { UserType } from "../enum/user-type.model";

export class OfferShipOwner {
  id?: string;
  offerId?: string;
  offer?: Offer;
  shipOwnerId?: string;
  shipOwner?: Shipowner;
  email?: string;
  emailPreview?: string;
  subject?: string;
  isModified: boolean;
  isSelected?: boolean;
  comment?: string;
  lines?: OfferShipownerLine[];
  state!: StateOfferShipOwner;
  expirationDate?: Date;
  initialShipOwnerClauses?: string;
  addressCom?: number;
  shift?: UserType;
  counter?: number;
  originalOffer?: Offer;
  imo?: string;

  constructor(
    offer?: Offer,
    shipowner?: Shipowner,
    email?: string,
    isModified?: boolean
  ) {
    this.offerId = offer?.id || undefined;
    //this.offer = offer;
    this.shipOwnerId = shipowner?.id || undefined;
    this.shipOwner = shipowner || new Shipowner();
    this.email = email || undefined;
    this.isModified = isModified || false;
    this.lines = this.lines || [];
  }
}
