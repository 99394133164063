import { Charger } from "./charger.model";
import { User } from "@overa/security";
import { contactType } from "../enum/contact-type";
import { TypeContactOld } from "../enum/type-contact-old";
export class ChargerContact {
    id?: string;
    name?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    isPrincipal?: boolean;
    type?: TypeContactOld;
    types?: contactType[];
    chargerId?: string;
    charger?: Charger;
    userId?: string;
    isEnabled?: boolean;
    termsAndConditionsAceptationDate?: Date;
    privacyPolicyAceptationDate?: Date;
    isLockedOut?: boolean;
    editable?: boolean;
    roles?: any[];
    isBanned?: boolean;
    isNew?: boolean;
    constructor() {

    }


}