import { Injectable } from "@angular/core";
import { Table } from "primeng/table";
import { ChargerShipOwner } from "../models/charger-shipowner/charger-shipowner.model";

@Injectable({
  providedIn: "root",
})
export class Filters {
  constructor() { }

  public chargerShipownerContactFilter(
    event: any,
    listShipowners: any[],
    tableShipowners?: Table,
    nameView?: string
  ): any[] {
    console.log("EVENT", event);
    console.log("LIST", listShipowners);
    console.log("TABLE", tableShipowners);
    const value = event.target ? event.target?.value.toLowerCase() : event.toLowerCase();
    console.log("VALUE", value);
    var filteredShipowners: any[] = [];
    if (value.trim() == "") {
      filteredShipowners = [...listShipowners];
      if (tableShipowners) {
        tableShipowners.filteredValue = filteredShipowners;
      }

      return filteredShipowners;
    }
    var foundShipowner = false;
    listShipowners.forEach((sh) => {
      if (sh.charteringCompany?.toLowerCase().includes(value)) {
        filteredShipowners.push(sh);
        foundShipowner = true;
      }
    });
    if (!foundShipowner) {
      listShipowners.forEach((sh) => {
        var list;
        if (nameView === "step2") {
          list = sh.contacts;
        } else if (nameView === "shipowners") {
          list = sh.chargerShipOwnerContacts;
        }
        var contactsFiltered = list.filter(
          (x: any) =>
            x.email.toLowerCase().includes(value) ||
            x.name.toLowerCase().includes(value) ||
            x.lastName.toLowerCase().includes(value) ||
            x.phone?.toLowerCase().includes(value)
        );
        if (contactsFiltered.length) {
          var shipownerAd = { ...sh };
          if (nameView === "step2") {
            shipownerAd.contacts = [];
          } else if (nameView === "shipowners") {
            shipownerAd.chargerShipOwnerContacts = [];
          }

          var filteredShipowner = filteredShipowners.find((x) => x.id == sh.id);
          contactsFiltered.forEach((contact: any) => {
            if (nameView === "step2") {
              shipownerAd.contacts.push(contact);
            } else if (nameView === "shipowners") {
              shipownerAd.chargerShipOwnerContacts.push(contact);
            }
          });
          if (!filteredShipowner) {
            filteredShipowner = filteredShipowners.push(shipownerAd);
          } else {
            // filteredShipowner.chargerShipOwnerContacts.push(contact);
          }
        }
      });
    }
    if (listShipowners.filter((x) => x.isNew).length) {
      filteredShipowners = [listShipowners.find((x) => x.isNew)].concat(
        filteredShipowners
      );
    }
    return filteredShipowners;
  }
}
