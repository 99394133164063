import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { KanbanTask, TaskButon } from "src/app/models/kanban-task.model";
import { Offer } from "src/app/models/offers/offer.model";
import { QuantityType } from "src/app/models/enum/quantity-type.model";
import { ViewType } from "src/app/models/enum/view-type.model";
import { CLHGlobals } from "src/app/utils/globals";

@Component({
  selector: "app-kanban-element",
  templateUrl: "./kanban-element.component.html",
  styleUrls: ["./kanban-element.component.scss"],
})
export class KanbanElementComponent implements OnInit {
  @Input() headerTitle = "";
  @Input() cssClass = "";
  @Input() backgroundClass = "";
  @Input() title = "";
  @Input() taskList: KanbanTask[] = [];
  @Input() foterText = "";
  @Input() svgPath = "";
  @Input() buttons: TaskButon[] = [];
  @Input() listOffers: any[] = [];
  @Input() isDetail: boolean = false;
  @Input() offerId!: string;
  @Input() offerShipOwnerId?: string;
  @Output()
  onSelectKanban: EventEmitter<KanbanTask> = new EventEmitter<KanbanTask>();
  @Output()
  onUnSelectKanban: EventEmitter<KanbanTask> = new EventEmitter<KanbanTask>();
  expanded = false;
  readonly QuantityType = QuantityType;
  storageId = "";
  viewNegotiation: ViewType = ViewType.NEGOTIATION;
  viewRecap: ViewType = ViewType.RECAP;
  viewOperative: ViewType = ViewType.OPERATIVE;
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private globals: CLHGlobals
  ) {}

  ngOnInit(): void {
    this.storageId = this.globals.storageId;
    // console.log(this.taskList);
  }
  selectKanban(task: KanbanTask) {
    this.onSelectKanban.emit(task);
  }
  minimizeKanban(task: KanbanTask) {
    this.onUnSelectKanban.emit(task);
  }
}
