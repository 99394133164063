import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { OveraMessagesService } from "@overa/components/info-dialog";
import { ConfirmationService, MessageService } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Offer } from "src/app/models/offers/offer.model";
import { Shipowner } from "src/app/models/shipowner/shipowner.model";
import { ShipOwnerContact } from "src/app/models/shipowner/shipowner-contact.model";
import { OfferService } from "src/app/services/offer.service";
import { CLHGlobals } from "src/app/utils/globals";
import { ExpirationDateDialogComponent } from "../expiration-date-dialog/expiration-date-dialog.component";
import { ExpirationDateResponse } from "src/app/models/offers/expiration-date-response.model";
import { ExpirationDate } from "src/app/models/offers/expiration-date.model";
import { NgxSpinnerService } from "ngx-spinner";
import { StateOfferShipOwner } from "src/app/models/enum/state-offer-shipowner.model";
import { ClhValidators } from "src/app/utils/validators";
import { TranslateService } from "@ngx-translate/core";
import { ContactListChargerShipownerContact } from "../../../../models/charger-shipowner/contact-list-charger-shipowner-contact.model";
import { PermissionsService } from "../../../../services/permissions.service";
import { NgxTranslateService } from "@overa/shared";

@Component({
  selector: "app-step3-offer",
  templateUrl: "./step3-offer.component.html",
  styleUrls: ["./step3-offer.component.scss"],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class Step3OfferComponent implements OnInit {
  offer: Offer;
  offerUpdate: Offer;
  shipOwnerList: Shipowner[] = [];
  shipOwnerContactList: ShipOwnerContact[] = [];
  form: FormGroup;
  isLoading: boolean = false;
  isShow: boolean = false;
  ref: DynamicDialogRef;
  expirationDate: Date;
  statusNone = [StateOfferShipOwner.NONE];
  readonlyStep3: boolean = false;
  today: Date = new Date();
  checked: boolean = false;
  contactList: ContactListChargerShipownerContact;
  fileData: any[] = [];
  loadedFiles = false;
  fileList: any[] = [];
  isCharger = false;
  canDeleted: boolean = true;
  constructor(
    private router: Router,
    private offerService: OfferService,
    private messagesService: OveraMessagesService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private globals: CLHGlobals,
    private dialogServicePrime: DialogService,
    private spinner: NgxSpinnerService,
    private validators: ClhValidators,
    private fb: FormBuilder,
    private permissionsService: PermissionsService,
    private translate: NgxTranslateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    const id = this.router.url.toString().split("/").pop();
    console.log(id);
    this.initialForm();
    const charger = this.globals.currentCharger;
    if (charger) {
      this.isCharger = true;
    }
    if (id) {
      this.offerService.getById(id).subscribe({
        next: (x) => {
          this.offer = x;
          console.log("offer", this.offer);
          this.offerService.getOfferContactListById(this.offer.id).subscribe({
            next: (contactList) => {
              console.log(contactList);
              this.contactList = contactList;
              if (!this.permissionsService.hasPermissionToEdit()) {
                this.readonlyStep3 = true;
              }
              this.offer.offerShipOwners.forEach((s) => {
                if (!this.statusNone.includes(s.state)) {
                  this.readonlyStep3 = true;
                  this.canDeleted = false;
                  if (s.expirationDate) {
                    console.log(s.expirationDate);
                    this.checked = true;
                    this.form.get("checkedExpirationDate").setValue(true);
                    this.form
                      .get("expirationDate")
                      .setValue(new Date(s.expirationDate));
                  }
                }
                if (this.isCharger) {
                  s.shipOwner = charger.shipOwners.find(
                    (c) => c.shipOwnerId == s.shipOwnerId
                  ).shipOwner;
                }
              });

              this.offerUpdate = this.offer;
              console.log("offer", this.offer);
              console.log("offer", this.offer.offerShipOwners);
              this.offer.offerShipOwners.forEach((o) => {
                // o.comment = ostra
              });
              if (!this.permissionsService.hasPermissionToEdit(this.route)) {
                this.readonlyStep3 = true;
                this.canDeleted = false;
              }
              this.isShow = true;
            },
            error: (err) => {
              console.log(err);
              this.spinner.hide();
            },
          });
        },
        error: (err) => {
          console.log(err);
          this.spinner.hide();
        },
      });
    }
  }

  back() {
    console.log("back");
    if (!this.readonlyStep3) {
      this.offerService.edit(this.offer).subscribe((offerEdited) => {
        this.router.navigateByUrl(
          "firmOffer/shipownersSelector/" + this.offer.id
        );
      });
    } else {
      this.router.navigateByUrl(
        "firmOffer/shipownersSelector/" + this.offer.id
      );
    }
  }

  continue() {
    if (!this.readonlyStep3) {
      const trans = this.checked
        ? "COMMONS.ASK_EXPIRATIONDATE"
        : "COMMONS.ASK_NO_EXPIRATIONDATE";

      const trmessage = this.translate.get(trans);
      const messageAccept = this.translate.get("ACTION.YES");

      this.confirmationService.confirm({
        message: trmessage,
        header: "",
        acceptLabel: messageAccept,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.sendContinue();
        },
        reject: () => {
          console.log("reject");
        },
      });
    } else {
      this.router.navigate(["home"]);
    }
  }

  sendContinue() {
    this.isLoading = true;
    const expirationDateResponse = new ExpirationDateResponse();
    const expirationDate = this.form.get("expirationDate").value;
    const checkedExpirationDate = this.form.get("checkedExpirationDate").value;
    expirationDateResponse.date = checkedExpirationDate ? expirationDate : null;

    console.log("expirationDateResponse", expirationDateResponse);
    if (expirationDateResponse) {
      this.spinner.show();
      if (!expirationDateResponse.date) {
        console.log("enviar solo emails");
        this.spinner.hide();
        this.sendEmailShipOwners();
      } else {
        console.log("enviar con fecha");

        this.offerService
          .setExpirationDate({
            expirationDate: expirationDateResponse.date.toISOString(),
            offerId: this.offer.id,
          })
          .subscribe({
            next: () => {
              this.spinner.hide();
              this.sendEmailShipOwners();
            },
            error(err) {
              console.log(err);
              this.messagesService.showToast(
                "ERROR",
                "MESSAGES.ERROR.NO_UPDATE_OFFER_EXPIRATIONDATE",
                "error",
                ""
              );
              this.isLoading = false;
              this.spinner.hide();
            },
          });
      }
    } else {
      console.log("cerrar");
      this.isLoading = false;
    }
  }
  sendEmailShipOwners() {
    let sendEmail = true;
    //  this.shipOwnerList = this.offer.offerShipOwners.map(s => s.shipOwner);
    this.shipOwnerList = this.contactList.contactList.shipOwners;
    if (this.shipOwnerList.length > 0) {
      this.shipOwnerList.forEach((ship) => {
        console.log(ship);
        if (ship != null) {
          this.shipOwnerContactList = ship.contacts;
          console.log(this.shipOwnerContactList);
          // this.shipOwnerContactList.filter(s => s.isPrincipal == true);
          sendEmail = this.shipOwnerContactList.length > 0 ? true : false;
        }
      });
    } else {
      sendEmail = false;
    }

    if (sendEmail) {
      this.spinner.show();
      this.offerService.sendEmail(this.offer.id).subscribe({
        next: () => {
          this.messagesService.showToast(
            "INFO",
            "MESSAGES.SUCCESS.UPDATE_EMAIL",
            "success",
            ""
          );
          this.router.navigate(["home"]);
          this.isLoading = false;
          this.spinner.hide();
        },
        error: (err) => {
          console.log(err);
          this.messagesService.showToast(
            "ERROR",
            "MESSAGES.ERROR.BAD_REQUEST_BODY",
            "error",
            ""
          );
          this.isLoading = false;
          this.spinner.hide();
        },
      });
    } else {
      if (this.shipOwnerList.length) {
        this.messagesService.showToast(
          "ERROR",
          "MESSAGES.ERROR.NO_PRINCIPAL_SHIPOWNER",
          "error",
          ""
        );
      } else {
        this.messagesService.showToast(
          "ERROR",
          "MESSAGES.ERROR.NO_SELECTED_SHIPOWNER",
          "error",
          ""
        );
      }
      this.isLoading = false;
    }
  }
  ngOnDestroy() {
    console.log("destroy");
  }
  sendExpirationDate(event) {
    this.checked = event.checked;
    if (this.checked) {
      this.form.get("expirationDate")?.enable();
    } else {
      this.form.get("expirationDate")?.disable();
    }
  }
  initialForm() {
    this.form = this.fb.group(
      {
        expirationDate: new FormControl(this.today, []),
        checkedExpirationDate: new FormControl(this.checked, []),
        files: new FormControl([], []),
      },
      { validators: [this.validators.expirationDateValidator()] }
    );
    this.form.get("expirationDate")?.disable();
  }
  deleteOffer() {
    if (this.canDeleted) {
      this.spinner.show();
      this.offerService.delete(this.offer.id).subscribe({
        next: () => {
          this.spinner.hide();
          this.messagesService.showToast(
            "OK",
            "MESSAGES.SUCCESS.DELETE",
            "success",
            ""
          );
          this.router.navigateByUrl("home");
        },
        error: (err) => {
          console.log(err);
          this.spinner.hide();
        },
      });
    }
  }
}
