<ngx-spinner></ngx-spinner>
<p-toast></p-toast>
<p-confirmDialog
  styleClass="containerConfirmDialog"
  acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"
></p-confirmDialog>
<div class="card step1-offer">
  <ng-container *ngIf="offer && form">
    <ng-container>
      <app-table-offer [form]="form" [offer]="offer" [readonly]="readonlyStep1"></app-table-offer>
    </ng-container>
    <ng-container>
      <div class="containerButtonStep1">
        <div class="div-btn-cancel">
          <div>
            <button pButton label="{{'ACTION.CANCEL' | translate}}" routerLink="home" (click)="cancelForm()" class="p-button cancelar p-ripple">
            </button>
          </div>
        </div>
        <div class="div-btn-next">
          <div >
            <button pButton type="button"
              pButtonSig 
              label="{{'ACTION.NEXT' | translate}}"
              (click)="nextPage()"
              class="p-button siguiente actualizar p-ripple"
              [disabled]="isLoading || !form.valid"
            ></button>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
