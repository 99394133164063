<ngx-spinner></ngx-spinner>
<p-toast></p-toast>
<p-confirmDialog
  styleClass="containerConfirmDialog"
  acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"
></p-confirmDialog>
<div class="card">
  <div class="card" *ngIf="isLoaded" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="10px">
    <div fxFlex fxFlex.gt-sm="66" fxFlex.lt-md="100" fxShow [fxHide.xs]="showPreviewEmail" [fxHide.sm]="showPreviewEmail">
      <div fxLayout="column" fxLayoutAlign="start" class="step2container mt-2">
        <div class="col-12 row text-center" fxFlex.gt-sm="100" [fxHide.xs]="showPreviewEmail" [fxHide.sm]="showPreviewEmail" fxLayoutAlign="center">
          <div class="header w-100">{{'STEP2_OFFER.SHIPOWNERS' | translate}}</div>
        </div>

        <!-- LIST & SEARCH SAME ROW -->

        <div class="container">
          <div class="row">
            <!-- LIST  -->
            <div class="col-12 col-xxl-4 col-xl-4 col-md-4 col-sm-12 mt-2">
              <div class="w-100" *ngIf="listAllListNames">
                <span class="p-float-label">
                  <p-autoComplete
                    id="float-inputListTableStep2"
                    [(ngModel)]="textAutocomplete"
                    appendTo="body"
                    [suggestions]="listAllListNames"
                    (completeMethod)="filterlistShipowners($event)"
                    [minLength]="1"
                    (onSelect)="onItemSelect($event)"
                    [dropdown]="true"
                    styleClass="listAutocompleteStep2"
                    panelStyleClass="panelListAutocompleteStep2"
                    [disabled]="readonlyStep2"
                    [escape]="false"
                    [pTooltip]="'TOOLTIP.LIST_STEP2'| translate"
                    tooltipPosition="bottom"
                    tooltipStyleClass="tooltipCLH"
                    [showDelay]="1000"
                    [hideDelay]="1000"
                  ></p-autoComplete>
                  <label for="float-inputListTableStep2">{{ 'STEP2_OFFER.LIST' | translate }}</label>
                </span>
              </div>
            </div>
            <!--END LIST  -->
            <!--SEARCH  -->
            <div class="col-12 col-xxl-8 col-xl-8 col-md-8 col-sm-12 mt-2">
              <div class="w-100">
                <span class="p-input-icon-right" style="width: 100%">
                  <i class="pi pi-search" style="top: 35%"></i>
                  <input style="margin-right: 1em" class="w-100" pInputText type="text" (input)="filterData($event)" />
                </span>
              </div>
            </div>
            <!--END SEARCH  -->
          </div>
        </div>

        <!-- END LIST & SEARCH SAME ROW -->

        <div class="row" style="padding: 0.75rem" fxFlex="33%" [fxHide.xs]="showPreviewEmail" [fxHide.sm]="showPreviewEmail" fxLayout="column">
          <div class="col-8 p-3"></div>
        </div>

        <div class="content row w-100" fxLayout="row">
          <!-- TODO: styles height get 100% of visible screen -->
          <div class="col-8 table-shipowners" fxFlex [fxHide.xs]="showPreviewEmail" [fxHide.sm]="showPreviewEmail">
            <div style="padding: 0.75rem">
              <ng-container>
                <p-table
                  *ngIf="shipownerlistTable.length >= 0"
                  [columns]="columns"
                  [value]="shipownerlistTable"
                  dataKey="charteringCompany"
                  styleClass="p-datatable-striped listTableStep2ChargerShipownerContact clh-table-grouped"
                  responsiveLayout="scroll"
                  #tableShipownersStep2
                  [globalFilterFields]="['charteringCompany']"
                  [responsive]="true"
                  responsiveLayout="scroll"
                  [autoLayout]="true"
                  [paginator]="true"
                  [rows]="10"
                  currentPageReportTemplate="{{'PRIMENGCALENDAR.showingRecords' | translate}}"
                  [rowsPerPageOptions]="[10,30,50]"
                  [showCurrentPageReport]="true"
                  paginatorDropdownAppendTo="body"
                >
                  <ng-template pTemplate="header" let-columns>
                    <tr>
                      <th
                        *ngFor="let col of columns"
                        [ngClass]="'step2TableTh'+col.sort"
                        class="line-border-right"
                        [escape]="false"
                        [pTooltip]="col.tooltip| translate"
                        tooltipPosition="bottom"
                        tooltipStyleClass="tooltipCLH"
                        [showDelay]="1000"
                        [hideDelay]="1000"
                      >
                        {{col.id | translate}}
                      </th>
                    </tr>
                    <tr>
                      &nbsp;
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-shipowner let-expanded="expanded">
                    <tr class="campo" *ngIf="shipowner" [pSelectableRow]="shipowner">
                      <ng-container *ngFor="let col of columns">
                        <td [ngClass]="'step2TableTd'+col.sort" *ngIf="col.sort === 'select'">
                          <p-inputSwitch
                            styleClass="switchCLH"
                            [(ngModel)]="shipowner.isSelected"
                            (onChange)="changeSelectSendEmail(shipowner)"
                            [readonly]="readonlyStep2"
                          ></p-inputSwitch>
                        </td>
                        <td *ngIf="col.sort === 'expandedRow'">
                          <button
                            type="button"
                            pButton
                            pRipple
                            [pRowToggler]="shipowner"
                            class="p-button-text p-button-rounded p-button-plain"
                            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-up'"
                          ></button>
                        </td>
                        <td
                          [ngClass]="'step2TableTd'+col.sort"
                          *ngIf="col.sort === 'charteringCompany'"
                          (click)="getShipOwner($event,shipowner);showEmail()"
                          [pTooltip]="'STEP2_OFFER.MESSAGE_SHOW_PREVIEW_EMAIL'| translate"
                          tooltipStyleClass="tooltipCLH"
                          [tooltipOptions]="{showDelay: 1000}"
                          tooltipPosition="top"
                        >
                          {{shipowner.charteringCompany}}
                        </td>
                        <td [ngClass]="'step2TableTd'+col.sort" *ngIf="col.sort==='commentEmail'" (click)="showComment(shipowner)">
                          <span role="button" *ngIf="shipowner.isSelected && !shipowner.comment" class="material-icons corporate-color notranslate">
                            chat_bubble_outline
                          </span>
                          <span role="button" *ngIf="shipowner.isSelected && shipowner.comment" class="material-icons corporate-color notranslate">
                            chat
                          </span>
                          <!--<i *ngIf="shipowner.isSelected" [ngClass]="shipowner.comment ? 'pi pi-circle-fill': 'pi pi-comment'"></i>-->
                        </td>
                      </ng-container>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="rowexpansion" let-shipowner>
                    <tr>
                      <td colspan="4" class="inner-background-expanded">
                        <div class="p-3">
                          <p-table [value]="shipowner.contacts" dataKey="email" styleClass="datatable-expansion">
                            <ng-template pTemplate="header">
                              <tr>
                                <th></th>

                                <th pSortableColumn="name">{{'CONTACTS.FORM.NAME'| translate}} <p-sortIcon field="name"></p-sortIcon></th>
                                <th pSortableColumn="lastName">{{'USERS.FORM.LAST_NAME'| translate }} <p-sortIcon field="lastName"></p-sortIcon></th>
                                <th pSortableColumn="email">{{'CONTACTS.FORM.EMAIL'| translate }} <p-sortIcon field="email"></p-sortIcon></th>
                                <th pSortableColumn="phone">{{'CONTACTS.FORM.PHONE'| translate}} <p-sortIcon field="phone"></p-sortIcon></th>
                                <th pSortableColumn="comment">
                                  {{'CHARTERER_DATA_SHEET.MAINTENANCE_OWNERS.COMMENT' | translate}} <p-sortIcon field="amount"></p-sortIcon>
                                </th>
                              </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-contact>
                              <tr class="example-list">
                                <td>
                                  <p-checkbox
                                    [(ngModel)]="contact.isSelected"
                                    [binary]="true"
                                    inputId="checkedDefault"
                                    class="checkShipownerCharger"
                                    (onChange)="changeCheckContact($event, contact, shipowner)"
                                    [readonly]="readonlyStep2"
                                  >
                                  </p-checkbox>
                                </td>
                                <td>{{contact.name}}</td>
                                <td>{{contact.lastName}}</td>
                                <td>{{contact.email}}</td>
                                <td>{{contact.phone}}</td>
                                <td>{{contact.comment}}</td>
                              </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                              <tr>
                                <td colspan="1"></td>
                              </tr>
                            </ng-template>
                          </p-table>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex fxFlex.gt-sm="33" fxFlex.lt-md="100" [fxHide.xs]="!showPreviewEmail" [fxHide.sm]="!showPreviewEmail">
      <div
        fxLayout="column"
        fxLayoutAlign="start"
        fxFlex
        fxShow
        [fxHide.xs]="!showPreviewEmail"
        [fxHide.sm]="!showPreviewEmail"
        class="emailContent step2container mt-2"
      >
        <div class="w-100" fxLayoutAlign="center">
          <div class="header w-100" fxFlex fxFlex.gt-sm="100" fxFlex.xs="100" fxFlex.sm="100" fxLayoutAlign="center">
            <p
              [escape]="false"
              [pTooltip]="'TOOLTIP.EMAIL_PREVIEW_STEP2'| translate"
              tooltipPosition="bottom"
              tooltipStyleClass="tooltipCLH"
              [showDelay]="1000"
              [hideDelay]="1000"
            >
              {{'STEP2_OFFER.PREVIEW_EMAIL' | translate}}
            </p>
          </div>
        </div>
        <div
          class="email-preview-container"
          fxLayout="column"
          fxLayoutAlign="start"
          fxShow.gt-sm
          [fxHide.xs]="!showPreviewEmail"
          [fxHide.sm]="!showPreviewEmail"
        >
          <ng-container *ngIf="selectedShipOwner && showPreviewEmail">
            <div fxShow fxHide.gt-sm>
              <button
                class="btn btn-link btn-collapse btn-cancelEmail"
                type="button"
                *ngIf="showPreviewEmail"
                (click)="showPreviewEmail = !showPreviewEmail"
              >
                <span class="pi" [ngClass]="{'pi-chevron-right': !showPreviewEmail, 'pi-chevron-left': showPreviewEmail}"></span>
              </button>
            </div>
            <div class="cross-hide-email" sty fxHide fxShow.gt-sm>
              <button
                class="btn btn-link btn-collapse btn-cancelEmail"
                type="button"
                *ngIf="showPreviewEmail"
                (click)="changeSelectedShipownerAndShowPreviewEmail()"
              >
                <span class="pi pi-times"></span>
              </button>
            </div>

            <ng-container *ngIf="selectedShipOwner.isSelected">
              <div class="email-preview-reference">REF: {{offer.refOffer}}</div>
              <div class="email-preview-title">{{ 'STEP2_OFFER.OFFER_FIRM' | translate}}</div>

              <div
                class="preview-subject emailContentAndSubject email-preview-greeting"
                [innerHtml]="selectedShipOwner.subject | safeHtml:true"
              ></div>
              <div class="preview-content emailContentAndSubject" [innerHtml]="selectedShipOwner.emailPreview | safeHtml:true"></div>
            </ng-container>

            <ng-container *ngIf="!selectedShipOwner.isSelected && showPreviewEmail">
              {{ 'STEP2_OFFER.MESSAGE_NO_SEND_OFFER'| translate}}
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!selectedShipOwner && !showPreviewEmail"> {{ 'STEP2_OFFER.MESSAGE_SELECT_SHIPOWNER'| translate}} </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid mt-5" [formGroup]="form">
    <clh-textarea
      [form]="form"
      [nameControl]="'commentsAdd'"
      [errorLabel]="''"
      [idTextarea]="'step2commentsAdd'"
      [placeholder]=""
      [readOnly]="readonlyStep2"
      [classTextarea]="'textAreaTableOffer clhTextarea'"
      [style]="'width:100%; min-height:120px;'"
      [label]="'STEP1_OFFER.ADDITIONAL_COMMENTS'"
      [minHeight]="120"
      [showLabelFloat]="true"
      [escape]="false"
      [pTooltip]="'TOOLTIP.ADITIONAL_COMMENT_STEP2' | translate"
      [tooltipPosition]="'top'"
      [tooltipStyleClass]="'tooltipCLH'"
      [showDelay]="1000"
      [hideDelay]="1000"
      (textAreaBlur)="onTextareaCommentsBlur()"
    ></clh-textarea>
    <label><b>{{'STEP1_OFFER.ADDITIONAL_COMMENTS_MESSAGE'| translate}}</b></label>
  </div>
  <div class="containerButtonDeleteLeft">
    <div *ngIf="canDeleted" class="containerButtonStep1">
      <div>
        <button type="button" pButton label="{{'COMMONS.DELETE_2' | translate}}" class="p-button p-ripple borrar" (click)="deleteOffer()"></button>
      </div>
    </div>
    <div class="containerButtonStep1">
      <div class="div-btn-cancel">
        <div>
          <button pButton label="{{'ACTION.PREVIOUS' | translate}}" class="p-button cancelar p-ripple" (click)="back()"></button>
        </div>
      </div>
      <div class="div-btn-next">
        <div>
          <button
            pButton
            type="button"
            pButtonSig
            label="{{'ACTION.NEXT' | translate}}"
            (click)="continue()"
            class="p-button siguiente actualizar p-ripple"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>
