import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ClauseNegotiation } from "src/app/models/clause/clause-negotiation.model";
import { NegotiationFieldState } from "src/app/models/offers/negotiation-field-state.model";
import { NegotiationOfferShipOwner } from "src/app/models/offers/negotiation-offer-shipowner.model";
import { ConfirmationService } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { NgxTranslateService } from "@overa/shared";

@Component({
  selector: "app-buttons-offer",
  templateUrl: "./buttons-offer.component.html",
  styleUrls: ["./buttons-offer.component.scss"],
  providers: [ConfirmationService],
})
export class ButtonsOfferComponent implements OnInit {
  readonly NegotiationFieldState = NegotiationFieldState;
  isShipownerBindingButton: boolean = false;
  readOnly = true;
  isEditting = false;
  hasApproved = false;
  isDeleted = false;
  isDeletedInitial = false;
  hadChangeAnyTime = false;
  // showCheck = false;

  @Input() isApproved: boolean = false;
  @Input() value: any;
  @Input() negotiationClause!: ClauseNegotiation;
  @Input() disabled = false;
  @Input() field: string = "";
  @Input() isClause: boolean = false;
  @Output() approveField = new EventEmitter<any>();
  @Output() undoField = new EventEmitter<any>();
  @Output() undoApprove = new EventEmitter<any>();
  @Output() onEdit = new EventEmitter<boolean>();
  @Output() onReopen = new EventEmitter<any>();
  @Output() onSave = new EventEmitter<any>();
  @Output() onRemove = new EventEmitter<any>();
  @Output() onActive = new EventEmitter<any>();

  isApprovedInitial = false;
  initialValue: any;
  hadReopened = false;
  hadChanged = false;
  //TODO Borrar cuando confirmen que no va en negociacion
  // changeFreightTargetCurrency=false;
  constructor(
    private confirmationService: ConfirmationService,
    private translate: NgxTranslateService
  ) {}

  ngOnInit() {
    // console.log(this.field,this);
    // if(this.isClause){
    //   if(this.negotiationClause){
    //     if(this.negotiationClause.isRefused){
    //       if(this.negotiationClause.approvedRefused){
    //         this.isApproved=true;
    //         this.isDeleted=false;
    //         this.isDeletedInitial=true;
    //       }else{
    //         this.isDeleted=true;
    //         this.isApproved=false;
    //         this.isEditting = false;
    //         this.canRemove=false;
    //         this.isDeletedInitial=true;
    //       }

    //     }
    //   }
    // }
    if (this.isClause) {
      this.isDeleted = this.negotiationClause.isRefused;
    }
    this.isApprovedInitial = this.isApproved;
    this.initialValue = this.value;
    // console.log('isEditting-isApproved-TrueisApprovedInitial-hasApproved-hadChanged-deleted',this.isEditting,this.isApproved,this.isApprovedInitial,this.hasApproved,this.hadChanged,this.isDeleted);
  }
  onUndoApprove() {
    this.isApproved = false;
    this.hasApproved = false;
    this.undoApprove.emit({ field: this.field });
  }
  onApproveField() {
    this.isApproved = true;
    this.hasApproved = true;
    this.approveField.emit({
      isApproved: true,
      isDeleted: this.isDeleted,
      isDeletedInitial: this.isDeletedInitial,
      field: this.field,
    });
  }
  // changeEdit(propertyState: string, propertyLabel: string) {
  //   console.log('propertyLabel',propertyLabel);
  //   this.negotiationOfferBinding[propertyLabel] =
  //     !this.negotiationOfferBinding[propertyLabel];
  // }
  reOpen() {
    //TODO translate
    let trmessage = this.translate.get("COMMONS.ASK_REOPEN");
    let messageAccept = this.translate.get("ACTION.YES");

    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.isEditting = true;

        this.hadReopened = true;

        //this.isApproved = false;
        console.log(this.field, this);
        this.onReopen.emit({ field: this.field });
      },
      reject: () => {},
    });
  }
  setEditting(editting: boolean) {
    if (!this.isApproved && !this.hasApproved) {
      this.isEditting = editting;
      this.isApproved = false;
      this.onEdit.emit(editting);
    } else {
    }
  }
  confirmEdit() {
    const propertyState = this.field + "State";
    this.isEditting = false;
    // console.log('value', this.value, this.initialValue);
    var hadReopened = false;
    if (this.field == "layCan") {
      if (
        this.value[0].toDateString() != this.initialValue[0].toDateString() ||
        this.value[1].toDateString() != this.initialValue[1].toDateString()
      ) {
        this.hadChanged = true;
        this.isApproved = false;
        if (this.hadReopened) {
          hadReopened = true;
        }
      } else {
        this.hadChanged = false;
      }
    } else {
      if (JSON.stringify(this.value) != JSON.stringify(this.initialValue)) {
        this.hadChanged = true;
        this.isApproved = false;
        if (this.hadReopened) {
          hadReopened = true;
        }
        //TODO Borrar cuando confirmen que no va en negociacion
        /*
        if (this.field == 'freight') {
          let value = JSON.stringify(this.value).split(' ');
          let valueInitial = JSON.stringify(this.initialValue).split(' ');
          console.log(value,valueInitial)
          if (value[0] != valueInitial[0]) {
            console.log('cambio en currency');
          this.changeFreightTargetCurrency=true;
          
          // this.onSave.emit({ field: 'demurrage', hadReopened: hadReopened, hadChanged: this.hadChanged });
          }
        }
        */
      } else {
        this.hadChanged = false;
      }
    }
    if (this.isClause) {
      if (
        this.negotiationClause.refusedInitial != this.isDeleted ||
        this.value != this.initialValue
      ) {
        this.hadChanged = true;
      }
    }
    //TODO Borrar cuando confirmen que no va en negociacion
    /*
    if(this.field == 'freight' && this.changeFreightTargetCurrency){
      this.onSave.emit({ field: this.field, hadReopened: hadReopened, hadChanged: this.hadChanged,changeFreightTargetCurrency:this.changeFreightTargetCurrency });
    }else{
      this.onSave.emit({ field: this.field, hadReopened: hadReopened, hadChanged: this.hadChanged });
    }*/

    this.onSave.emit({
      field: this.field,
      hadReopened: hadReopened,
      hadChanged: this.hadChanged,
    });
  }

  undoChange() {
    if (this.isClause) {
      this.isDeleted = this.negotiationClause.refusedInitial;
    }
    console.log("thisUndochange", this);
    var isApproved = undefined;
    if (this.isApprovedInitial) {
      console.log("initial");
      //this.isApproved = true;
      this.undoField.emit({
        field: this.field,
        isDeleted: this.isDeleted,
        hadChange: this.hadChangeAnyTime,
        isApproved: true,
        undoReopen: true,
      });
    }
    if (this.hadChangeAnyTime) {
      isApproved = false;
    }
    if (this.isDeleted) {
      console.log("this", this);
      this.undoField.emit({
        field: this.field,
        isDeleted: this.isDeleted,
        isDeletedInitial: this.isDeletedInitial,
        isApproved: isApproved,
        hadChange: this.hadChangeAnyTime,
      });
    } else {
      console.log("else");
      this.undoField.emit({
        field: this.field,
        isDeleted: this.isDeleted,
        hadChange: this.hadChangeAnyTime,
        isApproved: isApproved,
      });
    }
  }

  removeField(field: any) {
    this.negotiationClause.isRefused = true;
    this.isDeleted = true;
    if (
      this.negotiationClause.isRefused != this.negotiationClause.refusedInitial
    ) {
      this.hadChanged = true;
    } else {
      this.hadChanged = false;
    }
    this.onRemove.emit({ field: this.field, isDeleted: this.isDeleted });
  }
  activeField() {
    this.negotiationClause.isRefused = false;
    this.isDeleted = false;
    if (
      this.negotiationClause.isRefused != this.negotiationClause.refusedInitial
    ) {
      this.hadChanged = true;
    } else {
      this.hadChanged = false;
    }
    this.onActive.emit({ field: this.field, isDeleted: this.isDeleted });
  }
}
