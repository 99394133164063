import { Charger } from "../charger/charger.model";
import { FreightTargetCurrency } from "../enum/freight-target-currency.model";
import { FreightTargetType } from "../enum/freight-target-type.model";
import { OfferState } from "../enum/offer-state.model";
import { OfferShipOwner } from "./offer-shipowner.model";
import { QuantityType } from "../enum/quantity-type.model";

export class Offer {
  id?: string;
  refOffer?: number;
  code?: string;
  customerName?:string;
  quantity?: number;
  quantityType?: QuantityType;
  commodity?: string;
  currentCommodity?: string;
  stowage?: string;
  loadingPorts?: string[];
  loadingPortConditions?: string;
  dischargingPorts?: string[];
  dischargingPortConditions?: string;
  releaseDate?:Date;
  cpForm?: string;
  restrictionsRegarding?: string;
  aditionalRequirements?: string;
  comments?: string;
  layCanFrom?: Date | null;
  currentLayCanFrom?: Date | null;
  layCanTo?: Date | null;
  currentLayCanTo?: Date | null;
  chargeringTerm?: string;
  freightTargetCurrency?: FreightTargetCurrency;
  freightTargetType?: FreightTargetType;
  freightTargetQuantity?: number | null;
  layTimeTerm?: string;
  chargerId?: string | null;
  charger?: Charger;
  offerShipOwners?: OfferShipOwner[];
  state?:OfferState;
  updatedDate?:Date;
  addressComm?:number; //campo comision de 
  vesselName?:string;
  remarks?:string;
  demurrageCurrency?: FreightTargetCurrency;
  demurrageType?: FreightTargetType;
  demurrageQuantity?: number | null;
  demurrageConditions?:string;
  comparativeNotes?:string;
  expirationDate?: Date;
  chargerName?:string; //para identificar el charger name en html table component
  nameShipOwner?:string;//para identificar el shipowner name en html response-shipowner
  offerShipownerIndication?:Offer;

  constructor() {
    this.offerShipOwners = this.offerShipOwners || [];
    this.charger = this.charger || new Charger();
    this.freightTargetCurrency = this.freightTargetCurrency || FreightTargetCurrency.EUR;
    this.freightTargetType = this.freightTargetType || FreightTargetType.LPSM;
    this.quantityType = this.quantityType || QuantityType.MT;
    this.refOffer = this.refOffer || undefined;
    this.code = this.code || '';
    this.quantity = this.quantity || undefined;
    this.commodity = this.commodity || '';
    this.stowage = this.stowage || '';
    this.loadingPorts = this.loadingPorts || [];
    this.dischargingPorts = this.dischargingPorts || [];
    this.cpForm = this.cpForm || '';
    this.restrictionsRegarding = this.restrictionsRegarding || '';
    this.aditionalRequirements = this.aditionalRequirements || '';
    this.comments = this.comments || '';
    this.layCanFrom = this.layCanFrom || new Date();
    this.layCanTo = this.layCanTo || new Date();
    this.chargeringTerm = this.chargeringTerm || '';
    this.freightTargetQuantity = this.freightTargetQuantity || undefined;
    this.layTimeTerm = this.layTimeTerm || '';
    this.chargerId = this.chargerId || '';
    this.state = this.state || OfferState.OFFER;
    this.updatedDate = this.updatedDate || new Date();
    this.addressComm=this.addressComm || 0;
    this.vesselName=this.vesselName || '';
    this.remarks=this.remarks || '';
    this.demurrageCurrency = this.demurrageCurrency || undefined;
    this.demurrageType = this.demurrageType || undefined;
    this.demurrageQuantity = this.demurrageQuantity || undefined;
    this.releaseDate = this.releaseDate || new Date();

   
   
    
  }


}
