<div class="map-container">
    <div id="overamap" class="overamap" style="height:500px;width:100%"></div>
    <div class="overlay-map" *ngIf="data">
        <h6>{{data?.vessel?.ais?.name}}</h6>
        <!--<div><span>IMO:</span> {{data?.vessel?.ais?.imo}}</div>
        <div><span>MMSI:</span> {{data?.vessel?.ais?.mmsi}}</div>
        <div><span>Destination:</span> {{data?.vessel?.ais?.destination}}</div>
        <div><span>Speed:</span> {{data?.vessel?.ais?.speed}}</div>
        <div><span>Latitude:</span> {{data?.vessel?.ais?.latitude}}</div>
        <div><span>Longitude:</span> {{data?.vessel?.ais?.longitude}}</div>
        <div><span>Course:</span> {{data?.vessel?.ais?.course}}</div>
        <div><span>Heading:</span> {{data?.vessel?.ais?.heading}}</div>
        <div><span>Timestamp:</span> {{data?.vessel?.ais?.timestamp}}</div>
        <div><span>Departure:</span> {{data?.vessel?.voyage?.departure}}</div>
        <div><span>Last Port:</span> {{data?.vessel?.voyage?.lastPort}}</div>
        <div><span>Last Country:</span> {{data?.vessel?.voyage?.lastCountry}}</div>-->
        <div class="route-header">
            <p-timeline [value]="portsList" align="left">
                <ng-template pTemplate="content" let-event>
                    <div class="portcall-item-timeline" overaellipsis>{{ event.port }}</div>
                    <div class="portcall-item-date" overaellipsis *ngIf="event.ata && event.type=='a'">{{ 'ATA: ' + (event.ata | date:'d MMM, yy, hh:mm:ss')}}</div>
                    <div class="portcall-item-date" overaellipsis *ngIf="event.eta && !event.ata && event.type=='a'">{{ 'ETA: ' +(event.eta | date:'d MMM, yy, hh:mm:ss') }}</div>
                    <div class="portcall-item-date" overaellipsis *ngIf="event.atd  && event.type=='d'">{{ 'ATD: ' + (event.atd | date:'d MMM, yy, hh:mm:ss')}}</div>
                    <div class="portcall-item-date" overaellipsis *ngIf="event.etd && !event.atd  && event.type=='d'">{{ 'ETD: ' + (event.etd | date:'d MMM, yy, hh:mm:ss')}}</div>

                </ng-template>
            </p-timeline>
        </div>
        <div class="accordion-vessel-info">
            <div class="table-speed">
                <div class="table-speed-row d-flex justify-content-between p-1">
                    <div class="table-speed-container  d-flex flex-column">
                        <div class="speed-label">
                            Velocidad:
                        </div>
                        <div class="speed-value">
                            {{data?.vessel?.ais?.speed}}
                        </div>
                    </div>
                    <div class="table-speed-container  d-flex flex-column">
                        <div class="speed-label">
                            Rumbo:
                        </div>
                        <div class="speed-value">
                            {{data?.vessel?.ais?.course + ' &deg;'}}
                        </div>
                    </div>
                    <div class="table-speed-container  d-flex flex-column">
                        <div class="speed-label">
                            Calado:
                        </div>
                        <div class="speed-value">
                            {{data?.vessel?.ais?.draught}}
                        </div>
                    </div>

                </div>
                <div class="table-speed-row d-flex justify-content-between p-1">
                    <div class="table-speed-container  d-flex flex-column">
                        <div class="speed-label">
                            Estatus:
                        </div>
                        <div class="speed-value">
                            {{data?.vessel?.ais?.navstatValue}}
                        </div>
                    </div>
                    <div class="table-speed-container  d-flex flex-column">
                        <div class="speed-label">
                            {{'LAST_REPORT' | translate}}
                        </div>
                        <div class="speed-value">
                            {{data?.vessel?.ais?.timestamp}}
                        </div>
                    </div>


                </div>
            </div>
            <p-accordion styleClass="accordionResponse">

                <p-accordionTab>
                    <ng-template pTemplate="header">
                        <p [escape]="false"
                           [pTooltip]="'PORTCALLS.HEADER'| translate"
                           tooltipPosition="bottom"
                           tooltipStyleClass="tooltipCLH"
                           [showDelay]="1000"
                           [hideDelay]="1000">
                            {{'PORTCALLS.HEADER' | translate}}
                        </p>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="portcalls-table">
                            <table>
                                <thead>
                                <th>{{'LAST_PORTCALL.HEADER' | translate}}</th>
                                <th>ATA</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let port of portCalls">
                                        <td>{{port.port + ' (' + port.locode + ')'}}</td>
                                        <td>{{port.timestamp}}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </ng-template>
                </p-accordionTab>
                <p-accordionTab>
                    <ng-template pTemplate="header">
                        <p [escape]="false"
                           [pTooltip]="'WEATHER.HEADER'| translate"
                           tooltipPosition="bottom"
                           tooltipStyleClass="tooltipCLH"
                           [showDelay]="1000"
                           [hideDelay]="1000">
                            {{'WEATHER_HEADER' | translate}}
                        </p>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="weather-table   d-flex justify-content-between p-1" *ngIf="data?.weather?.current_Weather">
                            <div class="weather-item d-flex align-items-center">
                                <div class="icons">
                                    <svg width="20" height="30" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><g stroke="#333" stroke-width="2"><path d="M4 17.803V4a3 3 0 116 0v13.803a6 6 0 11-6 0z"></path><path stroke-linecap="round" d="M14 6h5M14 10h5M14 14h5"></path></g><g transform="translate(4 11)" fill="red" fill-rule="nonzero"><circle cx="3" cy="12" r="3"></circle><rect x="2" width="2" height="11" rx="1"></rect></g></g></svg>
                                </div>
                                <div class="d-flex flex-column">
                                    <div class="weather-item-value item-1">{{data?.weather?.current_Weather.temperature + ' &deg;C'}}</div>
                                    <div class="weather-item-value ">{{data?.weather?.current_Weather.temperature*9/5 + 32 + ' &deg;F'}}</div>
                                </div>


                            </div>
                            <div class="weather-item  d-flex align-items-center">
                                <div class="icons">
                                    <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg"><g fill="#333" fill-rule="nonzero"><path d="M14 1.502a12.504 12.504 0 014.867 24.016c-6.362 2.688-13.698-.29-16.385-6.651-2.688-6.362.29-13.698 6.651-16.385A12.42 12.42 0 0114 1.502M14 0C6.267 0 0 6.267 0 14s6.267 14 14 14 14-6.267 14-14S21.733 0 14 0z"></path><path d="M13.159 24.215h1.803v2.524h-1.803zM13.039 1.082h1.803v2.644h-1.803zM1.142 13.099h2.644v1.803H1.142zM24.155 13.039h2.644v1.803h-2.644zM4.167 22.555l1.971-1.971 1.275 1.275-1.971 1.97zM20.527 6.084l1.789-1.789L23.59 5.57l-1.788 1.788zM4.168 5.444l1.275-1.275 1.97 1.97L6.14 7.414zM20.525 21.799l1.275-1.275 1.85 1.85-1.274 1.275z"></path></g></svg>
                                    <svg class="wtw-wda" [style]="'transform: rotateZ(' + (data.weather.current_Weather.windDirection + 180) % 360 + 'deg)'" width="10" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M.06 11.934c-.055-.045-.074-.111-.05-.17L4.82.103C4.848.043 4.922 0 5.005 0s.157.042.186.105l4.79 11.658c.03.075.03.16-.05.201-.08.04-.222.055-.29 0L5 7.984l-4.643 3.98c-.068.055-.173.035-.26-.005l-.037-.025z" fill="#065785" fill-rule="nonzero"></path></svg>
                                </div>
                                <div class="d-flex flex-column">
                                    <div class="weather-item-value ">{{(data?.weather?.current_Weather.windspeed * 0.5399568).toFixed(1) + ' kn'}}</div>
                                    <div class="weather-item-value ">{{(data?.weather?.current_Weather.windspeed * 5/18).toFixed(1) + ' m/s'}}</div>
                                </div>


                            </div>
                            <div class="weather-item  d-flex align-items-center">
                                <div class="icons">
                                    <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" fill-rule="evenodd" clip-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                        <g>
                                            <path d="M26.985 25.294c-.985 0-1.923-.431-2.566-1.18a.912.912 0 00-1.38 0 3.385 3.385 0 01-5.133 0 .912.912 0 00-1.38 0 3.385 3.385 0 01-5.133 0 .912.912 0 00-1.38 0 3.385 3.385 0 01-5.133 0 .912.912 0 00-1.38 0 3.385 3.385 0 01-2.587 1.18.914.914 0 000 1.83c1.05 0 2.076-.32 2.942-.915l.315-.234.314.234a5.194 5.194 0 005.894 0l.315-.234.314.234a5.194 5.194 0 005.894 0l.315-.234.314.234a5.194 5.194 0 005.895 0l.314-.234.315.234a5.196 5.196 0 002.942.915.914.914 0 000-1.83h-.01z" fill="#065785"></path>
                                            <path d="M27.031 19.3c-.986 0-1.923-.431-2.566-1.18a.912.912 0 00-1.38 0 3.385 3.385 0 01-5.134 0 .912.912 0 00-1.38 0 3.385 3.385 0 01-5.133 0 .912.912 0 00-1.38 0 3.385 3.385 0 01-5.133 0 .912.912 0 00-1.38 0A3.385 3.385 0 01.96 19.3a.914.914 0 000 1.83c1.05 0 2.076-.32 2.942-.915l.314-.234.315.234a5.194 5.194 0 005.894 0l.314-.234.315.234a5.194 5.194 0 005.894 0l.315-.234.314.234a5.194 5.194 0 005.894 0l.315-.234.314.234a5.196 5.196 0 002.942.915.917.917 0 000-1.83h-.01z" fill="#065785"></path>
                                            <path d="M.913 15.155c1.05 0 2.076-.32 2.942-.915l.315-.234.314.233a5.194 5.194 0 005.894 0l.315-.233.314.233a5.194 5.194 0 005.894 0l.315-.233.314.233a5.194 5.194 0 005.895 0l.314-.233.315.233a5.196 5.196 0 002.942.916.914.914 0 000-1.83c-.986 0-1.923-.432-2.567-1.18a.912.912 0 00-1.38 0 3.385 3.385 0 01-5.133 0 .912.912 0 00-1.38 0 3.385 3.385 0 01-5.133 0 .912.912 0 00-1.38 0 3.385 3.385 0 01-5.133 0 .912.912 0 00-1.38 0 3.385 3.385 0 01-2.597 1.18.914.914 0 000 1.83z" fill="#AAA"></path>
                                            <path d="M.913 9.137c1.05 0 2.076-.32 2.942-.915l.315-.234.314.234a5.194 5.194 0 005.894 0l.315-.234.314.234a5.194 5.194 0 005.894 0l.315-.234.314.234a5.194 5.194 0 005.895 0l.314-.234.315.234a5.196 5.196 0 002.942.915.914.914 0 000-1.83c-.986 0-1.923-.431-2.567-1.18a.912.912 0 00-1.38 0 3.385 3.385 0 01-5.133 0 .912.912 0 00-1.38 0 3.385 3.385 0 01-5.133 0 .912.912 0 00-1.38 0 3.385 3.385 0 01-5.133 0 .912.912 0 00-1.38 0 3.385 3.385 0 01-2.597 1.18.914.914 0 000 1.83z" fill="#AAA"></path>
                                            <path d="M.913 3.326c1.05 0 2.076-.32 2.942-.915l.315-.234.314.234a5.194 5.194 0 005.894 0l.315-.234.314.234a5.194 5.194 0 005.894 0l.315-.234.314.234a5.194 5.194 0 005.895 0l.314-.234.315.234a5.196 5.196 0 002.942.915.914.914 0 000-1.83c-.986 0-1.923-.431-2.567-1.18a.912.912 0 00-1.38 0 3.385 3.385 0 01-5.133 0 .912.912 0 00-1.38 0 3.385 3.385 0 01-5.133 0 .912.912 0 00-1.38 0 3.385 3.385 0 01-5.133 0 .912.912 0 00-1.38 0 3.385 3.385 0 01-2.597 1.18.914.914 0 000 1.83z" fill="#AAA"></path>
                                        </g>
                                    </svg>                           
                                </div>
                                <div class="d-flex flex-column">
                                    <div class="weather-item-value ">{{(data?.weather?.current_Weather.waveHeight)+ ' m'}}</div>
                                    <div class="weather-item-value ">{{(data?.weather?.current_Weather.waveHeight * 3.28084 ).toFixed(1) + ' ft'}}</div>
                                </div>


                            </div>

                        </div>
                    </ng-template>
                </p-accordionTab>
                <p-accordionTab>
                    <ng-template pTemplate="header">
                        <p [escape]="false"
                           [pTooltip]="'WEATHER.HEADER'| translate"
                           tooltipPosition="bottom"
                           tooltipStyleClass="tooltipCLH"
                           [showDelay]="1000"
                           [hideDelay]="1000">
                            {{'VESSEL_DATA_HEADER' | translate}}
                        </p>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="vessel-details-table">
                            <div class="table-speed-row d-flex justify-content-between p-1">
                                <div class="table-speed-container  d-flex flex-column">
                                    <div class="speed-label">
                                        IMO:
                                    </div>
                                    <div class="speed-value">
                                        {{data?.vessel?.ais?.imo}}
                                    </div>
                                </div>
                                <div class="table-speed-container  d-flex flex-column">
                                    <div class="speed-label">
                                        MMSI:
                                    </div>
                                    <div class="speed-value">
                                        {{data?.vessel?.ais?.mmsi}}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>

        </div>
    </div>
</div>