import {
  AfterContentChecked,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmationService } from "primeng/api";
import { Charger } from "src/app/models/charger/charger.model";
import { Clause } from "src/app/models/clause/clause.model";
import { ClauseService } from "src/app/services/clause.service";
import { OfferService } from "src/app/services/offer.service";
import { PermissionsService } from "../../../services/permissions.service";
import { CLHGlobals } from "src/app/utils/globals";
import { NgxTranslateService } from "@overa/shared";

@Component({
  selector: "app-clauses",
  templateUrl: "./clauses.component.html",
  styleUrls: ["./clauses.component.scss"],
  providers: [ConfirmationService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClausesComponent implements OnInit {
  autoResize: boolean = true;
  clauseTextArea!: string;
  // selectClause: Clause;
  // selectedClauses: Clause[] = [];
  columns: any[] = [];
  isNewClause: boolean = false;
  isEdittingClause: boolean = false;
  newClause?: Clause;
  listClauses: Clause[] = [];
  isEmptyNewClause: boolean = false;
  formClauses!: FormGroup;
  isLoaded: boolean = false;
  @Input()
  charger!: Charger;
  @Output()
  sendListClauses = new EventEmitter<Clause[]>();
  readonly = false;
  constructor(
    private translate: NgxTranslateService,
    private confirmationService: ConfirmationService,
    private elementRef: ElementRef,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private offerService: OfferService,
    private spinner: NgxSpinnerService,
    private globals: CLHGlobals,
    private route: ActivatedRoute,
    private clauseService: ClauseService,
    private permissionsService: PermissionsService
  ) {}

  ngOnInit() {
    console.log(this.charger);
    if (!this.charger) {
      this.charger = this.globals.currentCharger || new Charger();
    }
    this.initialForm();
    this.readonly = !this.permissionsService.hasPermissionToEdit(this.route);
    this.columns = [
      { id: "CLAUSES_MAINTENANCE.TABLE.HEADER_2", sort: "description" },
    ];
    if (!this.readonly) {
      this.columns.push({
        id: "CLAUSES_MAINTENANCE.TABLE.HEADER_3",
        sort: "modify",
      });
    }

    //if (this.charger.clauses.length) {
    //  this.listClauses = this.charger.clauses;
    //  this.listClauses.forEach((clauseN) => {
    //    clauseN.nameControl = clauseN.id + 'Textarea';
    //    clauseN.isEdit = false;
    //    this.formClauses.addControl(clauseN.nameControl, new FormControl(clauseN.description, [Validators.required]));
    //  });
    //  this.isLoaded = true;
    //}
    this.reloadList();
  }
  initialForm() {
    this.formClauses = new FormGroup({
      newClauseTextarea: new FormControl("", [Validators.required]),
    });
  }
  //ngAfterViewInit() {
  //  this.cd.detectChanges();
  //}

  addRow() {
    this.formClauses.get("newClauseTextarea")?.setValue("");
    this.isNewClause = true;
    this.newClause = new Clause();
    this.newClause.isNew = true;
    this.newClause.isEmpty = true;
    this.isEmptyNewClause = false;
    this.newClause.description = "";
    this.newClause.height = 100;
    this.listClauses.unshift(this.newClause);
    this.listClauses = [...this.listClauses];
    // console.log(this.listClauses);
  }
  editClause(clause: Clause) {
    this.isEdittingClause = true;
    clause.isEdit = true;
    clause.valueInitial = clause.description;
    clause.isEmpty = false;
    this.listClauses = [...this.listClauses];
  }
  saveClause(clause?: Clause) {
    console.log(this.isNewClause, clause);
    this.spinner.show("clauseSpinner");
    var subscription;
    if (this.isNewClause && this.newClause) {
      this.isNewClause = false;
      let addClause = { ...this.newClause };
      addClause.isNew = false;
      addClause.isEmpty = false;
      addClause.chargerId = this.charger.id;
      addClause.description = this.formClauses.get("newClauseTextarea")?.value;
      let indice = this.listClauses.indexOf(this.newClause);
      this.listClauses.splice(indice, 1);
      this.newClause = undefined;
      subscription = this.clauseService.addClause(addClause);
    }
    if (this.isEdittingClause && clause) {
      this.isEdittingClause = false;
      clause.isEdit = false;
      clause.description = this.formClauses.get(
        clause.nameControl || ""
      )?.value;
      subscription = this.clauseService.editClause(clause);
    }
    if (subscription) {
      subscription.subscribe({
        next: (value) => {
          console.log(value);
          value.nameControl = value.id + "Textarea";
          value.isEdit = false;
          this.formClauses.addControl(
            value.nameControl,
            new FormControl(value.description, [Validators.required])
          );
          let index = this.listClauses.findIndex((x) => x.id === value.id);
          if (index >= 0) {
            this.listClauses[index] = value;
          } else {
            this.listClauses.push(value);
          }

          this.listClauses = [...this.listClauses];
          this.cd.detectChanges();
          this.spinner.hide("clauseSpinner");
        },
        error: (err) => {
          console.log("errorSaveClause", err);
          this.spinner.hide("clauseSpinner");
        },
      });
    }
    //TODO
    //IT IS PENDING TO INDIVIDUALIZE THE DELETION UNTIL IT IS READY BACK
    // this.sendListClauses.emit(this.listClauses);
  }
  cancelEditClause(clause?: Clause) {
    if (this.isNewClause && this.newClause) {
      this.isNewClause = false;
      let indice = this.listClauses.indexOf(this.newClause);
      this.listClauses.splice(indice, 1);
      this.newClause = undefined;
    }
    if (this.isEdittingClause && clause) {
      this.isEdittingClause = false;
      clause.isEdit = false;
      clause.description = clause.valueInitial;
      this.formClauses
        .get(clause.nameControl || "")
        ?.setValue(clause.description);
      clause.isEmpty = false;
    }
    this.listClauses = [...this.listClauses];
    // console.log(this.listClauses)
  }
  onValueChanged(value: any) {
    console.log(value);
    if (this.isNewClause && this.newClause) {
      this.newClause.description = value.description;
    }
    if (this.isEdittingClause) {
      console.log(value);
    }
  }
  deleteClause(clause: Clause) {
    let trmessage = this.translate.get("CLAUSES_MAINTENANCE.ASK_DELETE");
    let messageAccept = this.translate.get("ACTION.YES");

    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.spinner.show("clauseSpinner");
        this.clauseService.deleteClause(clause?.id || "").subscribe({
          next: () => {
            let indice = this.listClauses.indexOf(clause);
            this.listClauses.splice(indice, 1);
            this.listClauses = [...this.listClauses];
            this.cd.detectChanges();
            this.spinner.hide("clauseSpinner");
          },
          error: (err) => {
            console.log("errorDeleteClause", err);
            this.spinner.hide("clauseSpinner");
          },
        });

        //TODO
        //IT IS PENDING TO INDIVIDUALIZE THE DELETION UNTIL IT IS READY BACK
        // this.sendListClauses.emit(this.listClauses);
      },
      reject: () => {},
    });
  }
  reloadList() {
    this.spinner.show("clauseSpinner");
    this.offerService.getClausesByChargerId(this.charger.id || "").subscribe({
      next: (value) => {
        this.listClauses = value;
        this.listClauses.forEach((clauseN) => {
          clauseN.nameControl = clauseN.id + "Textarea";
          clauseN.isEdit = false;
          this.formClauses.addControl(
            clauseN.nameControl,
            new FormControl(clauseN.description, [Validators.required])
          );
        });
        this.listClauses = [...this.listClauses];
        this.cd.detectChanges();
        this.isLoaded = true;
        this.spinner.hide("clauseSpinner");
        console.log("clauses", this.listClauses);
      },
      error: (err) => {
        console.log("errorClause", err);
        this.spinner.hide("clauseSpinner");
      },
    });
  }
}
