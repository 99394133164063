import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigsService } from "@overa/shared";
import { Note } from "../models/note/note.model";
import { CLHBaseService } from "./clh-base.service";

@Injectable({
  providedIn: "root",
})
export class NotesService extends CLHBaseService {
  getNotesByEntityId(entityId: string) {
    return this.http.get<Note[]>(`${this.baseUrl}/note/getByOffer/${entityId}`);
  }
  getNotesByEntityIdAndGroupId(entityId: string, groupId: string) {
    return this.http.get<Note[]>(
      `${this.baseUrl}/note/getByShipOwner/${entityId}/${groupId}`
    );
  }
  save(note: Note) {
    const endpointUrl = `${this.baseUrl}/note`;
    return this.http.post<Note>(endpointUrl, note);
  }
}
