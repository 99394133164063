<div class="card">
  <div class="card-header">
    <div class="card-header tittleFeedback"><h4>{{ 'FEEDBACK.FEEDBACK' | translate}}</h4></div>
  </div>
  <div class="card-body"></div>
  <div class="containerButtonStep1">
    <div class="div-btn-cancel">
      <div>
        <button pButton type="button" icon="pi pi-home" iconPos="left" class="p-button home custom-button p-ripple" routerLink="/home"></button>
      </div>
    </div>
  </div>
</div>
