import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { OveraMessagesService } from "@overa/components/info-dialog";
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { ExpirationDateResponse } from "src/app/models/offers/expiration-date-response.model";
import { ClhValidators } from "src/app/utils/validators";

@Component({
  selector: "app-expiration-date-dialog",
  templateUrl: "./expiration-date-dialog.component.html",
  styleUrls: ["./expiration-date-dialog.component.scss"],
  providers: [DialogService],
})
export class ExpirationDateDialogComponent implements OnInit {
  expirationDate: Date = new Date();
  expirationDateResponse: ExpirationDateResponse;
  today: Date = new Date();
  checked: boolean = false;
  today6Hours: Date = new Date();
  isLoading: boolean = false;
  isValidDate: boolean = false;
  form: FormGroup;
  constructor(
    protected dialogServicePrime: DialogService,
    public messageService: OveraMessagesService,
    private cd: ChangeDetectorRef,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public validators: ClhValidators,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.form = this.fb.group(
      {
        expirationDate: new FormControl<Date>(this.today, []),
        checkedExpirationDate: new FormControl<boolean>(false, []),
      },
      {
        validators: [this.validators.expirationDateValidator()],
      }
    );
    // this.form= new FormGroup(
    //   {
    //     expirationDate: new FormControl<Date>(this.today,[]),
    //     checked: new FormControl<boolean>(false, []),
    //   },
    //   validators: [this.validators.expirationDateValidator()]
    // );
    const hours = this.today6Hours.getHours();
    this.today6Hours.setHours(hours + 6);
  }

  back() {
    this.ref.close();
  }
  continue() {
    if (!this.form.valid) {
      this.messageService.showToast(
        "INFO",
        "MESSAGES.INFO.EXPIRATION_DATE_MINOR",
        "info",
        ""
      );
    } else {
      //this.selectDate();
      const expirationDateResponse = new ExpirationDateResponse();
      const expirationDate = this.form.get("expirationDate").value;
      const checkedExpirationDate = this.form.get(
        "checkedExpirationDate"
      ).value;

      expirationDateResponse.date = checkedExpirationDate
        ? expirationDate
        : null;
      //TODO review
      this.ref.close(expirationDateResponse);
    }
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }
}
