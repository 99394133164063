import { NegotiationFieldState } from "../offers/negotiation-field-state.model";
import { NegotiationOfferShipOwnerHistory } from "../offers/negotiation-offer-shipowner-history.model";
import { UserType } from "../enum/user-type.model";

export class ClauseNegotiation {
  id?: string;
  value?: string;
  state?: NegotiationFieldState;
  isRefused: boolean = false;
  clauseHistories: NegotiationOfferShipOwnerHistory[] = [];
  userType?: UserType; //añadido para model typescript
  isReadOnly?: boolean; //añadido para model typescript
  isEditClause?: boolean; //añadido para model typescript
  nameControl?: string; //añadido para model typescript identificar el nombre del input
  valueInitial?: string; //añadido para guardar el valor inicial
  refusedInitial: boolean = false; //añadido para guardar el valor inicial
  stateInitial?: NegotiationFieldState; //añadido para guardar el valor inicial
  dataTarget?: string; //añadido para poner el atributo en el componente historico para el collapse
  ariaControls?: string; //añadido para poner el atributo en el componente historico para el collapse
  approvedRefused?: boolean; //añadido para el aprobado de rechazado
  valid?: boolean;
  isDefault?: boolean; //añadido para saber si es preestablecida
  isDuplicate?: boolean; //añadido para saber si es duplicada de otra orden
  indexList?: number; // añadido para identificar el form porque no hay id en la clausula
  constructor() {}
}
