<ngx-spinner></ngx-spinner>
<div class="container-fluid containerShipownerList">
  <div class="card">
    <div class="card-header">
      <div class="card-text">
        <div class="row">
          <div class="col align-self-start">
            <h4 class="card-tittle tittleListShipowner">{{'SHIPOWNER_LIST.TITLE' | translate}}</h4>
          </div>
          <div class="col">
            <div class="d-flex justify-content-end">
              <button *ngIf="showButton" pButton pRipple type="button" icon="pi pi-plus"
                class="p-button-rounded addButtonRounded" (click)="isNew('new')" [pTooltip]="'ACTION.ADD'| translate"
                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH"></button>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
    <div class="card-body cardBodyShipownerList">
      <p-table *ngIf="showTable" [columns]="columns" [value]="shipowners"
        styleClass="p-datatable-striped listTableShipowner"
        [globalFilterFields]="['refShipOwner','charteringCompany','dischargeDate','companyImo','web','companyAddress','isBanned']"
        [responsive]="true" responsiveLayout="stack" breakpoint="960px" [autoLayout]="true" [paginator]="true"
        [rows]="10" currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
        [rowsPerPageOptions]="[10,30,50]" [showCurrentPageReport]="true" paginatorDropdownAppendTo="body" sortField="Id"
        [sortOrder]="-1">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th overaellipsis *ngFor="let col of columns" [ngClass]="'th-' +col.sort" class="line-border-right">{{col.id
              | translate}}</th>
          </tr>
          <tr>
            <th *ngFor="let col of columns" class="table-filters">
              <p-columnFilter *ngIf="col.sort === 'dischargeDate' " type="date" field="{{col.sort}}" matchMode="is">
                <ng-template pTemplate="filter" let-filter="filterCallback">
                  <p-calendar (onSelect)="filter($event)" dateFormat="dd/mm/yy" appendTo="body"></p-calendar>
                </ng-template>
              </p-columnFilter>
              <p-columnFilter *ngIf="col.sort === 'isBanned' " type="boolean" field="{{col.sort}}"
                class="checkShipownerCharger"></p-columnFilter>
              <p-columnFilter *ngIf="col.sort != 'dischargeDate' && col.sort != 'isBanned'" type="text"
                field="{{col.sort}}"></p-columnFilter>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-shipowner>
          <tr class="campo" (dblclick)="selectShipowner(shipowner)">
            <ng-container *ngFor="let col of columns">
              <td overaellipsis class="{{col.sort}}" *ngIf="col.sort != 'dischargeDate' && col.sort != 'isBanned'">
                <span class="p-column-title">{{col.id | translate}}</span> {{shipowner[col.sort]}}
              </td>
              <td class="{{col.sort}}" *ngIf="col.sort === 'dischargeDate' ">
                <span class="p-column-title">{{col.id | translate}}</span> {{shipowner[col.sort] | date: 'dd/MM/yy'}}
              </td>
              <td class="{{col.sort}}" *ngIf="col.sort === 'isBanned' ">
                <p-checkbox [(ngModel)]="shipowner.isBanned" [binary]="true" [disabled]="true"
                  class="checkShipownerCharger"> </p-checkbox>
              </td>
            </ng-container>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>