import {
  Observable,
  catchError,
  forkJoin,
  map,
  of,
  switchMap,
  tap,
} from "rxjs";
import { ChargerContact } from "../models/charger/charger-contact.model";
import { Charger } from "../models/charger/charger.model";
import { ChatGroup } from "../models/chat/chatGroup.model";
import { ChatGroupParticipant } from "../models/chat/chatGroupParticipant.model";
import { ChatMessage } from "../models/chat/chatMessage.model";
import { ChatParticipant } from "../models/chat/chatParticipant.model";
import { OfferShipOwner } from "../models/offers/offer-shipowner.model";
import { Offer } from "../models/offers/offer.model";
import { Shipowner } from "../models/shipowner/shipowner.model";
import { ChargerService } from "../services/charger.service";
import { ChatGroupService } from "../services/chatGroup.service";
import { ChatMessageService } from "../services/chatMessage.service";
import { ChatParticipantService } from "../services/chatParticipant.service";
import { OfferService } from "../services/offer.service";
import { ShipownerService } from "../services/shipowner.service";
import { Injectable } from "@angular/core";
import { AccountService } from "@overa/security";
import { Utils } from "./utils";
import { CLHIdentityService } from "../services/identity.service";
import { CLHGlobals } from "./globals";

@Injectable({
  providedIn: "root",
})
export class ChatHelper {
  isCLH: boolean = false;
  constructor(
    private chatParticipantService: ChatParticipantService,
    private chatGroupService: ChatGroupService,
    private chatMessageService: ChatMessageService,
    private offerService: OfferService,
    private shipOwnerService: ShipownerService,
    private chargerService: ChargerService,
    private globals: CLHGlobals,
    private utils: Utils,
    private identityService: CLHIdentityService,
    private accountService: AccountService
  ) {
    if (
      this.accountService.currentUser.roles
        .map((x) => x.name)
        .includes("CLH Admin")
    ) {
      this.isCLH = true;
    }
    console.log(this.isCLH);
  }

  //public getChatWindowName(offerShipOwnerId: string, isClh: boolean = false,clhWithShipOwner:boolean=false): Observable<string> {
  //  if (!isClh) {
  //    if (this.globals.currentCharger) {
  //      //WHEN IS CHARGER, SET SHIPOWNER NAME // CHECK HERE IF ITS GOING TO SET CHAT CLH WINDOW NAME
  //      return this.getShipOwnerName(offerShipOwnerId);
  //    } else if (this.globals.currentShipOwner) {
  //      return this.getChargerName(offerShipOwnerId);
  //    }
  //  } else {
  //    if (this.globals.currentCharger || this.globals.currentShipOwner) {
  //      return of('CLH');
  //    } else {
  //      if(!clhWithShipOwner){
  //        return this.getChargerName(offerShipOwnerId);
  //      }else{
  //        return this.getShipOwnerName(offerShipOwnerId);
  //      }
  //    }
  //  }
  //}

  //getChargerName(offerShipOwnerId: string): Observable<string> {
  //  return this.offerService.getOfferShipOwnerById(offerShipOwnerId).pipe(
  //    switchMap((offerShipOwnerResponse: OfferShipOwner) => {
  //      return this.offerService.getById(offerShipOwnerResponse.offerId);
  //    }),
  //    map((offer) => offer.charger.name)
  //  );
  //}

  //getShipOwnerName(offerShipOwnerId: string): Observable<string> {
  //    //    console.log('shipownername');
  //    return this.offerService.getOfferShipOwnerById(offerShipOwnerId).pipe(
  //        map((offerShipOwnerResponse: OfferShipOwner) => {
  //            return offerShipOwnerResponse.shipOwner.charteringCompany;
  //        }));
  //    //  map((offer) => {
  //    //    return offer.nameShipOwner || offer.customerName || "No Name";
  //    //  }) // Transformar el valor emitido por el observable
  //    //);
  //}

  public getChatGroup(offerShipOwnerId: string): Observable<ChatGroup> {
    return this.getGroupChargerShipOwner(offerShipOwnerId);
  }

  public getCurrentParticipant(user: any): Observable<ChatParticipant> {
    return this.chatParticipantService.getChatParticipantByUserId(user.id).pipe(
      catchError((err) => {
        const chatParticipant = this.setNewChatParticipant(user);
        return this.chatParticipantService.add(chatParticipant);
      })
    );
  }

  public getMessageHistory(groupId: string): Observable<ChatMessage[]> {
    return this.chatMessageService.getHistoricMessagesByGroupId(groupId);
  }

  public getChatGroupChargerCLHNew(offerId: string, charger: Charger) {
    return this.getGroupChargerCLH(offerId, charger);
  }
  public getChatGroupChargerCLH(offerId: string) {
    // return this.getGroupChargerClhOld(offerId);
    return this.getGroupChargerClhOldWithOfferId(offerId);
  }
  public getChatGroupCLHOfferShipOwner(offerId: string) {
    return this.getCLHGroupShipOwner(offerId);
  }

  //**********CODE LOGIC***************//

  //TODO: comentarios offerShipOwner
  private getGroupChargerShipOwner(
    offerShipOwnerId: string
  ): Observable<ChatGroup> {
    return this.offerService.getOfferShipOwnerById(offerShipOwnerId).pipe(
      switchMap((offerShipOwnerResponse: OfferShipOwner) => {
        const offerSubscription = this.offerService.getById(
          offerShipOwnerResponse.offerId || ""
        );
        const shipOwnerContactsSubscription = this.shipOwnerService.getById(
          offerShipOwnerResponse.shipOwnerId || ""
        );
        return offerSubscription.pipe(
          switchMap((offer: Offer) => {
            const chargerContactsSubscription = this.chargerService.getById(
              offer.chargerId || ""
            );
            return forkJoin({
              shipOwner: shipOwnerContactsSubscription,
              charger: chargerContactsSubscription,
            }).pipe(
              switchMap(({ shipOwner, charger }) => {
                const newGroupName = this.setGroupName(
                  offerShipOwnerId,
                  charger,
                  shipOwner
                );
                return this.chatGroupService.getGroupByName(newGroupName).pipe(
                  catchError((err) => {
                    const group = this.setGroupInfo(charger, offerShipOwnerId, {
                      shipOwnerContacts: shipOwner,
                    });
                    return this.chatGroupService.add(group).pipe(
                      switchMap((createdGroup: ChatGroup) => {
                        const messageCommentOnOffer =
                          this.getFirstMessagesByComments(
                            createdGroup,
                            charger,
                            offer
                          );
                        const messageCommentOnShipOwner =
                          this.getFirstMessageByShipOwnerComments(
                            createdGroup,
                            charger,
                            offerShipOwnerResponse
                          );
                        return forkJoin({
                          msgCharger:
                            messageCommentOnOffer.message &&
                            messageCommentOnOffer.message.trim() !== ""
                              ? this.chatMessageService.add(
                                  messageCommentOnOffer
                                )
                              : of(null),
                          msgShipOwner:
                            messageCommentOnShipOwner.message &&
                            messageCommentOnShipOwner.message.trim() !== ""
                              ? this.chatMessageService.add(
                                  messageCommentOnShipOwner
                                )
                              : of(null),
                        }).pipe(map(() => createdGroup));
                      })
                    );
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  private getGroupChargerClhOld(
    offerShipOwnerId: string
  ): Observable<ChatGroup> {
    return this.offerService.getOfferShipOwnerById(offerShipOwnerId).pipe(
      switchMap((offerShipOwnerResponse: OfferShipOwner) => {
        const offerSubscription = this.offerService.getById(
          offerShipOwnerResponse.offerId || ""
        );
        return offerSubscription.pipe(
          switchMap((offer: Offer) => {
            const chargerContactsSubscription = this.chargerService.getById(
              offer.chargerId || ""
            );
            const clhContacts = this.globals.clhUsers;

            return forkJoin({
              charger: chargerContactsSubscription,
            }).pipe(
              switchMap(({ charger }) => {
                const newGroupName = this.setGroupName(
                  offerShipOwnerId,
                  charger
                );
                return this.chatGroupService.getGroupByName(newGroupName).pipe(
                  catchError((err) => {
                    const group = this.setGroupInfo(charger, offerShipOwnerId, {
                      clhContacts: clhContacts,
                    });
                    return this.chatGroupService.add(group).pipe(
                      switchMap((createdGroup: ChatGroup) => {
                        const chatMessage = this.getFirstMessagesByComments(
                          createdGroup,
                          charger,
                          offer
                        );
                        if (
                          chatMessage.message != null &&
                          chatMessage.message.trim() !== ""
                        ) {
                          return this.chatMessageService
                            .add(chatMessage)
                            .pipe(map(() => createdGroup));
                        } else {
                          return of(createdGroup);
                        }
                      })
                    );
                  })
                );
              })
            );
          })
        );
      })
    );
  }
  private getGroupChargerClhOldWithOfferId(
    offerId: string
  ): Observable<ChatGroup> {
    // return this.offerService.getOfferShipOwnerById(offerShipOwnerId).pipe(
    //     switchMap((offerShipOwnerResponse: OfferShipOwner) => {
    return this.offerService.getById(offerId).pipe(
      switchMap((offer: Offer) => {
        const chargerContactsSubscription = this.chargerService.getById(
          offer.chargerId || ""
        );
        const clhContacts = this.globals.clhUsers;

        return forkJoin({
          charger: chargerContactsSubscription,
        }).pipe(
          switchMap(({ charger }) => {
            const newGroupName = this.setGroupName(offerId, charger);
            return this.chatGroupService
              .getByNameToCharterer(newGroupName)
              .pipe(
                catchError((err) => {
                  const group = this.setGroupInfo(charger, offerId, {
                    clhContacts: clhContacts,
                  });
                  return this.chatGroupService.add(group).pipe(
                    switchMap((createdGroup: ChatGroup) => {
                      const chatMessage = this.getFirstMessagesByComments(
                        createdGroup,
                        charger,
                        offer
                      );
                      if (
                        chatMessage.message != null &&
                        chatMessage.message.trim() !== ""
                      ) {
                        return this.chatMessageService
                          .add(chatMessage)
                          .pipe(map(() => createdGroup));
                      } else {
                        return of(createdGroup);
                      }
                    })
                  );
                })
              );
          })
        );
      })
    );
    //     })
    // );
  }
  //TODO end with clhContacts
  private getGroupChargerCLH(
    offerShipOwnerId: string,
    charger: Charger
  ): Observable<ChatGroup> {
    console.log(offerShipOwnerId);
    const clhContacts = this.globals.clhUsers;
    return this.offerService.getOfferShipOwnerById(offerShipOwnerId).pipe(
      switchMap((offerShipOwnerResponse: OfferShipOwner) => {
        const offerSubscription = this.offerService.getById(
          offerShipOwnerResponse.offerId || ""
        );
        return offerSubscription.pipe(
          switchMap((offer: Offer) => {
            const chargerContactsSubscription = this.chargerService.getById(
              offer.chargerId || ""
            );
            return chargerContactsSubscription.pipe(
              switchMap((chargetContacts) => {
                const newGroupName = this.setGroupName(
                  offerShipOwnerId,
                  chargetContacts
                );
                return this.chatGroupService.getGroupByName(newGroupName).pipe(
                  catchError((err) => {
                    const group = this.setGroupInfo(
                      chargetContacts,
                      offerShipOwnerId,
                      { clhContacts: clhContacts }
                    );
                    return this.chatGroupService.add(group).pipe(
                      switchMap((createdGroup: ChatGroup) => {
                        const chatMessage = this.getFirstMessagesByComments(
                          createdGroup,
                          charger,
                          offer
                        );
                        if (
                          chatMessage.message != null &&
                          chatMessage.message.trim() !== ""
                        ) {
                          return this.chatMessageService
                            .add(chatMessage)
                            .pipe(map(() => createdGroup));
                        } else {
                          return of(createdGroup);
                        }
                      })
                    );
                  })
                );
              })
            );
          })
        );
      })
    );

    //const clhUsersSubscription = this.identityService.getUsersByRole(this.globals.clhRoleName);
    //return forkJoin({
    //  clhContacts: clhUsersSubscription,
    //  charger: chargerContactsSubscription,
    //})
    //  .pipe(
    //switchMap(({ clhContacts, charger }) => {
    //  let newGroupName = this.setGroupName(offerShipOwnerId,charger);
    //  return this.chatGroupService.getGroupByName(newGroupName).pipe(
    //    catchError((err) => {
    //      let group = this.setGroupInfo(charger, offerShipOwnerId,{clhContacts:clhContacts});
    //      return this.chatGroupService.add(group).pipe(
    //        switchMap((createdGroup:ChatGroup)=>{
    //          let chatMessage = this.getFirstMessagesByComments(createdGroup, charger, offer);
    //          if(chatMessage.message!=null && chatMessage.message.trim() !== ''){
    //            return this.chatMessageService.add(chatMessage).pipe(
    //              map(()=>createdGroup)
    //            );
    //          }else{
    //            return of(createdGroup);
    //          }
    //        })
    //      );
    //    })
    //  );
    // })
    // );
    //})
    //    );
    //  })
    //);
  }

  //TODO comentarios offerShipOwner
  private getCLHGroupShipOwner(
    offerShipOwnerId: string
  ): Observable<ChatGroup> {
    return this.offerService.getOfferShipOwnerById(offerShipOwnerId).pipe(
      switchMap((offerShipOwnerResponse: OfferShipOwner) => {
        const shipOwnerContactsSubscription = this.shipOwnerService.getById(
          offerShipOwnerResponse.shipOwnerId || ""
        );
        const clhUsersSubscription = this.identityService.getUsersByRole(
          this.globals.clhRoleName
        );
        const offerSubscription = this.offerService.getById(
          offerShipOwnerResponse.offerId || ""
        );
        const clhContacts = this.globals.clhUsers;
        return offerSubscription.pipe(
          switchMap((offer: Offer) => {
            return forkJoin({
              //clhContacts: clhUsersSubscription,
              shipOwner: shipOwnerContactsSubscription,
              offer: of(offer),
            }).pipe(
              switchMap(({ shipOwner, offer }) => {
                const newGroupName = this.setGroupName(
                  offerShipOwnerId,
                  undefined,
                  shipOwner
                );
                return this.chatGroupService.getGroupByName(newGroupName).pipe(
                  catchError((err) => {
                    const group = this.setGroupInfo(
                      undefined,
                      offerShipOwnerId,
                      {
                        shipOwnerContacts: shipOwner,
                        clhContacts: clhContacts,
                      }
                    );
                    return this.chatGroupService.add(group).pipe(
                      switchMap((createdGroup: ChatGroup) => {
                        const messageCommentOnOffer =
                          this.getFirstMessagesByComments(
                            createdGroup,
                            undefined,
                            offer,
                            clhContacts
                          );
                        const messageCommentOnShipOwner =
                          this.getFirstMessageByShipOwnerComments(
                            createdGroup,
                            undefined,
                            offerShipOwnerResponse,
                            clhContacts
                          );
                        return forkJoin({
                          msgCharger:
                            messageCommentOnOffer.message &&
                            messageCommentOnOffer.message.trim() !== ""
                              ? this.chatMessageService.add(
                                  messageCommentOnOffer
                                )
                              : of(null),
                          msgShipOwner:
                            messageCommentOnShipOwner.message &&
                            messageCommentOnShipOwner.message.trim() !== ""
                              ? this.chatMessageService.add(
                                  messageCommentOnShipOwner
                                )
                              : of(null),
                        }).pipe(map(() => createdGroup));
                      })
                    );
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  private getFirstMessagesByComments(
    createdGroup: ChatGroup,
    charger: Charger | undefined,
    offer: Offer,
    clhContacts?: any[]
  ) {
    const chatMessage = new ChatMessage();
    chatMessage.date = new Date();
    chatMessage.groupId = createdGroup.id;
    if (charger != null) {
      chatMessage.isCharger = true;
    } else {
      chatMessage.isCLH = true;
    }
    chatMessage.seen = true;
    if (charger != null) {
      chatMessage.participantId = createdGroup.groupParticipants.filter(
        (f) => f.participant.userId == charger.contacts[0].userId
      )[0].participant.id;
    } else {
      chatMessage.participantId =
        createdGroup.groupParticipants[
          createdGroup.groupParticipants.length - 1
        ].participant.id;
    }
    chatMessage.message = offer.comments;
    return chatMessage;
  }

  private getFirstMessageByShipOwnerComments(
    createdGroup: ChatGroup,
    charger?: Charger,
    offer?: OfferShipOwner,
    clhContacts?: any[]
  ) {
    const chatMessage = new ChatMessage();
    chatMessage.date = new Date();
    chatMessage.groupId = createdGroup.id;
    chatMessage.isCharger = true;
    chatMessage.seen = true;
    if (charger != null) {
      chatMessage.participantId = createdGroup.groupParticipants.filter(
        (f) => f.participant.userId == charger.contacts[0].userId
      )[0].participant.id;
    } else {
      chatMessage.participantId =
        createdGroup.groupParticipants[
          createdGroup.groupParticipants.length - 1
        ].participant.id;
    }
    chatMessage.message = offer?.comment;
    return chatMessage;
  }

  private setGroupInfo(
    chargerContacts: Charger | undefined,
    offerShipOwnerId: string,
    options: { shipOwnerContacts?: Shipowner; clhContacts?: any[] }
  ) {
    console.log("setGroupInfo");
    const newGroup: ChatGroup = new ChatGroup();
    newGroup.groupName = this.setGroupName(
      offerShipOwnerId,
      chargerContacts,
      options?.shipOwnerContacts
    );
    // newGroup.offerShipOwnerId = offerShipOwnerId;
    newGroup.groupParticipants = [];
    if (chargerContacts != null) {
      if (options?.clhContacts) {
        newGroup.offerId = offerShipOwnerId;
      } else {
        newGroup.offerShipOwnerId = offerShipOwnerId;
      }
      newGroup.groupParticipants = chargerContacts.contacts.map((m) => {
        return this.setChatGroupParticipantBasedOnContact(m);
      });
    }
    if (options?.shipOwnerContacts && options?.shipOwnerContacts?.contacts) {
      newGroup.offerShipOwnerId = offerShipOwnerId;
      newGroup.groupParticipants = newGroup.groupParticipants.concat(
        options.shipOwnerContacts.contacts?.map((m: any) => {
          return this.setChatGroupParticipantBasedOnContact(m);
        })
      );
    }
    if (options.clhContacts) {
      if (chargerContacts != null) {
        newGroup.offerId = offerShipOwnerId;
      } else {
        newGroup.offerShipOwnerId = offerShipOwnerId;
      }
      newGroup.groupParticipants = newGroup.groupParticipants.concat(
        options.clhContacts.map((m) => {
          return this.setChatGroupParticipantBasedOnUser(m);
        })
      );
    }
    return newGroup;
  }

  setChatGroupParticipantBasedOnUser(m: any): any {
    const chatGroupParticipant = new ChatGroupParticipant();
    chatGroupParticipant.participant = new ChatParticipant();
    chatGroupParticipant.participant.userId = m.userId || m.id;
    chatGroupParticipant.participant.userName = m.name || m.userName;
    return chatGroupParticipant;
  }

  private setNewChatParticipant(user: any) {
    const chatParticipant = new ChatParticipant();
    chatParticipant.userId = user.id;
    chatParticipant.userName = user.firstName;
    return chatParticipant;
  }

  private setChatGroupParticipantBasedOnContact(m: ChargerContact) {
    const chatGroupParticipant = new ChatGroupParticipant();
    chatGroupParticipant.participant = new ChatParticipant();
    chatGroupParticipant.participant.userId = m.userId;
    chatGroupParticipant.participant.userName = m.name;
    return chatGroupParticipant;
  }

  private setGroupName(
    offerShipOwnerId: string,
    chargerContacts?: Charger,
    shipOwnerContacts?: Shipowner
  ): string {
    //Set unic groupNames to avoid the problem of 2 groups with the same name
    let groupName: string;
    if (!shipOwnerContacts) {
      groupName = "CLH -" + chargerContacts?.id + "-" + offerShipOwnerId;
    } else if (!chargerContacts) {
      groupName = "CLH" + "-" + shipOwnerContacts.id + "-" + offerShipOwnerId;
    } else {
      groupName =
        shipOwnerContacts.id +
        "-" +
        chargerContacts.id +
        "-" +
        offerShipOwnerId;
    }
    const groupNameHash = this.utils.utf8_to_b64(groupName);
    return groupNameHash;
  }

  setReplyBasedOnCurrentUser(s: ChatMessage): ChatMessage {
    const isClh = this.accountService.currentUser.roles
      .map((x) => x.name)
      .includes("CLH Admin");
    const isCharger: boolean =
      this.globals.currentCharger != null && !isClh ? true : false;
    if (isClh) {
      if (s.isCLH) {
        s.reply = true;
      } else {
        s.reply = false;
      }
    } else {
      if (isCharger) {
        if (s.isCharger) {
          s.reply = true;
        } else {
          s.reply = false;
        }
      } else {
        if (s.isCLH || s.isCharger) {
          s.reply = false;
        } else {
          s.reply = true;
        }
      }
    }
    return s;
  }
}
