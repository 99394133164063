import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { ChargerContact } from "src/app/models/charger/charger-contact.model";
import { contactType } from "src/app/models/enum/contact-type";
import { ShipOwnerContact } from "src/app/models/shipowner/shipowner-contact.model";
import {
  AccountService,
  User,
  UserEdit,
  Role,
  // RoleService,
  UserService,
} from "@overa/security";
import { ClhValidators } from "src/app/utils/validators";
import { Observable, Subscription } from "rxjs";
import { ShipownerService } from "src/app/services/shipowner.service";
import { ChargerContactService } from "src/app/services/chargerContact.service";
import { ShipOwnerContactService } from "src/app/services/shipowner-contact.service";
import { ConfirmationService } from "primeng/api";
import { Utils } from "src/app/utils/utils";
import { MailTemplateTypes } from "../../../models/enum/mail-template-types";
import { PermissionsService } from "../../../services/permissions.service";
import { ActivatedRoute } from "@angular/router";
import { ChargerService } from "src/app/services/charger.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Constants } from "src/app/utils/constants";
import { OveraMessagesService } from "@overa/components/info-dialog";
import { CLHIdentityService } from "src/app/services/identity.service";
import { CLHGlobals } from "src/app/utils/globals";
import { MailTemplateService } from "src/app/services/mail-template.service";
import { NgxTranslateService } from "@overa/shared";
import { mapTemplate } from "src/app/utils/template-helper";

import { MessageTemplateService } from "@overa/notification";
const CHARTERER_ROLE_NAME = "charterer";
const SHIPOWNER_ROLE_NAME = "shipowner";
const ADMIN_ROLE_NAME = "administrator";
const NEGOTIATOR_ROLE_NAME = "negotiator";
const OPERATOR_ROLE_NAME = "operator";
const ROLE_NAMES = [CHARTERER_ROLE_NAME, SHIPOWNER_ROLE_NAME];
const COLUMNS_SCHEMA = [
  {
    key: "name",
    type: "text",
    label: "CONTACTS.FORM.NAME",
    float: "float-name",
    tooltip: "CONTACTS.FORM.NAME",
  },
  {
    key: "lastName",
    type: "text",
    label: "CONTACTS.FORM.SURNAMES",
    float: "float-lastName",
    tooltip: "CONTACTS.FORM.SURNAMES",
  },
  {
    key: "email",
    type: "text",
    label: "CONTACTS.FORM.EMAIL",
    float: "float-email",
    tooltip: "CONTACTS.FORM.EMAIL",
  },
  {
    key: "phone",
    type: "text",
    label: "CONTACTS.FORM.PHONE",
    float: "float-phone",
    tooltip: "CONTACTS.FORM.PHONE",
  },
  {
    key: "types",
    type: "enum",
    label: "CONTACTS.FORM.TYPE",
    float: "float-types",
    tooltip: "TOOLTIP.ROL",
  },
  {
    key: "isPrincipal",
    type: "boolean",
    label: "CONTACTS.FORM.MAIN",
    float: "",
    tooltip: "TOOLTIP.PRINCIPAL",
  },
  {
    key: "isEnabled",
    type: "boolean",
    readonly: true,
    label: "COMMONS.ACTIVE",
    float: "",
    tooltip: "TOOLTIP.ACTIVE",
  },
  {
    key: "isLockedOut",
    type: "boolean",
    readonly: true,
    label: "COMMONS.BLOCKED",
    float: "",
    tooltip: "TOOLTIP.BLOCKED",
  },
  {
    key: "termsAndConditionsAceptationDate",
    type: "date",
    readonly: true,
    label: "COMMONS.TCA",
    float: "",
    tooltip: "TOOLTIP.TCA",
  },
  {
    key: "privacyPolicyAceptationDate",
    type: "date",
    readonly: true,
    label: "COMMONS.PPA",
    float: "",
    tooltip: "TOOLTIP.PPA",
  },

  // {
  //   key: 'isEdit',
  //   type: 'isEdit',
  //   label: '',
  // },
  //{
  //    key: 'borrar',
  //    type: 'borrar',
  //    label: '',
  //    float: '',
  //},
];

@Component({
  selector: "app-contact-new",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
  providers: [ConfirmationService],
})

/*
Funciones:
  traducciones
*/
//Este componente se utiliza en la ficha del armador
//y en la ficha del cargador, identificandolo por la propiedad datasheet
export class ContactComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewChecked {
  //TODO añadir spinner, controlar errores

  formGroup!: FormGroup;
  isCharger = false;
  @Input() itemToUse: any;
  @Input() enabled: boolean = false;
  @Input() dataSheet: string = "";
  loading = false;
  // dataSource = new MatTableDataSource<(ChargerContact|ShipOwnerContact)>();
  dataSource: any[] = [];
  displayedColumns: string[] = COLUMNS_SCHEMA.map((col) => col.key);
  columnsSchema: any = [...COLUMNS_SCHEMA];
  types: any[] = [];
  contacts: any[] = [];
  selectedType: any;
  validStatus = "VALID";
  //TODO tipar
  identityUsers: any[] = [];
  selectedContact: any;
  tcaDate: Date = new Date();
  isEditting: boolean = false;
  isNew: boolean = false;
  readonly regexValidator = this.validators.myRegexValidator;
  selectedRoles = [];
  roles: Role[] = [];
  permissions: any[] = [];
  errorEmail: boolean = false;
  errorPhone: boolean = false;
  template: string = "";
  subjectTemplate: string = "";
  neededPermissions = [
    "users.view",
    "roles.view",
    "users.create",
    "roles.assign",
    "users.update",
  ];
  isClh: boolean = false;
  itemUpdate: any;
  nameTemplate: string = Constants.TEMPLATE_SHIPOWNERS_DECLINE;

  constructor(
    private translate: NgxTranslateService,
    public messagesService: OveraMessagesService,
    private validators: ClhValidators,
    private accountService: AccountService,
    // private rolesService: RoleService,
    private usersService: UserService,
    private shipownerService: ShipownerService,
    private chargerContactService: ChargerContactService,
    private shipownerContactService: ShipOwnerContactService,
    private confirmationService: ConfirmationService,
    private identityService: CLHIdentityService,
    private utils: Utils,
    private globals: CLHGlobals,
    private cdRef: ChangeDetectorRef,
    private permissionsService: PermissionsService,
    private route: ActivatedRoute,
    private mailTemplateService: MailTemplateService,
    private messageTemplateService: MessageTemplateService,
    private chargerService: ChargerService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.enabled ? this.formGroup?.enable() : this.formGroup?.disable();
  }

  ngOnDestroy(): void { }
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  initializeForm() {
    let needPrincipal: boolean = true;
    this.itemToUse?.contacts?.forEach((contacts: any) => {
      if (contacts.isPrincipal) {
        needPrincipal = false;
      }
    });
    this.formGroup = new FormGroup({
      email: new FormControl("", [Validators.required]),
      name: new FormControl("", Validators.required),
      lastName: new FormControl("", []),
      phone: new FormControl("", [Validators.required]),
      types: new FormControl([], Validators.required),
      isPrincipal: new FormControl(needPrincipal, Validators.required),
      // observations: new FormControl('', []),
      isBanned: new FormControl(false, []),
      // termsAndConditionsAceptationDate: new FormControl(null, []),
    });
    this.enabled ? this.formGroup.enable() : this.formGroup.disable();
  }

  ngOnInit(): void {
    this.columnsSchema = [...COLUMNS_SCHEMA];
    console.log("this.itemToUse", this.itemToUse);
    this.isClh =
      !this.globals.currentCharger && !this.globals.currentShipOwner
        ? true
        : false;
    if (!this.itemToUse) {
      this.enabled = this.permissionsService.hasPermissionToEdit(this.route);

      if (this.globals.currentCharger) {
        // this.itemToUse = this.globals.currentCharger;
        // this.loadData();
        this.spinner.show();
        this.chargerService
          .getById(this.globals.currentCharger.id || "")
          .subscribe({
            next: (item) => {
              this.itemToUse = item;
              // this.spinner.hide();
              this.loadData();
            },
            error: (err) => {
              this.spinner.hide();
              console.error(err);
            },
          });
      } else if (this.globals.currentShipOwner) {
        this.shipownerService
          .getById(this.globals.currentShipOwner.id || "")
          .subscribe({
            next: (shipowner) => {
              this.itemToUse = shipowner;
              this.loadData();
            },
            error: (err) => {
              console.error(err);
            },
          });
      }
    } else {
      this.loadData();
    }
  }

  loadData() {
    this.loading = true;
    console.log(this.itemToUse);
    this.dataSource = [];
    console.log(this.dataSource);
    if (this.itemToUse) {
      if (this.itemToUse.hasOwnProperty("shipOwners")) {
        this.isCharger = true;
      } else {
        this.columnsSchema.push({
          key: "isBanned",
          type: "boolean",
          label: "SHIPOWNER_LIST.TABLE.HEAD_COL6",
          float: "",
        });
      }
    }
    this.getMailTemplate();
    this.getTypes();
    //this.getUsersTermsDates();
    this.identityUsers = [];
    this.usersService.getUsers().subscribe({
      next: (users) => {
        this.accountService.getRoles().subscribe({
          next: (roles) => {
            this.accountService.getPermissions().subscribe((p) => {
              this.permissions = p;
              const loadedRoles = false;
              this.roles = roles;
              const rolesAdded = 0;

              this.identityUsers = users;
              this.dataSource = this.itemToUse?.contacts;
              const userIds = this.dataSource.map((x: any) => x.userId);
              this.identityService.getRolesByUsers(userIds).subscribe({
                next: (userRoles) => {
                  Object.keys(userRoles).forEach((userId: any, index) => {
                    const identityUser = this.identityUsers.find(
                      (u) => u.id === userId
                    );
                    const user =
                      this.dataSource.find((x: any) => x.userId === userId) ||
                      new User();
                    user.roles = this.roles.filter((role) =>
                      userRoles[userId].includes(role.id)
                    );
                    user.types = this.getTypesByRoles(user.roles);
                    user.isEnabled = identityUser.isEnabled;
                    user.isLockedOut = identityUser.isLockedOut;
                    identityUser.roles = user.roles;
                  });
                  this.spinner.hide();
                  this.loading = false;

                  //this.getDataSourceWithTerms();
                },
                error: (err) => {
                  this.spinner.hide();
                  console.error(err);

                  this.loading = false;
                },
              });
            });
          },
          error: (err) => {
            console.error(err);
            this.spinner.hide();
            this.loading = false;
          },
        });
        /* console.log("this.dataSource 1",this.dataSource);
        this.getDataSourceWithTerms(); */
        this.initializeForm();
      },
      error: (err) => {
        console.error(err);
        this.spinner.hide();
        this.loading = false;
      },
    });
  }

  getIdentityUsers() {
    //TODO controlar errores
    this.usersService
      .getUsers()
      .subscribe((users) => (this.identityUsers = users));
  }

  getTypes(): any {
    const res = this.translate.get("CONTACTS.TYPES");
    this.types = [
      { value: res.ADMINISTRATION, code: contactType.Admin },
      { value: res.NEGOTIATIONS, code: contactType.Negotiation },
      { value: res.OPERATIVE, code: contactType.Operative },
    ];
  }

  resetForm() {
    if (this.isEditting || this.isNew) {
      this.selectedContact = null;
      //return;
    }
    if (this.dataSource.length > 0) {
      this.formGroup.reset({ types: [], isPrincipal: false });
    } else {
      this.formGroup.reset({ types: [], isPrincipal: true });
    }
    this.isEditting = false;
    this.isNew = this.isNew ? false : false;
    this.dataSource.forEach((data) => {
      data.editable = false;
    });
  }

  newRow() {
    this.isNew = true;
    let newContact;
    if (this.isCharger) {
      newContact = new ChargerContact();
      newContact.isNew = true;
    } else {
      newContact = new ShipOwnerContact();
      newContact.isNew = true;
    }
    this.dataSource.forEach((data) => {
      data.editable = false;
    });
    newContact.email = "";
    newContact.phone = "";
    if (newContact instanceof ShipOwnerContact) {
      newContact.observations = "";
    }
    newContact.name = "";
    newContact.lastName = "";
    newContact.isBanned = false;
    newContact.termsAndConditionsAceptationDate = undefined;
    if (this.dataSource.find((x: any) => x.isPrincipal)) {
      newContact.isPrincipal = false;
    } else {
      newContact.isPrincipal = true;
    }
    this.selectedContact = newContact;
    this.dataSource.unshift(newContact);
    this.dataSource = [...this.dataSource];
  }
  // getEmailDomain(email: string) {
  //   let dominio = '';
  //   let partsOfEmailPrincipal = email.split('@');
  //   if (partsOfEmailPrincipal.length === 2) {
  //     dominio = partsOfEmailPrincipal[1];
  //   }
  //   return dominio;
  // }
  getMainContactDetails(): { domainPrincipal: string; emailPrincipal: string } {
    let domainPrincipal = "";
    let emailPrincipal = "";

    let thereIsMainContact: boolean = false;
    const field = "";

    if (this.itemToUse) {
      // field= this.globals.currentShipOwner ? 'currentShipOwner' : this.globals.currentCharger ? 'currentCharger' : '';
      // if (field !== '') {
      //    if (this.globals[field].contacts && this.globals[field].contacts.length > 0) {
      //     thereIsMainContact = this.globals[field].contacts.some((x:any) => x.isPrincipal);
      //     if (thereIsMainContact) emailPrincipal = this.globals[field].contacts.find((x:any) => x.isPrincipal)?.email ?? '';
      //   }
      // }

      // if (this.globals.isClh) {
      //   if (this.itemToUse && this.itemToUse.contacts && this.itemToUse.contacts.length > 0) {
      //     thereIsMainContact = this.itemToUse.contacts.some((x:any) => x.isPrincipal);
      //     if (thereIsMainContact) emailPrincipal = this.itemToUse.contacts.find((x:any) => x.isPrincipal)?.email ?? '';
      //   }
      // }
      if (this.itemUpdate != null) {
        if (
          this.itemUpdate &&
          this.itemUpdate.contacts &&
          this.itemUpdate.contacts.length > 0
        ) {
          thereIsMainContact = this.itemUpdate.contacts.some(
            (x: any) => x.isPrincipal
          );
          if (thereIsMainContact)
            emailPrincipal =
              this.itemUpdate.contacts.find((x: any) => x.isPrincipal)?.email ??
              "";
        }
      } else {
        if (
          this.itemToUse &&
          this.itemToUse.contacts &&
          this.itemToUse.contacts.length > 0
        ) {
          thereIsMainContact = this.itemToUse.contacts.some(
            (x: any) => x.isPrincipal
          );
          if (thereIsMainContact)
            emailPrincipal =
              this.itemToUse.contacts.find((x: any) => x.isPrincipal)?.email ??
              "";
        }
      }
      if (emailPrincipal === "" && this.dataSheet === "shipowner") {
        if (this.itemUpdate != null) {
          if (
            this.itemUpdate.genericEmail != "" &&
            this.itemUpdate.genericEmail != null
          ) {
            emailPrincipal = this.itemUpdate.genericEmail;
          }
        } else {
          if (
            this.itemToUse.genericEmail != "" &&
            this.itemToUse.genericEmail != null
          ) {
            emailPrincipal = this.itemToUse.genericEmail;
          }
        }
      }
    }
    if (emailPrincipal != "")
      domainPrincipal = this.utils.getEmailDomain(emailPrincipal);

    return { domainPrincipal, emailPrincipal };
  }
  // CRUD //

  create() {
    console.log(this.selectedContact);
    let domain = "";
    this.loading = true;
    this.errorEmail = false;
    let errorEmailDomain = false;
    const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const regexPhoneNumber = /^\+?(?:[0-9] ?){6,14}[0-9]$/;
    const testEmail = this.formGroup.get("email")?.value;
    const testPhone = this.formGroup.get("phone")?.value;
    const errorInEmail = !regexEmail.test(testEmail);
    const errorInPhone = !regexPhoneNumber.test(testPhone);
    this.updateItemUpdate(() => {
      const { domainPrincipal, emailPrincipal } = this.getMainContactDetails();
      let errorSameMailGeneric = false;
      if (this.dataSheet === "shipowner") {
        errorSameMailGeneric = this.checkSameGenericMail(testEmail);
      }

      if (this.isNew) {
        if (emailPrincipal != "" && !errorInEmail) {
          domain = this.utils.getEmailDomain(testEmail);
          if (domain != domainPrincipal) {
            errorEmailDomain = true;
          }
        }
      }
      if (
        !errorInEmail &&
        !errorInPhone &&
        !errorEmailDomain &&
        !errorSameMailGeneric
      ) {
        if (this.selectedContact.id) {
          console.log("exists (update)");
          this.editUser(this.selectedContact);
        } else {
          console.log("new (create)");
          this.createUser();
        }
      } else {
        this.errorEmail = errorInEmail ? true : false;
        this.errorPhone = errorInPhone ? true : false;
        const message: string[] = [];
        let messageError = "";
        if (errorInEmail) {
          message.push("MESSAGES.VALIDATION.MAIL");
        }
        if (errorInPhone) {
          message.push("MESSAGES.VALIDATION.PHONE");
        }
        if (errorEmailDomain) {
          message.push("MESSAGES.VALIDATION.MAIL_DOMAIN");
        }
        if (errorSameMailGeneric) {
          message.push("MESSAGES.VALIDATION.CHECK_GENERIC_MAIL");
        }
        if (message.length > 0) {
          message.forEach((x: any) => {
            messageError = this.translate.get(x);
            this.messagesService.showToast("ERROR", messageError, "error", "");
          });
        }

        this.loading = false;
      }
    });
  }
  cancelEdit() {
    this.isEditting = false;
    this.isNew = false;
    if (this.selectedContact.id) {
      console.log("Cancelling edit");
    } else {
      console.log("Remove first field");
      this.dataSource.splice(0, 1);
    }
    this.dataSource.forEach((data) => {
      data.editable = false;
    });
    this.resetForm();
  }
  createContact(userId: string) {
    const contact = { ...this.formGroup.value };
    console.log("CONTACTCREATE", contact);
    console.log("formgroupValue", this.formGroup.value);
    if (contact.isBanned == null || contact.isBanned == undefined) {
      contact.isBanned = false;
    }
    contact.types = this.selectedContact.types;
    contact.isEnabled = this.selectedContact.isEnabled;
    contact.isLockedOut = this.selectedContact.isLockedOut;
    contact.termsAndConditionsAceptationDate =
      this.selectedContact.termsAndConditionsAceptationDate;
    contact.roles = this.getRolesByType(this.selectedContact.types);
    contact.userId = userId;

    let subscription;
    if (this.isCharger) {
      (contact as ChargerContact).chargerId = this.itemToUse.id;
      subscription = this.chargerContactService.add(contact as ChargerContact);
    } else {
      (contact as ShipOwnerContact).shipOwnerId = this.itemToUse.id;
      subscription = this.shipownerContactService.add(
        contact as ShipOwnerContact
      );
    }
    console.log("contact good", contact);
    this.handlePrincipalContact(contact, subscription);
  }
  createUser() {
    let user = new User();
    user.userName = this.formGroup.get("email")?.value;
    user.email = this.formGroup.get("email")?.value;
    user.firstName = this.formGroup.get("name")?.value;
    user.lastName = this.formGroup.get("lastName")?.value;
    user.phoneNumber = this.formGroup.get("phone")?.value;

    const types = this.formGroup.get("types")?.value;
    console.log(types);
    //  user.roles = ;
    if (user.email) {
      let existsUser = false;
      const userEdit = new UserEdit();
      let userBd: any = undefined;
      let userId = "";
      this.usersService.getUsers().subscribe(async (users) => {
        this.identityUsers = users;
        for (const userIdent of users) {
          if (userIdent.email.toLowerCase() === user.email.toLowerCase()) {
            existsUser = true;
            const userIdentity = await this.usersService
              .getUser(userIdent.id)
              .toPromise();
            userId = userIdentity?.id || "";
            userBd = userIdentity;
          }
        }

        const newPassword = this.utils.generatePassword();
        const roles = this.getRolesByType(types);

        this.selectedContact.types = this.getTypesByRoles(roles);
        //let rolesNames = roles.map(x => x.name);
        //if (rolesNames.includes('charterer.administrator') || rolesNames.includes('shipowner.administrator')) {
        //    var adminRole = this.roles.find(x => x.name.toLowerCase() == 'admin');
        //    roles.push(adminRole);
        //}

        if (!user.roles) {
          user.roles = [];
        }
        if (!user.permissions) {
          user.permissions = [];
        }
        this.neededPermissions.forEach((per) => {
          if (!user.permissions.map((x: any) => x.value).includes(per)) {
            const permission = this.permissions.find(
              (x: any) => x.value == per
            );
            user.permissions.push(permission);
          }
        });
        roles.forEach((role) => {
          if (!user.roles.map((r) => r.name).includes(role.name)) {
            user.roles.push(role);
          }
        });

        if (!user.tenants || user.tenants.length === 0) {
          user.tenants = [];
          const currentTenant = this.accountService.currentTenant;
          user.tenants.push(currentTenant);
        }

        user.id = existsUser ? userId : undefined;
        let subscription;
        // var subscription = subscription = this.accountService
        //     .newUser(new UserEdit(user, newPassword, newPassword));
        if (existsUser) {
          user.isEnabled = userBd.isEnabled;
          user.isLockedOut = userBd.isLockedOut;
          user.mainTenantId = userBd.mainTenantId;
          user.userName = userBd.userName;
          this.selectedContact.isEnabled = user.isEnabled;
          this.selectedContact.isLockedOut = user.isLockedOut;
          //TODO comprobar si se pode editar, setear o userId ao contacto e despois update contacto
          // user.isEnabled = false;
          subscription = this.accountService.updateUser(user);

          subscription.subscribe(
            (res) => {
              user = res;

              this.shipownerService
                .checkUserAvailability(user.id || "")
                .subscribe({
                  next: (result) => {
                    if (result) {
                      this.createContact(user.id || "");
                      // user = new User();
                    } else {
                      this.messagesService.showToast(
                        "ERROR",
                        "MESSAGES.ERROR.USER_DUPLICATED",
                        "error",
                        ""
                      );
                      if (this.isCharger && !this.isClh) {
                        this.chargerService.getUserById(this.accountService.currentUser.id).subscribe(charger => {
                          this.globals.currentCharger = charger;
                        })
                      }
                      else if (!this.isCharger && !this.isClh) {
                        this.shipownerService.getShipOwnerByUserId(this.accountService.currentUser.id).subscribe(shipowner => {
                          this.globals.currentShipOwner = shipowner;
                        })
                      }

                      this.loading = false;
                    }
                  },
                  error: (err) => {
                    console.error(err);

                    this.loading = false;
                  },
                });
            },
            (error) => {
              this.messagesService.showError(error);

              this.loading = false;
              console.log(error);
            }
          );
        } else {
          user.isEnabled = false;
          subscription = this.accountService.newUser(
            new UserEdit(user, newPassword, newPassword)
          );

          subscription.subscribe(
            (res) => {
              user = res;
              //TODO setear id ao contacto e despois create contacto
              this.createContact(user.id || "");
              this.identityUsers.push(user);
              // this.addUser(user);
              user = new User();
            },
            (error) => {
              this.messagesService.showError(error);
              console.log(error);
              this.loading = false;
            }
          );
        }
      });
    }
  }

  invite(contact: any) {
    this.loading = true;
    this.loading = true;

    const newPassword = this.utils.generatePassword();
    const invitatorName = this.globals.user.firstName;
    let companyName = "";
    const contactName = contact.name;
    if (this.isCharger) {
      companyName = this.itemToUse.name;
    } else {
      companyName = this.itemToUse.charteringCompany;
    }
    const termsConditionsEs = this.globals.termsConditions.terms;
    const termsConditionsEn = this.globals.termsConditions.termsEN;
    const legalAdviceEs = Constants.LEGAL_ADVICE.es;
    const legalAdviceEn = Constants.LEGAL_ADVICE.en;
    const dataProtectionEs =
      this.globals.dataProtection.policy + Constants.DATA_PROTECTION.es;
    const dataProtectionEn =
      this.globals.dataProtection.policyEN + Constants.DATA_PROTECTION.en;
    const entity = {
      invitatorName: invitatorName,
      companyName: companyName,
      contactName: contactName,
      termsConditionsEs: termsConditionsEs,
      termsConditionsEn: termsConditionsEn,
      legalAdviceEs: legalAdviceEs,
      legalAdviceEn: legalAdviceEn,
      dataProtectionEs: dataProtectionEs,
      dataProtectionEn: dataProtectionEn,
    };
    const htmlContent = mapTemplate(this.template, entity, ["{{callbackUrl"]);
    const subject = mapTemplate(this.subjectTemplate, entity);
    const user = {
      email: contact.email,
      password: newPassword,
      returnUrl: document.baseURI,
      htmlContent: htmlContent,
      subject: subject,
      firstName: contact.name,
      lastName: contact.lastName,
      phoneNumber: contact.phone,
      roles: [],
      permissions: [],
      newPassword: newPassword,
      userName: contact.email,
      tenants: [],
    };
    this.identityService.sendInvitation(user).subscribe({
      next: (user: any) => {
        console.log(user);
        this.messagesService.showToast(
          "OK",
          $localize`:@@MESSAGES.SUCCESS.INVITATION_SENDED:Invitación enviada`,
          "success",
          ""
        );
        this.loading = false;

        // this.messagesService.showToast('WARNING', 'Usuario ya invitado', 'warning', '');
      },
      error: (err: any) => {
        console.error(err);
        this.loading = false;

        if (err.status == 400) {
          if (err.error == "User already invited") {
            this.messagesService.showToast(
              "WARNING",
              $localize`:@@MESSAGES.ERROR.INVITATION_SENDED:El usuario ya ha sido invitado`,
              "warning",
              ""
            );
          } else if (err.error == "User already enabled") {
            this.messagesService.showToast(
              "WARNING",
              $localize`:@@MESSAGES.ERROR.USER_ALREADY_ENABLED:El usuario ya está activo`,
              "warning",
              ""
            );
          }
        }
      },
    });
  }
  delete(itemContact: ChargerContact & ShipOwnerContact) {
    if (itemContact.isPrincipal) {
      this.messagesService.showToast(
        "WARNING",
        "MESSAGES.ERROR.CANNOT_DELETE_PRINCIPAL_CONTACT",
        "warning",
        ""
      );
      return;
    }
    // console.log('item',itemContact);
    // console.log('contactos',this.itemToUse.contacts);
    // console.log('contactos',this.dataSource.data);
    const trmessage = this.translate.get("COMMONS.ASK_DELETE_RECORD");
    const messageAccept = this.translate.get("ACTION.YES");

    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.loading = true;
        // console.log(shipowner);
        let subscription = this.chargerContactService.delete(
          itemContact.id || ""
        );
        if (!this.isCharger) {
          subscription = this.shipownerContactService.delete(
            itemContact.id || ""
          );
        }
        subscription.subscribe({
          next: (contact) => {
            this.identityService
              .disableUser(itemContact.email || "")
              .subscribe({
                next: (us) => {
                  console.log(us);
                  const data = this.dataSource;
                  data.forEach((item, index, object) => {
                    if (item.email === itemContact.email) {
                      object.splice(index, 1);
                    }
                  });
                  this.dataSource = data;
                  this.loading = false;
                },
                error: (err) => {
                  console.error(err);
                  const data = this.dataSource;
                  data.forEach((item, index, object) => {
                    if (item.email === itemContact.email) {
                      object.splice(index, 1);
                    }
                  });
                  this.dataSource = data;
                  this.loading = false;
                },
              });
          },
          error: (err) => {
            this.loading = false;
            //TODO: review delete, show error message.
            //this.identityService.disableUser(itemContact.email).subscribe({
            //    next: (us) => {
            //        console.log(us);
            //        let data = this.dataSource;
            //        data.forEach((item, index, object) => {
            //            if (item.email === itemContact.email) {
            //                object.splice(index, 1);
            //            }
            //        });
            //        this.dataSource = data;
            //        this.loading = false;
            //    },
            //    error: (err) => {
            //        console.error(err);
            //        this.loading = false;
            //    }
            //});
          },
        });
      },
      reject: () => { },
    });
  }

  handlePrincipalContact(contact: any, subscription: Observable<any>): any {
    const isOk = true;
    const types = this.formGroup.get("types")?.value;

    this.loading = false;
    if (
      contact.isPrincipal &&
      this.dataSource.some(
        (x: any) => x.isPrincipal && x.email != contact.email && x.id
      )
    ) {
      const principalContact = this.dataSource.find(
        (x: any) => x.isPrincipal && x.email != contact.email
      );
      const message =
        "Ya existe un contacto principal (" +
        principalContact.email +
        "). ¿Quiere establecer este contacto como principal?";

      const messageAccept = this.translate.get("ACTION.YES");

      return this.confirmationService.confirm({
        message: message,
        header: "",
        acceptLabel: messageAccept,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.loading = true;
          console.log("si");
          principalContact.isPrincipal = false;
          let subscriptionUpdate =
            this.chargerContactService.edit(principalContact);
          if (!this.isCharger) {
            subscriptionUpdate =
              this.shipownerContactService.edit(principalContact);
          }
          subscriptionUpdate.subscribe({
            next: (res) => {
              console.log(res);
              res.types = principalContact.types;
              res.roles = principalContact.roles;
              res.isLockedOut = principalContact.isLockedOut;
              res.isEnabled = principalContact.isEnabled;
              this.dataSource[
                this.dataSource.indexOf(
                  this.dataSource.find((x: any) => x.id == res.id)
                )
              ] = res;
              subscription.subscribe({
                next: (c) => {
                  c.types = contact.types;
                  c.roles = contact.roles;
                  c.isLockedOut = contact.isLockedOut;
                  c.isEnabled = contact.isEnabled;

                  console.log(c);

                  if (this.isEditting && contact.id) {
                    let subscriptionGet = this.chargerContactService.getById(
                      c.id
                    );
                    if (!this.isCharger) {
                      subscriptionGet = this.shipownerContactService.getById(
                        c.id
                      );
                    }
                    subscriptionGet.subscribe({
                      next: (cont) => {
                        const contacts = this.dataSource;
                        this.dataSource[
                          contacts.indexOf(
                            contacts.find((x: any) => x.id == c.id)
                          )
                        ] = c;
                        this.resetForm();
                        this.loading = false;
                      },
                      error: (err) => {
                        console.error(err);

                        this.loading = false;
                      },
                    });
                  } else {
                    console.log("Contact added", c);
                    c.isEnabled = contact.isEnabled;
                    c.isLockedOut = contact.isLockedOut;
                    if (this.isNew) {
                      this.dataSource.splice(0, 1);
                    }
                    this.dataSource = this.dataSource.concat(c);
                    this.resetForm();
                    this.loading = false;
                  }
                },
                error: (err) => {
                  console.error(err);
                  this.loading = false;
                },
              });
            },
            error: (err) => {
              console.error(err);
              this.loading = false;
            },
          });
        },
        reject: () => {
          contact.isPrincipal = false;
          subscription.subscribe({
            next: (c) => {
              c.types = contact.types;
              c.roles = contact.roles;
              c.isLockedOut = contact.isLockedOut;
              c.isEnabled = contact.isEnabled;

              console.log(c);
              //this.isEditting = false;
              if (this.isEditting && contact.id) {
                this.chargerContactService.getById(c.id).subscribe({
                  next: (cont) => {
                    //TODO añadir tamén en shipowners
                    const contacts = this.dataSource;
                    this.dataSource[
                      contacts.indexOf(contacts.find((x: any) => x.id == c.id))
                    ] = c;
                    this.resetForm();
                    this.loading = false;
                  },
                  error: (err) => {
                    console.error(err);
                    this.loading = false;
                  },
                });
              } else {
                console.log("Contact added", c);
                if (this.isNew) {
                  this.dataSource.splice(0, 1);
                }
                c.isEnabled = contact.isEnabled;
                c.isLockedOut = contact.isLockedOut;
                this.dataSource = this.dataSource.concat(c);
                this.resetForm();
                this.loading = false;
              }
            },
            error: (err) => {
              console.error(err);
              this.loading = false;
            },
          });
        },
      });
      //return {result: true, message: message}
    } else if (
      !contact.isPrincipal &&
      !this.dataSource.some(
        (x: any) => x.isPrincipal && x.email != contact.email && !x.isNew
      )
    ) {
      //
      const messagePrincipal = "Se marcará este contacto como principal";
      this.messagesService.showToast(
        "WARNING",
        messagePrincipal,
        "warning",
        ""
      );
      contact.isPrincipal = true;
      subscription.subscribe({
        next: (c) => {
          c.types = contact.types;
          c.roles = contact.roles;
          c.isLockedOut = contact.isLockedOut;
          c.isEnabled = contact.isEnabled;
          console.log(c);
          //this.isEditting = false;
          let subscriptionGet = this.chargerContactService.getById(c.id);
          if (!this.isCharger) {
            subscriptionGet = this.shipownerContactService.getById(c.id);
          }
          if (this.isEditting && contact.id) {
            subscriptionGet.subscribe({
              next: (cont) => {
                //TODO añadir tamén en shipowners
                const contacts = this.dataSource;
                this.dataSource[
                  contacts.indexOf(contacts.find((x: any) => x.id == c.id))
                ] = c;
                this.resetForm();
                this.loading = false;
              },
              error: (err) => {
                console.error(err);
                this.loading = false;
              },
            });
          } else {
            console.log("Contact added", c);
            if (this.isNew) {
              this.dataSource.splice(0, 1);
              this.isNew = this.isNew ? false : false;
              this.loading = false;
            }
            c.isEnabled = contact.isEnabled;
            c.isLockedOut = contact.isLockedOut;
            this.dataSource = this.dataSource.concat(c);
            this.resetForm();
            this.loading = false;
          }
        },
        error: (err) => {
          console.error(err);
          this.loading = false;
        },
      });
      //return {result: false, message: 'Al menos un contacto debe ser principal' + principalContact.email}
    } else {
      //
      subscription.subscribe({
        next: (c) => {
          c.types = contact.types;
          c.roles = contact.roles;
          c.isLockedOut = contact.isLockedOut;
          c.isEnabled = contact.isEnabled;
          console.log(c);
          //this.isEditting = false;
          let subscriptionGet = this.chargerContactService.getById(c.id);
          if (!this.isCharger) {
            subscriptionGet = this.shipownerContactService.getById(c.id);
          }
          if (this.isEditting && contact.id) {
            subscriptionGet.subscribe({
              next: (cont) => {
                //TODO añadir tamén en shipowners
                const contacts = this.dataSource;
                this.dataSource[
                  contacts.indexOf(contacts.find((x: any) => x.id == c.id))
                ] = c;
                this.resetForm();
                this.loading = false;
              },
              error: (err) => {
                console.error(err);
                this.loading = false;
              },
            });
          } else {
            console.log("Contact added", c);
            if (this.isNew) {
              this.dataSource.splice(0, 1);
            }
            this.dataSource = this.dataSource.concat(c);
            this.resetForm();
            this.loading = false;
          }
        },
        error: (err) => {
          console.error(err);
          this.loading = false;
        },
      });
      //return {result: true, message:''}
    }
  }

  setEditting(contactAux: any) {
    // if(contactAux && !contactAux.id){
    //   this.dataSource.splice(0, 1);
    //   this.resetForm();
    // }
    console.log("contactAux", contactAux);
    contactAux.editable = true;
    const types = this.getTypesByRoles(contactAux.roles);

    const contact = {
      name: contactAux.name,
      email: contactAux.email,
      phone: contactAux.phone,
      //type: this.types[0].code,
      lastName: contactAux.lastName,
      observations: contactAux.observations,
      isBanned: contactAux.isBanned || false,
      isPrincipal: contactAux.isPrincipal,
      termsAndConditionsAceptationDate:
        contactAux.termsAndConditionsAceptationDate,
      types: types,
    };
    this.dataSource.forEach((data) => {
      if (data.email == contactAux.email) {
        data.editable = true;
      } else {
        data.editable = false;
      }
    });
    delete contact.observations;
    delete contact.termsAndConditionsAceptationDate;
    this.formGroup.setValue(contact);
    this.selectedContact = contactAux;
    this.isEditting = true;
  }
  getRolesByType(types: any): Role[] {
    let roleSearch = SHIPOWNER_ROLE_NAME;
    if (this.isCharger) {
      roleSearch = CHARTERER_ROLE_NAME;
    }
    const roles: any[] = [];
    const rolesSearch = types.map((type: any) => {
      return roleSearch + "." + type.code;
    });
    // this.selectedRoles.forEach()

    //if (this.roles.map(r => r.name.toLowerCase()).includes(roleSearch)) {
    //user.roles = [];
    rolesSearch.forEach((roleName: string) => {
      const role = this.roles.find((r) => r.name.toLowerCase() === roleName);
      if (role) {
        roles.push(role);
      }
    });
    return roles;
  }
  getTypesByRoles(roles: any) {
    // var roleSearch = SHIPOWNER_ROLE_NAME;
    // if(this.isCharger){
    //   roleSearch = CHARTERER_ROLE_NAME;
    // }
    const types: any[] = [];
    this.types.forEach((type) => {
      if (
        roles
          ?.filter((x: any) => x.name.includes("."))
          .map((x: any) => x.name.split(".")[1])
          .includes(type.code)
      ) {
        types.push(type);
      }
    });
    return types;
  }

  editUser(contactAux: any) {
    console.log("edit user contact info", contactAux);
    const contact = { ...contactAux, ...this.formGroup.value };
    console.log(contact);
    const type = this.formGroup.get("types")?.value;
    console.log(contact.types);
    const roles = this.getRolesByType(type);
    if (contact.isBanned == null || contact.isBanned == undefined) {
      contact.isBanned = false;
    }
    //let rolesNames = roles.map(x => x.name);
    //if (rolesNames.includes('charterer.administrator') || rolesNames.includes('shipowner.administrator')) {
    //    var adminRole = this.roles.find(x => x.name.toLowerCase() == 'admin');
    //    roles.push(adminRole);
    //}

    console.log(type);
    console.log(roles);
    contact.types = type;
    const user = this.identityUsers.find((user) => user.id == contact.userId);
    if (!user.roles) {
      user.roles = [];
    }
    const rolesDelete = user.roles.filter(
      (role: any) =>
        !roles.map((r) => r.name).includes(role.name) &&
        (role.name.includes("charterer") || role.name.includes("shipowner"))
    );
    for (const role in rolesDelete) {
      const index = user.roles.indexOf(role);
      user.roles.splice(index, 1);
    }
    console.log(user.roles);

    roles.forEach((role) => {
      if (!user.roles.map((r: any) => r.name).includes(role.name)) {
        user.roles.push(role);
      }
    });

    this.accountService.getUser(user.id).subscribe({
      next: (res) => {
        res.roles = user.roles;
        res.phoneNumber = contact.phone;
        res.firstName = contact.name;
        res.lastName = contact.lastName;

        this.accountService.updateUser(res).subscribe((us) => {
          console.log(us);
          //update user
          //delete contact.type;
          contact.roles = us.roles;
          contact.isEnabled = us.isEnabled;
          contact.isLockedOut = us.isLockedOut;
          //contact.termsAndConditionsAceptationDate = us.termsAndConditionsAceptationDate
          if (contact.termsAndConditionsAceptationDate) {
            this.tcaDate = contact.termsAndConditionsAceptationDate;
            delete contact.termsAndConditionsAceptationDate;
          }
          let subscription;
          if (this.isCharger) {
            subscription = this.chargerContactService.edit(contact);
          } else {
            subscription = this.shipownerContactService.edit(contact);
          }
          this.handlePrincipalContact(contact, subscription);
        });
      },
      error: (err) => {
        this.loading = false;
      },
    });
  }
  getMailTemplate() {
    this.messageTemplateService.readAll().subscribe({
      next: (templates) => {
        this.nameTemplate = this.isCharger
          ? "Invitacion aplicacion cargador"
          : "Invitacion aplicacion armador";
        const template = templates.find(
          (x: any) => x.name == this.nameTemplate
        );
        if (template) {
          this.template = template.content;
          this.subjectTemplate = template.subject;
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
  getUsersTermsDates() {
    let subscription;
    if (this.isCharger) subscription = this.chargerContactService.get();
    else subscription = this.shipownerContactService.get();

    subscription.subscribe({
      next: (items: any) => {
        this.contacts = items;
        console.log("this.contacts", this.contacts);
      },
      error: (err: any) => {
        console.error(err);
      },
    });
  }
  getDataSourceWithTerms() {
    this.getUsersTermsDates();
    const ids = this.dataSource.flatMap((item) => item.id);
    if (this.isCharger) {
      let index = 0;
      if (this.dataSource.length > 0) {
        this.dataSource.forEach((item) => {
          this.contacts.forEach((contact) => {
            if (
              item.chargerId === contact.chargerId &&
              item.userId === contact.userId &&
              item.id.includes(ids[index]) &&
              item.termsAndConditionsAceptationDate !=
              contact.termsAndConditionsAceptationDate
            ) {
              item.termsAndConditionsAceptationDate =
                contact.termsAndConditionsAceptationDate;
            }
          });
          index = index + 1;
        });
      }
    } else {
      let index = 0;
      if (this.dataSource.length > 0) {
        this.dataSource.forEach((item) => {
          if (this.contacts.length > 0) {
            this.contacts.forEach((contact) => {
              if (
                item.shipOwnerId === contact.shipOwnerId &&
                item.userId === contact.userId &&
                item.id.includes(ids[index]) &&
                item.termsAndConditionsAceptationDate !=
                contact.termsAndConditionsAceptationDate
              ) {
                item.termsAndConditionsAceptationDate =
                  contact.termsAndConditionsAceptationDate;
              }
            });
            index = index + 1;
          }
        });
      }
    }
  }
  checkSameGenericMail(testEmail: string): boolean {
    let checkSameGenericMail = false;
    if (
      this.itemToUse.genericEmail != "" &&
      this.itemToUse.genericEmail != null
    ) {
      checkSameGenericMail = testEmail === this.itemToUse.genericEmail;
    }
    return checkSameGenericMail;
  }
  updateItemUpdate(callback: () => void): void {
    this.itemUpdate = null;
    let subscription;
    if (
      this.dataSheet !== "" &&
      this.dataSheet !== null &&
      this.dataSheet !== undefined
    ) {
      if (this.dataSheet === "shipowner") {
        subscription = this.shipownerService.getById(this.itemToUse.id);
      }
      if (this.dataSheet === "charger") {
        subscription = this.chargerService.getById(this.itemToUse.id);
      }
    } else {
      if (this.globals.currentCharger) {
        subscription = this.chargerService.getById(this.itemToUse.id);
      }
      if (this.globals.currentShipOwner) {
        subscription = this.shipownerService.getById(this.itemToUse.id);
      }
    }
    if (subscription) {
      subscription.subscribe({
        next: (value: any) => {
          if (value) {
            this.itemUpdate = value;
          }
          callback();
        },
        error: (err: any) => {
          console.log("errorUpdateItemContact", err);
          callback();
        },
      });
    }
  }
}
