import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, ReplaySubject } from "rxjs";
import { ConfigsService } from "@overa/shared";
import { ChatMessage } from "../models/chat/chatMessage.model";
import { CLHBaseService } from "./clh-base.service";

@Injectable({ providedIn: "root" })
export class ChatMessageService extends CLHBaseService {
  private chatMessageSubject: ReplaySubject<boolean> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public chatMessageObservable: Observable<boolean> =
    this.chatMessageSubject.asObservable();

  private routechatMessageSubject: ReplaySubject<string> = new ReplaySubject(1);
  public routechatMessageObservable: Observable<string> =
    this.routechatMessageSubject.asObservable();

  get(pageIndex = -1, pageSize = -1) {
    return this.http.get<ChatMessage[]>(
      `${this.baseUrl}/chatMessage?pageIndex=${pageIndex}&pageSize=${pageSize}`
    );
  }

  getById(id: string) {
    return this.http.get<ChatMessage>(`${this.baseUrl}/chatMessage/${id}`);
  }

  add(chatMessage: ChatMessage) {
    const endpointUrl = `${this.baseUrl}/chatMessage`;
    return this.http.post<ChatMessage>(endpointUrl, chatMessage);
  }

  edit(chatMessage: ChatMessage) {
    const endpointUrl = `${this.baseUrl}/chatMessage`;
    return this.http.put<ChatMessage>(endpointUrl, chatMessage);
  }

  delete(id: string) {
    const endpointUrl = `${this.baseUrl}/chatMessage/${id}`;
    return this.http.delete(endpointUrl);
  }

  getHistoricMessagesByGroupId(groupId: string) {
    return this.http.get<ChatMessage[]>(
      `${this.baseUrl}/chatMessage/getHistoricMessages/${groupId}`
    );
  }

  getMessagesMarkedAsSeen(chatMessages: ChatMessage[]) {
    return this.http.post<ChatMessage[]>(
      `${this.baseUrl}/chatMessage/markAsRead`,
      chatMessages
    );
  }
}
