import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-comments-dialog',
  templateUrl: './comments-dialog.component.html',
  styleUrls: ['./comments-dialog.component.scss'],
  providers:[DialogService]
})
export class CommentsDialogComponent implements OnInit {
  formComment: FormGroup;
  isreadonly:boolean=false;
  constructor(private dialogConfig: DynamicDialogConfig, private ref: DynamicDialogRef) { }

  ngOnInit(): void {
    var comment = this.dialogConfig.data['comment'] || '';
    console.log(comment);
    this.isreadonly=  this.dialogConfig.data['readonlyStep2'] || false;
    this.formComment = new FormGroup({
      comment: new FormControl(comment)
    });
  }
  save(){
    var comment = this.formComment.get('comment').value;

    this.ref.close(comment || '');
  }
  close(){
    
    this.ref.close(null);
  }

}
