export enum MailTemplateTypes {
  OFFER_SEND_CHARGER = 'Oferta: envio por cargador',
  OFFER_SEND_CHARGER_CLH ='Oferta: (CLH) envio por cargador ',
  OFFER_SHIPOWNER_RESPONSE = 'Oferta: respuesta armador',
  OFFER_SHIPOWNER_RESPONSE_TO_CHARGER = 'Oferta: respuesta armador a cargador',
  OFFER_SHIPOWNER_REJECT = 'OFFER_SHIPOWNER_REJECT',
  OFFER_FIRM_CHARGER = 'Oferta Firme: Acuse de recibo cargador',
  OFFER_FIRM_CHARGER_CLH = 'Oferta Firme: (CLH)Acuse de recibo cargador',
  OFFER_FIRM_CHARGER_RESPONSE = 'Oferta Firme: envio por cargador',
  OFFER_FIRM_CHARGER_RESPONSE_CLH = 'Oferta Firme:(CLH) envio por cargador',
  OFFER_FIRM_CLAUSES_CLH = 'Aviso Clausulas: envio por armador',
  OFFER_FIRM_CLAUSES_CLH_CLH = 'Aviso Clausulas: (CLH) envio por armador',
  OFFER_FIRM_COUNTER_CHARGER_RESPONSE = 'Oferta Firme Counter: respuesta cargador a armador',
  OFFER_FIRM_COUNTER_CHARGER_RESPONSE_CLH = 'Oferta Firme Counter: (CLH) respuesta cargador a armador',
  OFFER_FIRM_COUNTER_CHARGER = 'Oferta Firme Counter: Acuse de recibo cargador',
  OFFER_FIRM_COUNTER_CHARGER_CLH = 'Oferta Firme Counter: (CLH) Acuse de recibo cargador',
  OFFER_FIRM_COUNTER_SHIPOWNER_RESPONSE = 'Oferta Firme Counter: respuesta armador a cargador',
  OFFER_FIRM_COUNTER_SHIPOWNER_RESPONSE_CLH = 'Oferta Firme Counter:(CLH) respuesta armador a cargador',
  OFFER_FIRM_COUNTER_SHIPOWNER = 'Oferta Firme Counter: Acuse de recibo armador',
  OFFER_FIRM_COUNTER_SHIPOWNER_CLH = 'Oferta Firme Counter: (CLH) Acuse de recibo armador',
  OFFER_FIRM_RECAP_CONFIRM_SHIPOWNER = 'Oferta Firme Recap: Aviso Recap Confirmar para armador',
  OFFER_FIRM_RECAP_CONFIRM_CHARGER = 'Oferta Firme Recap: Aviso Recap Confirmar para cargador',
  OFFER_FIRM_RECAP_NOTICE_CONFIRM_SHIPOWNER = 'Oferta Firme Recap: Aviso Recap para Confirmar a armador',
  OFFER_FIRM_RECAP_NOTICE_CONFIRM_ACKNOWLEDGMENT_CHARGER = 'Oferta Firme Recap: Acuse de recibo a cargador al confirmar Recap',
  OFFER_FIRM_RECAP_NOTICE_CONFIRM_ACKNOWLEDGMENT_SHIPOWNER = 'Oferta Firme Recap:  Acuse de recibo a armador al confirmar Recap',
  OFFER_FIRM_RECAP_NOTICE_CONFIRM_CHARGER = 'Oferta Firme Recap: Aviso Recap para Confirmar a cargador',
  OFFER_FIRM_RECAP_SHIPOWNER = 'Oferta Firme Recap: Acuse de recibo armador',
  OFFER_FIRM_RECAP_CHARGER = 'Oferta Firme Recap: Acuse de recibo cargador',
  CANCELED_OFFERSHIPOWNER = 'Aviso Cancelacion Oferta Firme :envio por cargador',
  APP_INVITATION = 'Invitaci�n a la aplicaci�n',
  APP_INVITATION_SEND_LINK = 'Invitaci�n a la aplicaci�n por enlace',
  COMPARATIVE_REMEMBER_SHIPOWNER = 'Recordar:Envio por cargador',
  COMPARATIVE_REMEMBER_SHIPOWNER_CLH = 'Recordar:(CLH) Envio por cargador',
  MESSAGE_NOTICE_CHAT = 'Aviso De Mensaje En Chat',
  MESSAGE_NOTICE_CHAT_CHARTERER = 'Aviso De Mensaje En Chat Cargador',
  MESSAGE_NOTICE_CHAT_SHIPOWNER = 'Aviso De Mensaje En Chat Armador',
  SHIPOWNERS_FEEDBACK='Feedback Armadores',
  APPLICATION_INVITATION_CHARTERER = 'Invitacion aplicacion cargador',
  APPLICATION_INVITATION_SHIPOWNER = 'Invitacion aplicacion armador',
  OFFER_SHIPOWNER_RESPONSE_REGISTER='Contraoferta:invitacion registro nuevo armador',

}
