<div class="container-graphics">
    <div class="row align-items-center justify-content-center mr-2 ml-2">
        <div class="col">
            <p class="text-center colorBlue">{{'VIEWS.DASHBOARD.EXTERNAL_VISITS' | translate}}</p>
            <div  class="row text-center" echarts [options]="chartOption" style="margin:auto;width:320px;height:280px;"  [autoResize]="true">

            </div>
        </div>
        <div class="col">
            <p class="text-center colorBlue">{{'VIEWS.DASHBOARD.BRANCH_OFFICES' | translate}}</p>
            <div  class="row" echarts [options]="chartOption1" style="margin:auto;width:280px;height:280px;"  [autoResize]="true"></div>
        </div>
        <div class="col">
            <p class="text-center colorBlue">{{'VIEWS.DASHBOARD.EVOLUTION' | translate}}</p>
            <div  class="row" echarts [options]="chartOption2" style="margin:auto;width:auto;height:280px;"  [autoResize]="true"></div>
        </div>
    </div>
</div>