import { ComparativeOfferShipOwner } from "./comparative-offer-shipowner.model";
import { OfferState } from "../enum/offer-state.model";
import { QuantityType } from "../enum/quantity-type.model";

export class ComparativeOffer {

    id?: string;
    refOffer?:number;
    code?: string;
    customerName?: string;
    quantity?: number;
    quantityType?: QuantityType;
    commodity?: string;
    stowage?: string;
    loadingPorts?: string[];
    loadingPortConditions?: string;
    dischargingPorts?: string[];
    dischargingPortConditions?: string;
    cpForm?: string;
    restrictionsRegarding?: string;
    aditionalRequirements?: string;
    comments?: string;
    layCanFrom?: Date | null;
    layCanTo?: Date | null;
    chargeringTerm?: string;
    freight?: string;
    layTimeTerm?: string;
    state?: OfferState;
    expirationDate?: Date | null;
    addressComm?: string;
    vesselName?: string;
    remarks?: string;
    chargerName?: string;
    demurrage?:string;
    demurrageConditions?:string;
    offerShipOwners?: ComparativeOfferShipOwner[];
    comparativeNotes?: string;
    releaseDate?: Date;

    constructor() {
        this.id = this.id || '';
        this.refOffer = this.refOffer || undefined;
        this.code = this.code || undefined;
        this.quantity = this.quantity || undefined;
        this.quantityType = this.quantityType || QuantityType.MT;
        this.commodity = this.commodity || '';
        this.stowage = this.stowage || '';
        this.loadingPorts = this.loadingPorts || [];
        this.loadingPortConditions = this.loadingPortConditions || '';
        this.dischargingPorts = this.dischargingPorts || [];
        this.dischargingPortConditions = this.dischargingPortConditions || '';
        this.cpForm = this.cpForm || '';
        this.restrictionsRegarding = this.restrictionsRegarding || '';
        this.aditionalRequirements = this.aditionalRequirements || '';
        this.comments = this.comments || '';
        this.layCanFrom = this.layCanFrom || new Date();
        this.layCanTo = this.layCanTo || new Date();
        this.chargeringTerm = this.chargeringTerm || '';
        this.freight = this.freight || '';
        this.layTimeTerm = this.layTimeTerm || '';
        this.state = this.state || OfferState.OFFER;
        this.expirationDate = this.expirationDate || new Date();
        this.addressComm = this.addressComm || '';
        this.vesselName = this.vesselName || '';
        this.remarks = this.remarks || '';
        this.chargerName = this.chargerName || '';
        this.demurrage = this.demurrage || '';
         this.demurrageConditions = this.demurrageConditions || '';
        this.offerShipOwners = this.offerShipOwners || [];
    }
}