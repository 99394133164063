import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { map, Observable, ReplaySubject } from "rxjs";
import { Charger } from "../models/charger/charger.model";
import { Offer } from "../models/offers/offer.model";
import { OfferFilter } from "../models/searcher/offer-filter.model";
import { ConfigsService } from "@overa/shared";
import { CLHBaseService } from "./clh-base.service";

@Injectable({ providedIn: "root" })
export class ChargerService extends CLHBaseService {
  private chargerSubject: ReplaySubject<boolean> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public chargerObservable: Observable<boolean> =
    this.chargerSubject.asObservable();

  private routeChargerSubject: ReplaySubject<string> = new ReplaySubject(1);
  public routeChargerObservable: Observable<string> =
    this.routeChargerSubject.asObservable();

  get(pageIndex = -1, pageSize = -1) {
    return this.http
      .get<any[]>(
        `${this.baseUrl}/charger?pageIndex=${pageIndex}&pageSize=${pageSize}`
      )
      .pipe(
        map((res: any[]) => {
          res.forEach(
            (item) =>
              (item.dischargeDate = new Date(item.dischargeDate || new Date()))
          );
          return res;
        })
      );
  }

  getById(id: string) {
    return this.http.get<Charger>(`${this.baseUrl}/charger/${id}`);
  }

  add(charger: Charger) {
    const endpointUrl = `${this.baseUrl}/charger`;
    return this.http.post<Charger>(endpointUrl, charger);
  }

  edit(charger: Charger) {
    const endpointUrl = `${this.baseUrl}/charger`;
    return this.http.put<Charger>(endpointUrl, charger);
  }

  delete(id: string) {
    const endpointUrl = `${this.baseUrl}/charger/${id}`;
    return this.http.delete(endpointUrl);
  }
  getUserById(id: string) {
    return this.http.get<any>(`${this.baseUrl}/chargerContact/getByUser/${id}`);
  }
  getOfferByChargerIdWithOfferFilter(id: string, searchString: OfferFilter) {
    const endpointUrl = `${this.baseUrl}/charger/${id}/offers`;
    return this.http.post<Offer[]>(endpointUrl, searchString);
  }
}
