export class Note {

    id?: string;
    description?: string;
    entityId?:string;
    groupId?:string;
    contactName?:string;
    createdDate?:Date;

    constructor() {
    }
}