import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AccountService } from "@overa/security";
import { ChooseUserService } from "../../services/choose-user.service";
import { CLHGlobals } from "src/app/utils/globals";

@Component({
  selector: "app-shipowner-data",
  templateUrl: "./shipowner-data.component.html",
  styleUrls: ["./shipowner-data.component.scss"],
})
export class ShipownerDataComponent implements OnInit {
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private accountServive: AccountService,
    private chooseUserService: ChooseUserService,
    private globals: CLHGlobals
  ) {}

  ngOnInit() {
    if (
      this.accountServive.currentUser.roles
        .map((r) => r.name.toLowerCase())
        .includes(this.globals.clhRoleName.toLowerCase()) ||
      this.accountServive.currentUser.isGlobalAdmin
    ) {
      //this.globals.currentCharger = null;
      //this.globals.currentShipOwner = null;

      this.chooseUserService.setNull();
    }
  }
  selectShipowner(event: any) {
    console.log(event);
    if (event === "new") {
      this.router.navigate(["shipowners", event]);
    } else {
      this.router.navigate(["shipowners", event.id]);
    }
  }
}
