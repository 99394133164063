import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigsService } from "@overa/shared";
import { Observable, of, Subject, ReplaySubject, Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ShipOwnerContact } from "src/app/models/shipowner/shipowner-contact.model";
import { TypeContactOld } from "../models/enum/type-contact-old";
import { CLHBaseService } from "./clh-base.service";

@Injectable({ providedIn: "root" })
export class ShipOwnerContactService extends CLHBaseService {
  //DELETE not working

  get() {
    return this.http.get<any[]>(`${this.baseUrl}/shipownerContact`);
  }

  getById(id: string) {
    return this.http.get<ShipOwnerContact>(
      `${this.baseUrl}/shipownerContact/${id}`
    );
  }

  getByUser(userId: string) {
    return this.http.get<ShipOwnerContact>(
      `${this.baseUrl}/shipownerContact/getByUser/${userId}`
    );
  }

  add(shipownerContact: ShipOwnerContact) {
    shipownerContact.type = TypeContactOld.Admin;
    const endpointUrl = `${this.baseUrl}/shipownerContact`;
    return this.http.post<ShipOwnerContact>(endpointUrl, shipownerContact);
  }

  edit(shipownerContact: ShipOwnerContact) {
    shipownerContact.type = TypeContactOld.Admin;
    const endpointUrl = `${this.baseUrl}/shipownerContact`;
    return this.http.put<ShipOwnerContact>(endpointUrl, shipownerContact);
  }

  delete(id: string) {
    const endpointUrl = `${this.baseUrl}/shipownerContact/delete/${id}`;
    return this.http.delete(endpointUrl);
  }
}
