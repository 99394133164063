<ngx-spinner></ngx-spinner>
<p-toast></p-toast>
<p-confirmDialog styleClass="containerConfirmDialog" acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"></p-confirmDialog>
<div class="offerBinding" *ngIf="offerBinding">
  <!-- <ng-container *ngIf="!expirationOffer else showInfoExpiration "> -->
  <!-- CONTAINER ALL  -->
  <ng-container *ngIf="!expirationOffer">
    <!-- SHOW EXPIRATION DATE -->
    <ng-container *ngIf="offerBinding && offerBinding.expirationDate && !offerFirmBindingIsReadOnly && !isRecap">
      <div class="d-flex justify-content-center divMargin">
        <h4>
          <i class="pi pi-history" style="font-size: 2rem"></i><strong> {{'BINDING_OFFER.DEADLINE_EXPIRES' | translate}}
          </strong> {{offerBinding.expirationDate | date: 'dd/MM/yy HH:mm' }}
        </h4>
      </div>
    </ng-container>
    <!-- END SHOW EXPIRATION DATE -->
    <!-- SHIFT SHOW ONLY CLH -->
    <ng-container *ngIf="isClh && offerBinding.shift != UserType.ADMIN">
      <div class="d-flex justify-content-center divMargin">
        <h4 *ngIf="offerBinding.shift === UserType.CHARGER">
          <i class="pi pi-pi-user" style="font-size: 2rem"></i>
          <strong> {{'BINDING_OFFER.SHIFT' | translate}} </strong> {{'BINDING_OFFER.CHARTERER' | translate}}
        </h4>
        <h4 *ngIf="offerBinding.shift === UserType.SHIPOWNER">
          <i class="pi pi-pi-user" style="font-size: 2rem"></i>
          <strong> {{'BINDING_OFFER.SHIFT' | translate}} </strong> {{'BINDING_OFFER.SHIPOWNER' | translate}}
        </h4>
      </div>
    </ng-container>
    <!-- END SHIFT SHOW ONLY CLH -->
    <!-- CONTAINER TABLE -->
    <ng-container>
      <div class="row div-container-offer-binding" *ngIf="showTable">
        <!-- DIV TABLE -->
        <div class="col-12 col-sm-12 col-md-12 col-xl-8 divTableofferBinding" *ngIf="form" [formGroup]="form">
          <table class="table table-striped tableofferBinding">
            <thead class="theadofferBinding">
              <tr class="trofferBinding">
                <th class="th1offerBinding" scope="col">{{'STEP1_OFFER.TABLE.HEAD.HEAD_1' | translate}}</th>
                <!-- DELETE CHECK -->
                <!-- <th class="thStatus" scope="col"></th> -->
                <th class="th2offerBinding" scope="col"
                  [style]="(isFirstStepCharterer || isClhRevision)  ? 'width: 20%' : 'width: 30%' ">
                  <div class="d-flex flex-column">
                    <div>{{'STEP1_OFFER.TABLE.HEAD.HEAD_2' | translate}}</div>
                  </div>
                </th>

                <th *ngIf="isFirstStepCharterer || isClhRevision" class="th4offerBinding" scope="col"
                  style="width: 25%">
                  <div class="d-flex flex-column">
                    <div>{{'BINDING_OFFER.ORIGINAL_OFFER' | translate}}</div>
                  </div>
                </th>
                <th class="th3offerBinding" scope="col"
                  [style]="(isFirstStepCharterer || isClhRevision)  ? 'width: 45%' : 'width: 60%' ">
                  <div class="d-flex flex-column">
                    <div *ngIf="offerBinding?.refOffer">{{offerBinding.refOffer}}</div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody class="tbodyofferBinding">
              <tr class="tr0offerBinding" *ngIf="!isShipownerBinding">
                <th scope="row"></th>
                <!-- DELETE CHECK -->
                <!-- <td><i *ngIf="showCheckInTable1To4" class="pi pi-check-circle"></i></td> -->
                <td class="labelOffer">{{'STEP1_OFFER.TABLE.ROW.ROW_CODE' | translate}}</td>
                <td *ngIf="isFirstStepCharterer || isClhRevision">
                  <label *ngIf="offerBinding.code"> {{offerBinding.code}}</label>
                </td>
                <td>
                  <div class="d-flex justify-content-start align-items-center" *ngIf="offerBinding">
                    <label *ngIf="offerBinding.code"> {{offerBinding.code}}</label>
                  </div>
                </td>
              </tr>
              <!-- HIDDEN CUSTOMMER NAME -->
              <!-- <tr class="tr0offerBinding" *ngIf="!isShipownerBinding">
                              <th scope="row"></th>
                              <td><i *ngIf="showCheckInTable1To4" class="pi pi-check-circle"></i></td>
                              <td class="labelOffer">{{'STEP1_OFFER.TABLE.ROW.ROW_CUSTOMER_NAME' | translate}}</td>
                              <td *ngIf="isFirstStepCharterer || isClhRevision">
                                <label *ngIf="offerBinding.customerName"> {{offerBinding.customerName}}</label>
                              </td>
                              <td>
                                <div class="d-flex justify-content-start align-items-center" *ngIf="offerBinding">
                                  <label *ngIf="offerBinding.customerName"> {{offerBinding.customerName}}</label>
                                </div>
                              </td>
                            </tr> -->
              <!-- END HIDDEN CUSTOMMER NAME -->
              <!-- TR CHARGER NAME -->
              <tr class="tr1offerBinding">
                <th scope="row">1</th>
                <!-- DELETE CHECK -->
                <!-- <td><i *ngIf="showCheckInTable1To4" class="pi pi-check-circle"></i></td> -->
                <td class="labelOffer">{{'STEP1_OFFER.TABLE.ROW.ROW_NAME' | translate}}</td>
                <td *ngIf="isFirstStepCharterer || isClhRevision">
                  <label *ngIf="offerBinding?.chargerName">{{offerBinding.chargerName}}</label>
                </td>
                <td>
                  <div class="d-flex justify-content-start align-items-center" *ngIf="offerBinding">
                    <label *ngIf="offerBinding?.chargerName"> {{offerBinding.chargerName}}</label>
                  </div>
                </td>
              </tr>
              <!-- END TR CHARGER NAME -->
              <!-- TR SHIPOWNER NAME-->
              <tr class="tr2offerBinding">
                <th scope="row">2</th>
                <!-- DELETE CHECK -->
                <!-- <td><i *ngIf="showCheckInTable1To4" class="pi pi-check-circle"></i></td> -->
                <td class="labelOffer">{{'BINDING_OFFER.ROW.NAME_SHIPOWNER' | translate}}</td>
                <td *ngIf="isFirstStepCharterer || isClhRevision">
                  <label *ngIf="offerBinding?.shipOwnerName">{{offerBinding.shipOwnerName}}</label>
                </td>
                <td>
                  <div class="d-flex justify-content-start align-items-center" *ngIf="offerBinding">
                    <label *ngIf="offerBinding.shipOwnerName"> {{offerBinding.shipOwnerName}}</label>
                  </div>
                </td>
              </tr>
              <!-- END TR SHIPOWNER NAME-->
              <!-- TR QUANTITY/STOWAGE/COMMODITY -->
              <tr class="tr3offerBinding"
                [ngClass]="((!isShipownerBinding && !isClhRevision) ? ('state' + offerBindingInitial.quantityFieldState + ' ' +
             (offerBinding.quantityFieldHistories?.length ? 'input-orange' : 'input-nochanges')) : ('state' + offerBindingInitial.quantityFieldState ) && (((isCounter0shipOwner && changeQuantity)|| ((isNegotiation && changeQuantity))) ? ' orangeQuantity' : '' ) )">
                <th scope="row">3</th>
                <!-- DELETE CHECK -->
                <!-- <td><i *ngIf="showCheckInTable1To4" class="pi pi-check-circle"></i></td> -->
                <td class="labelOffer">
                  {{'STEP1_OFFER.TABLE.ROW.ROW_CARGO_QUANTITY' | translate}} / {{'BINDING_OFFER.ROW.ROW_STOWAGE' |
                  translate}} /
                  {{'STEP1_OFFER.TABLE.ROW.ROW_COMODITY' | translate}}
                </td>
                <td *ngIf="isFirstStepCharterer || isClhRevision">
                  <label *ngIf="offerBinding?.initialOffer?.quantity">
                    {{offerBinding.initialOffer.quantity | number: '1.0-0' }}
                    {{QuantityType[offerBinding.initialOffer.quantityType]}} /
                    {{offerBinding.initialOffer.stowage}} / {{offerBinding.initialOffer.commodity}}
                  </label>
                </td>
                <td>
                  <ng-container *ngIf="isShipownerBinding else isCharger">
                    <ng-container *ngIf="isRecap else containerGeneralQuantity">
                      <div *ngIf="offerBinding" class="row" style="width: 100%">
                        <div class="row">
                          <div class="col-12">
                            <label>
                              {{offerBinding.quantity | number: '1.0-0' }} {{QuantityType[offerBinding.quantityType]}} /
                              {{offerBinding.stowage}}
                            </label>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <label> {{offerBinding.commodity}}</label>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #containerGeneralQuantity>
                      <div [ngClass]="withoutBorderInput ? 'withoutBorderAll field d-flex' : 'field d-flex'">
                        <div class="d-flex flex-wrap align-content-center divQuantity">
                          <ng-container>
                            <div style="padding-left: 0 !important"
                              class="w-100 d-flex flex-wrap flex-row justify-content-start align-items-center">
                              <div class="d-flex flex-column justify-content-start divInputQuantity">
                                <p-inputNumber formControlName="quantity" class="inputNumberQuantity"
                                  inputStyleClass="quantityOfferBinding"
                                  [readonly]="offerBinding.quantityFieldShowLabel" locale="de-DE"
                                  (click)="changeClassOnDivButtonsOffer('quantityField')">
                                </p-inputNumber>
                                <div
                                  *ngIf="!isRecap && form.get('quantity').touched  && !form.get('quantity').value  && form.get('quantity').hasError('required')"
                                  class="error-label labelError">
                                  {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                                </div>
                              </div>
                              <p-dropdown class="dropdownQuantityType" [options]="quantityTypes"
                                formControlName="quantityType"
                                [readonly]="offerBinding.quantityFieldShowLabel"></p-dropdown>
                              <div class="divLabelBarra">
                                <label> / </label>
                              </div>
                              <div class="divInputStowage">
                                <input type="text" formControlName="stowage" pInputText size="30" placeholder=""
                                  class="inputStowage" id="inputTable1" [readonly]="offerBinding.quantityFieldShowLabel"
                                  (click)="changeClassOnDivButtonsOffer('quantityField')" />
                                <div
                                  *ngIf="!isRecap && form.get('stowage').touched  && !form.get('stowage').value  && form.get('stowage').hasError('required')"
                                  class="error-label labelError">
                                  {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                                </div>
                              </div>
                            </div>
                            <div class="field divInputCommodity w-100">
                              <clh-textarea [form]="form" [nameControl]="'commodity'" [style]="'width:100%;'"
                                [errorLabel]="'MESSAGES.VALIDATION.REQUIRED_FIELD'"
                                [placeholder]="'STEP1_OFFER.PLACEHOLDER.PLACEHOLDER_COMODITY'" [label]="''"
                                [idTextarea]="'textareaCommodityBinding'"
                                [classTextarea]="'inputCommodity w-100 clhTextarea'" [required]="true"
                                [readOnly]="offerBinding.quantityFieldShowLabel"
                                [showLabelFloat]="false"></clh-textarea>
                            </div>
                          </ng-container>
                        </div>
                        <ng-container
                          *ngIf="((isCounter0shipOwner && changeQuantity) || (isNegotiation && changeQuantity))">
                          <div class="div-button-collapse d-flex historicQuantity"
                            *ngIf="offerBinding.quantityFieldHistories?.length">
                            <!-- <ng-container *ngIf="!withoutBorderInput || isClh">
                          <div class="div-button-collapse d-flex" *ngIf="offerBinding.quantityFieldHistories?.length"> -->
                            <button class="btn btn-link btn-collapse" type="button" data-toggle="collapse"
                              data-target="#collapseHistoryquantityField" aria-expanded="false"
                              aria-controls="collapseHistoryquantityField"
                              (click)="addClassTrHistory($event,'quantityField')">
                              <span class="p-button-icon pi pi-chevron-down" [pTooltip]="'ACTION.EXPAND'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                              <span class="p-button-icon pi pi-chevron-up" [pTooltip]="'ACTION.COLLAPSE'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                            </button>
                          </div>
                        </ng-container>
                        <!-- <div
                          class="d-flex justify-content-center align-items-center buttonsOffer quantityField"
                          *ngIf="isNegotiation && !offerFirmBindingIsReadOnly && !isViewNegotationAfterRecap"
                        >
                          <app-buttons-offer
                            [field]="'quantityField'"
                            (approveField)="onApproveField($event)"
                            (undoField)="onUndoField($event)"
                            (undoApprove)="onUndoApprove($event)"
                            (onReopen)="reopenField($event)"
                            (onSave)="saveField($event)"
                            (onEdit)="setEditable('quantityField', $event)"
                            [value]="form.get('quantity')?.value +' ' + form.get('quantityType')?.value + ' ' + form.get('stowage')?.value + ' ' + form.get('commodity')?.value"
                            [isApproved]="confirmedStatus.includes(offerBinding.quantityFieldState)"
                            [disabled]="!form.get('quantity').valid || !form.get('commodity').valid"
                          ></app-buttons-offer>
                        </div> -->
                      </div>
                    </ng-template>
                  </ng-container>
                  <ng-template #isCharger>
                    <ng-container *ngIf="isRecap else containerGeneralQuantity">
                      <div *ngIf="offerBinding" class="row" style="width: 100%">
                        <div class="row">
                          <div class="col-12">
                            <label>
                              {{offerBinding.quantity | number: '1.0-0' }} {{QuantityType[offerBinding.quantityType]}} /
                              {{offerBinding.stowage}}
                            </label>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <label> {{offerBinding.commodity}}</label>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #containerGeneralQuantity>
                      <div [ngClass]="withoutBorderInput ? 'withoutBorderAll field d-flex' : 'field d-flex'">
                        <div class="d-flex flex-wrap align-content-center divQuantity">
                          <ng-container>
                            <div style="padding-left: 0 !important"
                              class="w-100 d-flex flex-wrap flex-row justify-content-start align-items-center">
                              <div class="d-flex flex-column justify-content-start divInputQuantity">
                                <p-inputNumber formControlName="quantity" class="inputNumberQuantity"
                                  inputStyleClass="quantityOfferBinding"
                                  [readonly]="offerBinding.quantityFieldShowLabel" locale="de-DE"
                                  (click)="changeClassOnDivButtonsOffer('quantityField')">
                                </p-inputNumber>
                                <div
                                  *ngIf="!isRecap && form.get('quantity').touched  && !form.get('quantity').value  && form.get('quantity').hasError('required')"
                                  class="error-label labelError">
                                  {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                                </div>
                              </div>
                              <p-dropdown class="dropdownQuantityType" [options]="quantityTypes"
                                formControlName="quantityType"
                                [readonly]="offerBinding.quantityFieldShowLabel"></p-dropdown>
                              <div class="divLabelBarra">
                                <label> / </label>
                              </div>
                              <div class="divInputStowage">
                                <!-- <span class="p-float-label"> -->
                                <input type="text" formControlName="stowage" pInputText size="30" placeholder=""
                                  class="inputStowage" id="inputTable1" [readonly]="offerBinding.quantityFieldShowLabel"
                                  (click)="changeClassOnDivButtonsOffer('quantityField')" />
                                <!-- <label class="labelColor" for="inputTable1">{{'STEP1_OFFER.PLACEHOLDER.PLACEHOLDER_STOWAGE' | translate}}</label>
                                                             </span> -->
                                <div
                                  *ngIf="!isRecap && form.get('stowage').touched  && !form.get('stowage').value  && form.get('stowage').hasError('required')"
                                  class="error-label labelError">
                                  {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                                </div>
                              </div>
                            </div>
                            <div class="field divInputCommodity w-100">
                              <clh-textarea [form]="form" [nameControl]="'commodity'" [style]="'width:100%;'"
                                [errorLabel]="'MESSAGES.VALIDATION.REQUIRED_FIELD'"
                                [placeholder]="'STEP1_OFFER.PLACEHOLDER.PLACEHOLDER_COMODITY'" [label]="''"
                                [idTextarea]="'textareaCommodityBinding'"
                                [classTextarea]="'inputCommodity w-100 clhTextarea'" [required]="true"
                                [readOnly]="offerBinding.quantityFieldShowLabel"
                                [showLabelFloat]="false"></clh-textarea>
                            </div>
                          </ng-container>
                        </div>
                        <ng-container *ngIf="!withoutBorderInput || isClh">
                          <div class="div-button-collapse d-flex" *ngIf="offerBinding.quantityFieldHistories?.length">
                            <button class="btn btn-link btn-collapse" type="button" data-toggle="collapse"
                              data-target="#collapseHistoryquantityField" aria-expanded="false"
                              aria-controls="collapseHistoryquantityField"
                              (click)="addClassTrHistory($event,'quantityField')">
                              <span class="p-button-icon pi pi-chevron-down" [pTooltip]="'ACTION.EXPAND'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                              <span class="p-button-icon pi pi-chevron-up" [pTooltip]="'ACTION.COLLAPSE'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                            </button>
                          </div>
                        </ng-container>
                        <div class="d-flex justify-content-center align-items-center buttonsOffer quantityField"
                          *ngIf="isNegotiation && !offerFirmBindingIsReadOnly && !isViewNegotationAfterRecap">
                          <app-buttons-offer [field]="'quantityField'" (approveField)="onApproveField($event)"
                            (undoField)="onUndoField($event)" (undoApprove)="onUndoApprove($event)"
                            (onReopen)="reopenField($event)" (onSave)="saveField($event)"
                            (onEdit)="setEditable('quantityField', $event)"
                            [value]="form.get('quantity')?.value +' ' + form.get('quantityType')?.value + ' ' + form.get('stowage')?.value + ' ' + form.get('commodity')?.value"
                            [isApproved]="confirmedStatus.includes(offerBinding.quantityFieldState)"
                            [disabled]="!form.get('quantity').valid || !form.get('commodity').valid"></app-buttons-offer>
                        </div>
                      </div>
                    </ng-template>
                  </ng-template>
                </td>
              </tr>
              <tr class="trHistoryquantityField hiddenHistory">
                <td [attr.colspan]="(isFirstStepCharterer || isClhRevision) ? '4' : '3'">
                  <ng-container
                    *ngIf="(offerBinding && (!withoutBorderInput || isClh)) ||( offerBinding && changeQuantity)">
                    <app-histories-offer fieldName="quantityField" *ngIf="offerBinding"
                      [negotiationOfferShipOwner]="offerBinding"></app-histories-offer>
                  </ng-container>
                </td>
              </tr>
              <!-- END TR QUANTITY/STOWAGE/COMMODITY -->
              <!-- TR LOADINGPORTS -->
              <tr class="tr4offerBinding" [ngClass]="(!isClhRevision) ? 'state' + offerBindingInitial.loadingPortsState + ' ' +
                                 (offerBinding.loadingPortHistories?.length ? 'input-orange' : 'input-') : ''">
                <th scope="row">4</th>
                <!-- DELETE CHECK -->
                <!-- <td><i *ngIf="confirmedStatus.includes(offerBinding.loadingPortsState)  && isNegotiation" class="pi pi-check-circle"></i></td> -->
                <td class="labelOffer">{{'STEP1_OFFER.TABLE.ROW.ROW_LOADING' | translate}}</td>
                <td *ngIf="isFirstStepCharterer || isClhRevision">
                  <label
                    *ngIf="offerBinding?.initialOffer?.loadingPorts">{{offerBinding.initialOffer.loadingPorts.join(',')}}</label>
                </td>
                <td>
                  <ng-container *ngIf="isRecap else containerGeneralLoadingPorts">
                    <div class="d-flex justify-content-start" *ngIf="offerBinding">
                      <label *ngIf="offerBinding.loadingPorts"> {{offerBinding.loadingPorts.join(',')}}</label>
                    </div>
                  </ng-container>
                  <ng-template #containerGeneralLoadingPorts>
                    <div [ngClass]="withoutBorderInput ? 'withoutBorder' : ''">
                      <div class="d-flex justify-content-start align-items-center"
                        [ngClass]="offerBinding.loadingPortsShowLabel ? 'checkConfirm' : ''">
                        <overa-autocomplete [formGroup]="form" [controlName]="'loadingPorts'"
                          [isDisabled]="offerBinding.loadingPortsShowLabel" [showClear]="false" [options]="ports"
                          [selectedItems]="offerBinding.loadingPorts" [filterStartIndex]="3" [multiple]="true"
                          [validators]="[arrValidator]" [required]="true"
                          [readonly]="offerBinding.loadingPortsShowLabel"
                          (click)="changeClassOnDivButtonsOffer('loadingPorts')"></overa-autocomplete>

                        <!-- <overa-autocomplete
                          [form]="form"
                          *ngIf="ports"
                          [name]="'loadingPorts'"
                          style="width: 100%"
                          [required]="true"
                          [items]="ports"
                          [readonly]="offerBinding.loadingPortsShowLabel"
                          [selectedItems]="offerBinding.loadingPorts"
                          [multiple]="true"
                          [filterStartIndex]="3"
                          [validators]="[arrValidator]"
                          (click)="changeClassOnDivButtonsOffer('loadingPorts')"
                        >
                        </overa-autocomplete> -->
                        <ng-container *ngIf="!withoutBorderInput || isClh">
                          <div class="div-button-collapse d-flex" *ngIf="offerBinding.loadingPortHistories?.length">
                            <button class="btn btn-link btn-collapse" type="button" data-toggle="collapse"
                              data-target="#collapseHistoryloadingPort" aria-expanded="false"
                              aria-controls="collapseHistoryloadingPort"
                              (click)="addClassTrHistory($event,'loadingPorts')">
                              <span class="p-button-icon pi pi-chevron-down" [pTooltip]="'ACTION.EXPAND'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                              <span class="p-button-icon pi pi-chevron-up" [pTooltip]="'ACTION.COLLAPSE'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                            </button>
                          </div>
                        </ng-container>
                        <div *ngIf="isNegotiation && !offerFirmBindingIsReadOnly && !isViewNegotationAfterRecap"
                          class="buttonsOffer loadingPorts">
                          <!--  [form]="this.form"?? -->
                          <app-buttons-offer [field]="'loadingPorts'" (approveField)="onApproveField($event)"
                            [disabled]="!form.get('loadingPorts').valid" (undoField)="onUndoField($event)"
                            (undoApprove)="onUndoApprove($event)" (onReopen)="reopenField($event)"
                            (onSave)="saveField($event)" (onEdit)="setEditable('loadingPorts', $event)"
                            [value]="form.get('loadingPorts')?.value"
                            [isApproved]="confirmedStatus.includes(offerBinding.loadingPortsState)"></app-buttons-offer>
                        </div>
                      </div>
                      <div class="d-flex flex-column justify-content-start" *ngIf="!isRecap">
                        <div *ngIf="form.get('loadingPorts').touched &&  form.get('loadingPorts').hasError('required') "
                          class="error-label labelError">
                          {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                        </div>
                        <div *ngIf="form.get('loadingPorts').touched && form.get('loadingPorts').hasError('notInList')"
                          class="error-label labelError">
                          {{'MESSAGES.VALIDATION.PORTS_INVALID' | translate}}
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </td>
              </tr>
              <tr class="trHistoryloadingPorts hiddenHistory">
                <td [attr.colspan]="(isFirstStepCharterer || isClhRevision) ? '4' : '3'">
                  <ng-container *ngIf="!withoutBorderInput || isClh">
                    <app-histories-offer [negotiationOfferShipOwner]="offerBinding" *ngIf="offerBinding"
                      fieldName="loadingPort"></app-histories-offer>
                  </ng-container>
                </td>
              </tr>
              <!-- END TR LOADINGPORTS -->
              <!-- TR LOADINGPORTS CONDITIONS -->
              <tr class="tr5offerBinding"
                [ngClass]="(!isClhRevision) ? 'state' + offerBindingInitial.loadingPortConditionsState + ' ' +
                                (offerBinding.loadingPortConditionsHistories?.length ? 'input-orange' : 'input-nochanges') : '' ">
                <th scope="row">5</th>
                <!-- DELETE CHECK -->
                <!-- <td><i *ngIf="confirmedStatus.includes(offerBinding.loadingPortConditionsState) && isNegotiation" class="pi pi-check-circle"></i></td> -->
                <td class="labelOffer">{{'RESPONSE_SHIP_OFFER.TABLE.ROW.ROW_LOADING_CONDITIONS' | translate}}</td>
                <td *ngIf="isFirstStepCharterer || isClhRevision">
                  <label> </label>
                </td>
                <td>
                  <ng-container *ngIf="isRecap else containerGeneralLoadingPortConditions">
                    <div class="d-flex justify-content-start" *ngIf="offerBinding">
                      <label *ngIf="!offerBinding.loadingPortConditions"> </label>
                      <label *ngIf="offerBinding.loadingPortConditions"> {{offerBinding.loadingPortConditions}}</label>
                    </div>
                  </ng-container>
                  <ng-template #containerGeneralLoadingPortConditions>
                    <div [ngClass]="withoutBorderInput ? 'withoutBorder' : ''">
                      <div class="field d-flex">
                        <clh-textarea [form]="form" [nameControl]="'loadingPortConditions'" [style]="'width:100%;'"
                          [errorLabel]="''"
                          [placeholder]="'RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_LOADING_CONDITIONS'"
                          [label]="''" [idTextarea]="'textAreaLPCBinding'"
                          [classTextarea]="'w-100 textAreaLoadingConditions clhTextarea'" [required]="false"
                          [readOnly]="offerBinding.loadingPortConditionsShowLabel"
                          (textAreaClick)="changeClassOnDivButtonsOffer('loadingPortConditions')"
                          [showLabelFloat]="false"></clh-textarea>
                        <ng-container *ngIf="!withoutBorderInput || isClh">
                          <div class="div-button-collapse d-flex"
                            *ngIf="offerBinding.loadingPortConditionsHistories?.length">
                            <button class="btn btn-link btn-collapse" type="button" data-toggle="collapse"
                              data-target="#collapseHistoryloadingPortConditions" aria-expanded="false"
                              aria-controls="collapseHistoryloadingPortConditions"
                              (click)="addClassTrHistory($event,'loadingPortConditions')">
                              <span class="p-button-icon pi pi-chevron-down" [pTooltip]="'ACTION.EXPAND'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                              <span class="p-button-icon pi pi-chevron-up" [pTooltip]="'ACTION.COLLAPSE'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                            </button>
                          </div>
                        </ng-container>
                        <div *ngIf="isNegotiation && !offerFirmBindingIsReadOnly && !isViewNegotationAfterRecap"
                          class="buttonsOffer loadingPortConditions">
                          <app-buttons-offer [field]="'loadingPortConditions'" (approveField)="onApproveField($event)"
                            [disabled]="!form.get('loadingPortConditions').valid" (undoField)="onUndoField($event)"
                            (undoApprove)="onUndoApprove($event)" (onReopen)="reopenField($event)"
                            (onSave)="saveField($event)" (onEdit)="setEditable('loadingPortConditions', $event)"
                            [value]="form.get('loadingPortConditions')?.value"
                            [isApproved]="confirmedStatus.includes(offerBinding.loadingPortConditionsState)"></app-buttons-offer>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </td>
              </tr>
              <tr class="trHistoryloadingPortConditions hiddenHistory">
                <td [attr.colspan]="(isFirstStepCharterer || isClhRevision) ? '4' : '3'">
                  <ng-container *ngIf="!withoutBorderInput || isClh">
                    <app-histories-offer fieldName="loadingPortConditions" *ngIf="offerBinding"
                      [negotiationOfferShipOwner]="offerBinding"></app-histories-offer>
                  </ng-container>
                </td>
              </tr>
              <!-- END TR LOADINGPORTS CONDITIONS -->
              <!-- TR DISCHARGINGPORTS -->
              <tr class="tr6offerBinding"
                [ngClass]="(!isClhRevision) ? 'state' + offerBindingInitial.dischargingPortsState + ' ' +
                                (offerBinding.dischargingPortHistories?.length ? 'input-orange' : 'input-nochanges') : ''">
                <th scope="row">6</th>
                <!-- DELETE CHECK -->
                <!-- <td><i *ngIf="confirmedStatus.includes(offerBinding.dischargingPortsState) && isNegotiation" class="pi pi-check-circle"></i></td> -->
                <td class="labelOffer">{{'STEP1_OFFER.TABLE.ROW.ROW_DISCHARGING' | translate}}</td>
                <td *ngIf="isFirstStepCharterer || isClhRevision">
                  <label
                    *ngIf="offerBinding?.initialOffer?.dischargingPorts">{{offerBinding.initialOffer.dischargingPorts.join(',')}}</label>
                </td>
                <td>
                  <ng-container *ngIf="isRecap else containerGeneralDischargingPorts">
                    <div class="d-flex justify-content-start" *ngIf="offerBinding">
                      <label *ngIf="offerBinding.dischargingPorts"> {{offerBinding.dischargingPorts.join(',')}}</label>
                    </div>
                  </ng-container>
                  <ng-template #containerGeneralDischargingPorts>
                    <div [ngClass]="withoutBorderInput ? 'withoutBorder' : ''">
                      <div class="d-flex justify-content-start align-items-center"
                        [ngClass]="offerBinding.dischargingPortsShowLabel ? 'checkConfirm' : ''">
                        <overa-autocomplete [formGroup]="form" [controlName]="'dischargingPorts'" [options]="portsD"
                          [selectedItems]="offerBinding.dischargingPorts" [filterStartIndex]="3" [multiple]="true"
                          [validators]="[arrValidator]" [required]="true"
                          [readonly]="offerBinding.dischargingPortsShowLabel"
                          [isDisabled]="offerBinding.dischargingPortsShowLabel"
                          (click)="changeClassOnDivButtonsOffer('dischargingPorts')"></overa-autocomplete>
                        <!-- <overa-autocomplete
                          [form]="form"
                          *ngIf="portsD"
                          [name]="'dischargingPorts'"
                          [id]="'pInputText4'"
                          style="width: 100%"
                          [required]="true"
                          class="overa-autocomplete"
                          [items]="portsD"
                          [selectedItems]="offerBinding.dischargingPorts"
                          [readonly]="offerBinding.dischargingPortsShowLabel"
                          [editable]="true"
                          (onSelectionChange)="selectedDischargingPortChange($event)"
                          [multiple]="true"
                          [filterStartIndex]="3"
                          [validators]="[arrValidator]"
                          (click)="changeClassOnDivButtonsOffer('dischargingPorts')"
                        >
                        </overa-autocomplete> -->
                        <ng-container *ngIf="!withoutBorderInput || isClh">
                          <div class="div-button-collapse d-flex" *ngIf="offerBinding.dischargingPortHistories?.length">
                            <button class="btn btn-link btn-collapse" type="button" data-toggle="collapse"
                              data-target="#collapseHistorydischargingPort" aria-expanded="false"
                              aria-controls="collapseHistorydischargingPort"
                              (click)="addClassTrHistory($event,'dischargingPorts')">
                              <span class="p-button-icon pi pi-chevron-down" [pTooltip]="'ACTION.EXPAND'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                              <span class="p-button-icon pi pi-chevron-up" [pTooltip]="'ACTION.COLLAPSE'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                            </button>
                          </div>
                        </ng-container>
                        <div *ngIf="isNegotiation && !offerFirmBindingIsReadOnly && !isViewNegotationAfterRecap"
                          class="buttonsOffer dischargingPorts">
                          <app-buttons-offer [field]="'dischargingPorts'" (approveField)="onApproveField($event)"
                            [disabled]="!form.get('dischargingPorts').valid" (undoField)="onUndoField($event)"
                            (undoApprove)="onUndoApprove($event)" (onReopen)="reopenField($event)"
                            (onSave)="saveField($event)" (onEdit)="setEditable('dischargingPorts', $event)"
                            [value]="form.get('dischargingPorts')?.value"
                            [isApproved]="confirmedStatus.includes(offerBinding.dischargingPortsState)"></app-buttons-offer>
                        </div>
                      </div>
                      <div class="d-flex flex-column justify-content-start" *ngIf="!isRecap">
                        <div
                          *ngIf="form.get('dischargingPorts').touched  &&  form.get('dischargingPorts').hasError('required')"
                          class="error-label labelError">
                          {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                        </div>
                        <div
                          *ngIf="((form.get('dischargingPorts').touched  || form.get('dischargingPorts').dirty))  && form.get('dischargingPorts').hasError('notInList')"
                          class="error-label labelError">
                          {{'MESSAGES.VALIDATION.PORTS_INVALID' | translate}}
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </td>
              </tr>
              <tr class="trHistorydischargingPorts hiddenHistory">
                <td [attr.colspan]="(isFirstStepCharterer || isClhRevision) ? '4' : '3'">
                  <ng-container *ngIf="!withoutBorderInput || isClh">
                    <app-histories-offer [negotiationOfferShipOwner]="offerBinding" *ngIf="offerBinding"
                      fieldName="dischargingPort"></app-histories-offer>
                  </ng-container>
                </td>
              </tr>
              <!-- END TR DISCHARGINGPORTS -->
              <!-- TR DISCHARGINGPORTS CONDITIONS -->
              <tr class="tr7offerBinding"
                [ngClass]="(!isClhRevision) ? 'state' + offerBindingInitial.dischargingPortConditionsState + ' ' +
                                (offerBinding.dischargingPortConditionsHistories?.length ? 'input-orange' : 'input-nochanges') : ''">
                <th scope="row">7</th>
                <!-- DELETE CHECK -->
                <!-- <td><i *ngIf="confirmedStatus.includes(offerBinding.dischargingPortConditionsState) && isNegotiation" class="pi pi-check-circle"></i></td> -->
                <td class="labelOffer">{{'RESPONSE_SHIP_OFFER.TABLE.ROW.ROW_DISCHARGING_CONDITIONS' | translate}}</td>
                <td *ngIf="isFirstStepCharterer || isClhRevision"><label></label></td>
                <td>
                  <ng-container *ngIf="isRecap else containerGeneralDischargingPortConditions">
                    <div class="d-flex justify-content-start" *ngIf="offerBinding">
                      <label *ngIf="!offerBinding.dischargingPortConditions"> </label>
                      <label *ngIf="offerBinding.dischargingPortConditions">{{offerBinding.dischargingPortConditions}}
                      </label>
                    </div>
                  </ng-container>
                  <ng-template #containerGeneralDischargingPortConditions>
                    <div [ngClass]="withoutBorderInput ? 'withoutBorder' : ''">
                      <div class="field d-flex">
                        <clh-textarea [form]="form" [nameControl]="'dischargingPortConditions'" [style]="'width:100%;'"
                          [errorLabel]="''"
                          [placeholder]="'RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_DISCHARGING_CONDITIONS'"
                          [label]="''" [idTextarea]="'textAreaDPCBinding'"
                          [classTextarea]="'w-100 textAreaDischargingConditions clhTextarea'" [required]="false"
                          [readOnly]="offerBinding.dischargingPortConditionsShowLabel"
                          (textAreaClick)="changeClassOnDivButtonsOffer('dischargingPortConditions')"
                          [showLabelFloat]="false"></clh-textarea>
                        <ng-container *ngIf="!withoutBorderInput || isClh">
                          <div class="div-button-collapse d-flex"
                            *ngIf="offerBinding.dischargingPortConditionsHistories?.length">
                            <button class="btn btn-link btn-collapse" type="button" data-toggle="collapse"
                              data-target="#collapseHistorydischargingPortConditions" aria-expanded="false"
                              aria-controls="collapseHistorydischargingPortConditions"
                              (click)="addClassTrHistory($event,'dischargingPortConditions')">
                              <span class="p-button-icon pi pi-chevron-down" [pTooltip]="'ACTION.EXPAND'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                              <span class="p-button-icon pi pi-chevron-up" [pTooltip]="'ACTION.COLLAPSE'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                            </button>
                          </div>
                        </ng-container>
                        <div *ngIf="isNegotiation && !offerFirmBindingIsReadOnly && !isViewNegotationAfterRecap"
                          class="buttonsOffer dischargingPortConditions">
                          <app-buttons-offer [negotiationOfferBinding]="offerBinding"
                            [field]="'dischargingPortConditions'" [value]="form.get('dischargingPortConditions')?.value"
                            (approveField)="onApproveField($event)" (onReopen)="reopenField($event)"
                            [disabled]="!form.get('dischargingPortConditions').valid" (undoField)="onUndoField($event)"
                            (undoApprove)="onUndoApprove($event)"
                            (onEdit)="setEditable('dischargingPortConditions', $event)" (onSave)="saveField($event)"
                            [isApproved]="confirmedStatus.includes(offerBinding.dischargingPortConditionsState)"></app-buttons-offer>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </td>
              </tr>
              <tr class="trHistorydischargingPortConditions hiddenHistory">
                <td [attr.colspan]="(isFirstStepCharterer || isClhRevision) ? '4' : '3'">
                  <ng-container *ngIf="!withoutBorderInput || isClh">
                    <app-histories-offer fieldName="dischargingPortConditions" *ngIf="offerBinding" s
                      [negotiationOfferShipOwner]="offerBinding"></app-histories-offer>
                  </ng-container>
                </td>
              </tr>
              <!-- END TR DISCHARGINGPORTS CONDITIONS -->
              <!-- TR LAYCAN -->
              <tr class="tr8offerBinding" [ngClass]="(!isClhRevision) ? 'state' + offerBindingInitial.layCanState + ' ' +
                                (offerBinding.layCanHistories?.length ? 'input-orange' : 'input-nochanges') : ''">
                <th scope="row">8</th>
                <!-- DELETE CHECK -->
                <!-- <td><i *ngIf="confirmedStatus.includes(offerBinding.layCanState) && isNegotiation" class="pi pi-check-circle"></i></td> -->
                <td class="labelOffer">{{'STEP1_OFFER.TABLE.ROW.ROW_LAYCAN' | translate}}</td>
                <td *ngIf="isFirstStepCharterer || isClhRevision">
                  <label
                    *ngIf="offerBinding?.initialOffer?.layCanFrom && offerBinding?.initialOffer?.layCanTo">{{offerBinding.initialOffer.layCanFrom
                    | date: 'dd/MM/yy'}} - {{offerBinding.initialOffer.layCanTo | date: 'dd/MM/yy'}}</label>
                </td>
                <td>
                  <ng-container *ngIf="isRecap else containerGeneralLaycan">
                    <div class="d-flex justify-content-start" *ngIf="offerBinding">
                      <label *ngIf="offerBinding.layCanFrom">
                        {{offerBinding.layCanFrom | date: 'dd/MM/yy'}} - {{offerBinding.layCanTo | date: 'dd/MM/yy'}}
                      </label>
                    </div>
                  </ng-container>
                  <ng-template #containerGeneralLaycan>
                    <div [ngClass]="withoutBorderInput ? 'withoutBorderAll field d-flex' : 'field d-flex'">
                      <div class="w-100">
                        <p-calendar dateFormat="dd/mm/yy" formControlName="rangeDates" [utc]="true"
                          selectionMode="range" inputId="range" styleClass="calendarBinding"
                          [disabled]="offerBinding.layCanShowLabel" (click)=" changeClassOnDivButtonsOffer('layCan')">
                        </p-calendar>
                      </div>
                      <ng-container *ngIf="!withoutBorderInput || isClh">
                        <div class="div-button-collapse d-flex" *ngIf="offerBinding.layCanHistories?.length">
                          <button class="btn btn-link btn-collapse" type="button" data-toggle="collapse"
                            data-target="#collapseHistorylayCan" aria-expanded="false"
                            aria-controls="collapseHistorylayCan" (click)="addClassTrHistory($event,'layCan')">
                            <span class="p-button-icon pi pi-chevron-down" [pTooltip]="'ACTION.EXPAND'| translate"
                              tooltipPosition="bottom" tooltipStyleClass="tooltipCLH"
                              [pTooltip]="'ACTION.EXPAND'| translate" tooltipPosition="bottom"
                              tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                            <span class="p-button-icon pi pi-chevron-up" [pTooltip]="'ACTION.COLLAPSE'| translate"
                              tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                          </button>
                        </div>
                      </ng-container>
                      <div *ngIf="isNegotiation && !offerFirmBindingIsReadOnly && !isViewNegotationAfterRecap"
                        class="buttonsOffer layCan">
                        <app-buttons-offer [field]="'layCan'" (approveField)="onApproveField($event)"
                          [disabled]="!form.get('rangeDates').valid" (undoField)="onUndoField($event)"
                          (undoApprove)="onUndoApprove($event)" (onReopen)="reopenField($event)"
                          (onSave)="saveField($event)" (onEdit)="setEditable('layCan', $event)"
                          [value]="form.get('rangeDates')?.value"
                          [isApproved]="confirmedStatus.includes(offerBinding.layCanState)"></app-buttons-offer>
                      </div>
                    </div>
                    <div class="d-flex flex-column justify-content-start" *ngIf="!isRecap">
                      <div *ngIf="form.get('rangeDates').touched  && form.get('rangeDates').hasError('required')"
                        class="error-label labelError">
                        {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                      </div>
                      <div *ngIf="form.get('rangeDates').hasError('errorDates') " class="error-label labelError">
                        {{'MESSAGES.VALIDATION.RANGE_DATES' | translate}}
                      </div>
                    </div>
                  </ng-template>
                </td>
              </tr>
              <tr class="trHistorylayCan hiddenHistory">
                <td [attr.colspan]="(isFirstStepCharterer || isClhRevision) ? '4' : '3'">
                  <ng-container *ngIf="!withoutBorderInput || isClh">
                    <app-histories-offer [negotiationOfferShipOwner]="offerBinding" *ngIf="offerBinding"
                      fieldName="layCan"></app-histories-offer>
                  </ng-container>
                </td>
              </tr>
              <!-- END TR LAYCAN -->
              <!-- TR CHARGERINGTERMS -->
              <tr class="tr9offerBinding"
                [ngClass]="(!isClhRevision) ? 'state' + offerBindingInitial.chargeringTermState + ' ' +
                                (offerBinding.chargeringTermHistories?.length ? 'input-orange' : 'input-nochanges') :''">
                <th scope="row">9</th>
                <!-- DELETE CHECK -->
                <!-- <td><i *ngIf="confirmedStatus.includes(offerBinding.chargeringTermState) && isNegotiation" class="pi pi-check-circle"></i></td> -->
                <td class="labelOffer">{{'BINDING_OFFER.ROW.ROW_CHARTERING' | translate}}</td>
                <td *ngIf="isFirstStepCharterer || isClhRevision">
                  <label
                    *ngIf="offerBinding?.initialOffer?.chargeringTerm">{{offerBinding.initialOffer.chargeringTerm}}</label>
                </td>
                <td>
                  <ng-container *ngIf="isRecap else containerGeneralChargeringTerm">
                    <div class="d-flex justify-content-start" *ngIf="offerBinding">
                      <label *ngIf="offerBinding.chargeringTerm"> {{offerBinding.chargeringTerm}}</label>
                    </div>
                  </ng-container>
                  <ng-template #containerGeneralChargeringTerm>
                    <div [ngClass]="withoutBorderInput ? 'withoutBorder' : ''">
                      <div class="field d-flex">
                        <!-- <span class="p-float-label w-100"> -->
                        <input class="w-100 inputChartering" pInputText type="text" style="width: 100%"
                          formControlName="chargeringTerm" id="inputCharteringBinding" size="60"
                          placeholder="RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_CHARTERING"
                          [readonly]="offerBinding.chargeringTermShowLabel"
                          (click)="changeClassOnDivButtonsOffer('chargeringTerm')" />
                        <!-- <label for="inputCharteringBinding" class="labelColor"
                                                    >{{'RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_CHARTERING' | translate}}</label
                                                  >
                                                </span> -->
                        <ng-container *ngIf="!withoutBorderInput || isClh">
                          <div class="div-button-collapse d-flex" *ngIf="offerBinding.chargeringTermHistories?.length">
                            <button class="btn btn-link btn-collapse" type="button" data-toggle="collapse"
                              data-target="#collapseHistorychargeringTerm" aria-expanded="false"
                              aria-controls="collapseHistorychargeringTerm"
                              (click)="addClassTrHistory($event,'chargeringTerm')">
                              <span class="p-button-icon pi pi-chevron-down" [pTooltip]="'ACTION.EXPAND'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH"
                                [pTooltip]="'ACTION.EXPAND'| translate" tooltipPosition="bottom"
                                tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                              <span class="p-button-icon pi pi-chevron-up" [pTooltip]="'ACTION.COLLAPSE'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                            </button>
                          </div>
                        </ng-container>
                        <div *ngIf="isNegotiation && !offerFirmBindingIsReadOnly && !isViewNegotationAfterRecap"
                          class="buttonsOffer chargeringTerm">
                          <app-buttons-offer [field]="'chargeringTerm'" (approveField)="onApproveField($event)"
                            [disabled]="!form.get('chargeringTerm').valid" (undoField)="onUndoField($event)"
                            (undoApprove)="onUndoApprove($event)" (onReopen)="reopenField($event)"
                            (onSave)="saveField($event)" (onEdit)="setEditable('chargeringTerm', $event)"
                            [value]="form.get('chargeringTerm')?.value"
                            [isApproved]="confirmedStatus.includes(offerBinding.chargeringTermState)"></app-buttons-offer>
                        </div>
                      </div>
                      <div class="d-flex flex-column justify-content-start" *ngIf="!isRecap">
                        <div
                          *ngIf="form.get('chargeringTerm').touched  && !form.get('chargeringTerm').value  && form.get('chargeringTerm').hasError('required')"
                          class="error-label labelError">
                          {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </td>
              </tr>
              <tr class="trHistorychargeringTerm hiddenHistory">
                <td [attr.colspan]="(isFirstStepCharterer || isClhRevision) ? '4' : '3'">
                  <ng-container *ngIf="!withoutBorderInput || isClh">
                    <!-- <div class="d-flex justify-content-start align-items-center" *ngIf="offerDto">
                                                                                            <label *ngIf="offerDto.chargeringTerm">
                                                                                                {{offerDto.chargeringTerm}}</label>
                                                                                        </div> -->
                    <app-histories-offer [negotiationOfferShipOwner]="offerBinding"
                      fieldName="chargeringTerm"></app-histories-offer>
                  </ng-container>
                </td>
              </tr>
              <!-- END TR CHARGERINGTERMS -->
              <!-- TR LAYTIMETERM -->
              <tr class="tr10offerBinding" [ngClass]="(!isClhRevision) ? 'state' + offerBindingInitial.layTimeTermState + ' ' +
                                (offerBinding.layTimeTermHistories?.length ? 'input-orange' : 'input-nochanges') : ''">
                <th scope="row">10</th>
                <!-- DELETE CHECK -->
                <!-- <td><i *ngIf="confirmedStatus.includes(offerBinding.layTimeTermState) && isNegotiation" class="pi pi-check-circle"></i></td> -->
                <td class="labelOffer">{{'BINDING_OFFER.ROW.ROW_LAYTIME' | translate}}</td>
                <td *ngIf="isFirstStepCharterer || isClhRevision">
                  <label
                    *ngIf="offerBinding?.initialOffer?.layTimeTerm">{{offerBinding.initialOffer.layTimeTerm}}</label>
                </td>
                <td>
                  <ng-container *ngIf="isRecap else containerGeneralLaytimeterm">
                    <div class="d-flex justify-content-start" *ngIf="offerBinding">
                      <label *ngIf="offerBinding.layTimeTerm"> {{offerBinding.layTimeTerm}}</label>
                    </div>
                  </ng-container>
                  <ng-template #containerGeneralLaytimeterm>
                    <div [ngClass]="withoutBorderInput ? 'withoutBorder' : ''">
                      <div class="field d-flex">
                        <!-- <span class="p-float-label w-100"> -->
                        <input class="w-100 inputLayterms" pInputText type="text" formControlName="layTimeTerm"
                          id="inputLaytermsBinding" size="60"
                          placeholder="RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_LAYTERMS"
                          [readonly]="offerBinding.layTimeTermShowLabel"
                          (click)="changeClassOnDivButtonsOffer('layTimeTerm')" />
                        <!-- <label for="inputLaytermsBinding" class="labelColor"
                                                    >{{'RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_LAYTERMS' | translate}}</label
                                                  >
                                                </span> -->
                        <ng-container *ngIf="!withoutBorderInput || isClh">
                          <div class="div-button-collapse d-flex" *ngIf="offerBinding.layTimeTermHistories?.length">
                            <button class="btn btn-link btn-collapse" type="button" data-toggle="collapse"
                              data-target="#collapseHistorylayTimeTerm" aria-expanded="false"
                              aria-controls="collapseHistorylayTimeTerm"
                              (click)="addClassTrHistory($event,'layTimeTerm')">
                              <span class="p-button-icon pi pi-chevron-down" [pTooltip]="'ACTION.EXPAND'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH"
                                [pTooltip]="'ACTION.EXPAND'| translate" tooltipPosition="bottom"
                                tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                              <span class="p-button-icon pi pi-chevron-up" [pTooltip]="'ACTION.COLLAPSE'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                            </button>
                          </div>
                        </ng-container>
                        <div *ngIf="isNegotiation && !offerFirmBindingIsReadOnly && !isViewNegotationAfterRecap"
                          class="buttonsOffer layTimeTerm">
                          <app-buttons-offer [field]="'layTimeTerm'" (approveField)="onApproveField($event)"
                            [disabled]="!form.get('layTimeTerm').valid" (undoField)="onUndoField($event)"
                            (undoApprove)="onUndoApprove($event)" (onReopen)="reopenField($event)"
                            (onSave)="saveField($event)" (onEdit)="setEditable('layTimeTerm', $event)"
                            [value]="form.get('layTimeTerm')?.value"
                            [isApproved]="confirmedStatus.includes(offerBinding.layTimeTermState)"></app-buttons-offer>
                        </div>
                      </div>
                      <div class="d-flex flex-column justify-content-start" *ngIf="!isRecap">
                        <div
                          *ngIf="form.get('layTimeTerm').touched  && !form.get('layTimeTerm').value  && form.get('layTimeTerm').hasError('required')"
                          class="d-flex justify-content-start align-items-center error-label labelError">
                          {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </td>
              </tr>
              <tr class="trHistorylayTimeTerm hiddenHistory">
                <td [attr.colspan]="(isFirstStepCharterer || isClhRevision) ? '4' : '3'">
                  <ng-container *ngIf="!withoutBorderInput || isClh">
                    <app-histories-offer *ngIf="offerBinding" [negotiationOfferShipOwner]="offerBinding"
                      fieldName="layTimeTerm">
                    </app-histories-offer>
                  </ng-container>
                </td>
              </tr>
              <!-- END TR LAYTIMETERM -->
              <!-- TR CPFORM -->
              <tr class="tr11offerBinding" [ngClass]="(!isClhRevision) ? 'state' + offerBindingInitial.cpFormState + ' ' +
                                (offerBinding.cpFormHistories?.length ? 'input-orange' : 'input-nochanges') : ''">
                <th scope="row">11</th>
                <!-- DELETE CHECK -->
                <!-- <td><i *ngIf="confirmedStatus.includes(offerBinding.cpFormState) && isNegotiation" class="pi pi-check-circle"></i></td> -->
                <td class="labelOffer">{{'BINDING_OFFER.ROW.ROW_CP' | translate}}</td>
                <td *ngIf="isFirstStepCharterer || isClhRevision">
                  <label *ngIf="offerBinding?.initialOffer?.cpForm">{{offerBinding.initialOffer.cpForm}}</label>
                </td>
                <td>
                  <ng-container *ngIf="isRecap else containerGeneralCpform">
                    <div class="d-flex justify-content-start" *ngIf="offerBinding">
                      <label *ngIf="offerBinding.cpForm"> {{offerBinding.cpForm}}</label>
                    </div>
                  </ng-container>
                  <ng-template #containerGeneralCpform>
                    <div [ngClass]="withoutBorderInput ? 'withoutBorder' : ''">
                      <div class="field d-flex">
                        <!-- <span class="p-float-label w-100"> -->
                        <input class="w-100 inputCp" pInputText type="text" formControlName="cpForm" style="width: 100%"
                          id="inputCpBinding" size="60"
                          placeholder="RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_CP"
                          [readonly]="offerBinding.cpFormShowLabel" (click)="changeClassOnDivButtonsOffer('cpForm')" />
                        <!-- <label for="inputCpBinding" class="labelColor"
                                                    >{{'RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_CP' | translate}}</label
                                                  >
                                                </span> -->
                        <ng-container *ngIf="!withoutBorderInput || isClh">
                          <div class="div-button-collapse d-flex" *ngIf="offerBinding.cpFormHistories?.length">
                            <button class="btn btn-link btn-collapse" type="button" data-toggle="collapse"
                              data-target="#collapseHistorycpForm" aria-expanded="false"
                              aria-controls="collapseHistorycpForm" (click)="addClassTrHistory($event,'cpForm')">
                              <span class="p-button-icon pi pi-chevron-down" [pTooltip]="'ACTION.EXPAND'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH"
                                [pTooltip]="'ACTION.EXPAND'| translate" tooltipPosition="bottom"
                                tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                              <span class="p-button-icon pi pi-chevron-up" [pTooltip]="'ACTION.COLLAPSE'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                            </button>
                          </div>
                        </ng-container>
                        <div *ngIf="isNegotiation && !offerFirmBindingIsReadOnly && !isViewNegotationAfterRecap"
                          class="buttonsOffer cpForm">
                          <app-buttons-offer [field]="'cpForm'" (approveField)="onApproveField($event)"
                            [disabled]="!form.get('cpForm').valid" (undoField)="onUndoField($event)"
                            (undoApprove)="onUndoApprove($event)" (onReopen)="reopenField($event)"
                            (onSave)="saveField($event)" (onEdit)="setEditable('cpForm', $event)"
                            [value]="form.get('cpForm')?.value"
                            [isApproved]="confirmedStatus.includes(offerBinding.cpFormState)"></app-buttons-offer>
                        </div>
                      </div>
                      <div class="d-flex flex-column justify-content-start" *ngIf="!isRecap">
                        <div
                          *ngIf="form.get('cpForm').touched  && !form.get('cpForm').value  && form.get('cpForm').hasError('required')"
                          class="error-label labelError">
                          {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </td>
              </tr>
              <tr class="trHistorycpForm hiddenHistory">
                <td [attr.colspan]="(isFirstStepCharterer || isClhRevision) ? '4' : '3'">
                  <ng-container *ngIf="!withoutBorderInput || isClh">
                    <app-histories-offer fieldName="cpForm"
                      [negotiationOfferShipOwner]="offerBinding"></app-histories-offer>

                    <!-- <div class="d-flex justify-content-start align-items-center" *ngIf="offerDto"> -->
                    <!-- <label *ngIf="offerDto.cpForm"> {{offerDto.cpForm}}</label> -->
                    <!-- </div> -->
                  </ng-container>
                </td>
              </tr>
              <!-- END TR CPFORM -->
              <!-- TR RESTRICTIONS REGARDING -->
              <tr class="tr12offerBinding"
                [ngClass]="(!isClhRevision) ? 'state' + offerBindingInitial.restrictionsRegardingState + ' ' +
                                (offerBinding.restrictionsRegardingHistories?.length ? 'input-orange' : 'input-nochanges') : ''">
                <th scope="row">12</th>
                <!-- DELETE CHECK -->
                <!-- <td><i *ngIf="confirmedStatus.includes(offerBinding.restrictionsRegardingState) && isNegotiation" class="pi pi-check-circle"></i></td> -->
                <td class="labelOffer">{{'BINDING_OFFER.ROW.ROW_RESTRICTIONS' | translate}}</td>
                <td *ngIf="isFirstStepCharterer || isClhRevision">
                  <label
                    *ngIf="offerBinding?.initialOffer?.restrictionsRegarding">{{offerBinding.initialOffer.restrictionsRegarding}}</label>
                </td>
                <td>
                  <ng-container *ngIf="isRecap else containerGeneralRestrictionsregarding">
                    <div class="d-flex justify-content-start" *ngIf="offerBinding">
                      <label *ngIf="!offerBinding.restrictionsRegarding"> </label>
                      <label *ngIf="offerBinding.restrictionsRegarding">{{offerBinding.restrictionsRegarding}} </label>
                    </div>
                  </ng-container>
                  <ng-template #containerGeneralRestrictionsregarding>
                    <div [ngClass]="withoutBorderInput ? 'withoutBorder' : ''">
                      <div class="field d-flex">
                        <clh-textarea [form]="form" [nameControl]="'restrictionsRegarding'" [style]="'width:100%;'"
                          [errorLabel]="''"
                          [placeholder]="'RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_RESTRICTIONS'" [label]="''"
                          [idTextarea]="'textAreaRestrictionsBinding'"
                          [classTextarea]="'w-100 textAreaRestrictions clhTextarea'" [required]="false"
                          [readOnly]="offerBinding.restrictionsRegardingShowLabel"
                          (textAreaClick)="changeClassOnDivButtonsOffer('restrictionsRegarding')"
                          [showLabelFloat]="false"></clh-textarea>
                        <ng-container *ngIf="!withoutBorderInput || isClh">
                          <div class="div-button-collapse d-flex"
                            *ngIf="offerBinding.restrictionsRegardingHistories?.length">
                            <button class="btn btn-link btn-collapse" type="button" data-toggle="collapse"
                              data-target="#collapseHistoryrestrictionsRegarding" aria-expanded="false"
                              aria-controls="collapseHistoryrestrictionsRegarding"
                              (click)="addClassTrHistory($event,'restrictionsRegarding')">
                              <span class="p-button-icon pi pi-chevron-down" [pTooltip]="'ACTION.EXPAND'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                              <span class="p-button-icon pi pi-chevron-up" [pTooltip]="'ACTION.COLLAPSE'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                            </button>
                          </div>
                        </ng-container>
                        <div *ngIf="isNegotiation && !offerFirmBindingIsReadOnly && !isViewNegotationAfterRecap"
                          class="buttonsOffer restrictionsRegarding">
                          <app-buttons-offer [field]="'restrictionsRegarding'" (approveField)="onApproveField($event)"
                            [disabled]="!form.get('restrictionsRegarding').valid" (undoField)="onUndoField($event)"
                            (undoApprove)="onUndoApprove($event)" (onReopen)="reopenField($event)"
                            (onSave)="saveField($event)" (onEdit)="setEditable('restrictionsRegarding', $event)"
                            [value]="form.get('restrictionsRegarding')?.value"
                            [isApproved]="confirmedStatus.includes(offerBinding.restrictionsRegardingState)">
                          </app-buttons-offer>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </td>
              </tr>
              <tr class="trHistoryrestrictionsRegarding hiddenHistory">
                <td [attr.colspan]="(isFirstStepCharterer || isClhRevision) ? '4' : '3'">
                  <ng-container *ngIf="!withoutBorderInput || isClh">
                    <app-histories-offer fieldName="restrictionsRegarding" *ngIf="offerBinding"
                      [negotiationOfferShipOwner]="offerBinding"></app-histories-offer>
                  </ng-container>
                </td>
              </tr>
              <!-- END TR RESTRICTIONS REGARDING -->
              <!-- TR ADITIONAL REQUIREMENTS -->
              <tr class="tr13offerBinding"
                [ngClass]="(!isClhRevision) ? 'state' + offerBindingInitial.aditionalRequirementsState + ' ' +
                                (offerBinding.aditionalRequirementsHistories?.length ? 'input-orange' : 'input-nochanges') : ''">
                <th scope="row">13</th>
                <!-- DELETE CHECK -->
                <!-- <td><i *ngIf="confirmedStatus.includes(offerBinding.aditionalRequirementsState) && isNegotiation" class="pi pi-check-circle"></i></td> -->
                <td class="labelOffer">{{'BINDING_OFFER.ROW.ROW_ADD_REQUIREMENTS' | translate}}</td>
                <td *ngIf="isFirstStepCharterer || isClhRevision">
                  <label
                    *ngIf="offerBinding?.initialOffer?.aditionalRequirements">{{offerBinding.initialOffer.aditionalRequirements}}</label>
                </td>
                <td>
                  <ng-container *ngIf="isRecap else containerGeneralAditionalrequirements">
                    <div class="d-flex justify-content-start" *ngIf="offerBinding">
                      <label *ngIf="!offerBinding.aditionalRequirements"> </label>
                      <label *ngIf="offerBinding.aditionalRequirements">{{offerBinding.aditionalRequirements}} </label>
                    </div>
                  </ng-container>
                  <ng-template #containerGeneralAditionalrequirements>
                    <div [ngClass]="withoutBorderInput ? 'withoutBorder' : ''">
                      <div class="field d-flex">
                        <clh-textarea [form]="form" [nameControl]="'aditionalRequirements'" [style]="'width:100%;'"
                          [errorLabel]="''"
                          [placeholder]="'RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_ADD_REQUIREMENTS'"
                          [label]="''" [idTextarea]="'textAreaRequirementsBinding'"
                          [classTextarea]="'w-100 textAreaRequirements clhTextarea'" [required]="false"
                          [readOnly]="offerBinding.aditionalRequirementsShowLabel"
                          (textAreaClick)="changeClassOnDivButtonsOffer('aditionalRequirements')"
                          [showLabelFloat]="false"></clh-textarea>
                        <ng-container *ngIf="!withoutBorderInput || isClh">
                          <div class="div-button-collapse d-flex"
                            *ngIf="offerBinding.aditionalRequirementsHistories?.length">
                            <button class="btn btn-link btn-collapse" type="button" data-toggle="collapse"
                              data-target="#collapseHistoryaditionalRequirements" aria-expanded="false"
                              aria-controls="collapseHistoryaditionalRequirements"
                              (click)="addClassTrHistory($event,'aditionalRequirements')">
                              <span class="p-button-icon pi pi-chevron-down" [pTooltip]="'ACTION.EXPAND'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                              <span class="p-button-icon pi pi-chevron-up" [pTooltip]="'ACTION.COLLAPSE'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                            </button>
                          </div>
                        </ng-container>
                        <div *ngIf="isNegotiation && !offerFirmBindingIsReadOnly && !isViewNegotationAfterRecap"
                          class="buttonsOffer aditionalRequirements">
                          <app-buttons-offer [field]="'aditionalRequirements'" (approveField)="onApproveField($event)"
                            [disabled]="!form.get('aditionalRequirements').valid" (undoField)="onUndoField($event)"
                            (undoApprove)="onUndoApprove($event)" (onReopen)="reopenField($event)"
                            (onSave)="saveField($event)" (onEdit)="setEditable('aditionalRequirements', $event)"
                            [value]="form.get('aditionalRequirements')?.value"
                            [isApproved]="confirmedStatus.includes(offerBinding.aditionalRequirementsState)"></app-buttons-offer>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </td>
              </tr>
              <tr class="trHistoryaditionalRequirements hiddenHistory">
                <td [attr.colspan]="(isFirstStepCharterer || isClhRevision) ? '4' : '3'">
                  <ng-container *ngIf="!withoutBorderInput || isClh">
                    <app-histories-offer fieldName="aditionalRequirements" *ngIf="offerBinding"
                      [negotiationOfferShipOwner]="offerBinding"></app-histories-offer>
                  </ng-container>
                </td>
              </tr>
              <!-- END TR ADITIONAL REQUIREMENTS -->
              <!-- TR FREIGHTTARGET -->
              <tr class="tr14offerBinding"
                [ngClass]="(!isClhRevision) ? 'state' + offerBindingInitial.freightState + ' ' +
                                (offerBinding.freightTargetHistories?.length ? 'input-orange' : 'input-nochanges') : ''">
                <th scope="row">14</th>
                <!-- DELETE CHECK -->
                <!-- <td><i *ngIf="confirmedStatus.includes(offerBinding.freightState) && isNegotiation" class="pi pi-check-circle"></i></td> -->
                <td class="labelOffer">{{'BINDING_OFFER.ROW.ROW_FLETE' | translate}}</td>
                <td *ngIf="isFirstStepCharterer || isClhRevision">
                  <label *ngIf="offerBinding?.initialOffer?.freight">{{offerBinding.initialOffer.freight}}</label>
                </td>
                <td>
                  <ng-container *ngIf="isRecap else containerGeneralFreight">
                    <div class="d-flex justify-content-start" *ngIf="offerBinding">
                      <label *ngIf="offerBinding.freightTargetQuantity">
                        {{FreightTargetCurrency[offerBinding.freightTargetCurrency]}}
                        {{offerBinding.freightTargetQuantity | number: '1.2-2'}}
                        {{FreightTargetType[offerBinding.freightTargetType]}}
                      </label>
                    </div>
                  </ng-container>
                  <ng-template #containerGeneralFreight>
                    <div [ngClass]="withoutBorderInput ? 'withoutBorder' : ''">
                      <div [ngClass]="withoutBorderInput ? 'withoutBorderAll field d-flex' : 'field d-flex'">
                        <div class="d-flex flex-wrap justify-content-start align-content-center divFlete">
                          <p-dropdown class="dropdownCoinBinding" [options]="coins"
                            [readonly]="offerBinding.freightShowLabel" formControlName="freightTargetCurrency"
                            (ngModelChange)="changeFreightTargetCurrency($event)"></p-dropdown>
                          <div class="field d-flex flex-column divFreightTargetCurrency">
                            <p-inputNumber inputId="freightTarget" inputStyleClass="freightTargetOfferBinding"
                              styleclass="inputFreightTargetQuantityBinding" formControlName="freightTargetQuantity"
                              mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5"
                              [readonly]="offerBinding.freightShowLabel" locale="de-DE"
                              (click)="changeClassOnDivButtonsOffer('freight')">
                            </p-inputNumber>
                            <div
                              *ngIf="!isRecap && form.get('freightTargetQuantity').touched  && !form.get('freightTargetQuantity').value  && form.get('freightTargetQuantity').hasError('required')"
                              class="error-label labelError">
                              {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                            </div>
                          </div>
                          <p-dropdown class="dropdownFleteBinding" [options]="fletes"
                            [readonly]="offerBinding.freightShowLabel" formControlName="freightTargetType">
                          </p-dropdown>
                        </div>
                        <div class="div-button-collapse d-flex"
                          *ngIf="offerBinding.freightTargetHistories?.length &&( !withoutBorderInput || isClh)">
                          <button class="btn btn-link btn-collapse" type="button" data-toggle="collapse"
                            data-target="#collapseHistoryfreightTarget" aria-expanded="false"
                            aria-controls="collapseHistoryfreightTarget" (click)="addClassTrHistory($event,'freight')">
                            <span class="p-button-icon pi pi-chevron-down" [pTooltip]="'ACTION.EXPAND'| translate"
                              tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                            <span class="p-button-icon pi pi-chevron-up" [pTooltip]="'ACTION.COLLAPSE'| translate"
                              tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                          </button>
                        </div>
                        <div class="d-flex justify-content-center align-items-center buttonsOffer freight"
                          *ngIf="isNegotiation && !offerFirmBindingIsReadOnly && !isViewNegotationAfterRecap">
                          <app-buttons-offer [field]="'freight'" (approveField)="onApproveField($event)"
                            (undoField)="onUndoField($event)" (undoApprove)="onUndoApprove($event)"
                            (onReopen)="reopenField($event)" (onSave)="saveField($event)"
                            (onEdit)="setEditable('freight', $event)"
                            [value]="form.get('freightTargetCurrency')?.value +' ' + form.get('freightTargetQuantity')?.value + ' ' + form.get('freightTargetType')?.value"
                            [isApproved]="confirmedStatus.includes(offerBinding.freightState)"
                            [disabled]="!form.get('freightTargetQuantity').valid"></app-buttons-offer>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </td>
              </tr>
              <tr class="trHistoryfreight hiddenHistory">
                <td [attr.colspan]="(isFirstStepCharterer || isClhRevision) ? '4' : '3'">
                  <ng-container *ngIf="offerBinding && (!withoutBorderInput || isClh)">
                    <app-histories-offer fieldName="freightTarget" *ngIf="offerBinding"
                      [negotiationOfferShipOwner]="offerBinding"></app-histories-offer>
                  </ng-container>
                </td>
              </tr>
              <!-- END TR FREIGHTTARGET -->
              <!-- TR DEMURRAGE -->
              <tr class="tr15offerBinding" [ngClass]="(!isClhRevision) ? 'state' + offerBindingInitial.demurrageState + ' ' +
                                (offerBinding.demurrageHistories?.length ? 'input-orange' : 'input-nochanges') : ''">
                <th scope="row">15</th>
                <!-- DELETE CHECK -->
                <!-- <td><i *ngIf="confirmedStatus.includes(offerBinding.demurrageState) && isNegotiation" class="pi pi-check-circle"></i></td> -->
                <td class="labelOffer">{{'COMPARATIVE_OFFER.ROW.ROW_DEMURRAGE' | translate}}</td>
                <td *ngIf="isFirstStepCharterer || isClhRevision"><label></label></td>
                <td>
                  <ng-container *ngIf="isRecap else containerGeneralDemurrage">
                    <div class="d-flex justify-content-start" *ngIf="offerBinding">
                      <label *ngIf="offerBinding.demurrageQuantity">
                        {{FreightTargetCurrency[offerBinding.demurrageCurrency]}} {{offerBinding.demurrageQuantity |
                        number: '1.2-2'}}
                        {{DemurrageType[offerBinding.demurrageType]}}
                      </label>
                    </div>
                  </ng-container>
                  <ng-template #containerGeneralDemurrage>
                    <div [ngClass]="withoutBorderInput ? 'withoutBorder' : ''">
                      <div [ngClass]="withoutBorderInput ? 'withoutBorderAll field d-flex' : 'field d-flex'">
                        <div class="d-flex flex-wrap justify-content-start align-content-center divDemurrage">
                          <div class="field d-flex flex-column">
                            <p-dropdown class="dropdownCoin" [options]="coins"
                              [readonly]="isFirstStepCharterer ? true : offerBinding.demurrageShowLabel"
                              formControlName="demurrageCurrency"></p-dropdown>
                            <div *ngIf="form.errors?.['isMatch']" class="error-label labelError w-100">
                              {{'MESSAGES.VALIDATION.MATCH_DEMURRAGE' | translate}}
                            </div>
                            <div *ngIf="form.errors?.['isMatch']" class="error-label labelError w-100">
                              {{'MESSAGES.VALIDATION.MATCH_DEMURRAGE_COMMENT' | translate}}
                            </div>
                          </div>
                          <div class="field d-flex flex-column divDemurrageCurrency">
                            <p-inputNumber inputId="demurrageQuantityBinding"
                              [readonly]="offerBinding.demurrageShowLabel"
                              styleclass="w-100 inputdemurrageQuantityBinding" formControlName="demurrageQuantity"
                              mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5" locale="de-DE"
                              inputStyleClass="demurrageQuantityOfferBinding"
                              (click)="changeClassOnDivButtonsOffer('demurrage')">
                            </p-inputNumber>
                            <div
                              *ngIf="!isRecap && form.get('demurrageQuantity').touched  && !form.get('demurrageQuantity').value  && form.get('demurrageQuantity').hasError('required')"
                              class="error-label labelError">
                              {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                            </div>
                          </div>
                          <p-dropdown class="dropdownFlete" [options]="demurrageTypes"
                            [readonly]="offerBinding.demurrageShowLabel" formControlName="demurrageType">
                          </p-dropdown>
                        </div>
                        <div class="div-button-collapse d-flex"
                          *ngIf="offerBinding.demurrageHistories?.length &&( !withoutBorderInput || isClh)">
                          <button class="btn btn-link btn-collapse" type="button" data-toggle="collapse"
                            data-target="#collapseHistorydemurrage" aria-expanded="false"
                            aria-controls="collapseHistorydemurrage" (click)="addClassTrHistory($event,'demurrage')">
                            <span class="p-button-icon pi pi-chevron-down" [pTooltip]="'ACTION.EXPAND'| translate"
                              tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                            <span class="p-button-icon pi pi-chevron-up" [pTooltip]="'ACTION.COLLAPSE'| translate"
                              tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                          </button>
                        </div>
                        <div class="d-flex justify-content-center align-items-center buttonsOffer demurrage"
                          *ngIf="isNegotiation && !offerFirmBindingIsReadOnly && !isViewNegotationAfterRecap">
                          <app-buttons-offer #buttonsDemurrage [field]="'demurrage'"
                            (approveField)="onApproveField($event)" (undoField)="onUndoField($event)"
                            (undoApprove)="onUndoApprove($event)" (onReopen)="reopenField($event)"
                            (onSave)="saveField($event)" (onEdit)="setEditable('demurrage', $event)"
                            [value]="form.get('demurrageQuantity')?.value +' ' + form.get('demurrageCurrency')?.value + ' ' + form.get('demurrageType')?.value"
                            [isApproved]="confirmedStatus.includes(offerBinding.demurrageState)"
                            [disabled]="!form.get('demurrageQuantity').valid || form.errors?.['isMatch']"></app-buttons-offer>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </td>
              </tr>
              <tr class="trHistorydemurrage hiddenHistory">
                <td [attr.colspan]="(isFirstStepCharterer || isClhRevision) ? '4' : '3'">
                  <ng-container *ngIf="offerBinding && !withoutBorderInput || isClh">
                    <app-histories-offer fieldName="demurrage" *ngIf="offerBinding"
                      [negotiationOfferShipOwner]="offerBinding"></app-histories-offer>

                    <!-- <label> {{offerBinding.demurrageQuantity }}
                                                                                            {{FreightTargetCurrency[offerBinding.demurrageCurrency] }}
                                                                                            {{FreightTargetType[offerBinding.demurrageType] }} </label> -->
                  </ng-container>
                </td>
              </tr>
              <!-- END TR DEMURRAGE -->
              <!-- TR DEMURRAGE CONDITIONS -->
              <tr class="tr16offerBinding"
                [ngClass]="(!isClhRevision) ? 'state' + offerBindingInitial.demurrageConditionsState + ' ' +
                                (offerBinding.demurrageConditionsHistories?.length ? 'input-orange' : 'input-nochanges') : ''">
                <th scope="row">16</th>
                <!-- DELETE CHECK -->
                <!-- <td><i *ngIf="confirmedStatus.includes(offerBinding.demurrageConditionsState) && isNegotiation" class="pi pi-check-circle"></i></td> -->
                <td class="labelOffer">{{'RESPONSE_SHIP_OFFER.TABLE.ROW.ROW_DEMURRAGE_CONDITIONS' | translate}}</td>
                <td *ngIf="isFirstStepCharterer || isClhRevision"><label></label></td>
                <td>
                  <ng-container *ngIf="isRecap else containerGeneralDemurrageConditions">
                    <div class="d-flex justify-content-start" *ngIf="offerBinding">
                      <label *ngIf="!offerBinding.demurrageConditions"> </label>
                      <label *ngIf="offerBinding.demurrageConditions">{{offerBinding.demurrageConditions}} </label>
                    </div>
                  </ng-container>
                  <ng-template #containerGeneralDemurrageConditions>
                    <div [ngClass]="withoutBorderInput ? 'withoutBorder' : ''">
                      <div class="field d-flex">
                        <clh-textarea [form]="form" [nameControl]="'demurrageConditions'" [style]="'width:100%;'"
                          [errorLabel]="''"
                          [placeholder]="'RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_DEMURRAGE_CONDITIONS'"
                          [label]="''" [idTextarea]="'textAreademurrageConditionsBinding'"
                          [classTextarea]="'w-100 textAreademurrageConditions clhTextarea'" [required]="false"
                          [readOnly]="offerBinding.demurrageConditionsShowLabel"
                          (textAreaClick)="changeClassOnDivButtonsOffer('demurrageConditions')"
                          [showLabelFloat]="false"></clh-textarea>
                        <ng-container *ngIf="!withoutBorderInput || isClh">
                          <div class="div-button-collapse d-flex"
                            *ngIf="offerBinding.demurrageConditionsHistories?.length">
                            <button class="btn btn-link btn-collapse" type="button" data-toggle="collapse"
                              data-target="#collapseHistorydemurrageConditions" aria-expanded="false"
                              aria-controls="collapseHistorydemurrageConditions"
                              (click)="addClassTrHistory($event,'demurrageConditions')">
                              <span class="p-button-icon pi pi-chevron-down" [pTooltip]="'ACTION.EXPAND'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                              <span class="p-button-icon pi pi-chevron-up" [pTooltip]="'ACTION.COLLAPSE'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                            </button>
                          </div>
                        </ng-container>
                        <div *ngIf="isNegotiation && !offerFirmBindingIsReadOnly && !isViewNegotationAfterRecap"
                          class="buttonsOffer demurrageConditions">
                          <app-buttons-offer [field]="'demurrageConditions'" (approveField)="onApproveField($event)"
                            [disabled]="!form.get('demurrageConditions').valid" (undoField)="onUndoField($event)"
                            (undoApprove)="onUndoApprove($event)" (onReopen)="reopenField($event)"
                            (onSave)="saveField($event)" (onEdit)="setEditable('demurrageConditions', $event)"
                            [value]="form.get('demurrageConditions')?.value"
                            [isApproved]="confirmedStatus.includes(offerBinding.demurrageConditionsState)"></app-buttons-offer>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </td>
              </tr>
              <tr class="trHistorydemurrageConditions hiddenHistory">
                <td [attr.colspan]="(isFirstStepCharterer || isClhRevision) ? '4' : '3'">
                  <ng-container *ngIf="!withoutBorderInput || isClh">
                    <app-histories-offer fieldName="demurrageConditions" *ngIf="offerBinding"
                      [negotiationOfferShipOwner]="offerBinding"></app-histories-offer>
                  </ng-container>
                </td>
              </tr>
              <!-- END TR DEMURRAGE CONDITIONS -->
              <!-- TR VESSEL NAME -->
              <tr class="tr17offerBinding" [ngClass]="(!isClhRevision) ? 'state' + offerBindingInitial.vesselNameState + ' ' +
                                (offerBinding.vesselNameHistories?.length ? 'input-orange' : 'input-nochanges') : ''">
                <th scope="row">17</th>
                <!-- DELETE CHECK -->
                <!-- <td><i *ngIf="confirmedStatus.includes(offerBinding.vesselNameState) && isNegotiation" class="pi pi-check-circle"></i></td> -->
                <td class="labelOffer">{{'COMPARATIVE_OFFER.ROW.ROW_VESSELNAME' | translate}}</td>
                <td *ngIf="isFirstStepCharterer || isClhRevision"><label></label></td>
                <td>
                  <ng-container *ngIf="isRecap && offerBinding.vesselName !== '' else containerGeneralVesselname">
                    <div class="d-flex justify-content-start" *ngIf="offerBinding">
                      <label *ngIf="offerBinding.vesselName"> {{offerBinding.vesselName}}</label>
                    </div>
                  </ng-container>
                  <ng-template #containerGeneralVesselname>
                    <div [ngClass]="withoutBorderInput ? 'withoutBorder' : ''">
                      <div class="field d-flex">
                        <!-- <span class="p-float-label w-100"> -->
                        <input type="text" pInputText formControlName="vesselName" style="width: 100%"
                          id="inputVesselBinding" size="60"
                          placeholder="RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_VESSEL"
                          class="w-100 inputVessel" [readonly]="offerBinding.vesselNameShowLabel"
                          (click)="changeClassOnDivButtonsOffer('vesselName')" />
                        <!-- <label for="inputVesselBinding" class="labelColor">
                                                    {{'RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_VESSEL' | translate}}
                                                  </label>
                                                </span> -->
                        <ng-container *ngIf="!withoutBorderInput || isClh">
                          <div class="div-button-collapse d-flex" *ngIf="offerBinding.vesselNameHistories?.length">
                            <button class="btn btn-link btn-collapse" type="button" data-toggle="collapse"
                              data-target="#collapseHistoryvesselName" aria-expanded="false"
                              aria-controls="collapseHistoryvesselName"
                              (click)="addClassTrHistory($event,'vesselName')">
                              <span class="p-button-icon pi pi-chevron-down" [pTooltip]="'ACTION.EXPAND'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                              <span class="p-button-icon pi pi-chevron-up" [pTooltip]="'ACTION.COLLAPSE'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                            </button>
                          </div>
                        </ng-container>
                        <div *ngIf="isNegotiation && !offerFirmBindingIsReadOnly && !isViewNegotationAfterRecap"
                          class="buttonsOffer vesselName">
                          <app-buttons-offer [field]="'vesselName'" (approveField)="onApproveField($event)"
                            [disabled]="!form.get('vesselName').valid" (undoField)="onUndoField($event)"
                            (undoApprove)="onUndoApprove($event)" (onReopen)="reopenField($event)"
                            (onSave)="saveField($event)" (onEdit)="setEditable('vesselName', $event)"
                            [value]="form.get('vesselName')?.value"
                            [isApproved]="confirmedStatus.includes(offerBinding.vesselNameState)"></app-buttons-offer>
                        </div>
                      </div>
                      <div class="d-flex flex-column justify-content-start" *ngIf="!isRecap">
                        <div
                          *ngIf="form.get('vesselName').touched  && !form.get('vesselName').value  && form.get('vesselName').hasError('required')"
                          class="error-label labelError">
                          {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </td>
              </tr>
              <tr class="trHistoryvesselName hiddenHistory">
                <td [attr.colspan]="(isFirstStepCharterer || isClhRevision) ? '4' : '3'">
                  <ng-container *ngIf="!withoutBorderInput || isClh">
                    <app-histories-offer fieldName="vesselName" *ngIf="offerBinding"
                      [negotiationOfferShipOwner]="offerBinding"></app-histories-offer>
                  </ng-container>
                </td>
              </tr>
              <!-- END TR VESSEL NAME -->
              <!-- TR REMARKS -->
              <tr class="tr18offerBinding" [ngClass]="(!isClhRevision) ? 'state' + offerBindingInitial.remarksState + ' ' +
                                (offerBinding.remarksHistories?.length ? 'input-orange' : 'input-nochanges') : ''">
                <th scope="row">18</th>
                <!-- DELETE CHECK -->
                <!-- <td><i *ngIf="confirmedStatus.includes(offerBinding.remarksState) && isNegotiation" class="pi pi-check-circle"></i></td> -->
                <td class="labelOffer">{{'COMPARATIVE_OFFER.ROW.ROW_REMARKS' | translate}}</td>
                <td *ngIf="isFirstStepCharterer || isClhRevision"><label></label></td>
                <td>
                  <ng-container *ngIf="isRecap else containerGeneralRemarks">
                    <div class="d-flex justify-content-start" *ngIf="offerBinding">
                      <label *ngIf="!offerBinding.remarks"> </label>
                      <label *ngIf="offerBinding.remarks">{{offerBinding.remarks}}</label>
                    </div>
                  </ng-container>
                  <ng-template #containerGeneralRemarks>
                    <div [ngClass]="withoutBorderInput ? 'withoutBorder' : ''">
                      <div class="field d-flex">
                        <clh-textarea [form]="form" [nameControl]="'remarks'" [style]="'width:100%;'" [errorLabel]="''"
                          [placeholder]="'RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_REMARKS'" [label]="''"
                          [idTextarea]="'textAreaRemarksBinding'" [classTextarea]="'w-100 textAreaRemarks clhTextarea'"
                          [required]="false" [readOnly]="offerBinding.remarksShowLabel"
                          (textAreaClick)="changeClassOnDivButtonsOffer('remarks')"
                          [showLabelFloat]="false"></clh-textarea>
                        <ng-container *ngIf="!withoutBorderInput || isClh">
                          <div class="div-button-collapse d-flex" *ngIf="offerBinding.remarksHistories?.length">
                            <button class="btn btn-link btn-collapse" type="button" data-toggle="collapse"
                              data-target="#collapseHistoryremarks" aria-expanded="false"
                              aria-controls="collapseHistoryremarks" (click)="addClassTrHistory($event,'remarks')">
                              <span class="p-button-icon pi pi-chevron-down" [pTooltip]="'ACTION.EXPAND'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                              <span class="p-button-icon pi pi-chevron-up" [pTooltip]="'ACTION.COLLAPSE'| translate"
                                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                            </button>
                          </div>
                        </ng-container>
                        <div class="d-flex align-items-center buttonsOffer remarks"
                          *ngIf="isNegotiation && !offerFirmBindingIsReadOnly && !isViewNegotationAfterRecap">
                          <app-buttons-offer [field]="'remarks'" (approveField)="onApproveField($event)"
                            [disabled]="!form.get('remarks').valid" (undoField)="onUndoField($event)"
                            (undoApprove)="onUndoApprove($event)" (onReopen)="reopenField($event)"
                            (onSave)="saveField($event)" (onEdit)="setEditable('remarks', $event)"
                            [value]="form.get('remarks')?.value"
                            [isApproved]="confirmedStatus.includes(offerBinding.remarksState)"></app-buttons-offer>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </td>
              </tr>
              <tr class="trHistoryremarks hiddenHistory">
                <td [attr.colspan]="(isFirstStepCharterer || isClhRevision) ? '4' : '3'">
                  <ng-container *ngIf="!withoutBorderInput || isClh">
                    <app-histories-offer fieldName="remarks" *ngIf="offerBinding"
                      [negotiationOfferShipOwner]="offerBinding"></app-histories-offer>
                  </ng-container>
                </td>
              </tr>
              <!-- END TR REMARKS -->
              <!-- TR ADDRESS COM -->
              <tr class="tr19OfferBinding" *ngIf="isShipownerBinding || isRecap || isOperative">
                <th scope="row">A</th>
                <!-- DELETE CHECK -->
                <!-- <td></td> -->
                <td class="labelOffer">{{'CHARTERER_DATA_SHEET.ADDRESS_COM' | translate}}</td>
                <td *ngIf="isFirstStepCharterer || isClhRevision"><label></label></td>
                <td colspan="2">
                  <ng-container *ngIf="isRecap else containerGeneralAddresscom">
                    <div class="d-flex justify-content-start" *ngIf="offerBinding">
                      <label *ngIf="offerBinding.addressComm"> {{offerBinding.addressComm | number:'1.2-2' }} %</label>
                    </div>
                  </ng-container>
                  <ng-template #containerGeneralAddresscom>
                    <div class="field">
                      <span class="spanMargin">
                        <p-inputNumber class="inputAddressComm" id="inputAddressComm" [readonly]="true"
                          formControlName="addressComm" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"
                          [readonly]="offerBinding.addressCommShowLabel" locale="de-DE"></p-inputNumber>
                        <span class="span-percent">%</span>
                      </span>
                    </div>
                  </ng-template>
                </td>
              </tr>
              <!-- END TR ADDRESS COM -->
              <!-- LIST CHARGERCLAUSES -->
              <ng-container *ngIf="listchargerClauses?.length">
                <ng-container>
                  <tr>
                    <th scope="row" [attr.colspan]="(isFirstStepCharterer || isClhRevision) ? '6' : '6' "
                      class="clausesRed">
                      {{'BINDING_OFFER.ROW.CLAUSES_CHARTERER' | translate}}
                    </th>
                  </tr>
                </ng-container>
                <ng-container>
                  <ng-container *ngFor="let clause of listchargerClauses;let i = index ">
                    <tr [ngClass]="(!isClhRevision) ? 'state' + clause.stateInitial + ' ' +
                                        (clause.clauseHistories?.length ? 'input-orange' : 'input-nochanges') : ''">
                      <th scope="row">{{i+19}}</th>
                      <!-- DELETE CHECK -->
                      <!-- <td><i *ngIf="confirmedStatus.includes(clause.state)  && isNegotiation && !clause.isRefused || clause.approvedRefused" class="pi pi-check-circle"></i>
                                              <i *ngIf="clause.isRefused && clause.state != NegotiationFieldState.CONFIRMED" class="pi pi-times-circle"></i></td> -->
                      <td [attr.colspan]="(isFirstStepCharterer || isClhRevision) ? '6' : '4' ">
                        <!-- DIV TO BORDER -->
                        <ng-container *ngIf="isRecap else containerGeneralClausesCharterer">
                          <div class="d-flex justify-content-start p-2">
                            <label class="labelClauseWord"> {{clause.value}}</label>
                          </div>
                        </ng-container>
                        <ng-template #containerGeneralClausesCharterer>
                          <div [ngClass]="withoutBorderInput ? 'withoutBorder' : ''">
                            <!-- DIV TEXTAREA CLAUSE + BUTTONS -->
                            <div class="field d-flex">
                              <!-- TEXTAREA -->
                              <clh-textarea [form]="form" [nameControl]="clause.nameControl" [style]="'width:100%;'"
                                [errorLabel]="''" [placeholder]="''" [label]="''"
                                [idTextarea]="'clauseTextarea'+clause.nameControl"
                                [classTextarea]="clause.isRefused ? 'w-100 inputClauses crossed clhTextarea' : 'w-100 inputClauses clhTextarea' && clause.isReadOnly ? 'w-100 inputClauses clauseReadOnly clhTextarea' : 'w-100 inputClauses clauseNoReadOnly clhTextarea'"
                                [required]="false" [readOnly]="clause.isReadOnly"
                                (textAreaClick)="changeClassOnDivButtonsOffer('Clauses',clause)"
                                [showLabelFloat]="false"></clh-textarea>
                              <!-- END TEXTAREA -->
                              <!-- CONTAINER AFTER THE FIRST STEP  -->
                              <ng-container *ngIf="!isFirstStepCharterer">
                                <!-- COLLAPSE BUTTON  -->
                                <ng-container
                                  *ngIf="((!withoutBorderInput || isClh) && offerBinding.counter >= 1) || isShipownerBinding && !withoutBorderInput ">
                                  <div class="div-button-collapse d-flex justify-content-center"
                                    *ngIf="clause.clauseHistories?.length">
                                    <button class="btn btn-link btn-collapse" type="button" data-toggle="collapse"
                                      [attr.data-target]="clause.dataTarget" aria-expanded="false"
                                      [attr.aria-controls]="clause.ariaControls"
                                      (click)="addClassTrHistory($event,'Clauses',clause)">
                                      <span class="p-button-icon pi pi-chevron-down"
                                        [pTooltip]="'ACTION.EXPAND'| translate" tooltipPosition="bottom"
                                        tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                                      <span class="p-button-icon pi pi-chevron-up"
                                        [pTooltip]="'ACTION.COLLAPSE'| translate" tooltipPosition="bottom"
                                        tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                                    </button>
                                  </div>
                                </ng-container>
                                <!--END COLLAPSE BUTTON  -->
                                <!-- OPERATIONAL NEGOTIATION BUTTONS  -->
                                <div
                                  *ngIf="isNegotiation && !showDivClauses && !offerFirmBindingIsReadOnly && !isViewNegotationAfterRecap"
                                  class="d-flex justify-content-center align-items-center buttonsOffer {{clause.nameControl}}">
                                  <app-buttons-offer [field]="'chargerClauses'" [negotiationClause]="clause"
                                    (approveField)="onApproveField($event, clause)"
                                    (undoField)="onUndoField($event,clause)"
                                    (undoApprove)="onUndoApprove($event, clause)"
                                    (onReopen)="reopenField($event, clause)" (onSave)="saveField($event, clause)"
                                    (onEdit)="setEditable('clause', $event,clause)"
                                    [value]="form.get(clause.nameControl).value" [isClause]="true"
                                    [isApproved]="confirmedStatus.includes(clause.state)"
                                    [disabled]="!form.get(clause.nameControl).valid"
                                    (onActive)="onActive($event,clause)"
                                    (onRemove)="onRemove($event,clause)"></app-buttons-offer>
                                </div>
                                <!-- END OPERATIONAL NEGOTIATION BUTTONS  -->
                              </ng-container>
                              <!-- END CONTAINER AFTER THE FIRST STEP  -->
                              <!-- CLAUSE EDIT BUTTONS  -->
                              <div [ngClass]=" isRecap ? '': 'd-flex justify-content-center align-items-center'"
                                *ngIf="!isShipownerBinding && showDivClauses && !isClhRevision && !isRecap">
                                <button pButton pRipple type="button" *ngIf="clause.isReadOnly && !clause.isEditClause"
                                  icon="pi pi-pencil" (click)="editClause(clause)" [pTooltip]="'ACTION.EDIT'| translate"
                                  tooltipPosition="bottom" tooltipStyleClass="tooltipCLH"
                                  class="p-button-rounded p-button-text iconModificarClause"></button>
                                <button pButton pRipple type="button" *ngIf="!clause.isReadOnly" icon="pi pi-check"
                                  (click)="saveClause(clause)" [pTooltip]="'ACTION.SAVE'| translate"
                                  tooltipPosition="bottom" tooltipStyleClass="tooltipCLH"
                                  class="p-button-rounded p-button-text iconAceptarClause"></button>
                                <button pButton pRipple type="button" icon="pi pi-trash"
                                  class="p-button-rounded p-button-text iconEliminarClause"
                                  [pTooltip]="'ACTION.DELETE'| translate" tooltipPosition="bottom"
                                  tooltipStyleClass="tooltipCLH" (click)="deleteClause(clause)"></button>
                              </div>
                              <!-- END CLAUSE EDIT BUTTONS  -->
                            </div>
                            <!-- END DIV TEXTAREA CLAUSE + BUTTONS -->
                          </div>
                        </ng-template>
                        <!-- END DIV TO BORDER -->
                      </td>
                    </tr>
                    <tr
                      [ngClass]="clause.nameControl ? ['trHistory' + clause.nameControl, 'hiddenHistory']: 'hiddenHistory'">
                      <td [attr.colspan]="(isFirstStepCharterer || isClhRevision) ? '4' : '3'">
                        <ng-container *ngIf="!withoutBorderInput || isClh">
                          <app-histories-offer [negotiationOfferShipOwner]="offerBinding" [clauseSend]="clause"
                            *ngIf="clause" fieldName="chargerClauses">
                          </app-histories-offer>
                        </ng-container>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </ng-container>
              <!-- END LIST CHARGERCLAUSES -->
              <!-- LIST SHIPOWNERCLAUSES -->
              <ng-container *ngIf="listshipOwnerClauses.length">
                <ng-container>
                  <tr>
                    <th scope="row" [attr.colspan]="(!isShipownerBinding) ? '4' : '3' " class="clausesRed">
                      {{'BINDING_OFFER.ROW.CLAUSES_SHIPOWNER' | translate}}
                    </th>
                  </tr>
                </ng-container>
                <ng-container>
                  <ng-container *ngFor="let clause of listshipOwnerClauses;let i = index ">
                    <tr [ngClass]="(!isClhRevision) ? 'state' + clause.stateInitial + ' ' +
                                        (clause.clauseHistories?.length ? 'input-orange' : 'input-nochanges') : ''">
                      <th scope="row">{{i+19+countClauses}}</th>
                      <!-- DELETE CHECK -->
                      <!-- <td><i *ngIf="confirmedStatus.includes(clause.state)   && isNegotiation && !clause.isRefused || clause.approvedRefused" class="pi pi-check-circle"></i><i *ngIf="clause.isRefused && !clause.approvedRefused" class="pi pi-times-circle"></i>
                                            </td> -->
                      <td [attr.colspan]="(!isShipownerBinding) ? '6' : '4' ">
                        <!-- DIV TO BORDER -->
                        <ng-container *ngIf="isRecap else containerGeneralClausesShipowner">
                          <div class="d-flex justify-content-start p-2">
                            <label class="labelClauseWord"> {{clause.value}}</label>
                          </div>
                        </ng-container>
                        <ng-template #containerGeneralClausesShipowner>
                          <div [ngClass]="withoutBorderInput ? 'withoutBorder' : ''">
                            <!-- DIV TEXTAREA CLAUSE + BUTTONS -->
                            <div class="field d-flex">
                              <clh-textarea [form]="form" [nameControl]="clause.nameControl" [style]="'width:100%;'"
                                [errorLabel]="''" [placeholder]="''" [label]="''"
                                [idTextarea]="'clauseTextarea'+clause.nameControl"
                                [classTextarea]="clause.isRefused ? 'w-100 inputClauses crossed clhTextarea' : 'w-100 inputClauses clhTextarea' && clause.isReadOnly ? 'w-100 inputClauses clauseReadOnly clhTextarea' : 'w-100 inputClauses clauseNoReadOnly clhTextarea'"
                                [required]="false" [readOnly]="clause.isReadOnly"
                                (textAreaClick)="changeClassOnDivButtonsOffer('Clauses',clause)"
                                [showLabelFloat]="false"></clh-textarea>
                              <!-- END TEXTAREA -->
                              <!-- CONTAINER AFTER THE FIRST STEP  -->
                              <ng-container *ngIf="!isRecap">
                                <!-- COLLAPSE BUTTON  -->
                                <ng-container *ngIf="!withoutBorderInput || ( isClh && offerBinding.counter >= 1)">
                                  <div class="div-button-collapse d-flex justify-content-center"
                                    *ngIf="clause.clauseHistories?.length">
                                    <button class="btn btn-link btn-collapse" type="button" data-toggle="collapse"
                                      [attr.data-target]="clause.dataTarget" aria-expanded="false"
                                      [attr.aria-controls]="clause.ariaControls"
                                      (click)="addClassTrHistory($event,'Clauses',clause)">
                                      <span class="p-button-icon pi pi-chevron-down"
                                        [pTooltip]="'ACTION.EXPAND'| translate" tooltipPosition="bottom"
                                        tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                                      <span class="p-button-icon pi pi-chevron-up"
                                        [pTooltip]="'ACTION.COLLAPSE'| translate" tooltipPosition="bottom"
                                        tooltipStyleClass="tooltipCLH" aria-hidden="true"></span>
                                    </button>
                                  </div>
                                </ng-container>
                                <!--END COLLAPSE BUTTON  -->
                                <!-- OPERATIONAL NEGOTIATION BUTTONS  -->
                                <div
                                  *ngIf="isNegotiation && !offerFirmBindingIsReadOnly && !isViewNegotationAfterRecap && !clause.isDuplicate"
                                  class="d-flex justify-content-center align-items-center  buttonsOffer {{clause.nameControl}}">
                                  <app-buttons-offer [field]="'shipOwnerClauses'" [negotiationClause]="clause"
                                    (approveField)="onApproveField($event, clause)"
                                    (undoField)="onUndoField($event,clause)"
                                    (undoApprove)="onUndoApprove($event, clause)"
                                    (onReopen)="reopenField($event, clause)" (onSave)="saveField($event, clause)"
                                    (onEdit)="setEditable('clause', $event,clause)"
                                    [value]="form.get(clause.nameControl).value" [isClause]="true"
                                    [isApproved]="confirmedStatus.includes(clause.state)"
                                    [disabled]="!form.get(clause.nameControl).valid"
                                    (onActive)="onActive($event,clause)"
                                    (onRemove)="onRemove($event,clause)"></app-buttons-offer>
                                </div>
                                <!-- END OPERATIONAL NEGOTIATION BUTTONS  -->
                              </ng-container>
                              <!-- END CONTAINER AFTER THE FIRST STEP  -->
                              <!-- CLAUSE EDIT BUTTONS  -->
                              <div [ngClass]=" isRecap ? '': 'd-flex justify-content-center align-items-center'"
                                *ngIf="!isShipownerBinding && clause.isDuplicate && !isClhRevision && !isRecap">
                                <button pButton pRipple type="button" *ngIf="clause.isReadOnly && !clause.isEditClause"
                                  icon="pi pi-pencil" (click)="editClause(clause)" [pTooltip]="'ACTION.EDIT'| translate"
                                  tooltipPosition="bottom" tooltipStyleClass="tooltipCLH"
                                  class="p-button-rounded p-button-text iconModificarClause"></button>
                                <button pButton pRipple type="button" *ngIf="!clause.isReadOnly" icon="pi pi-check"
                                  (click)="saveClause(clause)" [pTooltip]="'ACTION.SAVE'| translate"
                                  tooltipPosition="bottom" tooltipStyleClass="tooltipCLH"
                                  class="p-button-rounded p-button-text iconAceptarClause"></button>
                                <button pButton pRipple type="button" icon="pi pi-trash"
                                  [pTooltip]="'ACTION.DELETE'| translate" tooltipPosition="bottom"
                                  tooltipStyleClass="tooltipCLH"
                                  class="p-button-rounded p-button-text iconEliminarClause"
                                  (click)="deleteClause(clause)"></button>
                              </div>
                              <!-- END CLAUSE EDIT BUTTONS  -->
                            </div>
                            <!-- END DIV TEXTAREA CLAUSE + BUTTONS -->
                          </div>
                        </ng-template>
                        <!-- END DIV TO BORDER -->
                      </td>
                    </tr>
                    <tr
                      [ngClass]=" clause.nameControl ? ['trHistory' + clause.nameControl, 'hiddenHistory']: 'hiddenHistory'">
                      <td [attr.colspan]="(isFirstStepCharterer || isClhRevision) ? '4' : '3'">
                        <ng-container *ngIf="!withoutBorderInput || isClh">
                          <app-histories-offer [negotiationOfferShipOwner]="offerBinding" [clauseSend]="clause"
                            *ngIf="clause" fieldName="shipOwnerClauses"></app-histories-offer>
                        </ng-container>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </ng-container>
              <!--END LIST SHIPOWNERCLAUSES -->
              <ng-container>
                <tr class="tr19OfferBinding">
                  <th colspan="6">
                    <!-- TEXTAREA CLAUSES CHARGER -->
                    <ng-container *ngIf="showTextArea">
                      <div class="field d-flex divTextAreaBinding">
                        <clh-textarea [form]="form" [nameControl]="'clausesNew'"
                          [style]="'width:100%;min-height:120px;'" [errorLabel]="''" [placeholder]="''" [label]="''"
                          [idTextarea]="'clauseTextarea'+'clausesNew'"
                          [classTextarea]="'w-100 inputClausesNew clhTextarea'" [required]="false" [minHeight]="120"
                          [readOnly]="isreadonly" [showLabelFloat]="false"></clh-textarea>
                        <div class="d-flex justify-content-center align-items-center">
                          <button pButton pRipple type="button" icon="pi pi-plus" (click)="saveClause()"
                            [pTooltip]="'ACTION.ADD'| translate" tooltipPosition="bottom" tooltipStyleClass="tooltipCLH"
                            class="p-button-rounded p-button-text buttonSaveClause"></button>
                          <button pButton pRipple type="button" icon="pi pi-times"
                            [pTooltip]="'ACTION.CANCEL'| translate" tooltipPosition="bottom"
                            tooltipStyleClass="tooltipCLH" (click)="cancelClause()"
                            class="p-button-rounded buttonCancelClause"></button>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="showDivClauses">
                      <ng-container *ngIf=" !showTextArea">
                        <div class="d-flex justify-content-center mt-3 mb-3">
                          <button pButton type="button" icon="pi pi-file" (click)="changeVariables()"
                            [pTooltip]="'ACTION.ADD_CLAUSE'| translate" tooltipPosition="bottom"
                            tooltipStyleClass="tooltipCLH" class="buttonInsertTextArea"></button>
                        </div>
                      </ng-container>
                    </ng-container>
                    <!-- TEXTAREA CLAUSES SHIPOWNER -->
                    <ng-container
                      *ngIf="(isShipownerBinding && isCounter0shipOwner)|| isClhRevision || isClh || (isShipownerBinding && offerBinding.counter==1 && offerBinding.state == StateOfferShipOwner.AGREEMENT_PREVALIDATED) ">
                      <div class="d-flex divTextAreaBindingCLH">
                        <div class="row" style="width: 100%">
                          <h5 *ngIf="isShipownerBinding && isCounter0shipOwner" class="tittleTextArea">
                            {{'BINDING_OFFER_SHIPOWNER.TITLE_TEXTAREA' | translate}}
                          </h5>
                          <h5 *ngIf="isClhRevision && !offerFirmBindingIsReadOnly" class="tittleTextArea">
                            {{'BINDING_OFFER_SHIPOWNER.TITLE_TEXTAREA_CLH_REVISION' | translate}}
                          </h5>
                          <h5
                            *ngIf="(isClh && offerFirmBindingIsReadOnly) || (isShipownerBinding && offerBinding.state == StateOfferShipOwner.AGREEMENT_PREVALIDATED)"
                            class="tittleTextArea">
                            {{'BINDING_OFFER_SHIPOWNER.TITLE_TEXTAREA_CLH' | translate}}
                          </h5>
                          <clh-textarea [form]="form" [nameControl]="'initialShipOwnerClauses'"
                            [style]="'width:100%; min-height:360px;'" [placeholder]="''"
                            [idTextarea]="'clauseTextarea'+'initialShipOwnerClauses'"
                            [classTextarea]="'w-100 inputClausesShipowner clhTextarea'" [readOnly]="isreadonly"
                            [minHeight]="360" [showLabelFloat]="false"></clh-textarea>
                        </div>
                      </div>
                    </ng-container>
                  </th>
                </tr>
              </ng-container>
              <!-- LIST CLAUSES FOR CLH-->
              <ng-container *ngIf="isClhRevision">
                <ng-container *ngFor="let clause of listClauseCLH;let i = index ">
                  <tr>
                    <th scope="row">{{i+19+countClauses}}</th>
                    <td [attr.colspan]="(isFirstStepCharterer || isClhRevision) ? '6' : '4' ">
                      <div class="field d-flex justify-content-start align-items-center">
                        <clh-textarea [form]="form" [nameControl]="clause.nameControl" [style]="'width:100%;'"
                          [errorLabel]="''" [placeholder]="''" [idTextarea]="'clauseTextarea'+clause.nameControl"
                          [classTextarea]="clause.isReadOnly ? 'w-100 inputClauses clauseReadOnly clhTextarea' : 'w-100 inputClauses clauseNoReadOnly clhTextarea' "
                          [required]="true" [readOnly]="clause.isReadOnly" [showLabelFloat]="false"></clh-textarea>
                        <!-- col-2 -->
                        <div class="d-flex justify-content-center align-items-center" *ngIf="isClhRevision">
                          <!-- <div class="d-flex justify-content-center"> -->
                          <button pButton pRipple type="button" *ngIf="clause.isReadOnly && !clause.isEditClause"
                            icon="pi pi-pencil" (click)="editClause(clause)" [pTooltip]="'ACTION.EDIT'| translate"
                            tooltipPosition="bottom" tooltipStyleClass="tooltipCLH"
                            class="p-button-rounded p-button-text iconModificarClause"></button>
                          <button pButton pRipple type="button" *ngIf="!clause.isReadOnly" icon="pi pi-check"
                            (click)="saveClause(clause)" [pTooltip]="'ACTION.SAVE'| translate" tooltipPosition="bottom"
                            tooltipStyleClass="tooltipCLH"
                            class="p-button-rounded p-button-text iconAceptarClause"></button>
                          <button pButton pRipple type="button" icon="pi pi-trash"
                            class="p-button-rounded p-button-text iconEliminarClause"
                            [pTooltip]="'ACTION.DELETE'| translate" tooltipPosition="bottom"
                            tooltipStyleClass="tooltipCLH" (click)="deleteClause(clause)"></button>
                          <!-- </div> -->
                          <!-- </div> -->
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
          <!-- CONFIRM RECAP  -->
          <ng-container *ngIf="isRecap && isConfirmedRecap">
            <div class="divContainerConfirmRecap">
              <div class="divInsideConfirmRecap">
                <h5 class="titleRecapDate">{{'TABLE.CHARTERER_CONFIRM_RECAP' | translate}}</h5>
                <label *ngIf="offerBinding.chargerContactNameToRecapConfirm || offerBinding.chargerRecapDate">
                  {{offerBinding.chargerContactNameToRecapConfirm}} : {{offerBinding.chargerRecapDate | date: 'dd/MMM/yy
                  HH:mm'}} hrs UTC
                </label>
              </div>
              <div class="divInsideConfirmRecap">
                <h5 class="titleRecapDate">{{'TABLE.SHIPOWNER_CONFIRM_RECAP' | translate}}</h5>
                <label *ngIf="offerBinding.shipOwnerContactNameToRecapConfirm || offerBinding.shipOwnerRecapDate">
                  {{offerBinding.shipOwnerContactNameToRecapConfirm}} : {{offerBinding.shipOwnerRecapDate | date:
                  'dd/MMM/yy HH:mm'}} hrs UTC
                </label>
              </div>
            </div>
          </ng-container>
          <!-- END CONFIRM RECAP -->
          <!-- BUTTON ADD CLAUSES  -->
          <ng-container *ngIf="isClhRevision && !offerFirmBindingIsReadOnly">
            <div class="d-flex justify-content-center mt-3 mb-3">
              <button pButton type="button" icon="pi pi-file" (click)="changeVariables()"
                [pTooltip]="'ACTION.ADD_CLAUSE'| translate" tooltipPosition="bottom" tooltipStyleClass="tooltipCLH"
                class="buttonInsertTextArea"></button>
            </div>
          </ng-container>
          <!-- END BUTTON ADD CLAUSES -->
          <!-- TEXTAREA ADD CLAUSES CLH -->
          <ng-container *ngIf="!isreadonlyCLH">
            <div class="field d-flex divTextAreaBinding">
              <clh-textarea [form]="form" [nameControl]="'clausesNewCLH'" [style]="'width:100%; min-height:120px;'"
                [errorLabel]="''" [placeholder]="''" [idTextarea]="'clauseTextareaclausesNewCLH'"
                [classTextarea]="'w-100 inputClausesNew clhTextarea'" [required]="false" [readOnly]="isreadonlyCLH"
                [minHeight]="120" [showLabelFloat]="false"></clh-textarea>
              <div class="d-flex justify-content-around align-items-center">
                <button pButton pRipple type="button" icon="pi pi-plus" (click)="saveClause()"
                  [pTooltip]="'ACTION.ADD'| translate" tooltipPosition="bottom" tooltipStyleClass="tooltipCLH"
                  class="p-button-rounded p-button-text buttonSaveClause"></button>
                <button pButton pRipple type="button" icon="pi pi-times" [pTooltip]="'ACTION.CANCEL'| translate"
                  tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" (click)="cancelClause()"
                  class="p-button-rounded buttonCancelClause"></button>
              </div>
            </div>
          </ng-container>
          <!-- END TEXTAREA ADD CLAUSES CLH -->
          <!-- BUTTONS ADD DEFAULT CLAUSES CHARGER -->
          <ng-container>
            <ng-container *ngIf="showDivClauses">
              <div class="containerButtonStep1">
                <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-6"> -->
                <div class="d-flex justify-content-center">
                  <p-button label="{{'BINDING_OFFER.BUTTON.DUPLICATE' | translate}}" styleClass="actualizar"
                    (onClick)="duplicateClauses()" [disabled]="contentDuplicateClause || contentDefaultClause"
                    [escape]="false" [pTooltip]="'TOOLTIP.DUPLICATECLAUSE'| translate" tooltipPosition="bottom"
                    tooltipStyleClass="tooltipCLH" [showDelay]="1000" [hideDelay]="1000">
                  </p-button>
                </div>
                <!-- </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-6"> -->
                <div class="d-flex justify-content-center">
                  <p-button label="{{'BINDING_OFFER.BUTTON.LOAD_CLAUSE' | translate}}" styleClass="actualizar"
                    (onClick)="showClauses()" [disabled]="contentDefaultClause || contentDuplicateClause"
                    [escape]="false" [pTooltip]="'TOOLTIP.LOADCLAUSE'| translate" tooltipPosition="bottom"
                    tooltipStyleClass="tooltipCLH" [showDelay]="1000" [hideDelay]="1000"></p-button>
                </div>
                <!-- </div> -->
              </div>
            </ng-container>
            <!-- DEADLINE -->
            <ng-container
              *ngIf="offerBinding && !isClhRevision && !isRecap && !isConfirmedRecap && !isViewNegotationAfterRecap">
              <div class="divDeadLine d-flex justify-content-center">
                <h6>
                  <strong>{{'BINDING_OFFER.DEADLINE_LABEL' | translate}}</strong><p-calendar #calendarExpiration
                    formControlName="expirationDate" dateFormat="dd/mm/yy"
                    styleClass="calendarBinding calendarBindingMargin" [showIcon]="true" [showTime]="true"
                    [showSeconds]="false" [minDate]="today"></p-calendar>
                  <p-inputSwitch styleClass="switchCLH" *ngIf="!offerFirmBindingIsReadOnly"
                    formControlName="checkedExpirationDate"
                    (onChange)="sendExpirationDate($event);($event.checked ? calendarExpiration.showOverlay() : null)"></p-inputSwitch>
                </h6>
              </div>
              <div class="d-flex d-flex justify-content-center" *ngIf="!isRecap">
                <div *ngIf="form.get('expirationDate').touched  && form.errors?.['expirationDate']"
                  class="error-label labelError">
                  {{'MESSAGES.VALIDATION.EXPIRATION_DATE' | translate}}
                </div>
              </div>
            </ng-container>
            <!-- END DEADLINE -->

            <!-- BUTTONS SEND -->
            <div class="containerButtonStep1">
              <ng-container
                *ngIf="(isShipownerBinding && isCounter0shipOwner && !offerFirmBindingIsReadOnly) || (isShipownerBinding&& isNegotiation && !offerFirmBindingIsReadOnly)  || (!isShipownerBinding && isNegotiation && !offerFirmBindingIsReadOnly) ">
                <div class="div-btn-cancel">
                  <div *ngIf="(!isCLHRepresentingCharger && !isCLHRepresentingShipowner)">
                    <p-button
                      [label]="isShipownerBinding ? ('BINDING_OFFER.BUTTON.DECLINED' | translate) :  ('BINDING_OFFER.BUTTON.CANCEL_ARCHIVE' | translate) "
                      styleClass="borrar" [disabled]="isLoading" (onClick)="cancelOrDeclineOffer()"></p-button>
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngIf="(isShipownerBinding && isCounter0shipOwner && !offerFirmBindingIsReadOnly) || (isFirstStepCharterer && showDivClauses) ">
                <div class="div-btn-next">
                  <div>
                    <p-button label="{{'ACTION.SEND' | translate}}" styleClass="actualizar"
                      [disabled]="isLoading || !form.valid" (onClick)="confirmationSendOffer('offer')"></p-button>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="isNegotiation && !isCounter0shipOwner && !offerFirmBindingIsReadOnly && !isClh">
                <div class="div-btn-next">
                  <div>
                    <p-button label="{{'BINDING_OFFER.BUTTON.SEND_COUNTER' | translate}}" styleClass="actualizar"
                      [disabled]="isLoading  || !form.valid" (onClick)="confirmationSendOffer('offer')"></p-button>
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngIf="isRecap && !isNegotiation && offerBinding && !isConfirmedRecap && !offerFirmBindingIsReadOnly && !isClh">
                <div class="div-btn-next"
                  *ngIf="(!isShipownerBinding && !isCLHRepresentingCharger) || (isShipownerBinding && !isCLHRepresentingShipowner)">
                  <div>
                    <p-button label="{{'BINDING_OFFER.BUTTON.RECAP_CONFIRM' | translate}}" styleClass="actualizar"
                      [disabled]="isLoading" (onClick)="confirmationSendOffer('recap')" [escape]="false"
                      [pTooltip]="'TOOLTIP.RECAP_CONFIRM'| translate" tooltipPosition="bottom"
                      tooltipStyleClass="tooltipCLH" [showDelay]="1000" [hideDelay]="1000">
                    </p-button>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="isClhRevision && !offerFirmBindingIsReadOnly">
                <div class="d-flex justify-content-center mt-2">
                  <p-button label="{{'ACTION.SEND' | translate}}" styleClass="actualizar" [disabled]="isLoading"
                    (onClick)="confirmationSendOffer('clh')"></p-button>
                </div>
              </ng-container>
            </div>
            <!-- END BUTTONS SEND -->
          </ng-container>
          <!-- END BUTTONS ADD DEFAULT CLAUSES CHARGER -->
          <!-- <ng-template #OtherView>

                                                                <ng-container *ngIf="isShipownerBinding && isCounter0shipOwner && !offerFirmBindingIsReadOnly">
                                                                    <div class="d-flex justify-content-center mt-2">
                                                                        <p-button label="{{'ACTION.SEND' | translate}}" styleClass="buttonSendBinding"
                                                                            [disabled]='isLoading  || !form.valid'
                                                                            (onClick)="confirmationSendOffer('offer')"></p-button>
                                                                    </div>
                                                                </ng-container>

                                                            </ng-template> -->
        </div>
        <!-- END DIV TABLE -->
        <!-- DIV CHAT -->
        <div class="col-12 col-sm-12 col-md-12 col-xl-4 divChatofferBinding overflowInX">
          <div>
            <p-tabView *ngIf="chatValues && (chatValues.length > 0) && allMethodsCompleted;else loadingSpinner">
              <ng-container *ngFor="let group of chatValues">
                <p-tabPanel header="{{group.windowName}}">
                  <app-chat-room [offerShipOwnerId]="group.offerShipOwnerId" [offerId]="group.offerId"
                    [windowName]="group.windowName" [currentParticipant]="group.currentParticipant"
                    [group]="group.group" [messages]="group.historicMessages" [unReadMessages]="group.unReadMessages"
                    (unReadMessagesUpdated)="group.unReadMessages = $event"></app-chat-room>
                </p-tabPanel>
              </ng-container>
            </p-tabView>
            <ng-template #loadingSpinner>
              <div class="spinner-container">
                <mat-spinner></mat-spinner>
              </div>
            </ng-template>
          </div>
          <div class="offerBinding-filemanager">
            <div class="documents-accordion mb-3 mt-3" *ngIf="offerBinding && offerBinding.offerId">
              <p-accordion styleClass="accordionResponse">
                <p-accordionTab>
                  <ng-template pTemplate="header"> {{'DOCUMENT.HEADER' | translate}}</ng-template>
                  <ng-template pTemplate="content">
                    <app-documents [form]="form" [entityId]="offerBinding.offerId"
                      [readonly]="offerFirmBindingIsReadOnly"
                      [placeholder]="offerFirmBindingIsReadOnly ? ('DOCUMENT.HEADER' | translate) : ('DOCUMENT.SELECT' | translate)"
                      [groupId]="offerBinding.offerShipOwnerId">
                    </app-documents>
                  </ng-template>
                </p-accordionTab>
                <p-accordionTab>
                  <ng-template pTemplate="header"> {{'NOTE.HEADER' | translate}}</ng-template>
                  <ng-template pTemplate="content">
                    <overa-notes *ngIf="isShipownerBinding" [entityId]="offerBinding.offerId"
                      [groupId]="offerBinding.offerShipOwnerId">
                    </overa-notes>
                    <overa-notes *ngIf="!isShipownerBinding" [entityId]="offerBinding.offerId"> </overa-notes>
                  </ng-template>
                </p-accordionTab>
              </p-accordion>
            </div>
          </div>
        </div>
        <!-- END DIV CHAT -->
      </div>
    </ng-container>
    <!-- END CONTAINER TABLE -->
  </ng-container>
  <!-- END CONTAINER ALL  -->
  <ng-template #showInfoExpiration>
    <div class="d-flex justify-content-center">
      <h6>{{'BINDING_OFFER.DEADLINE_EXPIRED' | translate}}</h6>
    </div>
  </ng-template>
</div>