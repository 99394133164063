<p-confirmDialog
  styleClass="containerConfirmDialog"
  acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"
></p-confirmDialog>
<div [ngClass]=" isClause && isEditting ? 'divAppButtonsOffer d-flex flex-column' : 'divAppButtonsOffer d-flex flex-row'">
  <div [ngClass]=" isClause ? 'd-flex flex-row' : ''" *ngIf="isClause && isEditting">
    <ng-container *ngIf="isClause && !isDeleted && isEditting">
      <button
        pButton
        pRipple
        type="button"
        class="p-button-text removeClause"
        (click)="removeField(field)"
        [pTooltip]="'ACTION.DELETE'| translate"
        tooltipPosition="bottom"
        tooltipStyleClass="tooltipCLH"
      >
        ab
      </button>
    </ng-container>
    <ng-container *ngIf="isClause && isEditting && isDeleted">
      <button
        pButton
        pRipple
        type="button"
        class="p-button-text activeClause"
        [pTooltip]="'ACTION.ACTIVE'| translate"
        tooltipPosition="bottom"
        tooltipStyleClass="tooltipCLH"
        (click)="activeField()"
      >
        ab
      </button>
      <!-- <button pButton pRipple type="button" icon="pi pi-refresh" class="p-button-text undoField"
        [pTooltip]="'ACTION.UNDO'| translate" tooltipPosition="bottom" tooltipStyleClass="tooltipCLH"
            (click)="undoChange()" *ngIf="isApproved"></button> -->
    </ng-container>
  </div>
  <div [ngClass]=" isClause ? ' d-flex flex-row' : 'd-flex flex-row'">
    <ng-container *ngIf="!isApproved && !isEditting && !hadChanged">
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-thumbs-up"
        (click)="onApproveField()"
        [disabled]="disabled"
        class="p-button-text checkField"
        [pTooltip]="'ACTION.CONFIRM'| translate"
        tooltipPosition="bottom"
        tooltipStyleClass="tooltipCLH"
      ></button>
    </ng-container>
    <ng-container *ngIf="hasApproved && isApproved">
      <!-- boton verde -->
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-thumbs-up"
        class="p-button-text undoField-confirmed"
        [pTooltip]="'ACTION.UNDO'| translate"
        tooltipPosition="bottom"
        tooltipStyleClass="tooltipCLH"
        (click)="onUndoApprove()"
      ></button>
    </ng-container>
    <!-- contemplar si se reabreu, poñer o candado e mostrar dialogo confirmación -->
    <!-- <ng-container *ngIf="!isEditting && !isApproved && !hasApproved && !isDeleted">
        <button pButton pRipple type="button" icon="pi pi-pencil" (click)="setEditting(true)"
            class="p-button-text editField" [pTooltip]="'ACTION.EDIT'| translate" tooltipPosition="bottom"
            tooltipStyleClass="tooltipCLH"></button>
    </ng-container> -->
    <ng-container *ngIf="!isEditting  && !isApproved ">
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-pencil"
        (click)="setEditting(true)"
        class="p-button-text editField"
        [pTooltip]="'ACTION.EDIT'| translate"
        tooltipPosition="bottom"
        tooltipStyleClass="tooltipCLH"
      ></button>
    </ng-container>
    <ng-container *ngIf="!isEditting && isApprovedInitial && !hasApproved && !hadChanged">
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-lock"
        (click)="reOpen()"
        class="p-button-text editField"
        [pTooltip]="'ACTION.EDIT'| translate"
        tooltipPosition="bottom"
        tooltipStyleClass="tooltipCLH"
      ></button>
    </ng-container>
    <ng-container *ngIf="isEditting">
      <!--
        <button pButton pRipple type="button"  class="p-button-text confirmField"
            [disabled]="disabled" (click)="confirmEdit()" >Aplicar cambios</button>
        <button pButton pRipple type="button" icon="pi pi-refresh" class="p-button-text undoField"
        [pTooltip]="'ACTION.BACK_TO_ORIGINAL'| translate" tooltipPosition="bottom" tooltipStyleClass="tooltipCLH"
            (click)="undoChange()"></button>
            -->

      <!--
    <button pButton pRipple type="button" icon="pi pi-lock" class="p-button-text confirmField" [disabled]="disabled" (click)="confirmEdit()">
      Aplicar cambios
    </button>
     -->

      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-check"
        (click)="confirmEdit()"
        class="p-button-text editField"
        [pTooltip]="'ACTION.APPLY_CHANGES'| translate"
        tooltipPosition="bottom"
        tooltipStyleClass="tooltipCLH"
      ></button>

      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-refresh"
        class="p-button-text undoField"
        [pTooltip]="'ACTION.BACK_TO_ORIGINAL'| translate"
        tooltipPosition="bottom"
        tooltipStyleClass="tooltipCLH"
        (click)="undoChange()"
      ></button>

      <!-- <ng-container *ngIf="isClause">
            <button pButton pRipple type="button" icon="pi pi-times" class="p-button-text removeField"
                (click)="removeField(field)" [pTooltip]="'ACTION.DELETE'| translate" tooltipPosition="bottom"
                tooltipStyleClass="tooltipCLH"></button>
        </ng-container> -->
    </ng-container>
  </div>
</div>
