import { contactType } from "../enum/contact-type";
import { TypeContactOld } from "../enum/type-contact-old";
import { Shipowner } from "./shipowner.model";

export class ShipOwnerContact {
    id?: string;
    name?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    isPrincipal?: boolean;
    type?: TypeContactOld;
    types?: contactType[];
    shipOwnerId?: string;
    shipOwner?: Shipowner;
    userId?: string;
    isEnabled?: boolean;
    termsAndConditionsAceptationDate?: Date;
    privacyPolicyAceptationDate?: Date;

    isLockedOut?: boolean;
    editable?: boolean;
    roles?: any[];
    isBanned?: boolean;
    isNew?: boolean;
    observations?:string;
    isPublic?:boolean;
    constructor() {
    }
}