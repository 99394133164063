<ngx-spinner name="privacyPublicSpinner"></ngx-spinner>
<div class="header-privacy">
  <div class="svg-privacy-container">
    <img class="img-privacy-header" src="assets/img/logo.svg" alt="CARGOLIGHTHOUSE" />
  </div>
</div>
<div class="card privacy-public-card">
  <div class="card-header">
    <div class="card-header paddingTittle"><h4 class="card-tittle tittlePrivacyPolicies">{{'PRIVACY_POLICIES.HEADER' | translate}}</h4></div>
  </div>
  <div class="card-body">
    <div *ngIf="privacyPoliciesLanguage else noPrivacy" class="privacy-policy-content" [innerHTML]="privacyPoliciesLanguageProcessed"></div>
    <ng-template #noPrivacy>
      <div class="no-privacy-message">{{'PRIVACY_POLICIES.HEADER' | translate}}</div>
    </ng-template>
  </div>
  <div class="containerButtonStep1">
    <div class="div-btn-cancel">
      <div>
        <button pButton type="button" icon="pi pi-home" iconPos="left" class="p-button home custom-button p-ripple" routerLink="/home"></button>
      </div>
    </div>
  </div>
</div>
