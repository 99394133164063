import { Injectable } from "@angular/core";
import { TermsAndConditions } from "../models/clause/terms-and-conditions.model";
import { HttpClient } from "@angular/common/http";
import { ConfigsService } from "@overa/shared";
import { CLHBaseService } from "./clh-base.service";

@Injectable({ providedIn: "root" })
export class TermsAndConditionsService extends CLHBaseService {
  get() {
    return this.http.get<TermsAndConditions[]>(
      `${this.baseUrl}/termsandconditions/`
    );
  }

  getLast() {
    return this.http.get<TermsAndConditions>(
      `${this.baseUrl}/termsandconditions/getLast`
    );
  }

  getById(id: string) {
    return this.http.get<TermsAndConditions>(
      `${this.baseUrl}/termsandconditions/${id}`
    );
  }

  mustAcceptTermsAndConditions(contactId: string) {
    let endpointUrl = `${this.baseUrl}/termsandconditions/mustaccepttermsandconditions/${contactId}`;
    return this.http.get<boolean>(endpointUrl);
  }

  add(tca: TermsAndConditions) {
    const endpointUrl = `${this.baseUrl}/termsandconditions`;
    return this.http.post<TermsAndConditions>(endpointUrl, tca);
  }

  edit(tca: TermsAndConditions) {
    const endpointUrl = `${this.baseUrl}/termsandconditions`;
    return this.http.put<TermsAndConditions>(endpointUrl, tca);
  }

  delete(id: string) {
    const endpointUrl = `${this.baseUrl}/termsandconditions/${id}`;
    return this.http.delete(endpointUrl);
  }
  send(tca: TermsAndConditions) {
    const endpointUrl = `${this.baseUrl}/termsandconditions/send/${tca.id}`;
    return this.http.post<TermsAndConditions>(endpointUrl, tca.id);
  }
}
