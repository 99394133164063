import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { PrivacyPolicies } from "src/app/models/privacy-policies/privacy-policies.model";
import { PrivacyPoliciesService } from "src/app/services/privacy-policies.service";

@Component({
  selector: "app-privacy-policies",
  templateUrl: "./privacy-policies.component.html",
  styleUrls: ["./privacy-policies.component.scss"],
})
export class PrivacyPoliciesComponent implements OnInit {
  languageNavigator: string;
  privacyPolicies: PrivacyPolicies;
  privacyPoliciesLanguage: string;
  privacyPoliciesLanguageProcessed: string;
  constructor(
    private privacyPoliciesService: PrivacyPoliciesService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.languageNavigator = navigator.language;
    this.spinner.show("privacyPublicSpinner");
    this.privacyPoliciesService.getLast().subscribe({
      next: (value) => {
        if (value) {
          this.privacyPolicies = value;
          if (this.languageNavigator.includes("es")) {
            this.privacyPoliciesLanguage = this.privacyPolicies.policy;
          } else {
            this.privacyPoliciesLanguage = this.privacyPolicies.policyEN;
          }
          this.spinner.hide("privacyPublicSpinner");
          //reemplaza /n por salto de linea
          this.privacyPoliciesLanguageProcessed =
            this.privacyPoliciesLanguage.replace(/\n/g, "<br>");
          // Pone las mayúsculas en negrita
          this.privacyPoliciesLanguageProcessed =
            this.privacyPoliciesLanguageProcessed.replace(
              /([A-ZÁÉÍÓÚÜ])/g,
              "<strong>$1</strong>"
            );
          console.log(this.privacyPolicies, this.privacyPoliciesLanguage);
        } else {
          this.spinner.hide("privacyPublicSpinner");
        }
      },
      error: (err) => {
        console.error(err);
        this.spinner.hide("privacyPublicSpinner");
      },
    });
  }
}
