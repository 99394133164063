import { LayoutComponent } from "./components/layout.component";

import { AppConfig } from "./app.config";

import { OveraModule } from "./modules/overa.module";
import { PrimeNgModule } from "./modules/primeng.module";
import { VendorModule } from "./modules/vendor.module";
import { TestComponent } from "./components/test/test.component";
import { APP_BASE_HREF, CommonModule, NgOptimizedImage } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  InitModule,
  OveraAppComponent,
  OveraAppRoutingModule,
} from "@overa/base";
import { TranslateService, TranslateStore } from "@ngx-translate/core";
import {
  Filters,
  GLOBALS_TOKEN,
  NgxTranslateService,
  OveraEllipsisModule,
  OveraTranslateModule,
  SafePipeModule,
  UtcDateInterceptor,
} from "@overa/shared";
import { ServiceWorkerModule } from "@angular/service-worker";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { MenuSettings } from "./menusettings";
import { OAuthModule } from "angular-oauth2-oidc";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule,
} from "@angular/common/http";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  LOCALE_ID,
  NgModule,
  isDevMode,
} from "@angular/core";
import {
  AuthInterceptor,
  ErrorHandlerInterceptor,
  TenantInterceptor,
  UserInterceptor,
} from "@overa/security";
import {
  FileuploadModule,
  FileuploadMultipleModule,
  StorageService,
} from "@overa/storage";
import { ChargerDataSheetComponent } from "./components/charger-data-sheet/charger-data-sheet.component";
import { ChargerFormComponent } from "./components/charger-data-sheet/charger-form/charger-form.component";
import { ChargerListComponent } from "./components/charger-data-sheet/charger-list/charger-list.component";
import { RoutesComponent } from "./components/charger-data-sheet/routes/routes.component";
import { ChatRoomComponent } from "./components/chat-room/chat-room.component";
import { ChooseUserComponent } from "./components/choose-user/choose-user.component";
import { HomeComponent } from "./components/home/home.component";
import { DashboardComponent } from "./components/home/tab-view-home/dashboard/dashboard.component";
import { MapComponent } from "./components/home/tab-view-home/map/map.component";
import { TabViewHomeComponent } from "./components/home/tab-view-home/tab-view-home.component";
import { KanbanElementComponent } from "./components/kanban-element/kanban-element.component";
import { KanbanViewComponent } from "./components/kanban-view/kanban-view.component";
import { ChargerShipownerContactComponent } from "./components/manteinance/charger-shipowner-contact/charger-shipowner-contact.component";
import { ClausesComponent } from "./components/manteinance/clauses/clauses.component";
import { ClhTextareaComponent } from "./components/manteinance/clh-textarea/clh-textarea.component";
import { ContactComponent } from "./components/manteinance/contact/contact.component";
import { ShipownersComponent } from "./components/manteinance/shipowners/shipowners.component";
import { OfferComparativeComponent } from "./components/offer-comparative/offer-comparative.component";
import { ButtonsOfferComponent } from "./components/offer-firm-binding/buttons-offer/buttons-offer.component";
import { HistoriesOfferComponent } from "./components/offer-firm-binding/histories-offer/histories-offer.component";
import { OfferFirmBindingComponent } from "./components/offer-firm-binding/offer-firm-binding.component";
import { OfferReceivedComponent } from "./components/offer-received/offer-received.component";
import { CommentsDialogComponent } from "./components/offer-request/comments-dialog/comments-dialog.component";
import { OfferRequestComponent } from "./components/offer-request/offer-request.component";
import { ExpirationDateDialogComponent } from "./components/offer-request/steps-offer-request/expiration-date-dialog/expiration-date-dialog.component";
import { Step1OfferComponent } from "./components/offer-request/steps-offer-request/step1-offer/step1-offer.component";
import { Step2OfferComponent } from "./components/offer-request/steps-offer-request/step2-offer/step2-offer.component";
import { Step3OfferComponent } from "./components/offer-request/steps-offer-request/step3-offer/step3-offer.component";
import { StepsOfferRequestComponent } from "./components/offer-request/steps-offer-request/steps-offer-request.component";
import { TableOfferComponent } from "./components/offer-request/steps-offer-request/table-offer/table-offer.component";
import { OfferResponseShipownerComponent } from "./components/offer-response-shipowner/offer-response-shipowner.component";
import { OperativeViewComponent } from "./components/operative-view/operative-view.component";
import { ConditionsComponent } from "./components/platform-view/conditions/conditions.component";
import { FeedbackViewComponent } from "./components/platform-view/feedback-view/feedback-view.component";
import { GenericConditionsViewComponent } from "./components/platform-view/generic-conditions-view/generic-conditions-view.component";
import { PlatformViewComponent } from "./components/platform-view/platform-view.component";
import { PrivacyPoliciesViewComponent } from "./components/platform-view/privacy-policies-view/privacy-policies-view.component";
import { TermsAndConditionsAcceptanceComponent } from "./components/platform-view/terms-and-conditions-acceptance/terms-and-conditions-acceptance.component";
import { TermsAndConditionsViewComponent } from "./components/platform-view/terms-and-conditions-view/terms-and-conditions-view.component";
import { PrivacyPoliciesComponent } from "./components/privacy-policies/privacy-policies.component";
import { CompanyDetailsComponent } from "./components/shared/company-details/company-details.component";
import { TableOffershipownerComponent } from "./components/shared/table-offershipowner/table-offershipowner.component";
import { ShipownerDataComponent } from "./components/shipowner-data/shipowner-data.component";
import { ShipownerFormComponent } from "./components/shipowner-data/shipowner-form/shipowner-form.component";
import { ShipownerListComponent } from "./components/shipowner-data/shipowner-list/shipowner-list.component";
import { TermsConditionsViewSimpleComponent } from "./components/terms-conditions-view-simple/terms-conditions-view-simple.component";
import { DocumentsComponent } from "./components/manteinance/documents/documents.component";
import { OveraAutocompleteModule } from "@overa/components/autocomplete";
import { CardModule } from "primeng/card";
import { ConfirmationService, MessageService } from "primeng/api";
import { AutoCompleteModule } from "primeng/autocomplete";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { ButtonModule } from "primeng/button";
import { Calendar, CalendarModule } from "primeng/calendar";
import { CheckboxModule } from "primeng/checkbox";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DialogModule } from "primeng/dialog";
import { DragDropModule } from "primeng/dragdrop";
import { DropdownModule } from "primeng/dropdown";
import { DialogService, DynamicDialogModule } from "primeng/dynamicdialog";
import { EditorModule } from "primeng/editor";
import { InplaceModule } from "primeng/inplace";
import { InputNumberModule } from "primeng/inputnumber";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { MenuModule } from "primeng/menu";
import { MultiSelectModule } from "primeng/multiselect";
import { StepsModule } from "primeng/steps";
import { TableModule } from "primeng/table";
import { TabViewModule } from "primeng/tabview";
import { ToastModule } from "primeng/toast";
import { AccordionModule } from "primeng/accordion";

import {
  NbLayoutModule,
  NbChatModule,
  NbButtonModule,
  NbSidebarModule,
  NbIconModule,
} from "@nebular/theme";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatListModule } from "@angular/material/list";
import { TooltipModule } from "primeng/tooltip";
import { TimelineModule } from "primeng/timeline";
import { ClhValidators } from "./utils/validators";
import { CanDeactivateGuard } from "./services/canDeactivateGuard.service";
import { ChatHelper } from "./utils/chat-helper.utils";
import { Utils } from "./utils/utils";
import { CLHGlobals } from "./utils/globals";
import { NgxSpinnerModule } from "ngx-spinner";
import { NotesComponent } from "./components/manteinance/notes/notes.component";
import { NgxEchartsModule } from "ngx-echarts";
import { ChooseUserService } from "./services/choose-user.service";
import { ApiErrorInterceptor } from "./interceptors/apiErrorInterceptor";
import { ConditionsInterceptor } from "./interceptors/conditions-interceptor";
import { AutocompleteModule } from "@overa/components/autocomplete-old";
import { NewChargerShipownerDialogComponent } from "./components/manteinance/shipowners/new-charger-shipowner-dialog/new-charger-shipowner-dialog.component";
import { NewListDialogComponent } from "./components/manteinance/shipowners/new-list-dialog/new-list-dialog.component";
import { AutoFocusModule } from "primeng/autofocus";
import { OveraMessagesService } from "@overa/components/info-dialog";

export function getBaseUrlFactory() {
  try {
    return document.getElementsByTagName("base")[0].href ?? "/";
  } catch {
    return "/";
  }
}

@NgModule({
  declarations: [
    LayoutComponent,

    KanbanViewComponent,
    KanbanElementComponent,
    OfferRequestComponent,
    HomeComponent,
    TabViewHomeComponent,
    MapComponent,
    DashboardComponent,
    StepsOfferRequestComponent,
    Step1OfferComponent,
    Step2OfferComponent,
    ContactComponent,
    ChargerDataSheetComponent,
    ChargerListComponent,
    ChargerFormComponent,
    ShipownerDataComponent,
    ShipownerFormComponent,
    ShipownerListComponent,
    Step3OfferComponent,
    TableOfferComponent,
    CommentsDialogComponent,
    OfferReceivedComponent,
    ClausesComponent,
    OfferResponseShipownerComponent,
    OfferComparativeComponent,
    OfferFirmBindingComponent,
    HistoriesOfferComponent,
    ButtonsOfferComponent,
    ExpirationDateDialogComponent,
    RoutesComponent,
    ChatRoomComponent,
    ShipownersComponent,
    ChooseUserComponent,
    ChargerShipownerContactComponent,
    NotesComponent,
    DocumentsComponent,
    CompanyDetailsComponent,
    ClhTextareaComponent,
    OperativeViewComponent,
    TableOffershipownerComponent,
    TermsAndConditionsViewComponent,
    FeedbackViewComponent,
    PlatformViewComponent,
    TermsAndConditionsAcceptanceComponent,
    PrivacyPoliciesViewComponent,
    PrivacyPoliciesComponent,
    TermsConditionsViewSimpleComponent,
    ConditionsComponent,
    GenericConditionsViewComponent,
    NewChargerShipownerDialogComponent,
    NewListDialogComponent
  ],

  imports: [
    InitModule.setConfig(AppConfig),
    CommonModule,
    BrowserModule,
    CardModule,
    AutoFocusModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    OveraModule,
    PrimeNgModule,
    OveraEllipsisModule,
    NgxEchartsModule,
    FileuploadModule,
    FileuploadMultipleModule,
    OveraAutocompleteModule,
    VendorModule,
    NbLayoutModule,
    NbChatModule,
    NbIconModule,
    NbButtonModule,
    NgxSpinnerModule,
    NbSidebarModule.forRoot(),
    NbIconModule,
    OveraTranslateModule.setConfig("en", []),
    NgxExtendedPdfViewerModule,
    ConfirmDialogModule,
    InputSwitchModule,
    MultiSelectModule,
    CheckboxModule,
    SafePipeModule,
    MatInputModule,
    MatFormFieldModule,
    DialogModule,
    EditorModule,
    MatListModule,
    NgOptimizedImage,
    MatAutocompleteModule,
    DragDropModule,
    AutoCompleteModule,
    OveraAutocompleteModule,
    CommonModule,
    InplaceModule,
    DynamicDialogModule,
    FlexLayoutModule,
    AccordionModule,
    TooltipModule,
    HttpClientJsonpModule,
    TimelineModule,
    TabViewModule,
    TableModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    DropdownModule,
    DynamicDialogModule,
    EditorModule,
    InputNumberModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    MenuModule,
    StepsModule,
    ToastModule,
    AutocompleteModule,


    OveraAppRoutingModule.forRoot(MenuSettings.MENU.routes ?? []),

    ToastrModule.forRoot({
      positionClass: "toast-bottom-right",
      timeOut: 5000,
      enableHtml: true,
      preventDuplicates: true,
    }),

    OAuthModule.forRoot(),

    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    // The APP_BASE_HREF provider is defined here in the AppModule to ensure that Angular's routing system
    // correctly recognizes the application's base path for all navigation and routing operations.
    // Defining APP_BASE_HREF at the module level, rather than in the main.ts, guarantees that it is properly
    // utilized by the RouterModule and any lazy-loaded modules, thus avoiding routing issues where Angular
    // might not match routes correctly if the base path is not explicitly set. This approach aligns with best
    // practices for Angular application setup, especially in cases where the application might be served from
    // a subdirectory or require different base paths in different environments.

    { provide: APP_BASE_HREF, useFactory: getBaseUrlFactory },

    { provide: GLOBALS_TOKEN, useClass: CLHGlobals },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UserInterceptor, multi: true },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ErrorHandlerInterceptor,
    //   multi: true,
    // },
    { provide: HTTP_INTERCEPTORS, useClass: ApiErrorInterceptor, multi: true },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ConditionsInterceptor,
    //   multi: true,
    // },
    { provide: LOCALE_ID, useValue: "en" },
    DialogService,
    ToastrService,
    StorageService,
    ClhValidators,
    ConfirmationService,
    CanDeactivateGuard,
    Utils,
    CLHGlobals,
    Filters,
    ChooseUserService,
    ChatHelper,
    TranslateService,
    MessageService,
  ],
  bootstrap: [OveraAppComponent],
  entryComponents: [],
})
export class AppModule {
  constructor(private translate: NgxTranslateService) {
    this.translate.setTranslations();
    if (!localStorage.getItem("locale")) {
      this.translate.use("en");
    }
  }
}
