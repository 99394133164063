<p-confirmDialog styleClass="containerConfirmDialog" acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"></p-confirmDialog>
<ngx-spinner name="termsSpinner"></ngx-spinner>
<div class="container-fluid">
  <div class="card cardTermsConditionsList">
    <div class="card-header">
      <div class="card-text">
        <div class="row">
          <div class="col align-self-start">
            <h4 card-title *ngIf="type==='terms'" titleTermConditions>{{ 'TERMS_CONDITIONS.TERMS_CONDITIONS' |
              translate}}</h4>
            <h4 *ngIf="type==='policy'" titleTermConditions class="card-title">{{ 'PRIVACY_POLICIES.PRIVACY_POLICIES' |
              translate}}</h4>
          </div>
          <div class="col">
            <div class="d-flex justify-content-end">
              <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded addButtonRounded"
                (click)="addRow()" [pTooltip]="'ACTION.ADD'| translate" tooltipPosition="bottom"
                tooltipStyleClass="tooltipCLH" *ngIf="!isNewTerm && !isEditting && !readonly"></button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p-table *ngIf="showTable" [columns]="columns" [value]="terms" styleClass="p-datatable-striped listTableTerms"
      [globalFilterFields]="globalFilterFields" [responsive]="true" responsiveLayout="stack" breakpoint="960px"
      [autoLayout]="true" [paginator]="true" [rows]="10"
      currentPageReportTemplate="{{'PRIMENGCALENDAR.showingRecords' | translate}}" [rowsPerPageOptions]="[10,30,50]"
      [showCurrentPageReport]="true" paginatorDropdownAppendTo="body" sortField="validityDate" [sortOrder]="-1">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th overaellipsis *ngFor="let col of columns" [ngClass]="'th-' +col.sort" class="line-border-right">{{col.id |
            translate}}</th>
        </tr>
        <tr>
          <th *ngFor="let col of columns" class="filterTh table-filters">
            <p-columnFilter *ngIf="col.sort === 'validityDate'" type="date" field="{{col.sort}}" [showMenu]="true"
              [showMatchModes]="true" #colFilter>
              <ng-template pTemplate="filter" let-filter="filterCallback">
                <ng-container [ngSwitch]="$any(colFilter.dt.filters[col.sort]).matchMode">
                  <p-calendar [(ngModel)]="filterDate"
                    (onSelect)="onFilterDate($event, $any(colFilter.dt.filters[col.sort]).matchMode)" type="date"
                    appendTo="body"></p-calendar>
                </ng-container>
              </ng-template>
            </p-columnFilter>
            <p-columnFilter *ngIf="col.sort === 'terms' || col.sort === 'policy'" type="text" field="{{col.sort}}"
              class="colFilterTerm"></p-columnFilter>
            <p-columnFilter *ngIf="col.sort === 'termsEN' || col.sort === 'policyEN'" type="text" field="{{col.sort}}"
              class="colFilterTerm"></p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-term>
        <ng-container *ngIf="term.isNew else other">
          <tr *ngIf="isNewTerm" class="campo">
            <td>
              <div [formGroup]="form">
                <p-calendar formControlName="newDatePicker" appendTo="body"></p-calendar>
              </div>
            </td>
            <td></td>
            <td>
              <clh-textarea overaellipsis [form]="form" [nameControl]="'newTermTextarea'" [style]="'width:100%'"
                [errorLabel]="'MESSAGES.ERROR.TERMS_NOT_NULL'" [placeholder]="'COMMONS.DESCRIPTION'"
                [idTextarea]="'textAreaNewTermMaintenance'" [classTextarea]="'clhTextarea'" [required]="true"
                [showLabelFloat]="false"></clh-textarea>
            </td>
            <td>
              <clh-textarea overaellipsis [form]="form" [nameControl]="'newTermENTextarea'" [style]="'width:100%'"
                [errorLabel]="'MESSAGES.ERROR.TERMS_NOT_NULL'" [placeholder]="'COMMONS.DESCRIPTION'"
                [idTextarea]="'textAreaNewTermENMaintenance'" [classTextarea]="'clhTextarea'" [required]="true"
                [showLabelFloat]="false"></clh-textarea>
            </td>
            <td>
              <overa-file-upload [form]="form" [storageId]="storageId" [id]="'newDocES'" [controlName]="'newDocES'"
                [style]="'width:100%'" [errorLabel]="'MESSAGES.ERROR.TERMS_NOT_NULL'"
                [placeholder]="'COMMONS.FILE_ES' | translate" [required]="true" [showLabelFloat]="false"
                accept=".pdf"></overa-file-upload>
            </td>
            <td>
              <overa-file-upload [form]="form" [storageId]="storageId" [controlName]="'newDocEN'" [id]="'newDocEN'"
                [style]="'width:100%'" [errorLabel]="'MESSAGES.ERROR.TERMS_NOT_NULL'"
                [placeholder]="'COMMONS.FILE_EN' | translate" [required]="true" [showLabelFloat]="false"
                accept=".pdf"></overa-file-upload>
            </td>
            <td style="width: 15%"> 
              <div class="d-flex flex-row justify-content-start align-items-center">
                <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded saveButtonRounded"
                  *ngIf="(form.get('newTermTextarea').value !== '' && 
                  !form.get('newTermTextarea').hasError('required'))
                   && (form.get('newTermENTextarea').value !== '' 
                  && !form.get('newTermENTextarea').hasError('required')) && 
                  !form.get('newDocEN').hasError('required') && !form.get('newDocES').hasError('required')"
                  (click)="saveTerm()" [pTooltip]="'ACTION.SAVE'| translate" tooltipPosition="bottom"
                  tooltipStyleClass="tooltipCLH" style="margin-left: 15%"></button>
                <button pButton pRipple type="button" icon="pi pi-times" [pTooltip]="'ACTION.CANCEL'| translate"
                  tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" class="p-button-rounded cancelButtonRounded"
                  (click)="cancelEditTerm()" style="margin-left: 15%"></button>
              </div>
            </td>
          </tr>
        </ng-container>
        <ng-template #other>
          <tr class="campo" (dblclick)="selectTerm(term)">
            <ng-container *ngFor="let col of columns">
              <td overaellipsis
                *ngIf="col.sort !== 'validityDate' && col.sort !== 'sendDate' && col.sort !== 'terms'
                && col.sort !== 'policy'  && col.sort !== 'policyEN' && col.sort !== 'termsEN' && col.sort !== 'modify' && col.sort !== 'docEs' && col.sort !== 'docEn'"
                class="{{col.sort}}">
                <span class="p-column-title">{{col.id | translate}}</span>{{term[col.sort]}}
              </td>
              <td *ngIf="col.sort === 'validityDate' || col.sort === 'sendDate'" style="width: 10%">
                <span class="p-column-title">{{col.id | translate}}</span>
                <ng-container *ngIf="term.isEdit && col.sort === 'validityDate' else notIsEdit">
                  <div [formGroup]="form">
                    <p-calendar formControlName="{{term.id}}Datepicker" appendTo="body"></p-calendar>
                  </div>
                </ng-container>
                <ng-template #notIsEdit>
                  <ng-container *ngIf="col.sort === 'validityDate'">{{term[col.sort] | date:
                    'dd/MM/yyyy'}}</ng-container>
                  <ng-container *ngIf="col.sort === 'sendDate'">{{term[col.sort] | date: 'dd/MM/yyyy
                    HH:mm'}}</ng-container>
                </ng-template>
              </td>
              <td *ngIf="col.sort === 'terms'  || col.sort === 'policy'">
                <span class="p-column-title">{{col.id | translate}}</span>
                <!-- <div class="container-terms">{{term[col.sort]}}</div> -->
                <clh-textarea [form]="form" [nameControl]="term.id+'Textarea'"
                  [errorLabel]="'MESSAGES.ERROR.CLAUSES_NOT_NULL'" [idTextarea]="term.id" [placeholder]=""
                  [readOnly]="!term.isEdit" [classTextarea]="'textAreaTermEdit clhTextarea'" [required]="true"
                  [style]="'width:100%'" [showLabelFloat]="false"></clh-textarea>
              </td>
              <td *ngIf="col.sort === 'termsEN' || col.sort === 'policyEN'">
                <span class="p-column-title">{{col.id | translate}}</span>
                <clh-textarea [form]="form" [nameControl]="term.id+'ENTextarea'"
                  [errorLabel]="'MESSAGES.ERROR.CLAUSES_NOT_NULL'" [idTextarea]="term.id + 'EN'" [placeholder]=""
                  [readOnly]="!term.isEdit" [classTextarea]="'textAreaTermEdit clhTextarea'" [required]="true"
                  [style]="'width:100%'" [showLabelFloat]="false"></clh-textarea>
              </td>

              <td *ngIf="col.sort === 'docEs'" class="docEs" >
                <overa-file-upload [storageId]="storageId" [id]="term.id+'docEs'" [form]="form"
                  [fileData]="term.fileDataES" [controlName]="term.id+'docEs'" [style]="'width:100%'"
                  [errorLabel]="'MESSAGES.ERROR.TERMS_NOT_NULL'" [placeholder]="'COMMONS.FILE_ES' | translate"
                  [idTextarea]="'textAreaNewTermENMaintenance'" [classTextarea]="'clhTextarea'" [required]="true"
                  [readonly]="!term.isEdit" [showLabelFloat]="false" accept=".pdf"></overa-file-upload>
              </td>
              <td *ngIf="col.sort === 'docEn'" class="docEn">
                <overa-file-upload [storageId]="storageId" [id]="term.id+'docEn'" [form]="form"
                  [fileData]="term.fileDataEN" [controlName]="term.id+'docEn'" [style]="'width:100%'"
                  [errorLabel]="'MESSAGES.ERROR.TERMS_NOT_NULL'" [placeholder]="'COMMONS.FILE_EN' | translate"
                  [idTextarea]="'textAreaNewTermENMaintenance'" [classTextarea]="'clhTextarea'" [required]="true"
                  [readonly]="!term.isEdit" [showLabelFloat]="false" accept=".pdf"></overa-file-upload>
              </td>
              <td *ngIf="col.sort === 'modify' " class="modify"> 
                <div class="d-flex flex-row justify-content-start align-items-center" *ngIf="!isNewTerm && !isEditting">
                  <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded editButtonRounded"
                    (click)="editTerm(term)" [disabled]="term.sendDate"></button>
                  <button pButton pRipple type="button" icon="pi pi-send" class="p-button-rounded editButtonRounded"
                    (click)="sendTerm(term)" *ngIf="!term.sendDate"></button>
                  <button pButton pRipple type="button" icon="pi pi-trash" [pTooltip]="'ACTION.DELETE'| translate"
                    tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" class="p-button-rounded deleteButtonRounded"
                    (click)="deleteTerm(term)" [disabled]="term.sendDate" style="margin-left: 15%"></button>
                </div>
                <div class="d-flex flex-row justify-content-start align-items-center"
                  *ngIf="!isNewTerm && isEditting && term.isEdit">
                  <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded saveButtonRounded"
                    *ngIf="(form.get(term.nameControl).value && !form.get(term.nameControl).hasError('required')) && (form.get(term.nameControlEN).value && !form.get(term.nameControlEN).hasError('required'))"
                    (click)="saveTerm(term)" [pTooltip]="'ACTION.SAVE'| translate" tooltipPosition="bottom"
                    tooltipStyleClass="tooltipCLH" tyle="margin-left: 15%"></button>
                  <button pButton pRipple type="button" icon="pi pi-times" [pTooltip]="'ACTION.CANCEL'| translate"
                    tooltipPosition="bottom" tooltipStyleClass="tooltipCLH" class="p-button-rounded cancelButtonRounded"
                    (click)="cancelEditTerm(term)" style="margin-left: 15%"></button>
                </div>
              </td>
            </ng-container>
          </tr>
        </ng-template>
      </ng-template>
    </p-table>

    <div class="containerButtonStep1">
      <div class="div-btn-cancel">
        <div>
          <button pButton type="button" icon="pi pi-home" iconPos="left" class="p-button home custom-button p-ripple"
            routerLink="/home"></button>
        </div>
      </div>
    </div>
  </div>
</div>