import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, ReplaySubject } from "rxjs";
import { ConfigsService } from "@overa/shared";
import { ChatGroup } from "../models/chat/chatGroup.model";
import { CLHBaseService } from "./clh-base.service";

@Injectable({ providedIn: "root" })
export class ChatGroupService extends CLHBaseService {
  private chatGroupSubject: ReplaySubject<boolean> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public chatGroupObservable: Observable<boolean> =
    this.chatGroupSubject.asObservable();

  private routechatGroupSubject: ReplaySubject<string> = new ReplaySubject(1);
  public routechatGroupObservable: Observable<string> =
    this.routechatGroupSubject.asObservable();

  get(pageIndex = -1, pageSize = -1) {
    return this.http.get<ChatGroup[]>(
      `${this.baseUrl}/chatGroup?pageIndex=${pageIndex}&pageSize=${pageSize}`
    );
  }

  getById(id: string) {
    return this.http.get<ChatGroup>(`${this.baseUrl}/chatGroup/${id}`);
  }

  add(chatGroup: ChatGroup) {
    const endpointUrl = `${this.baseUrl}/chatGroup`;
    return this.http.post<ChatGroup>(endpointUrl, chatGroup);
  }

  edit(chatGroup: ChatGroup) {
    const endpointUrl = `${this.baseUrl}/chatGroup`;
    return this.http.put<ChatGroup>(endpointUrl, chatGroup);
  }

  delete(id: string) {
    const endpointUrl = `${this.baseUrl}/chatGroup/${id}`;
    return this.http.delete(endpointUrl);
  }
  getGroupByName(groupName: string) {
    return this.http.get<ChatGroup>(
      `${this.baseUrl}/chatGroup/getByName/${groupName}`
    );
  }
  getByNameToCharterer(groupName: string) {
    return this.http.get<ChatGroup>(
      `${this.baseUrl}/chatGroup/getByNameToCharterer/${groupName}`
    );
  }
}
