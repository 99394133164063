import { HttpClient, HttpClientModule } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";

import { Document } from "../models/document.model";
import { FileElement } from "../models/fileelement.model";
import { CLHBaseService } from "./clh-base.service";

@Injectable({ providedIn: "root" })
export class DocumentService extends CLHBaseService {
  private filemanagerApiUrl: string = "";

  public isDebug: boolean = false;
  public isTest: boolean = false;
  constructor(http: HttpClient) {
    super(http);
    this.filemanagerApiUrl =
      this.config.getModuleConfig("overa-security", "apiBaseUrl") + "/api";
  }

  public addDocument(
    file: FileElement,
    rootId: string,
    key: string,
    isPublic: boolean,
    entityOwnerId: string
  ): Observable<Document> {
    var document = new Document(file);
    document.entityId = rootId;
    document.entityKey = key;
    document.isPublic = isPublic;
    document.groupId = entityOwnerId;
    return this.http.post<Document>(
      `${this.filemanagerApiUrl}/Document`,
      document
    );
  }

  public deleteDocument(id: string) {
    return this.http.delete<Document>(
      `${this.filemanagerApiUrl}/Document/${id}`
    );
  }

  public getRootDocuments(
    rootId: string,
    key: string,
    entityOwnerId: string
  ): Observable<Document[]> {
    if (!entityOwnerId) {
      return this.http.get<Document[]>(
        `${this.filemanagerApiUrl}/Document/Root/${rootId}/${key}`
      );
    } else {
      return this.http.get<Document[]>(
        `${this.filemanagerApiUrl}/Document/Root/Owner/${rootId}/${key}/${entityOwnerId}`
      );
    }
  }
}
