import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DocumentService } from "../../../services/document.service";
import { CLHGlobals } from "src/app/utils/globals";

@Component({
  selector: "app-documents",
  templateUrl: "./documents.component.html",
  styleUrls: ["./documents.component.css"],
})
export class DocumentsComponent implements OnInit {
  @Input()
  form?: FormGroup;
  //@Input()
  //fileData: any[];
  storageId: string = "";
  @Input()
  entityId!: string;
  @Input()
  groupId!: string;
  fileList: any[] = [];
  @Input()
  placeholder?: string;
  @Input()
  readonly: boolean = false;
  fileData: string[] | null = null;
  constructor(
    private filemanagerService: DocumentService,
    private globals: CLHGlobals
  ) { }

  ngOnInit(): void {
    this.storageId = this.globals.storageId;
    this.filemanagerService
      .getRootDocuments(this.entityId, "offer", this.groupId)
      .subscribe({
        next: (documents) => {
          console.log(documents);

          this.fileData = [];

          documents.forEach((doc) => {
            // this.fileData.push({
            //   guid: doc.documentGuid,
            //   id: doc.documentGuid,
            //   name: doc.name,
            //   documentId: doc.id,
            // });
            this.fileData.push(doc.documentGuid);
            this.fileList.push({
              guid: doc.documentGuid,
              id: doc.documentGuid,
              name: doc.name,
              documentId: doc.id,
            })
          });
          //this.fileList = [...this.fileData];
        },
        error: (err) => {
          console.error(err);
        },
      });
  }
  onAddFile(event: any) {
    var file = event.file;
    console.log(file);
    file.documentName = file.displayName;
    file.name = file.originalName;
    file.documentId = file.id;

    this.filemanagerService
      .addDocument(file, this.entityId, "offer", true, this.groupId)
      .subscribe({
        next: (doc) => {
          console.log(doc);
          this.fileList.push({
            guid: doc.documentGuid,
            id: doc.documentGuid,
            name: doc.name,
            documentId: doc.id,
          });
        },
        error: (err) => {
          console.error(err);
        },
      });
  }
  onDeleteFile(file: any) {
    var document = this.fileList.find((f) => f.guid == file.id);
    var documentId = document.documentId;
    this.filemanagerService.deleteDocument(documentId).subscribe({
      next: (file) => {
        console.log(file);
        this.fileList.splice(this.fileList.indexOf(document), 1);
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
}
