<div class="div-history" *ngIf="histories && histories.length">
  <ng-container *ngIf="!isClause; else containerClause">
    <div class="div-lines">
      <div *ngFor="let line of histories" class="line-history" [ngClass]="line.haveChange ? 'haveChangeOrange' : ''">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12"><span>{{line.userName}}</span></div>
            <div class="col-lg-2 col-md-2 col-sm-6 col-xs-12"><span>{{line.date | date:'dd/MM/yy HH:mm'}}</span></div>
            <div class="col-lg-5 col-md-4 col-sm-6 col-xs-12"><span class="line-break">{{line.value}}</span></div>
            <div class="col-lg-1 col-md-1 col-sm-6 col-xs-12"><span>{{line.statusOffershipowner | translate}}</span></div>
            <div class="col-lg-2 col-md-2 col-sm-6 col-xs-12"><span class="span-status">{{line.status || ''}}</span></div>
          </div>
        </div>

        <!--
         contemplar si se reabreu, poñer o candado e mostrar dialogo confirmación
        <span>{{line.userName}}</span>
        <span>{{line.date | date:'dd/MM/yy HH:mm'}}</span>
        <span>{{line.value}}</span>
        <span class="span-status">{{line.status || ''}}</span>
        -->
      </div>
    </div>
  </ng-container>
  <ng-template #containerClause>
    <div class="div-lines" *ngIf="loadedHistories">
      <div *ngFor="let line of histories" class="line-history" [ngClass]="line.haveChange ? 'haveChangeOrange' : ''">
        <!--
        <span>{{line.userName}}</span>
        <span>{{line.date | date:'dd/MM/yy HH:mm'}}</span>
        <span [ngClass]="line.isRefused ? 'crossed' : '' ">{{line.value}}</span>
        <span class="span-status">{{line.status || ''}}</span>
        -->

        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12"><span>{{line.userName}}</span></div>
            <div class="col-lg-2 col-md-2 col-sm-6 col-xs-12"><span>{{line.date | date:'dd/MM/yy HH:mm'}}</span></div>
            <div class="col-lg-5 col-md-4 col-sm-6 col-xs-12">
              <span [ngClass]="line.isRefused ? 'crossed line-break' : 'line-break' ">{{line.value}}</span>
            </div>
            <div class="col-lg-1 col-md-1 col-sm-6 col-xs-12"><span>{{line.statusOffershipowner | translate}}</span></div>
            <div class="col-lg-2 col-md-2 col-sm-6 col-xs-12"><span class="span-status">{{line.status || ''}}</span></div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
