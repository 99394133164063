import {
  ValidatorFn,
  ValidationErrors,
  AbstractControl,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { Injectable } from "@angular/core";
import { CatalogueType } from "src/app/models/enum/catalogue-type";
import { FreightTargetCurrency } from "../models/enum/freight-target-currency.model";
import { CLHGlobals } from "./globals";

export const EmailValidation = Validators.pattern(
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
);
export const PhoneValidation = Validators.pattern(
  /^\+?(?:[0-9] ?){6,14}[0-9]$/
);
export const WebValidation = Validators.pattern(
  /^(www.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/
);
export const CifValidation = Validators.pattern(
  /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/
);
export const AccountValidation = Validators.pattern(
  /[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[a-zA-Z0-9]{7,23}$/
);

@Injectable()
export class ClhValidators {
  constructor(private globals: CLHGlobals) {}

  public myRegexValidator(type: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const form = control as FormGroup;
      let regex: RegExp = new RegExp("");
      let key: string = "";

      if (type === "web") {
        regex =
          /^(www.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
        key = "web";
      }
      if (type === "cif") {
        regex = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
        key = "cif";
      }
      if (type === "email") {
        regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        key = "email";
      }
      if (type === "phone") {
        regex = /^\+?(?:[0-9] ?){6,14}[0-9]$/;
        key = "phone";
      }
      if (type === "account") {
        regex = /[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[a-zA-Z0-9]{7,23}$/;
        key = "account";
        if (!control.value) {
          return null;
        }
      }
      const isValid = regex.test(control.value);
      return isValid ? null : { [key]: { value: control.value } };
    };
  }
  public portsValidator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    let notInList = true;
    if (!control?.value || !control?.value?.length) {
      notInList = false;
    }
    const allPorts = this.globals.catalogue
      .filter((x) => x.type == CatalogueType.PORT)
      .map((x) => x.value);
    if (
      control &&
      control.value &&
      control.value.length &&
      Array.isArray(control.value) &&
      control.value.every((v) => allPorts.includes(v))
    ) {
      notInList = false;
    }
    return notInList ? { notInList: { value: control.value } } : null;
  };

  public rangeValidator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    let invalid = true;
    // const control = form && form.get("rangeDates");
    if (
      control &&
      control.value &&
      control.value.length == 2 &&
      control.value.every((element: any) => element != null)
    ) {
      invalid = false;
    }
    return invalid ? { errorDates: true } : null;
  };
  public expirationDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const form = control as FormGroup;
      if (form) {
        const checked: boolean = form.get("checkedExpirationDate")?.value;

        if (!checked) {
          return null;
        } else {
          const date: Date = form.get("expirationDate")?.value;
          let isValid = false;
          if (date?.getTime()) {
            if (date.getTime() > new Date().getTime()) {
              isValid = true;
            }
          }

          return isValid ? null : { expirationDate: true };
        }
        return null;
      } else {
        return { expirationDate: true };
      }
    };
  }
  public validateMatch(
    controlName: string,
    matchingControl: string
  ): ValidatorFn {
    return (control: AbstractControl): any | null => {
      const form = control as FormGroup;
      if (form) {
        if (controlName === "demurrageCurrency") {
          let demurrage = form.get(controlName)?.value;
          let flete = form.get(matchingControl)?.value;

          if (!demurrage || !flete) {
            return null;
          } else {
            if (
              FreightTargetCurrency[demurrage] !== FreightTargetCurrency[flete]
            ) {
              return { isMatch: true };
            } else {
              return null;
            }
          }
        }
      } else {
        return { isMatch: true };
      }
    };
  }

  public checkGreatherThan(
    controlName: string,
    matchingControl: string
  ): ValidatorFn {
    return (control: AbstractControl): any | null => {
      const form = control as FormGroup;
      if (form) {
        if (controlName === "vesselRangeTo") {
          let vesselRangeTo = form.get(controlName)?.value;
          let vesselRangeFrom = form.get(matchingControl)?.value;
          if (!vesselRangeTo || !vesselRangeFrom) {
            if (!vesselRangeTo && vesselRangeFrom) {
              return { isGreatherThan: true };
            } else {
              return null;
            }
          } else {
            if (parseFloat(vesselRangeTo) < parseFloat(vesselRangeFrom)) {
              return { isGreatherThan: true };
            } else {
              return null;
            }
          }
        }
      } else {
        return { isGreatherThan: true };
      }
    };
  }
}

export function expirationDateValidator2(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const form = control as FormGroup;
    const checked: boolean = form.get("checkedExpirationDate")?.value;

    if (!checked) {
      return null;
    } else {
      const date: Date = form.get("expirationDate")?.value;
      let isValid = false;
      if (date.getTime() > new Date().getTime()) {
        isValid = true;
      }

      return isValid ? null : { expirationDate: true };
    }
    return null;
  };
}
