<div class="tabView-content">
  <div class="container-fluid">
    <p-tabView class="tabHome">
      <p-tabPanel header="{{'VIEWS.TABVIEW_HOME.HEADER_MAP' | translate}}">
        <div class="row">
          <div [ngClass]="selectedKanban ? 'col-9': 'col-12'">
            <app-map [markers]="markers" [offerShipownerId]="selectedKanban?.offerShipOwnerId" [allImos]="imos"></app-map>
          </div>
          <div class="col-3" *ngIf="selectedKanban">
            <div class="kanban-detail-view">
              <app-kanban-element
                [cssClass]="'c1-border'"
                [backgroundClass]="'c1-background'"
                [taskList]="[selectedKanban]"
                (onUnSelectKanban)="unSelectKanban($event)"
                [isDetail]="true"
              ></app-kanban-element>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <!--<p-tabPanel header="{{'VIEWS.TABVIEW_HOME.HEADER_DASHBOARD' | translate}}">

                <app-dashboard></app-dashboard>
            </p-tabPanel>-->
    </p-tabView>
  </div>
</div>
