import { inject, Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { MailDTO } from "../models/mail-templates/mail-dto.model";
import { AddresseeType } from "../models/enum/addresseeType.model";
import { ResponseOfferMail } from "../models/mail-templates/response-offer-mail.model";
import { CLHBaseService } from "./clh-base.service";
import { CLHGlobals } from "../utils/globals";

@Injectable({ providedIn: "root" })
export class MailTemplateService extends CLHBaseService {
  // private mailTemplateSubject: ReplaySubject<boolean> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  // public mailTemplateObservable: Observable<boolean> =
  //   this.mailTemplateSubject.asObservable();

  // private routeMailTemplateSubject: ReplaySubject<string> = new ReplaySubject(
  //   1
  // );
  // public routeMailTemplateObservable: Observable<string> =
  //   this.routeMailTemplateSubject.asObservable();

  globals = inject(CLHGlobals);

  sendMail(mailDTO: MailDTO) {
    if (mailDTO.addresseeType === AddresseeType.ADMIN) {
      mailDTO.cLHUsers = [];
      const filteredUsers = [...this.globals.clhUsers];
      mailDTO.cLHUsers = filteredUsers
        .filter((item) => item.id !== null)
        .map((item) => item.id.toString());
    }
    const endpointUrl = `${this.baseUrl}/mailTemplate/sendmail`;
    return this.http.post(endpointUrl, mailDTO);
  }
  //endpoint que sirve para enviar un email (teniendo una plantilla, un userId y un email de envio)
  sendOfferResponseMail(responseOfferMail: ResponseOfferMail) {
    const endpointUrl = `${this.baseUrl}/mailTemplate/offerResponse`;
    return this.http.post(endpointUrl, responseOfferMail);
  }
}
