import {
  DefaultRedirectGuard,
  PermissionsGuard,
  MenuDefinition,
} from "@overa/base";
import { AuthGuard } from "@overa/security";

import { ChargerDataSheetComponent } from "./components/charger-data-sheet/charger-data-sheet.component";
import { ChargerFormComponent } from "./components/charger-data-sheet/charger-form/charger-form.component";
import { HomeComponent } from "./components/home/home.component";
import { LayoutComponent } from "./components/layout.component";
import { OfferRequestComponent } from "./components/offer-request/offer-request.component";
import { Step1OfferComponent } from "./components/offer-request/steps-offer-request/step1-offer/step1-offer.component";
import { Step2OfferComponent } from "./components/offer-request/steps-offer-request/step2-offer/step2-offer.component";
import { ShipownerDataComponent } from "./components/shipowner-data/shipowner-data.component";
import { ShipownerFormComponent } from "./components/shipowner-data/shipowner-form/shipowner-form.component";
import { Step3OfferComponent } from "./components/offer-request/steps-offer-request/step3-offer/step3-offer.component";
import { OfferReceivedComponent } from "./components/offer-received/offer-received.component";
import { OfferResponseShipownerComponent } from "./components/offer-response-shipowner/offer-response-shipowner.component";
import { OfferComparativeComponent } from "./components/offer-comparative/offer-comparative.component";
import { OfferFirmBindingComponent } from "./components/offer-firm-binding/offer-firm-binding.component";
import { CanDeactivateGuard } from "./services/canDeactivateGuard.service";
import { ChooseUserComponent } from "./components/choose-user/choose-user.component";
import { CompanyDetailsComponent } from "./components/shared/company-details/company-details.component";
import { NotesComponent } from "./components/manteinance/notes/notes.component";
import { RoutesComponent } from "./components/charger-data-sheet/routes/routes.component";
import { ClausesComponent } from "./components/manteinance/clauses/clauses.component";
import { PermissionsShipownerGuard } from "./utils/permissions-shipowner-guard";
import { ShipownersComponent } from "./components/manteinance/shipowners/shipowners.component";
import { OperativeViewComponent } from "./components/operative-view/operative-view.component";
import { PlatformViewComponent } from "./components/platform-view/platform-view.component";
import { TermsAndConditionsViewComponent } from "./components/platform-view/terms-and-conditions-view/terms-and-conditions-view.component";
import { FeedbackViewComponent } from "./components/platform-view/feedback-view/feedback-view.component";
import { ContactComponent } from "./components/manteinance/contact/contact.component";
import { PrivacyPoliciesViewComponent } from "./components/platform-view/privacy-policies-view/privacy-policies-view.component";
import { PrivacyPoliciesComponent } from "./components/privacy-policies/privacy-policies.component";
import { TermsConditionsViewSimpleComponent } from "./components/terms-conditions-view-simple/terms-conditions-view-simple.component";
import { inject } from "@angular/core";
import { AppService } from "./services/app.service";
import { PermissionsGuardClh } from "./utils/permissions-guard";

export class MenuSettings {
  public static MENU: MenuDefinition = {
    routes: [
      {
        path: "",
        pathMatch: "prefix",
        component: LayoutComponent,
        canActivate: [DefaultRedirectGuard],

        canActivateChild: [AuthGuard],
        resolve: { data: () => inject(AppService).getData() },

        children: [
          {
            path: "core",
            loadChildren: () =>
              import(
                /* webpackChunkName: "menusettings-overa-base" */
                "@overa/base"
              ).then((m) => m.LibRoutingModule),
          },
          {
            path: "firmOffer",
            component: OfferRequestComponent,
            canActivate: [PermissionsGuard, PermissionsGuardClh],
            data: {
              breadcrumb: "BREADCRUMB.OFFER",
              permissions: [
                "charterer.negotiator",
                "charterer.operator",
                "charterer.administrator",
                "CLH Admin",
              ],
              editRoles: ["charterer.negotiator", "CLH Admin"],
            },
            children: [
              {
                path: "cover/:id",
                canDeactivate: [CanDeactivateGuard],
                canActivate: [PermissionsGuard, PermissionsGuardClh],
                component: Step1OfferComponent,
                pathMatch: "full",
                data: {
                  breadcrumb: "BREADCRUMB.COVER_INFORMATION",
                  permissions: [
                    "charterer.negotiator",
                    "charterer.operator",
                    "charterer.administrator",
                    "CLH Admin",
                  ],
                  editRoles: ["charterer.negotiator", "CLH Admin"],
                },
              },
              {
                path: "shipownersSelector/:id",
                component: Step2OfferComponent,
                canActivate: [PermissionsGuard, PermissionsGuardClh],
                pathMatch: "full",
                data: {
                  breadcrumb: "BREADCRUMB.SELECT_SHIPOWNERS",
                  permissions: [
                    "charterer.negotiator",
                    "charterer.operator",
                    "charterer.administrator",
                    "CLH Admin",
                  ],
                  editRoles: ["charterer.negotiator", "CLH Admin"],
                },
              },
              {
                path: "offerFinalView/:id",
                component: Step3OfferComponent,
                canActivate: [PermissionsGuard, PermissionsGuardClh],
                pathMatch: "full",
                data: {
                  breadcrumb: "BREADCRUMB.REVIEW_OFFER",
                  permissions: [
                    "charterer.negotiator",
                    "charterer.operator",
                    "charterer.administrator",
                    "CLH Admin",
                  ],
                  editRoles: ["charterer.negotiator", "CLH Admin"],
                },
              },
            ],
          },
          {
            path: "offerReceived/:id",
            component: OfferReceivedComponent,
            canActivate: [PermissionsGuard, PermissionsShipownerGuard],
            pathMatch: "full",
            data: {
              breadcrumb: "BREADCRUMB.OFFERS_RECEIVED",
              permissions: [
                "shipowner.negotiator",
                "shipowner.operator",
                "shipowner.administrator",
                "CLH Admin",
              ],
              editRoles: ["shipowner.negotiator", "CLH Admin"],
            },
          },
          {
            path: "offerResponse/:id",
            component: OfferResponseShipownerComponent,
            canActivate: [PermissionsGuard, PermissionsShipownerGuard],
            pathMatch: "full",
            data: {
              breadcrumb: "BREADCRUMB.OFFER_INDICATION",
              permissions: [
                "shipowner.negotiator",
                "shipowner.operator",
                "shipowner.administrator",
                "CLH Admin",
              ],
              editRoles: ["shipowner.negotiator", "CLH Admin"],
            },
          },
          {
            path: "offerComparative/:id",
            component: OfferComparativeComponent,
            pathMatch: "full",
            canActivate: [PermissionsGuard, PermissionsGuardClh],
            data: {
              breadcrumb: "BREADCRUMB.COMPARATIVE",
              permissions: [
                "charterer.negotiator",
                "charterer.administrator",
                "charterer.operator",
                "CLH Admin",
              ],
              editRoles: ["charterer.negotiator", "CLH Admin"],
            },
          },
          {
            path: "offerFirmBinding/:id",
            canDeactivate: [CanDeactivateGuard],
            canActivate: [PermissionsGuard, PermissionsShipownerGuard],
            component: OfferFirmBindingComponent,
            pathMatch: "full",
            data: {
              breadcrumb: "BREADCRUMB.FIRM_OFFER",
              permissions: [
                "charterer.negotiator",
                "charterer.administrator",
                "charterer.operator",
                "shipowner.negotiator",
                "shipowner.administrator",
                "shipowner.operator",
                "CLH Admin",
              ],
              editRoles: [
                "charterer.negotiator",
                "shipowner.negotiator",
                "CLH Admin",
              ],
            },
          },
          {
            path: "operative/:id",
            component: OperativeViewComponent,
            canActivate: [PermissionsGuard, PermissionsShipownerGuard],
            pathMatch: "full",
            data: {
              breadcrumb: "BREADCRUMB.OPERATIVE",
              permissions: [
                "charterer.negotiator",
                "charterer.administrator",
                "charterer.operator",
                "shipowner.negotiator",
                "shipowner.administrator",
                "shipowner.operator",
                "CLH Admin",
              ],
              editRoles: [
                "charterer.operator",
                "shipowner.operator",
                "CLH Admin",
              ],
            },
          },
          {
            path: "home",
            component: HomeComponent,
            canActivate: [PermissionsGuard, PermissionsGuardClh],
            data: {
              breadcrumb: "BREADCRUMB.HOME",
            },
          },
          {
            path: "chargers",
            pathMatch: "full",
            component: ChargerDataSheetComponent,
            canActivate: [PermissionsGuard, PermissionsGuardClh],
            data: {
              breadcrumb: "BREADCRUMB.CHARTERERS",
              permissions: ["CLH Admin"],
            },
            children: [],
          },
          {
            path: "chargers/:id",
            component: ChargerFormComponent,
            canDeactivate: [CanDeactivateGuard],
            canActivate: [PermissionsGuard, PermissionsGuardClh],
            pathMatch: "full",
            data: {
              breadcrumb: "BREADCRUMB.CHARTERER_SHEET",
              permissions: [
                "charterer.negotiator",
                "charterer.administrator",
                "charterer.operator",
                "CLH Admin",
              ],
              editRoles: ["charterer.administrator", "CLH Admin"],
            },
          },
          {
            path: "routes",
            component: RoutesComponent,
            canActivate: [PermissionsGuard, PermissionsGuardClh],
            pathMatch: "full",
            data: {
              breadcrumb: "BREADCRUMB.ROUTES",
              permissions: [
                "charterer.administrator",
                "charterer.negotiator",
                "charterer.operator",
              ],
              editRoles: ["charterer.administrator", "charterer.negotiator"],
            },
          },
          {
            path: "contacts",
            component: ContactComponent,
            canActivate: [PermissionsGuard, PermissionsGuardClh],
            pathMatch: "full",
            data: {
              breadcrumb: "Usuarios",
              permissions: [
                "charterer.administrator",
                "charterer.negotiator",
                "charterer.operator",
                "shipowner.administrator",
                "shipowner.negotiator",
                "shipowner.operator",
              ],
              editRoles: ["charterer.administrator", "shipowner.administrator"],
            },
          },
          {
            path: "my-shipowners",
            component: ShipownersComponent,
            canActivate: [PermissionsGuard, PermissionsGuardClh],
            pathMatch: "full",
            data: {
              breadcrumb: "BREADCRUMB.SHIPOWNERS",
              permissions: [
                "charterer.administrator",
                "charterer.negotiator",
                "charterer.operator",
              ],
              editRoles: ["charterer.administrator", "charterer.negotiator"],
            },
          },
          {
            path: "clauses",
            component: ClausesComponent,
            canActivate: [PermissionsGuard, PermissionsGuardClh],
            pathMatch: "full",
            data: {
              breadcrumb: "BREADCRUMB.CLAUSES",
              permissions: [
                "charterer.administrator",
                "charterer.negotiator",
                "charterer.operator",
              ],
              editRoles: ["charterer.administrator", "charterer.negotiator"],
            },
          },

          {
            path: "choose-user/:entityName",
            component: ChooseUserComponent,
            canActivate: [PermissionsGuard, PermissionsGuardClh],
            pathMatch: "full",
            data: {
              breadcrumb: "Choose an user",
              permissions: ["CLH Admin"],
            },
          },
          // {
          //     path:"chargers/new",
          //     component:ChargerFormComponent,
          //     pathMatch: 'full',
          //     data: {
          //         breadcrumb: 'Ficha Nuevo Cargador'
          //     },

          // },
          {
            path: "shipowners",
            component: ShipownerDataComponent,
            canActivate: [PermissionsGuard, PermissionsGuardClh],
            data: {
              breadcrumb: "BREADCRUMB.SHIPOWNERS",
              permissions: ["CLH Admin"],
              editRoles: ["shipowner.administrator", "CLH Admin"],
            },
          },
          {
            path: "shipowners/:id",
            component: ShipownerFormComponent,
            canDeactivate: [CanDeactivateGuard],
            canActivate: [PermissionsGuard, PermissionsGuardClh],
            pathMatch: "full",
            data: {
              breadcrumb: "BREADCRUMB.SHIPOWNER_SHEET",
              permissions: [
                "CLH Admin",
                "shipowner.negotiator",
                "shipowner.administrator",
                "shipowner.operator",
              ],
              editRoles: ["shipowner.administrator", "CLH Admin"],
            },
          },
          {
            path: "company-details",
            component: CompanyDetailsComponent,
            canActivate: [PermissionsGuard, PermissionsGuardClh],
            pathMatch: "full",
            data: {
              breadcrumb: "BREADCRUMB.MY_SHEET",
              permissions: [
                "charterer.negotiator",
                "charterer.administrator",
                "charterer.operator",
                "CLH Admin",
                "shipowner.negotiator",
                "shipowner.administrator",
                "shipowner.operator",
              ],
              editRoles: [
                "charterer.administrator",
                "shipowner.administrator",
                "CLH Admin",
              ],
            },
          },
          {
            path: "platform",
            children: [
              {
                path: "termsConditions",
                component: TermsAndConditionsViewComponent,
                data: {
                  breadcrumb: "BREADCRUMB.TERMS_CONDITIONS",
                  permissions: ["CLH Admin"],
                },
                canActivate: [PermissionsGuard, PermissionsGuardClh],
                pathMatch: "full",
              },
              {
                path: "feedback",
                component: FeedbackViewComponent,
                canActivate: [PermissionsGuard, PermissionsGuardClh],
                data: {
                  breadcrumb: "BREADCRUMB.FEEDBACK",
                },
              },
              {
                path: "privacyPolicies",
                component: PrivacyPoliciesViewComponent,
                canActivate: [PermissionsGuard, PermissionsGuardClh],
                pathMatch: "full",
                data: {
                  breadcrumb: "BREADCRUMB.PRIVACY_POLICIES",
                  permissions: ["CLH Admin"],
                },
              },
              { path: "", component: PlatformViewComponent, pathMatch: "full" },
            ],
          },

          {
            path: "mail-templates",
            data: {
              breadcrumb: null,
              permissions: ["CLH Admin"],
            },
            canActivate: [PermissionsGuard, PermissionsGuardClh],

            pathMatch: "prefix",
            loadChildren: () =>
              import(
                /* webpackChunkName: "menusettings-overa-notification" */
                `@overa/notification`
              ).then((m) => m.MessageTemplateRoutingModule),
          },
          { path: "", redirectTo: "home", pathMatch: "full" },
        ],
      },
      {
        path: "termsConditions",
        component: TermsConditionsViewSimpleComponent,
        pathMatch: "prefix",
      },
      {
        path: "security",
        pathMatch: "prefix",
        loadChildren: () =>
          import(
            /* webpackChunkName: "menusettings-overa-security" */
            "@overa/security"
          ).then((m) => m.SecurityRoutingModule),
      },
      {
        path: "d",
        pathMatch: "prefix",
        loadChildren: () =>
          import(
            /* webpackChunkName: "menusettings-overa-security" */
            "@overa/dynamic/components"
          ).then((m) => m.OveraDynamicRoutingModule),
      },
      // {
      //   path: "register",
      //   pathMatch: "full",
      //   loadChildren: () =>
      //     import("./components/register/register.module").then(
      //       (m) => m.RegisterModule
      //     ),
      // },
      {
        path: "privacyPolicies",
        component: PrivacyPoliciesComponent,
        pathMatch: "prefix",
      },
    ],
  };
}
