<ngx-spinner></ngx-spinner>
<p-toast></p-toast>
<p-confirmDialog
  styleClass="containerConfirmDialog"
  acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"
></p-confirmDialog>

<div class="container-vessel-info col-12" *ngIf="offerBinding">
  <div class="row">
    <div class="col-12" *ngIf="isCharterer">{{'Ref: ' + offerBinding.code}}</div>
    <div class="col-12 col-xl-4 vessel-field">
      <div class="d-flex">
        <div class="label-left">POL:</div>
        <div class="value-right">{{offerBinding.loadingPorts.join(', ')}}</div>
      </div>
    </div>
    <div class="col-12 col-xl-4 d-flex align-items-center vessel-field">
      <div class="label-left col-1">IMO:</div>
      <div class="col-9">
        <input
          type="text"
          [(ngModel)]="offerBinding.imo"
          pInputText
          placeholder="{{'SHIPOWNER_DATA.SHIPOWNER_FORM.COMPANY_IMO' | translate}}"
          class="w-100"
          [disabled]="readonlyOperative"
        />
      </div>
      <div class="col-2 value-right" *ngIf="!readonlyOperative">
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-pencil"
          class="p-button-rounded addButtonRounded"
          [pTooltip]="'ACTION.EDIT'| translate"
          tooltipPosition="bottom"
          tooltipStyleClass="tooltipCLH"
          [disabled]="!offerBinding.imo"
          (click)="updateImo()"
        ></button>
      </div>
    </div>
    <div class="col-12 col-xl-4 vessel-field">
      <div class="d-flex">
        <div class="label-left">
          {{'ATD ' + (vesselData?.voyage?.lastPort ? vesselData?.voyage?.lastPort + ' (' + vesselData?.voyage?.locode + ') :' : ': ' )}}
        </div>
        <div class="value-right">{{vesselData?.voyage?.departure}}</div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-xl-4 vessel-field">
      <div class="d-flex">
        <div class="label-left">POD:</div>
        <div class="value-right">{{offerBinding.dischargingPorts.join(', ')}}</div>
      </div>
    </div>
    <div class="col-12 col-xl-4 vessel-field">
      <div class="d-flex">
        <div class="label-left">VESSEL NAME:</div>
        <div class="value-right">{{vesselData?.ais?.name}}</div>
      </div>
    </div>
    <div class="col-12 col-xl-4 vessel-field">
      <div class="d-flex">
        <div class="label-left">{{'ETA ' + (vesselData?.ais?.destination ? vesselData?.ais?.destination + ': ' : ': ' )}}</div>
        <div class="value-right">{{vesselData?.ais?.eta}}</div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-xl-4 vessel-field">
      <div class="d-flex">
        <div class="label-left">L/C:</div>
        <div class="value-right">{{(offerBinding.layCanFrom | date: 'dd/MM/yy') + ' - ' + (offerBinding.layCanTo | date: 'dd/MM/yy' ) }}</div>
      </div>
    </div>
    <div class="col-12 col-xl-4 vessel-field">
      <div class="d-flex">
        <div class="label-left">OWNER:</div>
        <div class="value-right">{{offerBinding.shipOwnerName}}</div>
      </div>
    </div>
    <!--<div class="col-12 col-xl-4 vessel-field">
            <div class="d-flex">
                <div class="label-left">  {{'ATD ' + (vesselData?.voyage?.lastPort ? vesselData?.voyage?.lastPort + ' (' + vesselData?.voyage?.locode + ') :' : ': ' )}}</div>
                <div class="value-right">{{vesselData?.voyage?.departure}}</div>
            </div>


        </div>-->
  </div>
</div>

<div class="row div-container-offer-binding">
  <div class="col-12 col-xl-8 divTableofferBinding" *ngIf="offerBinding && isLoaded">
    <div class="p-3">
      <app-map *ngIf="idOfferShipOwner" [offerShipownerId]="idOfferShipOwner" (onVesselFound)="showVesselInfo($event)" [markers]="markers"></app-map>
    </div>
    <div>
      <p-accordion styleClass="accordionResponse">
        <p-accordionTab [selected]="offerBinding">
          <ng-template pTemplate="header">{{'OPERATIVE.RECAP' | translate}} </ng-template>
          <ng-template pTemplate="content">
            <app-table-offershipowner
              [offerBinding]="offerBinding"
              [isScreenLarge]="isScreenLarge"
              [isClh]="isClh"
              [isCharterer]="isCharterer"
              [isShipowner]="isShipowner"
            ></app-table-offershipowner>
          </ng-template>
        </p-accordionTab>
      </p-accordion>
    </div>
    <div class="containerButtonStep1">
      <div class="div-btn-next" *ngIf="!readonlyOperative && offerBinding">
        <div>
          <p-button
            label="{{'OPERATIVE.FINISH' | translate}}"
            styleClass="actualizar"
            [escape]="false"
            [pTooltip]="'TOOLTIP.OPERATIVE_CONFIRM'| translate"
            tooltipPosition="bottom"
            tooltipStyleClass="tooltipCLH"
            [showDelay]="1000"
            [hideDelay]="1000"
            (onClick)="confirmationHistoric()"
          ></p-button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-xl-4 divChatofferBinding overflowInX">
    <div class="row pt-3">
      <div class="col-12">
        <p-tabView *ngIf="chatValues && (chatValues.length > 0) && allMethodsCompleted;else loadingSpinner">
          <ng-container *ngFor="let group of chatValues">
            <p-tabPanel header="{{group.windowName}}">
              <app-chat-room
                [offerShipOwnerId]="group.offerShipOwnerId"
                [offerId]="group.offerId"
                [windowName]="group.windowName"
                [currentParticipant]="group.currentParticipant"
                [group]="group.group"
                [messages]="group.historicMessages"
                [unReadMessages]="group.unReadMessages"
                (unReadMessagesUpdated)="group.unReadMessages = $event"
              ></app-chat-room>
            </p-tabPanel>
          </ng-container>
        </p-tabView>
        <ng-template #loadingSpinner>
          <div class="spinner-container">
            <mat-spinner></mat-spinner>
          </div>
        </ng-template>
      </div>
      <div class="col-12 offerBinding-filemanager">
        <div class="documents-accordion mb-3 mt-3" *ngIf="offerBinding && offerBinding.offerId">
          <p-accordion styleClass="accordionResponse">
            <p-accordionTab>
              <ng-template pTemplate="header"> {{'DOCUMENT.HEADER' | translate}}</ng-template>
              <ng-template pTemplate="content">
                <app-documents
                  [form]="form"
                  [placeholder]="readonlyOperative ? ('DOCUMENT.HEADER' | translate) : ('DOCUMENT.SELECT' | translate)"
                  [entityId]="offerBinding.offerId"
                  [groupId]="offerBinding.offerShipOwnerId"
                  [readOnly]="readonlyOperative"
                >
                </app-documents>
              </ng-template>
            </p-accordionTab>
            <p-accordionTab>
              <ng-template pTemplate="header"> {{'NOTE.HEADER' | translate}}</ng-template>
              <ng-template pTemplate="content">
                <overa-notes *ngIf="isShipowner" [entityId]="offerBinding.offerId" [groupId]="offerBinding.offerShipOwnerId"> </overa-notes>
                <overa-notes *ngIf="!isShipowner" [entityId]="offerBinding.offerId"> </overa-notes>
              </ng-template>
            </p-accordionTab>
          </p-accordion>
        </div>
      </div>
    </div>
  </div>
</div>
