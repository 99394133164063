import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { map, Observable, ReplaySubject } from "rxjs";
import { ConfigsService } from "@overa/shared";
import { Shipowner } from "../models/shipowner/shipowner.model";
import { ChargerShipOwner } from "../models/charger-shipowner/charger-shipowner.model";
import { ChargerShipOwnerContact } from "../models/charger-shipowner/charger-shipowner-contact.model";
import { OfferShipOwner } from "../models/offers/offer-shipowner.model";
import { ContactListChargerShipownerContact } from "../models/charger-shipowner/contact-list-charger-shipowner-contact.model";
import { ContactList } from "../models/charger-shipowner/contact-list.model";
import { ContactListCSContactDto } from "../models/charger-shipowner/contact-list-cs-contact-dto.model";
import { CLHBaseService } from "./clh-base.service";
import { ChargerShipOwnerContactCreate } from "../models/charger/charger-shipowner-contact-create";

@Injectable({ providedIn: "root" })
export class ChargerShipownerService extends CLHBaseService {
  add(shipowner: ChargerShipOwner) {
    const endpointUrl = `${this.baseUrl}/chargerShipowner`;
    return this.http.post<ChargerShipOwner>(endpointUrl, shipowner);
  }

  edit(shipowner: ChargerShipOwner) {
    const endpointUrl = `${this.baseUrl}/chargerShipowner`;
    return this.http.put<ChargerShipOwner>(endpointUrl, shipowner);
  }

  delete(id: string) {
    const endpointUrl = `${this.baseUrl}/chargerShipowner/delete/${id}`;
    return this.http.delete(endpointUrl);
  }
  addContact(chargerShipOwnerContact: ChargerShipOwnerContact) {
    const endpointUrl = `${this.baseUrl}/chargerShipownerContact/create`;
    return this.http.post<ChargerShipOwnerContact>(
      endpointUrl,
      chargerShipOwnerContact
    );
  }
  editContact(chargerShipOwnerContact: ChargerShipOwnerContact) {
    const endpointUrl = `${this.baseUrl}/chargerShipownerContact/update`;
    return this.http.put<ChargerShipOwnerContact>(
      endpointUrl,
      chargerShipOwnerContact
    );
  }
  deleteContact(id: string) {
    const endpointUrl = `${this.baseUrl}/chargerShipownerContact/delete/${id}`;
    return this.http.delete(endpointUrl);
  }

  addContactList(list: ContactListChargerShipownerContact) {
    const endpointUrl = `${this.baseUrl}/contactList/addContact`;
    return this.http.post<ContactListChargerShipownerContact>(
      endpointUrl,
      list
    );
  }
  deleteContactList(list: ContactListChargerShipownerContact) {
    const endpointUrl = `${this.baseUrl}/contactList/deleteContact`;
    return this.http.put<ContactListChargerShipownerContact>(endpointUrl, list);
  }
  deleteList(list: ContactList) {
    const endpointUrl = `${this.baseUrl}/contactList/${list.id}`;
    return this.http.delete(endpointUrl);
  }
  getContactList(id: string) {
    const endpointUrl = `${this.baseUrl}/contactList/${id}`;
    return this.http.get<ContactList>(endpointUrl);
  }
  addList(list: ContactList) {
    const endpointUrl = `${this.baseUrl}/contactList`;
    return this.http.post<ContactList>(endpointUrl, list);
  }
  editList(list: ContactList) {
    const endpointUrl = `${this.baseUrl}/contactList/update`;
    return this.http.put<ContactList>(endpointUrl, list);
  }
  getWithContacts(id: string) {
    const endpointUrl = `${this.baseUrl}/contactList/getWithContacts/${id}`;
    return this.http.get<ContactListCSContactDto>(endpointUrl);
  }
  checkShipowner(chargerId: string, emailShipowner: string) {
    const endpointUrl = `${this.baseUrl}/chargerShipowner/check/${chargerId}/${emailShipowner}`;
    return this.http.post<Shipowner>(endpointUrl, {});
  }
  addNewShipownerContact(data: ChargerShipOwnerContactCreate) {
    const endpointUrl = `${this.baseUrl}/chargerShipowner/create`;
    return this.http.post<ChargerShipOwnerContactCreate>(endpointUrl, data);
  }

}
