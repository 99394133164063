import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { KanbanTask, TaskButon } from "src/app/models/kanban-task.model";
import { Offer } from "src/app/models/offers/offer.model";
import { OfferShipOwner } from "src/app/models/offers/offer-shipowner.model";
import { OfferState } from "src/app/models/enum/offer-state.model";
import { StateOfferShipOwner } from "src/app/models/enum/state-offer-shipowner.model";
import { ChargerService } from "src/app/services/charger.service";
import { OfferService } from "src/app/services/offer.service";
import { ShipownerService } from "src/app/services/shipowner.service";
import { CLHGlobals } from "src/app/utils/globals";
import { AccountService } from "@overa/security";
import { UserType } from "src/app/models/enum/user-type.model";
import { SearcherService } from "src/app/services/searcher.service";
import { QueryParams } from "src/app/models/query-params.model";
import { OfferFilter } from "src/app/models/searcher/offer-filter.model";
import { Subscription } from "rxjs";
import { Charger } from "../../models/charger/charger.model";
import {
  DynamicDialogRef,
} from "primeng/dynamicdialog";

import { NgxTranslateService } from "@overa/shared";

@Component({
  selector: "app-kanban-view",
  templateUrl: "./kanban-view.component.html",
  styleUrls: ["./kanban-view.component.scss"],
})
export class KanbanViewComponent implements OnInit, OnChanges {
  taskListReception: KanbanTask[] = [];
  taskListNegotiation: KanbanTask[] = [];
  taskListOperative: KanbanTask[] = [];
  taskListHistory: KanbanTask[] = [];
  allTasks: KanbanTask[] = [];
  buttons: TaskButon[] = [];
  loadedKanbans = false;
  idCharger: string = "";
  listOffers: Offer[] | OfferShipOwner[] = [];
  descriptionReception!: string;
  descriptionReceptionShipowner!: string;
  descriptionComparative!: string;
  descriptionNegotation!: string;
  descriptionNegotationOperative!: string;
  descriptionRecap!: string;
  descriptionOperative!: string;
  descriptionNegotiationShipowner!: string;
  descriptionOperativeShipowner!: string;
  iconCheck!: string;
  iconNotification!: string;
  iconWait!: string;
  listStates: string[];
  offer!: Offer;
  isShipowner: boolean = false;
  isClh: boolean = false;
  allChargers = [];
  @Input()
  selected?: KanbanTask;
  @Output()
  onSelectKanban: EventEmitter<KanbanTask> = new EventEmitter<KanbanTask>();
  selectedKanban?: KanbanTask;
  imos = [];
  openedConditionsDialog = false;
  statusNegotiation = [
    StateOfferShipOwner.RECAP_SHIPOWNER_PENDING,
    StateOfferShipOwner.RECAP_CHARTERER_PENDING,
    StateOfferShipOwner.RECAP,
    StateOfferShipOwner.RECAP_CONFIRMED,
    StateOfferShipOwner.AGREEMENT_STARTED, // cargador crea oferta en firme
    StateOfferShipOwner.AGREEMENT_PREVALIDATED, //armador contraoferta (cl�usulas textarea)
    StateOfferShipOwner.AGREEMENT_VALIDATED, //CLH a�ade cl�usulas armador
    StateOfferShipOwner.CHARTERER_COUNTER_OFFER, //COUNTER
    StateOfferShipOwner.SHIPOWNER_COUNTER_OFFER,
    StateOfferShipOwner.COMPLETED_CHARTERER_PENDING,
    StateOfferShipOwner.COMPLETED_SHIPOWNER_PENDING,
    StateOfferShipOwner.COMPLETED,
  ];
  statusRecap = [
    StateOfferShipOwner.RECAP,
    StateOfferShipOwner.RECAP_CHARTERER_PENDING,
    StateOfferShipOwner.RECAP_SHIPOWNER_PENDING,
  ];
  statusCanceled = [StateOfferShipOwner.CANCELLED];
  statusDecline = [StateOfferShipOwner.REFUSED, StateOfferShipOwner.CANCELLED];
  statusNone = [StateOfferShipOwner.REFUSED];
  statusComparative = [
    StateOfferShipOwner.WAIT_RESPONSE,
    StateOfferShipOwner.BIDED,
  ];
  statusOffershipownerToListReceptionWithOfferNegotiation = [
    StateOfferShipOwner.WAIT_RESPONSE,
    StateOfferShipOwner.BIDED,
  ];
  isClhUser = false;
  idUser: string = "";
  searchText: string = "";
  hasClhRole: boolean = false;
  offerFilter: OfferFilter = new OfferFilter();
  subscriptionSearch?: Subscription;
  chargers!: Charger[];
  loadedChargers = false;
  kanbanData: KanbanTask[] = [];
  userWithDate: any;
  contactId: string = "00000000-0000-0000-0000-000000000000";
  ref: DynamicDialogRef | undefined;
  constructor(
    private offerService: OfferService,
    private globals: CLHGlobals,
    private chargerService: ChargerService,
    private translate: NgxTranslateService,
    private shipOwnerService: ShipownerService,
    private spinner: NgxSpinnerService,
    private accountService: AccountService,
    private searcherService: SearcherService,
    private cdr: ChangeDetectorRef,

  ) {
    const translation = this.translate.get("KANVAN.CARD");
    this.descriptionReception = translation.CONTENT.DESCRIPTION_RECEPTION;
    this.descriptionReceptionShipowner =
      translation.CONTENT.DESCRIPTION_RECEPTION_SHIPOWNER;
    this.descriptionComparative = translation.CONTENT.DESCRIPTION_COMPARATIVE;
    this.descriptionNegotation = translation.CONTENT.DESCRIPTION_NEGOTATION;
    this.descriptionNegotiationShipowner =
      translation.CONTENT.DESCRIPTION_NEGOTATION_SHIPOWNER;
    this.descriptionNegotationOperative =
      translation.CONTENT.DESCRIPTION_NEGOTATION_OPERATIVE;
    this.descriptionRecap = translation.CONTENT.DESCRIPTION_RECAP;
    this.descriptionOperative = translation.CONTENT.DESCRIPTION_OPERATIVE;
    this.descriptionOperativeShipowner =
      translation.CONTENT.DESCRIPTION_OPERATIVE_SHIPOWNER;
    this.iconCheck = translation.ICON.CHECK;
    this.iconNotification = translation.ICON.NOTIFICATION;
    this.iconWait = translation.ICON.WAIT;

    this.listStates = Object.keys(OfferState).filter(
      (x) => !(parseInt(x) >= 0)
    );
  }
  ngOnChanges() {
    console.log("searchText", this.searchText);
  }

  //selectShipownerId(offerShipownerId: string) {
  //    console.log(offerShipownerId);
  //    if (this.kanbanData) {
  //        var selectedKanban = this.kanbanData.find(x => x.id == offerShipownerId);
  //        if (selectedKanban) {
  //            this.selectedKanban = selectedKanban;
  //        }
  //    }
  //    this.onSelectKanban.emit(selectedKanban);

  //}

  ngOnInit() {
    if (
      this.accountService.currentUser.roles
        .map((x) => x.name)
        .includes(this.globals.clhRoleName)
    ) {
      this.hasClhRole = true;
      if (!this.globals.currentCharger && !this.globals.currentShipOwner) {
        this.isClhUser = true;
      }
    }

    // console.log(this.listStates);
    this.resetLists();
    console.log("searchText", this.searchText);
    this.searchText = "";
    this.searcherService.selectedImo.subscribe((kanbanElement) => {
      console.log(kanbanElement);
      this.selectedKanban = this.allTasks.find(
        (x) => x.offerShipOwnerId == kanbanElement
      );
      if (this.selectedKanban) {
        this.searcherService.selectKanbanOnMap(this.selectedKanban);
      }
    });

    //this.searcherService.dataSearchResults.subscribe(results => {
    //    console.log(results);
    //    this.imos = [];
    //    let imos = [];
    //    this.selectedKanban = null;
    //    if (results) {
    //        results.forEach(res => {
    //            if (res.imo) {
    //                imos.push(res);
    //                var taskExample: KanbanTask = new KanbanTask();

    //                this.kanbanData.push(res);

    //            }
    //            else if (res.offerShipOwners) {
    //                res.offerShipOwners.forEach(os => {
    //                    if (os.imo) {
    //                        os.offer = res;
    //                        imos.push(os);
    //                        this.kanbanData.push(os);

    //                    }
    //                }
    //                );
    //            }
    //        });
    //    }
    //    this.imos = [...imos];
    //})
    this.searcherService.datacurrentSearch.subscribe((data) => {
      this.spinner.show("layout-spinner");

      //if (data != null) {
      //    this.spinner.hide('layout-spinner');
      //}
      if (this.subscriptionSearch) {
        this.subscriptionSearch.unsubscribe();
      }
      console.log(data);
      //if (this.searchText !== data) {
      if (data != null) {
        this.resetLists();
      }
      if (data == null) {
        this.offerFilter.isActive = false;
        this.offerFilter.isUnread = false;
        this.offerFilter.searchString = "";
      } else {
        this.offerFilter.isActive = data.isActive ? data.isActive : false;
        this.offerFilter.isUnread = data.isUnread ? data.isUnread : false;
        this.offerFilter.searchString = data.searchString
          ? data.searchString
          : "";
        this.offerFilter.startDate = data.startDate
          ? data.startDate
          : undefined;
        this.offerFilter.endDate = data.endDate ? data.endDate : undefined;
      }

      this.cdr.detectChanges();
      //}

      if (!this.isClhUser) {
        if (this.globals.currentCharger != null) {
          console.log("Cargador");
          this.isShipowner = false;
          this.idCharger = this.globals.currentCharger.id || "";
          console.log(this.offerFilter);
          this.subscriptionSearch = this.chargerService
            .getOfferByChargerIdWithOfferFilter(
              this.idCharger,
              this.offerFilter
            )
            .subscribe({
              next: (data) => {
                console.log(data);
                this.listOffers = data;
                if (this.listOffers?.length) {
                  // console.log(this.listOffers.length);
                  this.associateOfferToKanvan();
                }
                this.spinner.hide("layout-spinner");
                this.loadedKanbans = true;
                this.searcherService.updateResults(this.listOffers);
              },
              error: (err) => {
                console.error("Error:", err);
                this.spinner.hide("layout-spinner");
                this.loadedKanbans = true;
              },
            });
        } else if (this.globals.currentShipOwner != null) {
          if (!this.loadedChargers) {
            this.chargerService.get().subscribe({
              next: (chargers) => {
                this.loadedChargers = true;
                this.chargers = chargers;
                console.log("Armador");
                this.loadShipownersOffers();
              },
              error: (err) => {
                console.error(err);
                this.loadedKanbans = true;
              },
            });
          } else {
            this.loadShipownersOffers();
          }
        } else {
          console.log("No es ni armador ni cargador");
          this.spinner.hide("layout-spinner");
          this.loadedKanbans = true;
        }
      } else {
        console.log("isClhUser", this.isClhUser);
        this.isClh = true;
        if (!this.loadedChargers) {
          this.chargerService.get().subscribe({
            next: (chargers) => {
              this.chargers = chargers;
              this.loadedChargers = true;
              this.loadClhOffers();
            },
            error: (err) => {
              console.error(err);
              this.spinner.hide("layout-spinner");
              this.loadedKanbans = true;
            },
          });
        } else {
          this.loadClhOffers();
        }
      }
    });
    this.buttons = [
      {
        action: this.button1Click,
        icon: "user",
      },
    ];
  }
  loadClhOffers() {
    this.subscriptionSearch = this.offerService
      .get(this.offerFilter)
      .subscribe({
        next: (offers) => {
          this.listOffers = offers;
          console.log(this.listOffers);
          this.listOffers.forEach((offer) => {
            if (!offer.charger) {
              offer.charger = this.chargers.find(
                (c) => c.id == offer.chargerId
              );
            }
          });
          this.associateOfferToKanvan();
          this.spinner.hide("layout-spinner");
          this.loadedKanbans = true;
          this.searcherService.updateResults(this.listOffers);
        },
        error: (err) => {
          console.error(err);
          this.spinner.hide("layout-spinner");
          this.loadedKanbans = true;
        },
      });
  }
  loadShipownersOffers() {
    this.isShipowner = true;
    this.idUser = this.globals.user.id || "";
    console.log("searchText", this.searchText);
    const shipownerId = null;
    if (this.hasClhRole) {
      this.offerFilter.shipOwnerId = this.globals.currentShipOwner?.id;
    }
    this.subscriptionSearch = this.shipOwnerService
      .getOffersByShipownerId(this.idUser, this.offerFilter)
      .subscribe({
        //this.shipOwnerService.getOffersByShipownerId(this.globals.currentShipOwner.id).subscribe({
        next: (offers) => {
          console.log(offers);
          // offers = offers.filter((element) => !this.statusNone.includes(element.state));
          this.listOffers = offers;
          if (this.listOffers?.length) {
            console.log(this.listOffers);
            this.listOffers.forEach((offer) => {
              if (!offer.offer.charger) {
                offer.offer.charger = this.chargers.find(
                  (c) => c.id == offer.offer.chargerId
                );
              }
            });
            this.associateOfferToKanvan();
            this.spinner.hide("layout-spinner");
          }
          this.loadedKanbans = true;
          this.spinner.hide("layout-spinner");
          this.searcherService.updateResults(this.listOffers);
        },
        error: (err) => {
          this.loadedKanbans = true;
          console.log("error", err);
          this.spinner.hide("layout-spinner");
        },
      });
  }
  public getTime(date?: Date) {
    return date != null ? new Date(date).getTime() : 0;
  }
  associateOfferToKanvan() {
    // console.log('listaOffers', this.listOffers);
    this.listOffers.forEach((offer) => {
      const state = this.isShipowner
        ? (offer as OfferShipOwner).offer.state
        : offer.state;
      const offerShipownerNegotiation = this.isShipowner
        ? (offer as OfferShipOwner).offer
        : (offer as Offer).offerShipOwners?.find((x) => x.shift != null);

      const taskExample: KanbanTask = {
        description: this.isShipowner
          ? this.descriptionReceptionShipowner
          : this.descriptionReception,
        title: "",
        icon: this.iconCheck,
        routeRedirect: "",
        titleComparative: "",
        descriptionComparative:
          state === OfferState.NEGOTIATION || state === OfferState.OPERATIVE
            ? this.descriptionComparative
            : "",
        routeRedirectComparative: "",
        iconComparative:
          state === OfferState.NEGOTIATION || state === OfferState.OPERATIVE
            ? this.iconCheck
            : "",
        descriptionNegotation:
          state === OfferState.NEGOTIATION ? this.descriptionNegotation : "",
        routeRedirectNegotation: "",
        iconNegotation:
          state === OfferState.NEGOTIATION ? this.iconNotification : "",
        descriptionRecap: "",
        routeRedirectRecap: "",
        titleRecap: "",
        iconRecap: state === OfferState.OPERATIVE ? this.iconCheck : "",
        descriptionOperative: "",
        routeRedirectOperative: "",
        iconOperative:
          state === OfferState.OPERATIVE ? this.iconNotification : "",
        offer: this.isShipowner
          ? (offer as OfferShipOwner).offer
          : (offer as Offer),
        offerShipOwnerId: this.isShipowner
          ? (offer as OfferShipOwner).id
          : offerShipownerNegotiation?.id || undefined,
        offerId: this.isShipowner
          ? (offer as OfferShipOwner).offerId
          : offer.id,
        isShipowner: this.isShipowner,
        isClh: this.isClh,
      };
      // console.log(offer);
      let offerId = offer.id;
      let counter, shift, stateOffershipowner;
      let showComparative = false;
      // let counter, shift, stateOfferShipowner, stateOffer;
      const query = new QueryParams();
      if (!this.isShipowner) {
        offerId =
          (offer as Offer).offerShipOwners?.find((x) =>
            this.statusNegotiation.includes(x.state)
          )?.id || "";
        (offer as Offer).offerShipOwners?.find((x) => {
          if (this.statusNegotiation.includes(x.state)) {
            shift = x.shift;
            counter = x.counter;
            stateOffershipowner = x.state;
          }
        });

        if (!offerId) {
          if (offer.state === OfferState.HISTORIC) {
            offerId = offer.id;
          }
          (offer as Offer).offerShipOwners?.forEach((item) => {
            if (item.state === StateOfferShipOwner.AGREEMENT_STARTED) {
              counter = item.counter;
              shift = item.shift;
            }
            if (
              item.state === StateOfferShipOwner.CANCELLED &&
              item.shift != null
            ) {
              counter = item.counter;
              shift = item.shift;
            }
            if (
              this.statusComparative.includes(item.state) ||
              this.statusDecline.includes(item.state)
            ) {
              showComparative = true;
            }
          });
        }
      } else {
        counter = (offer as OfferShipOwner).counter;
        shift = (offer as OfferShipOwner).shift;
        stateOffershipowner = (offer as OfferShipOwner).state;
      }

      if (state === OfferState.OFFER) {
        if (!this.isShipowner) {
          taskExample.routeRedirect =
            "/firmOffer/cover/" + taskExample.offer?.id;
          if (
            !this.isShipowner &&
            (taskExample.offer as Offer).offerShipOwners?.length &&
            showComparative
          ) {
            taskExample.descriptionComparative = this.descriptionComparative;
            taskExample.routeRedirectComparative =
              "/offerComparative/" + (taskExample.offer as Offer).id;
            taskExample.iconComparative = this.iconNotification;
          }
        } else {
          if (this.statusDecline.includes((offer as OfferShipOwner).state)) {
            taskExample.routeRedirect =
              "/offerReceived/" + taskExample.offerShipOwnerId;
            if (counter && counter >= 1) {
              taskExample.routeRedirect =
                "/offerResponse/" + taskExample.offerShipOwnerId;
              taskExample.iconNegotation = this.iconCheck;
              // taskExample.descriptionNegotation = this.descriptionNegotationOperative;
              taskExample.routeRedirectNegotation =
                "/offerFirmBinding/" + offerId;
              if (offer.state === StateOfferShipOwner.COMPLETED) {
                taskExample.iconRecap = this.iconCheck;
                taskExample.descriptionRecap = this.descriptionRecap;
                taskExample.routeRedirectRecap = "/offerFirmBinding/" + offerId;
                taskExample.iconOperative = this.iconCheck;
                taskExample.descriptionOperative =
                  this.descriptionOperativeShipowner;
                taskExample.routeRedirectOperative = "/operative/" + offerId;
              }
            }
          } else {
            taskExample.routeRedirect =
              this.isShipowner && offer.state === StateOfferShipOwner.BIDED
                ? "/offerResponse/" + taskExample.offerShipOwnerId
                : "/offerReceived/" + taskExample.offerShipOwnerId;
          }
        }
        taskExample.queryParams = query;
        if (this.isShipowner) {
          taskExample.descriptionNegotation =
            taskExample.descriptionNegotation != ""
              ? this.descriptionNegotiationShipowner
              : "";
          if (this.statusDecline.includes((offer as OfferShipOwner).state)) {
            taskExample.classKanban =
              "STATUS_OFFERSHIPOWNER." +
              StateOfferShipOwner[(offer as OfferShipOwner).state]
                .toString()
                .toUpperCase();
            this.taskListHistory.push(taskExample);
          } else {
            this.taskListReception.push(taskExample);
          }
        } else {
          // if(taskExample.offer.offerShipOwners && taskExample.offer.offerShipOwners.length == 1 && taskExample.offer.offerShipOwners[0].state == StateOfferShipOwner.CANCELLED){
          //   taskExample.classKanban = 'STATUS_OFFERSHIPOWNER.CANCELLED';
          //   this.taskListHistory.push(taskExample);
          // }else{
          this.taskListReception.push(taskExample);
          // }
        }
      } else if (state === OfferState.NEGOTIATION) {
        if (offerId) {
          if (counter && counter < 2) {
            taskExample.routeRedirectNegotation =
              "/offerFirmBinding/" + offerId;
            taskExample.descriptionNegotation = this.isShipowner
              ? this.descriptionNegotiationShipowner
              : this.descriptionNegotation;
            if (this.isShipowner && stateOffershipowner) {
              this.linkShipownerIndicationToShipowner(taskExample);
              if (stateOffershipowner != StateOfferShipOwner.WAIT_RESPONSE) {
                if (this.statusRecap.includes(stateOffershipowner)) {
                  taskExample.iconNegotation =
                    stateOffershipowner ===
                      StateOfferShipOwner.RECAP_CHARTERER_PENDING
                      ? this.iconWait
                      : this.iconNotification;
                } else {
                  taskExample.iconNegotation =
                    shift === UserType.SHIPOWNER
                      ? this.iconNotification
                      : this.iconWait;
                }
              } else {
                taskExample.routeRedirectNegotation = "";
                taskExample.descriptionNegotation = "";
              }
            }
            if (this.isClh) {
              this.linkComparativeAndOrderToCharterer(taskExample);
              if (shift === UserType.ADMIN) {
                taskExample.iconNegotation = this.iconNotification;
              } else {
                taskExample.iconNegotation =
                  counter == 1 ? this.iconCheck : this.iconWait;
              }
            }
            if (this.globals.currentCharger && stateOffershipowner) {
              this.linkComparativeAndOrderToCharterer(taskExample);
              if (this.statusRecap.includes(stateOffershipowner)) {
                taskExample.iconNegotation =
                  stateOffershipowner ===
                    StateOfferShipOwner.RECAP_SHIPOWNER_PENDING
                    ? this.iconWait
                    : this.iconNotification;
              } else {
                taskExample.iconNegotation =
                  shift === UserType.SHIPOWNER || shift === UserType.ADMIN
                    ? this.iconWait
                    : this.iconNotification;
              }
            }
          } else {
            if (this.isShipowner || this.globals.currentCharger) {
              taskExample.routeRedirectNegotation =
                "/offerFirmBinding/" + offerId;
              if (stateOffershipowner === StateOfferShipOwner.RECAP) {
                taskExample.iconNegotation = this.iconNotification;
              } else {
                taskExample.iconNegotation =
                  (this.globals.currentCharger && shift === UserType.CHARGER) ||
                    (this.isShipowner && shift === UserType.SHIPOWNER)
                    ? this.iconNotification
                    : this.iconWait;
              }

              if (this.globals.currentCharger) {
                this.linkComparativeAndOrderToCharterer(taskExample);
              } else {
                this.linkShipownerIndicationToShipowner(taskExample);
              }
            }
            if (this.isClh) {
              this.linkComparativeAndOrderToCharterer(taskExample);
              taskExample.routeRedirectNegotation =
                "/offerFirmBinding/" + offerId;
              taskExample.iconNegotation = this.iconCheck;
            }
          }
        } else {
          if ((this.globals.currentCharger && counter == 0) || this.isClh) {
            this.linkComparativeAndOrderToCharterer(taskExample);
            if (shift === UserType.SHIPOWNER || shift === UserType.ADMIN) {
              taskExample.iconNegotation = this.iconWait;
              taskExample.routeRedirectNegotation =
                "/offerFirmBinding/" + offerId;
            }
          }
        }

        taskExample.queryParams = query;
        if (this.isShipowner) {
          if (
            stateOffershipowner &&
            !this.statusOffershipownerToListReceptionWithOfferNegotiation.includes(
              stateOffershipowner
            )
          ) {
            if (!this.statusDecline.includes(stateOffershipowner)) {
              this.taskListNegotiation.push(taskExample);
            } else {
              this.clearFieldsNegotiation(taskExample);
              taskExample.classKanban =
                "STATUS_OFFERSHIPOWNER." +
                StateOfferShipOwner[stateOffershipowner]
                  .toString()
                  .toUpperCase();
              this.taskListHistory.push(taskExample);
            }
          } else if (stateOffershipowner) {
            this.clearFieldsNegotiation(taskExample);
            this.taskListReception.push(taskExample);
          }
        } else {
          this.taskListNegotiation.push(taskExample);
        }
      } else if (state === OfferState.OPERATIVE) {
        if (this.globals.currentCharger || this.isClh) {
          this.linkComparativeAndOrderToCharterer(taskExample);
        }
        if (this.isShipowner) {
          console.log("canceled");
          if (this.statusDecline.includes((offer as OfferShipOwner).state)) {
            taskExample.routeRedirect =
              "/offerReceived/" + taskExample.offerShipOwnerId;
            if (counter && counter >= 1) {
              taskExample.routeRedirect =
                "/offerResponse/" + taskExample.offerShipOwnerId;
              taskExample.iconNegotation = this.iconCheck;
              taskExample.descriptionNegotation =
                this.descriptionNegotiationShipowner;
              taskExample.routeRedirectNegotation =
                "/offerFirmBinding/" + offerId;
              if (offer.state === StateOfferShipOwner.COMPLETED) {
                taskExample.iconRecap = this.iconCheck;
                taskExample.descriptionRecap = this.descriptionRecap;
                taskExample.routeRedirectRecap = "/offerFirmBinding/" + offerId;
                taskExample.iconOperative = this.iconCheck;
                taskExample.descriptionOperative =
                  this.descriptionOperativeShipowner;
                taskExample.routeRedirectOperative = "/operative/" + offerId;
              }
            }
          } else {
            taskExample.routeRedirect =
              this.isShipowner && offer.state === StateOfferShipOwner.BIDED
                ? "/offerResponse/" + taskExample.offerShipOwnerId
                : "/offerReceived/" + taskExample.offerShipOwnerId;
          }

          this.linkShipownerIndicationToShipowner(taskExample);
        }

        taskExample.queryParams = query;
        if (!this.isShipowner) {
          if (offerId) {
            this.fillFieldsToRecapAndOperativeAndNegotiation(
              taskExample,
              offerId
            );

            if (
              stateOffershipowner ===
              StateOfferShipOwner.COMPLETED_SHIPOWNER_PENDING
            ) {
              taskExample.iconOperative = this.iconWait;
            }
            if (stateOffershipowner === StateOfferShipOwner.COMPLETED) {
              taskExample.iconOperative = this.iconCheck;
            }

            this.taskListOperative.push(taskExample);
          }
        } else if (
          this.isShipowner &&
          stateOffershipowner &&
          offerId &&
          !this.statusOffershipownerToListReceptionWithOfferNegotiation.includes(
            stateOffershipowner
          ) &&
          !this.statusDecline.includes(stateOffershipowner)
        ) {
          this.fillFieldsToRecapAndOperativeAndNegotiation(
            taskExample,
            offerId
          );
          this.changeDescriptionToShipowner(taskExample);
          if (
            stateOffershipowner ===
            StateOfferShipOwner.COMPLETED_CHARTERER_PENDING
          ) {
            taskExample.iconOperative = this.iconWait;
          }
          if (stateOffershipowner === StateOfferShipOwner.COMPLETED) {
            taskExample.iconOperative = this.iconCheck;
          }

          this.taskListOperative.push(taskExample);
        } else if (
          this.isShipowner &&
          stateOffershipowner &&
          this.statusDecline.includes(stateOffershipowner)
        ) {
          taskExample.classKanban =
            "STATUS_OFFERSHIPOWNER." +
            StateOfferShipOwner[stateOffershipowner].toString().toUpperCase();
          if (counter && counter >= 1) {
            this.linkShipownerIndicationToShipowner(taskExample);
          } else {
            taskExample.routeRedirect =
              "/offerReceived/" + taskExample.offerShipOwnerId;
          }
          this.changeDescriptionToShipowner(taskExample);
          this.taskListHistory.push(taskExample);
        } else {
          if (this.isShipowner) {
            this.changeDescriptionToShipowner(taskExample);
          }
          this.clearFieldsOperative(taskExample);
          this.clearFieldsRecap(taskExample);
          this.taskListReception.push(taskExample);
        }
      } else if (
        state === OfferState.HISTORIC ||
        state === OfferState.CANCELLED
      ) {
        if (this.globals.currentCharger || this.isClh) {
          this.linkComparativeAndOrderToCharterer(taskExample);
          taskExample.iconComparative = this.iconCheck;
          taskExample.descriptionComparative = this.descriptionComparative;
          let allStateCancelled = true;
          (taskExample.offer as Offer).offerShipOwners?.forEach((item) => {
            if (item.state != StateOfferShipOwner.CANCELLED) {
              allStateCancelled = false;
            }
          });
          if (allStateCancelled) {
            taskExample.classKanban = "STATUS_OFFERSHIPOWNER.CANCELLED";
          }
        }
        if (this.isShipowner) {
          if (stateOffershipowner) {
            taskExample.classKanban =
              "STATUS_OFFERSHIPOWNER." +
              StateOfferShipOwner[stateOffershipowner].toString().toUpperCase();
          }
          if (counter && counter >= 1) {
            this.linkShipownerIndicationToShipowner(taskExample);
          } else {
            taskExample.routeRedirect =
              "/offerReceived/" + taskExample.offerShipOwnerId;
          }
        }
        if (counter && counter >= 1) {
          taskExample.iconNegotation = this.iconCheck;
          taskExample.descriptionNegotation =
            this.descriptionNegotationOperative;
          taskExample.routeRedirectNegotation = "/offerFirmBinding/" + offerId;
          if (stateOffershipowner === StateOfferShipOwner.COMPLETED) {
            taskExample.iconRecap = this.iconCheck;
            taskExample.descriptionRecap = this.descriptionRecap;
            taskExample.routeRedirectRecap = "/offerFirmBinding/" + offerId;
            taskExample.iconOperative = this.iconCheck;
            taskExample.descriptionOperative = this.descriptionOperative;
            taskExample.routeRedirectOperative = "/operative/" + offerId;
            taskExample.classKanban =
              "STATUS_OFFERSHIPOWNER." +
              StateOfferShipOwner[stateOffershipowner].toString().toUpperCase();
          }
        }
        taskExample.queryParams = query;
        if (this.isShipowner) {
          this.changeDescriptionToShipowner(taskExample);
        }
        // console.log(taskExample);
        this.taskListHistory.push(taskExample);
      }
    });

    console.log(
      "lista de reception",
      this.taskListReception,
      this.taskListNegotiation,
      this.taskListOperative,
      this.taskListHistory
    );
    this.spinner.hide("layout-spinner");
    this.allTasks = this.taskListReception
      .concat(this.taskListNegotiation)
      .concat(this.taskListOperative)
      .concat(this.taskListHistory);
  }

  resetLists() {
    this.taskListReception = [];
    this.taskListNegotiation = [];
    this.taskListOperative = [];
    this.taskListHistory = [];
  }
  button1Click() {
    console.log("click");
  }
  selectKanban(event: any) {
    this.onSelectKanban.emit(event);
  }
  linkComparativeAndOrderToCharterer(taskExample: KanbanTask) {
    taskExample.routeRedirect =
      "/firmOffer/cover/" + (taskExample.offer as Offer).id;
    taskExample.routeRedirectComparative =
      "/offerComparative/" + (taskExample.offer as Offer).id;
  }
  linkShipownerIndicationToShipowner(taskExample: KanbanTask) {
    taskExample.routeRedirect =
      "/offerResponse/" + taskExample.offerShipOwnerId;
  }
  clearFieldsNegotiation(taskExample: KanbanTask) {
    taskExample.routeRedirectNegotation = "";
    taskExample.descriptionNegotation = "";
    taskExample.iconNegotation = "";
  }
  clearFieldsOperative(taskExample: KanbanTask) {
    taskExample.iconOperative = "";
    taskExample.routeRedirectOperative = "";
    taskExample.descriptionRecap = "";
  }
  clearFieldsRecap(taskExample: KanbanTask) {
    taskExample.iconRecap = "";
    taskExample.routeRedirectRecap = "";
    taskExample.descriptionRecap = "";
  }
  fillFieldsToRecapAndOperativeAndNegotiation(
    taskExample: KanbanTask,
    offerId: string
  ) {
    taskExample.iconNegotation = this.iconCheck;
    taskExample.descriptionNegotation = this.descriptionNegotationOperative;
    taskExample.routeRedirectNegotation = "/offerFirmBinding/" + offerId;
    taskExample.iconRecap = this.iconCheck;
    taskExample.descriptionRecap = this.descriptionRecap;
    taskExample.descriptionOperative = this.descriptionOperative;
    taskExample.routeRedirectRecap = "/offerFirmBinding/" + offerId;
    taskExample.routeRedirectOperative = "/operative/" + offerId;
  }
  changeDescriptionToShipowner(taskExample: KanbanTask) {
    taskExample.descriptionNegotation =
      taskExample.descriptionNegotation != ""
        ? this.descriptionNegotiationShipowner
        : "";
    taskExample.descriptionOperative =
      taskExample.descriptionOperative != ""
        ? this.descriptionOperativeShipowner
        : "";
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }
}
