import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AccountService } from "@overa/security";
import { CLHGlobals } from "src/app/utils/globals";
import {
  Form,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ClauseNegotiation } from "src/app/models/clause/clause-negotiation.model";

@Component({
  selector: "clh-textarea",
  templateUrl: "./clh-textarea.component.html",
  styleUrls: ["./clh-textarea.component.scss"],
})
export class ClhTextareaComponent implements OnInit, AfterViewInit {
  @Input() readOnly?: boolean = false;
  @Input() form!: FormGroup;
  @Input() nameControl?: string = "";
  @Input() style?: string = "";
  @Input() placeholder?: string = "";
  @Input() idTextarea?: string = "";
  @Input() errorLabel?: string = "";
  @Input() classTextarea?: string = "";
  @Input() required?: boolean = false;
  @Input() label?: string = "";
  @Input() clause?: ClauseNegotiation;
  @Input() minHeight?: number;
  @Input() showLabelFloat?: boolean;
  @Input() escape?: boolean;
  @Input() pTooltip?: string = "";
  @Input() tooltipPosition?: string = "";
  @Input() tooltipStyleClass?: string = "";
  @Input() showDelay?: number;
  @Input() hideDelay?: number;
  @Input() autoHeight: boolean = true;
  @Output() textAreaClick = new EventEmitter<any>();
  @Output() textAreaBlur = new EventEmitter<any>();
  valueTextarea: any;

  constructor(
    protected globals: CLHGlobals,
    private route: ActivatedRoute,
    private accountService: AccountService,
    public router: Router,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // console.log('textarea');
    this.valueTextarea = this.form.get(this.nameControl || "")?.value;
    if (this.valueTextarea != "") {
      setTimeout(() => {
        this.updateHeigth();
      }, 1);
    }
    this.form.get(this.nameControl || "")?.valueChanges.subscribe(() => {
      this.updateHeigth();
      this.cd.detectChanges();
    });
  }

  change() {
    console.log();
    this.updateHeigth();
  }
  updateHeigth() {
    if (this.autoHeight) {
      var clauseHtml: HTMLElement;
      if (this.idTextarea) {
        clauseHtml =
          document.getElementById(this.idTextarea) || new HTMLTextAreaElement();

        if (clauseHtml) {
          clauseHtml.style.height = clauseHtml.scrollHeight + "px";
          if (this.minHeight) {
            clauseHtml.style.minHeight =
              clauseHtml.scrollHeight > this.minHeight
                ? clauseHtml.scrollHeight + "px"
                : this.minHeight + "px";
          } else {
            clauseHtml.style.minHeight = clauseHtml.scrollHeight + "px";
          }
          // model.height = clauseHtml.scrollHeight + 2;
        }
      }
    }
  }
  ngAfterViewInit() {
    this.cd.detectChanges();
  }
  onTextareaClick() {
    this.textAreaClick.emit();
  }
  onTextareaBlur() {
    this.textAreaBlur.emit();
  }
}
