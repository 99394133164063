import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ViewChildren,
  OnDestroy,
  HostListener,
  Inject,
  LOCALE_ID,
  Input,
  inject,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {
  ConfigsService,
  NgxTranslateService,
  SafeHtmlPipe,
} from "@overa/shared";
import { ConfirmationService, MessageService } from "primeng/api";
import { Charger } from "src/app/models/charger/charger.model";
import { Shipowner } from "src/app/models/shipowner/shipowner.model";
import { ChargerShipOwner } from "src/app/models/charger-shipowner/charger-shipowner.model";
import { ChargerService } from "src/app/services/charger.service";
import { ShipownerService } from "src/app/services/shipowner.service";
import { StorageService } from "@overa/storage";
import { NgxSpinnerService } from "ngx-spinner";
import {
  CifValidation,
  ClhValidators,
  WebValidation,
} from "src/app/utils/validators";
import {
  CanDeactivateGuard,
  ComponentCanDeactivate,
} from "src/app/services/canDeactivateGuard.service";
import { Observable } from "rxjs/internal/Observable";
import { LanguageCode } from "src/app/models/enum/language-code.model";
import { Country } from "src/app/models/country/country.model";
import { Constants } from "../../../utils/constants";
import { PermissionsService } from "../../../services/permissions.service";
import { Location } from "@angular/common";
import { CLHGlobals } from "src/app/utils/globals";
import { OveraMessagesService } from "@overa/components/info-dialog";

@Component({
  selector: "app-charger-form",
  templateUrl: "./charger-form.component.html",
  styleUrls: ["./charger-form.component.scss"],
  providers: [MessageService, ConfirmationService],
})
export class ChargerFormComponent
  implements OnInit, AfterViewInit, OnDestroy, ComponentCanDeactivate {
  today: Date = new Date();
  isNew: boolean = true;
  isUpdate: boolean = false;
  updateRoute: boolean = true;
  chargerToForm!: Charger;

  storageId = "";
  chargerLogoFile = null;
  chargerLogoPhoto: string | ArrayBuffer = "";
  loadedPhoto = false;
  config = inject(ConfigsService);
  // ports: string[];
  // portsD: string[];
  // selectedLoadingPorts = [];
  // selectedDischargingPorts = [];
  // initialLoadingPorts = [];
  // initialDischargingPorts = [];
  // readonly regexValidator = this.validators.myRegexValidator;
  hasUnsavedChanges: boolean = false;
  changesInLogo: boolean = false;
  exitToSave: boolean = false;
  noConfirmationServiceToExit: boolean = false;
  loadedClauses = false;
  localeLanguage!: string;
  listCountries: Country[] = [];
  listCountriesAll: Country[] = [];
  selectedCountry!: Country;
  form!: FormGroup;
  onTheList: boolean = false;
  public id!: string;
  shipownersList: Shipowner[] = [];
  selectedShipowners: Shipowner[] = [];
  selectShipowner?: Shipowner;
  columns: any[] = [];
  isDefault: boolean = false;
  comments: string = "";
  styleButtonYesConfirmDialog: string = "styleButtonYesConfirmDialog";
  styleButtonNoConfirmDialog: string = "styleButtonNoConfirmDialog";
  containerConfirmDialog: string = "containerConfirmDialog";
  isClh = false;
  initialUsers = [];
  showButtonAfterSave: boolean = true;
  canViewPanels = false;
  entityDefinition = {
    displayName: "Charger",
    entityDefinitionId: null,
    internalCode: null,
    description: null,
    isExtension: null,
    isAbstract: false,
    hasIdentityUser: null,
    hasGlobalAccessLevel: null,
    hasAccessLevel: null,
    entityAttributes: null,
    entityDefinitionRelations: null,
    accessLevel: null,
  };
  isAdmin = false;
  readonly = false;
  isValidToListClauses: boolean = true;

  // showTable: boolean = false;
  constructor(
    private translate: NgxTranslateService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private chargerService: ChargerService,
    private shipOwnerService: ShipownerService,
    public messagesService: OveraMessagesService,
    private confirmationService: ConfirmationService,
    private storageService: StorageService,
    private spinner: NgxSpinnerService,
    private globals: CLHGlobals,
    private validators: ClhValidators,
    private canDeactivate: CanDeactivateGuard,
    private permissionsService: PermissionsService,
    private fb: FormBuilder,
    private location: Location
  ) {
    // this.ports = this.globals.catalogue
    //   .filter((x) => x.type == CatalogueType.PORT)
    //   .map((x) => x.value);
    // this.portsD = [...this.ports];
    console.log(this.globals.user);
    this.storageId = this.config.getModuleConfig("overa-storage", "storageId");

    if (
      this.globals.user.isAdmin ||
      this.globals.user.isGlobalAdmin ||
      this.globals.user.roles
        .map((x) => x.name)
        .includes(this.globals.clhRoleName)
    ) {
      this.isAdmin = true;
      this.isClh = this.globals.isClh;
    }
  }

  ngOnInit() {
    if (!this.permissionsService.hasPermissionToEdit(this.activatedRoute)) {
      this.readonly = true;
    }
    if (this.globals.user.isGlobalAdmin) {
      this.canViewPanels = true;
    }
    console.log("ONINIT");
    const rolesToViewTabs = [this.globals.clhRoleName];
    if (this.globals.currentCharger && !this.isClh) {
      const currentContact = this.globals.currentCharger.contacts.find(
        (x) => x.email == this.globals.user.email
      );
      if (!currentContact?.isPrincipal) {
        this.readonly = true;
      }
    }

    console.log("IDIOMA", this.translate.currentLang);
    if (
      this.globals.user.roles
        .map((x) => x.name)
        .some((r) => rolesToViewTabs.includes(r))
    ) {
      this.canViewPanels = true;
    }
    if (
      this.globals.user.roles
        .map((x) => x.name)
        .includes(this.globals.clhRoleName)
    ) {
      this.canViewPanels = true;
    }
    if (this.globals.user.isGlobalAdmin) {
      this.canViewPanels = true;
    }

    this.hasUnsavedChanges = false;
    this.noConfirmationServiceToExit = false;

    this.showButtonAfterSave = true;
    this.updateRoute = true;
    this.selectShipowner = undefined;
    this.initialForm();
    this.resetForm();

    this.columns = [
      {
        id: "SHIPOWNER_DATA.SHIPOWNER_FORM.HEAD_COL0",
        sort: "charteringCompany",
      },
      { id: "SHIPOWNER_DATA.SHIPOWNER_FORM.HEAD_COL1", sort: "dischargeDate" },
      { id: "SHIPOWNER_DATA.SHIPOWNER_FORM.HEAD_COL2", sort: "isSelected" },
      { id: "SHIPOWNER_DATA.SHIPOWNER_FORM.HEAD_COL3", sort: "comment" },
      { id: "SHIPOWNER_DATA.SHIPOWNER_FORM.HEAD_COL4", sort: "modify" },
    ];

    this.localeLanguage = this.translate.currentLang;
    if (this.localeLanguage) {
      if (this.localeLanguage == "es") {
        this.listCountriesAll = this.globals?.countriesES?.length
          ? this.globals.countriesES
          : [];
      }
      if (this.localeLanguage == "en") {
        this.listCountriesAll = this.globals?.countriesEN?.length
          ? this.globals.countriesEN
          : [];
      }
      this.listCountries = [...this.listCountriesAll];
    }

    this.spinner.show();

    this.activatedRoute.params.subscribe({
      next: (paramsId) => {
        this.id = paramsId["id"];

        this.shipOwnerService.get().subscribe({
          next: (shipownersListResult) => {
            // console.log(shipownersListResult);
            if (shipownersListResult) {
              this.shipownersList = shipownersListResult;
              console.log(this.shipownersList);
              this.isNew = this.id == "new";
              if (this.isNew) {
                // console.log('new');
                this.isNew = true;
                this.form.get("refCharger")?.disable();
                this.chargerToForm = new Charger();
                this.initialUsers = [];
                this.chargerToForm.contacts = [];
                this.chargerToForm.clauses = [];
                this.chargerLogoPhoto = Constants.NO_LOGO_SHEET_DATA;

                this.selectedShipowners = [];
                this.setForm();
                this.spinner.hide();
              } else {
                // this.activatedRoute.params.subscribe({
                //   next: (paramsId) => {
                console.log(this.id);
                this.chargerService.getById(this.id).subscribe({
                  next: (chargerByIdResult) => {
                    // console.log(chargerByIdResult);
                    if (chargerByIdResult) {
                      console.log(chargerByIdResult);
                      this.chargerToForm = chargerByIdResult;
                      //this.initialUsers = [...chargerByIdResult.users];
                      // this.initialLoadingPorts = this.chargerToForm.loadingPorts;
                      // this.initialDischargingPorts = this.chargerToForm.dischargingPorts;
                      this.setForm();
                      //this.form.get('logo').valueChanges.subscribe((file) => {
                      //  if (file && file.files && file.files.length > 0) {
                      //    this.chargerLogoFile = file.files[0];
                      //    this.changesInLogo = true;
                      //  } else if (file === null) {
                      //    this.chargerLogoFile = null;
                      //    this.chargerLogoPhoto = null;
                      //  }
                      //});
                      this.form.get("refCharger")?.disable();
                      this.isUpdate = true;
                      if (chargerByIdResult.logoId != null) {
                        try {
                          this.storageService
                            .getFileContent(chargerByIdResult.logoId)
                            .subscribe((file) => {
                              this.chargerLogoPhoto =
                                "data:image/jpeg;base64," + file.content;
                              this.loadedPhoto = true;
                            });
                        } catch (ex) {
                          console.log(ex);
                        }
                      } else {
                        this.chargerLogoFile = null;
                        //this.chargerLogoPhoto = null;
                        this.chargerLogoPhoto = Constants.NO_LOGO_SHEET_DATA;
                      }
                      this.chargerToForm.logoId = chargerByIdResult.logoId;

                      this.spinner.hide();
                    } else {
                      this.spinner.hide();
                    }
                  },
                  error: (err) => {
                    console.error(err);
                    this.spinner.hide();
                  },
                });
              }
            } else {
              this.spinner.hide();
            }
          },
          error: (err) => {
            console.error(err);
            this.spinner.hide();
          },
        });
      },
      error: (err) => {
        console.error(err);
        this.spinner.hide();
      },
    });
  }
  initialForm() {
    this.form = this.fb.group({
      name: new FormControl("", [Validators.required]),
      refCharger: new FormControl("", [Validators.required]),
      cif: new FormControl("", [Validators.required, CifValidation]),
      dischargeDate: new FormControl(new Date()),
      //  logo: new FormControl(''),
      web: new FormControl("", [Validators.required, WebValidation]),
      address: new FormControl("", [Validators.required]),
      country: new FormControl("", [Validators.required]),
      postalCode: new FormControl("", [Validators.required]),
      province: new FormControl("", [Validators.required]),
      town: new FormControl("", [Validators.required]),
      // loadingPorts: new FormControl([], []),
      // dischargingPorts: new FormControl([], []),
      addressComm: new FormControl(undefined, [Validators.required]),
    });
  }
  changeTab(event: any) {
    console.log(event);
    if (event.index == 2) {
      this.loadedClauses = true;
    }
  }
  clickClauses() {
    this.loadedClauses = true;
    console.log("clauses clicked");
  }
  setForm() {
    this.onTheList = this.chargerToForm?.country ? true : false;
    if (this.onTheList) {
      const countryLanguage = this.chargerToForm?.country || new Country();
      if (this.translate.currentLang == "en") {
        countryLanguage.name = countryLanguage.nameEnglish;
      }
    }
    console.log(this.onTheList);
    this.form.setValue({
      refCharger: this.chargerToForm.refCharger || "",
      cif: this.chargerToForm.cif || "",
      dischargeDate: new Date(this.chargerToForm.dischargeDate || this.today),
      name: this.chargerToForm.name || "",

      web: this.chargerToForm.web || "",
      address: this.chargerToForm.address || "",
      postalCode: this.chargerToForm.postalCode || "",
      province: this.chargerToForm.province || "",
      country: this.chargerToForm.country || "",
      town: this.chargerToForm.town || "",
      // loadingPorts: this.chargerToForm.loadingPorts || [],
      // dischargingPorts: this.chargerToForm.dischargingPorts || [],
      addressComm: this.chargerToForm.addressComm || 0,
    });
    // this.selectedLoadingPorts = this.ports.filter((x) =>
    //   this.chargerToForm.loadingPorts.includes(x)
    // );
    // this.selectedDischargingPorts = this.portsD.filter((x) =>
    //   this.chargerToForm.dischargingPorts.includes(x)
    // );
    this.chargerToForm.shipOwners = this.chargerToForm.shipOwners || [];

    this.chargerToForm.routes = this.chargerToForm.routes || [];
    // console.log('CHARGERfORM', this.chargerToForm);
    // console.log('FORM', this.form);
  }
  resetForm() {
    this.form.reset();
    if (this.readonly) {
      this.form.disable();
    }
  }
  activeAccount() { }
  cancel() {
    //this.chargerToForm = null;
    this.location.back();
  }
  onChangeShipOwner() {
    let shipowner = new Shipowner();
    shipowner = this.selectShipowner || new Shipowner();
    shipowner.id = this.selectShipowner?.id;
    shipowner.isSelected = this.selectShipowner?.isSelected;
    shipowner.comment = this.selectShipowner?.comment;
    shipowner.shipOwnerChargerId = this.selectShipowner?.shipOwnerChargerId;
    this.selectedShipowners.push(shipowner);
    const indice = this.shipownersList.findIndex(
      (ship) => ship.id === this.selectShipowner?.id
    );
    this.shipownersList.splice(indice, 1);
    this.selectShipowner = undefined;
    this.selectedShipowners = [...this.selectedShipowners];
    this.shipownersList = [...this.shipownersList];
  }
  /*
  deleteShipOwner(shipowner: Shipowner) {
    let messageAccept = "";
    let trmessage = "";
    this.translate.get("COMMONS.ASK_DELETE_RECORD").subscribe((a: string) => {
      trmessage = a;
    });

    this.translate.get("ACTION.YES").subscribe((a: string) => {
      messageAccept = a;
    });
    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        // console.log(shipowner);
        let indice = this.selectedShipowners.findIndex(
          (ship) => ship.id === shipowner.id
        );
        this.selectedShipowners.splice(indice, 1);
        this.selectedShipowners = [...this.selectedShipowners];
        shipowner.comment = "";
        shipowner.isSelected = false;
        this.shipownersList.push(shipowner);
      },
      reject: () => { },
    });
  }
  */

  editCharger() {
    console.log(this.chargerToForm);
    this.chargerService.edit(this.chargerToForm).subscribe({
      next: (chargerResult) => {
        if (chargerResult) {
          this.spinner.hide();
          this.messagesService.showToast(
            this.translate.get("MESSAGES.SUCCESS.UPDATE"),
            this.translate.get("MESSAGES.SUCCESS.UPDATE_LONG", {
              type: this.translate.get("CHARTERER_DATA_SHEET.CHARGER"),
              code: chargerResult.name,
            }),
            "success",
            ""
          );
          this.noConfirmationServiceToExit = true;
          // console.log('ServiceToEXIT', this.noConfirmationServiceToExit);
          const currentUrl = "/chargers/" + chargerResult.id;
          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() => {
              this.router.navigate([currentUrl]);
            });

          //this.router.navigate(["chargers"]);
        } else {
          this.showButtonAfterSave = true;
          this.spinner.hide();
        }
      },
      error: (err) => {
        console.error(err);
        this.showButtonAfterSave = true;
        this.spinner.hide();
        this.messagesService.showToast(
          'ERROR',
          this.translate.get(err),
          "error",
          ""
        );
      },
    });
  }
  addCharger() {
    console.log(this.chargerToForm);
    this.chargerService.add(this.chargerToForm).subscribe({
      next: (chargerResult) => {
        if (chargerResult) {
          this.spinner.hide();
          this.messagesService.showToast(
            this.translate.get("MESSAGES.SUCCESS.CREATE"),
            this.translate.get("MESSAGES.SUCCESS.CREATE_LONG", {
              type: this.translate.get("CHARTERER_DATA_SHEET.CHARGER"),
              code: chargerResult.name,
            }),
            "success",
            ""
          );
          this.noConfirmationServiceToExit = true;
          this.onTheList = false;
          const currentUrl = "/chargers/" + chargerResult.id;
          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() => {
              this.router.navigate([currentUrl]);
            });
        } else {
          this.showButtonAfterSave = true;
          this.spinner.hide();
        }
      },
      error: (err) => {
        console.error(err);
        this.spinner.hide();
        this.showButtonAfterSave = true;
        this.messagesService.showToast(
          'ERROR',
          this.translate.get(err),
          "error",
          ""
        );
      },
    });
  }
  setCharger() {
    //let address = this.form.get('address').value + ',' + this.form.get('province').value + ',' + this.form.get('codePostal').value;
    // console.log(address);
    //this.form.get('address').setValue(address);
    if (!this.form.get("addressComm")?.value) {
      this.form.get("addressComm")?.setValue(0);
    }
    this.chargerToForm = { ...this.chargerToForm, ...this.form.value };
    this.chargerToForm.countryId = this.form.get("country")?.value?.id;
    this.chargerToForm.country = undefined;
    // if (!this.chargerToForm.loadingPorts) {
    //   this.chargerToForm.loadingPorts = [];
    // }
    // if (!this.chargerToForm.dischargingPorts) {
    //   this.chargerToForm.dischargingPorts = [];
    // }
  }
  validateForm() {
    // if (this.chargerToForm.users && this.chargerToForm.users.length) {
    //    var newUsers = this.chargerToForm.users.filter(user => !this.initialUsers.includes(user));
    //    console.log('newUsers', newUsers);
    //    newUsers.forEach(user =>{
    //     this.shipOwnerService.checkUserAvailability(user.userId).subscribe({
    //       next: (isAvailable) => {
    //         if(isAvailable){
    //           console.log('available');
    //         }
    //         else{
    //         }
    //         // console.log(shipownersListResult);
    //       }, error: (err)=>{
    //         console.error(err);
    //       }
    //     });
    //     });
    // }
  }

  selectedCountryAutocomplete(event: any) {
    console.log(event);
  }
  // -------------------- CRUD ---------------------- //
  updateCharger() {
    this.checkCountryId();
    console.log(this.isValidToListClauses);
    this.chargerToForm.clauses.find((item) => {
      console.log(item);
      if (item.description == "" || item.description == "\n") {
        this.isValidToListClauses = false;
        console.log(this.isValidToListClauses);
      }
    });
    if (!this.isValidToListClauses) {
      this.messagesService.showToast(
        "ERROR",
        "MESSAGES.ERROR.CLAUSES_NOT_NULL",
        "error",
        ""
      );
    }

    if (this.form.valid && this.isValidToListClauses && this.onTheList) {
      this.spinner.show();
      this.showButtonAfterSave = false;
      this.setCharger();
      // console.log(this.selectedShipowners);
      this.chargerToForm.shipOwners = [];
      for (const ship of this.selectedShipowners) {
        const shipownerCharger = new ChargerShipOwner();
        if (ship.shipOwnerChargerId) {
          shipownerCharger.id = ship.shipOwnerChargerId;
          shipownerCharger.shipOwnerId = ship.id;
        } else {
          shipownerCharger.shipOwnerId = ship.id;
        }
        shipownerCharger.isSelected = ship.isSelected;
        shipownerCharger.comment = ship.comment;
        this.chargerToForm.shipOwners.push(shipownerCharger);
      }
      this.selectedShipowners = [...this.selectedShipowners];

      console.log(this.chargerToForm);

      if (this.chargerToForm.logoId != null) {
        if (this.chargerLogoFile != null) {
          //tenía y se ha modificado
          this.storageService.deleteFile(this.chargerToForm.logoId).subscribe({
            next: (deleteRes) => {
              if (this.chargerLogoFile) {
                this.storageService
                  .uploadFile(this.chargerLogoFile, this.storageId)
                  .subscribe({
                    next: (uploadRes) => {
                      this.chargerToForm.logoId = uploadRes.id;
                      this.editCharger();
                    },
                    error: (err) => {
                      console.error(err);
                      this.showButtonAfterSave = true;
                      this.spinner.hide();
                    },
                  });
              }
            },
            error: (err) => {
              console.error(err);
              this.showButtonAfterSave = true;
              this.spinner.hide();
            },
          });
          this.spinner.hide();
        } else {
          this.editCharger();
          //tenía y se ha borrado
          //this.storageService.deleteFile(this.chargerToForm.id).subscribe({
          //  next: (deleteRes) => {
          //    this.chargerToForm.logoId = null;
          //    this.editCharger();
          //  },
          //  error: (err) => {
          //    console.error(err);
          //    this.showButtonAfterSave = true;
          //    this.spinner.hide();
          //  },
          //});
        }
      } else {
        if (this.chargerLogoFile != null) {
          //no tenía y se le ha añadido
          this.storageService
            .uploadFile(this.chargerLogoFile, this.storageId)
            .subscribe({
              next: (uploadRes) => {
                this.chargerToForm.logoId = uploadRes.id;
                this.editCharger();
              },
              error: (err) => {
                console.error(err);
                this.showButtonAfterSave = true;
                this.spinner.hide();
              },
            });
        } else {
          //no tenía y no se añade
          this.editCharger();
        }
      }
    } else {
      if (!this.onTheList) {
        this.messagesService.showToast(
          "ERROR",
          "MESSAGES.ERROR.COUNTRY",
          "error",
          ""
        );
      }

      this.isValidToListClauses = !this.isValidToListClauses ? true : true;
    }
  }

  deleteCharger() {
    this.showButtonAfterSave = false;
    const messageAccept = this.translate.get("ACTION.YES");
    const trmessage = this.translate.get("COMMONS.ASK_DELETE_RECORD");

    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.spinner.show();
        this.chargerService.delete(this.id).subscribe({
          next: (data) => {
            this.spinner.hide();
            this.router.navigate(["chargers"]);
            this.messagesService.showToast(
              this.translate.get("MESSAGES.SUCCESS.DELETE"),
              this.translate.get("MESSAGES.SUCCESS.DELETE_LONG", {
                type: this.translate.get("CHARTERER_DATA_SHEET.CHARGER"),
                code: this.chargerToForm.name,
              }),
              "success",
              ""
            );
            // this.showButtonAfterSave = true;
          },
          error: (err) => {
            this.spinner.hide();
            this.messagesService.showToast(
              "ERROR",
              "MESSAGES.ERROR.DELETE_CONFLICT",
              "error",
              ""
            );
            console.error(err);
            this.showButtonAfterSave = true;
          },
        });
      },
      reject: () => {
        this.showButtonAfterSave = true;
      },
    });
  }

  createCharger() {
    this.checkCountryId();

    if (this.form.valid && this.onTheList) {
      this.spinner.show();
      this.showButtonAfterSave = false;
      this.setCharger();
      this.chargerToForm.shipOwners = [];
      for (const ship of this.selectedShipowners) {
        const shipownerCharger = new ChargerShipOwner();
        shipownerCharger.shipOwnerId = ship.id;
        shipownerCharger.isSelected = ship.isSelected;
        shipownerCharger.comment = ship.comment;
        this.chargerToForm.shipOwners.push(shipownerCharger);
      }
      if (this.chargerLogoFile != null) {
        this.storageService
          .uploadFile(this.chargerLogoFile, this.storageId)
          .subscribe({
            next: (uploadFileResult) => {
              this.chargerToForm.logoId = uploadFileResult.id;
              this.addCharger();
            },
            error: (err) => {
              console.error(err);
              this.spinner.hide();
              this.showButtonAfterSave = true;
            },
          });
      } else {
        this.addCharger();
      }
    } else {
      if (!this.onTheList) {
        this.messagesService.showToast(
          "ERROR",
          "MESSAGES.ERROR.COUNTRY",
          "error",
          ""
        );
      }
    }
  }
  // -------------------- END CRUD ---------------------- //

  // selectedLoadingPortChange(event: string[]) {
  //   console.log(event);
  //   this.chargerToForm.loadingPorts = event;
  // }
  // selectedDischargingPortChange(event: string[]) {
  //   console.log(event);
  //   this.chargerToForm.dischargingPorts = event;
  // }
  ngAfterViewInit() { }

  ngOnDestroy() {
    // console.log('destroy');
  }
  checkToSaveToExit() {
    if (!this.noConfirmationServiceToExit) {
      Object.keys(this.form.controls).forEach((ctrl) => {
        if (this.form.get(ctrl)?.value) {
          if (ctrl === "dischargeDate") {
            if (
              this.form.get(ctrl)?.value >
              new Date(this.chargerToForm[ctrl] || new Date()) ||
              this.form.get(ctrl)?.value <
              new Date(this.chargerToForm[ctrl] || new Date())
            ) {
              this.hasUnsavedChanges = true;
            }
            //} else if (ctrl === 'address') {
            //  if (this.form.get(ctrl).value != arrayAddress[0]) {
            //    this.hasUnsavedChanges = true;
            //  }
            //} else if (ctrl === 'codePostal') {
            //  if (this.form.get(ctrl).value != arrayAddress[2]) {
            //    this.hasUnsavedChanges = true;
            //  }
            //} else if (ctrl === 'province') {
            //  if (this.form.get(ctrl).value != arrayAddress[1]) {
            //    this.hasUnsavedChanges = true;
            //  }
          } else if (ctrl === "country") {
            let name = this.form.get(ctrl)?.value;
            if (name?.id) {
              name = name.name.toLowerCase();
            } else {
              name = name.toLowerCase().trim();
            }
            if (name != this.chargerToForm[ctrl]?.name?.toLowerCase()) {
              this.hasUnsavedChanges = true;
            }
          } else if (ctrl === "logo") {
            if (this.changesInLogo) {
              this.hasUnsavedChanges = true;
            }
          }
          //  else if (ctrl === 'loadingPorts') {
          //   let value = this.form.get(ctrl).value;
          //   if (Array.isArray(value)) {

          //     if (
          //       JSON.stringify([...this.initialLoadingPorts].sort()) !=
          //       JSON.stringify([...value].sort())
          //     ) {
          //       this.hasUnsavedChanges = true;
          //     }
          //   }
          // } else if (ctrl === 'dischargingPorts') {
          //   let value = this.form.get(ctrl).value;
          //   if (Array.isArray(value)) {

          //     if (
          //       JSON.stringify([...this.initialDischargingPorts].sort()) !=
          //       JSON.stringify([...value].sort())
          //     ) {
          //       this.hasUnsavedChanges = true;
          //     }
          //   }
          // }
          else if (ctrl === "addressComm") {
            if (this.form.get(ctrl)?.value != this.chargerToForm[ctrl]) {
              this.hasUnsavedChanges = true;
            }
          } else {
            if (
              this.form.get(ctrl)?.value !=
              this.chargerToForm[ctrl as keyof Charger]
            ) {
              this.hasUnsavedChanges = true;
            }
          }
        }
      });
    } else {
      this.hasUnsavedChanges = false;
    }
  }
  checkCanDeactive(): boolean | Observable<boolean> {
    this.checkToSaveToExit();

    if (this.hasUnsavedChanges) {
      const trmessage = this.translate.get("COMMONS.ASK_SAVE_TO_EXIT");
      const messageAccept = this.translate.get("ACTION.YES");

      return new Observable((observer) => {
        this.confirmationService.confirm({
          message: trmessage,
          header: "",
          acceptLabel: messageAccept,
          icon: "pi pi-exclamation-triangle",
          accept: () => {
            observer.next(true);
            observer.complete();
          },
          reject: () => {
            observer.next(false);
            observer.complete();
          },
        });
      });
    } else {
      return true;
    }
  }
  onFileSelected(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    this.chargerLogoFile = file;
    this.changesInLogo = true;
    reader.readAsDataURL(file);
    reader.onload = () => {
      const dataImage = reader.result;
      this.chargerLogoPhoto = dataImage || "";
      this.loadedPhoto = true;
    };
  }
  filterlistCountries(event: any) {
    // console.log(event);
    this.onTheList = false;
    const filtered: any[] = [];
    const query = event.query;
    // console.log(this.listCountries);
    this.listCountries = [...this.listCountriesAll];
    for (const country of this.listCountries) {
      if (country?.name?.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }
    this.listCountries = filtered;
  }
  onCountrySelect(event: any) {
    console.log(event);
    console.log(this.form.get("country")?.value);
    this.onTheList = true;
  }
  checkCountryId() {
    let name = this.form.get("country")?.value;
    if (name?.id) {
      name = name.name.toLowerCase();
    } else {
      name = name.toLowerCase().trim();
    }
    if (!this.onTheList) {
      if (!this.form.get("country")?.value?.id) {
        this.listCountriesAll.forEach((country) => {
          if (country?.name?.toLowerCase() === name) {
            this.form.get("country")?.setValue(country);
            this.onTheList = true;
          }
        });
      }
    }
  }
}
