import { Clause } from "../clause/clause.model";
import { Shipowner } from "../shipowner/shipowner.model";
import { ChargerShipOwner } from "../charger-shipowner/charger-shipowner.model";
import { ChargerContact } from "./charger-contact.model";
import { ChargerUser } from "./charger-user.model";
import { Route } from "./route.model";
import { ContactList } from "../charger-shipowner/contact-list.model";
import { Country } from "../country/country.model";

export class Charger {
  id?: string;
  refCharger?: number;
  cif?: string;
  name?: string;
  dischargeDate?: Date;
  web?: string;
  address?: string;
  postalCode?: string;
  province?: string;
  town?: string;
  merchandiseType?: string;
  contacts: ChargerContact[];
  users: ChargerUser[] = [];
  shipOwners: ChargerShipOwner[];
  logoId?: string;
  logo?: any;
  clauses: Clause[];
  // loadingPorts: string[];
  // dischargingPorts: string[];
  addressComm?: number;
  routes?: Route[];
  contactLists: ContactList[] = [];
  country?: Country;
  countryName?: string;
  countryId?: string;

  constructor(obj?: Partial<Charger>) {
    this.refCharger = (obj && obj.refCharger) || undefined;
    this.id = (obj && obj.id) || undefined;
    this.cif = (obj && obj.cif) || undefined;
    this.name = (obj && obj.name) || undefined;
    this.dischargeDate = (obj && obj.dischargeDate) || new Date();
    this.web = (obj && obj.web) || undefined;
    this.address = (obj && obj.address) || undefined;
    this.merchandiseType = (obj && obj.merchandiseType) || undefined;
    this.addressComm = (obj && obj.addressComm) || undefined;

    this.contacts = (obj && obj.contacts) || [];
    this.shipOwners = (obj && obj.shipOwners) || [];
    this.logoId = (obj && obj.logoId) || undefined;
    this.clauses = (obj && obj.clauses) || [];
    this.routes = (obj && obj.routes) || [];
    // this.loadingPorts = (obj && obj.loadingPorts) || [];
    // this.dischargingPorts = (obj && obj.dischargingPorts) || [];
  }
}
