import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AccountService } from "@overa/security";
import { ChooseUserService } from "../../services/choose-user.service";
import { CLHGlobals } from "src/app/utils/globals";

@Component({
  selector: "app-charger-data-sheet",
  templateUrl: "./charger-data-sheet.component.html",
  styleUrls: ["./charger-data-sheet.component.scss"],
})
export class ChargerDataSheetComponent implements OnInit {
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private globals: CLHGlobals,
    private accountServive: AccountService,
    private chooseUserService: ChooseUserService
  ) {}

  ngOnInit() {
    if (
      this.globals.user.roles
        .map((r) => r.name.toLowerCase())
        .includes(this.globals.clhRoleName.toLowerCase()) ||
      this.globals.user.isGlobalAdmin
    ) {
      //this.globals.currentCharger = null;
      //this.globals.currentShipOwner = null;

      this.chooseUserService.setNull();
    }
  }
  selectCharge(event: any) {
    console.log(event);
    if (event === "new") {
      this.router.navigate(["chargers", event]);
    } else {
      this.router.navigate(["chargers", event.id]);
    }
  }
}
