import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AccountService } from "@overa/security";

import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmationService, MessageService } from "primeng/api";
import { forkJoin, of, switchMap } from "rxjs";
import { ChatGroup } from "src/app/models/chat/chatGroup.model";
import { ChatMessage } from "src/app/models/chat/chatMessage.model";
import { ChatParticipant } from "src/app/models/chat/chatParticipant.model";
import { NegotiationOfferShipOwner } from "src/app/models/offers/negotiation-offer-shipowner.model";
import { Recap } from "src/app/models/offers/recap.model";
import { OfferService } from "src/app/services/offer.service";
import { ChatHelper } from "src/app/utils/chat-helper.utils";
import { CLHGlobals } from "src/app/utils/globals";
import { DatePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { OfferComplete } from "src/app/models/offers/offer-complete.model";
import { UserType } from "src/app/models/enum/user-type.model";
import { OfferShipOwnerState } from "src/app/models/offers/offer-shipowner-state.model";
import { StateOfferShipOwner } from "src/app/models/enum/state-offer-shipowner.model";
import { PermissionsService } from "../../services/permissions.service";
import { OveraMessagesService } from "@overa/components/info-dialog";
import { NgxTranslateService } from "@overa/shared";

@Component({
  selector: "app-operative-view",
  templateUrl: "./operative-view.component.html",
  styleUrls: ["./operative-view.component.scss"],
  providers: [MessageService, ConfirmationService, DatePipe],
})
export class OperativeViewComponent implements OnInit {
  // @ViewChild('overamap') overamap
  isLoaded: boolean = false;
  idOfferShipOwner: string;
  isClh: boolean = false;
  offerBinding: NegotiationOfferShipOwner;
  vesselData: any;
  chatValues: Array<{
    offerId?: string;
    offerShipOwnerId?: string;
    currentParticipant: ChatParticipant;
    group: ChatGroup;
    windowName: string;
    historicMessages: ChatMessage[];
    unReadMessages: number;
  }> = [];
  isShipowner: boolean = false;
  isCharterer: boolean = false;
  form: FormGroup;
  markers: any[] = [];
  isScreenLarge: boolean = true;
  readonlyOperative: boolean = false;
  editingImo = false;
  imoNumber = "";
  allMethodsCompleted: boolean = false;
  statusReadonly = [StateOfferShipOwner.COMPLETED];
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.isScreenLarge = window.innerWidth >= 960;
  }
  constructor(
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private offerService: OfferService,
    private globals: CLHGlobals,
    public accountService: AccountService,
    private chatHelper: ChatHelper,
    private fb: FormBuilder,
    public messageService: OveraMessagesService,
    private confirmationService: ConfirmationService,
    private translate: NgxTranslateService,
    private permissionsService: PermissionsService,
    public router: Router,
    public cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.isScreenLarge = window.innerWidth >= 960;
    this.idOfferShipOwner = this.route.snapshot.params?.["id"];
    console.log(this.idOfferShipOwner);
    if (this.globals.currentShipOwner) {
      this.isShipowner = true;
    }
    if (this.globals.currentCharger) {
      this.isCharterer = true;
    }
    if (
      !this.globals.currentCharger &&
      !this.globals.currentShipOwner &&
      this.accountService.currentUser.roles
        .map((x) => x.name)
        .includes("CLH Admin")
    ) {
      this.isClh = true;
    }
    this.spinner.show();
    if (this.idOfferShipOwner) {
      this.offerService.getShipOwnerRecap(this.idOfferShipOwner).subscribe({
        next: (value) => {
          this.offerBinding = value;
          console.log("OFFER", this.offerBinding);
          if (this.isCharterer) {
            this.readonlyOperative =
              this.offerBinding.offerShipOwnerState ===
                StateOfferShipOwner.RECAP_CONFIRMED ||
              this.offerBinding.offerShipOwnerState ===
                StateOfferShipOwner.COMPLETED_CHARTERER_PENDING
                ? false
                : true;
          }
          if (this.isShipowner) {
            this.readonlyOperative =
              this.offerBinding.offerShipOwnerState ===
                StateOfferShipOwner.RECAP_CONFIRMED ||
              this.offerBinding.offerShipOwnerState ===
                StateOfferShipOwner.COMPLETED_SHIPOWNER_PENDING
                ? false
                : true;
          }
          if (this.isClh && !this.isCharterer && !this.isShipowner) {
            this.readonlyOperative = true;
          }
          if (
            this.statusReadonly.includes(this.offerBinding.offerShipOwnerState)
          ) {
            this.readonlyOperative = true;
          }
          if (!this.permissionsService.hasPermissionToEdit(this.route)) {
            this.readonlyOperative = true;
          }
          console.log("readonlyOperative", this.readonlyOperative);
          this.initialForm();
          this.setChatOptions(this.offerBinding);
          this.selectMarkers();
          // this.setChatOptions(this.offerBinding);
          this.checkPermissions();
          this.isLoaded = true;
          this.spinner.hide();
        },
        error: (err) => {
          console.log("errorOperative", err);
          this.spinner.hide();
        },
      });
    }
  }
  initialForm() {
    this.form = this.fb.group({});
  }
  selectMarkers() {
    this.markers = [];
    const locationIcon = "assets/icons/location.svg";
    const locationIconDischarging = "assets/icons/location-orange.svg";
    if (this.offerBinding.loadingPorts != null) {
      this.offerBinding.loadingPorts.forEach((port) => {
        const markerCat = this.globals.catalogue.find(
          (x) => x.value.trim() == port.trim()
        );
        if (markerCat) {
          const marker = {
            // The below line is equivalent to writing:
            // position: new google.maps.LatLng(-34.397, 150.644)
            position: { lat: markerCat.latitude, lng: markerCat.longitude },
            options: {
              border: "",
              clickable: true,
              icon: locationIcon,
              strokeColor: "blue",
              glyphColor: "blue",
            },
          };
          this.markers.push(marker);
        }
      });
    }
    if (this.offerBinding.dischargingPorts != null) {
      this.offerBinding.dischargingPorts.forEach((port) => {
        const markerCat = this.globals.catalogue.find(
          (x) => x.value.trim() == port.trim()
        );
        if (markerCat) {
          const marker = {
            // The below line is equivalent to writing:
            // position: new google.maps.LatLng(-34.397, 150.644)
            position: { lat: markerCat.latitude, lng: markerCat.longitude },
            options: {
              label: "",
              icon: locationIconDischarging,
            },
          };
          this.markers.push(marker);
        }
      });
    }
  }
  setChatOptions(offerBinding: NegotiationOfferShipOwner) {
    const isClh = this.accountService.currentUser.roles
      .map((x) => x.name)
      .includes("CLH Admin");

    if (this.globals.currentCharger || isClh) {
      // En esta pantall no deberia de poder entrar un cargador, ya que a cargador va a firmOffer
      //TODO: comprobar si borrar, clh tampoco tiene acceso a esta pantalla, va desde firmoffer
      const clhChargerSubscriptions = this.chatHelper
        .getChatGroupChargerCLH(offerBinding.offerId)
        .pipe(
          switchMap((group) => {
            return forkJoin({
              windowName: isClh ? offerBinding.chargerName : "CLH",
              currentParticipant: this.chatHelper.getCurrentParticipant(
                this.globals.user
              ),
              group: of(group),
              historicMessages: this.chatHelper.getMessageHistory(group.id),
            });
          })
        );
      clhChargerSubscriptions.subscribe(
        ({ windowName, currentParticipant, group, historicMessages }) => {
          if (windowName && currentParticipant && group && historicMessages) {
            const obj = {
              offerId: offerBinding.offerId,
              currentParticipant: currentParticipant,
              windowName: isClh ? offerBinding.chargerName : "CLH",
              group: group,
              historicMessages: historicMessages.map((s) =>
                this.chatHelper.setReplyBasedOnCurrentUser(s)
              ),
              unReadMessages: historicMessages
                .map((s) => this.chatHelper.setReplyBasedOnCurrentUser(s))
                .filter(
                  (s) => s.participantId != currentParticipant.id && !s.seen
                ).length,
            };
            this.chatValues.push(obj);
            this.allMethodsCompleted = !isClh ? false : true;
          }
        }
      );
      if (!isClh) {
        this.allMethodsCompleted = false;
        const shipOwnerChargerSubscriptions = this.chatHelper
          .getChatGroup(offerBinding.offerShipOwnerId)
          .pipe(
            switchMap((group) => {
              return forkJoin({
                windowName: offerBinding.shipOwnerName,
                currentParticipant: this.chatHelper.getCurrentParticipant(
                  this.globals.user
                ),
                group: of(group),
                historicMessages: this.chatHelper.getMessageHistory(group.id),
              });
            })
          );
        shipOwnerChargerSubscriptions.subscribe(
          ({ windowName, currentParticipant, group, historicMessages }) => {
            if (windowName && currentParticipant && group && historicMessages) {
              const obj = {
                offerShipOwnerId: offerBinding.offerShipOwnerId,
                currentParticipant: currentParticipant,
                windowName: offerBinding.shipOwnerName,
                group: group,
                historicMessages: historicMessages.map((s) =>
                  this.chatHelper.setReplyBasedOnCurrentUser(s)
                ),
                unReadMessages: historicMessages
                  .map((s) => this.chatHelper.setReplyBasedOnCurrentUser(s))
                  .filter(
                    (s) => s.participantId != currentParticipant.id && !s.seen
                  ).length,
              };
              this.chatValues.push(obj);
              this.chatValues = [...this.chatValues];
              this.allMethodsCompleted = true;
            }
          }
        );
      }
    }
    if (this.globals.currentShipOwner || isClh) {
      //Chat shipownerClh
      const clhShipownerSubscriptions = this.chatHelper
        .getChatGroupCLHOfferShipOwner(offerBinding.offerShipOwnerId)
        .pipe(
          switchMap((group) => {
            return forkJoin({
              windowName: isClh ? offerBinding.shipOwnerName : "CLH",
              currentParticipant: this.chatHelper.getCurrentParticipant(
                this.globals.user
              ),
              group: of(group),
              historicMessages: this.chatHelper.getMessageHistory(group.id),
            });
          })
        );
      clhShipownerSubscriptions.subscribe(
        ({ windowName, currentParticipant, group, historicMessages }) => {
          if (windowName && currentParticipant && group && historicMessages) {
            const obj = {
              offerShipOwnerId: offerBinding.offerShipOwnerId,
              currentParticipant: currentParticipant,
              windowName: isClh ? offerBinding.shipOwnerName : "CLH",
              group: group,
              historicMessages: historicMessages.map((s) =>
                this.chatHelper.setReplyBasedOnCurrentUser(s)
              ),
              unReadMessages: historicMessages
                .map((s) => this.chatHelper.setReplyBasedOnCurrentUser(s))
                .filter(
                  (s) => s.participantId != currentParticipant.id && !s.seen
                ).length,
            };
            this.chatValues.push(obj);
            this.allMethodsCompleted = !isClh ? false : true;
          }
        }
      );
      if (!isClh) {
        const shipOwnerChargerSubscriptions = this.chatHelper
          .getChatGroup(offerBinding.offerShipOwnerId)
          .pipe(
            switchMap((group) => {
              return forkJoin({
                windowName: offerBinding.chargerName,
                currentParticipant: this.chatHelper.getCurrentParticipant(
                  this.globals.user
                ),
                group: of(group),
                historicMessages: this.chatHelper.getMessageHistory(group.id),
              });
            })
          );
        shipOwnerChargerSubscriptions.subscribe(
          ({ windowName, currentParticipant, group, historicMessages }) => {
            if (windowName && currentParticipant && group && historicMessages) {
              const obj = {
                offerShipOwnerId: offerBinding.offerShipOwnerId,
                currentParticipant: currentParticipant,
                windowName: offerBinding.chargerName,
                group: group,
                historicMessages: historicMessages.map((s) =>
                  this.chatHelper.setReplyBasedOnCurrentUser(s)
                ),
                unReadMessages: historicMessages
                  .map((s) => this.chatHelper.setReplyBasedOnCurrentUser(s))
                  .filter(
                    (s) => s.participantId != currentParticipant.id && !s.seen
                  ).length,
              };
              this.chatValues.push(obj);
              this.allMethodsCompleted = true;
            }
          }
        );
      }
    }
  }
  confirmationHistoric() {
    const trmessage = this.translate.get("OPERATIVE.ASK_FINISH_OPERATIVE");
    const messageAccept = this.translate.get("ACTION.YES");

    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        const offerComplete = new OfferComplete();
        offerComplete.offerShipOwnerId = this.idOfferShipOwner;
        offerComplete.userType = this.isCharterer
          ? UserType.CHARGER
          : UserType.SHIPOWNER;
        this.spinner.show();
        this.offerService.completeOfferShipOwner(offerComplete).subscribe({
          next: () => {
            this.spinner.hide();
            this.messageService.showToast(
              "OK",
              "MESSAGES.SUCCESS.UPDATE_LONG",
              "success",
              ""
            );
            this.router.navigate(["home"]);
          },
          error: (err) => {
            console.log("errorCompleteOffershipowner", err);
            this.spinner.hide();
          },
        });
      },
      reject: () => {},
    });
  }
  checkPermissions() {
    let hasPermission = true;
    if (this.offerBinding) {
      if (this.globals.currentCharger) {
        if (this.globals.currentCharger.id != this.offerBinding.chargerId) {
          hasPermission = false;
        }
      } else if (this.globals.currentShipOwner) {
        if (this.globals.currentShipOwner.id != this.offerBinding.shipOwnerId) {
          hasPermission = false;
        }
      }
    }
    if (!hasPermission) {
      this.router.navigateByUrl("security/access-denied");
    }
  }
  updateImo() {
    this.offerService.getOfferShipOwnerById(this.idOfferShipOwner).subscribe({
      next: (offerShipowner) => {
        offerShipowner.imo = this.offerBinding.imo;
        this.offerService.updateOfferShipowner(offerShipowner).subscribe({
          next: (result) => {
            //TODO update map
            console.log(result);
            const idOfferShipOwner = this.idOfferShipOwner;
            this.idOfferShipOwner = "";
            this.cdRef.detectChanges();
            this.idOfferShipOwner = idOfferShipOwner;
            this.cdRef.detectChanges();
          },
          error: (err) => {
            console.error(err);
          },
        });
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
  showVesselInfo(info) {
    console.log(info);
    this.vesselData = info.vessel;
  }
}
