import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ConfigsService } from "@overa/shared";
import { Observable, of, Subject, ReplaySubject, Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ChargerContact } from "../models/charger/charger-contact.model";
import { TypeContactOld } from "../models/enum/type-contact-old";
import { CLHBaseService } from "./clh-base.service";

@Injectable({ providedIn: "root" })
export class ChargerContactService extends CLHBaseService {
  //DELETE not working

  get() {
    return this.http.get<any[]>(`${this.baseUrl}/chargerContact`);
  }

  getById(id: string) {
    return this.http.get<ChargerContact>(
      `${this.baseUrl}/chargerContact/${id}`
    );
  }

  getByUser(userId: string) {
    return this.http.get<ChargerContact>(
      `${this.baseUrl}/chargerContact/getByUser/${userId}`
    );
  }

  add(chargerContact: ChargerContact) {
    chargerContact.type = TypeContactOld.Admin;
    const endpointUrl = `${this.baseUrl}/chargerContact`;
    return this.http.post<ChargerContact>(endpointUrl, chargerContact);
  }

  edit(chargerContact: ChargerContact) {
    chargerContact.type = TypeContactOld.Admin;
    const endpointUrl = `${this.baseUrl}/chargerContact`;
    return this.http.put<ChargerContact>(endpointUrl, chargerContact);
  }

  delete(id: string) {
    const endpointUrl = `${this.baseUrl}/chargerContact/${id}`;
    return this.http.delete(endpointUrl);
  }
}
