<ngx-spinner></ngx-spinner>
<p-toast></p-toast>
<p-confirmDialog [styleClass]="containerConfirmDialog" [acceptButtonStyleClass]="styleButtonYesConfirmDialog"
  [rejectButtonStyleClass]="styleButtonNoConfirmDialog"></p-confirmDialog>
<div class="card shipownerForm">
  <!--  
  <div class="card-header shipownerFormCardHeader">
    <div class="card-text shipownerFormCardText">
      <h6 class="card-title">{{'SHIPOWNER_DATA.SHIPOWNER_FORM.SHIPOWNER' | translate}}</h6>
    </div>
  </div>
  -->
  <div class="card-header shipownerFormCardHeader">
    <div class="container container-header">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="d-flex flex-row justify-content-left flex-wrap div-header-block">
            <div>
              <div class="text-header-shipowner">{{'SHIPOWNER_DATA.SHIPOWNER_FORM.SHIPOWNER' | translate}}</div>
            </div>
            <div>
              <div class="text-header-shipowner-date" *ngIf="shipownerToForm">
                {{'SHIPOWNER_DATA.SHIPOWNER_FORM.DISCHARGE_DATE' | translate}}:
                <span class="grey-header-date">{{shipownerToForm.dischargeDate | date: 'dd/MM/yy'}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body shipownerFormCardBody">
    <ng-container class="shipownerFormContainer" *ngIf="loaded" [formGroup]="form">
      <!-- BOOSTRAP -->
      <!-- N CONTENEDOR -->
      <div class="container">
        <div class="row centered-row gx-5">
          <!-- COLUMNA IZQUIERDA -->
          <div class="col-md-5 border-container">
            <div class="row centered-row mb-5">
              <!--NOMBRE EMPRESA-->
              <div class="col-md-12 co12-sm-12 col-12">
                <div class="shadow-data-block">
                  <label for="float-inputCharteringCompany">{{'SHIPOWNER_DATA.SHIPOWNER_FORM.SHIPOWNER_COMPANY' |
                    translate }}</label>
                  <input id="float-inputCharteringCompany" type="text" formControlName="charteringCompany" pInputText
                    size="150" placeholder="" class="inputFormShipowner" />
                  <div class="col-12"
                    *ngIf="(!isNew ||form.get('charteringCompany').touched) && !form.get('charteringCompany').value && form.get('charteringCompany').hasError('required')"
                    class="error-label labelError">
                    {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                  </div>
                </div>
              </div>
              <!--FIN NOMBRE EMPRESA-->
            </div>

            <div class="row centered-row mb-3">
              <!--Cif -->
              <div class="col-md-6 col-sm-6 col-6">
                <div class="shadow-data-block">
                  <label for="float-inputCifVat">{{'SHIPOWNER_DATA.SHIPOWNER_FORM.CIF_VAT' | translate}}</label>
                  <input id="float-inputCifVat" type="text" formControlName="cifVat" pInputText size="150"
                    placeholder="" class="inputFormShipowner" />
                  <div class="col-12"
                    *ngIf="(!isNew ||form.get('cifVat').touched)&& !form.get('cifVat').value && form.get('cifVat').hasError('required')"
                    class="error-label labelError">
                    {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                  </div>
                </div>
              </div>
              <!--Fin Cif -->

              <!--IMO-->
              <div class="col-md-6 col-sm-6 col-6">
                <div class="shadow-data-block">
                  <label for="float-inputImoCompany">{{'SHIPOWNER_DATA.SHIPOWNER_FORM.COMPANY_IMO' | translate}}</label>
                  <input id="float-inputImoCompany" type="text" formControlName="companyIMONumber" pInputText size="150"
                    placeholder="" class="inputFormShipowner" />
                  <div class="col-12"
                    *ngIf="(!isNew ||form.get('companyIMONumber').touched) && !form.get('companyIMONumber').value && form.get('companyIMONumber').hasError('required')"
                    class="error-label labelError">
                    {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                  </div>
                </div>
              </div>
              <!--FIN IMO-->
            </div>

            <div class="row centered-row mb-3">
              <!--DIRECCION-->
              <div class="col-md-12 co12-sm-12 col-12">
                <div class="shadow-data-block">
                  <label for="float-inputAddress">{{'SHIPOWNER_DATA.SHIPOWNER_FORM.COMPANY_ADDRESS' |
                    translate}}</label>
                  <input type="text" id="float-inputAddress" formControlName="address" pInputText size="150"
                    placeholder="" class="inputFormShipowner" />
                  <div class="col-12"
                    *ngIf="(!isNew ||form.get('address').touched) && !form.get('address').value && form.get('address').hasError('required')"
                    class="error-label labelError">
                    {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                  </div>
                </div>
              </div>
              <!--FIN DIRECCION-->
            </div>

            <div class="row centered-row mb-3">
              <!--POBLACION -->
              <div class="col-md-6 col-sm-6 col-6">
                <div class="shadow-data-block">
                  <label for="float-inputTown">{{'CHARTERER_DATA_SHEET.TOWN' | translate}}</label>
                  <input pInputText type="text" formControlName="town" inputId="float-inputTown"
                    class="inputFormShipowner inputCodePostal" />

                  <div class="col-12 error-label labelError"
                    *ngIf="(!isNew ||form.get('town').touched)  && !form.get('town').value && form.get('town').hasError('required')">
                    {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                  </div>
                </div>
              </div>
              <!--FIN POBLACION -->

              <!--CP-->
              <div class="col-md-6 col-sm-6 col-6">
                <div class="shadow-data-block">
                  <label for="float-inputCodePostal">{{'SHIPOWNER_DATA.SHIPOWNER_FORM.CP' | translate}}</label>
                  <input pInputText type="text" formControlName="postalCode" inputId="float-inputCodePostal"
                    class="inputFormShipowner inputCodePostal" />

                  <div class="col-12 error-label labelError"
                    *ngIf="(!isNew ||form.get('postalCode').touched)  && !form.get('postalCode').value && form.get('postalCode').hasError('required')">
                    {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                  </div>
                </div>
              </div>
              <!--FIN CP-->
            </div>

            <div class="row centered-row mb-3">
              <!--PROVINCIA -->
              <div class="col-md-6 col-sm-6 col-6">
                <div class="shadow-data-block">
                  <label for="float-inputProvincia">{{'SHIPOWNER_DATA.SHIPOWNER_FORM.PROVINCE' | translate}}</label>
                  <input type="text" autocomplete="new-password" id="float-inputProvincia" formControlName="province"
                    pInputText placeholder="" class="inputFormShipowner" />
                  <div class="col-12"
                    *ngIf="(!isNew ||form.get('province').touched) && !form.get('province').value && form.get('province').hasError('required')"
                    class="error-label labelError">
                    {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                  </div>
                </div>
              </div>
              <!--FIN PROVINCIA -->

              <!--PAIS-->
              <div class="col-md-6 col-sm-6 col-6">
                <div class="shadow-data-block">
                  <label for="float-inputCountryShipowner">{{'SHIPOWNER_DATA.SHIPOWNER_FORM.COUNTRY' |
                    translate}}</label>
                  <p-autoComplete id="float-inputCountryShipowner" formControlName="country" appendTo="body"
                    [suggestions]="listCountries" (completeMethod)="filterlistCountries($event)" [minLength]="1"
                    (onSelect)="onCountrySelect($event)" [dropdown]="true" styleClass="listAutocomplete"
                    panelStyleClass="panelListAutocomplete" autocomplete="new-password" field="name"></p-autoComplete>
                  <div class="col-12"
                    *ngIf="(!isNew ||form.get('country').touched) && !form.get('country').value && form.get('country').hasError('required')"
                    class="error-label labelError">
                    {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                  </div>
                </div>
              </div>
              <!--FIN PAIS-->
            </div>

            <div class="row centered-row mb-3">
              <!--CUENTA CORRIENTE-->
              <div class="col-md-12 co12-sm-12 col-12">
                <div class="shadow-data-block">
                  <label for="float-inputAccount">{{'SHIPOWNER_DATA.SHIPOWNER_FORM.CURRENT_ACCOUNT' |
                    translate}}</label>
                  <input type="text" id="float-inputAccount" formControlName="account" pInputText size="150"
                    placeholder="" class="inputFormShipowner" />
                  <div class="col-12"
                    *ngIf="(!isNew ||form.get('account').touched) && !form.get('account').value && form.get('account').hasError('required')"
                    class="error-label labelError">
                    {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                  </div>
                  <div class="col-12"
                    *ngIf="form.get('account').touched && form.get('account').value && form.get('account').hasError('account')"
                    class="error-label labelError">
                    {{'MESSAGES.VALIDATION.ERROR_LABEL' | translate}}
                  </div>
                </div>
              </div>
              <!--FIN CUENTA CORREINTE-->
            </div>
          </div>
          <!-- FIN COLUMNA IZQUIERA -->

          <!-- COLUMNA DERECHA -->

          <div class="col-md-5 border-container">
            <div class="row centered-row mb-5">
              <!--LOGO-->
              <div class="col-md-12 co12-sm-12 col-12">
                <div class="shadow-data-block center">
                  <input class="input-file" #fileInput type="file" (change)="onFileSelected($event)" accept="image/*" />
                  <div class="logo-shipowner" (click)="!readonly ? fileInput.click() : null">
                    <img [src]="shipOwnerLogoPhoto" *ngIf="shipOwnerLogoPhoto" />
                  </div>
                </div>
              </div>
              <!--FIN LOGO-->
            </div>

            <div class="row centered-row mb-3">
              <!--RANGO DWT DESDE -->
              <div class="col-md-6 col-sm-6 col-6">
                <div class="shadow-data-block">
                  <label for="inputFormShipowner" class="inputVesselFrom"
                    for="float-inputVesselFrom">{{'SHIPOWNER_DATA.SHIPOWNER_FORM.VESSEL_DWT'| translate}}
                    {{'SHIPOWNER_DATA.SHIPOWNER_FORM.VESSEL_DWT_FROM' | translate}}</label>

                  <p-inputNumber formControlName="vesselRangeFrom" inputId="float-inputVesselFrom"
                    [minFractionDigits]="2" class="inputFormShipowner" locale="de-DE"></p-inputNumber>

                  <div class="col-12"
                    *ngIf="form.get('vesselRangeFrom').touched && !form.get('vesselRangeFrom').value && form.get('vesselRangeFrom').hasError('required')"
                    class="error-label labelError">
                    {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                  </div>
                </div>
              </div>
              <!--Fin RANGO DWT DESDE -->

              <!--RANGO DWT HASTA-->
              <div class="col-md-6 col-sm-6 col-6">
                <div class="shadow-data-block">
                  <label for="inputFormShipowner" class="inputVesselTo"
                    for="float-inputVesselTo">{{'SHIPOWNER_DATA.SHIPOWNER_FORM.VESSEL_DWT'| translate}}
                    {{'SHIPOWNER_DATA.SHIPOWNER_FORM.VESSEL_DWT_TO' | translate}}</label>

                  <p-inputNumber formControlName="vesselRangeTo" inputId="float-inputVesselTo" [minFractionDigits]="2"
                    class="inputFormShipowner" locale="de-DE"></p-inputNumber>

                  <div class="col-12"
                    *ngIf="form.get('vesselRangeTo').touched && !form.get('vesselRangeTo').value && form.get('vesselRangeTo').hasError('required')"
                    class="error-label labelError">
                    {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                  </div>
                  <div *ngIf="form.get('vesselRangeTo').touched && form.errors?.['isGreatherThan']"
                    class="col-12 error-label labelError">
                    {{'MESSAGES.VALIDATION.CHECK_VESSEL_RANGE_TO' | translate}}
                  </div>
                </div>
              </div>
              <!--FIN RANGO DWT HASTA-->
            </div>

            <div class="row centered-row mb-3">
              <!--AREA GEOGRAFICA-->
              <div class="col-md-12 co12-sm-12 col-12">
                <div class="shadow-data-block">
                  <label>{{'SHIPOWNER_DATA.SHIPOWNER_FORM.GEO_AREA' | translate}}</label>
                  <overa-autocomplete-old [form]="form" *ngIf="geoAreas" [readonly]="readonly" [name]="'geoAreas'"
                    [required]="false" [items]="geoAreas" [free]="true" class="fluid-element"
                    [selectedItems]="selectedGeoAreas" [multiple]="true" styleClass="area-geografica"
                    (onSelectionChange)="selectedGeoAreasChange($event)" id="geoarea">
                  </overa-autocomplete-old>
                  <!-- <overa-autocomplete
                    [form]="form"
                    *ngIf="geoAreas"
                    [readonly]="readonly"
                    [name]="'geoAreas'"
                    [required]="false"
                    [items]="geoAreas"
                    [free]="true"
                    class="fluid-element"
                    [selectedItems]="selectedGeoAreas"
                    [multiple]="true"
                    styleClass="area-geografica"
                    (onSelectionChange)="selectedGeoAreasChange($event)"
                  >
                  </overa-autocomplete> -->
                </div>
              </div>
              <!--FIN AREA GEOGRAFICA-->
            </div>

            <div class="row centered-row mb-3">
              <!--PAG WEB-->
              <div class="col-md-12 co12-sm-12 col-12">
                <div class="shadow-data-block">
                  <label for="float-inputWeb">{{'SHIPOWNER_DATA.SHIPOWNER_FORM.WEB' | translate}}</label>
                  <input type="text" id="float-inputWeb" formControlName="web" pInputText size="150" placeholder=""
                    class="inputFormShipowner" />
                  <div class="col-12"
                    *ngIf="(!isNew ||form.get('web').touched) && !form.get('web').value && form.get('web').hasError('required')"
                    class="error-label labelError">
                    {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                  </div>
                  <div class="col-12"
                    *ngIf="form.get('web').touched && form.get('web').value && form.get('web').hasError('web')"
                    class="error-label labelError">
                    {{'MESSAGES.VALIDATION.ERROR_LABEL' | translate}}
                  </div>
                </div>
              </div>
              <!--FIN PAG WEB-->
            </div>
            <div class="row centered-row mb-3">
              <!--EMAIL GENERICO -->
              <div class="col-md-12 co12-sm-12 col-12">
                <div class="shadow-data-block">
                  <label for="float-generic">{{'SHIPOWNER_DATA.SHIPOWNER_FORM.GENERIC_EMAIL' | translate}}</label>
                  <input type="text" id="float-generic" formControlName="genericEmail" pInputText size="150"
                    placeholder="" class="inputFormShipowner" />
                </div>
              </div>
              <!--FIN EMAIL GENERICO-->
            </div>
            <div class="row centered-row mb-3">
              <!--ALIAS -->
              <div class="col-md-12 co12-sm-12 col-12">
                <div class="shadow-data-block">
                  <label for="float-alias">{{'SHIPOWNER_DATA.SHIPOWNER_FORM.ALIAS' | translate}}</label>
                  <input type="text" id="float-alias" formControlName="alias" pInputText size="150" placeholder=""
                    class="inputFormShipowner" />
                </div>
              </div>
              <!--FIN ALIAS-->
            </div>
          </div>

          <!-- FIN COLUMNA DERECHA -->
        </div>

        <!-- buttons -->
        <div class="containerButtonDeleteLeft" *ngIf="!readonly">
          <div class="d-flex flex-row justify-content-center flex-wrap div-buttons-form">
            <div *ngIf="!isNew">
              <button pButton pRipple *ngIf="!isNew && isClh" label="{{'ACTION.DELETE' | translate}}"
                (click)="deleteShipowner()" class="borrar p-ripple" [disabled]="!showButtonAfterSave"></button>
            </div>
          </div>
          <div class="d-flex flex-row justify-content-center flex-wrap div-buttons-form">
            <div>
              <button pButton label="{{'ACTION.CANCEL' | translate}}" (click)="cancel()" class="cancelar p-ripple"
                [disabled]="!showButtonAfterSave"></button>
            </div>
            <div *ngIf="isNew && !isUpdate">
              <button pButton pRipple *ngIf="isNew && !isUpdate" label="{{'COMMONS.CREATE' | translate}}"
                (click)="!form.invalid ? createShipowner() : null " class="crear p-ripple"
                [disabled]="!form.valid || !showButtonAfterSave"></button>
            </div>
            <div *ngIf="!isVerify && !isNew && isClh">
              <button pButton pRipple label="{{'ACTION.VERIFY' | translate}}" (click)="verifyShipowner()"
                class="actualizar p-ripple" [disabled]="!form.valid || !showButtonAfterSave"></button>
            </div>
            <div *ngIf="!isNew && isUpdate">
              <button pButton pRipple *ngIf="!isNew && isUpdate" label="{{'ACTION.SAVE' | translate}}"
                (click)="updateShipowner()" class="actualizar p-ripple"
                [disabled]="!form.valid || !showButtonAfterSave"></button>
            </div>
          </div>
        </div>
        <!-- end buttons -->
      </div>

      <!-- FIN NI CONTENEDOR -->

      <!-- FIN BOOSTRAP -->
    </ng-container>
  </div>
</div>
<p-tabView class="tabViewShipowner" *ngIf="!isNew && canViewPanels">
  <p-tabPanel style="align-items: center" header="{{'CONTACTS.HEADER'|translate}}">
    <ng-container *ngIf="shipownerToForm">
      <app-contact-new [itemToUse]="shipownerToForm" [enabled]="this.form.enabled"
        [dataSheet]="'shipowner'"></app-contact-new>
    </ng-container>
  </p-tabPanel>
</p-tabView>
<br />