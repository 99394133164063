import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, ReplaySubject } from "rxjs";
import { ConfigsService } from "@overa/shared";
import { Clause } from "../models/clause/clause.model";
import { CLHBaseService } from "./clh-base.service";

@Injectable({ providedIn: "root" })
export class ClauseService extends CLHBaseService {
  addClause(clause: Clause) {
    const endpointUrl = `${this.baseUrl}/clause`;
    return this.http.post<Clause>(endpointUrl, clause);
  }
  deleteClause(id: string) {
    const endpointUrl = `${this.baseUrl}/clause/${id}`;
    return this.http.delete(endpointUrl);
  }
  editClause(clause: Clause) {
    const endpointUrl = `${this.baseUrl}/clause`;
    return this.http.put<Clause>(endpointUrl, clause);
  }
}
