import { OfferState } from "./enum/offer-state.model";
import { StateOfferShipOwner } from "./enum/state-offer-shipowner.model";
import { UserType } from "./enum/user-type.model";
import { ViewType } from "./enum/view-type.model";

export class QueryParams {
  view!: ViewType;

  constructor() {}
}
