import { HttpClient } from "@angular/common/http";
import { ConfigsService } from "@overa/shared";
import { Catalogue } from "../models/offers/catalogue.model";
import { inject, Injectable } from "@angular/core";
import { pipe, map, of, catchError } from "rxjs";
import { liveQuery } from "dexie";
import { CatalogueType } from "../models/enum/catalogue-type";
import { CLHBaseService } from "./clh-base.service";

@Injectable({ providedIn: "root" })
export class CatalogueService extends CLHBaseService {
  removePortsDb() {
    //db.ports.clear()
    //.then(function (deleteCount) {
    //    console.log( "Deleted " + deleteCount + " objects");
    //});
  }
  get(pageIndex = -1, pageSize = -1) {
    return this.http
      .get<Catalogue[]>(
        `${this.baseUrl}/catalogue?pageIndex=${pageIndex}&pageSize=${pageSize}`
      )
      .pipe(
        map((res: Catalogue[]) => {
          //db.ports.clear();
          //db.ports.bulkAdd(res.map(r => { return { type: r.type, value: r.value, latitude: r.latitude, longitude: r.longitude } }));
          //set local storage validation for one day
          // localStorage.setItem('cacheExpirationDate', (new Date().getTime() + 86400000).toString());
          return res;
        })
      );
  }

  async getCached() {
    //var ports =  db.ports.toArray();
    //return ports;
  }
  import(catalogues: any[]) {
    this.removePortsDb();
    return this.http.post<any[]>(
      `${this.baseUrl}/catalogue/import`,
      catalogues
    );
  }
  getGeoareas() {
    let type = CatalogueType.GEO;
    return this.http
      .get<Catalogue[]>(`${this.baseUrl}/catalogue/byType/${type}`)
      .pipe(
        catchError((err) => {
          console.error(err);
          return of([]);
        })
      );
  }
  getPortsDb() {
    let type = CatalogueType.PORT;
    return this.http.get<Catalogue[]>(
      `${this.baseUrl}/catalogue/byType/${type}`
    );
  }
  getPorts() {
    return this.http.get<Catalogue[]>("./assets/ports.json");

    //let type=CatalogueType.PORT;
    //return this.http.get<Catalogue[]>(`${this.baseUrl}/catalogue/byType/${type}?pageIndex=${pageIndex}&pageSize=${pageSize}`)
    //.pipe(map((res:Catalogue[]) => {
    //    //db.ports.clear();
    //    //db.ports.bulkAdd(res.map(r => { return { type: r.type, value: r.value, latitude: r.latitude, longitude: r.longitude } }));
    //    //set local storage validation for one day
    //    // localStorage.setItem('cacheExpirationDate', (new Date().getTime() + 86400000).toString());
    //    return res;
    //}));
  }
}
