import { ChatGroup } from "./chatGroup.model";
import { ChatParticipant } from "./chatParticipant.model";

export class ChatMessage {
  id?: string;
  groupId?: string;
  group?: ChatGroup;
  participant?: ChatParticipant;
  participantId?: string;
  message?: string;
  reply?: boolean;
  isCharger?: boolean;
  isCLH?: boolean;
  date?: Date;
  seen?: boolean;
}
