import { ContactList } from "./contact-list.model";
import { ChargerShipOwnerContact } from "./charger-shipowner-contact.model";

export class ContactListChargerShipownerContact {
    contactListId?: string;
    chargerShipOwnerContactId?: string;
    contactList?: ContactList;
    chargerShipOwnerContact?:ChargerShipOwnerContact ;

    constructor() {
    }
}