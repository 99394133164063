import { Injectable } from "@angular/core";
import { Charger } from "../models/charger/charger.model";
import { Shipowner } from "../models/shipowner/shipowner.model";
import { Catalogue } from "../models/offers/catalogue.model";
import { Country } from "../models/country/country.model";
import { TermsAndConditions } from "../models/clause/terms-and-conditions.model";
import { PrivacyPolicies } from "../models/privacy-policies/privacy-policies.model";
import { ChargerContact } from "../models/charger/charger-contact.model";
import { ShipOwnerContact } from "../models/shipowner/shipowner-contact.model";
import { ConfigsService, IGlobals } from "@overa/shared";
import { Globals } from "@overa/base";
import { User } from "@overa/security";

export class CLHUser extends User {}
@Injectable()
export class CLHGlobals implements IGlobals {
  user: CLHUser = this.globals.user as CLHUser;
  cache = this.globals.cache;
  currentCharger?: Charger;
  currentChargerContact?: ChargerContact;
  currentShipOwner?: Shipowner;
  currentShipOwnerContact?: ShipOwnerContact;
  catalogue: Catalogue[] = [];
  ports: string[] = [];
  geoAreas: string[] = [];
  isClh: boolean = false;
  acceptedConditions: boolean = false;
  mustAcceptTerms: boolean = false;
  mustAcceptPolicy: boolean = false;
  clhRoleName = "CLH Admin";
  countriesES: Country[] = [];
  countriesEN: Country[] = [];
  clhUsers: any[] = [];
  termsConditions?: TermsAndConditions;
  legalAdvice: any;
  dataProtection?: PrivacyPolicies;
  storageId: string = "";

  constructor(private globals: Globals, private config: ConfigsService) {
    this.storageId = config.getModuleConfig("overa-storage", "storageId");
    console.log(this.globals.user);
  }
}
