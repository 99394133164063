export class Route {
  id?: string;
  chargerId?: string;
  originPortsValues!: string[];
  destinationPortsValues!: string[];
  editable?: boolean;
  isNew?: boolean;

  constructor() {}
}
