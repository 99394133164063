export class ChargerShipOwnerContactCreate {
    chargerId!: string;
    shipOwnerId?: string;
    shipOwnerName?: string;
    isImported!: boolean;
    contactName?: string;
    contactLastName?: string;
    contactPhone?: string;
    contactEmail?: string;
    contactUserId?: string;
    shipOwnerContactId?: string;
    comment?: string;

    constructor(
        chargerId: string,
        isImported: boolean,
        shipOwnerId?: string,
        shipOwnerName?: string,
        contactName?: string,
        contactLastName?: string,
        contactPhone?: string,
        contactEmail?: string,
        contactUserId?: string,
        shipOwnerContactId?: string,
        comment?: string
    ) {
        this.chargerId = chargerId;
        this.isImported = isImported;
        this.shipOwnerId = shipOwnerId;
        this.shipOwnerName = shipOwnerName;
        this.contactName = contactName;
        this.contactLastName = contactLastName;
        this.contactPhone = contactPhone;
        this.contactEmail = contactEmail;
        this.contactUserId = contactUserId;
        this.shipOwnerContactId = shipOwnerContactId;
        this.comment = comment;
    }
}
