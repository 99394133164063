import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { OveraMessagesService } from "@overa/components/info-dialog";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { ChargerShipOwnerContactDto } from "src/app/models/charger-shipowner/charger-shipowner-contact-dto.model";
import { ShipOwnerContact } from "src/app/models/shipowner/shipowner-contact.model";
import { OfferService } from "src/app/services/offer.service";
import { Utils } from "src/app/utils/utils";
import { EmailValidation, PhoneValidation } from "src/app/utils/validators";

@Component({
  selector: "app-charger-shipowner-contact",
  templateUrl: "./charger-shipowner-contact.component.html",
  styleUrls: ["./charger-shipowner-contact.component.scss"],
})
export class ChargerShipownerContactComponent
  implements OnInit, AfterViewChecked {
  listContactAutocomplete: any[] = [];
  newContactSend?: ChargerShipOwnerContactDto;
  selectedShipownerContact?: ShipOwnerContact;
  formContactsResponse!: FormGroup;
  onTheList: boolean = false;
  isNew: boolean = false;
  listInitial: any[] = [];
  idOfferShipowner: string = "";
  isSelected: boolean = false;
  domainPrincipal: string = "";
  showDropdown = false;
  readonly regexEmail: RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  readonly regexPhoneNumber: RegExp = /^\+?(?:[0-9] ?){6,14}[0-9]$/;

  constructor(
    public dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    private fb: FormBuilder,
    public messagesService: OveraMessagesService,
    private cdRef: ChangeDetectorRef,
    private utils: Utils
  ) {
    if (dialogConfig.data?.listContact) {
      this.listInitial = [...dialogConfig.data?.listContact];
    }
    if (dialogConfig.data?.idOfferShipowner) {
      this.idOfferShipowner = dialogConfig.data.idOfferShipowner;
    }
    if (dialogConfig.data?.domainPrincipal) {
      this.domainPrincipal = dialogConfig.data.domainPrincipal;
    }
  }

  ngOnInit() {
    this.newContactSend = undefined;
    this.selectedShipownerContact = undefined;
    this.listContactAutocomplete = [...this.listInitial];
    this.showDropdown = this.listContactAutocomplete?.length > 0;
    this.initialForm();
  }
  initialForm() {
    this.formContactsResponse = this.fb.group({
      emailAutocomplete: new FormControl("", [
        Validators.required,
        EmailValidation,
      ]),
      name: new FormControl("", [Validators.required]),
      lastName: new FormControl("", [Validators.required]),
      phone: new FormControl("", [Validators.required, PhoneValidation]),
      comment: new FormControl("", []),
    });
  }
  saveNewContact() {
    this.newContactSend = new ChargerShipOwnerContactDto();
    if (this.onTheList && this.selectedShipownerContact) {
      this.newContactSend.email = this.selectedShipownerContact.email;
      this.newContactSend.name = this.selectedShipownerContact.name;
      this.newContactSend.lastName = this.selectedShipownerContact.lastName;
      this.newContactSend.phone = this.selectedShipownerContact.phone;
      this.newContactSend.comment = this.selectedShipownerContact.observations;
      this.newContactSend.userId = this.selectedShipownerContact.userId;
      console.log("newContactSend", this.newContactSend);
      this.dialogRef.close(this.newContactSend);
    }
    if (this.isNew) {
      let domain = this.utils.getEmailDomain(
        this.formContactsResponse.get("emailAutocomplete")?.value
      );
      if (domain != this.domainPrincipal) {
        this.formContactsResponse
          .get("emailAutocomplete")
          ?.setErrors({ domainError: true });
      } else {
        if (this.formContactsResponse.valid) {
          this.newContactSend.email =
            this.formContactsResponse.get("emailAutocomplete")?.value;
          this.newContactSend.name =
            this.formContactsResponse.get("name")?.value;
          this.newContactSend.lastName =
            this.formContactsResponse.get("lastName")?.value;
          this.newContactSend.phone =
            this.formContactsResponse.get("phone")?.value;
          this.newContactSend.comment =
            this.formContactsResponse.get("comment")?.value;
          console.log("newContactSend", this.newContactSend);
          this.dialogRef.close(this.newContactSend);
        }
      }
    }
  }
  cancelContact() {
    this.dialogRef.close();
  }
  cancel() {
    this.dialogRef.close();
  }

  //FILTER AND ITEM SELECTED AUTOCOMPLETE
  filterEmail(event: any) {
    // console.log(event);
    let filtered: any[] = [];
    let query = event.query.trimRight();

    for (let i = 0; i < this.listInitial.length; i++) {
      let shipowner = this.listInitial[i];

      if (shipowner.email.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        this.onTheList = true;
        this.isNew = false;
        filtered.push(shipowner);
        this.listContactAutocomplete = filtered;
        if (shipowner.email.toLowerCase() === query.toLowerCase().trimRight()) {
          this.onItemSelect(shipowner);
        }
      } else {
        this.onTheList = false;
        this.isNew = true;
        this.listContactAutocomplete = filtered;
      }
    }
    if (!this.listContactAutocomplete.length) {
      this.isNew = true;
      this.listContactAutocomplete = filtered;
    }
  }
  onItemSelect(event: any) {
    // console.log(event);
    this.isSelected = false;
    if (this.onTheList) {
      this.selectedShipownerContact = event;
      this.formContactsResponse
        .get("name")
        ?.setValue(this.selectedShipownerContact?.name);
      this.formContactsResponse
        .get("lastName")
        ?.setValue(
          this.selectedShipownerContact?.lastName
            ? this.selectedShipownerContact.lastName
            : ""
        );
      this.formContactsResponse
        .get("phone")
        ?.setValue(this.selectedShipownerContact?.phone);
      this.formContactsResponse
        .get("comment")
        ?.setValue(this.selectedShipownerContact?.observations);
      this.formContactsResponse.get("emailAutocomplete")?.disable();
      this.formContactsResponse.get("name")?.disable();
      this.formContactsResponse.get("phone")?.disable();
      this.formContactsResponse.get("lastName")?.disable();
      this.formContactsResponse.get("comment")?.disable();
      this.isSelected = true;
    }
  }
  clean() {
    this.selectedShipownerContact = undefined;
    this.onTheList = false;
    this.isNew = false;
    this.isSelected = false;
    this.cleanForm();
    this.listContactAutocomplete = [...this.listInitial];
  }
  cleanForm() {
    this.formContactsResponse.get("emailAutocomplete")?.setValue("");
    this.formContactsResponse.get("name")?.setValue("");
    this.formContactsResponse.get("lastName")?.setValue("");
    this.formContactsResponse.get("phone")?.setValue("");
    this.formContactsResponse.get("comment")?.setValue("");
    this.formContactsResponse.get("emailAutocomplete")?.enable();
    this.formContactsResponse.get("name")?.enable();
    this.formContactsResponse.get("phone")?.enable();
    this.formContactsResponse.get("lastName")?.enable();
    this.formContactsResponse.get("comment")?.enable();
  }
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
}
