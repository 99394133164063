import { HttpClient, HttpHeaders } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ConfigsService, IdentityService, OveraUtils } from "@overa/shared";
import { Observable, of, Subject, ReplaySubject } from "rxjs";
import { CLHGlobals } from "../utils/globals";
import { MailTemplateTypes } from "../models/enum/mail-template-types";

@Injectable({ providedIn: "root" })
export class CLHIdentityService {
  get identityBaseUrl() {
    return this.config.getModuleConfig("overa-security", "identityBaseUrl");
  }
  identityService = inject(IdentityService);
  utils = inject(OveraUtils);
  globals = inject(CLHGlobals);
  constructor(protected config: ConfigsService, protected http: HttpClient) {}

  sendInvitation(_user: any) {
    // const newPassword = this.utils.generatePassword();
    // const invitatorName = this.globals.user.firstName;
    // const contactName = _user.firstName;

    // const entity = {
    //   invitatorName: invitatorName,
    //   contactName: contactName,
    // };
    // const user = {
    //   email: _user.email,
    //   password: newPassword,
    //   returnUrl: document.baseURI,
    //   firstName: _user.name,
    //   lastName: _user.lastName,
    //   phoneNumber: _user.phone,
    //   roles: [],
    //   permissions: [],
    //   newPassword: newPassword,
    //   userName: _user.email,
    //   htmlContent: ,
    //   tenants: [],
    // };
    // return this.identityService.sendInvitation(user);
    return this.http.post<any>(
      `${this.identityBaseUrl}/account/sendInvitation`,
      _user
    );
  }

  // return this.http.post<any>(
  //   `${this.identityBaseUrl}/account/sendInvitation`,
  //   user
  // );

  disableUser(email: string) {
    return this.http.post<any>(`${this.identityBaseUrl}/account/disableUser`, {
      emailOrLoginName: email,
    });
  }
  getRolesByUsers(userIds: string[]) {
    return this.http.post<any>(
      `${this.identityBaseUrl}/users/rolesByUserId`,
      userIds
    );
  }

  getUsersByRole(roleName: string) {
    return this.http.get<any[]>(
      `${this.identityBaseUrl}/roles/name/${roleName}/users`
    );
  }

  getUsersByRoleNames(roleName: string[]) {
    throw new Error("Method not implemented.");
  }

  getRolesByName(roleName: string[]) {
    throw new Error("Method not implemented.");
  }
  registerUser(user, userId: string, tenantId: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        userId: userId,
        tenantId: tenantId,
      }),
    };
    return this.http.post<any>(
      `${this.config.getModuleConfig(
        "overa-security",
        "apiBaseUrl"
      )}/api/users/sendInvitationShipowner`,
      user,
      httpOptions
    );
  }
}
export class UserSimple {
  email?: string;
  name?: string;
  lastName?: string;
  phone?: string;
  firstName?: string;
}
