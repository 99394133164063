<p-table
  [value]="offers"
  responsiveLayout="stack"
  [breakpoint]="'960px'"
  styleClass="p-datatable-striped  tableofferBinding"
  [responsive]="true"
  [autoLayout]="true"
  [columns]="columns"
  [tableStyle]="{'min-width': '50rem'}"
  *ngIf="showTable"
>
  <ng-template pTemplate="header" let-columns>
    <tr class="trofferBinding">
      <ng-container *ngFor="let col of columns">
        <ng-container *ngIf="col.colName === 'TABLE.NUMBER'"
          ><th class="styleTextTable thOfferResponse">{{col.colName | translate}}</th>
        </ng-container>
        <ng-container *ngIf="col.colName === 'TABLE.REF_HEADER'"
          ><th class="styleTextTable thOfferResponse th2offerBinding" [style]="'width: 30%'">{{col.colName | translate}}</th>
        </ng-container>
        <ng-container *ngIf="col.colName === ''"
          ><th class="styleTextTable thOfferResponse th3offerBinding">{{offerBinding.refOffer}}</th>
        </ng-container>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-offer>
    <ng-container *ngFor="let title of columnsTitle;let index = index">
      <ng-container *ngIf="(title.control != 'chargerClauses' && title.control != 'shipOwnerClauses') else listClauses">
        <tr class="trofferBinding">
          <ng-container *ngFor="let col of columns">
            <ng-container *ngIf="col.colName==='TABLE.NUMBER' && isScreenLarge && isShipowner">
              <ng-container *ngIf="(title.control != 'chargerClauses' && title.control != 'shipOwnerClauses') else clauses">
                <td class="styleTextTable">{{index +1}}</td>
              </ng-container>
              <ng-template #clauses class="styleTextTable clausesRed">
                <td colspan="3" class="styleTextTable">{{title.field | translate}}</td>
              </ng-template>
            </ng-container>
            <ng-container *ngIf="col.colName==='TABLE.NUMBER' && isScreenLarge && !isShipowner && index ==0 ">
              <td class="styleTextTable"></td
            ></ng-container>
            <ng-container *ngIf="col.colName==='TABLE.NUMBER' && isScreenLarge && !isShipowner && index !=0 ">
              <ng-container *ngIf="(title.control != 'chargerClauses' && title.control != 'shipOwnerClauses') else clauses">
                <td class="styleTextTable">{{index}}</td>
              </ng-container>
              <ng-template #clauses>
                <td class="styleTextTable" colspan="3">{{title.field | translate}}</td>
              </ng-template>
            </ng-container>

            <ng-container *ngIf="col.colName==='TABLE.NUMBER' && !isScreenLarge && title.control != 'quantity'">
              <ng-container *ngIf="(title.control != 'chargerClauses' && title.control != 'shipOwnerClauses') else clauses1">
                <ng-container *ngIf="isShipowner">
                  <td class="styleTextTable">{{index +1}} - {{title.field | translate}}</td>
                </ng-container>
                <ng-container *ngIf="!isShipowner">
                  <td class="styleTextTable">{{index}} - {{title.field | translate}}</td>
                </ng-container>
              </ng-container>
              <ng-template #clauses1>
                <td class="styleTextTable" colspan="3">{{title.field | translate}}</td>
              </ng-template>
            </ng-container>

            <ng-container *ngIf="col.colName==='TABLE.NUMBER' && !isScreenLarge && title.control === 'quantity'">
              <td class="styleTextTable">
                <ng-container *ngIf="isShipowner">
                  {{index +1}} - {{title.moreFields.quantity | translate}} / {{title.moreFields.stowage | translate}} / {{title.moreFields.commodity |
                  translate}}</ng-container
                >
                <ng-container *ngIf="!isShipowner">
                  {{index}} - {{title.moreFields.quantity | translate}} / {{title.moreFields.stowage | translate}} / {{title.moreFields.commodity |
                  translate}}</ng-container
                >
              </td>
            </ng-container>

            <ng-container *ngIf="col.colName==='TABLE.REF_HEADER' && isScreenLarge">
              <ng-container *ngIf="(title.control != 'quantity') else templateQuantity">
                <ng-container *ngIf="(title.control != 'chargerClauses' && title.control != 'shipOwnerClauses') else clauses3">
                  <td class="styleTextTable">{{title.field | translate}}</td>
                </ng-container>
              </ng-container>
              <ng-template #clauses3> </ng-template>

              <ng-template #templateQuantity>
                <td class="styleTextTable">
                  {{title.moreFields.quantity | translate}} / {{title.moreFields.stowage | translate}} / {{title.moreFields.commodity | translate}}
                </td>
              </ng-template>
            </ng-container>

            <ng-container *ngIf="col.colName===''" class="styleTextTable">
              <ng-container *ngIf="title.control != 'chargerClauses' && title.control != 'shipOwnerClauses' else clauses">
                <ng-container
                  *ngIf="(title.control != 'addressComm' && title.control != 'dischargingPorts' && title.control != 'loadingPorts' &&  title.control != 'rangeDates' && title.control != 'flete' && title.control != 'demurrage' && title.control != 'quantity') else others"
                >
                  <td>
                    <label>{{offer[title.label]}}</label>
                  </td>
                </ng-container>
                <ng-template #others>
                  <td>
                    <label *ngIf="title.control === 'addressComm'">{{offer[title.label]}} %</label>
                    <label *ngIf="title.control === 'dischargingPorts' || title.control === 'loadingPorts'"> {{offer[title.label].join(',')}} </label>
                    <label *ngIf="title.control === 'rangeDates'">
                      {{offer[title.label.fieldFrom] | date: 'dd/MM/yy'}} - {{offer[title.label.fieldTo] | date: 'dd/MM/yy'}}
                    </label>
                    <label *ngIf="title.control === 'flete' && offer[title.moreLabels.quantity] > 0">
                      {{FreightTargetCurrency[offer[title.moreLabels.currency]]}} {{offer[title.moreLabels.quantity] | number: '1.2-2'}} -
                      {{FreightTargetType[offer[title.moreLabels.type]]}}
                    </label>
                    <label *ngIf="title.control === 'demurrage' && offer[title.moreLabels.quantity] > 0">
                      {{FreightTargetCurrency[offer[title.moreLabels.currency]]}} {{offer[title.moreLabels.quantity] | number: '1.2-2'}} -
                      {{DemurrageType[offer[title.moreLabels.type]]}}
                    </label>
                    <label *ngIf="title.control === 'quantity'">
                      {{offer[title.moreLabels.quantity] | number: '1.0-0' }} {{QuantityType[offer[title.moreLabels.quantityType]]}} /
                      {{offer[title.moreLabels.stowage]}} / {{offer[title.moreLabels.commodity]}}
                    </label>
                    <!-- <label *ngIf="title.control === 'chargerContactNameToRecapConfirm' || title.control === 'shipOwnerContactNameToRecapConfirm' ">
                      {{offer[title.label.name]}} : {{offer[title.label.date] | date: 'dd/MM/yy'}}
                    </label> -->
                  </td>
                </ng-template>
              </ng-container>
              <ng-template #clauses> </ng-template>
            </ng-container>
          </ng-container></tr
      ></ng-container>

      <ng-template #listClauses>
        <tr>
          <td class="styleTextTable" colspan="3" class="styleTextTable clausesRed">{{title.field | translate}}</td>
        </tr>
        <ng-container *ngIf="title.control === 'chargerClauses'">
          <tr *ngFor="let clause of offer[title.label];let index1 = index">
            <td class="styleTextTable" *ngIf="isShipowner">{{index + index1 +1}}</td>
            <td class="styleTextTable" *ngIf="!isShipowner">{{index + index1}}</td>
            <td class="styleTextTable" colspan="2">{{clause.value}}</td>
          </tr>
        </ng-container>
        <ng-container *ngIf="title.control === 'shipOwnerClauses'">
          <tr *ngFor="let clause of offer[title.label];let index2 = index">
            <ng-container *ngIf="countClausesCharger != 0 else noClausesCharger">
              <td class="styleTextTable" *ngIf="isShipowner">{{index + index2 + (countClausesCharger-1) +1}}</td>
              <td class="styleTextTable" *ngIf="!isShipowner">{{index + index2 + (countClausesCharger-1) }}</td>
            </ng-container>
            <ng-template #noClausesCharger>
              <td class="styleTextTable" *ngIf="isShipowner">{{index + index2 + (countClausesCharger) +1}}</td>
              <td class="styleTextTable" *ngIf="!isShipowner">{{index + index2 + (countClausesCharger) }}</td>
            </ng-template>

            <td class="styleTextTable" colspan="2">{{clause.value}}</td>
          </tr>
        </ng-container>
      </ng-template>
    </ng-container>
  </ng-template>
</p-table>

<!-- <div class="divContainerConfirmRecap">
  <div class="divInsideConfirmRecap">
    <h5 class="titleRecapDate">{{'TABLE.CHARTERER_CONFIRM_RECAP' | translate}}</h5>
    <label> {{offerBinding.chargerContactNameToRecapConfirm}} : {{offerBinding.chargerRecapDate | date: 'dd/MMM/yy HH:mm:ss'}} hrs UTC </label>
  </div>
  <div class="divInsideConfirmRecap">
    <h5 class="titleRecapDate">{{'TABLE.SHIPOWNER_CONFIRM_RECAP' | translate}}</h5>
    <label> {{offerBinding.shipOwnerContactNameToRecapConfirm}} : {{offerBinding.shipOwnerRecapDate | date: 'dd/MMM/yy HH:mm:ss'}} hrs UTC </label>
  </div>
</div> -->
