import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";
import { Buffer } from "buffer";

@Injectable()
export class Utils {
  private passwordEncrypt = "@vEr4ClH";

  constructor() {}

  public generatePassword(): string {
    var passwordLength = 12;
    var addUpper = true;
    var addNumbers = true;
    var addSymbols = true;

    var lowerCharacters = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
    ];
    var upperCharacters = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
    var numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    var symbols = ["!", "?", "@"];

    var getRandom = function (array) {
      return array[Math.floor(Math.random() * array.length)];
    };

    var finalCharacters = "";

    if (addUpper) {
      finalCharacters = finalCharacters.concat(getRandom(upperCharacters));
    }

    if (addNumbers) {
      finalCharacters = finalCharacters.concat(getRandom(numbers));
    }

    if (addSymbols) {
      finalCharacters = finalCharacters.concat(getRandom(symbols));
    }

    for (var i = 1; i < passwordLength - 3; i++) {
      finalCharacters = finalCharacters.concat(getRandom(lowerCharacters));
    }

    //shuffle!
    return finalCharacters
      .split("")
      .sort(function () {
        return 0.5 - Math.random();
      })
      .join("");
  }
  public encrypt(plainText: string) {
    return CryptoJS.AES.encrypt(
      plainText.trim(),
      this.passwordEncrypt
    ).toString();
  }
  public decrypt(encryptText: string) {
    return CryptoJS.AES.decrypt(
      encryptText.trim(),
      this.passwordEncrypt
    ).toString(CryptoJS.enc.Utf8);
  }
  public convertLocationToCoords(
    hoursLat: number,
    minutesLat: number,
    secondsLat: number,
    hoursLong: number,
    minutesLong: number,
    secondsLong: number
  ) {
    // Convert hours, minutes, and seconds to decimal degrees
    const latitude = hoursLat + (minutesLat * 60 + secondsLat) / 3600;
    const longitude = hoursLong + (minutesLong * 60 + secondsLong) / 3600;

    // Return the coordinates as an object
    return {
      latitude: latitude,
      longitude: longitude,
    };
  }
  public getHoursAndMinutes(text: string) {
    const regex = /(\d+)\u00B0\s(\d+)'?\s(\d+)"/g;

    const matches = text.match(regex);
    if (matches) {
      const hours = parseFloat(matches[0].split(" ")[0]);
      const minutes = parseFloat(matches[0].split(" ")[1]);
      const seconds = parseFloat(matches[0].split(" ")[2]);
      return { hours, minutes, seconds };
    } else {
      return { hours: 0, minutes: 0, seconds: 0 }; // Retornar null si no se encuentran coincidencias
    }
  }

  public utf8_to_b64(text: string) {
    return Buffer.from(text, "utf-8").toString("base64");
  }

  public b64_to_utf8(text: string) {
    return Buffer.from(text, "base64").toString("utf-8");
  }
  public getEmailDomain(email: string) {
    let dominio = "";
    let partsOfEmailPrincipal = email.split("@");
    if (partsOfEmailPrincipal.length === 2) {
      dominio = partsOfEmailPrincipal[1];
    }
    return dominio;
  }
}
