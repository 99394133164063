import {
  ChangeDetectorRef,
  Component,
  HostListener,
  inject,
  OnInit,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { setPlatformAPI } from "echarts";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";
import { FreightTargetCurrency } from "src/app/models/enum/freight-target-currency.model";
import { FreightTargetType } from "src/app/models/enum/freight-target-type.model";
import { DemurrageType } from "src/app/models/enum/demurrage-type.model";
import { Offer } from "src/app/models/offers/offer.model";
import { OfferShipOwner } from "src/app/models/offers/offer-shipowner.model";
import { QuantityType } from "src/app/models/enum/quantity-type.model";
import { OfferShipownerLine } from "src/app/models/offerShipOwnerLine/offer-shipowner-line.model";
import { OfferService } from "src/app/services/offer.service";
import { CLHGlobals } from "src/app/utils/globals";
import { MailTemplateTypes } from "src/app/models/enum/mail-template-types";
import { MailDTO } from "src/app/models/mail-templates/mail-dto.model";
import { AddresseeType } from "src/app/models/enum/addresseeType.model";
import { CatalogueType } from "src/app/models/enum/catalogue-type";
import { ClhValidators } from "src/app/utils/validators";
import { CanDeactivateGuard } from "src/app/services/canDeactivateGuard.service";
import { NegotiationOfferShipOwner } from "src/app/models/offers/negotiation-offer-shipowner.model";
import { StateOfferShipOwner } from "src/app/models/enum/state-offer-shipowner.model";
import { switchMap, forkJoin, of, catchError } from "rxjs";
import { ChatGroup } from "src/app/models/chat/chatGroup.model";
import { ChatMessage } from "src/app/models/chat/chatMessage.model";
import { ChatParticipant } from "src/app/models/chat/chatParticipant.model";
import { ChatHelper } from "src/app/utils/chat-helper.utils";
import {
  AccountService,
  RoleService,
  User,
  UserEdit,
  UserService,
} from "@overa/security";
import { Utils } from "src/app/utils/utils";
import { ChargerShipownerContactComponent } from "../manteinance/charger-shipowner-contact/charger-shipowner-contact.component";
import { DialogService } from "primeng/dynamicdialog";
import { ChargerShipOwnerContactDto } from "../../models/charger-shipowner/charger-shipowner-contact-dto.model";
import { ShipOwnerContact } from "../../models/shipowner/shipowner-contact.model";
import { OfferShipownerContacts } from "../../models/offers/offer-shipowner-contacts.model";
import { PermissionsService } from "../../services/permissions.service";
import { OfferState } from "src/app/models/enum/offer-state.model";
import { BreadcrumbService } from "src/app/services/breadcrumb.service";
import { Constants } from "src/app/utils/constants";
import { ResponseOfferMail } from "../../models/mail-templates/response-offer-mail.model";
import { CLHIdentityService } from "src/app/services/identity.service";
import { OveraMessagesService } from "@overa/components/info-dialog";
import { NgxTranslateService } from "@overa/shared";
import { mapTemplate } from "src/app/utils/template-helper"; import { MailTemplateService } from "src/app/services/mail-template.service";
import { MessageTemplate, MessageTemplateService } from "@overa/notification";
import { ShipownerService } from "src/app/services/shipowner.service";

@Component({
  selector: "app-offer-response-shipowner",
  templateUrl: "./offer-response-shipowner.component.html",
  styleUrls: ["./offer-response-shipowner.component.scss"],
  providers: [MessageService, DialogService],
})
export class OfferResponseShipownerComponent implements OnInit {
  listOfferShipOwnerLine: OfferShipownerLine[] = [];
  offer: Offer;
  chargerName: string;
  offerShipOwner: OfferShipOwner;
  nameShipOwner: string;
  changes: any[];
  listOfferLines: OfferShipownerLine[] = [];
  template: string;
  bodyTemplateRegister: string;
  subjectTemplateRegister: string;
  subjectTemplate: string;
  offerMap: any;
  idShipOwner: string;
  idOfferShipowner: string;

  coins: string[] = [];
  selectedCoin: string;
  fletes: string[] = [];
  demurrageTypes: string[] = [];
  quantityTypes: string[] = [];
  selectedFlete: string;
  today: Date = new Date();
  form: FormGroup;
  DEFAULT_QUANTITYTYPE = 0;
  DEFAULT_TARGETTYPE = 0;
  DEFAULT_DEMURRAGETYPE = 0;
  DEFAULT_TARGETCURRENCY = 0;
  ports = [];
  portsD = [];
  selectedLoadingPorts = [];
  selectedDischargingPorts = [];

  sendMails: boolean = false;
  isLoading: boolean = false;
  checked: boolean = false;
  expirationOffer: boolean = false;
  expirationDate: Date;
  today6Hours: Date = new Date();

  isReadOnlyResponse: boolean = false;
  offerShipownerIndication: Offer;
  offerReadOnlyOfferShipowner: OfferShipOwner;
  listChargerShipOwnerContact: ChargerShipOwnerContactDto[] = [];
  listShipownerContact: ShipOwnerContact[] = [];
  isEditting: boolean = false;
  listEmails: any[];
  selectedShipownerContact: ShipOwnerContact;
  listContactsAccordion: ChargerShipOwnerContactDto[] = [];
  offerShipownerContacts: OfferShipownerContacts;
  newContactSend: ChargerShipOwnerContactDto;

  haveUserId: boolean = false;
  identityUsers: any[] = [];
  roles: any[] = [];
  permissions = [];
  mailTemplateService = inject(MailTemplateService);
  messageTemplateService = inject(MessageTemplateService);
  neededPermissions = [
    "users.view",
    "roles.view",
    "users.create",
    "roles.assign",
    "users.update",
  ];
  isContact: boolean = false;

  readonly QuantityType = QuantityType;
  readonly FreightTargetCurrency = FreightTargetCurrency;
  readonly FreightTargetType = FreightTargetType;
  readonly DemurrageType = DemurrageType;
  readonly arrValidator = this.validators.portsValidator;
  //variables para tabla
  columns: any[];
  columnsTitle: any[];
  showTable: boolean = false;
  isScreenLarge: boolean = true;
  mailTemplates: MessageTemplate[];
  shipownerService = inject(ShipownerService)
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.isScreenLarge = window.innerWidth >= 960;
  }
  offers: Offer[] = [];
  statusReadOnly = [
    StateOfferShipOwner.BIDED,
    StateOfferShipOwner.RECAP_SHIPOWNER_PENDING,
    StateOfferShipOwner.RECAP_CHARTERER_PENDING,
    StateOfferShipOwner.RECAP,
    StateOfferShipOwner.RECAP_CONFIRMED,
    StateOfferShipOwner.AGREEMENT_STARTED, // cargador crea oferta en firme
    StateOfferShipOwner.AGREEMENT_PREVALIDATED, //armador contraoferta (cláusulas textarea)
    StateOfferShipOwner.AGREEMENT_VALIDATED, //CLH añade cláusulas armador
    StateOfferShipOwner.CHARTERER_COUNTER_OFFER, //COUNTER
    StateOfferShipOwner.SHIPOWNER_COUNTER_OFFER,
    StateOfferShipOwner.REFUSED,
    StateOfferShipOwner.CANCELLED,
    StateOfferShipOwner.COMPLETED_CHARTERER_PENDING,
    StateOfferShipOwner.COMPLETED_SHIPOWNER_PENDING,
    StateOfferShipOwner.COMPLETED,
  ];
  statusReadonlyToOffer = [OfferState.OPERATIVE, OfferState.HISTORIC];
  chatValues: Array<{
    offerShipOwnerId: string;
    currentParticipant: ChatParticipant;
    group: ChatGroup;
    windowName: string;
    historicMessages: ChatMessage[];
    unReadMessages: number;
  }> = [];
  isClh: boolean = false;
  breadcrumb: MenuItem[];
  shipownerPrincipal: ShipOwnerContact;
  domainPrincipal: string = "";
  listSendEmailRegister: ResponseOfferMail[] = [];
  constructor(
    private translate: NgxTranslateService,
    private route: ActivatedRoute,
    private globals: CLHGlobals,
    public messageService: OveraMessagesService,
    public router: Router,
    private offerService: OfferService,
    private spinner: NgxSpinnerService,
    private validators: ClhValidators,
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private dialogServicePrimeng: DialogService,
    private identityService: CLHIdentityService,
    private utils: Utils,
    private chatHelper: ChatHelper,
    private accountService: AccountService,
    private rolesService: RoleService,
    private usersService: UserService,
    private permissionsService: PermissionsService,
    private cd: ChangeDetectorRef,
    private breadcrumbService: BreadcrumbService
  ) {
    this.coins = Object.keys(FreightTargetCurrency).filter(
      (x) => !(parseInt(x) >= 0)
    );
    this.fletes = Object.keys(FreightTargetType).filter(
      (x) => !(parseInt(x) >= 0)
    );
    this.demurrageTypes = Object.keys(DemurrageType).filter(
      (x) => !(parseInt(x) >= 0)
    );
    this.quantityTypes = Object.keys(QuantityType).filter(
      (x) => !(parseInt(x) >= 0)
    );
  }

  ngOnInit() {
    this.isScreenLarge = window.innerWidth >= 960;
    this.assignedColumnsAndFieldsToTable();
    this.ports = this.globals.catalogue
      .filter((x) => x.type == CatalogueType.PORT)
      .map((x) => x.value);
    this.portsD = [...this.ports];
    console.log("offer response");
    const hours = this.today6Hours.getHours();
    this.today6Hours.setHours(hours + 6);
    this.isLoading = false;
    this.listOfferShipOwnerLine = [];
    if (this.globals.currentShipOwner) {
      this.nameShipOwner = this.globals.currentShipOwner.charteringCompany;
      this.idShipOwner = this.globals.currentShipOwner.id;
      // console.log('shipOwnerGlobal', this.globals.currentShipOwner);
      //this.initialForm();
      this.spinner.show();
      this.route.params.subscribe((paramsId) => {
        if (paramsId?.["id"]) {
          if (paramsId["id"] != "") {
            if (
              this.accountService.currentUser.roles
                .map((x) => x.name)
                .includes("CLH Admin") &&
              !this.globals.currentCharger &&
              !this.globals.currentShipOwner
            ) {
              this.isClh = true;
            }
            // console.log('idRoute', paramsId.id);
            this.offerService.getShipownerResponse(paramsId["id"]).subscribe({
              next: (value) => {
                if (!this.permissionsService.hasPermissionToEdit(this.route)) {
                  this.isReadOnlyResponse = true;
                }

                // console.log('offer', value);
                this.offer = value;
                this.chargerName = value.charger.name;
                this.offer.chargerName = this.chargerName;
                this.offer.nameShipOwner = this.nameShipOwner;
                this.offer.offerShipOwners = this.offer.offerShipOwners.filter(
                  (element) => element.shipOwnerId === this.idShipOwner
                );

                this.offerShipOwner = this.offer.offerShipOwners[0];
                this.setChatOptions(this.offerShipOwner);
                this.idOfferShipowner = this.offerShipOwner.id;
                this.offerShipOwner.originalOffer = { ...this.offer };
                // console.log('OFERSHIPOWNER', this.offerShipOwner);
                if (this.offerShipOwner.expirationDate) {
                  this.expirationDate = this.offerShipOwner.expirationDate;
                  if (this.offerShipOwner.expirationDate < this.today) {
                    //TODO when a condition to expirationDate
                    // this.expirationOffer = true;
                  }
                }
                this.breadcrumbService.getBreadcrumb().subscribe((items) => {
                  if (!this.breadcrumb) {
                    this.breadcrumb = items;
                  }
                });
                this.breadcrumb[0].label =
                  this.breadcrumb[0].label +
                  "    >    " +
                  this.offer.chargerName;
                this.breadcrumbService.setBreadcrumb(this.breadcrumb);
                this.offerService
                  .getContactstById(this.idOfferShipowner)
                  .subscribe({
                    next: (offerShipownerContact) => {
                      if (offerShipownerContact) {
                        // console.log('offerShipownerContact', offerShipownerContact);
                        this.offerShipownerContacts = offerShipownerContact;
                        this.listChargerShipOwnerContact = offerShipownerContact
                          ?.contacts.length
                          ? offerShipownerContact.contacts
                          : [];
                        // console.log('listChargerShipOwnerContact', this.listChargerShipOwnerContact);
                      }

                      this.createListToCollapsable();

                      if (
                        this.statusReadOnly.includes(
                          this.offerShipOwner.state
                        ) ||
                        this.statusReadonlyToOffer.includes(this.offer.state)
                      ) {
                        //TODO refactor to V2 same variable offer when readonly
                        this.isReadOnlyResponse = true;
                        this.initialForm();
                        //this.offerService.getShipownerResponse(this.offer.id).subscribe({
                        // next: (value) => {
                        this.offerShipownerIndication = new Offer();
                        this.offerShipownerIndication.layCanFrom = null;
                        this.offerShipownerIndication.layCanTo = null;

                        this.offerReadOnlyOfferShipowner =
                          this.offer.offerShipOwners.find(
                            (x) =>
                              x.shipOwnerId == this.globals.currentShipOwner.id
                          );
                        const offerLines =
                          this.offerReadOnlyOfferShipowner.lines.map(
                            (x) => x.histories[0]
                          );
                        offerLines.forEach((line) => {
                          console.log(line);
                          if (line.label == "DischargingPort") {
                            this.offerShipownerIndication.dischargingPorts =
                              line.multivaluedProperty.values.map(
                                (x) => x.value
                              );
                            console.log(
                              this.offerShipownerIndication.dischargingPorts
                            );
                          } else if (line.label == "LoadingPort") {
                            this.offerShipownerIndication.loadingPorts =
                              line.multivaluedProperty.values.map(
                                (x) => x.value
                              );
                            console.log(
                              this.offerShipownerIndication.loadingPorts
                            );
                          } else if (line.label == "ExpirationDate") {
                            this.offerShipownerIndication.expirationDate =
                              new Date(line.value);
                          } else if (line.label == "LayCanFrom") {
                            this.offerShipownerIndication.layCanFrom = new Date(
                              line.value
                            );
                          } else if (line.label == "LayCanTo") {
                            this.offerShipownerIndication.layCanTo = new Date(
                              line.value
                            );
                          } else {
                            let label = line.label;

                            label =
                              label.charAt(0).toLowerCase() + label.slice(1);
                            console.log(label);
                            this.offerShipownerIndication[label] = line.value;
                          }
                        });
                        console.log("readonly", this.offerShipownerIndication);
                        if (this.offerReadOnlyOfferShipowner.expirationDate) {
                          // this.form.get("checkedExpirationDate").setValue(true);
                          this.form
                            .get("expirationDate")
                            .setValue(
                              new Date(
                                this.offerReadOnlyOfferShipowner.expirationDate
                              )
                            );
                        }
                        this.offer.offerShipownerIndication =
                          this.offerShipownerIndication;
                        this.spinner.hide();
                        //  },
                        //  error: (err) => {
                        //    console.log('error', err);
                        //    this.spinner.hide();
                        //  },
                        //});
                      } else {
                        //this.offer.loadingPorts.sort();
                        //this.offer.dischargingPorts.sort();
                        this.selectedLoadingPorts = this.ports.filter((x) =>
                          this.offer.loadingPorts.includes(x)
                        );
                        this.selectedDischargingPorts = this.portsD.filter(
                          (x) => this.offer.dischargingPorts.includes(x)
                        );
                        this.ports = this.ports.filter(
                          (x) => !this.offer.loadingPorts.includes(x)
                        );
                        this.portsD = this.portsD.filter(
                          (x) => !this.offer.dischargingPorts.includes(x)
                        );

                        this.listOfferLines = this.offerShipOwner.lines;
                        this.setForm();
                        this.spinner.hide();
                      }
                      this.offers.push(this.offer);
                      console.log(this.offers, this.isReadOnlyResponse);
                      this.showTable = true;
                    },
                    error: (err) => {
                      console.log("error", err);
                      this.spinner.hide();
                    },
                  });
              },
              error: (err) => {
                console.error(err);
                this.spinner.hide();
              },
            });
          } else {
            Object.keys(this.form.controls).forEach((ctrl) => {
              this.form.controls[ctrl].disable();
            });
          }
        }
      });
    } else {
      this.messageService.showToast(
        "ERROR",
        "MESSAGES.ERROR.UNAUTHORIZED_USER",
        "error",
        ""
      );
      this.router.navigate(["home"]);
    }
  }

  assignedColumnsAndFieldsToTable() {
    this.columns = [
      { colName: "STEP1_OFFER.TABLE.HEAD.HEAD_1" },
      { colName: "STEP1_OFFER.TABLE.HEAD.HEAD_2" },
      { colName: "" },
      { colName: "RESPONSE_SHIP_OFFER.TABLE.HEAD.HEAD_1" },
    ];
    this.columnsTitle = [
      // { field: 'STEP1_OFFER.TABLE.ROW.ROW_CODE', control: 'code', label: 'code' },
      {
        field: "STEP1_OFFER.TABLE.ROW.ROW_NAME",
        control: "chargerName",
        label: "chargerName",
      },
      {
        field: "quantity",
        moreFields: {
          quantity: "STEP1_OFFER.TABLE.ROW.ROW_CARGO_QUANTITY",
          stowage: "STEP1_OFFER.TABLE.ROW.ROW_STOWAGE",
          commodity: "STEP1_OFFER.TABLE.ROW.ROW_COMODITY",
        },
        control: "quantity",
        label: "quantity",
        moreLabels: {
          quantity: "quantity",
          quantityType: "quantityType",
          stowage: "stowage",
          commodity: "commodity",
        },
        moreControls: {
          quantity: "quantity",
          quantityType: "quantityType",
          stowage: "stowage",
          commodity: "commodity",
        },
        placeholder: {
          stowage: "STEP1_OFFER.PLACEHOLDER.PLACEHOLDER_STOWAGE",
          commodity: "STEP1_OFFER.PLACEHOLDER.PLACEHOLDER_COMODITY",
        },
        idInputTextarea: {
          stowage: "inputTable1",
          commodity: "inputTableCommodity",
        },
      },
      {
        field: "STEP1_OFFER.TABLE.ROW.ROW_LOADING",
        control: "loadingPorts",
        label: "loadingPorts",
        required: true,
      },
      {
        field: "RESPONSE_SHIP_OFFER.TABLE.ROW.ROW_LOADING_CONDITIONS",
        control: "loadingPortConditions",
        label: "loadingPortConditions",
        labelTextarea:
          "RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_LOADING_CONDITIONS",
        idTextarea: "inputResponseLC",
        classTextarea: "textAreaLoadingConditions clhTextarea",
        required: false,
        placeholder:
          "RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_LOADING_CONDITIONS",
        showLabelFloat: false,
        tooltip: "TOOLTIP.CONDITIONS_LOADING_RESPONSE",
      },
      {
        field: "STEP1_OFFER.TABLE.ROW.ROW_DISCHARGING",
        control: "dischargingPorts",
        label: "dischargingPorts",
        required: true,
      },
      {
        field: "RESPONSE_SHIP_OFFER.TABLE.ROW.ROW_DISCHARGING_CONDITIONS",
        control: "dischargingPortConditions",
        label: "dischargingPortConditions",
        labelTextarea:
          "RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_DISCHARGING_CONDITIONS",
        idTextarea: "inputResponseDC",
        classTextarea: "textAreaDischargingConditions clhTextarea",
        required: false,
        placeholder:
          "RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_DISCHARGING_CONDITIONS",
        showLabelFloat: false,
        tooltip: "TOOLTIP.CONDITIONS_DISCHARGING_RESPONSE",
      },
      {
        field: "STEP1_OFFER.TABLE.ROW.ROW_LAYCAN",
        control: "rangeDates",
        label: { fieldFrom: "layCanFrom", fieldTo: "layCanTo" },
      },
      {
        field: "STEP1_OFFER.TABLE.ROW.ROW_CHARTERING",
        control: "chargeringTerm",
        label: "chargeringTerm",
        labelTextarea:
          "RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_CHARTERING",
        idTextarea: "pInputText7",
        placeholder:
          "RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_CHARTERING",
        showLabelFloat: false,
      },
      {
        field: "STEP1_OFFER.TABLE.ROW.ROW_LAYTIME",
        control: "layTimeTerm",
        label: "layTimeTerm",
        labelTextarea:
          "RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_LAYTERMS",
        idTextarea: "pInputText6",
        placeholder:
          "RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_LAYTERMS",
        showLabelFloat: false,
      },
      {
        field: "STEP1_OFFER.TABLE.ROW.ROW_CP",
        control: "cpForm",
        label: "cpForm",
        labelTextarea: "RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_CP",
        idTextarea: "pInputText8",
        placeholder: "RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_CP",
        showLabelFloat: false,
      },
      {
        field: "STEP1_OFFER.TABLE.ROW.ROW_RESTRICTIONS",
        control: "restrictionsRegarding",
        label: "restrictionsRegarding",
        labelTextarea:
          "RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_RESTRICTIONS",
        idTextarea: "inputResponseRestrictions",
        classTextarea: "textAreaRestrictions clhTextarea",
        required: false,
        placeholder:
          "RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_RESTRICTIONS",
        showLabelFloat: false,
      },
      {
        field: "STEP1_OFFER.TABLE.ROW.ROW_ADD_REQUIREMENTS",
        control: "aditionalRequirements",
        label: "aditionalRequirements",
        labelTextarea:
          "RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_ADD_REQUIREMENTS",
        idTextarea: "inputResponseRequirements",
        classTextarea: "textAreaRequirements clhTextarea",
        required: false,
        placeholder:
          "RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_ADD_REQUIREMENTS",
        showLabelFloat: false,
      },
      {
        field: "RESPONSE_SHIP_OFFER.TABLE.ROW.ROW_FLETE",
        control: "flete",
        label: "flete",
        moreLabels: {
          currency: "freightTargetCurrency",
          quantity: "freightTargetQuantity",
          type: "freightTargetType",
        },
        moreControls: {
          currency: "freightTargetCurrency",
          quantity: "freightTargetQuantity",
          type: "freightTargetType",
        },
        tooltip: "TOOLTIP.FREIGHT_RESPONSE",
      },
      {
        field: "RESPONSE_SHIP_OFFER.TABLE.ROW.ROW_DEMURRAGE",
        control: "demurrage",
        label: "demurrage",
        moreLabels: {
          currency: "demurrageCurrency",
          quantity: "demurrageQuantity",
          type: "demurrageType",
        },
        moreControls: {
          currency: "demurrageCurrency",
          quantity: "demurrageQuantity",
          type: "demurrageType",
        },
        tooltip: "TOOLTIP.DEMURRAGE_RESPONSE",
      },
      {
        field: "RESPONSE_SHIP_OFFER.TABLE.ROW.ROW_DEMURRAGE_CONDITIONS",
        control: "demurrageConditions",
        label: "demurrageConditions",
        labelTextarea:
          "RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_DEMURRAGE_CONDITIONS",
        idTextarea: "inputResponseDemuC",
        classTextarea: "textAreaDemurrageConditions clhTextarea",
        required: false,
        placeholder:
          "RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_DEMURRAGE_CONDITIONS",
        showLabelFloat: false,
        tooltip: "TOOLTIP.DESPATCH_RESPONSE",
      },
      {
        field: "RESPONSE_SHIP_OFFER.TABLE.ROW.ROW_VESSELNAME",
        control: "vesselName",
        label: "vesselName",
        labelTextarea:
          "RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_VESSEL",
        idTextarea: "float-pInputText13",
        classTextarea: "inputVessel",
        placeholder: "RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_VESSEL",
        showLabelFloat: false,
      },
      {
        field: "RESPONSE_SHIP_OFFER.TABLE.ROW.ROW_REMARKS",
        control: "remarks",
        label: "remarks",
        labelTextarea:
          "RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_REMARKS",
        idTextarea: "inputResponseRemarks",
        classTextarea: "textAreaRemarks clhTextarea",
        required: false,
        placeholder:
          "RESPONSE_SHIP_OFFER.TABLE.PLACEHOLDER.PLACEHOLDER_REMARKS",
        showLabelFloat: false,
        tooltip: "TOOLTIP.REMARKS_RESPONSE",
      },
      {
        field: "CHARTERER_DATA_SHEET.ADDRESS_COM",
        control: "addressComm",
        label: "addressComm",
        idTextarea: "inputAddressComm",
      },
    ];
  }
  setChatOptions(offerBinding: OfferShipOwner) {
    const isClh = this.accountService.currentUser.roles
      .map((x) => x.name)
      .includes("CLH Admin");

    console.log("this.globals", this.globals);
    console.log("this.isClh", this.isClh);
    if (isClh) {
      // En esta pantall no deberia de poder entrar un cargador, ya que a cargador va a firmOffer
      //TODO: comprobar si borrar, clh tampoco tiene acceso a esta pantalla, va desde firmoffer
      const clhChargerSubscriptions = this.chatHelper
        .getChatGroupChargerCLH(offerBinding.offerId)
        .pipe(
          switchMap((group) => {
            return forkJoin({
              windowName: this.offer.chargerName,
              currentParticipant: this.chatHelper.getCurrentParticipant(
                this.globals.user
              ),
              group: of(group),
              historicMessages: this.chatHelper.getMessageHistory(group.id),
            });
          })
        );
      clhChargerSubscriptions.subscribe(
        ({ windowName, currentParticipant, group, historicMessages }) => {
          const obj = {
            offerShipOwnerId: offerBinding.id,
            currentParticipant: currentParticipant,
            windowName: this.offer.chargerName,
            group: group,
            historicMessages: historicMessages.map((s) =>
              this.chatHelper.setReplyBasedOnCurrentUser(s)
            ),
            unReadMessages: historicMessages
              .map((s) => this.chatHelper.setReplyBasedOnCurrentUser(s))
              .filter(
                (s) => s.participantId != currentParticipant.id && !s.seen
              ).length,
          };
          this.chatValues.push(obj);
        }
      );
    } else if (this.globals.currentShipOwner) {
      //Chat shipownerClh
      const clhShipownerSubscriptions = this.chatHelper
        .getChatGroupCLHOfferShipOwner(offerBinding.id)
        .pipe(
          switchMap((group) => {
            return forkJoin({
              windowName: "CLH",
              currentParticipant: this.chatHelper.getCurrentParticipant(
                this.globals.user
              ),
              group: of(group),
              historicMessages: this.chatHelper.getMessageHistory(group.id),
            });
          })
        );
      clhShipownerSubscriptions.subscribe(
        ({ windowName, currentParticipant, group, historicMessages }) => {
          const obj = {
            offerShipOwnerId: offerBinding.id,
            currentParticipant: currentParticipant,
            windowName: "CLH",
            group: group,
            historicMessages: historicMessages.map((s) =>
              this.chatHelper.setReplyBasedOnCurrentUser(s)
            ),
            unReadMessages: historicMessages
              .map((s) => this.chatHelper.setReplyBasedOnCurrentUser(s))
              .filter(
                (s) => s.participantId != currentParticipant.id && !s.seen
              ).length,
          };
          this.chatValues.push(obj);
        }
      );
    }
  }

  filterList() {
    //filter by email the list to show in the dropdown of contacts to add
    const listToFilter = [...this.listShipownerContact];
    const filteredList = listToFilter.filter(
      (item) =>
        !this.listContactsAccordion.some((obj2) => item.email === obj2.email)
    );
    this.listEmails = [...filteredList];
  }
  createListToCollapsable() {
    //create the lists for the collapsable
    this.listShipownerContact = [...this.globals.currentShipOwner.contacts];
    this.shipownerPrincipal = this.listShipownerContact.find(
      (item) => item.isPrincipal
    );
    if (this.shipownerPrincipal) {
      this.domainPrincipal = this.utils.getEmailDomain(
        this.shipownerPrincipal.email
      );
    }
    // this.listShipownerContact = this.listShipownerContact.filter(
    //   (item) => item.isBanned === true
    // );
    this.listEmails = [...this.listShipownerContact];
    this.listContactsAccordion = [...this.listChargerShipOwnerContact];
    this.filterList();
  }
  send() {
    if (this.isReadOnlyResponse) {
      this.router.navigate(["home"]);
    } else {
      const trmessage = this.translate.get("COMMONS.ASK_SEND_RESPONSE");
      const messageAccept = this.translate.get("ACTION.YES");

      this.confirmationService.confirm({
        message: trmessage,
        header: "",
        acceptLabel: messageAccept,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          if (this.form.valid && !this.isLoading) {
            // console.log(this.form.value);
            this.isLoading = true;
            if (this.form) {
              let createFreightTargetCurrencyLine = false;
              let createFreightTargetQuantityLine = false;
              let createFreightTargetTypeLine = false;

              let createLaycanFromLine = false;
              let createLaycanToLine = false;

              let createDemurrageCurrencyLine = false;
              let createDemurrageQuantityLine = false;
              let createDemurrageTypeLine = false;

              Object.keys(this.form.controls).forEach((ctrl) => {
                if (ctrl.toLowerCase() != "fileupload") {
                  if (ctrl === "freightTargetCurrency") {
                    if (
                      FreightTargetCurrency[this.form.get(ctrl).value] != null
                    ) {
                      if (
                        FreightTargetCurrency[
                        FreightTargetCurrency[this.form.get(ctrl).value]
                        ] != FreightTargetCurrency[this.offer[ctrl]]
                      ) {
                        this.createOfferLine(ctrl, this.form);
                        createFreightTargetCurrencyLine = true;
                      }
                    }
                  } else if (ctrl === "freightTargetType") {
                    if (FreightTargetType[this.form.get(ctrl).value] != null) {
                      if (
                        FreightTargetType[
                        FreightTargetType[this.form.get(ctrl).value]
                        ] != FreightTargetType[this.offer[ctrl]]
                      ) {
                        this.createOfferLine(ctrl, this.form);
                        createFreightTargetTypeLine = true;
                      }
                    }
                  } else if (ctrl === "rangeDates") {
                    const formValue = { ...this.form.value };

                    const dateFrom = new Date(this.offer.layCanFrom);
                    const dateTo = new Date(this.offer.layCanTo);

                    if (
                      dateFrom < formValue.rangeDates[0] ||
                      dateFrom > formValue.rangeDates[0]
                    ) {
                      ctrl = "layCanFrom";
                      this.createOfferLine(ctrl, this.form);
                      createLaycanFromLine = true;
                    }
                    if (
                      dateTo < formValue.rangeDates[1] ||
                      dateTo > formValue.rangeDates[1]
                    ) {
                      ctrl = "layCanTo";
                      this.createOfferLine(ctrl, this.form);
                      createLaycanToLine = true;
                    }
                  } else if (ctrl === "remarks") {
                    if (this.form.get(ctrl).value != "") {
                      this.createOfferLine(ctrl, this.form);
                    }
                  } else if (ctrl === "vesselName") {
                    if (this.form.get(ctrl).value != "") {
                      this.createOfferLine(ctrl, this.form);
                    }
                  } else if (
                    ctrl === "loadingPortConditions" ||
                    ctrl === "dischargingPortConditions" ||
                    ctrl === "demurrageConditions"
                  ) {
                    if (this.form.get(ctrl).value != "") {
                      this.createOfferLine(ctrl, this.form);
                    }
                  } else if (ctrl === "freightTargetQuantity") {
                    if (this.offer[ctrl] != this.form.get(ctrl).value) {
                      this.createOfferLine(ctrl, this.form);
                      createFreightTargetQuantityLine = true;
                    }
                  } else if (ctrl === "loadingPorts") {
                    const value = this.form.get(ctrl).value;
                    if (Array.isArray(value)) {
                      //TODO compare arrays
                      if (
                        JSON.stringify([...this.offer.loadingPorts].sort()) !=
                        JSON.stringify([...value].sort())
                      ) {
                        this.createOfferLine(ctrl, this.form);
                      }
                    }
                  } else if (ctrl === "dischargingPorts") {
                    const value = this.form.get(ctrl).value;
                    if (Array.isArray(value)) {
                      //TODO compare arrays
                      if (
                        JSON.stringify(
                          [...this.offer.dischargingPorts].sort()
                        ) != JSON.stringify([...value].sort())
                      ) {
                        this.createOfferLine(ctrl, this.form);
                      }
                    }
                  } else if (ctrl === "demurrageCurrency") {
                    if (
                      FreightTargetCurrency[this.form.get(ctrl).value] != null
                    ) {
                      this.createOfferLine(ctrl, this.form);
                      createDemurrageCurrencyLine = true;
                    }
                  } else if (ctrl === "demurrageType") {
                    if (DemurrageType[this.form.get(ctrl).value] != null) {
                      this.createOfferLine(ctrl, this.form);
                      createDemurrageTypeLine = true;
                    }
                  } else if (ctrl === "demurrageQuantity") {
                    // console.log("quantity", this.form.get(ctrl).value);
                    this.createOfferLine(ctrl, this.form);
                    createDemurrageQuantityLine = true;
                  } else if (ctrl === "expirationDate") {
                    this.createOfferLine(ctrl, this.form);
                  } else {
                    let value = this.form.get(ctrl).value;
                    let offerValue = this.offer[ctrl];
                    if (typeof value === "string") {
                      value = value.trim();
                      if (offerValue) {
                        offerValue = offerValue.toString().trim();
                      }
                      if (offerValue != value) {
                        this.createOfferLine(ctrl, this.form);
                      }
                    } else if (Array.isArray(value)) {
                      //TODO compare arrays
                      if (
                        JSON.stringify([...this.offer[ctrl]].sort()) !=
                        JSON.stringify([...value].sort())
                      ) {
                        this.createOfferLine(ctrl, this.form);
                      }
                    }
                  }
                }
              });

              //console.log('listaA', this.listOfferShipOwnerLine);
              //TODO sara: comprobar si funciona bien
              if (createLaycanFromLine && !createLaycanToLine) {
                this.createOfferLine("layCanTo", this.form);
              } else if (createLaycanToLine && !createLaycanFromLine) {
                this.createOfferLine("layCanFrom", this.form);
              }

              if (
                createFreightTargetCurrencyLine &&
                (!createFreightTargetQuantityLine ||
                  !createFreightTargetTypeLine)
              ) {
                if (!createFreightTargetQuantityLine) {
                  this.createOfferLine("freightTargetQuantity", this.form);
                }
                if (!createFreightTargetTypeLine) {
                  this.createOfferLine("freightTargetType", this.form);
                }
              } else if (
                !createFreightTargetCurrencyLine &&
                (createFreightTargetQuantityLine || createFreightTargetTypeLine)
              ) {
                this.createOfferLine("freightTargetCurrency", this.form);
                if (!createFreightTargetQuantityLine) {
                  this.createOfferLine("freightTargetQuantity", this.form);
                }
                if (!createFreightTargetTypeLine) {
                  this.createOfferLine("freightTargetType", this.form);
                }
              }

              if (
                createDemurrageCurrencyLine &&
                (!createDemurrageQuantityLine || !createDemurrageTypeLine)
              ) {
                if (!createDemurrageQuantityLine) {
                  this.createOfferLine("demurrageQuantity", this.form);
                }
                if (!createDemurrageTypeLine) {
                  this.createOfferLine("demurrageType", this.form);
                }
              } else if (
                !createDemurrageCurrencyLine &&
                (createDemurrageQuantityLine || createDemurrageTypeLine)
              ) {
                this.createOfferLine("demurrageCurrency", this.form);
                if (!createDemurrageQuantityLine) {
                  this.createOfferLine("demurrageQuantity", this.form);
                }
                if (!createDemurrageTypeLine) {
                  this.createOfferLine("demurrageType", this.form);
                }
              }
            }
            // console.log('lista', this.listOfferShipOwnerLine);

            this.spinner.show();

            this.offerService
              .updateOfferShipOwnerWithLine(this.listOfferShipOwnerLine)
              .subscribe({
                next: () => {
                  this.spinner.hide();
                  this.sendMails = false;
                  this.sendMail("charger");
                  this.sendMail("shipowner");
                  this.sendMailRegister();
                  this.router.navigate(["home"]);
                  this.isLoading = false;
                },
                error: (err) => {
                  console.error(err);
                  this.spinner.hide();
                  this.messageService.showToast(
                    "ERROR",
                    "MESSAGES.ERROR.BAD_REQUEST_BODY",
                    "error",
                    ""
                  );
                  this.isLoading = false;
                  //TODO: onInit
                },
              });
          }
        },
        reject: () => { },
      });
    }
  }
  createOfferLine(ctrl: string, form: FormGroup) {
    const offerLine = new OfferShipownerLine();
    offerLine.indicationDate = this.today;
    offerLine.offerShipOwnerId = this.offerShipOwner.id;
    offerLine.label = ctrl.charAt(0).toUpperCase() + ctrl.slice(1);

    if (ctrl === "freightTargetCurrency") {
      offerLine.value = FreightTargetCurrency[form.get(ctrl).value];
      this.findIdForOfferLine(ctrl, offerLine);
    } else if (ctrl === "freightTargetType") {
      offerLine.value = FreightTargetType[form.get(ctrl).value];
      this.findIdForOfferLine(ctrl, offerLine);
    } else if (ctrl === "layCanFrom") {
      const formValue = { ...this.form.value };
      formValue.rangeDates[0].setHours(0, 0, 0);
      offerLine.value = new Date(
        Date.UTC(
          formValue.rangeDates[0].getFullYear(),
          formValue.rangeDates[0].getMonth(),
          formValue.rangeDates[0].getDate()
        )
      ).toISOString();
      this.findIdForOfferLine(ctrl, offerLine);
    } else if (ctrl === "layCanTo") {
      const formValue = { ...this.form.value };
      formValue.rangeDates[1].setHours(0, 0, 0);
      offerLine.value = new Date(
        Date.UTC(
          formValue.rangeDates[1].getFullYear(),
          formValue.rangeDates[1].getMonth(),
          formValue.rangeDates[1].getDate()
        )
      ).toISOString();
      this.findIdForOfferLine(ctrl, offerLine);
    } else if (ctrl === "loadingPorts") {
      const formValue = { ...this.form.value };
      offerLine.loadingPorts = formValue.loadingPorts;
      offerLine.label = "LoadingPort";
      this.findIdForOfferLine(ctrl, offerLine);
    } else if (ctrl === "dischargingPorts") {
      const formValue = { ...this.form.value };
      offerLine.dischargingPorts = formValue.dischargingPorts;
      offerLine.label = "DischargingPort";
      this.findIdForOfferLine(ctrl, offerLine);
    } else if (ctrl === "demurrageCurrency") {
      offerLine.value = FreightTargetCurrency[form.get(ctrl).value];
      this.findIdForOfferLine(ctrl, offerLine);
    } else if (ctrl === "demurrageType") {
      offerLine.value = DemurrageType[form.get(ctrl).value];
      this.findIdForOfferLine(ctrl, offerLine);
    } else if (ctrl === "demurrageQuantity") {
      offerLine.value = form.get(ctrl).value;
      this.findIdForOfferLine(ctrl, offerLine);
    } else if (ctrl === "expirationDate") {
      if (this.checked) {
        const formValue = { ...this.form.value };
        //offerLine.value = new Date(Date.UTC(formValue.expirationDate.getFullYear(), formValue.expirationDate.getMonth(), formValue.expirationDate.getDate(), formValue.expirationDate.getHours(), formValue.expirationDate.getMinutes())).toISOString();
        offerLine.value = formValue.expirationDate.toISOString();
        this.findIdForOfferLine(ctrl, offerLine);
      } else {
        offerLine.value = null;
        this.findIdForOfferLine(ctrl, offerLine);
      }
    } else {
      offerLine.value = form.get(ctrl).value;
      if (typeof offerLine.value === "string") {
        offerLine.value = offerLine.value.trim();
      }
      this.findIdForOfferLine(ctrl, offerLine);
    }
  }
  findIdForOfferLine(ctrl: string, offerLine: OfferShipownerLine) {
    if (this.listOfferLines != null) {
      let line = this.listOfferLines.find(
        (item) => item.label === ctrl.charAt(0).toUpperCase() + ctrl.slice(1)
      );
      if (!line && ctrl == "loadingPorts") {
        line = this.listOfferLines.find((item) => item.label == "LoadingPort");
      }
      if (!line && ctrl == "dischargingPorts") {
        line = this.listOfferLines.find(
          (item) => item.label == "DischargingPort"
        );
      }
      //  console.log('line', line);
      if (line != null) {
        offerLine.id = line.id;
        //  console.log('offerline', offerLine.id);
      }
    }
    this.listOfferShipOwnerLine.push(offerLine);
  }
  initialForm() {
    this.form = this.fb.group(
      {
        loadingPorts: new FormControl([], []),
        loadingPortConditions: new FormControl("", []),
        dischargingPorts: new FormControl([], []),
        dischargingPortConditions: new FormControl("", []),
        cpForm: new FormControl("", [Validators.required]),
        chargeringTerm: new FormControl("", [Validators.required]),
        layTimeTerm: new FormControl("", [Validators.required]),
        freightTargetQuantity: new FormControl("", [Validators.required]),
        restrictionsRegarding: new FormControl("", []),
        aditionalRequirements: new FormControl("", []),
        freightTargetCurrency: new FormControl(this.DEFAULT_TARGETCURRENCY, []),
        freightTargetType: new FormControl(this.DEFAULT_TARGETTYPE, []),
        rangeDates: new FormControl(
          [this.today, this.today],
          [Validators.required, this.validators.rangeValidator]
        ),
        vesselName: new FormControl("", [Validators.required]),
        remarks: new FormControl("", []),
        demurrageQuantity: new FormControl(null, [Validators.required]),
        demurrageType: new FormControl(this.DEFAULT_DEMURRAGETYPE, [
          Validators.required,
        ]),
        addressComm: new FormControl(0, []),
        demurrageCurrency: new FormControl(this.DEFAULT_TARGETCURRENCY, [
          Validators.required,
        ]),
        demurrageConditions: new FormControl("", []),
        expirationDate: new FormControl(this.today, []),
        checkedExpirationDate: new FormControl(this.checked, []),
      },
      { validators: [this.validators.expirationDateValidator()] }
    );
    this.form.get("expirationDate")?.disable();
  }
  //expirationDateValidator: ValidatorFn
  setForm() {
    if (this.offer) {
      this.form = this.fb.group(
        {
          loadingPorts: new FormControl(this.offer.loadingPorts, []),
          loadingPortConditions: new FormControl("", []),
          dischargingPorts: new FormControl(this.offer.dischargingPorts, []),
          dischargingPortConditions: new FormControl("", []),
          cpForm: new FormControl(this.offer.cpForm, [Validators.required]),
          chargeringTerm: new FormControl(this.offer.chargeringTerm, [
            Validators.required,
          ]),
          layTimeTerm: new FormControl(this.offer.layTimeTerm, [
            Validators.required,
          ]),
          freightTargetQuantity: new FormControl(
            this.offer.freightTargetQuantity,
            [Validators.required]
          ),
          restrictionsRegarding: new FormControl(
            this.offer.restrictionsRegarding,
            []
          ),
          aditionalRequirements: new FormControl(
            this.offer.aditionalRequirements,
            []
          ),
          freightTargetCurrency: new FormControl(
            FreightTargetCurrency[this.offer.freightTargetCurrency],
            []
          ),
          freightTargetType: new FormControl(
            FreightTargetType[this.offer.freightTargetType],
            []
          ),
          rangeDates: new FormControl(
            [new Date(this.offer.layCanFrom), new Date(this.offer.layCanTo)],
            [Validators.required, this.validators.rangeValidator]
          ),
          vesselName: new FormControl("", [Validators.required]),
          remarks: new FormControl(this.offer.remarks, []),
          demurrageQuantity: new FormControl(null, [Validators.required]),
          demurrageType: new FormControl(
            DemurrageType[this.offer.demurrageType],
            [Validators.required]
          ),
          addressComm: new FormControl(this.offer.addressComm, []),
          demurrageCurrency: new FormControl(
            FreightTargetCurrency[this.offer.freightTargetCurrency],
            [Validators.required]
          ),
          demurrageConditions: new FormControl("", []),
          expirationDate: new FormControl(this.today, []),
          checkedExpirationDate: new FormControl(this.checked, []),
        },
        { validators: [this.validators.expirationDateValidator()] }
      );
      this.form.get("expirationDate")?.disable();
      //   this.form.get('freightTargetCurrency').setValue(FreightTargetCurrency[this.offer.freightTargetCurrency]);
      //   this.form.get('freightTargetType').setValue(FreightTargetType[this.offer.freightTargetType]);
      //  // this.form.get('loadingPorts').setValue(this.offer.loadingPorts);
      ////   this.form.get('dischargingPorts').setValue(this.offer.dischargingPorts);
      //   this.form.get('cpForm').setValue(this.offer.cpForm);
      //   this.form.get('chargeringTerm').setValue(this.offer.chargeringTerm);
      //   this.form.get('layTimeTerm').setValue(this.offer.layTimeTerm);
      //   this.form.get('addressComm').setValue(this.offer.addressComm);
      //   this.form.get('freightTargetQuantity').setValue(this.offer.freightTargetQuantity);
      //   this.form.get('restrictionsRegarding').setValue(this.offer.restrictionsRegarding);
      //   this.form.get('aditionalRequirements').setValue(this.offer.aditionalRequirements);
      //   this.form.get('demurrageCurrency').setValue(FreightTargetCurrency[this.offer.freightTargetCurrency]);
      //   this.form.get('demurrageType').setValue(DemurrageType[this.offer.demurrageType]);
    }
    //if (this.offer.layCanFrom != null && this.offer.layCanTo != null) {
    //  this.form.get('rangeDates').setValue([new Date(this.offer.layCanFrom), new Date(this.offer.layCanTo)], { emitEvent: false });
    //}
    this.form.get("demurrageCurrency").disable();
  }
  sendMail(typeAddressee: string) {
    this.spinner.show();
    let type = "";
    this.mapOfferLineToOffer();
    this.offerShipOwner.offer = this.offerMap;

    this.offerShipOwner.shipOwner = this.globals.currentShipOwner;
    // console.log('offershipowner email',this.offerShipOwner);
    const emailDto = new MailDTO();
    emailDto.offerShipOwnerId = this.offerShipOwner.id;

    if (typeAddressee === "shipowner") {
      this.sendMails = false;
      type = MailTemplateTypes.OFFER_SHIPOWNER_RESPONSE;
      emailDto.addresseeType = AddresseeType.SHIPOWNER;
      this.offerShipOwner["chargerName"] = this.chargerName;
      this.offerShipOwner["shipOwnerName"] =
        this.globals.currentShipOwner.charteringCompany;
      // this.offerShipOwner["userId"] = encodeURIComponent(
      //   this.utils.encrypt(this.globals.user.id)
      // );
      this.offerShipOwner["tenantId"] = encodeURIComponent(
        this.utils.encrypt(
          this.globals.user.mainTenantId || this.globals.user.tenants[0].id
        )
      );
      this.offerShipOwner["shipownerId"] = this.globals.currentShipOwner.id;

      this.offerShipOwner["termsConditionsEn"] =
        this.globals.termsConditions.termsEN;
      this.offerShipOwner["legalAdviceEn"] = Constants.LEGAL_ADVICE.en;
      this.offerShipOwner["dataProtectionEn"] =
        this.globals.dataProtection.policyEN + Constants.DATA_PROTECTION.en;
      this.offerShipOwner["termsConditionsEs"] =
        this.globals.termsConditions.terms;
      this.offerShipOwner["legalAdviceEs"] = Constants.LEGAL_ADVICE.es;
      this.offerShipOwner["dataProtectionEs"] =
        this.globals.dataProtection.policy + Constants.DATA_PROTECTION.es;
      this.offerShipOwner.offerId = this.offerShipOwner.offer.id;

      console.log(this.offerShipOwner);
    } else if (typeAddressee === "charger") {
      this.sendMails = false;
      type = MailTemplateTypes.OFFER_SHIPOWNER_RESPONSE_TO_CHARGER;
      emailDto.addresseeType = AddresseeType.CHARGER;
    }
    this.messageTemplateService.readAll().subscribe({
      next: (templates) => {
        this.mailTemplates = templates;
        const template = this.mailTemplates.find((x) => x.name == type);
        if (template) {
          this.template = template.content;
          this.subjectTemplate = template.subject;
          // console.log('template', template.html);
          // console.log('subject', template.subject);
        }
        //TODO MAP LINES TO OFFERSHIPOWNER
        emailDto.body = mapTemplate(this.template, this.offerShipOwner, ['userId']);
        emailDto.subject = mapTemplate(
          this.subjectTemplate,
          this.offerShipOwner
        );

        // console.log('emailDto', emailDto);

        this.mailTemplateService.sendMail(emailDto).subscribe({
          next: () => {
            this.spinner.hide();
            if (!this.sendMails) {
              this.messageService.showToast(
                "INFO",
                "MESSAGES.SUCCESS.UPDATE_EMAIL",
                "success",
                ""
              );
              this.sendMails = true;
            }
          },
          error: (err) => {
            console.error(err);
            this.spinner.hide();
            this.sendMails = false;
            this.messageService.showToast(
              "ERROR",
              "MESSAGES.ERROR.NO_SEND_EMAIL",
              "error",
              ""
            );
          },
        });
      },
      error: (err) => {
        this.spinner.hide();
        console.error(err);
        this.sendMails = false;
        this.messageService.showToast(
          "ERROR",
          "MESSAGES.ERROR.BAD_REQUEST_BODY",
          "error",
          ""
        );
      },
    });
  }
  mapOfferLineToOffer() {
    this.offerMap = { ...this.offer };
    this.offerMap.vesselName = "";
    this.offerMap.remarks = "";

    //console.log('offermap', this.offerMap);
    this.listOfferShipOwnerLine.forEach((item) => {
      const label = item.label.charAt(0).toLowerCase() + item.label.slice(1);
      if (label == "loadingPort") {
        this.offerMap.loadingPorts = item.loadingPorts;
      } else if (label == "dischargingPort") {
        this.offerMap.dischargingPorts = item.dischargingPorts;
      } else {
        this.offerMap[label] = item.value;
      }
    });
    //console.log('offerReformada', this.offerMap);
  }

  selectedLoadingPortChange(event: string[]) {
    // console.log(event);
    //  this.offer.loadingPorts = event;
  }
  selectedDischargingPortChange(event: string[]) {
    // console.log(event);
    //this.offer = {...this.offer};
    //this.offer.dischargingPorts = event;
  }
  ngOndestroy() { }

  sendExpirationDate(event) {
    this.checked = event.checked;
    if (this.checked) {
      this.form.get("expirationDate")?.enable();
    } else {
      this.form.get("expirationDate")?.disable();
    }
  }
  selectDate() {
    const value = new Date(this.form.get("expirationDate").value);
    if (value.getUTCDate() == this.today.getUTCDate()) {
      if (value.getUTCHours() < this.today.getUTCHours()) {
        this.messageService.showToast(
          "INFO",
          "MESSAGES.INFO.EXPIRATION_DATE_MINOR",
          "info",
          ""
        );
        this.form.get("expirationDate").setValue(this.today);
      }
    }
  }
  changeFreightTargetCurrency(event) {
    this.form
      .get("demurrageCurrency")
      .setValue(FreightTargetCurrency[FreightTargetCurrency[event]]);
  }
  deleteContact(contact) {
    if (this.listContactsAccordion.length > 1) {
      // console.log(contact);
      this.isEditting = true;
      const trmessage = this.translate.get("COMMONS.ASK_DELETE_RECORD");
      const messageAccept = this.translate.get("ACTION.YES");

      this.confirmationService.confirm({
        message: trmessage,
        header: "",
        acceptLabel: messageAccept,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          if (contact?.id) {
            this.spinner.show();
            this.offerService
              .deleteContactToChargerShipownerList(
                this.idOfferShipowner,
                contact.id
              )
              .subscribe({
                next: () => {
                  this.deleteItemToList(contact);
                  this.deleteItemToListRegisterEmail(contact);
                  this.filterList();
                  this.isEditting = false;
                  this.spinner.hide();
                },
                error: (err) => {
                  console.log(err);
                  this.isEditting = false;
                  this.spinner.hide();
                },
              });
          }
        },
        reject: () => {
          this.isEditting = false;
        },
      });
    } else {
      this.messageService.showToast(
        "INFO",
        "MESSAGES.INFO.ONE_CONTACT",
        "info",
        ""
      );
    }
  }
  deleteItemToList(contact: any) {
    const index = this.listContactsAccordion.findIndex(
      (item) => item.email === contact.email
    );
    if (index !== -1) {
      this.listContactsAccordion.splice(index, 1);
      this.listContactsAccordion = [...this.listContactsAccordion];
      this.filterList();
    }
  }
  openContact() {
    this.isEditting = true;
    const header = this.translate.get("RESPONSE_SHIP_OFFER.CONTACT_HEADER");

    const ref = this.dialogServicePrimeng.open(
      ChargerShipownerContactComponent,
      {
        width: "60%",
        height: "60%",
        header: header,
        data: {
          listContact: this.listEmails,
          idOfferShipowner: this.idOfferShipowner,
          domainPrincipal: this.domainPrincipal,
        },
      }
    );

    ref.onClose.subscribe((contact: any) => {
      // console.log(contact);
      if (contact) {
        this.newContactSend = contact;
        this.haveUserId = false;
        this.isContact = false;
        if (this.newContactSend?.userId && this.newContactSend.userId != null) {
          this.listEmails.forEach((item) => {
            if (
              item.email.toLowerCase() ===
              this.newContactSend.email.toLowerCase()
            ) {
              this.newContactSend.email = item.email;
              this.newContactSend.name = item.name;
              this.newContactSend.lastName = item.lastName;
              this.newContactSend.phone = item.phone;
              this.newContactSend.comment = item.observations;
              this.newContactSend.userId = item.userId;
              // console.log(this.newContactSend);
            }
          });
          this.haveUserId = true;
          this.isContact = true;
        }

        // console.log(this.newContactSend);
        this.spinner.show();
        //TODO el contacto est� asociado a un armador o cargador
        // comprobar si existe en identityuser el email , si existe se asigna el userid y sino se hace un post

        if (!this.haveUserId) {
          // {
          //   users: this.usersService.getUsers(),
          //   isAvailable: this.identityService

          // }
          this.usersService.getUsers().subscribe({
            next: (users) => {
              // console.log(users);

              this.identityUsers = users?.length > 0 ? users : [];
              const index = this.identityUsers.findIndex(
                (item) => item.email === this.newContactSend.email
              );
              // if (index > -1) {
              //   let messageError = this.translate.get("MESSAGES.VALIDATION.CONTACT_EXIST_SHIPOWNER");

              // this.messageService.showToast(
              //   "ERROR",
              //   messageError,
              //   "error",
              //   ""
              // );
              // this.newContactSend = null;
              // this.isEditting = false;
              // this.spinner.hide();
              /*
              let user = this.identityUsers[index];
              // console.log(user);
              if (user) {
                //TODO: a�adir rol si existe?
                this.newContactSend.userId = user.id;
                this.newContactSend.isNew=true;
                this.addChargerShipownerContact();
              }*/
              // } else {
              //TODO revisar permisos en roles
              this.accountService.getRoles().subscribe({
                next: (roles) => {
                  // console.log(roles);
                  this.roles = roles?.length ? roles : [];

                  this.accountService.getPermissions().subscribe({
                    next: (permissionsAll) => {
                      this.permissions = permissionsAll?.length
                        ? permissionsAll
                        : [];

                      const user = new User();
                      user.userName = this.newContactSend.email;
                      user.email = this.newContactSend.email;
                      user.firstName = this.newContactSend.name;
                      user.lastName = this.newContactSend.lastName;
                      user.phoneNumber = this.newContactSend.phone;
                      user.isEnabled = false;
                      user.permissions = [];

                      const rol = this.roles.filter((item) => {
                        if (item.name === "shipowner.negotiator") {
                          user.roles.push(item);
                        }
                      });

                      this.neededPermissions.forEach((per) => {
                        const permission = this.permissions.find(
                          (x) => x.value == per
                        );
                        user.permissions.push(permission);
                      });
                      // console.log('USER', user);
                      const newPassword = this.utils.generatePassword();


                      //TODO: check availability
                      let subscriptionAvailability = of(true);
                      if (index >= 0) {
                        let userIdentity = this.identityUsers[index];
                        subscriptionAvailability = this.shipownerService.checkUserAvailability(userIdentity.id);
                        user.id = userIdentity.id;
                      }
                      subscriptionAvailability.subscribe(isAvailable => {
                        if (isAvailable) {


                          var subscription = index >= 1 ? this.accountService.updateUser(user) : this.accountService
                            .newUser(new UserEdit(user, newPassword, newPassword))
                          subscription
                            .subscribe({
                              next: (user) => {
                                if (user) {
                                  this.newContactSend.userId = user.id;
                                  this.newContactSend.isNew = true;
                                  this.addChargerShipownerContact();
                                }
                              },
                              error: (err) => {
                                console.log(err);
                                this.spinner.hide();
                              },
                            });
                        }
                        else {
                          let messageError = this.translate.get("MESSAGES.VALIDATION.CONTACT_EXIST_SHIPOWNER");

                          this.messageService.showToast(
                            "ERROR",
                            messageError,
                            "error",
                            ""
                          );
                          this.newContactSend = null;
                          this.isEditting = false;
                          this.spinner.hide();
                        }

                      });

                    },

                    error: (err) => {
                      console.log(err);
                      this.spinner.hide();
                    },
                  });
                },
                error: (err) => {
                  console.log(err);
                  this.spinner.hide();
                },
              });
              // }
            },
            error: (err) => {
              console.log(err);
              this.spinner.hide();
            },
          });
        } else {
          this.addChargerShipownerContact();
        }
      } else {
        this.isEditting = false;
      }
    });
  }
  addChargerShipownerContact() {
    this.offerService
      .addChargerShipownerContact(this.idOfferShipowner, this.newContactSend)
      .subscribe({
        next: () => {
          this.offerService.getContactstById(this.idOfferShipowner).subscribe({
            next: (offerShipownerContact) => {
              if (offerShipownerContact) {
                // console.log('offerShipownerContact', offerShipownerContact);
                this.offerShipownerContacts = offerShipownerContact;
                this.listChargerShipOwnerContact = offerShipownerContact
                  .contacts?.length
                  ? offerShipownerContact.contacts
                  : [];
                // console.log('listChargerShipOwnerContact', this.listChargerShipOwnerContact);
              }
              if (this.newContactSend.isNew) {
                this.addNewContactToListEmailRegister(this.newContactSend);
              }
              this.createListToCollapsable();
              this.newContactSend = null;
              let message = "";
              message = this.isContact
                ? "MESSAGES.SUCCESS.CONTACT"
                : "MESSAGES.SUCCESS.CREATE";
              this.messageService.showToast("OK", message, "success", "");
              this.isEditting = false;
              this.spinner.hide();
            },
            error: (errr) => {
              this.spinner.hide();
            },
          });
        },
        error: (err) => {
          console.log(err);
          this.isEditting = false;
          this.spinner.hide();
        },
      });
  }
  addNewContactToListEmailRegister(newContact: ChargerShipOwnerContactDto) {
    const newContactToEmail: ResponseOfferMail = new ResponseOfferMail();
    if (newContact.userId != null && newContact.userId != "") {
      newContactToEmail.userId = newContact.userId;
      newContactToEmail.emailTo = newContact.email;
      newContactToEmail.name = newContact.name;
      newContactToEmail.userName = newContact.email;
      newContactToEmail.firstName = newContact.name;
      newContactToEmail.lastName = newContact.lastName;
      newContactToEmail.phoneNumber = newContact.phone;
      const newPassword = this.utils.generatePassword();
      newContactToEmail.newPassword = newPassword;
      this.listSendEmailRegister.push(newContactToEmail);
      // console.log('NEWCONTACT',newContactToEmail,this.listSendEmailRegister);
    }
  }
  updateFieldsToSendRegisterMailTemplate(
    newContactToEmail: ResponseOfferMail
  ): any {
    const invitatorName =
      this.globals.user.firstName && this.globals.user.firstName != ""
        ? this.globals.user.firstName
        : this.globals.currentShipOwner.charteringCompany;
    const offerMail = { ...this.offerShipOwner };
    offerMail["invitatorName"] = invitatorName;
    offerMail["chargerName"] = this.chargerName;
    offerMail["shipOwnerName"] =
      this.globals.currentShipOwner.charteringCompany;
    offerMail["userId"] = encodeURIComponent(
      this.utils.encrypt(this.globals.user.id)
    );
    offerMail["tenantId"] = encodeURIComponent(
      this.utils.encrypt(
        this.globals.user.mainTenantId || this.globals.user.tenants[0].id
      )
    );
    offerMail["shipownerId"] = encodeURIComponent(
      this.utils.encrypt(this.globals.currentShipOwner.id)
    );
    offerMail["termsConditionsEn"] = this.globals.termsConditions.termsEN;
    offerMail["legalAdviceEn"] = Constants.LEGAL_ADVICE.en;
    offerMail["dataProtectionEn"] =
      this.globals.dataProtection.policyEN + Constants.DATA_PROTECTION.en;
    offerMail["termsConditionsEs"] = this.globals.termsConditions.terms;
    offerMail["legalAdviceEs"] = Constants.LEGAL_ADVICE.es;
    offerMail["dataProtectionEs"] =
      this.globals.dataProtection.policy + Constants.DATA_PROTECTION.es;
    offerMail.offerId = encodeURIComponent(this.utils.encrypt(this.offer.id));
    offerMail["charteringCompany"] = offerMail.shipOwner.charteringCompany;
    offerMail["contactName"] = newContactToEmail.name;
    offerMail["callbackUrl"] = "{{callbackUrl}}";
    newContactToEmail.subject = mapTemplate(
      this.subjectTemplateRegister,
      offerMail,
      ['userId']
    );
    newContactToEmail.body = mapTemplate(this.bodyTemplateRegister, offerMail, ['userId']);
    // console.log('NEWCONTACT',newContactToEmail);
    return newContactToEmail;
  }
  deleteItemToListRegisterEmail(contact: any) {
    const index = this.listSendEmailRegister.findIndex(
      (item) => item.emailTo === contact.email
    );
    if (index !== -1) {
      this.listSendEmailRegister.splice(index, 1);
      this.listSendEmailRegister = [...this.listSendEmailRegister];
    }
  }
  sendMailRegister() {
    this.mapOfferLineToOffer();
    this.offerShipOwner.offer = this.offerMap;
    this.offerShipOwner.shipOwner = this.globals.currentShipOwner;
    this.sendMails = false;
    if (this.listSendEmailRegister.length > 0) {
      this.listSendEmailRegister.forEach((item) => {
        this.updateFieldsToSendRegisterMailTemplate(item);
      });
      const observables = this.listSendEmailRegister.map((item) => {
        const user = {
          email: item.emailTo,
          password: item.newPassword,
          returnUrl: document.baseURI + "security/login?returnUrl=home",
          htmlContent: item.body,
          subject: item.subject,
          firstName: item.name,
          lastName: item.lastName,
          phoneNumber: item.phoneNumber,
          roles: [],
          permissions: [],
          newPassword: item.newPassword,
          userName: item.emailTo,
          tenants: [],
        };
        return this.identityService.sendInvitation(user).pipe(
          catchError((error) => {
            console.error("Error al enviar invitación:", error);
            return of(null);
          })
        );
      });
      this.spinner.show();
      forkJoin(observables).subscribe({
        // this.mailTemplateService.sendOfferResponseMail(this.listSendEmailRegister[0]).subscribe({
        next: (results) => {
          // console.log('ENVIO SATISFACTORIO DE REGISTRO');
          const successfulResults = results.filter((result) => result !== null);
          if (successfulResults.length > 0) {
            if (!this.sendMails) {
              this.messageService.showToast(
                "INFO",
                "MESSAGES.SUCCESS.UPDATE_EMAIL",
                "success",
                ""
              );
              this.sendMails = true;
            }
          }
          this.spinner.hide();
        },
        error: (err) => {
          console.error(err);
          this.spinner.hide();
          this.sendMails = false;
          this.messageService.showToast(
            "ERROR",
            "MESSAGES.ERROR.NO_SEND_EMAIL",
            "error",
            ""
          );
        },
      });
    }
  }
  ngAfterViewInit() {
    this.cd.detectChanges();
  }
}
