import { Charger } from "../charger/charger.model";
import { Shipowner } from "../shipowner/shipowner.model";
import { ChargerShipOwnerContact } from "./charger-shipowner-contact.model";

export class ChargerShipOwner {
    id?:string;
    chargerId?:string;
    charteringCompany?: string;
    shipOwnerId?:string;
    isSelected?:boolean;
    comment?:string;
    shipOwner?:Shipowner;
    createdDate?:Date;
    charger?:Charger;
    chargerShipOwnerContacts?: ChargerShipOwnerContact[];
    isNew?:boolean;  
    counterContacts?:number;
    isEdit?:boolean;  
    lists?: any[];
    constructor() { 
        this.chargerShipOwnerContacts = [];
    }
}