import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OveraDialogService } from '@overa/components/dialog';
import { OveraMessagesService } from '@overa/components/info-dialog';
import { AccountService, Permission, Role, User, UserEdit } from '@overa/security';
import { IdentityService, NgxTranslateService } from '@overa/shared';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, of } from 'rxjs';
import { ChargerShipOwnerContactCreate } from 'src/app/models/charger/charger-shipowner-contact-create';
import { Shipowner } from 'src/app/models/shipowner/shipowner.model';
import { ChargerShipownerService } from 'src/app/services/charger-shipowner.service';
import { Utils } from 'src/app/utils/utils';
import { EmailValidation, PhoneValidation } from 'src/app/utils/validators';

@Component({
  selector: 'app-new-charger-shipowner-dialog',
  templateUrl: './new-charger-shipowner-dialog.component.html',
  styleUrls: ['./new-charger-shipowner-dialog.component.scss'],
  providers: [DialogService]
})
export class NewChargerShipownerDialogComponent implements OnInit {
  form: FormGroup;
  isreadonly: boolean = false;
  chargerId: string = "";
  listShipowners: any[] = [];
  neededPermissions = [
    "users.view",
    "roles.view",
    "users.create",
    "roles.assign",
    "users.update",
  ];
  neededRoles = ["shipowner.negotiator"];
  identityUsers: any[] = [];
  roles: any[] = [];
  permissions: any[] = [];
  listAllShipowners: Shipowner[] = [];
  allShipowners: any[] = [];
  listAllShipownersNames: string[] = [];
  constructor(private dialogConfig: DynamicDialogConfig,
    private utils: Utils,
    private ref: DynamicDialogRef,
    private chargerShipownerService: ChargerShipownerService,
    private overaDialogService: OveraDialogService,
    private overaMessagesService: OveraMessagesService,
    private accountService: AccountService,
    private translate: NgxTranslateService,
    private fb: FormBuilder) { }

  ngOnInit(): void {

    this.chargerId = this.dialogConfig.data?.data['chargerId'] || "";
    this.listShipowners = this.dialogConfig.data?.data['listShipowners'] || [];
    this.identityUsers = this.dialogConfig.data?.data['identityUsers'] || [];
    this.permissions = this.dialogConfig.data?.data['permissions'] || [];
    this.roles = this.dialogConfig.data?.data['roles'] || [];
    this.listAllShipowners = this.dialogConfig.data?.data['listAllShipowners'] || [];
    this.allShipowners = this.dialogConfig.data?.data['allShipowners'] || [];
    this.listAllShipownersNames = this.listAllShipowners.map(x => x.charteringCompany);
    this.form = this.fb.group({
      email: new FormControl("", [
        Validators.required,
        EmailValidation,
      ]),
      name: new FormControl("", [Validators.required]),
      lastName: new FormControl("", [Validators.required]),
      phone: new FormControl("", [Validators.required, PhoneValidation]),
      companyName: new FormControl("", [Validators.required]),
      remarks: new FormControl("", []),
    });

  }
  addUser(): Observable<any> {
    const user = new User();
    user.userName = this.form.get("email")?.value;
    user.email = this.form.get("email")?.value;
    user.firstName = this.form.get("name")?.value;
    user.lastName = this.form.get("lastName")?.value;
    user.phoneNumber = this.form.get("phone")?.value;
    let userId = "";
    if (user.email) {
      let existsUser = false;
      const userEdit = new UserEdit();

      for (const userIdent of this.identityUsers) {
        if (userIdent.email === user.email) {
          existsUser = true;
          userId = userIdent.id;
        }
      }

      const newPassword = this.utils.generatePassword();

      if (!user.tenants || user.tenants.length === 0) {
        user.tenants = [];
        const currentTenant = this.accountService.currentTenant;
        user.tenants.push(currentTenant);
      }

      user.id = existsUser ? userId : undefined;

      if (!existsUser) {
        user.isEnabled = false;
        if (!user.permissions) {
          user.permissions = [];
        }
        this.neededPermissions.forEach((per) => {
          if (!user.permissions.map((x: any) => x.value).includes(per)) {
            const permission =
              this.permissions.find((x: any) => x.value == per) ||
              new Permission();
            user.permissions.push(permission);
          }
        });
        this.neededRoles.forEach((r) => {
          if (!user.roles.map((x: any) => x.name).includes(r)) {
            const role = this.roles.find((x: any) => x.name == r) || new Role();
            user.roles.push(role);
          }
        });
        return this.accountService.newUser(
          new UserEdit(user, newPassword, newPassword)
        );
      } else {
        console.log("check availability");
        return of({ id: userId });
      }
    } else {
      return of({ id: userId });
    }
  }
  save() {
    if (this.form.valid) {
      console.log(this.form.value);
      this.chargerShipownerService.checkShipowner(this.chargerId, this.utils.utf8_to_b64(this.form.get('email').value.trim())).subscribe({
        next: (shipowner) => {
          const emailForm = this.form.value?.email;
          console.log(shipowner);
          const newChargerShipowner: ChargerShipOwnerContactCreate =
            new ChargerShipOwnerContactCreate(this.chargerId, false, '',
              this.form.value?.companyName, this.form.value?.name,
              this.form.value?.lastName, this.form.value?.phone, emailForm, '', '', this.form.value?.remarks,
            );
          if (shipowner && shipowner.id) {
            const shipownerData = `\n\n${shipowner.charteringCompany}\n${shipowner.cifVat || ""}`;
            const questionDialog = this.overaDialogService.openYesNo("",
              $localize`:@@shipowner.alreadyexists:Se va a importar el siguiente armador. \n¿Confirma la operación?` + shipownerData);

            questionDialog.onClose.subscribe(res => {
              console.log(res);
              if (res) {
                var contactId = shipowner.contacts.find(x => x.email == emailForm)?.id || null;

                console.log('SI!!!');
                newChargerShipowner.isImported = true;
                newChargerShipowner.shipOwnerId = shipowner.id;
                if (contactId) {
                  newChargerShipowner.shipOwnerContactId = contactId;
                }
                const identityUser = this.identityUsers.find(x => x.email == emailForm);
                if (!identityUser) {
                  this.addUser().subscribe((user: User) => {
                    newChargerShipowner.contactUserId = user.id;

                    this.createShipowner(newChargerShipowner, false);
                  });
                }
                else {
                  newChargerShipowner.contactUserId = identityUser.id;
                  this.createShipowner(newChargerShipowner, false);

                }

              }
              else {
                //  this.close();
              }
            })
          }
          else {
            this.addUser().subscribe((user: User) => {
              newChargerShipowner.contactUserId = user.id;

              this.createShipowner(newChargerShipowner, false);
            });

          }
        },
        error: (err) => {
          console.error(err);
          if (err?.error?.developerMessage?.Message?.includes('El armador ya existe en la lista de armadores del cargador')) {
            // const title = this.translate.get("ALREADY_SHIPOWNER_IN_LIST");
            // const body = this.translate.get("ALREADY_SHIPOWNER_IN_LIST");
            this.overaMessagesService.showToast("Warning", "MESSAGES.ERROR.ALREADY_SHIPOWNER_IN_LIST", "warning", "");
          }
        }

      })

    }

  }
  createShipowner(shipowner: ChargerShipOwnerContactCreate, existing: boolean = false) {

    this.chargerShipownerService.addNewShipownerContact(shipowner).subscribe({
      next: (resShipowner) => {
        console.log('SHIPOWNER CREATED!!!', resShipowner);
        this.close(resShipowner);
      },
      error: (err) => {
        console.error(err);
      }
    })
  }
  close(data?: any) {

    this.ref.close({ shipowner: data, email: this.form.value?.email });
  }
  filterChartering(event: any) {
    const shipownersCharger = this.listShipowners.map(
      (l) => l.charteringCompany
    );
    this.listAllShipowners = [
      ...this.allShipowners.filter(
        (x: any) => !shipownersCharger.includes(x.charteringCompany)
      ),
    ];
    console.log(event);
    const filtered: any[] = [];
    const query = event.query;

    for (let i = 0; i < this.listAllShipowners.length; i++) {
      const shipowner = this.listAllShipowners[i];
      if (
        shipowner.charteringCompany &&
        shipowner.charteringCompany
          .toLowerCase()
          .indexOf(query.toLowerCase()) >= 0
      ) {
        filtered.push(shipowner);
      }
    }

    this.listAllShipowners = filtered;
    this.listAllShipownersNames = filtered.map((x: any) => x.charteringCompany);
  }

}
