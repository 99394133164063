import {
  AfterViewInit,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmationService, MessageService } from "primeng/api";
import { ComparativeOffer } from "src/app/models/offers/comparative-offer.model";
import { FreightTargetCurrency } from "src/app/models/enum/freight-target-currency.model";
import { FreightTargetType } from "src/app/models/enum/freight-target-type.model";
import { DemurrageType } from "src/app/models/enum/demurrage-type.model";
import { Offer } from "src/app/models/offers/offer.model";
import { OfferShipOwner } from "src/app/models/offers/offer-shipowner.model";
import { StateOfferShipOwner } from "src/app/models/enum/state-offer-shipowner.model";
import { QuantityType } from "src/app/models/enum/quantity-type.model";
import { OfferService } from "src/app/services/offer.service";
import { CLHGlobals } from "src/app/utils/globals";
import { ComparativeOfferShipOwner } from "src/app/models/offers/comparative-offer-shipowner.model";
import { switchMap, forkJoin, of, Observable } from "rxjs";
import { ChatHelper } from "src/app/utils/chat-helper.utils";
import { ChatGroup } from "src/app/models/chat/chatGroup.model";
import { ChatMessage } from "src/app/models/chat/chatMessage.model";
import { ChatParticipant } from "src/app/models/chat/chatParticipant.model";
import { AccountService } from "@overa/security";
import { Constants } from "../../utils/constants";
import { DialogService } from "primeng/dynamicdialog";
import { MatDialog } from "@angular/material/dialog";
import { OfferState } from "../../models/enum/offer-state.model";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PermissionsService } from "../../services/permissions.service";
import { MailDTO } from "src/app/models/mail-templates/mail-dto.model";
import { AddresseeType } from "src/app/models/enum/addresseeType.model";
import { MailTemplateTypes } from "src/app/models/enum/mail-template-types";
import { OveraMessagesService } from "@overa/components/info-dialog";
import {
  MessageTemplate,
  MessageTemplateService,
  NotificationMessage,
  NotificationService,
} from "@overa/notification";
import { NgxTranslateService } from "@overa/shared";
import { mapTemplate } from "src/app/utils/template-helper";import { MailTemplateService } from "src/app/services/mail-template.service";

@Component({
  selector: "app-offer-comparative",
  templateUrl: "./offer-comparative.component.html",
  styleUrls: ["./offer-comparative.component.scss"],
  providers: [MessageService, ConfirmationService],
})
export class OfferComparativeComponent implements OnInit {
  offerDto!: ComparativeOffer;
  chargerName!: string;
  listOfferShipOwners: OfferShipOwner[] = [];
  // colors: string[] = Constants.COLORS_TABLE;

  showTable: boolean = false;
  chatValues: Array<{
    offerId?: string;
    offerShipOwnerId?: string;
    currentParticipant: ChatParticipant;
    group: ChatGroup;
    windowName: string;
    historicMessages: ChatMessage[];
    unReadMessages: number;
  }> = [];
  isClh: boolean = false;
  // idRoute:string;
  form!: FormGroup;
  commentsComparative: string = "";
  offerId!: string;
  offer!: Offer;
  readonlyComparative: boolean = false;
  mailTemplateService = inject(MailTemplateService);
  messageTemplateService = inject(MessageTemplateService);
  mailTemplates: MessageTemplate[];
  template: string;
  subjectTemplate: string;
  statusReadonly = [
    StateOfferShipOwner.RECAP_SHIPOWNER_PENDING,
    StateOfferShipOwner.RECAP_CHARTERER_PENDING,
    StateOfferShipOwner.RECAP,
    StateOfferShipOwner.RECAP_CONFIRMED,
    StateOfferShipOwner.AGREEMENT_STARTED, // cargador crea oferta en firme
    StateOfferShipOwner.AGREEMENT_PREVALIDATED, //armador contraoferta (cl�usulas textarea)
    StateOfferShipOwner.AGREEMENT_VALIDATED, //CLH a�ade cl�usulas armador
    StateOfferShipOwner.CHARTERER_COUNTER_OFFER, //COUNTER
    StateOfferShipOwner.SHIPOWNER_COUNTER_OFFER,
    StateOfferShipOwner.COMPLETED_CHARTERER_PENDING,
    StateOfferShipOwner.COMPLETED_SHIPOWNER_PENDING,
    StateOfferShipOwner.COMPLETED,
  ];
  statusReadonlyOffer = [
    OfferState.NEGOTIATION,
    OfferState.OPERATIVE,
    OfferState.HISTORIC,
  ];
  statusNone = [StateOfferShipOwner.NONE];
  statusRefused = [StateOfferShipOwner.REFUSED, StateOfferShipOwner.CANCELLED];
  statusShowOriginalFieldOffer = [
    StateOfferShipOwner.NONE,
    StateOfferShipOwner.WAIT_RESPONSE,
  ];
  statusRemember = [StateOfferShipOwner.WAIT_RESPONSE];
  OfferSendEmail: ComparativeOffer = new ComparativeOffer();
  showChat: boolean = true;
  allMethodsCompleted: boolean = false;
  readonly QuantityType = QuantityType;
  readonly FreightTargetCurrency = FreightTargetCurrency;
  readonly FreightTargetType = FreightTargetType;
  readonly DemurrageType = DemurrageType;
  readonly OfferState = OfferState;
  readonly StateOffershipowner = StateOfferShipOwner;

  constructor(
    protected globals: CLHGlobals,
    private route: ActivatedRoute,
    public messageService: OveraMessagesService,
    private translate: NgxTranslateService,
    private offerService: OfferService,
    private spinner: NgxSpinnerService,
    private chatHelper: ChatHelper,
    private accountService: AccountService,
    protected dialogService: MatDialog,
    private confirmationService: ConfirmationService,
    public router: Router,
    private permissionsService: PermissionsService,
    private notificationService: NotificationService
  ) {}

  //TODO FALTA FILTRAR POR EL ESTADO DE LA OFFERSHIPOWNER

  ngOnInit() {
    this.showChat = true;
    this.initialForm();
    this.spinner.show();
    if (
      this.accountService.currentUser.roles
        .map((x) => x.name)
        .includes("CLH Admin")
    ) {
      this.isClh = true;
    }
    if (this.globals.currentCharger || this.isClh) {
      if (!this.permissionsService.hasPermissionToEdit(this.route)) {
        this.readonlyComparative = true;
      }
      console.log(this.globals.currentCharger);
      this.route.params.subscribe((paramsId) => {
        console.log(paramsId);

        if (paramsId?.["id"]) {
          if (paramsId["id"]) {
            // this.idRoute=paramsId.id;
            this.offerId = paramsId["id"];
            this.offerService
              .getOfferShipOwnerDtoForComparative(paramsId["id"])
              .subscribe({
                next: (value) => {
                  this.offerDto = value;
                  this.setChatOptions(this.offerDto);
                  this.form
                    .get("commentsComparative")
                    ?.setValue(
                      this.offerDto?.comparativeNotes != ""
                        ? this.offerDto.comparativeNotes
                        : ""
                    );

                  console.log("offerDto", value);
                  //REVISAR FILTRAR POR EL ESTADO
                  //REVISAR ESTADOS POR SI CAMBIA
                  const statusFirst = [
                    StateOfferShipOwner.RECAP,
                    StateOfferShipOwner.RECAP_CHARTERER_PENDING,
                    StateOfferShipOwner.RECAP_SHIPOWNER_PENDING,
                    StateOfferShipOwner.RECAP_CONFIRMED,
                    StateOfferShipOwner.AGREEMENT_STARTED,
                    StateOfferShipOwner.AGREEMENT_VALIDATED,
                    StateOfferShipOwner.AGREEMENT_PREVALIDATED,
                    StateOfferShipOwner.CHARTERER_COUNTER_OFFER,
                    StateOfferShipOwner.SHIPOWNER_COUNTER_OFFER,
                    StateOfferShipOwner.COMPLETED_CHARTERER_PENDING,
                    StateOfferShipOwner.COMPLETED_SHIPOWNER_PENDING,
                    StateOfferShipOwner.COMPLETED,
                    StateOfferShipOwner.BIDED,
                  ];
                  this.offerDto.offerShipOwners = this.offerDto.offerShipOwners
                    ?.filter(
                      (element) =>
                        !this.statusNone.includes(element?.state || 0)
                    )
                    .sort((a, b) => {
                      if (statusFirst.includes(a.state || 0)) {
                        return -1;
                      } else {
                        return 0;
                      }
                    });
                  if (
                    this.statusReadonlyOffer.includes(this.offerDto.state || 0)
                  ) {
                    this.readonlyComparative = true;
                  }
                  if (!this.offerDto.offerShipOwners?.length) {
                    this.messageService.showToast(
                      "INFO",
                      "COMPARATIVE_OFFER.NO_RESPONSE",
                      "info",
                      ""
                    );
                  } else {
                    const expirationDateOffer = this.offerDto.expirationDate
                      ? new Date(this.offerDto.expirationDate)
                      : null;
                    this.offerDto.offerShipOwners.forEach((element, index) => {
                      if (index % 2 == 0) {
                        element.color = "#F0EFEF";
                      } else {
                        element.color = "#FFF";
                      }
                      element.showButtonRemember = this.statusRemember.includes(
                        element.state || 0
                      )
                        ? true
                        : false;
                      if (!this.statusRefused.includes(element.state || 0)) {
                        element.opacity = "1";
                        element.isDeclinedLabel = false;
                        if (this.statusReadonly.includes(element.state || 0)) {
                          this.readonlyComparative = true;
                          //element.opacity = '0.6';
                          element.opacity = "1";
                        }
                      } else {
                        //element.opacity = '0.6';
                        element.opacity = "1";
                        element.isDeclinedLabel = true;
                      }
                      element.inTime = true;
                      const indicationDate = element.indicationDate
                        ? new Date(element.indicationDate)
                        : null;
                      if (
                        expirationDateOffer &&
                        indicationDate &&
                        expirationDateOffer.getTime() < indicationDate.getTime()
                      ) {
                        element.inTime = false;
                      }
                    });
                  }
                  if (this.offerDto.offerShipOwners?.length) {
                    this.offerDto.offerShipOwners.forEach((element) => {
                      if (!element.layCanFrom) {
                        element.layCanFrom = this.offerDto.layCanFrom;
                      } else if (!element.layCanTo) {
                        element.layCanTo = this.offerDto.layCanTo;
                      }
                    });
                  }
                  this.offerService.getById(this.offerId).subscribe({
                    next: (value) => {
                      if (value) {
                        this.offer = value;
                        console.log(
                          "listaOfferShipowners",
                          this.offerDto.offerShipOwners
                        );
                        this.offerDto.offerShipOwners?.forEach((item) => {
                          item.showOriginalFieldOffer =
                            this.statusShowOriginalFieldOffer.includes(
                              item.state || 0
                            )
                              ? false
                              : true;
                        });

                        this.spinner.hide();
                        this.showTable = true;
                      }
                    },
                    error: (err) => {
                      console.log(err);
                      this.spinner.hide();
                    },
                  });
                },
                error: (err) => {
                  console.log("errorComparativa", err);
                },
              });
          }
        }
      });
    }
  }
  initialForm() {
    this.form = new FormGroup({
      commentsComparative: new FormControl("", [Validators.required]),
    });
  }
  //commented because it is no longer declined
  // decline(offershipOwner:ComparativeOfferShipOwner){

  //   let variableDecline='Decline';
  //   this.updateOffershipOwner(variableDecline,offershipOwner);
  // }

  setChatOptions(offerDto: ComparativeOffer) {
    console.log(offerDto);
    const isClh = this.accountService.currentUser.roles
      .map((x) => x.name)
      .includes("CLH Admin");
    this.chatHelper.getCurrentParticipant(this.globals.user).subscribe({
      next: (participant) => {
        if (isClh) {
          // CHAT CLH - CHARGER
          this.allMethodsCompleted = false;
          // let oneShipOwnerOffer = offerDto.offerShipOwners[0].id;
          const offerId = this.offerId;
          const clhChargerSubscriptions = this.chatHelper
            .getChatGroupChargerCLH(offerId)
            .pipe(
              switchMap((group) => {
                return forkJoin({
                  currentParticipant: of(participant),
                  group: of(group),
                  historicMessages: this.chatHelper.getMessageHistory(group.id),
                });
              })
            );

          clhChargerSubscriptions.subscribe(
            ({ currentParticipant, group, historicMessages }) => {
              if (currentParticipant && group && historicMessages) {
                const obj = {
                  offerId: offerId,
                  currentParticipant: currentParticipant,
                  windowName: offerDto.chargerName || "",
                  group: group,
                  historicMessages: historicMessages.map((s) =>
                    this.chatHelper.setReplyBasedOnCurrentUser(s)
                  ),
                  unReadMessages: historicMessages
                    .map((s) => this.chatHelper.setReplyBasedOnCurrentUser(s))
                    .filter(
                      (s) => s.participantId != currentParticipant.id && !s.seen
                    ).length,
                };
                this.chatValues.push(obj);
              }
            }
          );

          const clhShipownerSubscriptions = [];
          for (const offerShipowner of offerDto.offerShipOwners || []) {
            const subscription = this.chatHelper
              .getChatGroupCLHOfferShipOwner(offerShipowner.id || "")
              .pipe(
                switchMap((group) => {
                  return forkJoin({
                    currentParticipant: of(participant),
                    group: of(group),
                    historicMessages: this.chatHelper.getMessageHistory(
                      group.id
                    ),
                    offerShipownerId: of(offerShipowner.id),
                    shipOwnerName: of(offerShipowner.shipOwnerName),
                  });
                })
              );

            clhShipownerSubscriptions.push(subscription);
          }
          let indexSubs = 0;
          const chatValuesShipowners = [];
          clhShipownerSubscriptions.forEach((subs) => {
            subs.subscribe((element) => {
              const obj = {
                offerShipOwnerId: element.offerShipownerId,
                currentParticipant: element.currentParticipant,
                windowName: element.shipOwnerName || "",
                group: element.group,
                historicMessages: element.historicMessages.map((s) =>
                  this.chatHelper.setReplyBasedOnCurrentUser(s)
                ),
                unReadMessages: element.historicMessages
                  .map((s) => this.chatHelper.setReplyBasedOnCurrentUser(s))
                  .filter(
                    (s) =>
                      s.participantId != element.currentParticipant.id &&
                      !s.seen
                  ).length,
              };
              chatValuesShipowners.push(obj);
              indexSubs++;
              if (indexSubs == clhShipownerSubscriptions.length) {
                this.chatValues = [...this.chatValues, ...chatValuesShipowners];
                this.allMethodsCompleted = true;
              }
            });
          });

          // // CHAT CLH WITH SHIPOWNERS
          // offerDto.offerShipOwners.forEach((offerShipOwner) => {
          //   let chargerShipOwnerSubscriptions = this.chatHelper.getChatGroupCLHOfferShipOwner(offerShipOwner.id).pipe(
          //     switchMap((group) => {
          //       return forkJoin({
          //         windowName: of(offerShipOwner.shipOwnerName),//HACK: puesto directamente el shipowner name pq se mostrará ese para clh, pero deberia ir en el metodo de getWindowName
          //         currentParticipant: this.chatHelper.getCurrentParticipant(this.globals.user),
          //         group: of(group),
          //         historicMessages: this.chatHelper.getMessageHistory(group.id),
          //       });
          //     })
          //   );

          //   chargerShipOwnerSubscriptions.subscribe(({ windowName, currentParticipant, group, historicMessages }) => {
          //     let obj = {
          //       offerShipOwnerId: offerShipOwner.id,
          //       currentParticipant: currentParticipant,
          //       windowName: windowName,
          //       group: group,
          //       historicMessages: historicMessages.map((s) => this.chatHelper.setReplyBasedOnCurrentUser(s)),
          //     };
          //     this.chatValues.push(obj);
          //   });
          // });
        } else if (this.globals.currentCharger) {
          //Todo el codigo para chat Cargador
          this.allMethodsCompleted = false;
          // let oneShipOwnerOffer = offerDto.offerShipOwners[0].id;
          const offerId = this.offerId;
          const clhChargerSubscriptions = this.chatHelper
            .getChatGroupChargerCLH(offerId)
            .pipe(
              switchMap((group) => {
                return forkJoin({
                  windowName: "CLH",
                  currentParticipant: of(participant),
                  group: of(group),
                  historicMessages: this.chatHelper.getMessageHistory(group.id),
                });
              })
            );
          clhChargerSubscriptions.subscribe(
            ({ windowName, currentParticipant, group, historicMessages }) => {
              if (
                windowName &&
                currentParticipant &&
                group &&
                historicMessages
              ) {
                const obj = {
                  offerId: offerId,
                  currentParticipant: currentParticipant,
                  windowName: "CLH",
                  group: group,
                  historicMessages: historicMessages.map((s) =>
                    this.chatHelper.setReplyBasedOnCurrentUser(s)
                  ),
                  unReadMessages: historicMessages
                    .map((s) => this.chatHelper.setReplyBasedOnCurrentUser(s))
                    .filter(
                      (s) => s.participantId != currentParticipant.id && !s.seen
                    ).length,
                };
                this.chatValues.push(obj);
                this.allMethodsCompleted = true;
              }
            }
          );
        }
        // INFO: Cannot be currentShipOwner on this screen
        // if(this.globals.currentShipOwner){
        //   //Code for shipowner
        // }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  cancelAndArchiveOffer() {
    const trmessage = this.translate.get("COMPARATIVE_OFFER.ASK_CANCEL_OFFER");
    const messageAccept = this.translate.get("ACTION.YES");

    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.spinner.show();
        this.offerService.cancelOffer(this.offerId).subscribe({
          next: () => {
            this.spinner.hide();
            this.messageService.showToast(
              "OK",
              "MESSAGES.SUCCESS.UPDATE_LONG",
              "success",
              ""
            );
            this.router.navigate(["home"]);
          },
          error: (err) => {
            console.log("errorCancelOffer", err);
            this.spinner.hide();
          },
        });
      },
      reject: () => {
        console.log("reject");
      },
    });
  }
  sendMailRemember(comparativeOffershipOwner: ComparativeOfferShipOwner) {
    console.log(comparativeOffershipOwner, this.offerDto);
    let type = "";
    this.spinner.show();
    const emailDto = new MailDTO();
    emailDto.offerShipOwnerId = comparativeOffershipOwner.id;
    emailDto.addresseeType = AddresseeType.SHIPOWNER;
    type = this.isClh
      ? MailTemplateTypes.COMPARATIVE_REMEMBER_SHIPOWNER_CLH
      : MailTemplateTypes.COMPARATIVE_REMEMBER_SHIPOWNER;
    this.messageTemplateService.readAll().subscribe({
      next: (templates) => {
        this.mailTemplates = templates;
        const template = this.mailTemplates.find((x) => x.name == type);
        if (template) {
          this.template = template.content;
          this.subjectTemplate = template.subject;
        }
        this.OfferSendEmail = { ...this.offerDto };
        this.OfferSendEmail["shipOwnerName"] =
          comparativeOffershipOwner.shipOwnerName;
        this.OfferSendEmail["offerShipOwnerId"] = comparativeOffershipOwner.id;
        this.OfferSendEmail["chargerName"] = this.offerDto.chargerName;
        this.OfferSendEmail["termsConditions"] =
          this.globals.termsConditions.termsEN;
        this.OfferSendEmail["legalAdvice"] = Constants.LEGAL_ADVICE.en;
        this.OfferSendEmail["dataProtection"] =
          this.globals.dataProtection.policyEN + Constants.DATA_PROTECTION.en;
        // console.log(this.OfferSendEmail);
        emailDto.body = mapTemplate(this.template, this.OfferSendEmail);
        emailDto.subject = mapTemplate(
          this.subjectTemplate,
          this.OfferSendEmail
        );
        console.log(emailDto);
        this.mailTemplateService.sendMail(emailDto).subscribe({
          next: () => {
            this.spinner.hide();
            this.messageService.showToast(
              "INFO",
              "MESSAGES.SUCCESS.UPDATE_EMAIL",
              "success",
              ""
            );
          },
          error: (err) => {
            console.error(err);
            this.spinner.hide();
            this.messageService.showToast(
              "ERROR",
              "MESSAGES.ERROR.NO_SEND_EMAIL",
              "error",
              ""
            );
          },
        });
      },
      error: (err) => {
        this.spinner.hide();
        console.error(err);
        this.messageService.showToast(
          "ERROR",
          "MESSAGES.ERROR.BAD_REQUEST_BODY",
          "error",
          ""
        );
      },
    });
  }
}
