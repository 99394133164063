<div *ngIf="version" class="version-layer">**&nbsp;{{ version }}&nbsp;**</div>
<div class="container-master">
  <div
    *ngIf="!kioskMode"
    class="sidebar"
    data-color="white"
    data-background-color="black"
  >
    <app-sidebar-cmp #sidebar [menuItems]="menuItems" logo_svgPath = "../../assets/img/logo_white.svg"></app-sidebar-cmp>
  </div>

  <div *ngIf="!kioskMode" class="main-navbar">
    <section *ngIf="!kioskMode" class="top-nav-responsive">
      <label class="menu_responsive-button-container" for="menu_responsive-toggle">
        <div class="menu_responsive-button"></div>
      </label>
      <ul class="menu_responsive"></ul>
    </section>

    <overa-navbar
      #navbar
      [menuItems]="menuItems"
      [menubarItems]="menubarItems">
      <div midnav *ngIf="showNewOfferButton" class="container-btn-offer-request">
        <a
          class="btn btn-link new-btn-oferta-firme"
          (click)="goToOffer()"
          [escape]="false"
          [pTooltip]="'TOOLTIP.REQUEST_OFFER'| translate"
          tooltipPosition="bottom"
          tooltipStyleClass="tooltipCLH"
          [showDelay]="1000"
          [hideDelay]="1000"
          ><img class="icon-btn-oferta-firme" src="./assets/icons/icon_seleccionar_oferta.svg" />
          <!-- <span class="span-oferta-firme"
            >{{'OFFER_REQUEST.BUTTON_TITLE_SHORT' | translate}}</span> -->
        </a>
      </div>
    </overa-navbar>
  </div>

  <div class="container-level-2">
    <div class="root-layout" *ngIf="loadedCatalogue">
      <ngx-spinner
        bdColor="rgba(0, 0, 0, 0.4)"
        size="default"
        color="#fff"
        type="ball-newton-cradle"
      >
        <p class="spinner-text-color" i18n="@@messages.loading">Cargando</p>
      </ngx-spinner>
          <!-- CONTAINERS BREADCRUMBS & SEARCH -->
    <div class="topbar2  pb-1 d-flex flex-wrap justify-content-between " [ngClass]="isClhUser ? 'clh-user' : 'no-clh-user'">
      <!-- BREADCRUMB DIV -->
      <div class="d-flex justify-content-between">
        <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
        <span class="d-flex align-items-center entityName" *ngIf="currentEntity"> {{currentEntity.name}}</span>
      </div>

      <!-- SEARCH DIV -->
      <div class="div-search d-flex flex-row flex-wrap justify-content-center">
        <!-- <div class="d-flex flex-column justify-content-end align-items-end"> -->
        <div class="d-flex flex-row flex-wrap justify-content-center align-items-center">
          <form (submit)="search()" *ngIf="showSearch" class="formInputSearcher d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-center align-items-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search" (click)="search()"></i>
                <input
                  type="text"
                  Id="search-home"
                  class="input-search"
                  size="20"
                  pInputText
                  placeholder="{{'LAYOUT.SEARCHER_PLACEHOLDER'|translate}}"
                  [(ngModel)]="searchText"
                  [ngModelOptions]="{standalone: true}"
                  style="width: auto"
                  [class.ellipsis]="true"
                  placeholder.appEllipsis="16"
                />
              </span>
              <span class="p-input-icon-right">
                <i class="pi pi-times searcher" (click)="clearSearcher()"></i>
              </span>
              <!--<div class="d-flex flex-column justify-content-between align-items-center">
                    <button type="submit" class="btn-link border-0"><i class="pi pi-search searcher"></i></button>
                    <button type="button" class="btn-link border-0"><i class="pi pi-refresh searcher" (click)="clearSearcher()"></i></button>

                </div>-->
            </div>
          </form>
          <div class="calendarSearcher d-flex flex-row justify-content-center align-items-center" *ngIf="showSearch">
            <p-calendar
              dateFormat="dd/mm/yy"
              [(ngModel)]="rangeDatesSearcher"
              selectionMode="range"
              (ngModelChange)="sendDates()"
              [utc]="true"
              inputId="calendarSearcherRange"
              styleClass="calendarTableOffer"
              inputStyleClass="calendarSearcher"
              [escape]="false"
              [pTooltip]="'TOOLTIP.SEARCHER_DATE_MAIN'| translate"
              tooltipPosition="bottom"
              tooltipStyleClass="tooltipCLH"
              [showDelay]="1000"
              [hideDelay]="1000"
              placeholder="{{'LAYOUT.SEARCHER_DATE'|translate}}"
            ></p-calendar>
          </div>
          <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="showSearch">
            <p-multiSelect
              appendTo="body"
              [options]="filters"
              optionLabel="label"
              [filter]="false"
              [(ngModel)]="selectedFilters"
              defaultLabel="{{'COMMONS.FILTERS' | translate}}"
              display="chip"
              (onChange)="updateFilterValue()"
            ></p-multiSelect>
            <!-- <div class="d-flex justify-content-center align-items-center" *ngIf="showSearch">
                <button
                  class="btn btn-link"
                  type="button"
                  (click)="isCollapsed = !isCollapsed"
                  [attr.aria-expanded]="isCollapsed"
                  aria-controls="collapseSearcher"
                >
                  <div class="d-flex align-items-center btnMoreFilter">
                    <span class="ml-1">{{'COMMONS.FILTERS' | translate}} </span>
                    <span class="p-button-icon" [ngClass]="isCollapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up'"></span>
                  </div>
                </button>
              </div>
              <div class="position-relative w-100">
                <div
                  class="collapse overflow-auto"
                  [ngClass]="isCollapsed ? '' : 'd-flex flex-column justify-content-end divChecks'"
                  id="collapseSearcher"
                  *ngIf="showSearch"
                  style="position: absolute; top: 100%"
                >
                  <div class="d-flex justify-content-end align-items-center checkboxDiv">
                    <p-checkbox [(ngModel)]="offerFilter.isActive" [binary]="true" label="{{'COMMONS.ACTIVE_OFFER' | translate}}"></p-checkbox>
                  </div> -->
            <!-- <div class="d-flex justify-content-center align-items-end m-2 boderTopDiv">
                  <button (click)="cleanChecks()" class="searchClean mt-2">{{'ACTION.CLEAN' | translate}}</button>
                </div> -->
            <!-- </div>
              </div> -->
          </div>
          <div *ngIf="showSearch">
            <button
              pButton
              type="button"
              icon="pi pi-times"
              [pTooltip]="'ACTION.CLEAN'| translate"
              tooltipPosition="bottom"
              tooltipStyleClass="tooltipCLH"
              (click)="cleanAllSearcher()"
              class="buttonCleanSearcher"
            ></button>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>

    <!-- END CONTAINERS BREADCRUMBS & SEARCH -->
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
