<ngx-spinner></ngx-spinner>
<p-toast></p-toast>
<p-confirmDialog
  styleClass="containerConfirmDialog"
  acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"
></p-confirmDialog>
<div class="main offerReceived" *ngIf="isShow">
  <div class="row" *ngIf="expirationDate">
    <div class="col-12">
      <div class="d-flex justify-content-end divMargin">
        <h4>
          <i class="pi pi-history" style="font-size: 2rem"></i><strong> {{'BINDING_OFFER.DEADLINE_EXPIRES' | translate}} </strong> {{expirationDate |
          date: 'dd/MM/yy HH:mm' }}
        </h4>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="p-3 col-12 col-sm-12 col-md-12 col-xl-8">
      <app-table-offer [offer]="selectedOffer" [readonly]="true" [showAddressCom]="true"></app-table-offer>
    </div>
    <div class="p-3 col-12 col-sm-12 col-md-12 col-xl-4">
      <div class="col-12">
        <p-tabView *ngIf="chatValues && (chatValues.length > 0) && allMethodsCompleted; else loadingSpinner">
          <ng-container *ngFor="let group of chatValues">
            <p-tabPanel header="{{group.windowName}}">
              <app-chat-room
                [offerShipOwnerId]="group.offerShipOwnerId"
                [windowName]="group.windowName"
                [currentParticipant]="group.currentParticipant"
                [group]="group.group"
                [messages]="group.historicMessages"
                [unReadMessages]="group.unReadMessages"
                (unReadMessagesUpdated)="group.unReadMessages = $event"
              ></app-chat-room>
            </p-tabPanel>
          </ng-container>
        </p-tabView>
        <ng-template #loadingSpinner>
          <div class="spinner-container">
            <mat-spinner></mat-spinner>
          </div>
        </ng-template>
      </div>
      <p-accordion styleClass="accordionResponse">
        <p-accordionTab>
          <ng-template pTemplate="header"> {{'DOCUMENT.HEADER' | translate}}</ng-template>
          <ng-template pTemplate="content">
            <app-documents
              *ngIf="offerShipOwnerToExpiration && offerShipOwnerToExpiration.id"
              [form]="form"
              [entityId]="offerShipOwnerToExpiration.offerId"
              [groupId]="offerShipOwnerToExpiration.id"
              [placeholder]="isReadonly ? ('DOCUMENT.HEADER' | translate) : ('DOCUMENT.SELECT' | translate)"
              [readonly]="isReadonly"
            >
            </app-documents>
          </ng-template>
        </p-accordionTab>
        <p-accordionTab>
          <ng-template pTemplate="header"> {{'NOTE.HEADER' | translate}}</ng-template>
          <ng-template pTemplate="content">
            <overa-notes [entityId]="offerShipOwnerToExpiration.offerId" [groupId]="offerShipOwnerToExpiration.id"> </overa-notes>
          </ng-template>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>

  <div class="row" *ngIf="!isReadonly">
    <div class="p-3 col-12 col-sm-12 col-md-12 col-xl-8 d-flex flex-row justify-content-center flex-wrap">
      <div class="m-3">
        <div>
          <button
            type="button"
            pButton
            label="{{'COMMONS.BUTTON_DECLINE' | translate}}"
            class="p-button p-ripple borrar"
            (click)="declineOffer()"
          ></button>
        </div>
      </div>
      <div class="m-3">
        <div>
          <button
            type="button"
            pButton
            label="{{'COMMONS.BUTTON_OFFER_INDICATION' | translate}}"
            class="p-button p-ripple siguiente actualizar"
            (click)="acceptOffer()"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>
