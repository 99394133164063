import { OveraLayoutComponent } from "@overa/base";

import { environment } from "src/environments/environment";
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  AfterViewInit,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  inject,
  ViewChildren,
  ViewChild,
} from "@angular/core";
import { filter, Subscription } from "rxjs";
import { NavbarComponent, Link } from "@overa/base";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MenuItem, PrimeNGConfig } from "primeng/api";
import { AccountService, Menu, MenuService } from "@overa/security";
import { CatalogueService } from "src/app/services/catalogue.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ChooseUserService } from "../services/choose-user.service";
import { LanguageCode } from "../models/enum/language-code.model";
import { Country } from "../models/country/country.model";
import { CountryService } from "../services/country.service";
import { SearcherService } from "../services/searcher.service";
import { OfferFilter } from "../models/searcher/offer-filter.model";
import { BreadcrumbService } from "../services/breadcrumb.service";
import { Breadcrumb } from "primeng/breadcrumb";
import { TermsAndConditionsService } from "../services/terms-and-conditions.service";
import { TermsAndConditions } from "../models/clause/terms-and-conditions.model";
import { Constants } from "../utils/constants";
import { PrivacyPoliciesService } from "../services/privacy-policies.service";
import { PrivacyPolicies } from "../models/privacy-policies/privacy-policies.model";
import { CLHGlobals } from "../utils/globals";
import { NgxTranslateService } from "@overa/shared";
import { NOTIFICATION_TYPE } from "@overa/notification";
import { OveraMenuItem } from "@overa/components/menubar";
import { OveraNavbarComponent } from "@overa/components/navbar";
@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent extends OveraLayoutComponent {
  static override className = "LayoutComponent";
  override languages = [
    { key: "es", translateKey: "Es" },
    { key: "en", translateKey: "En" },
  ];
  home: MenuItem;
  items: MenuItem[];
  loadedCatalogue = false;
  currentEntity;
  showSearch: boolean = false;
  arrayUrl: string[] = ["/home"];
  searchText: string = "";
  showNewOfferButton = false;
  isCharger = false;
  isShipowner = false;
  isChl = false;
  isClhUser = false;
  navItems: MenuItem[] = [];
  rangeDatesSearcher: Date[];
  offerFilter: OfferFilter = new OfferFilter();
  isCollapsed: boolean = true;
  filters: any[];
  filter: any;
  selectedFilters: any[];
  rolesToRequestOffer = ["charterer.negotiator"];
  breadcrumbSubscription: Subscription;
  breadcrumb: MenuItem[];
  version!: string;
  translate = inject(NgxTranslateService);
  breadcrumbService = inject(BreadcrumbService);
  globals = inject(CLHGlobals);
  cdRef = inject(ChangeDetectorRef);
  chooseUserService = inject(ChooseUserService);
  termsConditionsService = inject(TermsAndConditionsService);
  privacyPoliciesService = inject(PrivacyPoliciesService);
  catalogueService = inject(CatalogueService);
  countryService = inject(CountryService);
  searcherService = inject(SearcherService);
  primeNGConfig = inject(PrimeNGConfig);
  // @ViewChild('navbar') navbar!: OveraNavbarComponent;
  override init = () => {

    // this.locale = localStorage.getItem("locale") ?? this.initConfig.config.defaultLocale;
    //this.namespaces = this.initConfig.config.namespaces;

    this.primeNGConfig.setTranslation({
      startsWith: $localize`:@@primeng.startWith:Empieza por`,
      contains: $localize`:@@primeng.contains:Contiene`,
      notContains: $localize`:@@primeng.notContains:No contiene`,
      endsWith: $localize`:@@primeng.endsWith:Termina con`,
      equals: $localize`:@@primeng.equals:Igual`,
      notEquals: $localize`:@@primeng.notEquals:Distinto`,
      noFilter: $localize`:@@primeng.noFilter:No Filter`,
      lt: $localize`:@@primeng.lt:Menor que`,
      lte: $localize`:@@primeng.lte:Menor o igual que`,
      gt: $localize`:@@primeng.gt:Mayor que`,
      gte: $localize`:@@primeng.gte:Mayor o igual que`,
      is: $localize`:@@primeng.is:Es`,
      isNot: $localize`:@@primeng.isNot:No es`,
      before: $localize`:@@primeng.before:Antes`,
      after: $localize`:@@primeng.after:Después`,
      dateIs: $localize`:@@primeng.dateIs:La fecha es`,
      dateIsNot: $localize`:@@primeng.dateIsNot:La fecha no es`,
      dateBefore: $localize`:@@primeng.dateBefore:La fecha es antes de`,
      dateAfter: $localize`:@@primeng.dateAfter:La fecha es después de`,
      clear: $localize`:@@primeng.clear:Limpiar`,
      apply: $localize`:@@primeng.apply:Aplicar`,
      matchAll: $localize`:@@primeng.matchAll:Match All`,
      matchAny: $localize`:@@primeng.matchAny:Match Any`,
      addRule: $localize`:@@primeng.addRule:Añadir Regla`,
      removeRule: $localize`:@@primeng.removeRule:Quitar Regla`,
      accept: $localize`:@@primeng.accept:Sí`,
      reject: $localize`:@@primeng.reject:No`,
      choose: $localize`:@@primeng.choose:Seleccionar`,
      upload: $localize`:@@primeng.upload:Subir`,
      cancel: $localize`:@@primeng.cancel:Cancelar`,
      dayNames: [
        $localize`:@@primeng.sunday:Domingo`,
        $localize`:@@primeng.monday:Lunes`,
        $localize`:@@primeng.tuesday:Martes`,
        $localize`:@@primeng.xednesday:Miércoles`,
        $localize`:@@primeng.thursday:Jueves`,
        $localize`:@@primeng.friday:Viernes`,
        $localize`:@@primeng.saturday:Sábado`,
      ],
      dayNamesShort: [
        $localize`:@@primeng.sun:Dom`,
        $localize`:@@primeng.mon:Lun`,
        $localize`:@@primeng.tue:Mar`,
        $localize`:@@primeng.wed:Mie`,
        $localize`:@@primeng.thu:Jue`,
        $localize`:@@primeng.fri:Vie`,
        $localize`:@@primeng.sat:Sáb`,
      ],
      dayNamesMin: [
        $localize`:@@primeng.su:Do`,
        $localize`:@@primeng.mo:Lu`,
        $localize`:@@primeng.tu:Ma`,
        $localize`:@@primeng.we:Mi`,
        $localize`:@@primeng.th:Ju`,
        $localize`:@@primeng.fr:Vi`,
        $localize`:@@primeng.sa:Sa`,
      ],
      monthNames: [
        $localize`:@@primeng.january:Enero`,
        $localize`:@@primeng.february:Febrero`,
        $localize`:@@primeng.march:Marzo`,
        $localize`:@@primeng.april:April`,
        $localize`:@@primeng.may:Mayo`,
        $localize`:@@primeng.june:Junio`,
        $localize`:@@primeng.july:Julio`,
        $localize`:@@primeng.august:Agosto`,
        $localize`:@@primeng.september:Septiembre`,
        $localize`:@@primeng.october:Octubre`,
        $localize`:@@primeng.november:Noviembre`,
        $localize`:@@primeng.december:Diciembre`,
      ],
      monthNamesShort: [
        $localize`:@@primeng.jan:Ene`,
        $localize`:@@primeng.feb:Feb`,
        $localize`:@@primeng.mar:Mar`,
        $localize`:@@primeng.apr:Abr`,
        $localize`:@@primeng.may:May`,
        $localize`:@@primeng.jun:Jun`,
        $localize`:@@primeng.jul:Jul`,
        $localize`:@@primeng.aug:Ago`,
        $localize`:@@primeng.sep:Sep`,
        $localize`:@@primeng.oct:Oct`,
        $localize`:@@primeng.nov:Nov`,
        $localize`:@@primeng.dec:Dic`,
      ],
      today: $localize`:@@primeng.today:Hoy`,
      weekHeader: $localize`:@@primeng.weekHeader:Se`,
      weak: $localize`:@@primeng.weak:Semana`,
      medium: $localize`:@@primeng.medium:Media`,
      strong: $localize`:@@primeng.strong:Fuerte`,
      passwordPrompt: $localize`:@@primeng.passwordPrompt:Introduzca una contraseña`,
    });
    if (!environment.production || environment.version.endsWith("-stg")) {
      this.version = environment.version;
    } else {
      this.version = "?";
      console.log(`${environment.app} ${environment.version}`);
    }


    // this.notificationService.notificationsNumber.subscribe((notif) => {
    // });

    this.getFilters();
    //this.spinner.show('layout-spinner');
    //this.getCountriesDb(); --->this request is moved to app.module.ts
    this.getTermsConditions();
    this.getLegalAdvice();
    this.getDataProtection();
    // this.changeFirstTimeEnterPage();
    this.showSearch = this.arrayUrl.includes(this.router["url"].split('?')[0]) ? true : false;
    console.log(this.showSearch);
    this.items = this.createBreadcrumbs(this.activatedRoute.root);
    this.home = { icon: "pi pi-home", routerLink: ["/home"], replaceUrl: true };
    this.breadcrumbService.setBreadcrumb(this.items);

    console.log("layout component", this.menuItems);
    this.activatedRoute.data.subscribe((data) => {
      console.log("SET LINKS", data["links"]);
      this.links = data["links"] as Link[];
    });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.showSearch = this.arrayUrl.includes(event["url"].split('?')[0]) ? true : false;
        this.items = this.createBreadcrumbs(this.activatedRoute.root);
        this.breadcrumbService.setBreadcrumb(this.items);
        this.tunningMenuItems();
      });

    this.isClhUser = this.globals.user.roles
      .map((x) => x.name)
      .includes(this.globals.clhRoleName);
    this.getChartererAndShipowner();
    this.setChargerOrShipOwner();
    this.cdRef.detectChanges();
    this.chooseUserService.chooseUserObservable.subscribe((user) => {
      this.setChargerOrShipOwner(true);
      this.cdRef.detectChanges();
    });

    this.getCatalogueDb();

    this.breadcrumbService.breadcrumb.subscribe((items) => {
      this.items = items;
      console.log("ITEMS", this.items);
    });
    this.cdRef.detectChanges();
  };

  setChargerOrShipOwner(isClh = false) {
    if (this.globals.currentCharger) {
      if (isClh) {
        this.currentEntity = { name: this.globals.currentCharger.name };
      }
      if (
        isClh ||
        this.globals.user.roles
          .map((x) => x.name)
          .some((r) => this.rolesToRequestOffer.includes(r))
      ) {
        this.showNewOfferButton = true;
      }
      this.isCharger = true;
      this.isShipowner = false;
      this.isChl = false;
    } else if (this.globals.currentShipOwner) {
      if (isClh) {
        this.currentEntity = {
          name: this.globals.currentShipOwner.charteringCompany,
        };
      }
      this.showNewOfferButton = false;
      this.isCharger = false;
      this.isShipowner = true;
      this.isChl = false;
    } else {
      this.currentEntity = null;
      this.showNewOfferButton = false;
      this.isCharger = false;
      this.isShipowner = false;
      this.isChl = true;
    }
  }

  getChartererAndShipowner() {
    const currentEntityString = localStorage.getItem("currentEntity");
    if (currentEntityString) {
      const currentEntity = JSON.parse(currentEntityString);
      if (currentEntity.hasOwnProperty("shipOwners")) {
        this.globals.currentCharger = currentEntity;
        this.currentEntity = { name: this.globals.currentCharger.name };
      } else {
        this.globals.currentShipOwner = currentEntity;
        this.currentEntity = {
          name: this.globals.currentShipOwner.charteringCompany,
        };
      }
    }
  }
  getCatalogueDb() {
    this.catalogueService.getPorts().subscribe({
      next: (catalogue) => {
        localStorage.setItem(
          "cacheExpirationDate",
          (new Date().getTime() + 86400000).toString()
        );
        this.setCatalogue(catalogue);
      },
      error: (err) => {
        console.error("Error loading catalogue: " + err);
        this.catalogueService.getPortsDb().subscribe({
          next: (catalogue) => {
            this.setCatalogue(catalogue);
          },
          error: (err) => {
            console.error("Error loading catalogue: " + err);
            this.catalogueService.getPortsDb();
            this.setCatalogue([]);
          },
        });
      },
    });
  }
  getTermsConditions() {
    this.termsConditionsService.getLast().subscribe({
      next: (item: TermsAndConditions) => {
        if (item) {
          this.globals.termsConditions = item;
        }
      },
      error: (err) => {
        console.error("Error loading TERMS: " + err);
      },
    });
  }
  getLegalAdvice() {
    this.globals.legalAdvice = Constants.LEGAL_ADVICE;
  }
  getDataProtection() {
    this.privacyPoliciesService.getLast().subscribe({
      next: (item: PrivacyPolicies) => {
        if (item) {
          this.globals.dataProtection = item;
        }
      },
      error: (err) => {
        console.error("Error loading Data Protection: " + err);
      },
    });
  }
  setCatalogue(catalogue) {
    this.globals.catalogue = catalogue.sort((a, b) => {
      return a.value > b.value ? 1 : -1;
    });
    this.loadedCatalogue = true;
    //this.spinner.hide('layout-spinner');
  }
  getCountriesDb() {
    //this.getCountriesES();
  }
  getCountriesES() {
    this.countryService.getCountriesES().subscribe({
      next: (countriesES: Country[]) => {
        const listCountriesES = [];
        countriesES.forEach((item) => {
          item = {
            id: item.id,
            name: item.name,
            nameEnglish: item.nameEnglish,
            languageCode: item.languageCode,
          };
          listCountriesES.push(item);
        });
        this.setCountries(listCountriesES);
      },
      error: (err) => {
        console.error("Error loading countriesES: " + err);
      },
    });
  }

  setCountries(countries: Country[]) {
    const countriesEs: Country[] = [];
    let countriesEn: Country[] = [];
    countries.forEach((country) => {
      countriesEs.push({ ...country });
      const countryEn = { ...country };
      countryEn.name = countryEn.nameEnglish;
      countriesEn.push(countryEn);
    });
    countries = countries.sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });
    countriesEn = countriesEn.sort((a, b) => {
      return a.nameEnglish > b.nameEnglish ? 1 : -1;
    });
    this.globals.countriesES = countries;
    this.globals.countriesEN = countriesEn;
    //  this.spinner.hide('layout-spinner');
  }
  override ngOnDestroy() {
    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  selectLang(lang) {
    const url = this.router.url.toString();

    window.location.href = window.location.href;
  }
  goToOffer() {
    this.router.navigateByUrl("firmOffer/cover/new");
    // if(!this.firstTimeEnterPage){
    //   this.firstTimeEnterPage=true;
    // }
  }
  search() {
    //this.spinner.show('layout-spinner');
    if (this.searchText != null && this.showSearch) {
      console.log(this.filters);
      this.offerFilter.searchString = this.searchText ? this.searchText : "";

      this.filters.forEach((item) => {
        if (item.code === "Active") {
          this.offerFilter.isActive = item.value;
        }
        if (item.code === "Unread") {
          this.offerFilter.isUnread = item.value;
        }
      });

      if (this.rangeDatesSearcher) {
        if (this.rangeDatesSearcher[0] != null) {
          this.rangeDatesSearcher[0].setHours(0, 0, 0);
          this.offerFilter.startDate = new Date(
            Date.UTC(
              this.rangeDatesSearcher[0].getFullYear(),
              this.rangeDatesSearcher[0].getMonth(),
              this.rangeDatesSearcher[0].getDate()
            )
          );
        }
        if (this.rangeDatesSearcher[1] != null) {
          this.rangeDatesSearcher[1].setHours(0, 0, 0);
          this.offerFilter.endDate = new Date(
            Date.UTC(
              this.rangeDatesSearcher[1].getFullYear(),
              this.rangeDatesSearcher[1].getMonth(),
              this.rangeDatesSearcher[1].getDate()
            )
          );
        }
      } else {
        this.offerFilter.startDate = null;
        this.offerFilter.endDate = null;
      }

      console.log(this.offerFilter);
      this.offerFilter.searchString = this.searchText;

      this.searcherService.updateSearch(this.offerFilter);
    }
  }
  sendDates() {
    if (
      this.rangeDatesSearcher &&
      this.rangeDatesSearcher.length === 2 &&
      this.rangeDatesSearcher.every((date) => date !== null)
    ) {
      this.search();
    }
  }
  clearSearcher() {
    if (this.showSearch) {
      //this.spinner.show('layout-spinner');
      this.searchText = "";
      this.offerFilter.searchString = "";
      this.searcherService.updateSearch(this.offerFilter);
    }
  }
  cleanChecks() {
    if (this.showSearch) {
      // this.spinner.show('layout-spinner');
      this.offerFilter.isActive = false;
      this.offerFilter.isUnread = false;
      this.searcherService.updateSearch(this.offerFilter);
    }
  }
  cleanAllSearcher() {
    if (this.showSearch) {
      // this.spinner.show('layout-spinner');
      this.searchText = "";
      this.rangeDatesSearcher = [];
      this.filters.forEach((filter) => {
        filter.value = false;
      });
      this.selectedFilters = [];
      this.offerFilter.isActive = false;
      this.offerFilter.isUnread = false;
      this.offerFilter.searchString = "";
      this.offerFilter.startDate = null;
      this.offerFilter.endDate = null;
      this.searcherService.updateSearch(this.offerFilter);
    }
  }
  getFilters() {
    const res = this.translate.get("FILTERS.SEARCHER");
    this.filters = [
      { label: res.ACTIVE_OFFER, value: false, code: "Active" },
      { label: res.UNREAD_MESSAGES, value: false, code: "Unread" },
    ];
  }
  updateFilterValue() {
    this.filters.forEach((filter) => {
      filter.value = this.selectedFilters.includes(filter);
    });
    console.log(this.filters);
    this.search();
  }
  override closeNavbarOnNavigation() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd),
        filter(() => {
          const queryParams = this.activatedRoute.snapshot.queryParams;
          return queryParams["hideMenu"] === undefined || queryParams["hideMenu"] === null;
        }),
        filter((event: any) => !event.url.includes('choose-user/null')))
      .subscribe(() => {
        this.navbar?.sidebarClose();
      });
  }
  // changeFirstTimeEnterPage() {
  //   console.log( localStorage.getItem('firstTimeEnterPage'))
  //    const local = localStorage.getItem('firstTimeEnterPage') ;
  //   if (local !== null ) {
  //       this.firstTimeEnterPage = true;
  //   } else{
  //       localStorage.setItem('firstTimeEnterPage', 'true');
  //   }
  //   console.log(this.firstTimeEnterPage)
  //   console.log( localStorage.getItem('firstTimeEnterPage'))
  // }
  // changeFirstTime(){
  //   this.firstTimeEnterPage = true;
  // }
  override calculateMenubarItems() {
    console.log(this.menubarItems);
    const currentLang = localStorage.getItem("locale");

    const f = this.languages.find((l) => l.key === currentLang);

    if (f) {
      this.selectedLanguage = { ...f };
    }

    const langs: any = {
      type: "dropdown",
      label: $localize`:@@common.language:Lenguaje`,
      selectedId: this.selectedLanguage?.key,
      command: (key: any) => {
        if (!this.selectedLanguage || key != this.selectedLanguage.key) {
          this.selectedLanguage = this.languages.find((l) => l.key === key);
          if (this.selectedLanguage) {
            localStorage.setItem("locale", this.selectedLanguage.key);
            location.reload();
          }
          console.log("SELECTED LANGUAGE", this.selectedLanguage);
        }
      },
      items: this.languages.map((l) => {
        return { id: l.key, label: l.translateKey };
      }),
    };
    const user: any = {
      icon: "pi pi-user",

      items: [],
    };
    if (this.isClhUser) {
      user.items.push({
        label: $localize`:@@common.profile:Perfil`,
        icon: "pi pi-user",
        command: (event: any) => {
          this.router.navigateByUrl("core/settings/profile");
        },
      });

    }
    user.items.push({
      label: $localize`:@@common.closesession:Cerrar Sesión`,
      icon: "pi pi-sign-out",
      command: (event: any) => {
        this.router.navigateByUrl("core/settings/profileLogOut");
      },
    });


    const notification: OveraMenuItem = {
      icon: "pi pi-bell",
      label: "",
      command: (event: any) => {
        this.router.navigateByUrl("core/notifications");
      },
      items: [],
      cssClass: 'notification-icon'
    };

    // const security: any = {
    //   icon: "pi pi-shield",
    //   label: $localize`:@@common.security:Seguridad`,
    //   items: [
    //     {
    //       label: $localize`:@@common.roles:Roles`,
    //       icon: "pi pi-list",
    //       command: (event: any) => {
    //         this.router.navigateByUrl("core/security/roles");
    //       },
    //     },
    //     {
    //       label: $localize`:@@common.users:Usuarios`,
    //       icon: "pi pi-users",
    //       command: (event: any) => {
    //         this.router.navigateByUrl("core/security/users");
    //       },
    //     },
    //   ],
    // };

    if (
      this.accountService.currentUser?.isAdmin ||
      this.accountService.currentUser?.isGlobalAdmin
    ) {
      user.items = [...user.items];
    }

    this.menubarItems = [langs, user, notification];

    const menuItemsFromService: any[] = [
      ...this.menuItems.filter((x) => x.isNavIcon || x.parentId),
    ];
    console.log("MENUITEMS", menuItemsFromService);
    menuItemsFromService.forEach((menu) => {
      menu.id = null;

      if (menu.uiUrl) {
        menu.command = (event: any) => {
          this.router.navigateByUrl(menu.uiUrl);
        };
      }
      if (menu.children?.length) {
        menu.children.forEach((c: any) => {
          if (c.showName) {
            c.label = c.name;
          }
          if (c.uiUrl) {
            c.command = (event: any) => {
              this.router.navigateByUrl(c.uiUrl);
            };
          }
        });
      }
      menu.items = menu.children;
      if (menu.parentId) {
        menu.position = "left";
      }
    });
    this.menubarItems = [...this.menubarItems, ...menuItemsFromService];
    this.notificationService.getCount(NOTIFICATION_TYPE.Internal).subscribe(count => {
      this.setNotificationsCircle(count);

    });
    this.notificationService.notificationsNumber.subscribe((notif) => {
      this.setNotificationsCircle(notif);
    });

  }

  override tunningMenuItems = () => {
    this.menuService.menuLoaded.subscribe((menu: any[] | null) => {
      if (menu == null) {
        this.authService.logout();
      }
      else {
        if (!this.isClhUser && (this.isCharger || this.isShipowner)) {
          //  this.navItems =
          var itemSelected = menu.find(
            (x) => x.name == "Charterer" || x.name == "Shipowner"
          );
          //this.menuItems = ConfigBase.menuItems.find(x => x.name == 'Charterer' || x.name == 'Shipowner').children;
          if (itemSelected) {
            this.navbar.currentMenuItem = itemSelected;

            // this.menuService.updateMainRoute(itemSelected);
          }
          console.log(this.menuItems);
        } else if (this.isClhUser && !this.isCharger && !this.isShipowner) {
          var itemSelected = menu.find((x) => x.name == "CLH");
          //this.menuItems = ConfigBase.menuItems.find(x => x.name == 'Charterer' || x.name == 'Shipowner').children;
          if (itemSelected) {
            //  this.menuService.updateMainRoute(itemSelected);
            //this.navbar.currentMenuItem = itemSelected;

          }
        } else if (this.isClhUser && (this.isCharger || this.isShipowner)) {
          //this.menuService.updateMainRoute(null);
        }
      }
    });
  };

  private createBreadcrumbs(
    route: ActivatedRoute,
    url: string = "",
    breadcrumbs: MenuItem[] = []
  ) {
    const children: ActivatedRoute[] = route.children;
    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url
        .map((segment) => segment.path)
        .join("/");
      if (routeURL !== "") {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data["breadcrumb"];
      if (label) {
        if (Array.isArray(label)) {
          label.forEach((l) => {
            const translatedLabel = this.translate.get(l.label);
            breadcrumbs.push({ label: translatedLabel, routerLink: [l.url] });
          });
        } else {
          const translatedLabel = this.translate.get(label);
          breadcrumbs.push({
            label: translatedLabel,
            routerLink: [url],
            replaceUrl: true,
          });
        }
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }
}
