<p-toast></p-toast>
<div class="containerNotesPrincipal">
  <ngx-spinner [fullScreen]="false" name="noteSpinner"></ngx-spinner>
  <!-- <div *ngIf="isLoading" class="divSpinner">
   
  </div> -->
  <div *ngIf="!readOnly">
    <div [formGroup]="form" (click)="toggleEditorHeader()" >
      <p-editor
        formControlName="noteDescription"
        [style]="{ height: '120px' }"
        styleClass="editorNote"
        [placeholder]="placeholderEditor"
      >
      </p-editor>
    </div>

    <div class="d-flex flex-row justify-content-end align-items-center">
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-save"
        *ngIf="form.get('noteDescription').value"
        (click)="saveNote()"
        [pTooltip]="'NOTE.SAVE'| translate"
        [tooltipPosition]="tooltipPositionButton"
      ></button>
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-times"
        [pTooltip]="'NOTE.CANCEL'| translate"
        [tooltipPosition]="tooltipPositionButton"
        *ngIf="form.get('noteDescription').value"
        (click)="cancelNote()"
        style="margin-left: 10px"
      ></button>
    </div>
  </div>
  <div *ngIf="listNotes?.length > 0" class="mt-3 overflowNotes">
    <h4 *ngIf="lisTitle">listTitle</h4>
    <div class="card flex justify-content-center">
      <ng-container *ngFor="let note of listNotes">
        <!-- add (click)="toggleShowButtons()" when add buttons edit and delete -->
        <p-card class="card-notes">
          <div class="custom-card-header">
            <span class="bold-text" *ngIf="note?.contactName">{{note.contactName}}</span>
            <span class="transparent-text" *ngIf="note?.createdDate">{{note.createdDate | date: 'dd/MM/yy HH:mm'}} : </span>
          </div>
          <div class="d-flex flex-row custom-card-content">
            <div>
              <p class="notes-description" [innerHTML]="note.description | safeHtml:true"></p>
            </div>
            <!-- <div class="d-flex flex-row justify-content-end align-items-center" *ngIf="showButtons && !readOnly">
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-pencil"
                [pTooltip]="'ACTION.EDIT'| translate"
                [tooltipPosition]="tooltipPositionButton"
                [tooltipStyleClass]="tooltipStyleClassButton"
                class="p-button-rounded editButtonRounded"
                (click)="editNote(note)"
              ></button>
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-trash"
                [pTooltip]="'ACTION.DELETE'| translate"
                [tooltipPosition]="tooltipPositionButton"
                [tooltipStyleClass]="tooltipStyleClassButton"
                class="p-button-rounded deleteButtonRounded"
                (click)="deleteNote(note)"
                style="margin-left: 15%"
              ></button>
            </div> -->
          </div>
        </p-card>
      </ng-container>
    </div>
  </div>
</div>
