import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AccountService } from "@overa/security";
import { NgxSpinnerService } from "ngx-spinner";
import { Note } from "src/app/models/note/note.model";
import { NotesService } from "src/app/services/notes.service";
import { CLHGlobals } from "src/app/utils/globals";
import { MessageService } from "primeng/api";
import { OveraMessagesService } from "@overa/components/info-dialog";

@Component({
  selector: "overa-notes",
  templateUrl: "./notes.component.html",
  styleUrls: ["./notes.component.scss"],
  providers: [MessageService],
})
export class NotesComponent implements OnInit, OnChanges {
  @Input() groupId?: string;
  @Input() entityId!: string;
  @Input() lisTitle: string = "";
  @Input() placeholderEditor: string = "";
  @Input() readOnly: boolean = false;
  @Input() tooltipPositionButton: string = "";
  @Input() tooltipStyleClassButton: string = "";
  listNotes: Note[] = [];
  form!: FormGroup;
  noteToSend: Note = new Note();
  showEditorHeader: boolean = false;
  showButtons: boolean = false;
  currentUrl: string = "";
  isLoading: boolean = false;
  @Output() sendIsLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    protected globals: CLHGlobals,
    private route: ActivatedRoute,
    public messageService: OveraMessagesService,
    private spinner: NgxSpinnerService,
    private notesService: NotesService,
    private accountService: AccountService,
    private fb: FormBuilder,
    public router: Router
  ) {}

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.initialForm();
    this.isLoading = true;
    this.spinner.show("noteSpinner");
    let subscriptionNotes;
    if (this.groupId) {
      subscriptionNotes = this.notesService.getNotesByEntityIdAndGroupId(
        this.entityId,
        this.groupId
      );
    } else {
      subscriptionNotes = this.notesService.getNotesByEntityId(this.entityId);
    }
    subscriptionNotes.subscribe({
      next: (listNotes) => {
        console.log("listaNotes", listNotes);
        this.listNotes = listNotes;
        this.listNotes.sort((a, b) =>
          a.createdDate || new Date() > (b.createdDate || new Date()) ? -1 : 1
        );
        this.spinner.hide("noteSpinner");
        this.isLoading = false;
      },
      error: (err) => {
        console.log("ERRORnotes", err);
        this.spinner.hide("noteSpinner");
        this.isLoading = false;
      },
    });
  }
  initialForm() {
    this.form = this.fb.group({
      noteDescription: new FormControl("", []),
    });
  }
  saveNote() {
    this.noteToSend.entityId = this.entityId;
    this.noteToSend.groupId = this.groupId ? this.groupId : "";
    this.noteToSend.contactName = this.globals.user.firstName
      ? this.globals.user.firstName
      : "";
    this.noteToSend.description = this.form.controls["noteDescription"].value;
    console.log(this.noteToSend);
    this.isLoading = true;
    this.spinner.show("noteSpinner");
    this.notesService.save(this.noteToSend).subscribe({
      next: (note) => {
        this.messageService.showToast("OK", "NOTE.UPDATE", "success", "");
        this.listNotes.push(note);
        this.listNotes.sort((a, b) =>
          a.createdDate || new Date() > (b.createdDate || new Date()) ? -1 : 1
        );
        this.form.controls["noteDescription"].setValue("");
        this.spinner.hide("noteSpinner");
        this.isLoading = false;
        this.showEditorHeader = false;
      },
      error: (err) => {
        console.log("ERRORSavenotes", err);
        this.spinner.hide("noteSpinner");
        this.isLoading = false;
      },
    });
    // this.sendNote.emit(this.noteHtml);
  }
  cancelNote() {
    this.form.get("noteDescription")?.setValue("");
    this.showEditorHeader = false;
  }
  toggleEditorHeader() {
    this.showEditorHeader = true;
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
  }
  // toggleShowButtons(){
  //   this.showButtons = !this.showButtons;
  // }
  // editNote(note:Note){}
  // deleteNote(note:Note){}
}
