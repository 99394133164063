import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GoogleMap } from '@angular/google-maps';
import { catchError, map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ClhConfig } from '../../../../clh-config';
import Feature from 'ol/Feature';
import Map from 'ol/Map';
import View from 'ol/View';
import { LineString, Point } from 'ol/geom';
import TileLayer from 'ol/layer/Tile';
import { fromLonLat } from 'ol/proj';
import OSM from 'ol/source/OSM';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { VesselService } from '../../../../services/vessel.service';
import { Stroke } from 'ol/style';
import { extend, Extent } from 'ol/extent';
import { SearcherService } from '../../../../services/searcher.service';
import { KanbanTask } from '../../../../models/kanban-task.model';
import { Coordinate } from 'ol/coordinate';
import { OveraMessagesService } from '@overa/components/info-dialog';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnChanges {
  @Input()
  markers: any[] = [];

  //@Input() long: number = 0;
  //@Input() lat: number = 0;
  long: number = 0;
  lat: number = 0;
  @Input() autoInit: boolean = true;
  @Input() zoom: number = 0;
  @Input() offerShipownerId: string;
  @Input() loadingPorts: any[];
  @Input() dischargingPorts: any[];
  @Input() allImos: any[];
  @Input() selectedKanban = null;
  data: any;
  view!: View;
  map?: Map;
  // markerLayer: VectorLayer<any>;
  markerVesselIcon = 'assets/icons/triangle.svg';
  markerStoppedIcon = 'assets/icons/circle.svg';
  markerLayers: VectorLayer<any>[] = [];
  routeLayers: VectorLayer<any>[] = [];
  @Output() onSelectKanban = new EventEmitter<string>();
  @Output() onVesselFound = new EventEmitter<any>();
  features: Feature[] = [];
  sources: VectorSource[] = [];
  portsList = [];
  portCalls = [];
  portsListLoading = [];
  portsListDischarging = [];
  constructor(
    protected vesselService: VesselService,
    protected messagesService: OveraMessagesService,
    private searcherService: SearcherService
  ) { }

  ngOnInit() {
    //if (this.autoInit) {
    //    setTimeout(() => {
    //        this.initMap();
    //    }, 500);
    //}
  }
  selectKanban(osId: string) {
    this.searcherService.selectKanban(osId);
  }
  cleanLayer() {
    this.markerLayers = [];
    this.sources = [];
    if (this.map)
      this.map.setLayers([
        new TileLayer({
          source: new OSM(),
        }),
      ]);
  }

  checkChanges(changes) {
    if (this.map) {
      if (changes.offerShipownerId?.currentValue) {
        this.cleanLayer();

        this.getAllVesselsInfo();

        this.getVesselInfoFromOfferShipowner(this.offerShipownerId);
      } else if (changes.markers?.currentValue?.length) {
        var coords: number[][] = [];

        this.markers.forEach((marker) => {
          this.addMarker(marker.position.lng, marker.position.lat, marker.options?.icon);
          coords.push([marker.position.lng, marker.position.lat]);
        });
        this.getVesselInfoFromOfferShipowner(this.offerShipownerId);
      } else if (changes.allImos?.currentValue?.length) {
        this.cleanLayer();
        this.getAllVesselsInfo();
        this.data = null;
      } else {
        this.data = null;
        //this.cleanLayer();
        //if (this.allImos?.length) {
        //    this.cleanLayer();
        //    this.getAllVesselsInfo();

        //}
        //else {
        //    /* this.cleanLayer();*/
        //}
      }
    }
  }
  ngOnChanges(changes) {
    if (!this.map) {
      if (this.autoInit) {
        setTimeout(() => {
          if (!this.map) {
            this.initMap();
            this.checkChanges(changes);
          } else {
            this.checkChanges(changes);
          }
        }, 500);
      }
    } else {
      this.checkChanges(changes);
    }
  }
  dateToUtc(date: Date) {
    //  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    return date;
  }
  getVesselInfoFromOfferShipowner(offerShipownerId) {
    this.vesselService.getInfo(offerShipownerId).subscribe({
      next: (info) => {
        console.log(info);
        if (info != null) {
          this.data = info;
          if (this.data.vessel) {
            var lastPort = this.data.vessel.voyage.lastPort + ' (' + this.data.vessel.voyage.locode + ' )';
            var ata = this.data.datesInfo.ata ? this.dateToUtc(new Date(this.data.datesInfo.ata)) : null;
            var atd = this.data.datesInfo.atd ? this.dateToUtc(new Date(this.data.datesInfo.atd)) : null;
            var eta = this.data.datesInfo.eta ? this.dateToUtc(new Date(this.data.datesInfo.eta)) : null;
            var etd = this.data.datesInfo.etd ? this.dateToUtc(new Date(this.data.datesInfo.etd)) : null;
            var destination = this.data.vessel.ais.destination + ' (' + this.data.vessel.ais.locode + ' )';
            var loadingPort = {
              port: lastPort,
              atd: atd,
              etd: etd,
              type: 'd',
            };
            var dischargingPort = {
              port: destination,
              ata: ata,
              eta: eta,
              type: 'a',
            };

            this.portsList = [dischargingPort, loadingPort];
            this.portCalls = this.data.portCalls.map((x) => x.portcall);
          }
          //var lat = this.data?.vessel?.ais?.latitude;
          //var long = this.data?.vessel?.ais?.longitude;
          var vessel = this.data?.vessel;
          this.onVesselFound.emit(info);
          if (vessel) {
            //this.addMarker(long, lat, this.markerVesselIcon);
            this.addRoutes(this.data);
            if (vessel?.ais?.speed > 0) {
              this.addMarker(vessel?.ais?.longitude, vessel?.ais?.latitude, this.markerVesselIcon, vessel.ais.imo, vessel.ais.course);
            } else {
              this.addMarker(vessel?.ais?.longitude, vessel?.ais?.latitude, this.markerStoppedIcon, vessel.ais.imo);
            }
            if (this.data?.vessel?.ais?.latitude) {
              const coordinates = fromLonLat([this.data.vessel?.ais.longitude, this.data.vessel?.ais.latitude]);

              // Configuramos el centro de la vista
              //this.map.getView().setCenter(coordinates);
            }
          }
          //Add to view markers to loading and discharginport
          if (this.markers?.length) {
            var coords: number[][] = [];
            this.markers.forEach((marker) => {
              this.addMarker(marker.position.lng, marker.position.lat, marker.options?.icon);
              coords.push([marker.position.lng, marker.position.lat]);
            });
          }
        } else {
          //Add to view markers to loading and discharginport
          if (this.markers?.length) {
            var coords: number[][] = [];
            this.markers.forEach((marker) => {
              this.addMarker(marker.position.lng, marker.position.lat, marker.options?.icon);
              coords.push([marker.position.lng, marker.position.lat]);
            });
            // this.fitToCoordinates(coords)
          }
        }
      },
      error: (err) => {
        console.error(err);
        this.data = null;

        if (err?.error?.developerMessage?.Message == 'Vessel not found') {
          this.messagesService.showToast('Error', 'Vessel not found', 'warning', '');
        }


        if (this.markers?.length) {
          var coords: number[][] = [];
          this.markers.forEach((marker) => {
            this.addMarker(marker.position.lng, marker.position.lat, marker.options?.icon);
            coords.push([marker.position.lng, marker.position.lat]);
          });
          // this.fitToCoordinates(coords)
        } else {
          console.warn(err);
          if (err?.error?.developerMessage?.Message == 'Imo must exist in OfferShipOwner') {
            // this.messagesService.showToast('Error', 'Imo must exist', 'warning', '');
          }
        }
      },
    });
  }
  setCenter() {
    //var features = [];
    //this.sources.forEach(source => {
    //    const feature = source.getFeatures();
    //    const point = feature.getGeometry();
    //});
  }
  getAllVesselsInfo() {
    if (this.allImos?.length) {
      this.vesselService.getVessels(this.allImos.map((x) => x.imo)).subscribe({
        next: (info) => {
          info.forEach((vessel) => {
            if (vessel.ais?.latitude) {
              if (vessel?.ais?.speed > 0) {
                this.addMarker(vessel?.ais?.longitude, vessel?.ais?.latitude, this.markerVesselIcon, vessel.ais.imo, vessel.ais.course);
              } else {
                this.addMarker(vessel?.ais?.longitude, vessel?.ais?.latitude, this.markerStoppedIcon, vessel.ais.imo);
              }
            }
          });
          //this.allImos = [];
        },
        error: (err) => {
          console.error(err);
        },
      });
    }
  }

  initMap() {
    this.view = new View({
      center: fromLonLat([this.long, this.lat]),
      zoom: this.zoom,
    });

    this.map = new Map({
      target: 'overamap',
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: this.view,
    });
    //TODO UNCOMMENT TO SELECT KANBAN ON BOAT CLICK
    let that = this;
    this.map.on('click', function (evt) {
      var feature = that.map.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
        return feature;
      });
      console.log(feature);
      if (feature) {
        if (feature['imo']) {
          var os = that.allImos.find((i) => i.imo == feature['imo']);
          if (os.id) {
            that.cleanLayer();
            that.getAllVesselsInfo();
            that.getVesselInfoFromOfferShipowner(os.id);
            //that.onSelectKanban.emit(os.id);
            that.selectKanban(os.id);
          } else {
          }
        }
      }
    });
  }
  degToRad(deg) {
    return (deg * Math.PI) / 180;
  }

  addMarker(long: number, lat: number, icon: string, imo = '', rotation: number = 0) {
    if (this.map) {
      const coordinates = fromLonLat([long, lat]);

      // Creamos el punto con las coordenadas
      const point = new Point(coordinates);

      // Creamos una feature con el punto
      const feature = new Feature({
        geometry: point,
      });
      feature['imo'] = imo;

      let imageIcon = new Icon({
        src: icon, // Ruta a tu archivo SVG o imagen
      });
      if (rotation > 0 && rotation <= 360) {
        var rotationInRadians = this.degToRad(rotation); // Convierte a radianes
        imageIcon = new Icon({
          src: this.markerVesselIcon, // Ruta a tu archivo SVG o imagen
          rotation: rotationInRadians,
          // Punto de anclaje del icono (opcional)
        });
      }

      let style = new Style({
        image: imageIcon,
      });

      feature.setStyle(style);
      // Creamos un VectorSource y a�adimos la feature
      const source = new VectorSource({
        features: [feature],
      });

      // Creamos un VectorLayer con el source
      let markerLayer = new VectorLayer({
        source: source,
      });
      if (imo) {
        this.sources.push(source);
      }
      this.markerLayers.push(markerLayer);
      // A�adimos la capa al mapa
      this.map.addLayer(markerLayer);
    }
  }
  private addRouteSegment(transformedCoordinates: Coordinate[], color: string, lineDash: number[] = [0, 0]) {
    // Creamos el LineString con las coordenadas
    const lineString = new LineString(transformedCoordinates);

    // Creamos una feature con el LineString
    const feature = new Feature({
      geometry: lineString,
    });

    // Creamos un estilo para la feature
    const style = new Style({
      stroke: new Stroke({
        color: color,
        width: 1.70,
        lineDash: lineDash,
      }),
    });
    feature.setStyle(style);

    // Creamos un VectorSource y añadimos la feature
    const source = new VectorSource({
      features: [feature],
    });

    // Creamos un VectorLayer con el source
    const layer = new VectorLayer({
      source: source,
    });

    // Añadimos la capa al mapa
    this.map.addLayer(layer);
  }
  addRoute(coordinates: any, color: string, lineDash?: number[]) {
    for (let i = 0; i < coordinates.length; i++) {
      for (let j = 0; j < coordinates[i].length - 1; j++) {
        // Convertimos las coordenadas a la proyección del mapa
        const transformedCoordinates = [coordinates[i][j], coordinates[i][j + 1]].map((coordinate) => fromLonLat(coordinate));
        this.addRouteSegment(transformedCoordinates, color, lineDash);
      }
    }
  }
  addRoutes(data: any) {
    const dischargingRouteColor: string = 'rgba(255, 0, 255, 0.35)';
    const loadingRouteColor: string = 'rgba(85, 107, 47, 0.75)';

    if (data.dischargingPortSplitRoute && data.dischargingPortSplitRoute.coordinates) {
      this.addRoute(data.dischargingPortSplitRoute.coordinates, dischargingRouteColor, [5, 5]);
    }
    if (data.loadingPortSplitRoute && data.loadingPortSplitRoute.coordinates) {
      this.addRoute(data.loadingPortSplitRoute.coordinates, loadingRouteColor);
    }
  }
  fitToCoordinates(coordinates: number[][]) {
    // Creamos un Extent vac�o
    let extent: Extent = [Infinity, Infinity, -Infinity, -Infinity];

    // Ajustamos las longitudes para que todas est�n en el mismo "lado" del antimeridiano
    const adjustedCoordinates = coordinates.map(([lon, lat]) => {
      return [lon < 0 ? lon + 360 : lon, lat];
    });

    // Extendemos el Extent para cada coordenada
    adjustedCoordinates.forEach((coordinate) => {
      const transformedCoordinate = fromLonLat(coordinate);
      extent = extend(extent, transformedCoordinate);
    });

    // Ajustamos la vista para que se ajuste al Extent
    this.map.getView().fit(extent, { padding: [50, 50, 50, 50] });
  }

  destroyMap() {
    if (this.map) {
      this.map.setTarget(undefined);
      this.map = undefined;
    }
  }
}
