<p-confirmDialog
  styleClass="containerConfirmDialog"
  acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"
></p-confirmDialog>
<p-toast></p-toast>
<div class="containerTabviewCharger">
  <ngx-spinner [fullScreen]="false" name="clauseSpinner"></ngx-spinner>
  <div class="m-auto">
    <form [formGroup]="formClauses">
      <div class="table-responsive">
        <p-table
          [columns]="columns"
          [value]="listClauses"
          styleClass="p-datatable-striped listTableClauses tables-maintenance"
          responsiveLayout="scroll"
          [responsive]="true"
          [autoLayout]="true"
          [paginator]="true"
          sortField="Id"
          [sortOrder]="-1"
          paginatorDropdownAppendTo="body"
          [rowsPerPageOptions]="[30,50,100]"
          [showCurrentPageReport]="true"
          [rows]="30"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
          #tableClauses
          [globalFilterFields]="['description']"
        >
          <ng-template pTemplate="caption">
            <div class="divSearch">
              <div class="d-flex flex-row">
                <div class="w-100">
                  <span
                    class="p-input-icon-right"
                    style="width: 100%"
                    [escape]="false"
                    [pTooltip]="'TOOLTIP.SEARCH_CLAUSESLIST'| translate"
                    tooltipPosition="bottom"
                    tooltipStyleClass="tooltipCLH"
                    [showDelay]="1000"
                    [hideDelay]="1000"
                  >
                    <i class="pi pi-search" style="top: 35%"></i>
                    <input
                      style="margin-right: 1em"
                      class="w-100"
                      pInputText
                      type="text"
                      (input)="tableClauses.filterGlobal($any($event.target).value,'contains')"
                      [escape]="false"
                      [pTooltip]="'TOOLTIP.SEARCH_CLAUSESLIST'| translate"
                      tooltipPosition="bottom"
                      tooltipStyleClass="tooltipCLH"
                      [showDelay]="1000"
                      [hideDelay]="1000"
                      placeholder="{{'CLAUSES_MAINTENANCE.PLACEHOLDER_SEARCHER'|translate}}"
                    />
                  </span>
                </div>
                <div style="margin-left: 3em">
                  <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-plus"
                    class="p-button-rounded addButtonRounded"
                    [pTooltip]="'ACTION.ADD'| translate"
                    tooltipPosition="bottom"
                    tooltipStyleClass="tooltipCLH"
                    (click)="addRow()"
                    *ngIf="!isNewClause && !isEdittingClause && !readonly"
                  ></button>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns"><b>{{col.id | translate}}</b></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-clause>
            <ng-container *ngIf="clause.isNew else other">
              <tr *ngIf="isNewClause">
                <td>
                  <clh-textarea
                    [form]="formClauses"
                    [nameControl]="'newClauseTextarea'"
                    [style]="'width:100%'"
                    [errorLabel]="'MESSAGES.ERROR.CLAUSES_NOT_NULL'"
                    [placeholder]="'CLAUSES_MAINTENANCE.PLACEHOLDER'"
                    [idTextarea]="'textAreaNewClauseMaintenance'"
                    [classTextarea]="'clhTextarea'"
                    [required]="true"
                    [showLabelFloat]="false"
                  ></clh-textarea>
                </td>
                <td style="width: 15%">
                  <div class="d-flex flex-row justify-content-start align-items-center">
                    <button
                      pButton
                      pRipple
                      type="button"
                      icon="pi pi-save"
                      class="p-button-rounded saveButtonRounded"
                      *ngIf="formClauses.get('newClauseTextarea').value && !formClauses.get('newClauseTextarea').hasError('required')"
                      (click)="saveClause()"
                      [pTooltip]="'ACTION.SAVE'| translate"
                      tooltipPosition="bottom"
                      tooltipStyleClass="tooltipCLH"
                      style="margin-left: 15%"
                    ></button>
                    <button
                      pButton
                      pRipple
                      type="button"
                      icon="pi pi-times"
                      [pTooltip]="'ACTION.CANCEL'| translate"
                      tooltipPosition="bottom"
                      tooltipStyleClass="tooltipCLH"
                      class="p-button-rounded cancelButtonRounded"
                      (click)="cancelEditClause()"
                      style="margin-left: 15%"
                    ></button>
                  </div>
                </td>
              </tr>
            </ng-container>
            <ng-template #other>
              <tr>
                <ng-container *ngFor="let col of columns">
                  <td class="{{col.sort}}" *ngIf="col.sort === 'description' ">
                    <clh-textarea
                      [form]="formClauses"
                      [nameControl]="clause.id+'Textarea'"
                      [errorLabel]="'MESSAGES.ERROR.CLAUSES_NOT_NULL'"
                      [idTextarea]="clause.id"
                      [placeholder]=""
                      [readOnly]="!clause.isEdit"
                      [classTextarea]="'textAreaClauseEdit clhTextarea'"
                      [required]="true"
                      [style]="'width:100%'"
                      [showLabelFloat]="false"
                    ></clh-textarea>
                  </td>
                  <td class="{{col.sort}}" *ngIf="col.sort === 'modify' " style="width: 15%">
                    <div class="d-flex flex-row justify-content-start align-items-center" *ngIf="!isNewClause && !isEdittingClause">
                      <button
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-pencil"
                        class="p-button-rounded editButtonRounded"
                        (click)="editClause(clause)"
                      ></button>
                      <button
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-trash"
                        [pTooltip]="'ACTION.DELETE'| translate"
                        tooltipPosition="bottom"
                        tooltipStyleClass="tooltipCLH"
                        class="p-button-rounded deleteButtonRounded"
                        (click)="deleteClause(clause)"
                        style="margin-left: 15%"
                      ></button>
                    </div>
                    <div class="d-flex flex-row justify-content-start align-items-center" *ngIf="!isNewClause && isEdittingClause && clause.isEdit">
                      <button
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-save"
                        class="p-button-rounded saveButtonRounded"
                        *ngIf="clause.description && !formClauses.get(clause.nameControl).hasError('required')"
                        (click)="saveClause(clause)"
                        [pTooltip]="'ACTION.SAVE'| translate"
                        tooltipPosition="bottom"
                        tooltipStyleClass="tooltipCLH"
                      ></button>
                      <button
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-times"
                        [pTooltip]="'ACTION.CANCEL'| translate"
                        tooltipPosition="bottom"
                        tooltipStyleClass="tooltipCLH"
                        class="p-button-rounded cancelButtonRounded"
                        (click)="cancelEditClause(clause)"
                        style="margin-left: 15%"
                      ></button>
                    </div>
                  </td>
                </ng-container>
              </tr>
            </ng-template>
          </ng-template>
        </p-table>
      </div>
    </form>
  </div>
</div>
