import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
  ValidationErrors,
  AbstractControl,
  FormBuilder,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AccountService } from "@overa/security";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";
import { Clause } from "src/app/models/clause/clause.model";
import { CatalogueType } from "src/app/models/enum/catalogue-type";
import { FreightTargetCurrency } from "src/app/models/enum/freight-target-currency.model";
import { DemurrageType } from "src/app/models/enum/demurrage-type.model";
import { FreightTargetType } from "src/app/models/enum/freight-target-type.model";
import { QuantityType } from "src/app/models/enum/quantity-type.model";
import { NegotiationOfferShipOwner } from "src/app/models/offers/negotiation-offer-shipowner.model";
import { OfferService } from "src/app/services/offer.service";
import { CLHGlobals } from "src/app/utils/globals";
import { DatePipe } from "@angular/common";
import { ClauseNegotiation } from "src/app/models/clause/clause-negotiation.model";
import { NegotiationFieldState } from "src/app/models/offers/negotiation-field-state.model";
import { UserType } from "src/app/models/enum/user-type.model";
import { MailTemplateTypes } from "src/app/models/enum/mail-template-types";
import { MailDTO } from "src/app/models/mail-templates/mail-dto.model";
import { AddresseeType } from "src/app/models/enum/addresseeType.model";
import { ClhValidators } from "src/app/utils/validators";
import { NegotiationShipownerClausesValidation } from "src/app/models/clause/negotiation-shipowner-clauses-validation.model";
import { OfferStateDto } from "src/app/models/offers/offer-state-dto.model";

import { OfferState } from "src/app/models/enum/offer-state.model";
import { StateOfferShipOwner } from "src/app/models/enum/state-offer-shipowner.model";
import { OfferShipOwnerState } from "src/app/models/offers/offer-shipowner-state.model";
import { ShipOwnerContactService } from "src/app/services/shipowner-contact.service";
import { ChatParticipant } from "src/app/models/chat/chatParticipant.model";
import { ChatGroup } from "src/app/models/chat/chatGroup.model";
import { ChatHelper } from "src/app/utils/chat-helper.utils";
import { Observable, firstValueFrom, forkJoin, of, switchMap } from "rxjs";
import { ChatMessage } from "src/app/models/chat/chatMessage.model";
import { StorageService } from "@overa/storage";
import { QueryParams } from "src/app/models/query-params.model";
import { ViewType } from "src/app/models/enum/view-type.model";
import { NegotiationClauses } from "src/app/models/offers/negotiation-clauses.model";
import { ComponentCanDeactivate } from "../../services/canDeactivateGuard.service";
import { PermissionsService } from "../../services/permissions.service";
import { OfferDecline } from "src/app/models/offers/offer-decline.model";
import { OfferShipOwner } from "src/app/models/offers/offer-shipowner.model";
import { Offer } from "src/app/models/offers/offer.model";
import { DocumentService } from "../../services/document.service";
import { FileElement } from "../../models/fileelement.model";
import { BreadcrumbService } from "src/app/services/breadcrumb.service";
import { Breadcrumb } from "primeng/breadcrumb";
import { Constants } from "src/app/utils/constants";
import { Utils } from "src/app/utils/utils";
import { OveraMessagesService } from "@overa/components/info-dialog";
import { NgxTranslateService } from "@overa/shared";
import { mapTemplate } from "src/app/utils/template-helper"; import { MailTemplateService } from "src/app/services/mail-template.service";
import { MessageTemplate, MessageTemplateService } from "@overa/notification";

@Component({
  selector: "app-offer-firm-binding",
  templateUrl: "./offer-firm-binding.component.html",
  styleUrls: ["./offer-firm-binding.component.scss"],
  providers: [MessageService, ConfirmationService, DatePipe],
})

//REMEMBER there is no email  acknowledgment of receipt for loaded or clhCharger indicated by CLH 1/6/23
export class OfferFirmBindingComponent
  implements OnInit, AfterContentChecked, ComponentCanDeactivate {
  offerBinding: NegotiationOfferShipOwner;
  offerBindingInitial: NegotiationOfferShipOwner;
  showTable: boolean = false;
  idCharger: string;
  nameContactCharger: string;
  idShipowner: string;
  nameContactShipowner: string;
  listClauses: Clause[] = []; // lista de clausulas iniciales/predeterminadas del cargador
  // listClausesSend: ClauseNegotiation[] = [];
  showTextArea: boolean = false;
  textAreaBinding: string = "";
  sendBinding: boolean = false;
  isreadonly: boolean = false; // variable para poner en readonly los textarea de las TEXTAREA CLAUSES SHIPOWNER y TEXTAREA CLAUSES SHIPOWNER

  coins: string[] = [];
  selectedCoin!: string;
  fletes: string[] = [];
  demurrageTypes: string[] = [];
  quantityTypes: string[] = [];
  selectedFlete!: string;
  today: Date = new Date();
  form!: FormGroup;
  DEFAULT_QUANTITYTYPE = 0;
  DEFAULT_TARGETTYPE = 0;
  DEFAULT_TARGETCURRENCY = 0;
  ports: string[] = [];
  portsD: string[] = [];
  selectedLoadingPorts: any[] = [];
  selectedDischargingPorts: any[] = [];
  negotiationOfferShipOwner!: NegotiationOfferShipOwner;
  templateName: string = "";
  sendMailToUser: boolean = false;
  listClauseCLH: ClauseNegotiation[] = [];
  listClauseCLHSend: string[] = [];
  isreadonlyCLH: boolean = true;
  countClauses: any;
  idOfferShipOwner!: string;
  allPorts: string[] = [];
  checked: boolean = false;
  expirationOffer: boolean = false; //variable para identificar si la oferta tiene expirationDate
  today6Hours: Date = new Date(); //variable para utilizar si hay que poner restriccion a la expirationDate

  isLoading: boolean = false;
  isNegotiation: boolean = false; //CUANDO ES NEGOCIACION TANTO PARA ARMADOR COMO CARGADOR
  isRecap: boolean = false; //variable de prueba
  withoutBorderInput: boolean = false; // Para poner el borde negro en el input
  showDivClauses: boolean = true; // Para mostrar el div de los botones clauses en binding y el button pi-file
  isClhRevision: boolean = false; //variable para identificar si es CLH cuando le toca añadir las clausulas del armador
  isClh: boolean = false; //variable para identificar si es CLH
  isShipownerBinding: boolean = false; //variable para identificar si es Shipowner
  showCheckInTable1To4: boolean = false; //variable para mostrar el check in los campos fijos de la tabla
  isCounter0shipOwner: boolean = false; //variable para diferenciar en el armador si el primer paso
  isFirstStepCharterer: boolean = false; //variable para diferenciar en el cargador si el primer paso
  sendMailResponse: boolean = false; //variable para el envio de acuse de recibo de email
  isOperative: boolean = false; //variable para identificar si vista en Operativa

  listchargerClauses: ClauseNegotiation[] = []; //lista de clausulas del cargador
  listshipOwnerClauses: ClauseNegotiation[] = []; //lista de clausulas del armador
  isApprovedOfferToSend: boolean = false; //variable para identificar si todos los campos estan aceptados antes de enviar el Recap
  isApprovedOfferChangeToRecap: boolean = false; //variable para identificar si todos los campos estan aceptados antes de enviar el Recap
  isConfirmed: boolean = false;
  isConfirmedRecap: boolean = false;
  offerFirmBindingIsReadOnly: boolean = false; //variable para poner la vista en READONLY
  contentDefaultClause: boolean = false; //variable para identificar si hay alguna clausula predeterminada en la lista
  contentDuplicateClause: boolean = false; //variable para identificar si hay alguna clausula de las duplicadas en la lista
  //Identificadores de vistas
  isViewOperative: boolean = false;
  isViewRecap: boolean = false;
  isViewNegotation: boolean = false;
  isViewNegotationAfterRecap: boolean = false; //para url en kanban ver negociacion con lineas de historico despues de confirmar el RECAP
  fieldView = { operative: "operative", negotation: "negotation" };
  readonly QuantityType = QuantityType;
  readonly FreightTargetCurrency = FreightTargetCurrency;
  readonly FreightTargetType = FreightTargetType;
  readonly DemurrageType = DemurrageType;
  readonly NegotiationFieldState = NegotiationFieldState;
  readonly arrValidator = this.validators.portsValidator;
  readonly StateOfferShipOwner = StateOfferShipOwner;
  readonly UserType = UserType;

  approvedStatus = [NegotiationFieldState.CONFIRMED];
  confirmedStatus = [NegotiationFieldState.CONFIRMED];
  approvedStatusForRecap = [NegotiationFieldState.CONFIRMED];
  statusRecap = [
    StateOfferShipOwner.RECAP_CHARTERER_PENDING,
    StateOfferShipOwner.RECAP_SHIPOWNER_PENDING,
  ];
  statusOperative = [StateOfferShipOwner.RECAP_CONFIRMED];
  statusReadOnly = [
    StateOfferShipOwner.COMPLETED,
    StateOfferShipOwner.COMPLETED_CHARTERER_PENDING,
    StateOfferShipOwner.COMPLETED_SHIPOWNER_PENDING,
    StateOfferShipOwner.CANCELLED,
    StateOfferShipOwner.REFUSED,
  ];
  statusRecapButton = [
    StateOfferShipOwner.RECAP,
    StateOfferShipOwner.RECAP_CHARTERER_PENDING,
    StateOfferShipOwner.RECAP_SHIPOWNER_PENDING,
  ];
  fieldQueryParams = { view: "view" };
  query: QueryParams = new QueryParams();
  chatValues: Array<{
    offerId?: string;
    offerShipOwnerId?: string;
    currentParticipant: ChatParticipant;
    group: ChatGroup;
    windowName: string;
    historicMessages: ChatMessage[];
    unReadMessages: number;
  }> = [];

  storageId = "";
  hasUnsavedChanges: boolean = false;
  noConfirmationServiceToExit: boolean = false;
  duplicateClausesAnotherOffer?: NegotiationClauses;
  isCLHRepresentingCharger: boolean = false; // variable para identificar cuando CLH se loguea como cargador
  isCLHRepresentingShipowner: boolean = false; // variable para identificar cuando CLH se loguea como armador
  changeQuantity: boolean = false;
  isConfirmRecapToDoc: boolean = false;
  breadcrumb: MenuItem[] = [];
  breadcrumbNegotiation: string = "";
  breadcrumbRecap: string = "";
  havePermissions: boolean = true;
  mailTemplateService = inject(MailTemplateService);
  allMethodsCompleted: boolean = false;
  @ViewChild("breadcrumb", { static: false }) breadcrumbOther?: ElementRef;
  template: MessageTemplate;
  messageTemplateService = inject(MessageTemplateService);
  mailTemplates: MessageTemplate[];
  //TODO borrar en savefield lo comentado
  // @ViewChild('buttonsDemurrage') buttonsDemurrage;
  constructor(
    private globals: CLHGlobals,
    private route: ActivatedRoute,
    public messageService: OveraMessagesService,
    private translate: NgxTranslateService,
    private offerService: OfferService,
    private spinner: NgxSpinnerService,
    private confirmationService: ConfirmationService,
    private datePipe: DatePipe,
    public router: Router,
    public accountService: AccountService,
    private validators: ClhValidators,
    private fb: FormBuilder,
    private elementRef: ElementRef,
    private shipownerContactService: ShipOwnerContactService,
    private chatHelper: ChatHelper,
    private permissionsService: PermissionsService,
    private documentService: DocumentService,
    private storageService: StorageService,
    private cd: ChangeDetectorRef,
    private breadcrumbService: BreadcrumbService,
    private activatedRoute: ActivatedRoute,
    private utils: Utils
  ) {
    this.storageId = this.globals.storageId;
    this.coins = Object.keys(FreightTargetCurrency).filter(
      (x) => !(parseInt(x) >= 0)
    );
    this.fletes = Object.keys(FreightTargetType).filter(
      (x) => !(parseInt(x) >= 0)
    );
    this.demurrageTypes = Object.keys(DemurrageType).filter(
      (x) => !(parseInt(x) >= 0)
    );
    this.quantityTypes = Object.keys(QuantityType).filter(
      (x) => !(parseInt(x) >= 0)
    );
  }
  //TODO diferenciar endpoint negotiation o recap segón el estado de la offer / offershipowner
  ngOnInit() {
    const a = this.translate.get("BREADCRUMB");
    this.breadcrumbNegotiation = a.NEGOTIATION;
    this.breadcrumbRecap = a.RECAP;

    this.breadcrumbService.getBreadcrumb().subscribe((items) => {
      this.breadcrumb = items;
    });
    // console.log('BREADCRUMB', this.breadcrumb);
    this.initializeVariables();
    if (!this.permissionsService.hasPermissionToEdit(this.activatedRoute)) {
      this.havePermissions = false;
    }
    //  this.getTemplateDecline();
    console.log(this.route.snapshot.params, this.globals);
    this.idOfferShipOwner = this.route.snapshot.params?.["id"] || "";
    this.spinner.show();
    this.route.queryParams.subscribe({
      next: (queryParams) => {
        console.log("QUERYPARAMS", queryParams);
        if (queryParams) {
          console.log(queryParams);

          this.query.view = queryParams[this.fieldQueryParams.view]
            ? queryParams[this.fieldQueryParams.view]
            : "";
          console.log(this.query);
          console.log(typeof this.query.view);
          if (this.query.view) {
            this.isViewNegotation =
              ViewType[this.query.view] === ViewType[ViewType.NEGOTIATION]
                ? true
                : false;
            //if (ViewType[this.query.view] === ViewType[ViewType.NEGOTIATION] &&
            //    this.statusOperative.includes(this.offerBinding.state)) {
            //    this.isViewNegotationAfterRecap = true;
            //}
            if (ViewType[this.query.view] === ViewType[ViewType.RECAP]) {
              this.isViewRecap = true;
            } else if (
              ViewType[this.query.view] === ViewType[ViewType.OPERATIVE]
            ) {
              this.isViewOperative = true;
            }
          }
          // console.log('vistas', this.isViewNegotationAfterRecap, this.isViewRecap, this.isViewOperative,this.isViewNegotation);
        }

        if (this.idOfferShipOwner) {
          console.log("id", this.idOfferShipOwner);

          if (
            !this.globals.currentCharger &&
            !this.globals.currentShipOwner &&
            this.accountService.currentUser.roles
              .map((x) => x.name)
              .includes("CLH Admin")
          ) {
            this.isClh = true;
          }
          //
          if (
            this.globals.currentCharger &&
            this.accountService.currentUser.roles
              .map((x) => x.name)
              .includes("CLH Admin")
          ) {
            this.isCLHRepresentingCharger = true;
          }
          if (
            this.globals.currentShipOwner &&
            this.accountService.currentUser.roles
              .map((x) => x.name)
              .includes("CLH Admin")
          ) {
            this.isCLHRepresentingShipowner = true;
          }
          console.log(
            "RepresentacionCLH C S",
            this.isCLHRepresentingCharger,
            this.isCLHRepresentingShipowner
          );
          // define endpoint according to the view to optimize performance
          let subscription;
          if (this.isViewRecap || this.isViewOperative) {
            console.log("subscription recap");
            subscription = this.offerService.getShipOwnerRecap(
              this.idOfferShipOwner
            );
          } else {
            subscription = this.offerService.getShipOwnerNegotiation(
              this.idOfferShipOwner
            );
          }

          subscription.subscribe({
            next: (value) => {
              console.log("VALUE OFFERFIRM BINDING", value);
              this.offerBinding = value;
              this.checkPermissions();
              //TODO REVISAR CON BPM PARA EL RECAP

              //if (!this.isViewRecap && !this.isViewOperative) {
              //    if (this.offerBinding.state && this.query.statusOffershipowner) {
              //        if (StateOfferShipOwner[this.offerBinding.state] != StateOfferShipOwner[this.query.statusOffershipowner]) {
              //          console.log('HOME');
              //          this.router.navigate(['home']);
              //        }
              //  }
              //}
              if (
                ViewType[this.query.view] === ViewType[ViewType.NEGOTIATION] &&
                this.statusOperative.includes(this.offerBinding.state)
              ) {
                this.isViewNegotationAfterRecap = true;
              }

              this.setChatOptions(this.offerBinding);
              this.offerBinding.quantityFieldState =
                this.offerBinding.quantityStowageComodityState;
              if (this.offerBinding?.initialOffer) {
                if (this.offerBinding.initialOffer?.freight) {
                  if (
                    this.offerBinding.initialOffer.freight == " USD LPSM" ||
                    this.offerBinding.initialOffer.freight == " EUR LPSM" ||
                    this.offerBinding.initialOffer.freight == " USD WM" ||
                    this.offerBinding.initialOffer.freight == " EUR WM"
                  ) {
                    this.offerBinding.initialOffer.freight = "";
                  }
                }
              }
              this.offerBindingInitial = { ...value };
              this.offerBindingInitial.quantityFieldState =
                this.offerBindingInitial.quantityStowageComodityState;
              this.offerBindingInitial.chargerClauses = [
                ...value.chargerClauses,
              ];
              this.offerBindingInitial.shipOwnerClauses = [
                ...value.shipOwnerClauses,
              ];
              this.offerBinding.currentUserType = this.globals.currentCharger
                ? UserType.CHARGER
                : UserType.SHIPOWNER;
              this.offerBinding.quantityFieldHistories = [];

              //EXPIRATION DATE
              if (this.offerBinding.expirationDate) {
                if (this.offerBinding.expirationDate < this.today) {
                  this.expirationOffer = true;
                }
              }
              //ASSIGN VARIABLES ACCORDING TO THE USER
              this.changesAccordingToUser();
              this.initialForm();
              //ASSIGN CHANGES TO VARIABLES ACCORDING TO VIEW TYPE
              this.assignChangesToVariablesToViewType();

              // console.log('offerBinding -listCharger -listShipowner', this.offerBinding, this.listchargerClauses, this.listshipOwnerClauses);

              //SHIFT
              this.checkIsReadOnlyViewOfferFirm();
              // console.log('READONLY', this.offerFirmBindingIsReadOnly);

              //ORDER PORTS AND FILTER
              this.orderAndFilterPorts();
              if (
                this.isViewNegotation ||
                this.isViewNegotationAfterRecap ||
                this.isFirstStepCharterer ||
                this.isCounter0shipOwner
              ) {
                // ASSIGN VARIABLES FOR THE HISTORY LINES
                this.setMultipleChanges(this.offerBinding);
              }
              if (this.isRecap || this.isViewRecap) {
                this.breadcrumb[0].label = this.breadcrumbRecap;
                this.breadcrumbService.setBreadcrumb(this.breadcrumb);
              }
              if (this.isNegotiation || this.isViewNegotationAfterRecap) {
                if (!this.isCounter0shipOwner) {
                  this.breadcrumb[0].label = this.breadcrumbNegotiation;
                  this.breadcrumbService.setBreadcrumb(this.breadcrumb);
                }
              }
              this.setForm();
              this.spinner.hide();
              this.countClauses = this.listchargerClauses.length;
              console.log("THIS", this);
            },
            error: (err) => {
              console.log("errorBinding", err);
              this.spinner.hide();
            },
          });
        }
      },

      error: (err) => {
        console.log("Error en queryParams", err);
        this.spinner.hide();
      },
    });
    console.log(this);
    this.showTable = true;
  }
  initializeVariables() {
    //Para la vista binding cargador la primera vez: negotiation y recap a false
    //para la vista binding armador primera vez:negotiation a true y isCounter0shipOwner a true
    //Para la vista binding armador o cargador que no sea la primera vez: negotiation true
    //Counter suma siempre cuando envia armador
    this.ports = this.globals.catalogue
      .filter((x) => x.type == CatalogueType.PORT)
      .map((x) => x.value);
    this.portsD = [...this.ports];
    this.allPorts = [...this.ports];
    this.isNegotiation = false;
    this.isRecap = false;
    this.isClhRevision = false;
    this.isShipownerBinding = false;
    this.showTextArea = false;
    this.sendBinding = false;
    this.isreadonly = false;
    this.showTable = false;
    this.showDivClauses = false;
    const hours = this.today6Hours.getHours();
    this.today6Hours.setHours(hours + 6);
    this.havePermissions = true;
  }
  setChatOptions(offerBinding: NegotiationOfferShipOwner) {
    const isClh = this.accountService.currentUser.roles
      .map((x) => x.name)
      .includes("CLH Admin");
    this.chatHelper.getCurrentParticipant(this.globals.user).subscribe({
      next: (participant) => {
        if (this.globals.currentCharger) {
          // En esta pantall no deberia de poder entrar un cargador, ya que a cargador va a firmOffer
          //TODO: comprobar si borrar, clh tampoco tiene acceso a esta pantalla, va desde firmoffer
          this.allMethodsCompleted = false;
          const clhChargerSubscriptions = this.chatHelper
            .getChatGroupChargerCLH(offerBinding.offerId)
            .pipe(
              switchMap((group) => {
                return forkJoin({
                  windowName: isClh ? offerBinding.chargerName || "" : "CLH",
                  currentParticipant: of(participant),
                  group: of(group),
                  historicMessages: this.chatHelper.getMessageHistory(group.id),
                });
              })
            );
          clhChargerSubscriptions.subscribe((res) => {
            const obj = {
              offerId: offerBinding.offerId,
              currentParticipant: res.currentParticipant,
              windowName: "CLH",
              group: res.group,
              historicMessages: res.historicMessages.map((s) =>
                this.chatHelper.setReplyBasedOnCurrentUser(s)
              ),
              unReadMessages: res.historicMessages
                .map((s) => this.chatHelper.setReplyBasedOnCurrentUser(s))
                .filter(
                  (s) => s.participantId != res.currentParticipant.id && !s.seen
                ).length,
            };
            this.chatValues.push(obj);
          });

          const shipOwnerChargerSubscriptions = this.chatHelper
            .getChatGroup(offerBinding.offerShipOwnerId)
            .pipe(
              switchMap((group) => {
                return forkJoin({
                  windowName: offerBinding.shipOwnerName || "",
                  offerShipownerId: offerBinding.offerShipOwnerId,
                  currentParticipant: of(participant),
                  group: of(group),
                  historicMessages: this.chatHelper.getMessageHistory(group.id),
                });
              })
            );
          shipOwnerChargerSubscriptions.subscribe(
            ({ windowName, currentParticipant, group, historicMessages }) => {
              if (
                windowName &&
                currentParticipant &&
                group &&
                historicMessages
              ) {
                const obj = {
                  offerShipOwnerId: offerBinding.offerShipOwnerId,
                  currentParticipant: currentParticipant,
                  windowName: offerBinding.shipOwnerName || "",
                  group: group,
                  historicMessages: historicMessages.map((s) =>
                    this.chatHelper.setReplyBasedOnCurrentUser(s)
                  ),
                  unReadMessages: historicMessages
                    .map((s) => this.chatHelper.setReplyBasedOnCurrentUser(s))
                    .filter(
                      (s) => s.participantId != currentParticipant.id && !s.seen
                    ).length,
                };
                this.chatValues.push(obj);
                this.allMethodsCompleted = true;
              }
            }
          );
        }
        if (isClh) {
          this.allMethodsCompleted = false;
          const clhChargerSubscriptions = this.chatHelper
            .getChatGroupChargerCLH(offerBinding.offerId)
            .pipe(
              switchMap((group) => {
                return forkJoin({
                  windowName: offerBinding.chargerName || "",
                  currentParticipant: of(participant),
                  group: of(group),
                  historicMessages: this.chatHelper.getMessageHistory(group.id),
                });
              })
            );
          clhChargerSubscriptions.subscribe((res) => {
            const obj = {
              offerId: offerBinding.offerId,
              currentParticipant: res.currentParticipant,
              windowName: offerBinding.chargerName || "",
              group: res.group,
              historicMessages: res.historicMessages.map((s) =>
                this.chatHelper.setReplyBasedOnCurrentUser(s)
              ),
              unReadMessages: res.historicMessages
                .map((s) => this.chatHelper.setReplyBasedOnCurrentUser(s))
                .filter(
                  (s) => s.participantId != res.currentParticipant.id && !s.seen
                ).length,
            };
            this.chatValues.push(obj);
          });
          const clhShipownerSubscriptions = this.chatHelper
            .getChatGroupCLHOfferShipOwner(offerBinding.offerShipOwnerId)
            .pipe(
              switchMap((group) => {
                return forkJoin({
                  offerShipOwnerId: offerBinding.offerShipOwnerId,
                  windowName: offerBinding.chargerName || "",
                  currentParticipant: of(participant),
                  group: of(group),
                  historicMessages: this.chatHelper.getMessageHistory(group.id),
                });
              })
            );
          clhShipownerSubscriptions.subscribe(
            ({ windowName, currentParticipant, group, historicMessages }) => {
              if (
                windowName &&
                currentParticipant &&
                group &&
                historicMessages
              ) {
                const obj = {
                  offerShipOwnerId: offerBinding.offerShipOwnerId,
                  currentParticipant: currentParticipant,
                  windowName: offerBinding.shipOwnerName || "",
                  group: group,
                  historicMessages: historicMessages.map((s) =>
                    this.chatHelper.setReplyBasedOnCurrentUser(s)
                  ),
                  unReadMessages: historicMessages
                    .map((s) => this.chatHelper.setReplyBasedOnCurrentUser(s))
                    .filter(
                      (s) => s.participantId != currentParticipant.id && !s.seen
                    ).length,
                };
                this.chatValues.push(obj);
                this.allMethodsCompleted = true;
              }
            }
          );
        }
        if (this.globals.currentShipOwner) {
          //Chat shipownerClh
          this.allMethodsCompleted = false;
          const clhShipownerSubscriptions = this.chatHelper
            .getChatGroupCLHOfferShipOwner(offerBinding.offerShipOwnerId)
            .pipe(
              switchMap((group) => {
                return forkJoin({
                  offerShipOwnerId: offerBinding.offerShipOwnerId,
                  windowName: offerBinding.chargerName || "",
                  currentParticipant: of(participant),
                  group: of(group),
                  historicMessages: this.chatHelper.getMessageHistory(group.id),
                });
              })
            );
          clhShipownerSubscriptions.subscribe(
            ({ windowName, currentParticipant, group, historicMessages }) => {
              if (
                windowName &&
                currentParticipant &&
                group &&
                historicMessages
              ) {
                const obj = {
                  offerShipOwnerId: offerBinding.offerShipOwnerId,
                  currentParticipant: currentParticipant,
                  windowName: "CLH",
                  group: group,
                  historicMessages: historicMessages.map((s) =>
                    this.chatHelper.setReplyBasedOnCurrentUser(s)
                  ),
                  unReadMessages: historicMessages
                    .map((s) => this.chatHelper.setReplyBasedOnCurrentUser(s))
                    .filter(
                      (s) => s.participantId != currentParticipant.id && !s.seen
                    ).length,
                };
                this.chatValues.push(obj);
              }
            }
          );

          const shipOwnerChargerSubscriptions = this.chatHelper
            .getChatGroup(offerBinding.offerShipOwnerId)
            .pipe(
              switchMap((group) => {
                return forkJoin({
                  offerShipOwnerId: offerBinding.offerShipOwnerId,
                  windowName: offerBinding.chargerName || "",
                  currentParticipant: of(participant),
                  group: of(group),
                  historicMessages: this.chatHelper.getMessageHistory(group.id),
                });
              })
            );
          shipOwnerChargerSubscriptions.subscribe(
            ({ windowName, currentParticipant, group, historicMessages }) => {
              if (
                windowName &&
                currentParticipant &&
                group &&
                historicMessages
              ) {
                const obj = {
                  offerShipOwnerId: offerBinding.offerShipOwnerId,
                  currentParticipant: currentParticipant,
                  windowName: offerBinding.chargerName || "",
                  group: group,
                  historicMessages: historicMessages.map((s) =>
                    this.chatHelper.setReplyBasedOnCurrentUser(s)
                  ),
                  unReadMessages: historicMessages
                    .map((s) => this.chatHelper.setReplyBasedOnCurrentUser(s))
                    .filter(
                      (s) => s.participantId != currentParticipant.id && !s.seen
                    ).length,
                };
                this.chatValues.push(obj);
                this.allMethodsCompleted = true;
              }
            }
          );
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  changesAccordingToUser() {
    // CLHADMIN
    // Set isClhRevision and offerFirmBindingIsReadOnly based on offerBinding state and userType
    if (
      this.isClh &&
      this.offerBinding.state == StateOfferShipOwner.AGREEMENT_PREVALIDATED &&
      !this.isCLHRepresentingCharger &&
      !this.isCLHRepresentingShipowner
    ) {
      this.isClhRevision = true;
      if (this.offerBinding.shift === UserType.ADMIN) {
        this.offerFirmBindingIsReadOnly = false;
      } else {
        this.isClhRevision = false;
        this.offerFirmBindingIsReadOnly = true;
      }
    }

    //RECAP
    // Set isConfirmedRecap and isRecap based on offerBinding state and isView flags
    if (!this.isViewNegotationAfterRecap) {
      if (
        this.offerBinding.state === StateOfferShipOwner.RECAP_CONFIRMED ||
        this.isViewRecap ||
        this.isViewOperative
      ) {
        if (
          this.offerBinding.offerShipOwnerState &&
          this.statusRecapButton.includes(this.offerBinding.offerShipOwnerState)
        ) {
          this.isConfirmedRecap = false;
        } else {
          this.isConfirmedRecap = true;
        }
        this.isRecap = true;
      }
    }
    //CONDITIONS IF CHARTERER OR SHIPOWNER TO COUNTER 0
    // Set isFirstStepCharterer, showDivClauses, isNegotiation, and offerFirmBindingIsReadOnly based on offerBinding counter and userType
    if (this.globals.currentCharger || this.isCLHRepresentingCharger) {
      this.idCharger = this.globals.currentCharger?.id || "";
      if (this.globals.user !== null) {
        this.nameContactCharger =
          this.globals.user.firstName + " " + this.globals.user.lastName;
      }
      if (this.offerBinding.state == StateOfferShipOwner.BIDED) {
        // if (!this.isClh) {
        this.isFirstStepCharterer = true;
        this.showDivClauses = true;
        this.isNegotiation = false;
        this.offerFirmBindingIsReadOnly =
          this.offerBinding.shift == null ? false : true;
        this.offerBinding.originalOffer = { ...this.offerBinding.initialOffer };
        // }
      }
    } else if (
      this.globals.currentShipOwner ||
      this.isCLHRepresentingShipowner
    ) {
      this.isShipownerBinding = true;
      this.idShipowner = this.globals.currentShipOwner?.id || "";
      if (this.globals.user !== null) {
        this.nameContactShipowner =
          this.globals.user.firstName + " " + this.globals.user.lastName;
      }
      // if (this.offerBinding.counter < 1) {
      if (this.offerBinding.state == StateOfferShipOwner.AGREEMENT_STARTED) {
        //primera vez
        this.isNegotiation = true;
        this.isCounter0shipOwner = true;
        this.offerFirmBindingIsReadOnly =
          this.offerBinding.shift === this.offerBinding.currentUserType
            ? false
            : true;
      }
    }

    //CONDITIONS IF OFFERSHIPOWNER STATE COUNTER
    // Set variablesForState and offerFirmBindingIsReadOnly based on offerBinding counter, isClhRevision, shift, and userType
    if (this.offerBinding.counter > 0 && !this.isClhRevision) {
      this.variablesForState();
      if (this.offerBinding.shift != UserType.ADMIN && !this.isRecap) {
        this.offerFirmBindingIsReadOnly =
          this.offerBinding.shift === this.offerBinding.currentUserType
            ? false
            : true;
        console.log("READONLY", this.offerFirmBindingIsReadOnly);
      } else if (!this.isRecap) {
        if (this.globals.currentCharger || this.isCLHRepresentingCharger) {
          this.offerFirmBindingIsReadOnly =
            this.offerBinding.shift === this.offerBinding.currentUserType
              ? false
              : true;
          console.log("READONLY", this.offerFirmBindingIsReadOnly);
        }
        if (this.globals.currentShipOwner || this.isCLHRepresentingShipowner) {
          this.offerFirmBindingIsReadOnly =
            this.offerBinding.shift === this.offerBinding.currentUserType
              ? false
              : true;
        }
      }
    }
    if (this.offerBinding.counter < 1) {
      if (this.offerBinding.shift) {
        this.offerFirmBindingIsReadOnly =
          this.offerBinding.shift === this.offerBinding.currentUserType
            ? false
            : true;
      } else {
        this.offerFirmBindingIsReadOnly = false;
      }
    }
    // Set isClhRevision and offerFirmBindingIsReadOnly based on offerBinding counter, shift, userType, and isClh
    if (this.isClh) {
      if (
        this.offerBinding.counter < 1 ||
        this.offerBinding.shift === UserType.CHARGER ||
        this.offerBinding.shift === UserType.SHIPOWNER
      ) {
        if (
          this.offerBinding.state === StateOfferShipOwner.RECAP ||
          this.offerBinding.state ===
          StateOfferShipOwner.RECAP_CHARTERER_PENDING ||
          this.offerBinding.state ===
          StateOfferShipOwner.RECAP_SHIPOWNER_PENDING
        ) {
          this.isClhRevision = false;
          this.offerFirmBindingIsReadOnly = true;
        } else {
          this.isClhRevision = true;
          this.offerFirmBindingIsReadOnly = true;
        }
      }
    }
    if (
      this.offerBinding.state == StateOfferShipOwner.BIDED &&
      !this.isShipownerBinding &&
      this.offerBinding.counter == 0
    ) {
      if (this.offerBinding.shift == UserType.SHIPOWNER) {
        this.offerFirmBindingIsReadOnly = true;
      }
    }
    if (this.offerBinding.state == StateOfferShipOwner.RECAP) {
      this.offerFirmBindingIsReadOnly = this.isClh ? true : false;
      if (this.isClh) {
        this.isClhRevision = false;
      }
    }
    if (
      this.offerBinding.state == StateOfferShipOwner.RECAP_CHARTERER_PENDING ||
      this.offerBinding.state == StateOfferShipOwner.RECAP_SHIPOWNER_PENDING
    ) {
      this.offerFirmBindingIsReadOnly =
        this.offerBinding.shift === this.offerBinding.currentUserType
          ? false
          : true;
    }
    if (this.offerBinding.state == StateOfferShipOwner.RECAP_CONFIRMED) {
      this.offerFirmBindingIsReadOnly = true;
    }
  }
  assignChangesToVariablesToViewType() {
    //CONDITIONS SHOW 1-4 FIELDS
    if (this.isShipownerBinding || this.isCLHRepresentingShipowner) {
      this.showCheckInTable1To4 = true;
      this.approvedStatus = [
        NegotiationFieldState.CONFIRMED,
        NegotiationFieldState.CHARGER_PENDING,
        NegotiationFieldState.CHARGER_PENDING_REOPENED,
      ];
    } else {
      this.approvedStatus = [
        NegotiationFieldState.CONFIRMED,
        NegotiationFieldState.SHIPOWNER_PENDING,
        NegotiationFieldState.SHIPOWNER_PENDING_REOPENED,
      ];
    }
    if (this.isNegotiation) {
      // this.showDivClauses = false;
      // this.withoutBorderInput=true;
    } else if (this.isCounter0shipOwner) {
      this.isreadonly = false; //para el textarea
    } else if (this.isRecap) {
      this.withoutBorderInput = true;
    } else if (this.isClhRevision) {
      //withoutBorderInput put a false so that the history can be seen
      this.withoutBorderInput = false;
      this.isreadonly = true; //para el textarea
    } else if (this.isViewNegotationAfterRecap) {
      this.withoutBorderInput = false;
    } else {
      const change = false;
      this.changeLabels(change);
    }
    if (
      this.isNegotiation ||
      this.isCounter0shipOwner ||
      this.isClhRevision ||
      this.isRecap
    ) {
      if (this.isNegotiation) {
        if (this.offerBinding.counter == 1 && this.globals.currentCharger) {
          if (!this.offerBinding.chargerClauses?.length) {
            this.showDivClauses = this.offerBinding.chargerClauses?.length
              ? false
              : true;
          }
        } else {
          this.showDivClauses = false;
        }
      } else {
        this.showDivClauses = false;
      }
      console.log("showDivClauses", this.showDivClauses);
      const change = true;
      this.changeLabels(change);
    }
    if (
      this.isNegotiation ||
      this.isCounter0shipOwner ||
      this.isClhRevision ||
      this.isRecap ||
      this.offerFirmBindingIsReadOnly
    ) {
      this.addClausesToList("chargerClauses", UserType.CHARGER);
      this.addClausesToList("shipOwnerClauses", UserType.SHIPOWNER);
    }
    // if (this.isNegotiation || this.isCounter0shipOwner || this.isRecap || (this.isClhRevision && this.offerFirmBindingIsReadOnly)) {
    //   this.addClausesToList('shipOwnerClauses', UserType.SHIPOWNER);
    // }
  }
  checkIsReadOnlyViewOfferFirm() {
    if (this.statusReadOnly.includes(this.offerBinding.state)) {
      this.offerFirmBindingIsReadOnly = true;
    }
    if (this.havePermissions) {
      if (this.offerFirmBindingIsReadOnly) {
        if (!this.isViewNegotationAfterRecap) {
          this.showDivClauses = false;

          this.withoutBorderInput = true;
          this.isreadonly = true; //para el textarea
          const change = true;
          this.changeLabels(change);
        }
        if (this.isClh && !this.isRecap) {
          //view historic add ! withoutBorderInput|| isClh in collapse and container app-histories-offer
          this.withoutBorderInput = true;
        }
      }
    } else {
      this.offerFirmBindingIsReadOnly = true;
    }
  }
  orderAndFilterPorts() {
    //comented order because otherwise it fails in history changes
    // this.offerBinding.loadingPorts.sort();
    // this.offerBinding.dischargingPorts.sort();
    this.selectedLoadingPorts = this.ports.filter((x) =>
      this.offerBinding.loadingPorts.includes(x)
    );
    //this.offerBinding.loadingPorts = this.selectedLoadingPorts;
    this.selectedDischargingPorts =
      this.portsD.filter((x) =>
        this.offerBinding.dischargingPorts.includes(x)
      ) || [];

    this.ports = this.ports.filter(
      (x) => !this.offerBinding.loadingPorts.includes(x)
    );
    this.portsD = this.portsD.filter(
      (x) => !this.offerBinding.dischargingPorts.includes(x)
    );
  }
  variablesForState() {
    // var state = this.globals.currentCharger ? StateOfferShipOwner.RECAP_CHARTERER_PENDING : StateOfferShipOwner.RECAP_SHIPOWNER_PENDING;
    if (
      this.offerBinding.state === StateOfferShipOwner.RECAP ||
      this.offerBinding.state === StateOfferShipOwner.RECAP_CHARTERER_PENDING ||
      this.offerBinding.state === StateOfferShipOwner.RECAP_SHIPOWNER_PENDING ||
      (this.offerBinding.state === StateOfferShipOwner.RECAP_CONFIRMED &&
        !this.isViewNegotationAfterRecap)
    ) {
      this.isRecap = true;
      this.isNegotiation = false;
    } else {
      this.isNegotiation = true;
      if (this.globals.currentShipOwner) {
        this.isCounter0shipOwner = false;
      }
    }
  }
  setMultipleChanges(offerBinding: NegotiationOfferShipOwner) {
    const layCanHistories: any[] = [];
    const freightTargetHistories: any[] = [];
    const demurrageHistories: any[] = [];
    const quantityHistories: any[] = [];
    const layCanFromNumber = offerBinding.layCanFromHistories?.length;
    const layCanToNumber = offerBinding.layCanToHistories?.length;

    const freightTargetCurrencyNumber =
      offerBinding.freightTargetCurrencyHistories?.length;
    const freightTargetQuantityNumber =
      offerBinding.freightTargetQuantityHistories?.length;
    const freightTargetTypeNumber =
      offerBinding.freightTargetTypeHistories?.length;

    const demurrageCurrencyNumber =
      offerBinding.demurrageCurrencyHistories?.length;
    const demurrageQuantityNumber =
      offerBinding.demurrageQuantityHistories?.length;
    const demurrageTypeNumber = offerBinding.demurrageTypeHistories?.length;

    const commodityNumber = offerBinding.commodityHistories?.length;
    const quantityNumber = offerBinding.quantityHistories?.length;
    const quantityTypeNumber = offerBinding.quantityTypeHistories?.length;
    const stowageNumber = offerBinding.stowageHistories?.length;

    if (layCanFromNumber == layCanToNumber) {
      offerBinding.layCanFromHistories?.forEach((historyFrom, index) => {
        const line = { ...historyFrom };
        line.value =
          this.datePipe.transform(
            new Date(historyFrom.value || new Date()),
            "dd/MM/yy"
          ) +
          " - " +
          this.datePipe.transform(
            new Date(offerBinding.layCanToHistories[index].value || new Date()),
            "dd/MM/yy"
          );
        layCanHistories.push(line);
      });
      offerBinding.layCanHistories = layCanHistories;

      //   });
    }
    if (
      freightTargetCurrencyNumber == freightTargetQuantityNumber &&
      freightTargetCurrencyNumber == freightTargetTypeNumber
    ) {
      offerBinding.freightTargetQuantityHistories.forEach((history, index) => {
        const line: any = { ...history };
        line.value =
          history.value +
            " " +
            offerBinding.freightTargetCurrencyHistories[index].value
            ? FreightTargetCurrency[
            offerBinding.freightTargetCurrencyHistories[index].value
            ]
            : "" +
            " " +
            FreightTargetType[
            offerBinding.freightTargetTypeHistories[index].value
            ];
        freightTargetHistories.push(line);
      });
      offerBinding.freightTargetHistories = freightTargetHistories;
    }
    if (
      demurrageCurrencyNumber == demurrageQuantityNumber &&
      demurrageCurrencyNumber == demurrageTypeNumber
    ) {
      offerBinding.demurrageQuantityHistories.forEach(
        (history: any, index: number) => {
          const line = { ...history };
          line.value =
            history.value +
            " " +
            FreightTargetCurrency[
            offerBinding.demurrageCurrencyHistories[index].value
            ] +
            " " +
            DemurrageType[offerBinding.demurrageTypeHistories[index].value];
          demurrageHistories.push(line);
          offerBinding.demurrageHistories = demurrageHistories;
        }
      );
    }

    if (
      commodityNumber == quantityNumber &&
      commodityNumber == quantityTypeNumber &&
      commodityNumber == stowageNumber
    ) {
      offerBinding.quantityHistories?.forEach((history, index) => {
        const line = { ...history };
        line.value =
          history.value +
          " " +
          QuantityType[offerBinding.quantityTypeHistories[index].value] +
          " " +
          offerBinding.commodityHistories[index].value +
          " " +
          offerBinding.stowageHistories[index].value;
        quantityHistories.push(line);
        offerBinding.quantityFieldHistories = quantityHistories;
      });
      if (
        this.isCounter0shipOwner ||
        (this.isShipownerBinding && this.isNegotiation)
      ) {
        if (offerBinding.quantityFieldHistories.length >= 2) {
          if (
            offerBinding.quantityFieldHistories[0].value !=
            offerBinding.quantityFieldHistories[1].value
          ) {
            if (this.isCounter0shipOwner) {
              this.changeQuantity = true;
            } else {
              if (
                this.offerBinding.quantityStowageComodityState !=
                NegotiationFieldState.CONFIRMED
              ) {
                this.changeQuantity = true;
              }
            }
          }
        }
      }
    }
  }

  initialForm() {
    this.form = this.fb.group(
      {
        quantityType: new FormControl(this.DEFAULT_QUANTITYTYPE, [
          Validators.required,
        ]),
        quantity: new FormControl("", [Validators.required]),
        commodity: new FormControl("", [Validators.required]),
        stowage: new FormControl("", []),
        loadingPorts: new FormControl("", [this.arrValidator]),
        loadingPortConditions: new FormControl("", []),
        dischargingPorts: new FormControl("", [this.arrValidator]),
        dischargingPortConditions: new FormControl("", []),
        cpForm: new FormControl("", [Validators.required]),
        chargeringTerm: new FormControl("", [Validators.required]),
        layTimeTerm: new FormControl("", [Validators.required]),
        freightTargetQuantity: new FormControl("", [Validators.required]),
        restrictionsRegarding: new FormControl("", []),
        aditionalRequirements: new FormControl("", []),
        freightTargetCurrency: new FormControl(
          FreightTargetCurrency[this.DEFAULT_TARGETCURRENCY],
          []
        ),
        freightTargetType: new FormControl(
          FreightTargetType[this.DEFAULT_TARGETTYPE],
          []
        ),
        rangeDates: new FormControl(
          [this.today, this.today],
          [this.validators.rangeValidator]
        ),
        vesselName: new FormControl("", [Validators.required]),
        remarks: new FormControl("", []),
        addressComm: new FormControl(0, []),
        demurrageQuantity: new FormControl("", [Validators.required]),
        demurrageType: new FormControl(
          DemurrageType[this.DEFAULT_TARGETTYPE],
          []
        ),
        demurrageCurrency: new FormControl(
          FreightTargetCurrency[this.DEFAULT_TARGETCURRENCY],
          []
        ),
        demurrageConditions: new FormControl("", []),
        clausesNew: new FormControl("", []),
        clausesNewCLH: new FormControl("", []),
        clauseOfListCLH: new FormControl("", []),
        expirationDate: new FormControl(this.today, []),
        checkedExpirationDate: new FormControl(this.checked, []),
      },
      {
        validators: [
          this.validators.expirationDateValidator(),
          this.validators.validateMatch(
            "demurrageCurrency",
            "freightTargetCurrency"
          ),
        ],
      }
    );
    this.form.get("expirationDate").disable();
    if (
      this.isClh ||
      this.isCounter0shipOwner ||
      (this.isShipownerBinding &&
        this.offerBinding.counter == 1 &&
        this.offerBinding.state == StateOfferShipOwner.AGREEMENT_PREVALIDATED)
    ) {
      this.form.addControl("initialShipOwnerClauses", new FormControl("", []));
    }
  }
  setForm() {
    if (this.offerBinding) {
      this.form
        .get("quantityType")
        .setValue(QuantityType[this.offerBinding.quantityType]);
      this.form.get("quantity").setValue(this.offerBinding.quantity);
      this.form.get("commodity").setValue(this.offerBinding.commodity);
      this.form.get("stowage").setValue(this.offerBinding.stowage);
      this.form
        .get("freightTargetCurrency")
        .setValue(
          FreightTargetCurrency[this.offerBinding.freightTargetCurrency]
        );
      this.form
        .get("freightTargetType")
        .setValue(FreightTargetType[this.offerBinding.freightTargetType]);
      this.form.get("loadingPorts").setValue(this.offerBinding.loadingPorts);
      this.form
        .get("dischargingPorts")
        .setValue(this.offerBinding.dischargingPorts);
      this.form.get("cpForm").setValue(this.offerBinding.cpForm);
      this.form
        .get("chargeringTerm")
        .setValue(this.offerBinding.chargeringTerm);
      this.form.get("layTimeTerm").setValue(this.offerBinding.layTimeTerm);
      this.form
        .get("freightTargetQuantity")
        .setValue(this.offerBinding.freightTargetQuantity);
      this.form
        .get("restrictionsRegarding")
        .setValue(this.offerBinding.restrictionsRegarding);
      this.form
        .get("aditionalRequirements")
        .setValue(this.offerBinding.aditionalRequirements);
      this.form.get("vesselName").setValue(this.offerBinding.vesselName);
      this.form.get("remarks").setValue(this.offerBinding.remarks);
      this.form.get("addressComm").setValue(this.offerBinding.addressComm);
      this.form
        .get("demurrageQuantity")
        .setValue(this.offerBinding.demurrageQuantity);
      this.form
        .get("demurrageCurrency")
        .setValue(FreightTargetCurrency[this.offerBinding.demurrageCurrency]);
      this.form
        .get("demurrageType")
        .setValue(DemurrageType[this.offerBinding.demurrageType]);
      this.form
        .get("loadingPortConditions")
        .setValue(this.offerBinding.loadingPortConditions);
      this.form
        .get("dischargingPortConditions")
        .setValue(this.offerBinding.dischargingPortConditions);
      this.form
        .get("demurrageConditions")
        .setValue(this.offerBinding.demurrageConditions);
    }
    if (this.offerFirmBindingIsReadOnly) {
      this.form.get('dischargingPorts').disable();
      this.form.get('loadingPorts').disable();
      this.form.get('rangeDates').disable();

    }
    if (
      this.offerBinding.layCanFrom != null &&
      this.offerBinding.layCanTo != null
    ) {
      this.form
        .get("rangeDates")
        .setValue(
          [
            new Date(this.offerBinding.layCanFrom),
            new Date(this.offerBinding.layCanTo),
          ],
          { emitEvent: false }
        );
    }

    if (
      (this.isClhRevision && this.offerBinding.counter === 1) ||
      this.isClh ||
      (this.isShipownerBinding &&
        this.offerBinding.counter == 1 &&
        this.offerBinding.state == StateOfferShipOwner.AGREEMENT_PREVALIDATED)
    ) {
      this.form
        .get("initialShipOwnerClauses")
        .setValue(this.offerBinding.initialShipOwnerClauses);
    }
    if (
      this.offerFirmBindingIsReadOnly &&
      this.offerBinding.expirationDate != null
    ) {
      this.form
        .get("expirationDate")
        .setValue(new Date(this.offerBinding.expirationDate));
    }
    if (this.isFirstStepCharterer) {
      this.form.get("demurrageCurrency").disable();
    }
  }

  selectedLoadingPortChange(event: string[]) {
    console.log(event);
    //TODO REVIEW
    // this.offerBinding.loadingPorts = event;
    // this.setForm();
  }
  selectedDischargingPortChange(event: string[]) {
    console.log(event);
    //TODO REVIEW
    // this.offerBinding.dischargingPorts = event;
    // this.setForm();
  }

  showClauses() {
    this.spinner.show();
    this.offerService.getClausesByChargerId(this.idCharger).subscribe({
      next: (value) => {
        this.listClauses = [];
        this.listClauses = value;
        console.log(value);
        this.convertStringToClauseNegotiation(this.listClauses);
        this.searchDefaultClause();
        this.countClauses = this.listchargerClauses.length;
        console.log("listClauses", this.listchargerClauses);
      },
      error: (err) => {
        console.log("errorClause", err);
        this.spinner.hide();
      },
    });

    this.listchargerClauses = [...this.listchargerClauses];
    this.sendBinding = true;
    this.spinner.hide();
  }
  addClausesToList(nameClauses: string, type: UserType) {
    const lista = "list".concat(nameClauses);
    let index = 0;
    if (this.isRecap) {
      this.offerBinding[nameClauses] = this.offerBinding[nameClauses].filter(
        (x) => !x.isRefused
      );
    }
    this.offerBinding[nameClauses].forEach((clauseN) => {
      clauseN.userType = type;
      clauseN.isReadOnly = true;
      clauseN.nameControl = nameClauses.concat(index.toString());
      clauseN.valueInitial = clauseN.value;
      clauseN.refusedInitial = clauseN.isRefused;
      clauseN.stateInitial = clauseN.state;
      clauseN.indexList = index;
      if (clauseN.id) {
        clauseN.dataTarget = "#collapseHistory" + nameClauses + clauseN.id;
        clauseN.ariaControls = "collapseHistory" + nameClauses;
      }
      if (
        clauseN.isRefused &&
        clauseN.state === NegotiationFieldState.CONFIRMED
      ) {
        clauseN.approvedRefused = true;
      }

      this[lista].push(clauseN);
      // console.log(this[lista]);
      this.form.addControl(
        clauseN.nameControl,
        new FormControl(clauseN.value, [Validators.required])
      );
      index++;
    });
  }
  changeVariables() {
    if (!this.isClhRevision) {
      this.showTextArea = true;
      this.isreadonly = false;
      this.form.get("clausesNew").setValue("");
    } else {
      this.isreadonlyCLH = !this.isreadonlyCLH;
      this.form.get("clausesNewCLH").setValue("");
    }
  }
  cancelClause() {
    this.isreadonly = true;
    this.showTextArea = false;
    if (this.isClh) {
      this.isreadonlyCLH = !this.isreadonlyCLH;
      this.form.get("clausesNewCLH").setValue("");
    }
    if (this.globals.currentCharger) {
      this.form.get("clausesNew").setValue("");
    }
  }
  editClause(clause: ClauseNegotiation) {
    console.log("edit", clause);
    this.spinner.show();
    if (!this.isClhRevision) {
      clause.isReadOnly = false;
      clause.isEditClause = true;
      this.form.get(clause.nameControl).setValue(clause.value);
    } else {
      clause.isReadOnly = false;
      clause.isEditClause = true;
      this.form.get(clause.nameControl).setValue(clause.value);
    }
    this.spinner.hide();
  }
  deleteClause(clause: ClauseNegotiation) {
    console.log("idClause", clause);

    const trmessage = this.translate.get("COMMONS.ASK_DELETE_RECORD");
    const messageAccept = this.translate.get("ACTION.YES");

    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        let indice;
        if (!this.isClhRevision && clause.userType === UserType.CHARGER) {
          console.log("clauseUserType");
          this.deleteClauseAndRemoveControl("listchargerClauses", clause);
        } else if (
          !this.isClhRevision &&
          clause.userType === UserType.SHIPOWNER
        ) {
          this.deleteClauseAndRemoveControl("listshipOwnerClauses", clause);
        } else {
          if (this.globals.currentCharger) {
            console.log("clause");
            this.deleteClauseAndRemoveControl("listchargerClauses", clause);
            // console.log("listacharger", this.listchargerClauses);
          } else {
            this.deleteClauseAndRemoveControl("listClauseCLH", clause);
            // console.log("listaCLH", this.listClauseCLH);
          }
        }
      },
      reject: () => {
        console.log("reject");
      },
    });
  }
  deleteClauseAndRemoveControl(listName: string, clause: ClauseNegotiation) {
    const indice = this[listName].indexOf(clause);
    this[listName].splice(indice, 1);
    this.form.removeControl(clause.nameControl);
    this[listName] = [...this[listName]];
    console.log(this[listName]);
    if (listName === "listchargerClauses") {
      this.countClauses = this.listchargerClauses.length;
      if (clause?.isDuplicate === true) {
        this.searchDuplicateClause();
      } else {
        this.searchDefaultClause();
      }
    } else if (listName === "listshipOwnerClauses") {
      this.searchDuplicateClause();
    }
  }
  searchDefaultClause() {
    this.contentDefaultClause = false;
    this.listchargerClauses.find((item) => {
      if (item.isDefault === true) {
        this.contentDefaultClause = true;
      }
    });
  }
  searchDuplicateClause() {
    this.contentDuplicateClause = false;
    this.listchargerClauses.find((item) => {
      if (item.isDuplicate === true) {
        this.contentDuplicateClause = true;
      }
    });
    if (this.listshipOwnerClauses.length) {
      this.listshipOwnerClauses.find((item) => {
        if (item.isDuplicate === true) {
          this.contentDuplicateClause = true;
        }
      });
    }
  }
  saveClause(clause?: ClauseNegotiation) {
    console.log("CLAUSE", clause);
    if (clause) {
      let indice;
      if (!this.isClhRevision && this.form.get(clause.nameControl).value) {
        console.log("clause", clause);
        let listToChange;
        if (this.offerBinding.currentUserType === UserType.CHARGER) {
          indice =
            clause.isDuplicate && clause.userType === UserType.SHIPOWNER
              ? this.listshipOwnerClauses.indexOf(clause)
              : this.listchargerClauses.indexOf(clause);

          listToChange =
            clause.isDuplicate && clause.userType === UserType.SHIPOWNER
              ? [...this.listshipOwnerClauses]
              : [...this.listchargerClauses];
          console.log(
            indice,
            listToChange[indice],
            this.form.get(clause.nameControl).value
          );
          listToChange[indice].state = NegotiationFieldState.SHIPOWNER_PENDING;
          listToChange[indice].value = this.form.get(clause.nameControl).value;
          listToChange = [...listToChange];
          clause.userType =
            clause.isDuplicate && clause.userType === UserType.SHIPOWNER
              ? UserType.SHIPOWNER
              : UserType.CHARGER;
          clause.isReadOnly = true;
          clause.isEditClause = false;
          console.log("lista", listToChange);
          // console.log('clause', clause);
        }
      } else {
        indice = this.listClauseCLH.indexOf(clause);
        this.listClauseCLH[indice].value = this.form.get(
          clause.nameControl
        ).value;
        // console.log('lista', this.listClauseCLH);
        clause.isReadOnly = true;
        clause.isEditClause = false;
      }
    } else {
      const clauseNegotiation = new ClauseNegotiation();
      if (
        !clause &&
        !this.isClhRevision &&
        this.globals.currentCharger &&
        this.form.get("clausesNew").value
      ) {
        const listLength = this.listchargerClauses?.length
          ? this.listchargerClauses.length
          : 0;
        const index =
          listLength > 0
            ? this.listchargerClauses[listLength - 1].indexList + 1
            : 0;
        this.isreadonly = true;
        clauseNegotiation.value = this.form.get("clausesNew").value;
        clauseNegotiation.isReadOnly = true;
        clauseNegotiation.isEditClause = false;
        clauseNegotiation.userType = UserType.CHARGER;
        clauseNegotiation.state = NegotiationFieldState.SHIPOWNER_PENDING;
        clauseNegotiation.nameControl = "chargerClauses".concat(
          index.toString()
        );
        clauseNegotiation.indexList = index;
        this.listchargerClauses.push(clauseNegotiation);
        this.form.addControl(
          clauseNegotiation.nameControl,
          new FormControl(clauseNegotiation.value, [Validators.required])
        );
        console.log("addclause", index, this.listchargerClauses);
        this.listchargerClauses = [...this.listchargerClauses];
        this.showTextArea = false;
        this.form.get("clausesNew").setValue("");
      } else if (
        !clause &&
        this.isClhRevision &&
        this.form.get("clausesNewCLH").value
      ) {
        this.isreadonlyCLH = !this.isreadonlyCLH;
        // console.log('ClauseNewCLH', this.form.get("clausesNewCLH").value);
        const listLength = this.listClauseCLH?.length
          ? this.listClauseCLH.length
          : 0;
        const index =
          listLength > 0 ? this.listClauseCLH[listLength - 1].indexList + 1 : 0;
        clauseNegotiation.value = this.form.get("clausesNewCLH").value;
        clauseNegotiation.isReadOnly = true;
        clauseNegotiation.isEditClause = false;
        clauseNegotiation.nameControl = "clauseOfListCLH".concat(
          index.toString()
        );
        clauseNegotiation.isDefault = false;
        clauseNegotiation.indexList = index;
        this.listClauseCLH.push(clauseNegotiation);
        this.form.addControl(
          clauseNegotiation.nameControl,
          new FormControl(clauseNegotiation.value, [Validators.required])
        );
        // console.log("addclause", this.listClauseCLH);
        this.form.get("clausesNewCLH").setValue("");
      }
    }
    this.countClauses = this.listchargerClauses.length;
  }
  deleteFieldsInClauses(list: ClauseNegotiation[]) {
    // list.forEach(item => {
    //   delete item.userType;
    //   delete item.isReadOnly;
    //   delete item.isEditClause;
    //   delete item.nameControl;
    // });
  }
  filterClauses(nameClauses: string, type: UserType) {
    const list = "list".concat(nameClauses);
    const listClauses = this[list].filter((item) => item.userType === type);
    this.deleteFieldsInClauses(listClauses);
    this.offerBinding[nameClauses] = listClauses;
    console.log("LIST", this.offerBinding[nameClauses]);
  }
  changeDataInOfferOfForm() {
    console.log("offerBindingAntes del form", this.offerBinding);
    const formValue = { ...this.form.value };
    if (this.form.valid) {
      this.converToEnum(formValue);
      // console.log('FormValue2', formValue);

      if (formValue.rangeDates != undefined) {
        this.offerBinding.layCanFrom = formValue.rangeDates[0]
          ? new Date(
            Date.UTC(
              formValue.rangeDates[0].getFullYear(),
              formValue.rangeDates[0].getMonth(),
              formValue.rangeDates[0].getDate()
            )
          )
          : this.offerBinding.layCanFrom;
        this.offerBinding.layCanTo = formValue.rangeDates[1]
          ? new Date(
            Date.UTC(
              formValue.rangeDates[1].getFullYear(),
              formValue.rangeDates[1].getMonth(),
              formValue.rangeDates[1].getDate()
            )
          )
          : this.offerBinding.layCanTo;
      }
    }

    // delete formValue.rangeDates;
    // delete formValue.clausesNew;
    //delete formValue.checkedExpirationDate;
    this.offerBinding = { ...this.offerBinding, ...formValue };
    let expirationDate = this.form.get("expirationDate").value;

    // this.offerBinding.expirationDate = !this.checked ? null : new Date(Date.UTC(formValue.expirationDate.getFullYear(), formValue.expirationDate.getMonth(), formValue.expirationDate.getDate(), formValue.expirationDate.getHours(), formValue.expirationDate.getMinutes()));
    if (this.checked) {
      expirationDate = this.form.get("expirationDate").value;
      this.offerBinding.expirationDate = expirationDate.toISOString();
    } else {
      expirationDate = null;
      this.offerBinding.expirationDate = null;
    }

    if (this.globals.currentCharger) {
      this.offerBinding.quantityStowageComodityState =
        this.offerBinding.quantityFieldState;
    } else if (this.globals.currentShipOwner) {
      if (
        this.offerBinding.quantityFieldState !=
        NegotiationFieldState.CONFIRMED ||
        (this.offerBinding.quantityFieldState ==
          NegotiationFieldState.CONFIRMED &&
          this.offerBinding.quantityStowageComodityState !=
          NegotiationFieldState.CONFIRMED)
      ) {
        this.offerBinding.quantityStowageComodityState =
          NegotiationFieldState.CONFIRMED;
        this.offerBinding.quantityFieldState = NegotiationFieldState.CONFIRMED;
      }
    }
    if (this.isFirstStepCharterer) {
      //Demurrage Currency equal a freight Currency
      this.offerBinding.demurrageCurrency =
        this.offerBinding.freightTargetCurrency;
    }

    console.log("offerBindingdespues del form", this.offerBinding);
  }

  sendOfferBinding() {
    this.isLoading = true;
    this.spinner.show();
    // console.log("formulario", this.form);
    this.changeDataInOfferOfForm();
    console.log("offerBindingDespues", this.offerBinding);

    if (this.globals.currentCharger && !this.isNegotiation) {
      if (this.listchargerClauses?.length) {
        this.deleteFieldsInClauses(this.listchargerClauses);
        this.offerBinding.chargerClauses = [...this.listchargerClauses];
      }
      if (this.listshipOwnerClauses?.length) {
        this.deleteFieldsInClauses(this.listshipOwnerClauses);
        this.offerBinding.shipOwnerClauses = this.listshipOwnerClauses;
      }
      console.log("offerBindingSend", this.offerBinding);

      this.sendOfferToService();
    } else if (this.isNegotiation) {
      if (!this.listchargerClauses?.length && this.globals.currentCharger) {
        this.isLoading = false;
        this.messageService.showToast(
          "INFO",
          "MESSAGES.INFO.CLAUSES_LIST_EMPTY",
          "warning",
          ""
        );
        this.spinner.hide();
      } else {
        if (this.showDivClauses) {
          this.offerBinding.chargerClauses = this.listchargerClauses;
          console.log("bindingClauses", this.offerBinding.chargerClauses);
        }

        if (this.listchargerClauses?.length && !this.showDivClauses) {
          this.filterClauses("chargerClauses", UserType.CHARGER);
        }
        if (this.listshipOwnerClauses?.length) {
          this.filterClauses("shipOwnerClauses", UserType.SHIPOWNER);
        }
        console.log("offerBindingSend", this.offerBinding);

        // approvedStatus
        this.isApprovedOfferToSend = true;
        this.isApprovedOfferChangeToRecap =
          this.offerBinding.counter > 0 || this.isCounter0shipOwner;
        this.checkApprovedFields();

        const isValidForm = this.isApprovedOfferToSend && this.form.valid;

        console.log(this.isApprovedOfferToSend);
        if (isValidForm) {
          if (this.globals.currentShipOwner && this.isNegotiation) {
            this.offerBinding.counter++;
            console.log("counter", this.offerBinding.counter);
          }

          this.sendOfferToService();
        } else if (!this.isApprovedOfferToSend) {
          this.isLoading = false;
          this.messageService.showToast(
            "INFO",
            "MESSAGES.INFO.INFO_OFFER_INCOMPLETE",
            "warning",
            ""
          );
          this.spinner.hide();
          this.isLoading = false;
        } else {
          this.isLoading = false;
          const errorMsg = this.form.get("expirationDate").errors?.[
            "expirationDate"
          ]
            ? "MESSAGES.INFO.EXPIRATION_DATE_MINOR"
            : "MESSAGES.VALIDATION.FORM_ERRORS";

          this.messageService.showToast("INFO", errorMsg, "warning", "");
          this.spinner.hide();
          this.isLoading = false;
        }
      }
    }
  }
  checkApprovedFields() {
    Object.entries(this.offerBinding).forEach(([key, value]) => {
      if (key.includes("State")) {
        if (!this.approvedStatus.includes(value)) {
          this.isApprovedOfferToSend = false;
          //  console.log('FALSE', key, value);
        }
        if (!this.approvedStatusForRecap.includes(value)) {
          this.isApprovedOfferChangeToRecap = false;
          //  console.log('FALSERECAP', key, value);
        }
      }
      // }
    });
    this.reviewClauses("chargerClauses");
    this.reviewClauses("shipOwnerClauses");
    if (!this.listchargerClauses.length && !this.listshipOwnerClauses.length) {
      this.isApprovedOfferChangeToRecap = false;
    }
    if (
      this.isCounter0shipOwner &&
      this.form.get("initialShipOwnerClauses").value
    ) {
      this.isApprovedOfferChangeToRecap = false;
    }
  }
  assignShift() {
    if (this.globals.currentCharger) {
      this.offerBinding.shift = UserType.SHIPOWNER;
    } else if (this.globals.currentShipOwner) {
      this.offerBinding.shift =
        this.isCounter0shipOwner &&
          this.form.get("initialShipOwnerClauses").value
          ? UserType.ADMIN
          : UserType.CHARGER;
    }
    console.log("SHIFT", this.offerBinding.shift);
  }
  sendOfferToService() {
    this.assignShift();
    let redirectToRecap = false;
    if (
      this.isApprovedOfferChangeToRecap &&
      (this.offerBinding.state == StateOfferShipOwner.CHARTERER_COUNTER_OFFER ||
        this.offerBinding.state ==
        StateOfferShipOwner.SHIPOWNER_COUNTER_OFFER ||
        this.offerBinding.state == StateOfferShipOwner.AGREEMENT_STARTED ||
        this.offerBinding.state == StateOfferShipOwner.AGREEMENT_VALIDATED)
    ) {
      this.offerBinding.state = StateOfferShipOwner.RECAP;
      redirectToRecap = true;
    }
    console.log(this.isRecap);
    this.offerService.createShipOwnerNegotiation(this.offerBinding).subscribe({
      next: () => {
        this.spinner.hide();
        this.sendMail(redirectToRecap);
        let subscriptionChangeState;
        let subscriptionChangeStateOffer;

        const offerShipownerState = new OfferShipOwnerState();

        //if (this.isApprovedOfferChangeToRecap && this.offerBinding.counter >= 1) {
        //  //Change status to Recap in offer
        //  offerShipownerState.offerShipOwnerId = this.offerBinding.offerShipOwnerId;
        //  offerShipownerState.state = StateOfferShipOwner.RECAP;
        //  subscriptionChangeState = this.offerService.changeStateInOfferShipOwnner(offerShipownerState);
        //} else {
        //  if (this.isCounter0shipOwner) {
        //    offerShipownerState.offerShipOwnerId = this.offerBinding.offerShipOwnerId;
        //    offerShipownerState.state = StateOfferShipOwner.COUNTER;
        //    subscriptionChangeState = this.offerService.changeStateInOfferShipOwnner(offerShipownerState);
        //  } else if (this.isFirstStepCharterer) {
        //    offerShipownerState.offerShipOwnerId = this.offerBinding.offerShipOwnerId;
        //    offerShipownerState.state = StateOfferShipOwner.FIRM_OFFER;
        //    subscriptionChangeState = this.offerService.changeStateInOfferShipOwnner(offerShipownerState);

        //    var offerState = new OfferStateDto();
        //    offerState.offerId = this.offerBinding.offerId;
        //    offerState.state = OfferState.NEGOTIATION;
        //    subscriptionChangeStateOffer = this.offerService.updateState(offerState);
        //  }
        //}
        if (subscriptionChangeState) {
          // let index = 0;
          // subscriptionsChangeState.forEach(subs => {
          subscriptionChangeState.subscribe({
            next: () => {
              if (subscriptionChangeStateOffer) {
                subscriptionChangeStateOffer.subscribe({
                  next: () => {
                    if (this.sendMailToUser) {
                      this.router.navigate(["home"]);
                      this.isLoading = false;
                    }
                  },
                  error: (err) => {
                    console.error(err);
                  },
                });
              } else {
                if (this.sendMailToUser) {
                  this.router.navigate(["home"]);
                  this.isLoading = false;
                }
              }

              //this.router.navigate(['home']);
            },
            error: (err) => {
              console.error(err);
            },
          });
          // });
        }
      },
      error: (err) => {
        console.log("errorNegotiation", err);
        this.spinner.hide();
        this.isLoading = false;
      },
    });
  }
  reviewClauses(key: string) {
    this.offerBinding[key].forEach((element) => {
      if (!this.approvedStatus.includes(element.state)) {
        this.isApprovedOfferToSend = false;
        console.log("FALSE", element);
      }
      if (!this.approvedStatusForRecap.includes(element.state)) {
        this.isApprovedOfferChangeToRecap = false;
        console.log("FALSERECAP", key, element.state);
      }
    });
  }
  sendMail(redirectToRecap = false) {
    let type = "";
    this.spinner.show();
    const emailDto = new MailDTO();
    emailDto.offerShipOwnerId = this.offerBinding.offerShipOwnerId;
    this.offerBinding["termsConditionsEs"] =
      this.globals.termsConditions?.terms;
    this.offerBinding["termsConditionsEn"] =
      this.globals.termsConditions.termsEN;
    this.offerBinding["legalAdviceEs"] = Constants.LEGAL_ADVICE.es;
    this.offerBinding["legalAdviceEn"] = Constants.LEGAL_ADVICE.en;
    this.offerBinding["dataProtectionEs"] =
      this.globals.dataProtection.policy + Constants.DATA_PROTECTION.es;
    this.offerBinding["dataProtectionEn"] =
      this.globals.dataProtection.policyEN + Constants.DATA_PROTECTION.en;
    this.offerBinding["userId"] = encodeURIComponent(
      this.utils.encrypt(this.globals.user.id)
    );
    this.offerBinding["tenantId"] = encodeURIComponent(
      this.utils.encrypt(
        this.globals.user.mainTenantId || this.globals.user.tenants[0].id
      )
    );
    this.offerBinding["shipownerId"] = encodeURIComponent(
      this.utils.encrypt(this.offerBinding.shipOwnerId)
    );

    if (this.globals.currentCharger) {
      //REMEMBER Commented there is no email  acknowledgment of receipt for loaded or clhCharger indicated by CLH 1/6/23
      if (this.isFirstStepCharterer) {
        // if (this.sendMailResponse) {
        //   //envio de email de acuse de recibo al cargador
        //   emailDto.addresseeType = AddresseeType.CHARGER;
        //   type = MailTemplateTypes.OFFER_FIRM_CHARGER;
        // } else {

        //envio de email de cargador a armador
        emailDto.addresseeType = AddresseeType.SHIPOWNER;
        type = this.isCLHRepresentingCharger
          ? MailTemplateTypes.OFFER_FIRM_CHARGER_RESPONSE_CLH
          : MailTemplateTypes.OFFER_FIRM_CHARGER_RESPONSE;

        // }
      } else if (this.isNegotiation) {
        if (this.offerBinding.state === StateOfferShipOwner.RECAP) {
          if (this.sendMailResponse) {
            //Oferta Firme Recap: Aviso Recap Confirmar para cargador
            emailDto.addresseeType = AddresseeType.CHARGER;
            type = MailTemplateTypes.OFFER_FIRM_RECAP_CONFIRM_CHARGER;
          } else {
            //Oferta Firme Recap: Aviso Recap Confirmar para armador
            emailDto.addresseeType = AddresseeType.SHIPOWNER;
            type = MailTemplateTypes.OFFER_FIRM_RECAP_CONFIRM_SHIPOWNER;
          }
        } else {
          // if (this.sendMailResponse) {
          //   //envio de email de acuse de recibo al cargador
          //   emailDto.addresseeType = AddresseeType.CHARGER;
          //   type = MailTemplateTypes.OFFER_FIRM_COUNTER_CHARGER;
          // } else {
          //envio de email de cargador a armadors
          emailDto.addresseeType = AddresseeType.SHIPOWNER;
          type = this.isCLHRepresentingCharger
            ? MailTemplateTypes.OFFER_FIRM_COUNTER_CHARGER_RESPONSE_CLH
            : MailTemplateTypes.OFFER_FIRM_COUNTER_CHARGER_RESPONSE;
          // }
        }
      } else if (this.isRecap) {
        if (this.offerBinding.state === StateOfferShipOwner.RECAP) {
          if (this.sendMailResponse) {
            emailDto.addresseeType = AddresseeType.CHARGER;
            type =
              MailTemplateTypes.OFFER_FIRM_RECAP_NOTICE_CONFIRM_ACKNOWLEDGMENT_CHARGER;
            this.isConfirmed = false;
          } else {
            emailDto.addresseeType = AddresseeType.SHIPOWNER;
            type = MailTemplateTypes.OFFER_FIRM_RECAP_NOTICE_CONFIRM_SHIPOWNER;
            this.isConfirmed = true;
          }
        }
        if (
          this.offerBinding.state ===
          StateOfferShipOwner.RECAP_CHARTERER_PENDING &&
          this.globals.currentCharger
        ) {
          if (this.sendMailResponse) {
            emailDto.addresseeType = AddresseeType.CHARGER;
            type = MailTemplateTypes.OFFER_FIRM_RECAP_CHARGER;
            this.isConfirmed = true;
          } else {
            emailDto.addresseeType = AddresseeType.SHIPOWNER;
            type = MailTemplateTypes.OFFER_FIRM_RECAP_SHIPOWNER;
            this.isConfirmed = false;
          }
        }
      }
    } else if (this.globals.currentShipOwner) {
      this.offerBinding["chargerId"] = encodeURIComponent(
        this.utils.encrypt(this.offerBinding.chargerId)
      );
      if (this.isCounter0shipOwner) {
        if (!this.form.get("initialShipOwnerClauses").value) {
          //enviamos directamente counter a cargador si el armador no sube cláusulas
          if (this.sendMailResponse) {
            //envio de email de armador  a cargador
            emailDto.addresseeType = AddresseeType.CHARGER;
            type = MailTemplateTypes.OFFER_FIRM_COUNTER_SHIPOWNER_RESPONSE;
          } else {
            //envio de email de acuse de recibo al armador
            emailDto.addresseeType = AddresseeType.SHIPOWNER;
            type = MailTemplateTypes.OFFER_FIRM_COUNTER_SHIPOWNER;
          }
        } else {
          // envia el recibo de acuse al armador
          const emailToShipOwnerDto = new MailDTO();
          emailToShipOwnerDto.addresseeType = AddresseeType.SHIPOWNER;
          emailToShipOwnerDto.offerShipOwnerId =
            this.offerBinding.offerShipOwnerId;
          type = MailTemplateTypes.OFFER_FIRM_COUNTER_SHIPOWNER;
          this.sendMailService(emailToShipOwnerDto, type, redirectToRecap);
          emailDto.addresseeType = AddresseeType.ADMIN;
          type = MailTemplateTypes.OFFER_FIRM_CLAUSES_CLH;
        }

        //envio de email la primera vez que el armador entra oferta binding
      } else if (this.isNegotiation) {
        if (this.offerBinding.state === StateOfferShipOwner.RECAP) {
          if (this.sendMailResponse) {
            //Oferta Firme Recap: Aviso Recap Confirmar para cargador
            emailDto.addresseeType = AddresseeType.CHARGER;
            type = MailTemplateTypes.OFFER_FIRM_RECAP_CONFIRM_CHARGER;
          } else {
            //Oferta Firme Recap: Aviso Recap Confirmar para armador
            emailDto.addresseeType = AddresseeType.SHIPOWNER;
            type = MailTemplateTypes.OFFER_FIRM_RECAP_CONFIRM_SHIPOWNER;
          }
        } else {
          if (this.sendMailResponse) {
            //envio de email de armador  a cargador
            emailDto.addresseeType = AddresseeType.CHARGER;
            type = MailTemplateTypes.OFFER_FIRM_COUNTER_SHIPOWNER_RESPONSE;
          } else {
            //envio de email de acuse de recibo al armador
            emailDto.addresseeType = AddresseeType.SHIPOWNER;
            type = MailTemplateTypes.OFFER_FIRM_COUNTER_SHIPOWNER;
          }
        }
      } else if (this.isRecap) {
        if (this.offerBinding.state === StateOfferShipOwner.RECAP) {
          if (this.sendMailResponse) {
            emailDto.addresseeType = AddresseeType.CHARGER;
            type = MailTemplateTypes.OFFER_FIRM_RECAP_NOTICE_CONFIRM_CHARGER;
            this.isConfirmed = true;
          } else {
            emailDto.addresseeType = AddresseeType.SHIPOWNER;
            type =
              MailTemplateTypes.OFFER_FIRM_RECAP_NOTICE_CONFIRM_ACKNOWLEDGMENT_SHIPOWNER;
            this.isConfirmed = false;
          }
        }
        if (
          this.offerBinding.state ===
          StateOfferShipOwner.RECAP_SHIPOWNER_PENDING &&
          this.globals.currentShipOwner
        ) {
          if (this.sendMailResponse) {
            emailDto.addresseeType = AddresseeType.CHARGER;
            type = MailTemplateTypes.OFFER_FIRM_RECAP_CHARGER;
            this.isConfirmed = false;
          } else {
            emailDto.addresseeType = AddresseeType.SHIPOWNER;
            type = MailTemplateTypes.OFFER_FIRM_RECAP_SHIPOWNER;
            this.isConfirmed = true;
          }
        }
      }
    } else if (this.isClhRevision) {
      //TODO  IMPORTANTE revisar addresseId para clh
      emailDto.addresseeType = AddresseeType.CHARGER;
      type = MailTemplateTypes.OFFER_FIRM_COUNTER_SHIPOWNER_RESPONSE;
    }
    console.log("emaiilDTO", emailDto);
    console.log("type", type);
    this.sendMailService(emailDto, type, redirectToRecap);
  }
  templateClauses() {
    this.offerBinding.chargerClausesText = [];
    this.offerBinding.shipOwnerClausesText = [];
    const listClauseChargerText = [];
    if (this.offerBinding.chargerClauses?.length) {
      this.offerBinding.chargerClauses.forEach((clause) => {
        const clauseTemplate = { ...clause };
        clauseTemplate.value = clause.isRefused
          ? "<li><del>" + clause.value + "</del></li>"
          : "<li>" + clause.value + "</li>";
        listClauseChargerText.push(clauseTemplate.value);
      });
      this.offerBinding.chargerClausesText = listClauseChargerText;
      console.log("clausesC", listClauseChargerText);
    }
    if (!this.isFirstStepCharterer) {
      if (this.offerBinding.shipOwnerClauses?.length) {
        const listClauseShipOwnerText = [];
        this.offerBinding.shipOwnerClauses.forEach((clause) => {
          const clauseTemplate = { ...clause };
          clauseTemplate.value = clause.isRefused
            ? "<li><del>" + clause.value + "</del></li>"
            : "<li>" + clause.value + "</li>";
          listClauseShipOwnerText.push(clauseTemplate.value);
        });
        this.offerBinding.shipOwnerClausesText = listClauseShipOwnerText;
        console.log("clausesS", listClauseShipOwnerText);
        this.offerBinding.countlistClausesText =
          listClauseChargerText.length + 18;
      }
    }
  }
  sendMailService(emailDto: MailDTO, type, redirectToRecap = false) {
    this.messageTemplateService.readAll().subscribe({
      next: (templates) => {
        this.mailTemplates = templates;
        emailDto.templateName = type;
        this.template = templates.find((x) => x.name == type);

        if (!this.sendMailResponse) {
          this.templateClauses();
          console.log(
            this.offerBinding.chargerClausesText,
            this.offerBinding.shipOwnerClausesText
          );
        }
        if (this.sendMailResponse && this.isFirstStepCharterer) {
          this.templateClauses();
          console.log(
            this.offerBinding.chargerClausesText,
            this.offerBinding.shipOwnerClausesText
          );
        }
        this.offerBinding["dateRecap"] = new Date(
          new Date().getUTCFullYear(),
          new Date().getUTCMonth(),
          new Date().getUTCDate(),
          new Date().getUTCHours(),
          new Date().getUTCMinutes()
        );
        console.log("offerToemail", this.offerBinding);
        emailDto.body = mapTemplate(this.template.content, this.offerBinding);
        emailDto.subject = mapTemplate(
          this.template.subject,
          this.offerBinding
        );
        emailDto.templateName = type;
        console.log("emailDto", emailDto);

        this.mailTemplateService.sendMail(emailDto).subscribe({
          next: () => {
            this.spinner.hide();
            //REMEMBER Commented there is no email  acknowledgment of receipt for loaded or clhCharger indicated by CLH 1/6/23
            if (
              this.isFirstStepCharterer ||
              this.isCounter0shipOwner ||
              this.isClhRevision
            ) {
              //solo puede entrar por aqui si es la primera vez de cargador o armador o clh
              // if (this.isFirstStepCharterer && !this.sendMailResponse) {
              //   //para enviar el acuse de recibo al cargador
              //   this.sendMailResponse = true;
              //   this.sendMail();
              // } else {
              this.noConfirmationServiceToExit = true;
              console.log("sendmailTouser", this.sendMailToUser);
              this.sendMailToUser = true;
              this.messageService.showToast(
                "INFO",
                "MESSAGES.SUCCESS.UPDATE",
                "success",
                ""
              );
              if (!redirectToRecap) {
                this.router.navigate(["home"]);
              } else {
                const currentUrl = this.router.url;
                this.router
                  .navigateByUrl("/", { skipLocationChange: true })
                  .then(() => {
                    this.router.navigateByUrl(currentUrl);
                  });
              }
              // }
            } else if (this.isNegotiation) {
              if (
                (!this.sendMailResponse && this.globals.currentShipOwner) ||
                (!this.sendMailResponse &&
                  this.offerBinding.state === StateOfferShipOwner.RECAP &&
                  this.globals.currentCharger)
              ) {
                //poner sendMailResponse para enviar el email de respuesta
                this.sendMailResponse = true;
                this.sendMail(redirectToRecap);
              } else {
                //ultimo paso despues de enviar el acuse de recibo y el de respuesta
                this.sendMailToUser = true;
                this.messageService.showToast(
                  "INFO",
                  "MESSAGES.SUCCESS.UPDATE",
                  "success",
                  ""
                );
                if (!redirectToRecap) {
                  this.router.navigate(["home"]);
                } else {
                  const currentUrl = this.router.url;
                  this.router
                    .navigateByUrl("/", { skipLocationChange: true })
                    .then(() => {
                      this.router.navigateByUrl(currentUrl);
                    });
                }
              }
            } else if (this.isRecap) {
              if (this.isConfirmed) {
                //poner sendMailResponse para enviar el email de respuesta
                this.sendMailResponse = true;
                this.sendMail(redirectToRecap);
              } else {
                //ultimo paso despues de enviar el acuse de recibo y el de respuesta
                this.sendMailToUser = true;
                this.messageService.showToast(
                  "INFO",
                  "MESSAGES.SUCCESS.UPDATE",
                  "success",
                  ""
                );
                if (!redirectToRecap) {
                  this.router.navigate(["home"]);
                } else {
                  const currentUrl = this.router.url;
                  this.router
                    .navigateByUrl("/", { skipLocationChange: true })
                    .then(() => {
                      this.router.navigateByUrl(currentUrl);
                    });
                }
              }
            }
          },
          error: (err) => {
            console.error(err);
            this.sendMailToUser = false;
            this.spinner.hide();
            this.messageService.showToast(
              "ERROR",
              "MESSAGES.ERROR.NO_SEND_EMAIL",
              "error",
              ""
            );
          },
        });
      },
      error: (err) => {
        this.spinner.hide();
        console.error(err);
        this.messageService.showToast(
          "ERROR",
          "MESSAGES.ERROR.BAD_REQUEST_BODY",
          "error",
          ""
        );
      },
    });
  }
  convertStringToClauseNegotiation(list: any[], type?: string) {
    const listLength = this.listchargerClauses?.length
      ? this.listchargerClauses.length
      : 0;
    const listLengthShipowner = this.listshipOwnerClauses?.length
      ? this.listshipOwnerClauses.length
      : 0;
    let index, indexShipowner;
    index =
      listLength > 0
        ? this.listchargerClauses[listLength - 1].indexList + 1
        : 0;
    indexShipowner =
      listLengthShipowner > 0
        ? this.listshipOwnerClauses[listLengthShipowner - 1].indexList + 1
        : 0;

    console.log(index, indexShipowner, list);
    list.forEach((item) => {
      const clauseNegotiation = new ClauseNegotiation();
      console.log(typeof item);
      if (typeof item === "string") {
        clauseNegotiation.value = item;
        clauseNegotiation.isDuplicate = true;
      } else {
        clauseNegotiation.value = item.description;
        clauseNegotiation.isDefault = true;
      }
      clauseNegotiation.state = NegotiationFieldState.SHIPOWNER_PENDING;
      clauseNegotiation.isReadOnly = true;
      clauseNegotiation.isEditClause = false;
      if (index == 0 && indexShipowner > 0) {
        index = indexShipowner;
      }
      clauseNegotiation.indexList =
        type === "shipowner" ? indexShipowner : index;
      clauseNegotiation.nameControl =
        type === "shipowner"
          ? "shipOwnerClauses".concat(indexShipowner.toString())
          : "chargerClauses".concat(index.toString());
      console.log(clauseNegotiation);

      if (type === "shipowner") {
        clauseNegotiation.userType = UserType.SHIPOWNER;
        this.listshipOwnerClauses.push(clauseNegotiation);
        indexShipowner++;
      } else {
        clauseNegotiation.userType = UserType.CHARGER;
        this.listchargerClauses.push(clauseNegotiation);
        index++;
      }

      this.form.addControl(
        clauseNegotiation.nameControl,
        new FormControl(clauseNegotiation.value, [Validators.required])
      );
    });
  }
  converToEnum(formValue: any) {
    Object.keys(formValue).forEach((ctrl) => {
      if (ctrl === "freightTargetCurrency") {
        if (typeof formValue[ctrl] != "number") {
          formValue[ctrl] = FreightTargetCurrency[formValue[ctrl]];
        }
      } else if (ctrl === "freightTargetType") {
        if (typeof formValue[ctrl] != "number") {
          formValue[ctrl] = FreightTargetType[formValue[ctrl]];
        }
      } else if (ctrl === "demurrageCurrency") {
        if (typeof formValue[ctrl] != "number") {
          formValue[ctrl] = FreightTargetCurrency[formValue[ctrl]];
        }
      } else if (ctrl === "demurrageType") {
        if (typeof formValue[ctrl] != "number") {
          formValue[ctrl] = DemurrageType[formValue[ctrl]];
        }
      } else if (ctrl === "quantityType") {
        if (typeof formValue[ctrl] != "number") {
          formValue[ctrl] = QuantityType[formValue[ctrl]];
        }
      }
    });
  }

  changeLabels(change: boolean) {
    this.offerBinding.quantityFieldShowLabel = null;
    this.offerBinding.loadingPortsShowLabel = null;
    this.offerBinding.loadingPortConditionsShowLabel = null;
    this.offerBinding.dischargingPortsShowLabel = null;
    this.offerBinding.dischargingPortConditionsShowLabel = null;
    this.offerBinding.layCanShowLabel = null;
    this.offerBinding.layTimeTermShowLabel = null;
    this.offerBinding.chargeringTermShowLabel = null;
    this.offerBinding.cpFormShowLabel = null;
    this.offerBinding.restrictionsRegardingShowLabel = null;
    this.offerBinding.aditionalRequirementsShowLabel = null;
    this.offerBinding.freightShowLabel = null;
    this.offerBinding.demurrageShowLabel = null;
    this.offerBinding.demurrageConditionsShowLabel = null;
    this.offerBinding.vesselNameShowLabel = null;
    this.offerBinding.remarksShowLabel = null;
    this.offerBinding.addressCommShowLabel = null;

    for (const key in this.offerBinding) {
      if (key.includes("ShowLabel")) {
        this.offerBinding[key] = change;
      }
    }
  }

  onApproveField(event, clause?: ClauseNegotiation) {
    if (
      event.field === "chargerClauses" ||
      event.field === "shipOwnerClauses"
    ) {
      this.nextStatusApprovedInClause(clause);
    } else {
      //if(this.offerBinding[event.field]){
      if (this.offerBinding.hasOwnProperty(event.field + "State")) {
        this.offerBinding[event.field + "State"] = this.nextStatusApproved(
          event.field
        );
        this.offerBinding[event.field + "ShowLabel"] = true;
      } else {
        console.log("approve not implemented", event, clause);
      }
      // }
    }
  }

  onUndoField(event, clause?: ClauseNegotiation) {
    console.log("undo", event);
    if (event.field === "chargerClauses") {
      clause.value = clause.valueInitial;
      //clause.state = clause.stateInitial;
      clause.isRefused = clause.refusedInitial;
      //clause.isReadOnly = true;
      this.form.get(clause.nameControl).setValue(clause.value);

      return;
    } else if (event.field === "shipOwnerClauses") {
      clause.value = clause.valueInitial;
      //  clause.state = clause.stateInitial;
      clause.isRefused = clause.refusedInitial;
      //clause.isReadOnly = true;
      this.form.get(clause.nameControl).setValue(clause.value);
      return;
    }

    if (this.offerBinding.hasOwnProperty(event.field)) {
      this.offerBinding[event.field] = this.offerBindingInitial[event.field];
      // this.offerBinding[event.field + 'ShowLabel'] = true;
      // this.offerBinding[event.field + 'State'] = this.offerBindingInitial[event.field + 'State'];
      this.form
        .get(event.field)
        .setValue(this.offerBindingInitial[event.field]);
      if (event.field == "loadingPorts") {
        this.ports = [...this.ports];
      } else if (event.field == "dischargingPorts") {
        this.portsD = [...this.portsD];
      }
      return;
    } else if (event.field == "layCan") {
      this.form
        .get("rangeDates")
        .setValue([
          new Date(this.offerBindingInitial.layCanFrom),
          new Date(this.offerBindingInitial.layCanTo),
        ]);
      this.offerBinding.layCanFrom = this.offerBindingInitial.layCanFrom;
      this.offerBinding.layCanTo = this.offerBindingInitial.layCanTo;
      // this.offerBinding[event.field + 'ShowLabel'] = true;
      // this.offerBinding[event.field + 'State'] = this.offerBindingInitial[event.field + 'State'];
      return;
    } else if (event.field == "freight") {
      this.offerBinding.freightTargetQuantity =
        this.offerBindingInitial.freightTargetQuantity;
      this.offerBinding.freightTargetType =
        this.offerBindingInitial.freightTargetType;
      this.offerBinding.freightTargetCurrency =
        this.offerBindingInitial.freightTargetCurrency;
      this.form
        .get("freightTargetQuantity")
        .setValue(this.offerBinding.freightTargetQuantity);
      this.form
        .get("freightTargetType")
        .setValue(FreightTargetType[this.offerBinding.freightTargetType]);
      this.form
        .get("freightTargetCurrency")
        .setValue(
          FreightTargetCurrency[this.offerBinding.freightTargetCurrency]
        );
    } else if (event.field == "demurrage") {
      this.offerBinding.demurrageQuantity =
        this.offerBindingInitial.demurrageQuantity;
      this.offerBinding.demurrageType = this.offerBindingInitial.demurrageType;
      this.offerBinding.demurrageCurrency =
        this.offerBindingInitial.demurrageCurrency;
      this.form
        .get("demurrageQuantity")
        .setValue(this.offerBinding.demurrageQuantity);
      this.form
        .get("demurrageType")
        .setValue(DemurrageType[this.offerBinding.demurrageType]);
      this.form
        .get("demurrageCurrency")
        .setValue(FreightTargetCurrency[this.offerBinding.demurrageCurrency]);
    } else if (event.field == "quantityField") {
      this.offerBinding.quantity = this.offerBindingInitial.quantity;
      this.offerBinding.quantityType = this.offerBindingInitial.quantityType;
      this.offerBinding.commodity = this.offerBindingInitial.commodity;
      this.offerBinding.stowage = this.offerBindingInitial.stowage;
      this.form.get("quantity").setValue(this.offerBinding.quantity);
      this.form
        .get("quantityType")
        .setValue(QuantityType[this.offerBinding.quantityType]);
      this.form.get("commodity").setValue(this.offerBinding.commodity);
      this.form.get("stowage").setValue(this.offerBinding.stowage);
    }
  }

  onApproveFieldOld(event, clause?: ClauseNegotiation) {
    //isApproved significa que le dio a ok sin darle antes a editar
    // console.log('formValueApprove', this.form.value);
    // console.log(event);
    // console.log('clause', clause);
    // console.log('this', this);
    // if (event.field === 'chargerClauses' || event.field === 'shipOwnerClauses') {
    //   clause.isReadOnly = true;
    //   if (!event.isApproved) {
    //     this.nextStatusApprovedInClause(clause, true);
    //     clause.value = this.form.get(clause.nameControl).value;
    //   } else {
    //     this.nextStatusApprovedInClause(clause, false);
    //     if (event.isDeleted) {
    //       clause.approvedRefused = true;
    //     }
    //     if (event.isDeletedInitial && !clause.isRefused) {
    //       this.nextPendingClauses(clause);
    //     }
    //   }
    //   console.log('clause', clause);
    //   setTimeout(() => {
    //     this.calculateHeightTextArea();
    //   }, 1000);
    // } else {
    //   //TODO considerar userType
    //   this.offerBinding[event.field + 'ShowLabel'] = true;
    //   if (!event.isApproved && this.offerBindingInitial[event.field] != this.form.get(event.field).value) {
    //     //this.offerBinding[event.field] = this.form.get(event.field);
    //     if (event.field == 'loadingPorts') {
    //       this.selectedLoadingPorts = this.form.get(event.field).value;
    //       //this.selectedLoadingPortChange(this.offerBinding[event.field]);
    //     }
    //     else if (event.field == 'dischargingPorts') {
    //       this.selectedDischargingPorts = this.form.get(event.field).value;
    //       // this.selectedDischargingPortChange(this.offerBinding[event.field]);
    //     }
    //     //modificamos. No solamente validamos
    //     //TODO: REVISAR
    //     if (this.form.get(event.field)) {
    //       this.offerBinding[event.field] = this.form.get(event.field).value;
    //     }
    //     else {
    //       const formValue = { ...this.form.value };
    //       if (event.field == 'layCan') {
    //         this.offerBinding.layCanFrom = formValue.rangeDates[0] ? new Date(Date.UTC(formValue.rangeDates[0].getFullYear(), formValue.rangeDates[0].getMonth(), formValue.rangeDates[0].getDate())) : this.offerBinding.layCanFrom;
    //         this.offerBinding.layCanTo = formValue.rangeDates[1] ? new Date(Date.UTC(formValue.rangeDates[1].getFullYear(), formValue.rangeDates[1].getMonth(), formValue.rangeDates[1].getDate())) : this.offerBinding.layCanTo;
    //       }
    //       else if (event.field == 'freight') {
    //         this.offerBinding.freightTargetCurrency = this.form.get('freightTargetCurrency').value;
    //         this.offerBinding.freightTargetType = this.form.get('freightTargetType').value;
    //         this.offerBinding.freightTargetQuantity = this.form.get('freightTargetQuantity').value;
    //       }
    //       else if (event.field == 'demurrage') {
    //         this.offerBinding.demurrageCurrency = this.form.get('demurrageCurrency').value;
    //         this.offerBinding.demurrageType = this.form.get('demurrageType').value;
    //         this.offerBinding.demurrageQuantity = this.form.get('demurrageQuantity').value;
    //       }
    //     }
    //     this.offerBinding[event.field + 'State'] = this.nextStatusApproved(event.field, true);
    //   }
    //   else {
    //     this.offerBinding[event.field + 'State'] = this.nextStatusApproved(event.field, false);
    //   }
    //   console.log('OFFER', this.offerBinding);
    // }
  }
  reopenField(event, clause?: ClauseNegotiation) {
    console.log(event);
    if (
      event.field === "chargerClauses" ||
      event.field === "shipOwnerClauses"
    ) {
      if (clause) {
        clause.isReadOnly = false;
      }
    } else {
      this.offerBinding[event.field + "ShowLabel"] = false;
    }
    // this.form.get(field)?.enable();
  }

  setEditable(field: string, editable: boolean, clause?: ClauseNegotiation) {
    console.log(editable);
    // console.log('formValueUndo', this.form.value);
    // console.log(field);
    if (!field.includes("clause")) {
      this.offerBinding[field + "ShowLabel"] = !editable;
      if (editable && this.form.get(field)) {
        this.form.get(field).setValue(this.offerBinding[field]);
      }
      //TODO igual hai que cambiar estado.
      //this.offerBinding[field + 'State'] = this.nextStatusPending(field);
    } else {
      let indice;

      if (clause.userType === UserType.CHARGER) {
        indice = this.listchargerClauses.indexOf(clause);
        this.listchargerClauses[indice].isReadOnly = !editable;
        // this.form.get(clause.nameControl).setValue(clause.value);
        this.nextStatusPendingInClause(clause);
        // console.log('clause', clause);
      }
      if (clause.userType === UserType.SHIPOWNER) {
        indice = this.listshipOwnerClauses.indexOf(clause);
        this.listshipOwnerClauses[indice].isReadOnly = !editable;
        // this.form.get(clause.nameControl).setValue(clause.value);
        //this.nextStatusPendingInClause(clause);
        // console.log('clause', clause);
      }
    }
  }
  saveField(event, clause?: ClauseNegotiation) {
    console.log("save", event);

    if (event.hadReopened) {
      console.log("clausula reabierta con cambios");
      if (
        this.offerBinding.hasOwnProperty(event.field) &&
        event.field != "chargerClauses" &&
        event.field != "shipOwnerClauses"
      ) {
        this.offerBinding[event.field] = this.form.get(event.field).value;
      } else {
        if (
          event.field === "chargerClauses" ||
          event.field === "shipOwnerClauses"
        ) {
          clause.value = this.form.get(clause.nameControl).value;
          clause.isReadOnly = true;
          this.nextStatusPendingReopenedInClause(clause);
        }
      }
      if (this.offerBinding.hasOwnProperty(event.field + "State")) {
        this.offerBinding[event.field + "State"] =
          this.nextStatusPendingReopened(event.field);
        this.offerBinding[event.field + "ShowLabel"] = true;
      }
    } else {
      console.log("clausula sin reabrir");

      if (
        event.field === "chargerClauses" ||
        event.field === "shipOwnerClauses"
      ) {
        if (event.hadChanged) {
          clause.value = this.form.get(clause.nameControl).value;
          clause.isReadOnly = true;
          this.nextStatusPendingInClause(clause);
        } else {
          clause.state = clause.stateInitial;
          clause.value = clause.valueInitial;
          clause.isReadOnly = true;
          clause.isRefused = clause.refusedInitial;
        }
      } else if (this.offerBinding.hasOwnProperty(event.field)) {
        if (event.hadChanged) {
          this.offerBinding[event.field] = this.form.get(event.field).value;

          if (this.offerBinding.hasOwnProperty(event.field + "State")) {
            this.offerBinding[event.field + "State"] = this.nextStatusPending(
              event.field
            );
            this.offerBinding[event.field + "ShowLabel"] = true;
          }
        } else {
          this.offerBinding[event.field + "State"] =
            this.offerBindingInitial[event.field + "State"];
          this.offerBinding[event.field] =
            this.offerBindingInitial[event.field];
          this.offerBinding[event.field + "ShowLabel"] = true;
        }
      }
      // else  if (event.field === 'chargerClauses' || event.field === 'shipOwnerClauses') {

      // }
    }
    if (event.hadChanged || event.hadReopened) {
      if (!event.hadReopened) {
        console.log("!reopened");
        if (this.offerBinding.hasOwnProperty(event.field + "State")) {
          this.offerBinding[event.field + "State"] = this.nextStatusPending(
            event.field
          );
          this.offerBinding[event.field + "ShowLabel"] = true;
        }
      } else {
        if (this.offerBinding.hasOwnProperty(event.field + "State")) {
          this.offerBinding[event.field + "State"] =
            this.nextStatusPendingReopened(event.field);
          this.offerBinding[event.field + "ShowLabel"] = true;
        }
      }
      if (event.field == "freight") {
        // if(this.buttonsDemurrage){
        //   console.log(this.buttonsDemurrage);
        // }
        this.offerBinding.freightTargetQuantity = this.form.get(
          "freightTargetQuantity"
        ).value;
        this.offerBinding.freightTargetType =
          this.form.get("freightTargetType").value;
        this.offerBinding.freightTargetCurrency = this.form.get(
          "freightTargetCurrency"
        ).value;
        /*
                if(event.changeFreightTargetCurrency){
                  if (this.offerBinding.hasOwnProperty('demurrageState')) {
                    this.buttonsDemurrage.hadChanged = event.hadChanged;
                    this.buttonsDemurrage.hadReopened = event.hadReopened;
                    this.buttonsDemurrage.isApproved = false;
                    // this.offerBinding['demurrageState'] =  this.offerBinding.freightState;
                    // this.offerBinding['demurrageShowLabel'] = true;
                    // console.log(this.offerBinding['demurrageState']);
                  }
                }*/
      } else if (event.field == "demurrage") {
        this.offerBinding.demurrageQuantity =
          this.form.get("demurrageQuantity").value;
        this.offerBinding.demurrageType = this.form.get("demurrageType").value;
        this.offerBinding.demurrageCurrency = this.form.get(
          "freightTargetCurrency"
        ).value;
      } else if (event.field == "layCan") {
        this.offerBinding.layCanFrom = this.form.get("rangeDates").value[0];
        this.offerBinding.layCanTo = this.form.get("rangeDates").value[1];
      } else if (event.field == "quantityField") {
        this.offerBinding.quantity = this.form.get("quantity").value;
        this.offerBinding.quantityType = this.form.get("quantityType").value;
        this.offerBinding.commodity = this.form.get("commodity").value;
        this.offerBinding.stowage = this.form.get("stowage").value;
      }
    } else {
      if (this.offerBinding.hasOwnProperty(event.field + "State")) {
        this.offerBinding[event.field + "State"] =
          this.offerBindingInitial[event.field + "State"];
        this.offerBinding[event.field + "ShowLabel"] = true;
      }
      if (event.field == "freight") {
        this.offerBinding.freightTargetQuantity =
          this.offerBindingInitial.freightTargetQuantity;
        this.offerBinding.freightTargetType =
          this.offerBindingInitial.freightTargetType;
        this.offerBinding.freightTargetCurrency =
          this.offerBindingInitial.freightTargetCurrency;
      } else if (event.field == "demurrage") {
        this.offerBinding.demurrageQuantity =
          this.offerBindingInitial.demurrageQuantity;
        this.offerBinding.demurrageType =
          this.offerBindingInitial.demurrageType;
        this.offerBinding.demurrageCurrency =
          this.offerBindingInitial.demurrageCurrency;
      } else if (event.field == "layCan") {
        this.offerBinding.layCanFrom = this.offerBindingInitial.layCanFrom;
        this.offerBinding.layCanTo = this.offerBindingInitial.layCanTo;
      } else if (event.field == "quantityField") {
        this.offerBinding.quantity = this.offerBindingInitial.quantity;
        this.offerBinding.quantityType = this.offerBindingInitial.quantityType;
        this.offerBinding.commodity = this.offerBindingInitial.commodity;
        this.offerBinding.stowage = this.offerBindingInitial.stowage;
      }
    }
    if (event.field == "loadingPorts") {
      this.ports = this.allPorts.filter(
        (x) => !this.offerBinding.loadingPorts.includes(x)
      );
    } else if (event.field == "dischargingPorts") {
      this.portsD = this.allPorts.filter(
        (x) => !this.offerBinding.dischargingPorts.includes(x)
      );
    }
    this.offerBinding = { ...this.offerBinding };
    console.log(this.offerBinding);
  }

  onUndoApprove(event, clause?: ClauseNegotiation) {
    if (
      event.field === "chargerClauses" ||
      event.field === "shipOwnerClauses"
    ) {
      //TODO implementar undoapprove en cláusulas
      this.nextStatusPendingInClause(clause);
    } else {
      //if(this.offerBinding.hasOwnProperty(event.field)){
      if (this.offerBinding.hasOwnProperty(event.field + "State")) {
        this.offerBinding[event.field + "State"] = this.nextStatusPending(
          event.field
        );
      }
      // }
    }
  }
  onActive(event, clause: ClauseNegotiation) {
    clause.isRefused = false;

    // if (this.globals.currentCharger) {
    //   clause.state = NegotiationFieldState.SHIPOWNER_PENDING;
    // } else if (this.isShipownerBinding) {
    //   clause.state = NegotiationFieldState.CHARGER_PENDING;
    // }
    // //clause.value = clause.valueInitial;
    // if(clause.isRefused != clause.refusedInitial){

    // }
    // clause.isReadOnly = true;
  }
  onRemove(event, clause?: ClauseNegotiation) {
    //TODO revisar onRemove en cláusulas
    //clause.isRefused = true;

    // if (this.globals.currentCharger) {
    //   clause.state = NegotiationFieldState.SHIPOWNER_PENDING;
    // } else if (this.isShipownerBinding) {
    //   clause.state = NegotiationFieldState.CHARGER_PENDING;
    // }
    // clause.isReadOnly = true;
    clause.value = clause.valueInitial;
    this.form.get(clause.nameControl).setValue(clause.value);
  }

  convertClausesToString() {
    this.listClauseCLH.forEach((item) => {
      const clauseString = item.value;
      this.listClauseCLHSend.push(clauseString);
    });
  }

  nextStatusApproved(field: string, hadChange: boolean = false): number {
    return NegotiationFieldState.CONFIRMED;
  }

  nextPending() {
    if (this.isShipownerBinding) {
      return NegotiationFieldState.CHARGER_PENDING;
    } else if (this.globals.currentCharger) {
      return NegotiationFieldState.SHIPOWNER_PENDING;
    }
    //pendiente clh cláusulas armador
    else if (this.isClhRevision) {
      return NegotiationFieldState.CHARGER_PENDING;
    } else {
      console.log("nextStatusApproved not implemented");
      return NegotiationFieldState.CHARGER_PENDING;
    }
  }

  nextStatusApprovedInClause(
    clause: ClauseNegotiation,
    hadChange: boolean = false
  ) {
    // console.log('hadChange',hadChange);
    // console.log('this',this);
    if (clause.state == NegotiationFieldState.CONFIRMED) {
      clause.state = NegotiationFieldState.CONFIRMED;
      console.log("confirmed", clause);
    }

    if (hadChange) {
      // console.log('hadchange',clause);
      // this.nextPendingClauses(clause);

      if (clause.valueInitial === this.form.get(clause.nameControl).value) {
        clause.state = NegotiationFieldState.CONFIRMED;
      } else {
        this.nextPendingClauses(clause);
      }
    } else {
      if (this.form.get(clause.nameControl).value === clause.valueInitial) {
        clause.state = NegotiationFieldState.CONFIRMED;
      } else {
        this.nextPendingClauses(clause);
      }
    }
    console.log("clauseNextStatusAproved", clause);
  }
  nextPendingClauses(clause: ClauseNegotiation) {
    // console.log('clauseNextPending',clause);
    // console.log('this',this);
    if (this.isShipownerBinding) {
      clause.state = NegotiationFieldState.CHARGER_PENDING;
    } else if (this.globals.currentCharger) {
      clause.state = NegotiationFieldState.SHIPOWNER_PENDING;
    }
    //pendiente clh cláusulas armador
    else if (this.isClhRevision) {
      clause.state = NegotiationFieldState.CHARGER_PENDING;
    } else {
      console.log("nextStatusApproved not implemented");
    }
    console.log("clauseNextPending", clause);
  }
  nextStatusPending(field: string) {
    if (this.isShipownerBinding) {
      return NegotiationFieldState.CHARGER_PENDING;
    } else {
      return NegotiationFieldState.SHIPOWNER_PENDING;
    }
  }
  nextStatusPendingReopenedInClause(clause: ClauseNegotiation) {
    if (this.isShipownerBinding) {
      clause.state = NegotiationFieldState.CHARGER_PENDING_REOPENED;
    } else if (this.globals.currentCharger) {
      clause.state = NegotiationFieldState.SHIPOWNER_PENDING_REOPENED;
    }
  }
  nextStatusPendingReopened(field: string) {
    if (this.isShipownerBinding) {
      return NegotiationFieldState.CHARGER_PENDING_REOPENED;
    } else if (this.globals.currentCharger) {
      return NegotiationFieldState.SHIPOWNER_PENDING_REOPENED;
    } else {
      return NegotiationFieldState.CHARGER_PENDING;
    }
  }

  nextStatusPendingInClause(clause: ClauseNegotiation) {
    // console.log('this',this);
    if (clause.approvedRefused) {
      clause.approvedRefused = false;
      clause.isRefused = false;
    }
    if (this.isShipownerBinding) {
      clause.state = NegotiationFieldState.CHARGER_PENDING;
    } else {
      clause.state = NegotiationFieldState.SHIPOWNER_PENDING;
    }
    console.log("clauseNextStatusPending", clause);
  }

  async sendOfferRecap() {
    this.assignShift();
    this.isLoading = true;
    this.sendMailResponse = this.sendMailResponse ? true : false;
    console.log(this.sendMailResponse);
    const offerShipOwnerStateSend = new OfferShipOwnerState();
    offerShipOwnerStateSend.offerShipOwnerId =
      this.offerBinding.offerShipOwnerId;
    offerShipOwnerStateSend.userType = this.globals.currentCharger
      ? UserType.CHARGER
      : UserType.SHIPOWNER;

    const currentRecap = await firstValueFrom(
      this.offerService.getShipOwnerRecap(this.idOfferShipOwner)
    );
    if (
      currentRecap.offerShipOwnerState ===
      StateOfferShipOwner.RECAP_CHARTERER_PENDING ||
      currentRecap.offerShipOwnerState ===
      StateOfferShipOwner.RECAP_SHIPOWNER_PENDING
    ) {
      offerShipOwnerStateSend.state = StateOfferShipOwner.RECAP_CONFIRMED;
      if (
        currentRecap.offerShipOwnerState ===
        StateOfferShipOwner.RECAP_CHARTERER_PENDING
      ) {
        offerShipOwnerStateSend.chargerContactNameToRecapConfirm =
          this.nameContactCharger;
      }
      if (
        currentRecap.offerShipOwnerState ===
        StateOfferShipOwner.RECAP_SHIPOWNER_PENDING
      ) {
        offerShipOwnerStateSend.shipOwnerContactNameToRecapConfirm =
          this.nameContactShipowner;
      }
      this.isConfirmed = true;
      this.isConfirmRecapToDoc = true;
    } else {
      if (this.globals.currentCharger) {
        offerShipOwnerStateSend.state =
          StateOfferShipOwner.RECAP_SHIPOWNER_PENDING;
        offerShipOwnerStateSend.chargerContactNameToRecapConfirm =
          this.nameContactCharger;
        this.isConfirmed = true;
      } else if (this.globals.currentShipOwner) {
        offerShipOwnerStateSend.state =
          StateOfferShipOwner.RECAP_CHARTERER_PENDING;
        offerShipOwnerStateSend.shipOwnerContactNameToRecapConfirm =
          this.nameContactShipowner;
        this.isConfirmed = true;
      }
    }
    this.spinner.show();
    console.log(offerShipOwnerStateSend);
    this.offerService
      .confirmOfferShipOwnerToRecap(offerShipOwnerStateSend)
      .subscribe({
        next: () => {
          this.spinner.hide();
          if (this.isConfirmRecapToDoc) {
            this.offerService
              .getRecapDoc(this.offerBinding.offerShipOwnerId)
              .subscribe({
                next: (res) => {
                  console.log(res);
                  const fileBlob = new Blob([res.body], {
                    type: "application/pdf",
                  });
                  //const fileURL = URL.createObjectURL(file);
                  const contentDisposition = res.headers.get(
                    "Content-Disposition"
                  );
                  const filename =
                    "Recap-" + this.offerBinding.refOffer + ".pdf";

                  const file = new File([fileBlob], filename, {
                    type: "application/pdf",
                    lastModified: Date.now(),
                  });

                  const fileElement: FileElement = new FileElement();
                  fileElement.documentName = filename;
                  fileElement.name = filename;
                  this.storageService
                    .uploadFile(file, this.storageId)
                    .subscribe({
                      next: (fileResponse) => {
                        fileElement.documentId = fileResponse.id;
                        this.documentService
                          .addDocument(
                            fileElement,
                            this.offerBinding.offerId || "",
                            "offer",
                            true,
                            this.offerBinding.offerShipOwnerId || ""
                          )
                          .subscribe({
                            next: (document) => {
                              console.log(document);
                            },
                            error: (err) => {
                              console.error(err);
                            },
                          });
                      },
                      error: (err) => {
                        console.error(err);
                      },
                    });
                },
                error: (err) => {
                  console.log("errorRecapDoc", err);
                  this.spinner.hide();
                  this.isLoading = false;
                },
              });
          }

          if (this.isConfirmed) {
            this.sendMail();
            if (this.sendMailToUser) {
              this.router.navigate(["home"]);
              this.isLoading = false;
            }
          } else {
            this.isLoading = false;
            this.router.navigate(["home"]);
          }
        },
        error: (err) => {
          console.log("errorNegotiation", err);
          this.spinner.hide();
          this.isLoading = false;
        },
      });
  }
  sendClausesClh() {
    this.convertClausesToString();
    const negotiationShipOwnerClausesValidation =
      new NegotiationShipownerClausesValidation();
    negotiationShipOwnerClausesValidation.offerShipOwnerId =
      this.idOfferShipOwner;
    negotiationShipOwnerClausesValidation.shipOwnerClauses =
      this.listClauseCLHSend;
    console.log("clauses", negotiationShipOwnerClausesValidation);

    this.spinner.show();
    this.offerService
      .sendClausesCLHNegotiation(negotiationShipOwnerClausesValidation)
      .subscribe({
        next: () => {
          this.spinner.hide();
          this.sendMail();
          if (this.sendMailToUser) {
            this.noConfirmationServiceToExit = true;
            this.router.navigate(["home"]);
            this.isLoading = false;
          }
        },
        error: (err) => {
          console.log("errorNegotiation", err);
          this.spinner.hide();
          this.isLoading = false;
        },
      });
  }
  sendExpirationDate(event) {
    this.checked = event.checked;
    if (this.checked) {
      this.form.get("expirationDate")?.enable();
    } else {
      this.form.get("expirationDate")?.disable();
    }
  }
  ngAfterContentChecked() { }
  cancelOrDeclineOffer() {
    const trans = this.isShipownerBinding
      ? this.isCounter0shipOwner
        ? "BINDING_OFFER.ASK_DECLINED"
        : "BINDING_OFFER.ASK_DECLINED_NEGOTIATION"
      : "BINDING_OFFER.ASK_CANCEL_OFFER";

    const trmessage = this.translate.get(trans);
    const messageAccept = this.translate.get("ACTION.YES");

    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        if (this.globals.currentCharger && !this.isCLHRepresentingCharger) {
          this.cancelOffer();
        }
        if (this.globals.currentShipOwner && !this.isCLHRepresentingShipowner) {
          this.declineOffer();
        }
      },
      reject: () => { },
    });
  }
  cancelOffer() {
    this.spinner.show();
    this.offerService
      .cancelOfferShipowner(this.offerBinding.offerShipOwnerId)
      .subscribe({
        next: () => {
          this.spinner.hide();
          this.messageService.showToast(
            "OK",
            "MESSAGES.SUCCESS.UPDATE_LONG",
            "success",
            ""
          );
          this.router.navigate(["home"]);
        },
        error: (err) => {
          console.log("errorCancelOffer", err);
          this.spinner.hide();
        },
      });
  }
  // getTemplateDecline() {
  //   this.mailTemplateService.get().subscribe({
  //     next: (templates) => {
  //       this.declineTemplate = templates.filter((s) =>
  //         s.name.includes("decl")
  //       )[0] as MailTemplate;
  //     },
  //     error: (err) => {
  //       console.error(err);
  //       this.messageService.showToast(
  //         "ERROR",
  //         "MESSAGES.ERROR.BAD_REQUEST_BODY",
  //         "error",
  //         ""
  //       );
  //     },
  //   });
  // }
  declineOffer() {
    const offerDecline: OfferDecline = new OfferDecline();
    offerDecline.offerId = this.offerBinding.offerId;
    offerDecline.shipOwnerId = this.offerBinding.shipOwnerId;
    const offershipownerTemplate: OfferShipOwner = new OfferShipOwner();
    offershipownerTemplate.offer = new Offer();
    offershipownerTemplate.offer.refOffer = this.offerBinding.refOffer;
    offershipownerTemplate.offer.loadingPorts = this.offerBinding.loadingPorts;
    offershipownerTemplate.offer.dischargingPorts =
      this.offerBinding.dischargingPorts;
    offershipownerTemplate.offer.commodity = this.offerBinding.commodity;
    offershipownerTemplate.offer.charger.name = this.offerBinding.chargerName;
    offershipownerTemplate.shipOwner.charteringCompany =
      this.offerBinding.shipOwnerName;
    offerDecline.templateName = Constants.TEMPLATE_SHIPOWNERS_DECLINE;
    // offerDecline.email = mapTemplate(
    //   this.declineTemplate.html,
    //   offershipownerTemplate,
    //   ["comment"]
    // );
    // offerDecline.subject = mapTemplate(
    //   this.declineTemplate.subject,
    //   offershipownerTemplate,
    //   ["comment"]
    // );
    console.log("OFFERDECLINE", offerDecline);
    this.spinner.show();
    this.offerService.declineOfferSendEmail(offerDecline).subscribe({
      next: (item) => {
        this.messageService.showToast(
          "INFO",
          "MESSAGES.SUCCESS.UPDATE_EMAIL",
          "success",
          ""
        );
        this.spinner.hide();
        this.router.navigate(["home"]);
      },
      error: (err) => {
        this.spinner.hide();
        this.messageService.showToast(
          "ERROR",
          "MESSAGES.ERROR.NO_SEND_EMAIL",
          "error",
          ""
        );
      },
    });
  }
  confirmationSendOffer(type: string) {
    let trans = "";
    let skipClhClauses = false;

    if (type === "offer") {
      trans = "COMMONS.ASK_SAVE_OFFER";
    } else if (type === "recap") {
      trans = "COMMONS.ASK_SAVE_RECAP";
    } else if (type === "clh") {
      trans = "COMMONS.ASK_SAVE_CLAUSES_CLH";
      if (
        this.form.get("clausesNewCLH").value &&
        this.form.get("clausesNewCLH").value != ""
      ) {
        trans = "COMMONS.ASK_SEND_CLAUSES_WITHOUT_ADD";
      }
      if (!this.listClauseCLH?.length) {
        trans = "COMMONS.ASK_SEND_OFFER_WITHOUT_CLAUSES";
      }
    }

    if (
      this.isCounter0shipOwner &&
      !this.form.get("initialShipOwnerClauses").value
    ) {
      trans = "COMMONS.ASK_SEND_OFFER_WITHOUT_CLAUSES";
      skipClhClauses = true;
    }

    if (
      (this.isFirstStepCharterer ||
        (this.globals.currentCharger && this.offerBinding.counter == 1)) &&
      this.form.get("clausesNew").value != ""
    ) {
      trans = "COMMONS.ASK_SEND_CLAUSES_WITHOUT_ADD";
    }

    const trmessage = this.translate.get(trans);
    const messageAccept = this.translate.get("ACTION.YES");

    //if (type === 'clh' && !this.listClauseCLH?.length) {
    //  this.messageService.showToast('INFO', 'MESSAGES.INFO.CLAUSES_LIST_EMPTY_CLH', 'warning', '');
    //} else
    //    if (

    //  (type === 'offer' && this.globals.currentCharger && this.form.get('clausesNew').value)
    //) {
    //  this.messageService.showToast('INFO', 'BINDING_OFFER.TEXTAREA_WITH_VALUE', 'warning', '');
    //} else {
    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        if (type === "clh") {
          this.sendClausesClh();
        } else if (type === "offer" || skipClhClauses) {
          this.noConfirmationServiceToExit = true;
          this.sendOfferBinding();
        } else if (type === "recap") {
          this.ngOnInit();
          this.noConfirmationServiceToExit = true;
          this.sendOfferRecap();
        }
      },
      reject: () => { },
    });
    // }
  }
  changeFreightTargetCurrency(event) {
    if (this.isFirstStepCharterer) {
      this.form
        .get("demurrageCurrency")
        .setValue(FreightTargetCurrency[FreightTargetCurrency[event]]);
    }
  }
  changeClassOnDivButtonsOffer(field: string, clause?: ClauseNegotiation) {
    // console.log(field);
    if (field != "Clauses") {
      const fieldState = field + "State";
      if (
        this.offerBindingInitial[fieldState] == 2 &&
        !this.isViewNegotationAfterRecap
      ) {
        const offerButtons = document.querySelector(".buttonsOffer." + field);
        offerButtons?.classList.add("showPadLock");
      }
    } else {
      console.log(clause.stateInitial);
      if (clause.stateInitial == 2 && !this.isViewNegotationAfterRecap) {
        const offerButtons = document.querySelector(
          ".buttonsOffer." + clause.nameControl
        );
        offerButtons?.classList.add("showPadLock");
      }
    }
  }
  addClassTrHistory(event, field: string, clause?: ClauseNegotiation) {
    // console.log('FIELD',field,event);
    if (field != "Clauses") {
      const trHistory = document.querySelector(".trHistory" + field);
      if (trHistory.classList.contains("hiddenHistory")) {
        trHistory.classList.remove("hiddenHistory");
        trHistory.classList.add("showHistory");
      } else if (trHistory.classList.contains("showHistory")) {
        trHistory.classList.remove("showHistory");
        trHistory.classList.add("hiddenHistory");
      }
      // console.log(trHistory.classList)
    } else {
      const trHistory = document.querySelector(
        ".trHistory" + clause.nameControl
      );
      if (trHistory.classList.contains("hiddenHistory")) {
        trHistory.classList.remove("hiddenHistory");
        trHistory.classList.add("showHistory");
      } else if (trHistory.classList.contains("showHistory")) {
        trHistory.classList.remove("showHistory");
        trHistory.classList.add("hiddenHistory");
      }
      // console.log(trHistory.classList)
    }
  }

  duplicateClauses() {
    this.spinner.show();
    this.offerService
      .getPreviousChargerClausesById(this.idOfferShipOwner)
      .subscribe({
        next: (list) => {
          if (Object.keys(list).length > 0) {
            console.log(list);
            if (!list.chargerClauses.length && !list.shipOwnerClauses.length) {
              this.messageService.showToast(
                "INFO",
                "MESSAGES.INFO.CLAUSES_DUPLICATE_LIST_EMPTY",
                "warning",
                ""
              );
              this.spinner.hide();
            } else {
              this.duplicateClausesAnotherOffer = list;
              this.convertStringToClauseNegotiation(
                this.duplicateClausesAnotherOffer.chargerClauses,
                "charger"
              );
              this.convertStringToClauseNegotiation(
                this.duplicateClausesAnotherOffer.shipOwnerClauses,
                "charger"
              );
              this.searchDuplicateClause();
              this.countClauses = this.listchargerClauses.length;
              console.log("listClauses", this.listchargerClauses);
              console.log("listClausesShipowner", this.listshipOwnerClauses);
              this.sendBinding = true;
              this.spinner.hide();
            }
          } else {
            this.messageService.showToast(
              "INFO",
              "MESSAGES.INFO.CLAUSES_DUPLICATE_LIST_EMPTY",
              "warning",
              ""
            );
            this.spinner.hide();
          }
        },
        error: (err) => {
          console.log("errorDuplicateClauses", err);
          this.spinner.hide();
        },
      });
  }
  checkToSaveToExit() {
    this.hasUnsavedChanges = false;
    if (
      !this.noConfirmationServiceToExit &&
      !this.offerFirmBindingIsReadOnly &&
      this.isClhRevision
    ) {
      if (
        (this.form.get("clausesNewCLH")?.value &&
          !this.listClauseCLH?.length) ||
        (this.listClauseCLH?.length && this.form.get("clausesNewCLH")?.value) ||
        (this.listClauseCLH?.length && !this.form.get("clausesNewCLH")?.value)
      ) {
        this.hasUnsavedChanges = true;
      }
    } else {
      this.hasUnsavedChanges = false;
    }
  }
  checkCanDeactive(): boolean | Observable<boolean> {
    console.log("checkCanDeactive");
    this.checkToSaveToExit();
    if (this.hasUnsavedChanges) {
      let translateSend = "";

      const messageAccept = this.translate.get("ACTION.YES");

      if (
        this.form.get("clausesNewCLH")?.value &&
        !this.listClauseCLH?.length
      ) {
        translateSend = "BINDING_OFFER.ASK_SAVE_TO_EXIT_CLH_2";
      } else if (
        this.listClauseCLH?.length ||
        (this.listClauseCLH?.length && this.form.get("clausesNewCLH")?.value)
      ) {
        translateSend = "BINDING_OFFER.ASK_SAVE_TO_EXIT_CLH";
      }

      const trmessage = this.translate.get(translateSend);

      return new Observable((observer) => {
        this.confirmationService.confirm({
          message: trmessage,
          header: "",
          acceptLabel: messageAccept,
          icon: "pi pi-exclamation-triangle",
          accept: () => {
            observer.next(true);
            observer.complete();
          },
          reject: () => {
            observer.next(false);
            observer.complete();
          },
        });
      });
    } else {
      return true;
    }
  }
  ngAfterViewInit() {
    this.cd.detectChanges();
  }
  checkPermissions() {
    let hasPermission = true;
    if (this.offerBinding) {
      if (this.globals.currentCharger) {
        if (this.globals.currentCharger.id != this.offerBinding.chargerId) {
          hasPermission = false;
        }
      } else if (this.globals.currentShipOwner) {
        if (this.globals.currentShipOwner.id != this.offerBinding.shipOwnerId) {
          hasPermission = false;
        }
      }
    }
    if (!hasPermission) {
      this.router.navigateByUrl("security/access-denied");
    }
  }
}
