import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConfigsService } from "@overa/shared";
import { GenericConditions } from "../models/clause/generic-conditions.model";
import { UserType } from "../models/enum/user-type.model";
import { CLHBaseService } from "./clh-base.service";

@Injectable({ providedIn: "root" })
export class GenericConditionsService extends CLHBaseService {
  private getBaseUrl(type: string) {
    const endpointUrl =
      type == "terms"
        ? `${this.baseUrl}/termsandconditions`
        : `${this.baseUrl}/privacyPolicy`;
    return endpointUrl;
  }
  get(type: string) {
    const endpointUrl = `${this.getBaseUrl(type)}`;
    return this.http.get<GenericConditions[]>(endpointUrl);
  }

  getLast(type: string) {
    const endpointUrl = `${this.getBaseUrl(type)}/getLast`;

    return this.http.get<GenericConditions>(endpointUrl);
  }

  getById(id: string, type: string) {
    const endpointUrl = `${this.getBaseUrl(type)}/${id}`;

    return this.http.get<GenericConditions>(`${this.getBaseUrl(type)}/${id}`);
  }

  mustAcceptGenericConditions(userId: string, type: string) {
    const endpointUrl = `${this.getBaseUrl(type)}/${userId}`;
    return this.http.get(endpointUrl);
  }

  add(tca: GenericConditions, type: string) {
    const endpointUrl = `${this.getBaseUrl(type)}`;
    return this.http.post<GenericConditions>(endpointUrl, tca);
  }

  edit(tca: GenericConditions, type: string) {
    const endpointUrl = `${this.getBaseUrl(type)}`;
    return this.http.put<GenericConditions>(endpointUrl, tca);
  }

  delete(id: string, type: string) {
    const endpointUrl = `${this.getBaseUrl(type)}/${id}`;
    return this.http.delete(endpointUrl);
  }
  send(tca: GenericConditions, type: string) {
    const endpointUrl = `${this.getBaseUrl(type)}/send/${tca.id}`;
    return this.http.post<GenericConditions>(endpointUrl, tca.id);
  }

  accept(id: string, type: string, userType: UserType, userId: string) {
    const endpointUrl = `${this.getBaseUrl(
      type
    )}/accept/${id}/${userId}/${userType}`;

    return this.http.post<GenericConditions>(endpointUrl, {});
  }
}
