import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AccountService } from "@overa/security";
import { TermsAndConditionsService } from "../services/terms-and-conditions.service";
import { PrivacyPoliciesService } from "../services/privacy-policies.service";
import { CLHGlobals } from "./globals";
import { firstValueFrom } from "rxjs";

@Injectable({ providedIn: "root" })
export class PermissionsGuardClh implements CanActivate {
  constructor(
    private router: Router,
    private accountService: AccountService,
    private globals: CLHGlobals,
    private termsAndConditionsService: TermsAndConditionsService,
    private privacyPoliciesService: PrivacyPoliciesService
  ) { }

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    //var url = route.url && route.url.length ? route.url[0].toString() : '';
    //var fullUrl = route.pathFromRoot
    //    .map(v => v.url.map(segment => segment.toString()).join('/'))
    //    .join('/');
    //console.log(fullUrl);
    //var lastSegment = fullUrl.split('/')[fullUrl.split('/').length - 1];
    //if (this.utils.isGuid(lastSegment)) {
    //    url = fullUrl.split('/').pop();
    //}
    // test here if you user is logged
    console.log("canActivate");
    console.log(this);

    /* if (url != '') {*/
    var roles = this.accountService.currentUser.roles;
    const permissions = route.data["permissions"] || undefined;
    if (permissions) {
      console.log(permissions);
      let hasPermission = roles
        .map((x) => x.name)
        .some((r) => permissions.includes(r));
      if (this.accountService.currentUser.isGlobalAdmin) {
        hasPermission = true;
      }
      let hasAcceptedTerms: boolean = await this.hasAcceptedTerms();
      console.log("hasAcceptedTerms", hasAcceptedTerms);
      if (
        !hasPermission ||
        (!hasAcceptedTerms && !this.accountService.currentUser.isGlobalAdmin)
      ) {



        // this.router.navigateByUrl("security/access-denied");
        // return false;
      }
      return hasPermission;
    } else {
      console.log("no permissions");
      return true;
    }

    //}
    //else {
    //    return true;
    //}
  }

  //private canActivateFn(url) {

  //    if (!url.includes('security') && !url.includes('login') && !url.includes('register') && !url.includes('access-denied')) {
  //        if (!this.accountService.currentUser) {
  //            this.router.navigateByUrl('security/access-denied');
  //            return false;
  //        }
  //        else {
  //            var hasPermission = this.hasPermission(url);
  //            // if (!hasPermission) {
  //            //   this.router.navigateByUrl('test');
  //            // }
  //            return hasPermission;
  //        }
  //    }
  //    else {
  //        return true;
  //    }
  //}

  private hasPermission(url: string) {
    let hasPermission = false;
    if (!hasPermission) {
      console.log("permissions-guard not hasPermission", url);
    }

    //  var isClh = this.accountService.currentUser.roles.map(x => x.name.toLowerCase().trim()).includes(this.globals.clhRoleName);

    return hasPermission;
  }

  private hasAcceptedTerms2(): boolean {
    return this.globals.acceptedConditions;
  }
  private async hasAcceptedTerms(): Promise<boolean> {
    let accepted = false,
      mustAccept = true;
    let mustAcceptPrivacy = true;
    let subscription, subscription2, role;
    var user = this.accountService.currentUser;
    console.log("userAccount", user);

    const roles = ["clh", "charger", "charterer", "shipowner"];

    for (let r of user.roles) {
      if (r.name.includes(roles[1]) || r.name.includes(roles[2])) {
        role = roles[1];
      } else if (r.name.includes(roles[3])) {
        role = roles[2];
      } else {
        role = roles[0];
      }
      console.log("roles", role);
    }

    if (role === roles[0]) {
      accepted = true;
    } else {
      console.log("user.id", user.id);
      const contact = this.globals.currentCharger
        ? this.globals.currentChargerContact
        : this.globals.currentShipOwnerContact;
      if (contact) {
        subscription =
          this.termsAndConditionsService.mustAcceptTermsAndConditions(
            contact.id || ""
          );
        subscription2 = this.privacyPoliciesService.mustAccept(
          contact.id || ""
        );
        const mustAccept = await firstValueFrom(subscription);
        const mustAcceptPrivacy = await firstValueFrom(subscription2);

        // [mustAccept, mustAcceptPrivacy] = await Promise.all([
        //   subscription.toPromise(),
        //   subscription2.toPromise(),
        // ]);

        if (mustAccept == false && mustAcceptPrivacy == false) {
          accepted = true;
        }
      } else if (this.globals.isClh || this.globals.user.isGlobalAdmin) {
        accepted = true;
      }
    }
    console.log("accepted", accepted);
    return accepted;
  }
}
