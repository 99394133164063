import { Charger } from "../charger/charger.model";

export class Clause {

    id?: string;
    description?: string;
    chargerId?: string;
    charger?: Charger;
    isEdit?: boolean;
    isNew?: boolean;
    valueInitial?:string;
    valueInitialDate?:string;
    isEmpty?:boolean;
    height?: number;
    nameControl?:string;//para front
    constructor() {
    }
}