import {
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmationService } from "primeng/api";
import { Charger } from "src/app/models/charger/charger.model";
import { Shipowner } from "src/app/models/shipowner/shipowner.model";
import { ChargerShipOwnerContact } from "src/app/models/charger-shipowner/charger-shipowner-contact.model";
import { ChargerShipOwner } from "src/app/models/charger-shipowner/charger-shipowner.model";
import { ContactList } from "src/app/models/charger-shipowner/contact-list.model";
import { ShipownerService } from "src/app/services/shipowner.service";
import { ChargerShipownerService } from "src/app/services/charger-shipowner.service";
import {
  AccountService,
  Permission,
  Role,
  RoleService,
  User,
  UserEdit,
  UserService,
} from "@overa/security";
import { Utils } from "src/app/utils/utils";
import { forkJoin, Observable, of } from "rxjs";
import {
  CdkDragDrop,
  moveItemInArray,
  CdkDropList,
} from "@angular/cdk/drag-drop";
import { ContactListChargerShipownerContact } from "../../../models/charger-shipowner/contact-list-charger-shipowner-contact.model";
import { ChargerService } from "../../../services/charger.service";
import { Table } from "primeng/table";
import { NgxSpinnerService } from "ngx-spinner";
import { Filters } from "src/app/utils/filters";
import { PermissionsService } from "../../../services/permissions.service";
import { ActivatedRoute } from "@angular/router";
import { CLHGlobals } from "src/app/utils/globals";
import { ShipOwnerContact } from "src/app/models/shipowner/shipowner-contact.model";
import { OveraMessagesService } from "@overa/components/info-dialog";
import { NgxTranslateService } from "@overa/shared";
import { NewChargerShipownerDialogComponent } from "./new-charger-shipowner-dialog/new-charger-shipowner-dialog.component";
import { OveraDialogService } from "@overa/components/dialog";
import { NewListDialogComponent } from "./new-list-dialog/new-list-dialog.component";

@Component({
  selector: "app-shipowners",
  templateUrl: "./shipowners.component.html",
  styleUrls: ["./shipowners.component.scss"],
  providers: [ConfirmationService],
})
export class ShipownersComponent implements OnInit, AfterViewInit {
  @Input()
  charger!: Charger;
  listShipowners: ChargerShipOwner[] = [];
  columns: any[] = [];
  isEditingShipowner: boolean = false;
  isNewShipowner: boolean = false;
  form!: FormGroup;
  formContacts!: FormGroup;
  today: Date = new Date();
  newShipOwner?: ChargerShipOwner;
  initialShipOwner?: ChargerShipOwner;
  allShipowners: Shipowner[] = [];
  listAllShipowners: Shipowner[] = [];
  selectedShipOwner?: ChargerShipOwner;
  onTheList: boolean = false;
  isEdittingContacts = false;
  listAllShipownersNames: string[] = [];
  identityUsers: any[] = [];
  listsOfLists: ContactList[] = [];
  selectedContact?: any;
  filter = "";
  searching = false;
  filteredLists: any[] = [];
  formFilter!: FormGroup;
  listName: string = "";
  permissions: Permission[] = [];
  readonly = false;
  filteredTextShipowners: string = '';
  neededPermissions = [
    "users.view",
    "roles.view",
    "users.create",
    "roles.assign",
    "users.update",
  ];
  neededRoles = ["shipowner.negotiator"];
  roles: Role[] = [];

  //contacts:CdkDropList<any> = [];

  @ViewChild("tableShipowners") tableShipowners!: Table;
  @ViewChild("listsContainer") listsContainer: ElementRef;


  constructor(
    private translate: NgxTranslateService,
    private confirmationService: ConfirmationService,
    private shipOwnerService: ShipownerService,
    private shipownerChargerService: ChargerShipownerService,
    private chargerService: ChargerService,
    private fb: FormBuilder,
    public messagesService: OveraMessagesService,
    private accountService: AccountService,
    private rolesService: RoleService,
    private usersService: UserService,
    private utils: Utils,
    private spinner: NgxSpinnerService,
    private filtersTable: Filters,
    private permissionsService: PermissionsService,
    private route: ActivatedRoute,
    private dialogService: OveraDialogService,
    private globals: CLHGlobals
  ) { }

  ngOnInit(): void {
    this.formFilter = this.fb.group({
      filter: new FormControl("", []),
      listName: new FormControl("", []),
    });
    this.usersService.getUsers().subscribe({
      next: (users) => {
        this.identityUsers = users;

        if (!this.charger) {
          this.identityUsers = users;
          if (this.globals.currentCharger) {
            this.chargerService
              .getById(this.globals.currentCharger.id || "")
              .subscribe({
                next: (item) => {
                  this.charger = item;
                  this.loadData();
                },
                error: (err) => {
                  console.error(err);
                },
              });

          }
        } else {
          this.loadData();
        }
      }

    });
  }
  loadData() {
    this.readonly = !this.permissionsService.hasPermissionToEdit(this.route);

    this.accountService.getRoles().subscribe((roles) => {
      this.roles = roles;
      this.accountService.getPermissions().subscribe((p) => {
        this.permissions = p;
        this.shipOwnerService.getVerified().subscribe({
          next: (shipownersListResult) => {
            // console.log(shipownersListResult);
            if (shipownersListResult) {
              const contactLists = [...(this.charger.contactLists || [])];
              console.log(contactLists);

              this.allShipowners = shipownersListResult;

              console.log(this.allShipowners);
              this.allShipowners = this.allShipowners;
              console.log(this.charger);

              this.createListSelectedShipOwners(this.charger);
              this.modifyListSshipownersList();

              this.mapLists();

              this.formFilter.get("filter")?.valueChanges.subscribe((val) => {
                this.filterLists(val);
              });
              this.columns = [
                // { id: "", sort: "check" },
                {
                  id: "SHIPOWNER_DATA.SHIPOWNER_FORM.HEAD_COL0",
                  sort: "charteringCompany",
                  tooltip: "TOOLTIP.SHIPOWNERS_SHIPOWNERSLIST",
                },
                {
                  id: "SHIPOWNER_DATA.SHIPOWNER_FORM.HEAD_COL1",
                  sort: "dischargeDate",
                  tooltip: "SHIPOWNER_DATA.SHIPOWNER_FORM.HEAD_COL1",
                },
                {
                  id: "SHIPOWNER_DATA.SHIPOWNER_FORM.HEAD_COL2",
                  sort: "isSelected",
                  tooltip: "TOOLTIP.PREDETERMINED_SHIPOWNERSLIST",
                },
                {
                  id: "SHIPOWNER_DATA.SHIPOWNER_FORM.HEAD_COL3",
                  sort: "comment",
                  tooltip: "TOOLTIP.COMMENTS_SHIPOWNERSLIST",
                },
                {
                  id: "SHIPOWNER_DATA.SHIPOWNER_FORM.HEAD_COL4",
                  sort: "counterContacts",
                  tooltip: "TOOLTIP.CONTACTS_SHIPOWNERSLIST",
                },
              ];
              if (!this.readonly) {
                this.columns.push({
                  id: "SHIPOWNER_DATA.SHIPOWNER_FORM.HEAD_COL5",
                  sort: "modify",
                  tooltip: "TOOLTIP.ACTIONS_SHIPOWNERSLIST",
                });
              }
              this.initialForm();
            }
          },
          error: (err) => {
            console.error(err);
          },
        });
      });
    });
  }
  ngAfterViewInit() {
    // this.filter.valueChanges.subscribe(val =>{
    //   console.log(val);
    // });
  }
  filterLists(val: string) {
    const value = val.toLowerCase();
    if (value.trim() == "") {
      this.filteredLists = [...this.listsOfLists];
      return;
    }
    const listsFiltered: any[] = [];
    this.listsOfLists.forEach((list) => {
      let found = false;
      if (list.name?.toLowerCase().includes(value)) {
        list.expanded = true;
        listsFiltered.push(list);
        found = true;
      }
      if (!found) {
        const listAux = { ...list };
        let foundShipownerInList = false;
        listAux.filteredShipOwners = [];
        list.shipOwners?.forEach((ship) => {
          let foundShipowner = false;
          let foundContact = false;
          if (ship.charteringCompany.toLowerCase().includes(value)) {
            listAux.filteredShipOwners?.push(ship);
            foundShipowner = true;
            foundShipownerInList = true;
          } else {
            const shipAux = { ...ship };
            shipAux.filteredContacts = [];
            foundContact = false;
            ship.chargerShipOwnerContacts.forEach((cont: any) => {
              if (cont.email.toLowerCase().includes(value)) {
                shipAux.filteredContacts.push(cont);
                foundContact = true;
                foundShipowner = true;
              }
            });

            if (foundShipowner) {
              foundShipownerInList = true;
              if (
                !listAux.filteredShipOwners
                  ?.map((s) => s.charteringCompany.toLowerCase())
                  .includes(shipAux.charteringCompany)
              ) {
                listAux.filteredShipOwners?.push(shipAux);
              }
            }
          }
          //  if(foundShipowner){
          //   listAux.expanded = true;
          //   listsFiltered.push(listAux);
          //  }
          //  if(found){

          //  }
        });
        if (foundShipownerInList) {
          listAux.expanded = true;
          listsFiltered.push(listAux);
        }
        // listsFiltered.push(listAux);
      }
    });
    this.filteredLists = [...listsFiltered];
    // if(!listsFiltered.length){

    // }
  }
  addNewContact(shipowner: any) {
    this.formContacts.reset();

    const selectedShipowner = this.listShipowners.find(
      (x: any) => x.id == shipowner.id
    );
    console.log(selectedShipowner);
    const newChargerShipOwnerContact = new ChargerShipOwnerContact();
    //newChargerShipOwnerContact.chargerId = '';
    newChargerShipOwnerContact.comment = "";
    newChargerShipOwnerContact.editable = true;
    if (selectedShipowner) {
      newChargerShipOwnerContact.chargerShipOwnerId = selectedShipowner.id;
      selectedShipowner.chargerShipOwnerContacts = [
        newChargerShipOwnerContact,
      ].concat(selectedShipowner.chargerShipOwnerContacts || []);
    }
    this.isEdittingContacts = true;
    if (this.tableShipowners) {
      if (this.tableShipowners.expandedRowKeys) {
        this.tableShipowners.expandedRowKeys[shipowner.id] = true;
      }
    }
  }
  saveContact(shipowner: Shipowner, contact: ShipOwnerContact) {
    let existsContact = false;
    const email = this.formContacts.get("email")?.value;
    const allContacts: any[] = [];
    const messagesTranslate = { mail: "", phone: "", domain: "", exists: "" };
    const res = this.translate.get("MESSAGES.VALIDATION");
    messagesTranslate.mail = res.MAIL;
    messagesTranslate.phone = res.PHONE;
    messagesTranslate.domain = res.MAIL_DOMAIN;
    messagesTranslate.exists = res.CONTACT_EXIST;
    const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const regexPhoneNumber = /^\+?(?:[0-9] ?){6,14}[0-9]$/;
    const testEmail = this.formContacts.get("email")?.value;
    const testPhone = this.formContacts.get("phone")?.value;
    const isOkEmail = regexEmail.test(testEmail);
    const isOkPhone = regexPhoneNumber.test(testPhone);
    let peticionObservable = null;
    let newShipownerId = "";
    const matchedShipOwner = this.listShipowners.find(
      (shipOwner) =>
        shipOwner.charteringCompany?.toLowerCase() ===
        shipowner.charteringCompany?.toLowerCase()
    );
    if (matchedShipOwner?.shipOwnerId && matchedShipOwner.shipOwnerId != "") {
      newShipownerId = matchedShipOwner.shipOwnerId;
      peticionObservable =
        this.shipOwnerService.GetEmailPrincipalContact(newShipownerId);
    }
    if (!isOkEmail || !isOkPhone) {
      const message = !isOkEmail
        ? messagesTranslate.mail
        : messagesTranslate.phone;
      this.messagesService.showToast("ERROR", message, "error", "");
      return;
    }

    if (!contact.id) {
      //this.listShipowners.forEach(x =>{
      shipowner.chargerShipOwnerContacts?.forEach((c) => {
        if (c.email && c.email == email) {
          existsContact = true;
          return;
        }
      });
    }
    if (existsContact) {
      this.messagesService.showToast(
        "WARNING",
        messagesTranslate.exists,
        "warning",
        ""
      );
      return;
    }
    console.log(allContacts);

    if (peticionObservable) {
      peticionObservable.subscribe({
        next: (result) => {
          console.log(result);
          if (result) {
            let emailPrincipal = "";
            emailPrincipal = result;
            const domainprincipal = this.utils.getEmailDomain(emailPrincipal);
            const domainContact = this.utils.getEmailDomain(email);
            if (domainprincipal != domainContact) {
              this.messagesService.showToast(
                "WARNING",
                messagesTranslate.domain + " : @" + domainprincipal,
                "warning",
                ""
              );
              return;
            }
          }
          this.sendToAddNewContact(shipowner, contact);
        },
        error: (error) => {
          console.log("Err endpoint getemailprincipal", error);
          this.sendToAddNewContact(shipowner, contact);
        },
      });
    } else {
      this.sendToAddNewContact(shipowner, contact);
    }
  }
  sendToAddNewContact(shipowner: any, contact: any) {
    let contactUpdated = shipowner.chargerShipOwnerContacts?.find(
      (x: any) => x.id && x.email == contact.email
    );
    if (!contactUpdated) {
      contactUpdated = new ChargerShipOwnerContact();
      contactUpdated = { ...this.formContacts.value };
    } else {
      const email = contactUpdated.email;
      contactUpdated = { ...this.formContacts.value };
      if (contactUpdated) {
        contactUpdated.email = email;
      }
    }
    if (contactUpdated) {
      contactUpdated.chargerShipOwnerId = shipowner.id;
      //contactUpdated.shipOwner = shipowner;
      contactUpdated.editable = false;
      contact.editable = false;
      console.log(contact);

      this.isEdittingContacts = false;
      if (contact.id) {
        this.spinner.show();
        contactUpdated.id = contact.id;
        const contactFind = shipowner?.chargerShipOwnerContacts?.find(
          (x: any) => x.id && x.id == contact.id
        );
        if (contactFind) {
          const index =
            shipowner.chargerShipOwnerContacts?.indexOf(contactFind) || -1;

          this.shipownerChargerService.editContact(contactUpdated).subscribe({
            next: (cont) => {
              if (contactUpdated) {
                contactUpdated.chargerShipOwner = shipowner;
                if (!shipowner.chargerShipOwnerContacts) {
                  shipowner.chargerShipOwnerContacts = [];
                }
                shipowner.chargerShipOwnerContacts[index] = contactUpdated;
                this.reloadList();
                // udpate contact
                this.formContacts.reset();
              }
            },
            error: (err) => {
              this.spinner.hide();
              console.error("Error update", err);
            },
          });
        }
      } else {
        this.spinner.show();
        //add contact
        this.usersService.getUsers().subscribe({
          next: (users) => {
            this.identityUsers = users;
            //TODO cambiar

            this.addUser().subscribe({
              next: (result: any) => {
                if (contactUpdated) {
                  //TODO cambiar
                  if (result.id != null) {
                    contactUpdated["userId"] = result.id;
                  }
                  //create shipownercontact
                  this.shipownerChargerService
                    .addContact(contactUpdated)
                    .subscribe({
                      next: (cont) => {
                        // udpate contact
                        this.formContacts.reset();
                        shipowner.chargerShipOwnerContacts?.shift();
                        cont.chargerShipOwner = shipowner;
                        shipowner.chargerShipOwnerContacts?.push(cont);
                        shipowner["counterContacts"] =
                          shipowner.chargerShipOwnerContacts?.length;
                        this.formContacts.reset();
                        this.isEdittingContacts = false;
                        this.reloadList();
                      },
                      error: (err) => {
                        console.error("Error create", err);
                        this.spinner.hide();
                        contact.editable = true;
                      },
                    });
                }
              },
              error: (err: any) => {
                this.spinner.hide();
                console.error(err);
              },
            });
          },
          error: (err) => {
            this.spinner.hide();
            console.error(err);
          },
        });
      }
    }
  }
  reloadList() {
    this.chargerService.getById(this.charger.id || "").subscribe({
      next: (charger) => {
        this.charger = charger;
        if (this.globals.currentCharger) {
          this.globals.currentCharger = this.charger;
        }
        this.spinner.hide();
        this.mapLists();
        this.listShipowners = [];
        this.createListSelectedShipOwners(this.charger);

        if (this.formFilter.get("filter")?.value != "") {
          this.filterLists(this.formFilter.get("filter")?.value);
        }
        //if (this.filteredTextShipowners) {
        this.filterData(this.filteredTextShipowners);


        // }
      },
      error: (err) => {
        this.spinner.hide();
        console.error(err);
      },
    });
  }

  addUser(): Observable<any> {
    const user = new User();
    user.userName = this.formContacts.get("email")?.value;
    user.email = this.formContacts.get("email")?.value;
    user.firstName = this.formContacts.get("name")?.value;
    user.lastName = this.formContacts.get("lastName")?.value;
    user.phoneNumber = this.formContacts.get("phone")?.value;
    let userId = "";
    if (user.email) {
      let existsUser = false;
      const userEdit = new UserEdit();
      let userBd;

      for (const userIdent of this.identityUsers) {
        if (userIdent.email === user.email) {
          existsUser = true;
          userId = userIdent.id;
        }
      }

      const newPassword = this.utils.generatePassword();

      if (!user.tenants || user.tenants.length === 0) {
        user.tenants = [];
        const currentTenant = this.accountService.currentTenant;
        user.tenants.push(currentTenant);
      }

      user.id = existsUser ? userId : undefined;

      if (!existsUser) {
        user.isEnabled = false;
        if (!user.permissions) {
          user.permissions = [];
        }
        this.neededPermissions.forEach((per) => {
          if (!user.permissions.map((x: any) => x.value).includes(per)) {
            const permission =
              this.permissions.find((x: any) => x.value == per) ||
              new Permission();
            user.permissions.push(permission);
          }
        });
        this.neededRoles.forEach((r) => {
          if (!user.roles.map((x: any) => x.name).includes(r)) {
            const role = this.roles.find((x: any) => x.name == r) || new Role();
            user.roles.push(role);
          }
        });
        return this.accountService.newUser(
          new UserEdit(user, newPassword, newPassword)
        );
      } else {
        console.log("check availability");
        return of({ id: userId });
      }
    } else {
      return of({ id: userId });
    }
  }
  setEditableContact(contact: any) {
    contact.editable = true;
    this.isEdittingContacts = true;
    this.formContacts.get("email")?.setValue(contact.email);
    this.formContacts.get("name")?.setValue(contact.name);
    this.formContacts.get("lastName")?.setValue(contact.lastName);
    this.formContacts.get("phone")?.setValue(contact.phone);
    this.formContacts.get("comment")?.setValue(contact.comment);
  }
  cancelEditContact(contact: any, shipowner: any) {
    this.spinner.show();
    contact.editable = false;
    if (contact.id) {
      contact = shipowner.chargerShipOwnerContacts.find(
        (x: any) => x.id == contact.id
      );
      // contact.editable = false
    } else {
      shipowner.chargerShipOwnerContacts.shift();
    }
    this.isEdittingContacts = false;
    this.formContacts.reset();
    this.spinner.hide();
  }

  deleteContact(contact: any, shipowner: any) {
    //TODO añadir confirmación
    const trmessage = this.translate.get("COMMONS.ASK_DELETE_RECORD");
    const messageAccept = this.translate.get("ACTION.YES");
    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.spinner.show();
        this.shipownerChargerService.deleteContact(contact.id).subscribe({
          next: (ship) => {
            shipowner.chargerShipOwnerContacts.splice(
              shipowner.chargerShipOwnerContacts.indexOf(contact),
              1
            );
            shipowner.counterContacts =
              shipowner.chargerShipOwnerContacts.length;
            this.listShipowners = [...this.listShipowners];
            this.reloadList();
            this.spinner.hide();
          },
          error: (err) => {
            this.spinner.hide();
            console.error(err);
          },
        });
      },
      reject: () => { },
    });
  }

  initialForm() {
    this.form = this.fb.group({
      charteringCompany: new FormControl("", [Validators.required]),
      dischargeDate: new FormControl(this.today, []),
      isSelected: new FormControl(false, []),
      comment: new FormControl("", []),
    });
    this.formContacts = this.fb.group({
      email: new FormControl("", [Validators.required]),
      name: new FormControl("", [Validators.required]),
      lastName: new FormControl("", []),
      phone: new FormControl("", [Validators.required]),
      comment: new FormControl("", []),
    });
  }
  setForm() {
    this.form.get("dischargeDate")?.setValue(this.today);
    this.form.get("isSelected")?.setValue(false);
  }
  createListSelectedShipOwners(charger: Charger) {
    if (this.charger.shipOwners) {
      for (const shipownerCharger of this.charger.shipOwners) {
        const shipowner = new Shipowner();

        if (!shipownerCharger.chargerShipOwnerContacts) {
          shipownerCharger.chargerShipOwnerContacts = [];
        }
        shipownerCharger.charteringCompany =
          shipownerCharger.shipOwner?.charteringCompany;
        shipownerCharger.counterContacts =
          shipownerCharger.chargerShipOwnerContacts.length;

        this.listShipowners.push(shipownerCharger);
      }
      this.listShipowners = [...this.listShipowners];
    }
    console.log(this.listShipowners);
  }
  filterData(event: any) {
    this.tableShipowners.filteredValue =
      this.filtersTable.chargerShipownerContactFilter(
        event,
        this.listShipowners,
        this.tableShipowners,
        "shipowners"
      );
  }

  modifyListSshipownersList() {
    this.allShipowners = [...this.allShipowners];
    this.listAllShipowners = [
      ...this.allShipowners.filter(
        (x) =>
          !this.listShipowners
            .map((c) => c.charteringCompany)
            .includes(x.charteringCompany)
      ),
    ];
    this.listAllShipownersNames = [
      ...this.allShipowners.map((l) => l.charteringCompany || ""),
    ];
  }
  mapLists(expanded = true) {
    const lists = [...this.charger.contactLists];
    this.listsOfLists = [];
    lists.forEach((list) => {
      const listMapped = new ContactList();
      listMapped.expanded = expanded;
      listMapped.name = list.name;
      listMapped.id = list.id;
      listMapped.shipOwners = [];
      list.contacts?.forEach((contact: any) => {
        const chargerShipOwnerContact = this.listShipowners.find((x) =>
          x.chargerShipOwnerContacts
            ?.map((c) => c.id)
            .includes(contact.chargerShipOwnerContactId)
        );
        if (chargerShipOwnerContact) {
          const charteringCompany =
            chargerShipOwnerContact.shipOwner?.charteringCompany;
          if (
            !listMapped.shipOwners
              ?.map((x: any) => x.charteringCompany)
              .includes(charteringCompany)
          ) {
            const newShipowner = {
              charteringCompany: charteringCompany,
              chargerShipOwnerContacts: [contact.chargerShipOwnerContact],
            };
            listMapped.shipOwners?.push(newShipowner);
          } else {
            const shipownerInList = listMapped.shipOwners.find(
              (x: any) => x.charteringCompany == charteringCompany
            );
            shipownerInList.chargerShipOwnerContacts.push(
              contact.chargerShipOwnerContact
            );
          }
        }
      });
      listMapped.isListOfAll = listMapped.name === "TODOS" ? true : false;
      this.listsOfLists.push(listMapped);
    });
    this.listsOfLists.forEach((list) => {
      list.filteredShipOwners = [...(list.shipOwners || [])];
      let counterContacts = 0;
      list.filteredShipOwners.forEach((ship) => {
        ship.filteredContacts = [...ship.chargerShipOwnerContacts];
        counterContacts += ship.chargerShipOwnerContacts.length;
      });
      list.counterContacts = list.shipOwners?.length;
    });
    this.filteredLists = [...this.listsOfLists];
  }
  openNewShipownerDialog() {
    const ref = this.dialogService.open(
      NewChargerShipownerDialogComponent,
      {
        blockScroll: true,
        data: {
          chargerId: this.charger.id,
          listShipowners: this.listShipowners,
          identityUsers: this.identityUsers,
          permissions: this.permissions,
          roles: this.roles,
          allShipowners: this.allShipowners,
          listAllShipowners: this.listAllShipowners
        },

      },
      {
        width: 'auto',
        height: 'auto',
        styleClass: 'dialog-new-charger-shipowner',

      }
    );

    ref.onClose.subscribe((data: any) => {
      console.log(data);
      if (data?.shipowner) {
        this.reloadList();
      }
    });
  }
  addNewShipowner() {
    // this.setForm();
    this.openNewShipownerDialog();
    //this.isNewShipowner = true;
    // console.log(this.isNewShipowner);
    // this.newShipOwner = new ChargerShipOwner();
    // this.newShipOwner.isNew = true;
    // // console.log(this.newShipOwner);
    // this.listShipowners.unshift(this.newShipOwner);
    // // console.log(this.listShipowners);
    // this.listShipowners = [...this.listShipowners];
  }
  saveNewShipowner() {
    const name = this.form.get("charteringCompany")?.value;
    if (
      this.listAllShipowners.find(
        (x: any) => x.charteringCompany.toLowerCase() == name.toLowerCase()
      )
    ) {
      this.onTheList = true;
    } else {
      this.onTheList = false;
    }

    if (
      this.listShipowners
        .filter((x: any) => x.id)
        .map((l) => l.charteringCompany?.trim())
        .includes(name.trim())
    ) {
      this.messagesService.showToast(
        "WARNING",
        "Este armador ya existe",
        "warning",
        ""
      );
      return;
    }
    const shipownerCharger = new ChargerShipOwner();
    shipownerCharger.isSelected = this.form.get("isSelected")?.value;
    shipownerCharger.comment = this.form.get("comment")?.value;
    shipownerCharger.shipOwner = new Shipowner();
    shipownerCharger.shipOwner.charteringCompany = name;
    shipownerCharger.chargerId = this.charger.id;
    // var shipownerCharger = this.mapShipownerToChargerShipowner(this.selectedShipOwner);
    this.spinner.show();
    this.shipownerChargerService.add(shipownerCharger).subscribe({
      next: (ship) => {
        // this.shipOwnerService.getById(ship.shipOwnerId).subscribe({
        //   next:(shipowner) => {
        ship.counterContacts = ship.chargerShipOwnerContacts?.length;
        ship.charteringCompany = shipownerCharger.shipOwner?.charteringCompany;
        ship.shipOwner = shipownerCharger.shipOwner;
        //ship.chargerShipOwnerContacts =
        this.listShipowners.push(ship);
        this.isNewShipowner = false;
        this.spinner.hide();
        if (!this.onTheList) {
          this.messagesService.showToast(
            "INFO",
            "MESSAGES.INFO.SHIPOWNER_IS_BANNED",
            "info",
            ""
          );
        }
        this.form.reset();
        this.newShipOwner = undefined;
        const indice = this.listShipowners.findIndex(
          (ship) => ship.isNew === true
        );
        this.listShipowners.splice(indice, 1);
        this.listShipowners = [...this.listShipowners];
        this.listAllShipowners = [
          ...this.allShipowners.filter(
            (x) =>
              !this.listShipowners
                .map((c) => c.charteringCompany)
                .includes(x.charteringCompany)
          ),
        ];
        this.reloadList();
        //   },
        //   error: (err) =>{
        //     console.error(err);
        //   }
        // });
      },
      error: (err) => {
        this.spinner.hide();
        console.error(err);
      },
    });
  }
  cancelNewShipowner() {
    this.spinner.show();
    this.newShipOwner = undefined;
    this.form.reset();
    this.isNewShipowner = false;
    const indice = this.listShipowners.findIndex((ship) => ship.isNew === true);

    this.listShipowners.splice(indice, 1);
    this.listShipowners = [...this.listShipowners];
    this.spinner.hide();
  }
  editShipowner(shipownerEdit: ChargerShipOwner) {
    shipownerEdit.isEdit = true;
    this.isEditingShipowner = true;
    this.initialShipOwner = { ...shipownerEdit };
  }
  saveShipowner(shipownerEdit: ChargerShipOwner) {
    shipownerEdit.isEdit = false;
    this.spinner.show();
    this.shipownerChargerService.edit(shipownerEdit).subscribe({
      next: (ship) => {
        this.initialShipOwner = undefined;
        this.isEditingShipowner = false;
        const index = this.listShipowners.indexOf(shipownerEdit);
        //ship.counterContacts = ship.chargerShipOwnerContacts.length;
        ship.charteringCompany = ship.shipOwner?.charteringCompany;
        this.listShipowners[index] = ship;
        this.reloadList();

        // this.listShipowners = [...this.listShipowners];
      },
      error: (err) => {
        this.spinner.hide();
        console.error(err);
      },
    });
  }
  cancelShipowner(shipownerEdit: ChargerShipOwner) {
    this.spinner.show();
    console.log(shipownerEdit);
    shipownerEdit.comment = this.initialShipOwner?.comment;
    shipownerEdit.isSelected = this.initialShipOwner?.isSelected;
    shipownerEdit.isEdit = false;
    console.log(shipownerEdit);
    this.isEditingShipowner = false;
    this.initialShipOwner = undefined;
    this.spinner.hide();
  }
  deleteShipowner(shipownerEdit: ChargerShipOwner) {
    const trmessage = this.translate.get("COMMONS.ASK_DELETE_RECORD");
    const messageAccept = this.translate.get("ACTION.YES");
    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.spinner.show();
        const indice = this.listShipowners.findIndex(
          (ship) => ship.id === shipownerEdit.id
        );
        // if (shipownerEdit.id) {
        //   indice = this.listShipowners.findIndex(
        //     (ship) => ship.id === shipownerEdit.id
        //   );
        // } else {
        //   indice = this.listShipowners.findIndex(
        //     (ship) => ship.charteringCompany === shipownerEdit.charteringCompany
        //   );
        // }
        this.shipownerChargerService.delete(shipownerEdit.id || "").subscribe({
          next: (ship) => {
            this.listShipowners.splice(indice, 1);
            this.listShipowners = [...this.listShipowners];
            this.listAllShipowners = [
              ...this.allShipowners.filter(
                (x) =>
                  !this.listShipowners
                    .map((c) => c.charteringCompany)
                    .includes(x.charteringCompany)
              ),
            ];
            this.reloadList();
          },
          error: (err) => {
            this.spinner.hide();
            console.error(err);
          },
        });
      },
      reject: () => { },
    });
  }

  filterChartering(event: any) {
    const shipownersCharger = this.listShipowners.map(
      (l) => l.charteringCompany
    );
    this.listAllShipowners = [
      ...this.allShipowners.filter(
        (x: any) => !shipownersCharger.includes(x.charteringCompany)
      ),
    ];
    console.log(event);
    const filtered: any[] = [];
    const query = event.query;

    for (let i = 0; i < this.listAllShipowners.length; i++) {
      const shipowner = this.listAllShipowners[i];
      if (
        shipowner.charteringCompany &&
        shipowner.charteringCompany
          .toLowerCase()
          .indexOf(query.toLowerCase()) >= 0
      ) {
        filtered.push(shipowner);
      }
    }

    this.listAllShipowners = filtered;
    this.listAllShipownersNames = filtered.map((x: any) => x.charteringCompany);
  }
  onItemSelect(event: any) {
    console.log(event);
    this.selectedShipOwner = event;
    this.onTheList = true;
  }

  drop(event: any, list: any) {
    if (this.readonly) {
      return;
    }
    this.spinner.show();
    console.log(event, list);
    let hadAdd = false;
    let hadAddContact = false;
    if (this.selectedContact) {
      if (
        !list.shipOwners
          .map((x: any) => x.charteringCompany)
          .includes(this.selectedContact.chargerShipOwner.charteringCompany)
      ) {
        list.shipOwners.push({
          charteringCompany:
            this.selectedContact.chargerShipOwner.charteringCompany,
          chargerShipOwnerContacts: [this.selectedContact],
        });
        hadAdd = true;
        hadAddContact = true;
      } else {
        const ship = list.shipOwners.find(
          (x: any) =>
            x.charteringCompany ==
            this.selectedContact.chargerShipOwner.charteringCompany
        );
        if (
          !ship.chargerShipOwnerContacts
            ?.map((c: any) => c.email)
            .includes(this.selectedContact.email)
        ) {
          ship.chargerShipOwnerContacts.push(this.selectedContact);
          hadAdd = false;
          hadAddContact = true;
        }
      }
    }
    if (hadAdd || hadAddContact) {
      this.filter = "";
      list.filteredShipOwners = [...list.shipOwners];
      let counterContacts = 0;
      list.filteredShipOwners.forEach((ship: any) => {
        ship.filteredContacts = [...ship.chargerShipOwnerContacts];
        counterContacts += ship.chargerShipOwnerContacts.length;
      });

      list.counterContacts = list.shipOwners.length;
    }
    const newContactList = new ContactListChargerShipownerContact();
    newContactList.contactListId = list.id;
    newContactList.chargerShipOwnerContactId = this.selectedContact.id;
    console.log(newContactList);
    this.selectedContact = null;
    if (hadAddContact) {
      this.shipownerChargerService.addContactList(newContactList).subscribe({
        next: (result) => {
          this.spinner.hide();
          console.log(result);
        },
        error: (err) => {
          this.spinner.hide();
          console.error("Error addContactList", err);
        },
      });
    }

    this.spinner.hide();
    // this.data = [...this.data];
    //TODO save order data
  }
  dragStart(e: any, c: any) {

    if (this.listsContainer) {
      this.listsContainer.nativeElement?.classList.add('dragging-item');
    }


    console.log(e);
    console.log(c);
    c.isDragging = true;
    const contact = c;
    const shipownerCharger = this.listShipowners.find(
      (x: any) => x.id == c.chargerShipOwnerId
    );
    contact.chargerShipOwner = shipownerCharger;
    this.selectedContact = c;
  }

  showListDialog() {
    const ref = this.dialogService.open(NewListDialogComponent, {

    },
      {
        width: 'auto',
        height: 'auto',
        styleClass: 'dialog-new-list-shipowner',

      })
    ref.onClose.subscribe(res => {
      if (res) {
        this.listName = res.name;
        this.addList();

      }
    })
  }

  dragEnd(e: any, c: any) {
    if (this.listsContainer && this.listsContainer.nativeElement?.classList.contains('dragging-item')) {
      this.listsContainer.nativeElement?.classList.remove('dragging-item');
    }

    console.log(e);
    console.log(c);
    c.isDragging = false;
    this.selectedContact = null;
  }
  searchNameInTheList(): boolean {
    let onTheList = false;
    this.listsOfLists.forEach((item) => {
      if (item.name?.toLowerCase() == this.listName.toLowerCase()) {
        onTheList = true;
      }
    });
    return onTheList;
  }
  addList() {
    if (this.listName?.trim() == "") {
      return;
    } else {
      const onTheList = this.searchNameInTheList();

      if (onTheList) {
        this.messagesService.showToast(
          "ERROR",
          "SHIPOWNER_DATA.EXISTS_LIST",
          "error",
          ""
        );
        return;
      } else {
        const list = {
          name: this.listName,
          chargerId: this.charger.id,
        };
        this.spinner.show();
        this.shipownerChargerService.addList(list).subscribe({
          next: (newList) => {
            newList.expanded = true;
            newList.shipOwners = [];
            newList.filteredShipOwners = [];
            newList.counterContacts = 0;
            this.listsOfLists.push(newList);
            this.filteredLists = [...this.listsOfLists];
            this.listName = "";
            this.spinner.hide();
          },
          error: (err) => {
            this.spinner.hide();
            console.error("Error addList", err);
          },
        });
      }
    }
  }
  editList(list: any) {
    const name = this.formFilter.get("listName")?.value;
    if (name.trim() != "") {
      const onTheList = this.searchNameInTheList();
      if (onTheList) {
        this.messagesService.showToast(
          "ERROR",
          "SHIPOWNER_DATA.EXISTS_LIST",
          "error",
          ""
        );
        return;
      } else {
        list.name = name;
        const newList = {
          name: list.name,
          id: list.id,
          tenantId: list.tenantId,
        };
        this.spinner.show();
        this.shipownerChargerService.editList(newList).subscribe({
          next: (res) => {
            this.reloadList();
          },
          error: (err) => {
            this.spinner.hide();
            console.error("Error editList", err);
          },
        });
      }
    }
  }
  setEditableList(list: any) {
    this.formFilter.get("listName")?.setValue(list.name);
    list.editable = true;
  }
  deleteList(list: ContactList) {
    this.spinner.show();
    this.shipownerChargerService.deleteList(list).subscribe({
      next: (res) => {
        this.reloadList();
      },
      error: (err) => {
        this.spinner.hide();
        console.error("Error addList", err);
      },
    });
  }
  deleteShipownerList(list: any, shipowner: any) {
    console.log(list, shipowner);
    const subscriptions: any[] = [];
    shipowner.chargerShipOwnerContacts.forEach(
      (contact: any, index: number) => {
        const contactDelete = new ContactListChargerShipownerContact();
        contactDelete.chargerShipOwnerContactId = contact.id;
        contactDelete.contactListId = list.id;

        subscriptions.push(
          this.shipownerChargerService.deleteContactList(contactDelete)
        );
      }
    );
    this.spinner.show();
    forkJoin(subscriptions).subscribe({
      next: (res) => {
        this.reloadList();
      },

      error: (err) => {
        this.spinner.hide();
        console.error("Error addList", err);
      },
    });
  }
  deleteContactList(contact: any, list: any) {
    this.spinner.show();
    console.log(contact);
    const contactDelete = new ContactListChargerShipownerContact();
    contactDelete.chargerShipOwnerContactId = contact.id;
    contactDelete.contactListId = list.id;

    this.shipownerChargerService.deleteContactList(contactDelete).subscribe({
      next: (res) => {
        this.reloadList();
      },
      error: (err) => {
        this.spinner.hide();
        console.error("Error addList", err);
      },
    });
  }
}
