import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, ReplaySubject } from "rxjs";
import { ConfigsService } from "@overa/shared";
import { ChatParticipant } from "../models/chat/chatParticipant.model";
import { CLHBaseService } from "./clh-base.service";

@Injectable({ providedIn: "root" })
export class ChatParticipantService extends CLHBaseService {
  private chatParticipantSubject: ReplaySubject<boolean> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public chatParticipantObservable: Observable<boolean> =
    this.chatParticipantSubject.asObservable();

  private routechatParticipantSubject: ReplaySubject<string> =
    new ReplaySubject(1);
  public routechatParticipantObservable: Observable<string> =
    this.routechatParticipantSubject.asObservable();

  get(pageIndex = -1, pageSize = -1) {
    return this.http.get<ChatParticipant[]>(
      `${this.baseUrl}/chatParticipant?pageIndex=${pageIndex}&pageSize=${pageSize}`
    );
  }

  getById(id: string) {
    return this.http.get<ChatParticipant>(
      `${this.baseUrl}/chatParticipant/${id}`
    );
  }

  add(chatParticipant: ChatParticipant) {
    const endpointUrl = `${this.baseUrl}/chatParticipant`;
    return this.http.post<ChatParticipant>(endpointUrl, chatParticipant);
  }

  edit(chatParticipant: ChatParticipant) {
    const endpointUrl = `${this.baseUrl}/chatParticipant`;
    return this.http.put<ChatParticipant>(endpointUrl, chatParticipant);
  }

  delete(id: string) {
    const endpointUrl = `${this.baseUrl}/chatParticipant/${id}`;
    return this.http.delete(endpointUrl);
  }

  getChatParticipantByUserId(userId: string) {
    return this.http.get<ChatParticipant>(
      `${this.baseUrl}/chatParticipant/getParticipantByUserId/${userId}`
    );
  }
}
