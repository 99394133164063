import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { PrivacyPolicies } from 'src/app/models/privacy-policies/privacy-policies.model';
import { PermissionsService } from 'src/app/services/permissions.service';
import { PrivacyPoliciesService } from 'src/app/services/privacy-policies.service';

@Component({
  selector: 'app-privacy-policies-view',
  templateUrl: './privacy-policies-view.component.html',
  styleUrls: ['./privacy-policies-view.component.scss'],
})
export class PrivacyPoliciesViewComponent {
 
}
