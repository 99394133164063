<div
  *ngFor="let task of taskList"
  [ngClass]="'row kanban-card-header-content-inner ' + (isDetail ? 'kanban-element-detail ' : '  mb-4 mt-1 pb-4  ') + (task.classKanban ? (task.classKanban | translate) : '' )"
>
  <div class="kanban-button-detail">
    <span
      class="p-button-icon pi pi-chevron-up colorSpan"
      *ngIf="!isDetail"
      (click)="selectKanban(task)"
      [pTooltip]="'ACTION.EXPAND'| translate"
      tooltipPosition="bottom"
      tooltipStyleClass="tooltipCLH"
      aria-hidden="true"
    ></span>
    <span
      class="p-button-icon pi pi-chevron-down closechevron"
      *ngIf="isDetail"
      (click)="minimizeKanban(task)"
      [pTooltip]="'ACTION.COLLAPSE'| translate"
      tooltipPosition="bottom"
      tooltipStyleClass="tooltipCLH"
      aria-hidden="true"
    ></span>
  </div>
  <div *ngIf="isDetail" [ngClass]="'col-xl-12 col-md-12 col-xxl-12 align-middle text-left kanban-card-header-content-inner-top top-detail'">
    <div class="row">
      <div [ngClass]=" 'col-xl-6 col-md-6 col-xxl-6 align-middle text-left pt-2 kanban-card-header-content-inner-top '">
        <ng-container>
          <p overaellipsis class="tooltip-p" *ngIf="task.isShipowner"><strong>{{"KANVAN.CARD.ID" | translate}}:</strong> {{task.offer.refOffer}}</p>
          <p
            overaellipsis
            [ngClass]="(isDetail && !task.isShipowner) ? 'tooltip-p margin-top-bottom-cardExpanded' : 'tooltip-p'"
            *ngIf="!task.isShipowner"
          >
            <strong>{{"KANVAN.CARD.REF" | translate}}:</strong> {{task.offer.refOffer}}
          </p>
        </ng-container>
      </div>
      <div *ngIf="task.offer.charger" [ngClass]="'col-xl-6 col-md-6 col-xxl-6 align-middle text-left pt-2 kanban-card-header-content-inner-top '">
        <p overaellipsis [ngClass]="(isDetail && !task.isShipowner) ? 'tooltip-p margin-top-bottom-cardExpanded' : 'tooltip-p'">
          <strong>{{"KANVAN.CARD.RELEASE" | translate}}:</strong> {{task.offer.releaseDate | date: 'dd/MM/yy'}}
        </p>
      </div>
    </div>
  </div>
  <div [ngClass]=" 'col-xl-6 col-md-6 col-xxl-6 align-middle text-left pt-2 kanban-card-header-content-inner-top '" *ngIf="!isDetail">
    <ng-container>
      <p overaellipsis class="tooltip-p" *ngIf="task.isShipowner"><strong>{{"KANVAN.CARD.ID" | translate}}:</strong> {{task.offer.refOffer}}</p>
      <p overaellipsis [ngClass]="(isDetail && !task.isShipowner) ? 'tooltip-p margin-top-bottom-cardExpanded' : 'tooltip-p'" *ngIf="!task.isShipowner">
        <strong>{{"KANVAN.CARD.REF" | translate}}:</strong> {{task.offer.refOffer}}
      </p>
    </ng-container>
  </div>
  <div
    *ngIf="task.offer.charger && !isDetail "
    [ngClass]="'col-xl-6 col-md-6 col-xxl-6 align-middle text-left pt-2 kanban-card-header-content-inner-top '"
  >
    <p overaellipsis [ngClass]="(isDetail && !task.isShipowner) ? 'tooltip-p margin-top-bottom-cardExpanded' : 'tooltip-p'">
      <strong>{{"KANVAN.CARD.RELEASE" | translate}}:</strong> {{task.offer.releaseDate | date: 'dd/MM/yy'}}
    </p>
  </div>
  <div
    *ngIf="!task.isShipowner"
    [ngClass]="'col-xl-12 col-md-12 col-xxl-12 align-middle text-left kanban-card-header-content-inner-top notpaddingtop'"
  >
    <div class="kanban-card-header-detail my-ref-title">
      <hr class="separator-header-detail notmargintop" />
      <p overaellipsis class="notuppercase centerText" *ngIf="task.offer.code">{{task.offer.code}}</p>
      <p overaellipsis class="notuppercase centerText" *ngIf="!task.offer.code"><br /></p>
      <hr class="separator-header-detail" />
    </div>
  </div>
  <div [ngClass]="'col-xl-6 col-md-6 col-xxl-6 align-middle text-left kanban-card-header-content-inner-top notpaddingtop'">
    <p overaellipsis><strong>{{"KANVAN.CARD.POL" | translate}}:</strong> {{task.offer.loadingPorts.join(', ')}}</p>
  </div>
  <div
    *ngIf="task.offer.charger"
    [ngClass]="'col-xl-6 col-md-6 col-xxl-6 align-middle text-left pt-2 kanban-card-header-content-inner-top notpaddingtop'"
  >
    <p overaellipsis class="tooltip-p" [attr.data-text]="task.offer.dischargingPorts.join(', ')">
      <strong>{{"KANVAN.CARD.POD" | translate}}:</strong> {{task.offer.dischargingPorts.join(', ')}}
    </p>
  </div>
  <div [ngClass]="'col-xl-12 col-md-12 col-xxl-12 align-middle text-left pt-2 kanban-card-header-content-inner-top notpaddingtop'">
    <!-- <div class="kanban-card-header-detail my-ref-title"> -->
    <p overaellipsis class="tooltip-p" [attr.data-text]="task.offer.currentLayCanFrom ">
      <strong>{{"KANVAN.CARD.LC" | translate}}:</strong> {{task.offer.currentLayCanFrom | date: 'dd/MM/yy' }} - {{task.offer.currentLayCanTo | date:
      'dd/MM/yy'}}
    </p>
    <p overaellipsis class="tooltip-p" *ngIf="task.isShipowner">
      <strong>{{"KANVAN.CARD.COMMODITY_SHIPOWNER" | translate}}:</strong> {{task.offer.currentCommodity}}
    </p>
    <p overaellipsis class="tooltip-p" *ngIf="task.isClh || !task.isShipowner">
      <strong>{{"KANVAN.CARD.COMMODITY" | translate}}:</strong> {{task.offer.currentCommodity}}
    </p>
    <!-- </div> -->
  </div>
  <div
    class="col-xl-12 col-md-12 col-xxl-12 align-middle text-left mt-2 kanban-card-header-content-inner-bottom"
    [ngClass]="!task.isClh && !task.isShipowner ? 'content-small' : task.isClh ? 'content-medium' : ''"
  >
    <div class="task-item card-check fontCorporate">
      <div *ngIf="task.description && !task.isShipowner">
        <mat-icon *ngIf="task.icon" [ngClass]="task.icon">{{task.icon}}</mat-icon>
        <ng-container *ngIf="task.routeRedirect ; else noLink">
          <a [routerLink]="[ task.routeRedirect ]" class="linkTask" routerLinkActive="active">{{task.description}} </a>
        </ng-container>
        <ng-template #noLink> {{task.description}} </ng-template>
      </div>
      <div *ngIf="task.description && task.isShipowner">
        <mat-icon *ngIf="task.icon" [ngClass]="task.icon">{{task.icon}}</mat-icon>
        <ng-container *ngIf="task.routeRedirect ; else noLink">
          <a [routerLink]="[task.routeRedirect ]" class="linkTask" routerLinkActive="active">{{task.description}} </a>
        </ng-container>
        <ng-template #noLink> {{task.description}} </ng-template>
      </div>
      <div *ngIf="task.descriptionComparative && !task.isShipowner" class="fontCorporate">
        <mat-icon *ngIf="task.iconComparative" [ngClass]="task.iconComparative">{{task.iconComparative}} </mat-icon>
        <ng-container *ngIf="task.routeRedirectComparative ; else noLink1">
          <a [routerLink]="[task.routeRedirectComparative ]" class="linkTask" routerLinkActive="active"> {{task.descriptionComparative}}</a>
        </ng-container>
        <ng-template #noLink1> {{task.descriptionComparative}} </ng-template>
      </div>
      <div *ngIf="task.descriptionNegotation" class="fontCorporate">
        <mat-icon *ngIf="task.iconNegotation" [ngClass]="task.iconNegotation">{{task.iconNegotation}} </mat-icon>
        <ng-container *ngIf="task.routeRedirectNegotation ; else noLink2">
          <a [routerLink]="[task.routeRedirectNegotation]" [queryParams]="{view: viewNegotiation }" class="linkTask" routerLinkActive="active">
            {{task.descriptionNegotation}}</a
          >
        </ng-container>
        <ng-template #noLink2> {{task.descriptionNegotation}} </ng-template>
      </div>
      <div *ngIf="task.descriptionRecap" class="fontCorporate">
        <mat-icon *ngIf="task.iconRecap" [ngClass]="task.iconRecap">{{task.iconRecap}} </mat-icon>
        <ng-container *ngIf="task.routeRedirectRecap ; else noLink3">
          <a [routerLink]="[ task.routeRedirectRecap ]" [queryParams]="{view: viewRecap }" class="linkTask" routerLinkActive="active">
            {{task.descriptionRecap}}
          </a>
        </ng-container>
        <ng-template #noLink3> {{task.descriptionRecap}} </ng-template>
      </div>
      <div *ngIf="task.descriptionOperative" class="fontCorporate">
        <mat-icon *ngIf="task.iconOperative" [ngClass]="task.iconOperative">{{task.iconOperative}} </mat-icon>
        <ng-container *ngIf="task.routeRedirectOperative ; else noLink4">
          <a [routerLink]="[ task.routeRedirectOperative]" [queryParams]="{view: viewOperative }" class="linkTask" routerLinkActive="active">
            {{task.descriptionOperative}}
          </a>
        </ng-container>
        <ng-template #noLink4> {{task.descriptionOperative}} </ng-template>
      </div>
    </div>
    <div *ngIf="isDetail">
      <div class="documents-accordion mb-3 mt-3" *ngIf="task.offerId">
        <p-accordion styleClass="accordionResponse">
          <p-accordionTab [selected]="true">
            <ng-template pTemplate="header">
              <p
                [escape]="false"
                [pTooltip]=" task.isShipowner ? ('TOOLTIP.DOCUMENT_DETAIL_SHIPOWNER'| translate) : ''"
                tooltipPosition="bottom"
                tooltipStyleClass="tooltipCLH"
                [showDelay]="1000"
                [hideDelay]="1000"
              >
                {{'DOCUMENT.HEADER' | translate}}
              </p></ng-template
            >
            <ng-template pTemplate="content">
              <app-documents
                [entityId]="task.offerId"
                [placeholder]="'DOCUMENT.SELECT' | translate"
                [groupId]="task.offerShipOwnerId"
              >
              </app-documents>
            </ng-template>
          </p-accordionTab>

          <p-accordionTab [selected]="true">
            <ng-template pTemplate="header"> {{'NOTE.HEADER' | translate}}</ng-template>
            <ng-template pTemplate="content">
              <overa-notes [entityId]="task.offerId" [groupId]="task.offerShipOwnerId"> </overa-notes>
            </ng-template>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>
    <!-- <p><a href="#">Ver Cargo Order Original</a></p>
        <p><a href="#">Ver Comparativa de Ofertas</a></p> -->
  </div>
</div>
