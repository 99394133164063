<div class="home-content">
    
    <div [ngClass]="'container-fluid ' + (selectedKanban != null ? ' selected-kanban' : '')">
        <div class="tab-view-home-container">
            <app-tab-view-home [markers]="markers" [selectedKanban]="selectedKanban" [allImos]="imos"></app-tab-view-home>
        </div>
        <div class="kanban-view">
            <app-kanban-view (onSelectKanban)="onSelectKanban($event)"></app-kanban-view>
        </div>

    </div>
</div>