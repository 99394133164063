import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConfigsService } from "@overa/shared";
import { PrivacyPolicies } from "../models/privacy-policies/privacy-policies.model";
import { CLHBaseService } from "./clh-base.service";

@Injectable({ providedIn: "root" })
export class PrivacyPoliciesService extends CLHBaseService {
  get() {
    return this.http.get<PrivacyPolicies[]>(`${this.baseUrl}/privacyPolicy/`);
  }

  getLast() {
    return this.http.get<PrivacyPolicies>(
      `${this.baseUrl}/privacyPolicy/getLast`
    );
  }

  getById(id: string) {
    return this.http.get<PrivacyPolicies>(
      `${this.baseUrl}/privacyPolicy/${id}`
    );
  }

  add(tca: PrivacyPolicies) {
    const endpointUrl = `${this.baseUrl}/privacyPolicy`;
    return this.http.post<PrivacyPolicies>(endpointUrl, tca);
  }

  edit(tca: PrivacyPolicies) {
    const endpointUrl = `${this.baseUrl}/privacyPolicy`;
    return this.http.put<PrivacyPolicies>(endpointUrl, tca);
  }

  delete(id: string) {
    const endpointUrl = `${this.baseUrl}/privacyPolicy/${id}`;
    return this.http.delete(endpointUrl);
  }
  mustAccept(userId: string) {
    let endpointUrl = `${this.baseUrl}/privacypolicy/mustAcceptPrivacyPolicy/${userId}`;
    return this.http.get<boolean>(endpointUrl);
  }
}
