import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, ReplaySubject } from "rxjs";
import { ConfigsService } from "@overa/shared";
import { Route } from "../models/charger/route.model";
import { CLHBaseService } from "./clh-base.service";

@Injectable({ providedIn: "root" })
export class VesselService extends CLHBaseService {
  //addRoute(route:Route){
  //    const endpointUrl = `${this.baseUrl}/route`;
  //    return this.http.post<Route>(endpointUrl, route);
  //}
  //deleteRoute(id: string){
  //    const endpointUrl = `${this.baseUrl}/route/${id}`;
  //    return this.http.delete(endpointUrl);
  //}
  //editRoute(route:Route){
  //    const endpointUrl = `${this.baseUrl}/route`;
  //    return this.http.put<Route>(endpointUrl, route);
  //}
  getVessels(
    imos: number[],
    voyage: boolean = false,
    master: boolean = false
  ): Observable<any> {
    let params = new HttpParams();
    imos.forEach((imo) => {
      params = params.append("imos", imo.toString());
    });
    params = params.set("voyage", voyage.toString());
    params = params.set("master", master.toString());

    return this.http.get(`${this.baseUrl}/vessel/vessels`, { params });
  }
  getInfo(offerShipownerId: string) {
    const endpointUrl = `${this.baseUrl}/vessel/info/${offerShipownerId}`;
    return this.http.get<any>(endpointUrl);
  }
}
