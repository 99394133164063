import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxTranslateService } from "@overa/shared";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Observable, forkJoin } from "rxjs";
import { TermsAndConditions } from "src/app/models/clause/terms-and-conditions.model";
import { PrivacyPolicies } from "src/app/models/privacy-policies/privacy-policies.model";
import { ChargerContactService } from "src/app/services/chargerContact.service";
import { PrivacyPoliciesService } from "src/app/services/privacy-policies.service";
import { ShipOwnerContactService } from "src/app/services/shipowner-contact.service";
import { TermsAndConditionsService } from "src/app/services/terms-and-conditions.service";
import { CLHGlobals } from "src/app/utils/globals";

@Component({
  selector: "app-terms-and-conditions-acceptance",
  templateUrl: "./terms-and-conditions-acceptance.component.html",
  styleUrls: ["./terms-and-conditions-acceptance.component.scss"],
})
export class TermsAndConditionsAcceptanceComponent
  implements OnInit, OnDestroy
{
  form: FormGroup = new FormGroup({});
  isChecked: boolean = false;
  textAreaValue: string = "";
  userId: string = "";
  userType: any;
  termsDate: Date;
  userWithDate: any;
  termsVigent: TermsAndConditions;
  termLast: TermsAndConditions;
  privacyLast: PrivacyPolicies;
  termLastDate: Date;
  termsAccepted: TermsAndConditions;
  acceptedTerms: boolean = false;
  languageNavigator: string;
  termsConditionsPrivacyLanguage: any = {
    es: "Términos y condiciones y Politica de privacidad",
    en: "Terms and conditions and Privacy Policy",
  };
  termsConditionsPrivacyTitle: string;
  termsConditionsLanguage: any = {
    es: "Términos y condiciones",
    en: "Terms and conditions",
  };
  termsConditionsTitle: string;
  privacyLanguage: any = { es: "Politica de privacidad", en: "Privacy Policy" };
  privacyTitle: string;
  acceptTermsLanguage: any = {
    es: "Aceptar Términos y condiciones y Politica de privacidad",
    en: "Accept terms and conditions and Privacy Policy",
  };
  acceptTermsTitle: string;
  acceptedTermsLanguage: any = {
    es: "Términos y politicas aceptados",
    en: "Accepted terms and privacy policy",
  };
  acceptedTermsTitle: string;
  buttonAcceptLanguage: any = { es: "Aceptar", en: "Accept" };
  buttonAcceptTitle: string;
  buttonCancelLanguage: any = { es: "Cancelar", en: "Cancel" };
  buttonCancelTitle: string;
  languagesTerms: string[] = [];
  @ViewChild("textAreaTermsConditions") textAreaTermsConditions: ElementRef;
  scrollFinishedTerms: boolean = false;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    public dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    private termsConditionsService: TermsAndConditionsService,
    private chargerContactService: ChargerContactService,
    private shipOwnerContactService: ShipOwnerContactService,
    private globals: CLHGlobals,
    private privacyPoliciesService: PrivacyPoliciesService,
    private translate: NgxTranslateService
  ) {
    if (dialogConfig?.data?.userId) this.userId = dialogConfig.data.userId;
    if (dialogConfig?.data?.userType)
      this.userType = dialogConfig.data.userType;
    if (dialogConfig?.data?.termsDate)
      this.termsDate = dialogConfig.data.termsDate;
    if (dialogConfig?.data) this.userWithDate = dialogConfig.data.userWithDate;
    if (dialogConfig?.data?.termLastDate)
      this.termLastDate = dialogConfig.data.termLastDate;
    if (dialogConfig?.data?.termLast)
      this.termLast = dialogConfig.data.termLast;
  }

  ngOnInit(): void {
    this.initForm();
    this.getAvailableLanguages();
    this.getLastTAC();
    this.subscribeToCheckboxChanges();
    this.changeTextAreaData();
  }

  initForm() {
    this.form = new FormGroup({
      terms: new FormControl("", [Validators.required]),
      checked: new FormControl(false, [Validators.required]),
      languageTerms: new FormControl([], []),
    });
  }
  getAvailableLanguages() {
    this.languagesTerms = this.translate.getLangs();
    let languageDefualt = this.translate.currentLang;
    console.log("LANGUAGES", this.languagesTerms, languageDefualt);
    if (this.globals.currentShipOwner) {
      languageDefualt = "en";
    }
    this.form.get("languageTerms").setValue(languageDefualt);
  }
  accept() {
    let contact: any;
    let subscription: any;
    if (this.userWithDate.chargerId != null) {
      contact = this.userWithDate;
      contact.termsAndConditionsAceptationDate = new Date();
      subscription = this.chargerContactService.edit(contact);
    } else if (this.userWithDate.shipOwnerId != null) {
      contact = this.userWithDate;
      contact.termsAndConditionsAceptationDate = new Date();
      subscription = this.shipOwnerContactService.edit(contact);
    }
    if (subscription)
      subscription.subscribe({
        next: (res) => {
          if (res) {
            this.acceptedTerms = true;
            if (res.termsAndConditionsAceptationDate >= this.termLastDate) {
              this.dialogRef.close({ acceptedTerms: true });
            } else {
              this.dialogRef.close({ acceptedTerms: false });
            }
          } else {
            this.dialogRef.close();
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  cancel() {
    this.router.navigateByUrl("/core/settings/profileLogOut");
  }
  private subscribeToCheckboxChanges() {
    this.form.get("checked").valueChanges.subscribe((isChecked: boolean) => {});
  }

  getLastTAC() {
    if (this.globals.currentShipOwner) {
      this.languageNavigator = "en";
    } else {
      this.languageNavigator = "es";
    }
    // this.languageNavigator = navigator.language;
    const privacyObservable: Observable<any> =
      this.privacyPoliciesService.getLast();
    const termsObservable: Observable<any> =
      this.termsConditionsService.getLast();
    forkJoin({
      privacy: privacyObservable,
      terms: termsObservable,
    }).subscribe({
      next: (result) => {
        if (result) {
          if (result.terms) {
            this.termLast = result.terms;
            this.termLastDate = result.terms.validityDate;
          }
          if (result.privacy) {
            this.privacyLast = result.privacy;
          }
          const language = this.languageNavigator.includes("es") ? "es" : "en";
          this.changeLanguageTermsConditions(language);
        }
      },
      error: (err) => {
        console.error("Error loading Data Protection && terms: " + err);
      },
    });
    // this.termsService.getLast().subscribe((value) => {
    //   this.termLast = value;
    //   this.termLastDate = value.validityDate;
    //   if (this.languageNavigator.includes('es')) {
    //     this.textAreaValue = this.termLast.terms;
    //     this.termsConditionsTitle = this.termsConditionsLanguage.es;
    //     this.acceptedTerms = this.acceptedTermsLanguage.es;
    //     this.acceptTermsTitle = this.acceptTermsLanguage.es;
    //     this.buttonAcceptTitle = this.buttonAcceptLanguage.es;
    //     this.buttonCancelTitle = this.buttonCancelLanguage.es;
    //   } else {
    //     this.textAreaValue = this.termLast.termsEN;
    //     this.termsConditionsTitle = this.termsConditionsLanguage.en;
    //     this.acceptedTermsTitle = this.acceptedTermsLanguage.en;
    //     this.acceptTermsTitle = this.acceptTermsLanguage.en;
    //     this.buttonAcceptTitle = this.buttonAcceptLanguage.en;
    //     this.buttonCancelTitle = this.buttonCancelLanguage.en;
    //   }
    //   this.changeTextAreaData();
    // });
  }
  changeLanguageTermsConditions(language: string) {
    if (language === "es") {
      this.termsConditionsTitle = this.termsConditionsLanguage.es;
      this.privacyTitle = this.privacyLanguage.es;
      this.textAreaValue = `\n${this.termsConditionsTitle}\n\n${this.termLast.terms}\n\n${this.privacyTitle}\n\n${this.privacyLast.policy}`;
      this.termsConditionsPrivacyTitle = this.termsConditionsPrivacyLanguage.es;
      this.acceptedTerms = this.acceptedTermsLanguage.es;
      this.acceptTermsTitle = this.acceptTermsLanguage.es;
      this.buttonAcceptTitle = this.buttonAcceptLanguage.es;
      this.buttonCancelTitle = this.buttonCancelLanguage.es;
    } else {
      this.termsConditionsTitle = this.termsConditionsLanguage.en;
      this.privacyTitle = this.privacyLanguage.en;
      this.textAreaValue = `\n${this.termsConditionsTitle}\n\n${this.termLast.termsEN}\n\n${this.privacyTitle}\n\n${this.privacyLast.policyEN}`;
      this.termsConditionsPrivacyTitle = this.termsConditionsPrivacyLanguage.en;
      this.acceptedTermsTitle = this.acceptedTermsLanguage.en;
      this.acceptTermsTitle = this.acceptTermsLanguage.en;
      this.buttonAcceptTitle = this.buttonAcceptLanguage.en;
      this.buttonCancelTitle = this.buttonCancelLanguage.en;
    }
    this.form.get("terms").setValue(this.textAreaValue);
    this.changeTextAreaData();
  }
  changeTextAreaData() {
    this.cd.detectChanges();
    // if (this.textAreaValue !== this.form.get('terms').getRawValue()) {
    //   this.form.get('terms').setValue(this.textAreaValue);
    // }
  }
  handleScroll(event: Event) {
    const textarea = this.textAreaTermsConditions.nativeElement; // Utiliza el nombre asignado con #
    const isScrolledToBottom =
      textarea.scrollHeight - textarea.clientHeight <= textarea.scrollTop + 1;

    if (isScrolledToBottom) {
      this.scrollFinishedTerms = true;
      this.isChecked = true;
      this.form.get("checked").setValue(this.isChecked);
    }
  }
  onChangeLanguage(event: any) {
    console.log(event);
    const language = event.value;
    this.scrollFinishedTerms = false;
    this.isChecked = false;
    this.form.get("checked").setValue(this.isChecked);
    // Reiniciar el desplazamiento del textarea al inicio
    const textarea = this.textAreaTermsConditions.nativeElement;
    textarea.scrollTop = 0;
    this.changeLanguageTermsConditions(language);
  }

  ngOnDestroy() {
    if (this.acceptedTerms == false) {
      this.router.navigateByUrl("/core/settings/profileLogOut");
    }
  }
}
