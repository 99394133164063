import { HttpClient } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { NbThemeModule } from "@nebular/theme";
import { AccountService } from "@overa/security";
import { ChatGroup } from "src/app/models/chat/chatGroup.model";
import { ChatMessage } from "src/app/models/chat/chatMessage.model";
import { ChatParticipant } from "src/app/models/chat/chatParticipant.model";
import { ChatMessageService } from "src/app/services/chatMessage.service";
import { SignalRGroupAdapterService } from "src/app/services/signalGroupAdapter.service";
import { ViewChildren, QueryList, ElementRef } from "@angular/core";
import { CLHGlobals } from "src/app/utils/globals";
import { ConfigsService } from "@overa/shared";

@Component({
  selector: "app-chat-room",
  templateUrl: "./chat-room.component.html",
  styleUrls: ["./chat-room.component.scss"],
  providers: [NbThemeModule.forRoot().providers || [], NbThemeModule],
})
export class ChatRoomComponent implements OnInit, OnDestroy, OnChanges {
  currentTheme = "dark-theme";
  triggeredEvents: string[] = [];
  @Input() offerShipOwnerId?: string;
  @Input() offerId?: string;
  @Input() windowName?: string;
  @Input() group?: ChatGroup;
  @Input() currentParticipant?: ChatParticipant;
  @Input() messages?: any[];
  @Input() unReadMessages: number = 0;
  signalRAdapter!: SignalRGroupAdapterService;
  isClh: boolean = false;
  scrollableElement?: HTMLElement;
  updatedMessages?: ChatMessage[];
  @Output() unReadMessagesUpdated: EventEmitter<number> =
    new EventEmitter<number>();
  @ViewChildren("chatMessage", { read: ElementRef })
  chatMessages?: QueryList<ElementRef>;
  constructor(
    private globals: CLHGlobals,
    private http: HttpClient,
    protected chatMessageService: ChatMessageService,
    private router: Router,
    private accountService: AccountService,
    private config: ConfigsService
  ) {}
  ngAfterViewInit(): void {
    console.log("chat-room");
    //const elementosScrollable = document.querySelectorAll('.scrollable');
    //if (elementosScrollable.length > 0) {
    //  elementosScrollable.forEach((elemento, index) => {
    //    elemento.addEventListener('scroll', () => {
    //      this.onScroll(elemento, index);
    //    });
    //  });
    //} else {
    //  console.error('No se encontraron elementos con la clase "scrollable".');
    //}
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.messages);
    if (
      changes["offerShipOwnerId"] ||
      changes["offerId"] ||
      changes["windowName"] ||
      changes["group"] ||
      changes["currentParticipant"] ||
      changes["messages"]
    ) {
      console.log("NGONCHANGES");
      this.initializeSignalRAdapter();
    }
  }

  private initializeSignalRAdapter(): void {
    if (
      (this.offerShipOwnerId || this.offerId) &&
      this.windowName &&
      this.group &&
      this.currentParticipant &&
      this.messages
    ) {
      if (this.signalRAdapter) {
        this.signalRAdapter.closeConnection();
      }
      this.signalRAdapter = new SignalRGroupAdapterService(
        this.offerShipOwnerId ?? (this.offerId || ""),
        this.globals,
        this.chatMessageService,
        this.group,
        this.currentParticipant,
        this.messages,
        this.accountService,
        this.config
      );
      if (this.messages) {
        const unSeenMessages = this.messages.filter(
          (x) => x.participantId != this.currentParticipant?.id && !x.seen
        );
        unSeenMessages.forEach((m) => (m.seen = true));

        if (unSeenMessages.length) {
          this.chatMessageService
            .getMessagesMarkedAsSeen(unSeenMessages)
            .subscribe();
        }
      }
    }
  }

  ngOnDestroy(): void {
    console.log("DESTROY");
    //this.chatMessageService.getMessagesMarkedAsSeen(this.updatedMessages).subscribe();
    try {
      this.signalRAdapter?.closeConnection();
    } catch (e) {}
    // this.scrollableElement?.removeEventListener('scroll', this.onScroll.bind(this));
  }

  ngOnInit() {
    if (
      this.accountService.currentUser.roles
        .map((x) => x.name)
        .includes("CLH Admin")
    ) {
      this.isClh = true;
    }
    this.updatedMessages = [];
  }

  switchTheme(theme: string): void {
    this.currentTheme = theme;
  }

  onEventTriggered(event: string): void {
    this.triggeredEvents.push(event);
  }

  sendMessage(message: any) {
    const chatMessage: ChatMessage = new ChatMessage();
    chatMessage.date = new Date();
    chatMessage.groupId = this.group?.id;
    chatMessage.isCharger = this.globals.currentCharger != null ? true : false;
    chatMessage.isCLH = this.isClh;
    chatMessage.message = message.message;
    chatMessage.participantId = this.currentParticipant?.id;
    chatMessage.reply = true;
    this.signalRAdapter.sendMessage(chatMessage);
    chatMessage.participant = this.currentParticipant;
  }

  onScroll(elemento: Element, index: number) {
    this.chatMessages?.forEach((msg, i) => {
      const rect = msg.nativeElement.getBoundingClientRect();
      const scrollableRect = elemento.getBoundingClientRect();
      const isInView =
        rect.top >= scrollableRect.top && rect.bottom <= scrollableRect.bottom;
      if (isInView) {
        if (
          this.signalRAdapter.messages[i].participantId !=
            this.currentParticipant?.id &&
          !this.signalRAdapter.messages[i].seen
        ) {
          this.signalRAdapter.messages[i].seen = true;
          this.updatedMessages?.push(this.signalRAdapter.messages[i]);
          if (this.unReadMessages && this.unReadMessages > 0)
            this.unReadMessagesUpdated.emit(this.unReadMessages - 1);
        }
      }
    });
    const isAtBottom =
      Math.abs(
        elemento.scrollHeight - elemento.scrollTop - elemento.clientHeight
      ) < 1;
    if (isAtBottom) {
      this.signalRAdapter.messages.forEach((msg) => {
        if (!msg.seen) {
          msg.seen = true;
          this.updatedMessages?.push(msg);
        }
      });
      this.unReadMessagesUpdated.emit(0);
    }
  }
}
