import { ChatGroup } from "./chatGroup.model";
import { ChatParticipant } from "./chatParticipant.model";

export class ChatGroupParticipant {
  id!: string;
  participantId!: string;
  participant!: ChatParticipant;
  groupId!: string;
  group!: ChatGroup;
}
