import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { Subscription } from "rxjs";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Offer } from "src/app/models/offers/offer.model";
import { QuantityType } from "src/app/models/enum/quantity-type.model";
import { FreightTargetCurrency } from "src/app/models/enum/freight-target-currency.model";
import { FreightTargetType } from "src/app/models/enum/freight-target-type.model";
import { OveraMessagesService } from "@overa/components/info-dialog";
import { Router } from "@angular/router";
import { CatalogueService } from "src/app/services/catalogue.service";
import { CLHGlobals } from "src/app/utils/globals";
import { CatalogueType } from "src/app/models/enum/catalogue-type";
import { ClhValidators } from "src/app/utils/validators";
import { OfferService } from "src/app/services/offer.service";
import { StateOfferShipOwner } from "src/app/models/enum/state-offer-shipowner.model";

@Component({
  selector: "app-table-offer",
  templateUrl: "./table-offer.component.html",
  styleUrls: ["./table-offer.component.scss"],
  providers: [MessageService, ConfirmationService],
})
export class TableOfferComponent implements OnInit, OnChanges {
  @Input() readonly: boolean;
  @Input() offer: Offer;
  @Input() showAddressCom = false;
  @Output() sendForm = new EventEmitter<FormGroup>();
  readonly arrValidator = this.validators.portsValidator;
  subscription: Subscription;
  submitted: boolean = false;
  coins: string[] = [];
  selectedCoin: string;
  fletes: string[] = [];
  quantityTypes: string[] = [];
  selectedFlete: string;
  today: Date = new Date();
  isValid: boolean = false;
  DEFAULT_QUANTITYTYPE = 0;
  DEFAULT_TARGETTYPE = 0;
  DEFAULT_TARGETCURRENCY = 0;
  quantityTypeLabel: string = "";
  targetTypeLabel: string = "";
  targetCurrencyLabel: string = "";
  userIdCurrent: string = "";
  selectedLoadingPorts = [];
  selectedDischargingPorts = [];
  lastCodeChecked: string = "";
  @Input() form: FormGroup;
  ports = [];
  portsD = [];
  statusNone = [StateOfferShipOwner.NONE];

  //variables para tabla
  columns: any[];
  columnsTitle: any[];
  showTable: boolean = false;
  offers: Offer[] = [];
  isScreenLarge: boolean = true;
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.isScreenLarge = window.innerWidth >= 960;
  }
  constructor(
    private router: Router,
    public mesageService: MessageService,
    public messagesService: OveraMessagesService,
    private globals: CLHGlobals,
    private validators: ClhValidators,
    private cd: ChangeDetectorRef,
    private offerService: OfferService
  ) {
    this.coins = Object.keys(FreightTargetCurrency).filter(
      (x) => !(parseInt(x) >= 0)
    );
    this.fletes = Object.keys(FreightTargetType).filter(
      (x) => !(parseInt(x) >= 0)
    );
    this.quantityTypes = Object.keys(QuantityType).filter(
      (x) => !(parseInt(x) >= 0)
    );
  }

  setForm() {
    this.form = new FormGroup({
      refCharger: new FormControl(this.offer.charger.refCharger, [
        Validators.required,
      ]),
      chargerName: new FormControl(this.offer.charger.name, [
        Validators.required,
      ]),
      code: new FormControl(this.offer.code, []),
      // customerName: new FormControl(this.offer.customerName, []),
      quantityType: new FormControl(this.offer.quantityType, [
        Validators.required,
      ]),
      quantity: new FormControl(this.offer.quantity, [Validators.required]),
      commodity: new FormControl(this.offer.commodity, [Validators.required]),
      stowage: new FormControl(this.offer.stowage, []),
      loadingPorts: new FormControl(
        [],
        [Validators.required, this.arrValidator]
      ),
      dischargingPorts: new FormControl(
        [],
        [Validators.required, this.arrValidator]
      ),
      cpForm: new FormControl(this.offer.cpForm, [Validators.required]),
      chargeringTerm: new FormControl(this.offer.chargeringTerm, [
        Validators.required,
      ]),
      layTimeTerm: new FormControl(this.offer.layTimeTerm, [
        Validators.required,
      ]),
      freightTargetQuantity: new FormControl(
        this.offer.freightTargetQuantity,
        []
      ),
      restrictionsRegarding: new FormControl(
        this.offer.restrictionsRegarding,
        []
      ),
      aditionalRequirements: new FormControl(
        this.offer.aditionalRequirements,
        []
      ),
      // comments: new FormControl(this.offer.comments, []),
      freightTargetCurrency: new FormControl(
        this.offer.freightTargetCurrency,
        []
      ),
      freightTargetType: new FormControl(this.offer.freightTargetType, []),
      rangeDates: new FormControl(
        [this.offer.layCanFrom, this.offer.layCanTo],
        [this.validators.rangeValidator]
      ),
      addressComm: new FormControl(this.offer.addressComm, []),
    });
    if (this.offer.layCanFrom != null && this.offer.layCanTo != null) {
      this.form
        .get("rangeDates")
        .setValue(
          [new Date(this.offer.layCanFrom), new Date(this.offer.layCanTo)],
          { emitEvent: false }
        );
    }

    this.form.get("chargerName").enable();
    this.form.get("refCharger").enable();
    this.form
      .get("quantityType")
      .setValue(QuantityType[this.DEFAULT_QUANTITYTYPE]);
    this.form
      .get("freightTargetCurrency")
      .setValue(FreightTargetCurrency[this.DEFAULT_TARGETCURRENCY]);
    this.form
      .get("freightTargetType")
      .setValue(FreightTargetType[this.DEFAULT_TARGETTYPE]);
    //this.form.get('loadingPorts').setValue(FreightTargetType[this.DEFAULT_TARGETTYPE]);

    this.form.valueChanges.subscribe((value) => {
      console.log(value);
    });
  }
  ngOnInit() {
    this.isScreenLarge = window.innerWidth >= 960;
    console.log("showAddressCom", this.showAddressCom);
    this.createColumnsAndColumnsTitle();
    console.log(this.offer);
    console.log(this.readonly);
    if (!this.offer) {
      this.offer = new Offer();
    }
    if (!this.form) {
      this.setForm();
    }

    if (this.offer.charger != null) {
      this.offer.chargerName = this.offer.charger.name;
      if (!this.readonly) {
        // this.setForm();
        //TODO NO vienen las offershipowners
        if (this.offer.offerShipOwners?.length) {
          this.offer.offerShipOwners.forEach((itemShipOwner) => {
            if (!this.statusNone.includes(itemShipOwner.state)) {
              this.readonly = true;
            }
          });
        }
        this.form.get("chargerName").disable();
        this.form.get("refCharger").disable();
      } else {
        if (this.offer) {
          console.log(this.offer);
          this.quantityTypeLabel = QuantityType[this.offer.quantityType];
          this.targetTypeLabel =
            FreightTargetType[this.offer.freightTargetType];
          this.targetCurrencyLabel =
            FreightTargetCurrency[this.offer.freightTargetCurrency];
        }
      }
    } else {
      Object.keys(this.form.controls).forEach((ctrl) => {
        this.form.controls[ctrl].disable();
      });
    }
    this.offers.push(this.offer);
    this.showTable = true;
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    console.log("valid", this.isValid);
    this.ports = this.globals.catalogue
      .filter((x) => x.type == CatalogueType.PORT)
      .map((x) => x.value);
    this.portsD = [...this.ports];
    this.selectedLoadingPorts = this.ports.filter((x) =>
      this.offer.loadingPorts.includes(x)
    );
    this.selectedDischargingPorts = this.portsD.filter((x) =>
      this.offer.dischargingPorts.includes(x)
    );
    if (this.form) {
      //this.setForm();
    }
  }
  selectedLoadingPortChange(event) {
    console.log(event);
    this.offer.loadingPorts = event;
  }
  selectedDischargingPortChange(event) {
    console.log(event);
    this.offer.dischargingPorts = event;
  }
  ngAfterViewInit() {
    this.cd.detectChanges();
  }
  onBlurMethod() {
    var code = this.form.get("code").value;
    if (code != this.lastCodeChecked) {
      this.lastCodeChecked = code;
      this.offerService.checkCodeOffer(code).subscribe({
        next: (data) => {
          if (data) {
            this.messagesService.showToast(
              "INFO",
              "MESSAGES.INFO.CODE_DUPLICATE",
              "info",
              ""
            );
          }
        },
        error: (err) => {
          console.error(err);
        },
      });
    }
  }
  createColumnsAndColumnsTitle() {
    this.columns = [
      { colName: "STEP1_OFFER.TABLE.HEAD.HEAD_1" },
      { colName: "STEP1_OFFER.TABLE.HEAD.HEAD_2" },
      { colName: "" },
    ];
    this.columnsTitle = [
      {
        field: {
          charterer: "STEP1_OFFER.TABLE.ROW.ROW_CODE",
          shipowner: "STEP1_OFFER.TABLE.ROW.ROW_REF_SHIPOWNER",
        },
        control: "code",
        label: { charterer: "code", shipowner: "refOffer" },
        tooltip: "TOOLTIP.MYREFERENCE_CARGOORDER",
      },
      {
        field: "STEP1_OFFER.TABLE.ROW.ROW_NAME",
        control: "chargerName",
        label: "chargerName",
        tooltip: "",
      },
      {
        field: "quantity",
        moreFields: {
          quantity: "STEP1_OFFER.TABLE.ROW.ROW_CARGO_QUANTITY",
          stowage: "STEP1_OFFER.TABLE.ROW.ROW_STOWAGE",
          commodity: "STEP1_OFFER.TABLE.ROW.ROW_COMODITY",
        },
        control: "quantity",
        label: "quantity",
        moreLabels: {
          quantity: "quantity",
          quantityType: "quantityType",
          stowage: "stowage",
          commodity: "commodity",
        },
        moreControls: {
          quantity: "quantity",
          quantityType: "quantityType",
          stowage: "stowage",
          commodity: "commodity",
        },
        placeholder: {
          quantity: "STEP1_OFFER.PLACEHOLDER_QUANTITY.QUANTITY",
          stowage: "STEP1_OFFER.PLACEHOLDER_QUANTITY.SF",
          commodity: "STEP1_OFFER.PLACEHOLDER_QUANTITY.DESCRIPTION",
        },
        idInputTextarea: {
          stowage: "inputTable1",
          commodity: "inputTableCommodity",
        },
        tooltip: "TOOLTIP.COMMODITY_CARGOORDER",
      },
      {
        field: "STEP1_OFFER.TABLE.ROW.ROW_LOADING",
        control: "loadingPorts",
        label: "loadingPorts",
        required: true,
        tooltip: "TOOLTIP.LOADINGPORT_CARGOORDER",
      },
      {
        field: "STEP1_OFFER.TABLE.ROW.ROW_DISCHARGING",
        control: "dischargingPorts",
        label: "dischargingPorts",
        required: true,
        tooltip: "TOOLTIP.DISCHARGINGPORT_CARGOORDER",
      },
      {
        field: "STEP1_OFFER.TABLE.ROW.ROW_LAYCAN",
        control: "rangeDates",
        label: { fieldFrom: "layCanFrom", fieldTo: "layCanTo" },
        tooltip: "TOOLTIP.LAYCAN_CARGOORDER",
      },
      {
        field: "STEP1_OFFER.TABLE.ROW.ROW_CHARTERING",
        control: "chargeringTerm",
        label: "chargeringTerm",
        tooltip: "TOOLTIP.LOADING_DISCHARGING_TERMS_CARGOORDER",
      },
      {
        field: "STEP1_OFFER.TABLE.ROW.ROW_LAYTIME",
        control: "layTimeTerm",
        label: "layTimeTerm",
        tooltip: "TOOLTIP.LAYTIME_TERMS_CARGOORDER",
      },
      {
        field: "STEP1_OFFER.TABLE.ROW.ROW_CP",
        control: "cpForm",
        label: "cpForm",
        tooltip: "TOOLTIP.CP_FORM_CARGOORDER",
      },
      {
        field: "STEP1_OFFER.TABLE.ROW.ROW_RESTRICTIONS",
        control: "restrictionsRegarding",
        label: "restrictionsRegarding",
        labelTextarea: "STEP1_OFFER.PLACEHOLDER.PLACEHOLDER_RESTRICTIONS",
        idTextarea: "inputTableRestrictions",
        classTextarea: "textAreaRestrictions clhTextarea",
        required: false,
        tooltip: "TOOLTIP.RESTRICTIONS_CARGOORDER",
      },
      {
        field: "STEP1_OFFER.TABLE.ROW.ROW_ADD_REQUIREMENTS",
        control: "aditionalRequirements",
        label: "aditionalRequirements",
        labelTextarea: "STEP1_OFFER.PLACEHOLDER.PLACEHOLDER_ADD_REQUIREMENTS",
        idTextarea: "inputTableRequirements",
        classTextarea: "textAreaRequirements clhTextarea",
        required: false,
        tooltip: "TOOLTIP.ADITIONAL_REQUIREMENTS_CARGOORDER",
      },
      {
        field: "STEP1_OFFER.TABLE.ROW.ROW_FLETE",
        control: "flete",
        label: "flete",
        moreLabels: {
          currency: "freightTargetCurrency",
          quantity: "freightTargetQuantity",
          type: "freightTargetType",
        },
        moreControls: {
          currency: "freightTargetCurrency",
          quantity: "freightTargetQuantity",
          type: "freightTargetType",
        },
        tooltip: "TOOLTIP.FREIGHT_CARGOORDER",
      },
      {
        field: "CHARTERER_DATA_SHEET.ADDRESS_COM",
        control: "addressComm",
        label: "addressComm",
        tooltip: "",
      },
    ];
  }
}
