
export class ChargerShipOwnerContactDto {
    id?:string;
    chargerShipOwnerId?:string;
    name?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    comment?: string;
    userId?:string;
    isSelected?:boolean; //This check is to select in step 2
    isSelectedShipownerCS?:boolean;
    isNew?:boolean;
    isEdit?:boolean;
    constructor() { 
       
    }
}