import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-offer-request',
  templateUrl: './offer-request.component.html',
  styleUrls: ['./offer-request.component.scss']
})
export class OfferRequestComponent implements OnInit {
  
  constructor() { }

  ngOnInit() {
  }

}
