import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { KanbanTask } from "../models/kanban-task.model";
import { OfferFilter } from "../models/searcher/offer-filter.model";
import { CLHBaseService } from "./clh-base.service";

@Injectable({
  providedIn: "root",
})
export class SearcherService extends CLHBaseService {
  private dataSearch = new BehaviorSubject<OfferFilter | null>(null);
  datacurrentSearch = this.dataSearch.asObservable();

  private searchResults = new BehaviorSubject<any | null>(null);
  dataSearchResults = this.searchResults.asObservable();
  private imoSelected = new BehaviorSubject<string | null>(null);
  selectedImo = this.imoSelected.asObservable();

  private mapKanbanSelected = new BehaviorSubject<KanbanTask | null>(null);
  selectKanbanMap = this.mapKanbanSelected.asObservable();

  updateSearch(newData: OfferFilter) {
    // console.log(newData);
    const offerFilter = new OfferFilter();
    offerFilter.isActive = newData.isActive;
    offerFilter.isUnread = newData.isUnread;
    offerFilter.searchString = newData.searchString;
    offerFilter.shipOwnerId = newData.shipOwnerId;
    offerFilter.startDate = newData.startDate;
    offerFilter.endDate = newData.endDate;
    this.dataSearch.next(offerFilter);
    // console.log(this.dataSearch);
  }
  updateResults(res: any) {
    this.searchResults.next(res);
  }
  selectKanban(osId: string) {
    this.imoSelected.next(osId);
  }
  selectKanbanOnMap(kanbanTssk: KanbanTask) {
    this.mapKanbanSelected.next(kanbanTssk);
  }
}
