import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CLHGlobals } from "src/app/utils/globals";

@Component({
  selector: "app-company-details",
  templateUrl: "./company-details.component.html",
  styleUrls: ["./company-details.component.css"],
})
export class CompanyDetailsComponent implements OnInit {
  constructor(private globals: CLHGlobals, private router: Router) {}

  ngOnInit(): void {
    if (this.globals.currentCharger?.id) {
      this.router.navigate(["chargers/", this.globals.currentCharger.id]);
    } else if (this.globals.currentShipOwner?.id) {
      this.router.navigate(["shipowners/", this.globals.currentShipOwner.id]);
    } else {
      console.warn("Not shipowner, not charterer");
    }
  }
}
