import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AuthService, Menu } from "@overa/security";
import { StorageService } from "@overa/storage";
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { ChargerContactService } from "src/app/services/chargerContact.service";
import { GenericConditionsService } from "src/app/services/generic-conditions.service";
import { ShipOwnerContactService } from "src/app/services/shipowner-contact.service";
import { CLHGlobals } from "src/app/utils/globals";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { UserType } from "src/app/models/enum/user-type.model";
import { forkJoin } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { OveraMessagesService } from "@overa/components/info-dialog";
import { NgxTranslateService } from "@overa/shared";

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
@Component({
  selector: "overa-conditions",
  templateUrl: "./conditions.component.html",
  styleUrls: ["./conditions.component.scss"],
})
export class ConditionsComponent implements OnInit, OnDestroy {
  dialogData?: any;
  isAcceptedTerms = false;
  isAcceptedPolicy = false;
  showButton = false;
  showButtonPolicy = false;
  messagesService = inject(OveraMessagesService);
  userId: any;
  userType: any;
  termsDate: any;
  userWithDate: any;
  termLast: any;
  chargerContactService = inject(ChargerContactService);
  shipOwnerContactService = inject(ShipOwnerContactService);
  acceptedTerms: boolean = false;
  termLastDate: any;
  languageMenuItem?: Menu;
  languagesTerms: any[] = [];
  languageTerms?: any;
  translate = inject(NgxTranslateService);
  selectLang!: { key: string; translateKey: string } | undefined;
  transLang!: { key: string; translateKey: string }[];
  showTerms = false;
  showPolicy = false;

  pdfSrc: string = "";
  pdfSrcPolicy: string = "";
  lastTerms?: any;
  lastTermsPolicy?: any;

  // Flags to control the display of PDFs
  showFirstPdf = false;
  showSecondPdf = false;
  loadedPdfs = false;
  loadedTerms = false;
  loadedPolicy = false;

  constructor(
    private storageService: StorageService,
    private conditionsService: GenericConditionsService,
    private authService: AuthService,
    private globals: CLHGlobals,
    public dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    public spinner: NgxSpinnerService
  ) {
    this.languagesTerms = ["es", "en"];
    this.languageTerms = this.translate.currentLang;
    this.transLang = [
      { key: "en", translateKey: `LANGUAGES.EN` },
      { key: "es", translateKey: `LANGUAGES.ES` },
    ];
    // this.transLang = this.translate.getLangs().map((lang) => {
    //   return { key: lang, translateKey: `LANGUAGES.${lang.toUpperCase()}` };
    // });
    const f = this.transLang.find((l) => l.key === this.translate.currentLang);
    if (f) {
      this.selectLang = { ...f };
    }

    if (dialogConfig?.data?.userId) this.userId = dialogConfig.data.userId;
    if (dialogConfig?.data?.userType)
      this.userType = dialogConfig.data.userType;
    if (dialogConfig?.data?.termsDate)
      this.termsDate = dialogConfig.data.termsDate;
    if (dialogConfig?.data) this.userWithDate = dialogConfig.data.userWithDate;
    if (dialogConfig?.data?.termLastDate)
      this.termLastDate = dialogConfig.data.termLastDate;
    if (dialogConfig?.data?.termLast)
      this.termLast = dialogConfig.data.termLast;
    if (this.userWithDate) {
      if (this.userWithDate.mustAcceptTermsAndConditions) {
        this.showTerms = true;
      }
      if (this.userWithDate.mustAcceptPrivacyPolicy) {
        this.showPolicy = true;
      }
    }
  }
  ngOnDestroy(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.loadPdf();
  }

  logout() {
    this.authService.logout();
  }

  loadPdf() {
    this.spinner.show("conditionsSpinner");
    this.conditionsService.getLast("terms").subscribe({
      next: (response) => {
        this.conditionsService.getLast("policy").subscribe({
          next: (responsePolicy) => {
            this.lastTerms = response;
            this.lastTermsPolicy = responsePolicy;
            this.getFileContents();
          },
        });
      },
    });
  }

  getFileContents() {
    const selectedLanguage = this.selectLang?.key.toUpperCase();
    const fileGuid = this.lastTerms["docTerms" + selectedLanguage];
    if (fileGuid) {
      this.storageService.getFileContent(fileGuid).subscribe({
        next: (fileContent) => {
          this.pdfSrc = fileContent.content;
          this.showFirstPdf = true; // Show the first PDF
          this.renderPdf(this.pdfSrc, "pdfCanvasTerms");
        },
      });
    } else {
      this.loadedTerms = true;
    }

    const fileGuidPolicy = this.lastTermsPolicy["docPolicy" + selectedLanguage];
    if (fileGuidPolicy) {
      this.storageService.getFileContent(fileGuidPolicy).subscribe({
        next: (fileContent) => {
          this.pdfSrcPolicy = fileContent.content;
          this.showSecondPdf = true; // Show the second PDF
          this.renderPdf(this.pdfSrcPolicy, "pdfCanvasPolicy");
        },
      });
    } else {
      this.loadedPolicy = true;
    }
  }
  renderPdf(pdfData: string, canvasIdPrefix: string) {
    const canvasContainerToClean = document.getElementById(
      canvasIdPrefix + "Container"
    );
    if (canvasContainerToClean) {
      canvasContainerToClean.innerHTML = "";
    }
    const loadingTask = pdfjsLib.getDocument({
      data: this.convertBase64ToUint8Array(pdfData),
    });

    loadingTask.promise
      .then(async (pdf: any) => {
        const numPages = pdf.numPages;

        for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
          const page = await pdf.getPage(pageNumber);
          //.then((page) => {
          setTimeout(async () => {
            const scale = 1.5;
            const viewport = page.getViewport({ scale });

            // Create a canvas element for each page
            const canvas = document.createElement("canvas");
            canvas.id = `${canvasIdPrefix}-${pageNumber}`;
            const canvasContainer = document.getElementById(
              canvasIdPrefix + "Container"
            );
            if (canvasContainer) {
              canvasContainer.appendChild(canvas); // Append each canvas to a container element
            }

            const context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            canvas.style.maxWidth = "100%";

            const renderContext = {
              canvasContext: context,
              viewport: viewport,
            };
            await page.render(renderContext);
            if (pageNumber == numPages) {
              if (canvasIdPrefix.includes("Terms")) {
                this.loadedTerms = true;
              } else {
                this.loadedPolicy = true;
              }
              if (this.loadedTerms && this.loadedPolicy) {
                this.loadedPdfs = true;
                this.spinner.hide("conditionsSpinner");
              }
            }
            // Render each page on its canvas
            // page.render(renderContext).promise.then(() => {
            //   console.log(`Page ${pageNumber} rendered`);
            //   if(pageNumber == numPages){
            //     if(canvasIdPrefix.includes('Terms')){
            //       this.loadedTerms = true;
            //     }
            //     else{
            //       this.loadedPolicy = true;

            //     }
            //     if(this.loadedTerms && this.loadedPolicy){
            //       this.loadedPdfs = true;
            //     }
            //   }
            // });
          }, 0);
          // })
          // .catch((error) => {
          //   console.error(`Error rendering page ${pageNumber}:`, error);
          // });
        }
        setTimeout(() => {
          this.calculateScroll();
        }, 200);
      })
      .catch((error: any) => {
        console.error("Error loading PDF:", error);
      });
  }

  convertBase64ToUint8Array(base64: string): Uint8Array {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }
  calculateScroll() {
    const viewerContainer = document.querySelector(
      ".p-dialog-content"
    ) as HTMLElement;
    if (viewerContainer) {
      viewerContainer.onscroll = (ev) => this.onScroll(ev);
      viewerContainer.style.paddingBottom = "0";
    }
  }

  async acceptTerms() {
    if (this.isAcceptedTerms && this.isAcceptedPolicy) {
      let contact: any;
      let subscription: any;
      let userType = UserType.CHARGER;
      if (this.userWithDate.chargerId != null) {
        contact = this.userWithDate;
        contact.termsAndConditionsAceptationDate = new Date();
        // subscription = this.chargerContactService.edit(contact);
      } else if (this.userWithDate.shipOwnerId != null) {
        contact = this.userWithDate;
        contact.termsAndConditionsAceptationDate = new Date();
        // subscription = this.shipOwnerContactService.edit(contact);
        userType = UserType.SHIPOWNER;
      }
      const subscriptions: any = {};
      if (this.showTerms) {
        subscriptions["terms"] = this.conditionsService.accept(
          this.lastTerms.id,
          "terms",
          userType,
          this.userWithDate.userId
        );
        await this.conditionsService
          .accept(
            this.lastTerms.id,
            "terms",
            userType,
            this.userWithDate.userId
          )
          .toPromise();
      }
      if (this.showPolicy) {
        subscriptions["policy"] = this.conditionsService.accept(
          this.lastTermsPolicy.id,
          "policy",
          userType,
          this.userWithDate.userId
        );
        await this.conditionsService
          .accept(
            this.lastTermsPolicy.id,
            "policy",
            userType,
            this.userWithDate.userId
          )
          .toPromise();
      }
      this.acceptedTerms = true;
      this.globals.acceptedConditions = true;
      this.dialogRef.close({ acceptedTerms: true });
      // if (subscriptions) {

      //   forkJoin(subscriptions).subscribe({
      //     next: (res) => {
      //       if (res) {
      //         this.acceptedTerms = true;
      //         this.globals.acceptedConditions = true;
      //         //if (res.termsAndConditionsAceptationDate >= this.termLastDate) {
      //           this.dialogRef.close({ acceptedTerms: true });
      //         // } else {
      //         //   this.dialogRef.close({ acceptedTerms: false });
      //         // }
      //       } else {
      //         this.dialogRef.close();
      //       }
      //     },
      //     error: (err) => {
      //       console.log(err);
      //     },
      //   });
      // }
    } else {
      const messageAlert = !this.isAcceptedTerms
        ? "TERMS_MAINTENANCE.MESSAGE_WARNING"
        : "PRIVACY_POLICIES_MAINTENANCE.MESSAGE_WARNING";
      const messageAlertTitle = "MESSAGES.ERROR.WARNING";
      this.messagesService.showToast(
        messageAlertTitle,
        messageAlert,
        "warning",
        ""
      );
    }
  }

  onScroll(event: any): void {
    const elemento = event.target;
    if (
      elemento.offsetHeight + elemento.scrollTop >=
      elemento.scrollHeight - 10
    ) {
      this.showButton = true;
      this.isAcceptedTerms = true;
      this.isAcceptedPolicy = true;

      // if (this.showButtonPolicy) {
      //   this.isAcceptedTerms = true;
      // }
    }
  }

  onChangeLanguage(langEv: any) {
    this.isAcceptedTerms = false;
    this.showButton = false;
    this.isAcceptedPolicy = false;
    this.selectLang = this.transLang.find((l) => l.key === langEv.value);
    this.translate.use(langEv.value);
    this.translate.setTranslations();
    location.href = location.href;
    this.getFileContents();
    // const lang = this.transLang.find((x) => x.key == langEv.value);
    // if (lang) {
    //   if (!this.selectLang || lang.key != this.selectLang.key) {
    //     this.selectLang = this.transLang.find((l) => l.key === lang.key);
    //     if (this.selectLang) {
    //       this.translate.use(this.selectLang.key);
    //       this.loadPdf(); // Reload the PDFs when the language changes
    //     }
    //   }
    // }
  }

  selectLanguage(event: any) {}
}
