<div class="dialog-container">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-8 titleTerms"><h4 *ngIf="termsConditionsPrivacyTitle">{{termsConditionsPrivacyTitle}}</h4></div>

      <div class="col-4 div-language-selector">
        <p-dropdown [options]="languagesTerms" formControlName="languageTerms" (onChange)="onChangeLanguage($event)"></p-dropdown>
      </div>
    </div>
    <!-- <div class="titleTerms">
    <h4 *ngIf="termsConditionsPrivacyTitle">{{termsConditionsPrivacyTitle}}</h4>
  </div> -->

    <!-- <div class="div-language-selector">
      <p-dropdown [options]="languagesTerms" formControlName="languageTerms" (onChange)="onChangeLanguage($event)"></p-dropdown>
    </div> -->
    <div class="dialog-content">
      <textarea
        #textAreaTermsConditions
        pInputTextarea
        formControlName="terms"
        [style]="{ 'width': '100%', 'height': '200px', 'overflow': 'auto' }"
        [rows]="10"
        [readonly]="true"
        placeholder="termsConditionsTitle"
        (ngModel)="textAreaValue"
        (onChange)="changeTextAreaData()"
        (scroll)="scrollFinishedTerms ? null : handleScroll($event)"
      ></textarea>
    </div>

    <div class="dialog-footer">
      <div class="centerDiv w-100">
        <div class="checkbox-container">
          <p-checkbox formControlName="checked" value="{{acceptTermsTitle}}" inputId="acceptedId" [disabled]="true" [binary]="true"></p-checkbox>
          <label for="acceptedId" class="checkbox-label" for="isChecked"> {{acceptTermsTitle}}</label>
        </div>
      </div>
    </div>
    <div class="containerButtonStep1">
      <div class="div-btn-cancel">
        <div>
          <button pButton label="{{buttonCancelTitle}}" class="p-button cancelar p-ripple" (click)="cancel()"></button>
        </div>
      </div>
      <div class="div-btn-next">
        <div>
          <button
            pButton
            label="{{buttonAcceptTitle}}"
            class="p-button actualizar p-ripple"
            [disabled]="!form.get('checked').value"
            (click)="accept()"
          ></button>
        </div>
      </div>
    </div>
  </form>
</div>
