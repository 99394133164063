export class ResponseOfferMail {
    userId?: string;
    subject?: string;
    body?: string;
    emailTo?:string;
    name?:string; // To front  
    newPassword?:string;  // To front  
    firstName?:string; // To front  
    lastName?:string; // To front  
    phoneNumber?:string; // To front  
    userName?:string; // To front  
    constructor(){}
}
