export class GenericConditions {
    id?: string;
    validityDate?: Date
  
   
    fileDataES?: any;
    fileDataEN?: any;
    isEdit?: boolean;
    isNew?: boolean;
    valueInitial?:string;
    valueInitialEN?:string;
    valueInitialDocEs?:string;
    valueInitialDocEn?:string;
    valueInitialDate?:Date;
    isEmpty?:boolean;
    nameControl?:string;//para front
    nameControlEN?:string;//para front terms english
    nameControlDate?:string;//para front
    nameControlDocEs?:string;//para front
    nameControlDocEn?:string;//para front
    sendDate?: Date;
    sendDateTmp?: Date;
    
    constructor(obj?: Partial<GenericConditions>) {
        this.id = (obj && obj.id) || undefined;
        this.validityDate = (obj && obj.validityDate) || new Date();
      
       
        this.isEdit = (obj && obj.isEdit) || false; 
        this.isNew = (obj && obj.isNew) || false;
        this.valueInitial = (obj && obj.valueInitial) || undefined;
        this.valueInitialEN = (obj && obj.valueInitialEN) || undefined;
        this.valueInitialDate = (obj && obj.valueInitialDate) || new Date();
        this.nameControl = (obj && obj.nameControl) || undefined; 
        this.nameControlEN = (obj && obj.nameControlEN) || undefined; 
        this.nameControlDate = (obj && obj.nameControlDate) || undefined; 
        this.nameControlDocEs = (obj && obj.nameControlDocEs) || undefined; 
        this.nameControlDocEn = (obj && obj.nameControlDocEn) || undefined; 
        this.sendDate = (obj && obj.sendDate) || undefined; 
    }   
}
