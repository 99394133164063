import { FileElement } from "./fileelement.model";

export class Document {
  id?: string;
  documentGuid?: string;
  name: string;
  documentName: string;
  documentFolderId: string;
  entityId!: string;
  entityKey!: string;
  updatedDate!: string;
  timestamp?: any[];
  versionNumber!: string;
  isVersion!: boolean;
  documentVersionId!: string;
  isPublic?: boolean;
  groupId?: string;

  constructor(fileElement: FileElement) {
    //this.id = fileElement.id;
    this.documentGuid = fileElement.documentId;
    this.documentName = fileElement.documentName;
    this.name = fileElement.name;
    this.documentFolderId = fileElement.parentId;
    this.timestamp = fileElement.timestamp;
  }
}
