<p-confirmDialog styleClass="containerConfirmDialog" acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"></p-confirmDialog>
<ngx-spinner></ngx-spinner>
<p-toast></p-toast>
<div class="contact-container">
  <div class="m-auto" *ngIf="formGroup">
    <ng-container [formGroup]="formGroup">
      <div class="container-fluid">
        <div class="row">
          <!-- SEARCH-->
          <div class="col-5 mt-1 mb-5">
            <span class="p-input-icon-right w-100">
              <i class="pi pi-search"></i>
              <input class="w-100 search-contact" pInputText type="text"
                (input)="tableContacts.filterGlobal($any($event.target).value, 'contains')" />
            </span>
          </div>
          <!-- END SEARCH-->
          <!-- ADD-->

          <div class="col-7">
            <div class="d-flex flex-row justify-content-right">
              <button *ngIf="!isEditting && !isNew && enabled" pButton pRipple type="button" icon="pi pi-plus"
                class="p-button-rounded addButtonRounded" (click)="newRow()" [pTooltip]="'ACTION.ADD'| translate"
                tooltipPosition="bottom" tooltipStyleClass="tooltipCLH"></button>
            </div>
          </div>
          <!-- END ADD-->
        </div>
      </div>

      <p-table [columns]="columnsSchema" [value]="dataSource" #tableContacts [loading]="loading"
        styleClass="p-datatable-striped listTableContacts tables-maintenance" [responsive]="true"
        responsiveLayout="stack" breakpoint="1400px" [globalFilterFields]="['email','name','phone']" [autoLayout]="true"
        paginatorDropdownAppendTo="body">
        <!--
        <ng-template pTemplate="caption">
            <div class="table-header">
              <div class="d-flex flex-row">
                <div style="margin-left: 3em">
                  <button
                    *ngIf="!isEditting && !isNew"
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-plus"
                    class="p-button-rounded addButtonRounded"
                    (click)="newRow()"
                    [pTooltip]="'ACTION.ADD'| translate"
                    tooltipPosition="bottom"
                    tooltipStyleClass="tooltipCLH"
                  ></button>
                </div>
              </div>
            </div>
          </ng-template>
        -->

        <ng-template pTemplate="header" let-columns>
          <tr>
            <th [escape]="false" [pTooltip]="col.tooltip | translate" tooltipPosition="top"
              tooltipStyleClass="tooltipCLH" [showDelay]="1000" [hideDelay]="1000"
              [ngClass]="col.key + ' font-weight-bold header'" *ngFor="let col of columns">
              {{col.label | translate}}
            </th>
            <th class="font-weight-bold" colspan="3" *ngIf="enabled">{{'CONTACTS.FORM.ACTIONS' | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-contact>
          <ng-container *ngIf="contact.isNew else other">
            <tr *ngIf="isNew" class="campo">
              <ng-container *ngFor="let col of columnsSchema">
                <td class="{{col.key}}">
                  <span class="p-column-title">{{col.label | translate}}</span>

                  <ng-container *ngIf="col.type=='text'">
                    <input type="text" pInputText [formControlName]="col.key" style="width: 100%" />
                    <div
                      *ngIf="col.key==='email' && formGroup.get('email').touched  && !formGroup.get('email').value  && formGroup.get('email').hasError('required')"
                      class="error-label labelError">
                      {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="col.key == 'types' && col.type == 'enum'">
                    <p-multiSelect appendTo="body" [options]="types" optionLabel="value" [formControlName]="col.key"
                      selectedItemsLabel="{{ 'CONTACTS.FORM.TYPE' | translate }}"
                      class="slim-select filter-combo-with-search-multiple">
                    </p-multiSelect>

                    <!-- <ng-container *ngIf="!contact.editable && types && types.length">
                                  <span *ngFor="let type of contact.types">{{type.value + ', '}}</span>
                                </ng-container> -->
                  </ng-container>
                  <ng-container *ngIf="col.type=='boolean'">
                    <ng-container *ngIf="(col.readonly) && (col.key != 'isBanned' && !isCharger)">
                      <span *ngIf="contact[col.key]" class="material-icons notranslate">done</span>
                      <!--<span *ngIf="!contact[col.key]" class="material-icons notranslate">close</span>-->
                    </ng-container>
                    <ng-container *ngIf="col.key == 'isPrincipal'">
                      <div class="field-checkbox">
                        <p-checkbox formControlName="isPrincipal" [binary]="true" inputId="isPrincipal"
                          class="checkShipownerCharger"> </p-checkbox>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="col.key == 'isBanned' && !isCharger">
                      <span *ngIf="contact[col.key] && !isClh" class="material-icons notranslate">done</span>
                      <div class="field-checkbox" *ngIf="isClh">
                        <p-checkbox formControlName="isBanned" [binary]="true" inputId="isBanned"> </p-checkbox>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="col.type=='date'">
                    <!-- <input type="date" pInputText [formControlName]="col.key"/> -->
                    <!-- <ng-container *ngIf="col.key == 'termsAndConditionsAceptationDate' || col.key == 'privacyPolicyAceptationDate'">
                      <div [formGroup]="formGroup">
                        <p-calendar formControlName="{{col.key}}" appendTo="body" [disabled]="true"></p-calendar>
                      </div>
                    </ng-container> -->
                  </ng-container>
                </td>
              </ng-container>
              <!--<td></td>-->
              <td>
                <div class="d-flex flex-row justify-content-around">
                  <button pButton pRipple *ngIf="contact.isNew"
                    [disabled]="!(formGroup?.status == validStatus) || !enabled " type="button" icon="pi pi-save"
                    class="p-button-rounded saveButtonRounded" (click)="create()" [pTooltip]="'ACTION.SAVE'| translate"
                    tooltipPosition="bottom" tooltipStyleClass="tooltipCLH"></button>
                  <button pButton pRipple *ngIf="contact.isNew" type="button" icon="pi pi-times"
                    class="p-button-rounded cancelButtonRounded" (click)="cancelEdit()"
                    [pTooltip]="'ACTION.CANCEL'| translate" tooltipPosition="bottom"
                    tooltipStyleClass="tooltipCLH"></button>
                </div>
              </td>
            </tr>
          </ng-container>
          <ng-template #other>
            <tr class="campo">
              <ng-container *ngFor="let col of columnsSchema">
                <td class="{{col.key}}">
                  <span class="p-column-title">{{col.label | translate}}</span>
                  <ng-container *ngIf="col.type=='text'">
                    <ng-container
                      *ngIf="!contact.editable || (col.key == 'privacyPolicyAceptationDate' && contact.id) || (col.key == 'email' && contact.id) || (col.key == 'termsAndConditionsAceptationDate' && contact.id)">
                      {{contact[col.key]}}
                    </ng-container>
                    <ng-container
                      *ngIf="contact.editable &&  (col.key != 'email' || !contact.id) &&  (col.key != 'privacyPolicyAceptationDate' || !contact.id) &&  (col.key != 'termsAndConditionsAceptationDate' || !contact.id)">
                      <span class="p-float-label">
                        <input [id]="col.float" type="text" pInputText [formControlName]="col.key"
                          style="width: 100%" />
                        <label [for]="col.float">{{col.label | translate}}</label>
                      </span>
                      <div
                        *ngIf="col.key==='email' && formGroup.get('email').touched  && !formGroup.get('email').value  && formGroup.get('email').hasError('required')"
                        class="error-label labelError">
                        {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="col.key == 'types' && col.type == 'enum'">
                    <ng-container *ngIf="contact.editable && types && types.length">
                      <p-multiSelect appendTo="body" [options]="types" optionLabel="value" [formControlName]="col.key"
                        selectedItemsLabel="{{ 'CONTACTS.FORM.TYPE' | translate }}"
                        class="slim-select filter-combo-with-search-multiple">
                      </p-multiSelect>
                    </ng-container>
                    <ng-container *ngIf="!contact.editable && types && types.length">
                      <span *ngFor="let type of contact.types;let i=index">{{type.value + ((contact.types.length > i +
                        1) ? ', ' : '')}}</span>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="col.type=='boolean'">
                    <ng-container *ngIf="(col.key != 'isPrincipal' && col.key != 'isBanned') || !contact.editable">
                      <span *ngIf="contact[col.key]" class="material-icons notranslate">done</span>
                      <!--<span *ngIf="!contact[col.key]" class="material-icons notranslate">close</span>-->
                    </ng-container>
                    <ng-container *ngIf="col.key == 'isPrincipal' && contact.editable">
                      <div class="field-checkbox">
                        <p-checkbox formControlName="isPrincipal" [binary]="true" inputId="isPrincipal"
                          class="checkShipownerCharger"> </p-checkbox>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="col.key == 'isBanned' && !isCharger && contact.editable">
                      <span *ngIf="contact[col.key] && !isClh" class="material-icons notranslate">done</span>
                      <div class="field-checkbox" *ngIf="isClh">
                        <p-checkbox formControlName="isBanned" [binary]="true" inputId="isBanned"> </p-checkbox>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="col.type == 'date'">
                    <ng-cointainer
                      *ngIf="col.key=='termsAndConditionsAceptationDate' || col.key == 'privacyPolicyAceptationDate'">
                      {{contact[col.key] | date: 'dd/MM/yyyy'}}
                    </ng-cointainer>
                  </ng-container>
                </td>
              </ng-container>
              <td *ngIf="enabled">
                <span class="p-column-title"> {{'CONTACTS.FORM.ACTIONS' | translate}}</span>
                <div class="d-flex flex-row justify-content-right contact-buttons-edit">
                  <ng-container *ngIf="!contact.isEnabled && contact.id && !isEditting && !isNew && enabled">
                    <button pButton pRipple *ngIf="!contact.isEnabled && contact.id && !isEditting && !isNew"
                      icon="pi pi-send" (click)="invite(contact)" class="p-button-rounded inviteButtonRounded"
                      [pTooltip]="'ACTION.INVITE'| translate" tooltipPosition="bottom"
                      tooltipStyleClass="tooltipCLH"></button>
                  </ng-container>
                  <!--</td>
                            <td *ngIf="!isNew">-->
                  <ng-container *ngIf="!isNew">
                    <button pButton pRipple *ngIf="!contact.editable && !isEditting" type="button" icon="pi pi-pencil"
                      class="p-button-rounded editButtonRounded" (click)="setEditting(contact)"
                      [pTooltip]="'ACTION.EDIT'| translate" tooltipPosition="bottom"
                      tooltipStyleClass="tooltipCLH"></button>
                    <button pButton pRipple *ngIf="contact.editable"
                      [disabled]="!(formGroup?.status == validStatus) || !enabled" type="button" icon="pi pi-save"
                      class="p-button-rounded saveButtonRounded" (click)="create()"
                      [pTooltip]="'ACTION.SAVE'| translate" tooltipPosition="bottom"
                      tooltipStyleClass="tooltipCLH"></button>
                    <button pButton pRipple *ngIf="contact.editable" type="button" icon="pi pi-times"
                      class="p-button-rounded cancelButtonRounded" (click)="cancelEdit()"
                      [pTooltip]="'ACTION.CANCEL'| translate" tooltipPosition="bottom"
                      tooltipStyleClass="tooltipCLH"></button>
                  </ng-container>
                  <!--</td>
                            <td *ngIf="!contact.editable && contact.id && !isEditting && !isNew">-->
                  <ng-container *ngIf="!contact.editable && contact.id && !isEditting && !isNew">
                    <button pButton pRipple type="button" icon="pi pi-trash"
                      class="p-button-rounded deleteButtonRounded"
                      *ngIf="contact.id && (!isEditting || contact.editable)" (click)="delete(contact)"
                      [pTooltip]="'ACTION.DELETE'| translate" tooltipPosition="bottom"
                      tooltipStyleClass="tooltipCLH"></button>
                  </ng-container>
                </div>
              </td>
            </tr>
          </ng-template>
        </ng-template>
      </p-table>
    </ng-container>
  </div>
</div>