import { Component } from "@angular/core";

import { ConfirmationService } from "primeng/api";

@Component({
  selector: "app-terms-and-conditions-view",
  templateUrl: "./terms-and-conditions-view.component.html",
  styleUrls: ["./terms-and-conditions-view.component.scss"],
  providers: [ConfirmationService],
})
export class TermsAndConditionsViewComponent {}
