import { inject, Injectable } from "@angular/core";

import {
  catchError,
  combineLatest,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
} from "rxjs";

import { IdentityService } from "@overa/shared";
import { CLHGlobals, CLHUser } from "../utils/globals";
import { ChargerService } from "./charger.service";
import { TermsAndConditionsService } from "./terms-and-conditions.service";
import { PrivacyPoliciesService } from "./privacy-policies.service";
import { CountryService } from "./country.service";
import { ShipownerService } from "./shipowner.service";
import { AccountService } from "@overa/security";
import { LanguageCode } from "../models/enum/language-code.model";
import { Charger } from "../models/charger/charger.model";
import { Shipowner } from "../models/shipowner/shipowner.model";
import { CLHIdentityService } from "./identity.service";
import { DialogService } from "primeng/dynamicdialog";
import { ConditionsComponent } from "../components/platform-view/conditions/conditions.component";
import { OveraMessagesService } from "@overa/components/info-dialog";
import { NavigationExtras, Router } from "@angular/router";
// import { DynamicViewService } from "@overa/dynamic";

@Injectable({ providedIn: "root" })
export class AppService {
  private identityService = inject(CLHIdentityService);
  private globals = inject(CLHGlobals);
  private chargerService = inject(ChargerService);
  private shipownerService = inject(ShipownerService);
  private countryService = inject(CountryService);
  private termsService = inject(TermsAndConditionsService);
  private policyService = inject(PrivacyPoliciesService);
  private dialogService = inject(DialogService);
  private accountService = inject(AccountService);
  private messageService = inject(OveraMessagesService);
  private router = inject(Router);
  /// <summary>
  /// Funci�n inicial donde se cargan datos de la aplicaci�n y se establecen en Globals
  /// </summary>
  getData(): Observable<boolean> {
    sessionStorage.removeItem("termsAccepted");

    if (!this.globals.user?.id && this.accountService.currentUser?.id) {
      this.globals.user = this.accountService.currentUser as CLHUser;
    }

    const userId = this.accountService.currentUser?.id || "";
    const currentUserRoles =
      this.accountService.currentUser?.roles?.map((r: any) => r.name) || [];

    return this.identityService.getUsersByRole(this.globals.clhRoleName).pipe(
      catchError((error) => {
        console.error("Error fetching CLH users:", error);
        return of(null);
      }),
      switchMap((clhUsers) =>
        this.countryService.getCountriesByCode(LanguageCode.ES).pipe(
          catchError((error) => {
            console.error("Error fetching countries:", error);
            return of([]);
          }),
          map((countries) => ({ clhUsers, countries }))
        )
      ),
      switchMap(({ clhUsers, countries }) =>
        this.chargerService.getUserById(userId).pipe(
          catchError((error) => {
            console.log("Charger Not Found, attempting to fetch Shipowner...");
            return this.shipownerService.getShipOwnerByUserId(userId).pipe(
              catchError((shipownerError) => {
                console.log("Shipowner Not Found");
                return of(null);
              })
            );
          }),
          map((chargerOrShipowner) => ({
            clhUsers,
            countries,
            chargerOrShipowner,
          }))
        )
      ),
      switchMap(({ clhUsers, countries, chargerOrShipowner }) => {
        let contact: any = null;

        if (chargerOrShipowner && "shipOwners" in chargerOrShipowner) {
          this.globals.currentCharger = chargerOrShipowner as Charger;
          contact =
            this.globals.currentChargerContact ||
            chargerOrShipowner.contacts?.find((x: any) => x.userId === userId);
          this.globals.currentChargerContact =
            this.globals.currentCharger.contacts?.find(
              (x: any) => x.userId === userId
            );
          localStorage.removeItem("currentEntity");
          this.globals.currentShipOwner = undefined;
        } else if (chargerOrShipowner) {
          this.globals.currentShipOwner = chargerOrShipowner as Shipowner;
          contact =
            this.globals.currentShipOwnerContact ||
            chargerOrShipowner.contacts?.find((x: any) => x.userId === userId);
          this.globals.currentShipOwnerContact =
            this.globals.currentShipOwner.contacts?.find(
              (x: any) => x.userId === userId
            );
          this.globals.currentCharger = undefined;
          localStorage.removeItem("currentEntity");
        }

        if (contact) {
          return combineLatest([
            this.termsService.mustAcceptTermsAndConditions(contact.id).pipe(
              catchError((error) => {
                console.error("Error fetching terms acceptance:", error);
                return of(false);
              })
            ),
            this.policyService.mustAccept(contact.id).pipe(
              catchError((error) => {
                console.error("Error fetching policy acceptance:", error);
                return of(false);
              })
            ),
          ]).pipe(
            map(([mustAcceptTerms, mustAcceptPolicy]) => {
              this.globals.mustAcceptTerms = mustAcceptTerms;
              this.globals.mustAcceptPolicy = mustAcceptPolicy;
              if (!mustAcceptTerms && !mustAcceptPolicy) {
                this.globals.acceptedConditions = true;
              }
              else {
                this.showDialogTermsConditions(mustAcceptTerms, mustAcceptPolicy);
              }
              return { clhUsers, chargerOrShipowner, countries };
            })
          );
        } else {
          return of({ clhUsers, chargerOrShipowner, countries });
        }
      }),
      map((result) => {
        const { chargerOrShipowner } = result;

        if (this.globals.currentCharger) {

        } else if (this.globals.currentShipOwner) {

        }

        if (currentUserRoles.includes(this.globals.clhRoleName)) {
          this.globals.isClh = true;
          this.globals.acceptedConditions = true;
        }

        if (
          this.accountService.currentUser.isAdmin ||
          this.accountService.currentUser.isGlobalAdmin
        ) {
          this.globals.acceptedConditions = true;
        }

        if (result.countries) {
          this.globals.countriesES = result.countries.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          this.globals.countriesEN = result.countries
            .map((x) => {
              return { ...x, name: x.nameEnglish };
            })
            .sort((a, b) => a.name.localeCompare(b.name));
        }

        return true;
      }),
      catchError((error) => {
        console.error("Final Error Handling in getData:", error);
        return of(false);
      })
    );
  }
  getUserType(
    clhRoleName: string,
    charger?: Charger,
    shipowner?: Shipowner
  ): any {
    let userType;
    if (clhRoleName !== "") {
      if (charger && charger.id) {
        userType = charger;
      } else {
        userType = shipowner;
      }
    }
    return userType;
  }
  showDialogTermsConditions(mustAcceptTerms: boolean, mustAcceptPolicy: boolean) {
    let userType;
    userType = this.getUserType(
      this.globals.clhRoleName,
      this.globals.currentCharger,
      this.globals.currentShipOwner
    );
    const g = this.globals;

    const itemContact = g.currentCharger
      ? g.currentChargerContact
      : g.currentShipOwnerContact;
    if (itemContact) {
      const userWithDate = itemContact as any;
      if (mustAcceptTerms) {
        userWithDate.mustAcceptTermsAndConditions = true;
      } else {
        userWithDate.mustAcceptTermsAndConditions = false;
      }
      if (mustAcceptPolicy) {
        userWithDate.mustAcceptPrivacyPolicy = true;
      } else {
        userWithDate.mustAcceptPrivacyPolicy = false;
      }
      const date =
        itemContact.termsAndConditionsAceptationDate != null
          ? itemContact.termsAndConditionsAceptationDate
          : undefined;
      const datePrivacy =
        itemContact.privacyPolicyAceptationDate != null
          ? itemContact.privacyPolicyAceptationDate
          : undefined;
      const termsDate = date;
      const termsDatePolicy = datePrivacy;
      const ref = this.dialogService.open(ConditionsComponent, {
        header: "",
        height: "80%",
        width: "70%",
        style: {
          "border-radius": "8px",
          "box-shadow": "0 0 15px rgba(0, 0, 0, 0.2)",
        },
        baseZIndex: 10000,
        closable: false,


        data: {
          userId: this.accountService.currentUser?.id,
          userType: userType,
          termsDate: termsDate,
          userWithDate: userWithDate,

        },
      });
      ref.onClose.subscribe((res: any) => {
        if (res) {
          if (res.acceptedTerms) {
            console.log("acceptedTerms", res.acceptedTerms);
            this.messageService.showToast(
              "OK",
              "TERMS_CONDITIONS.ACCEPT_TERMS_SUCCESS",
              "success",
              ""
            );
          } else {
            this.blockNavigation();
            this.router.navigateByUrl("/core/settings/profileLogOut");
          }
        } else {
        }
      });
    }

  }
  private blockNavigation() {
    const extras: NavigationExtras = { skipLocationChange: true };
    this.router.navigate(["security/access-denied"], extras);
  }

}
