import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ResponseNotificationService } from "@overa/security";
import { NgxTranslateService } from "@overa/shared";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmationService, MessageService } from "primeng/api";
import { Charger } from "src/app/models/charger/charger.model";
import { ChargerService } from "src/app/services/charger.service";

@Component({
  selector: "app-charger-list",
  templateUrl: "./charger-list.component.html",
  styleUrls: ["./charger-list.component.scss"],
  providers: [MessageService, ConfirmationService],
})
export class ChargerListComponent implements OnInit {
  columns: any[] = [];
  chargers: Charger[] = [];
  showTable: boolean = false;
  @Output() onSelectCharger: EventEmitter<any> = new EventEmitter();
  @Input() showButton = true;

  constructor(
    private translate: NgxTranslateService,
    public router: Router,
    private route: ActivatedRoute,
    private chargerService: ChargerService,
    public RNService: ResponseNotificationService,
    public messageService: MessageService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.showTable = false;
    let language = this.translate.currentLang;
    this.spinner.show();
    //,{ id: col4, sort: 'address' },{ id: col5, sort: 'web' },{ id: col6, sort: 'mainContact' }
    //this.columns = [{ id: 'CHARTERER_LIST.TABLE.HEAD_COL0', sort: 'refCharger' }, { id: 'CHARTERER_LIST.TABLE.HEAD_COL1', sort: 'name' }, { id: 'CHARTERER_LIST.TABLE.HEAD_COL3', sort: 'dischargeDate' }, { id: 'CHARTERER_LIST.TABLE.HEAD_COL2', sort: 'cif' }, { id: 'SHIPOWNER_LIST.TABLE.HEAD_COL5', sort: 'address' }, { id: 'SHIPOWNER_LIST.TABLE.HEAD_COL4', sort: 'web' }];
    this.columns = [
      { id: "CHARTERER_LIST.TABLE.HEAD_COL0", sort: "refCharger" },
      { id: "CHARTERER_LIST.TABLE.HEAD_COL1", sort: "name" },
      { id: "CHARTERER_LIST.TABLE.HEAD_COL3", sort: "dischargeDate" },
      { id: "CHARTERER_DATA_SHEET.CIF_CHARGER", sort: "cif" },
      { id: "SHIPOWNER_LIST.TABLE.HEAD_COL5", sort: "address" },
      { id: "CHARTERER_DATA_SHEET.TOWN", sort: "town" },
      { id: "CHARTERER_DATA_SHEET.PROVINCE", sort: "province" },
      { id: "CHARTERER_DATA_SHEET.COUNTRIES", sort: "countryName" },
    ];

    //console.log(this.columns);
    this.chargerService.get().subscribe({
      next: (chargersResult) => {
        if (chargersResult) {
          this.chargers = chargersResult;
          this.chargers.forEach((s) => {
            //var arrAdress = s.address ? [s.address] : [];
            //if (s.town) {
            //    arrAdress.push(s.town);
            //}
            //arrAdress.push(s.province);
            //if (s.country) {
            //    arrAdress.push(s.country.name);
            //}
            //s.address = arrAdress.join(', ');
            if (s.country) {
              s.countryName = s.country.name;
              if (this.translate.currentLang == "en") {
                s.countryName = s.country.nameEnglish;
              }
            }
          });
          console.log(this.chargers);
          this.showTable = true;
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      error: (err) => {
        console.error(err);
        this.spinner.hide();
      },
    });
  }

  selectCharger(charger: Charger) {
    console.log(charger);
    this.onSelectCharger.emit(charger);
  }
  isNew(string: string) {
    console.log("new");
    this.onSelectCharger.emit(string);
  }
}
