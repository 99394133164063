<div class="chargerFormContainer">
  <form class="formContainer" [formGroup]="formContactsResponse">
    <div class="formGroup">
      <label class="formLabel">{{'CONTACTS.FORM_REQUIRED.EMAIL' |translate}}</label>
      <p-autoComplete id="float-inputEmailResponse" formControlName="emailAutocomplete" appendTo="body"
        [suggestions]="listContactAutocomplete" (completeMethod)="filterEmail($event)" [minLength]="1"
        (onSelect)="onItemSelect($event)" field="email" [dropdown]="showDropdown" styleClass="listAutocomplete"
        panelStyleClass="panelListAutocomplete"></p-autoComplete>

      <div
        *ngIf="formContactsResponse.get('emailAutocomplete').touched  && !formContactsResponse.get('emailAutocomplete').value  && formContactsResponse.get('emailAutocomplete').hasError('required')"
        class="error-label labelError">
        {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
      </div>

      <div
        *ngIf="formContactsResponse.get('emailAutocomplete').touched  && formContactsResponse.get('emailAutocomplete').value  && formContactsResponse.get('emailAutocomplete').hasError('pattern')"
        class="col error-label labelError">
        {{'MESSAGES.VALIDATION.MAIL' | translate}}
      </div>

      <div
        *ngIf="formContactsResponse.get('emailAutocomplete').touched  && formContactsResponse.get('emailAutocomplete').value && formContactsResponse.get('emailAutocomplete').hasError('domainError')"
        class="error-label labelError">
        {{'MESSAGES.VALIDATION.MAIL_DOMAIN' | translate}} : {{domainPrincipal}}
      </div>

    </div>

    <div class="formGroup">
      <label class="formLabel">{{'CONTACTS.FORM_REQUIRED.NAME' |translate}}</label>
      <input id="float-input" type="text" pInputText formControlName="name" />
      <div
        *ngIf="formContactsResponse.get('name').touched && !formContactsResponse.get('name').value && formContactsResponse.get('name').hasError('required')"
        class="error-label labelError">
        {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
      </div>
    </div>


    <div class="formGroup">
      <label class="formLabel">{{'CONTACTS.FORM_REQUIRED.LAST_NAME' |translate}}</label>
      <input id="float-input" type="text" pInputText formControlName="lastName" />
      <div
        *ngIf="formContactsResponse.get('lastName').touched && !formContactsResponse.get('lastName').value && formContactsResponse.get('lastName').hasError('required')"
        class="error-label labelError">
        {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
      </div>
    </div>


    <div class="formGroup">
      <label class="formLabel">{{'CONTACTS.FORM_REQUIRED.PHONE' |translate}}</label>
      <input id="float-input" type="text" pInputText formControlName="phone" />
      <div
        *ngIf="formContactsResponse.get('phone').touched  && !formContactsResponse.get('phone').value  && formContactsResponse.get('phone').hasError('required')"
        class="error-label labelError">
        {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
      </div>
      <div
        *ngIf="formContactsResponse.get('phone').touched  && formContactsResponse.get('phone').value  && formContactsResponse.get('phone').hasError('pattern')"
        class="error-label labelError">
        {{'MESSAGES.VALIDATION.PHONE' | translate}}
      </div>
    </div>


    <div class="formGroup">
      <label class="formLabel">{{'CONTACTS.FORM.COMMENT' |translate}}</label>
      <textarea id="float-input" class="formTextarea" pInputTextarea formControlName="comment"></textarea>

    </div>


    <div class="d-flex flex-row justify-content-around align-items-center">
      <button pButton label="{{'ACTION.SAVE'| translate}}" type="button" icon="pi pi-save" iconPos="right"
        class="formButton" (click)="saveNewContact()"
        [disabled]="!formContactsResponse.valid &&  isNew || !isSelected && !isNew "></button>
      <button pButton label="{{'ACTION.CANCEL'| translate}}" type="button" icon="pi pi-times" iconPos="right"
        (click)="cancelContact()" class="cancelButtonRounded"></button>
    </div>

  </form>
</div>


<hr>
<hr>
<!-- 
<div class="w-100">
  <div [formGroup]="formContactsResponse">
    <div class="d-flex flex-column align-content-center">
      <div class="row mt-3 mb-3">
        <div class="col-2 d-flex justify-content-start divLabelChargerForm">
          <label>{{'CONTACTS.FORM_REQUIRED.EMAIL' |translate}}</label>
        </div>
        <div class="col-10 d-flex flex-column justify-content-start">
          <div class="d-flex flex-row justify-content-start">
            <span class="p-float-label">
              <p-autoComplete
                id="float-inputEmailResponse"
                formControlName="emailAutocomplete"
                appendTo="body"
                [suggestions]="listContactAutocomplete"
                (completeMethod)="filterEmail($event)"
                [minLength]="1"
                (onSelect)="onItemSelect($event)"
                field="email"
                [dropdown]="listContactAutocomplete.length ? true : false"
                styleClass="listAutocomplete"
                panelStyleClass="panelListAutocomplete"
              ></p-autoComplete>
              <label for="float-inputEmailResponse">{{'CONTACTS.FORM.EMAIL' | translate}}</label>
            </span>
            <button
              pButton
              pRipple
              type="button"
              icon="pi pi-refresh"
              class="p-button-text undoField"
              [pTooltip]="'ACTION.CLEAN'| translate"
              tooltipPosition="bottom"
              tooltipStyleClass="tooltipCLH"
              (click)="clean()"
              style="margin-left: 3%"
            ></button>
          </div>
          <div
            *ngIf="formContactsResponse.get('emailAutocomplete').touched  && !formContactsResponse.get('emailAutocomplete').value  && formContactsResponse.get('emailAutocomplete').hasError('required')"
            class="error-label labelError"
          >
            {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
          </div>
          <div
            *ngIf="formContactsResponse.get('emailAutocomplete').touched  && formContactsResponse.get('emailAutocomplete').value  && formContactsResponse.get('emailAutocomplete').hasError('pattern')"
            class="col error-label labelError"
          >
            {{'MESSAGES.VALIDATION.MAIL' | translate}}
          </div>
          <div
            *ngIf="formContactsResponse.get('emailAutocomplete').touched  && formContactsResponse.get('emailAutocomplete').value && formContactsResponse.get('emailAutocomplete').hasError('domainError')"
            class="error-label labelError"
          >
            {{'MESSAGES.VALIDATION.MAIL_DOMAIN' | translate}} : {{domainPrincipal}}
          </div>
        </div>
      </div>
      <div class="row mt-3 mb-3">
        <div class="col-2 d-flex justify-content-start divLabelChargerForm">
          <label>{{'CONTACTS.FORM_REQUIRED.NAME' |translate}}</label>
        </div>
        <div class="col-10 d-flex justify-content-start">
          <span class="p-float-label">
            <input id="float-input" type="text" pInputText formControlName="name" style="width: 100%" />
            <label for="float-inputNifCharger">{{'CONTACTS.FORM.NAME' | translate}}</label>
            <div
              class="col-12"
              *ngIf="formContactsResponse.get('name').touched && !formContactsResponse.get('name').value && formContactsResponse.get('name').hasError('required')"
              class="error-label labelError"
            >
              {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
            </div>
          </span>
        </div>
      </div>
      <div class="row mt-3 mb-3">
        <div class="col-2 d-flex justify-content-start divLabelChargerForm">
          <label>{{'CONTACTS.FORM_REQUIRED.LAST_NAME' |translate}}</label>
        </div>
        <div class="col-10 d-flex justify-content-start">
          <span class="p-float-label">
            <input id="float-input" type="text" pInputText formControlName="lastName" style="width: 100%" />
            <label for="float-inputNifCharger">{{'CONTACTS.FORM.LAST_NAME' | translate}}</label>
            <div
              class="col-12"
              *ngIf="formContactsResponse.get('lastName').touched && !formContactsResponse.get('lastName').value && formContactsResponse.get('lastName').hasError('required')"
              class="error-label labelError"
            >
              {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
            </div>
          </span>
        </div>
      </div>
      <div class="row mt-3 mb-3">
        <div class="col-2 d-flex justify-content-start divLabelChargerForm">
          <label>{{'CONTACTS.FORM_REQUIRED.PHONE' |translate}}</label>
        </div>
        <div class="col-10 d-flex flex-column justify-content-start">
          <div class="d-flex flex-row justify-content-start">
            <span class="p-float-label">
              <input id="float-input" type="text" pInputText formControlName="phone" style="width: 100%" />
              <label for="float-inputNifCharger">{{'CONTACTS.FORM.PHONE' | translate}}</label>
            </span>
          </div>

          <div
            *ngIf="formContactsResponse.get('phone').touched  && !formContactsResponse.get('phone').value  && formContactsResponse.get('phone').hasError('required')"
            class="error-label labelError"
          >
            {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
          </div>
          <div
            *ngIf="formContactsResponse.get('phone').touched  && formContactsResponse.get('phone').value  && formContactsResponse.get('phone').hasError('pattern')"
            class="error-label labelError"
          >
            {{'MESSAGES.VALIDATION.PHONE' | translate}}
          </div>
        </div>
      </div>
      <div class="row mt-3 mb-3">
        <div class="col-2 d-flex justify-content-start divLabelChargerForm">
          <label>{{'CONTACTS.FORM.COMMENT' |translate}}</label>
        </div>
        <div class="col-10 d-flex justify-content-start">
          <span class="p-float-label">
            <input id="float-input" type="text" pInputText formControlName="comment" style="width: 100%" />
            <label for="float-inputNifCharger">{{'CONTACTS.FORM.COMMENT' | translate}}</label>
          </span>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-around align-items-center">
        <button
          pButton
          label="{{'ACTION.SAVE'| translate}}"
          type="button"
          icon="pi pi-save"
          iconPos="right"
          class="saveButtonRounded"
          (click)="saveNewContact()"
          [disabled]="!formContactsResponse.valid &&  isNew || !isSelected && !isNew "
        ></button>
        <button
          pButton
          label="{{'ACTION.CANCEL'| translate}}"
          type="button"
          icon="pi pi-times"
          iconPos="right"
          (click)="cancelContact()"
          class="cancelButtonRounded"
        ></button>
      </div>
    </div>
  </div>
</div>

 -->