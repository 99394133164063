import { UserType } from "../enum/user-type.model";


export class OfferComplete {
 
  offerShipOwnerId?: string;
  userType?:UserType;
  

  constructor() {
    
     }


}
