<!-- Language selector -->
<div class="col-4 div-language-selector w-100 text-end">
  <p-dropdown [options]="languagesTerms" [(ngModel)]="languageTerms" (onChange)="onChangeLanguage($event)"></p-dropdown>
</div>
<ngx-spinner [fullScreen]="false" name="conditionsSpinner"></ngx-spinner>

<!-- Terms and Conditions PDF -->
<div class="pdfs-containers">
  <ng-container *ngIf="showTerms">
    <h4 class="ps-4 conditions-title">{{'TERMS_CONDITIONS.HEADER' | translate}}</h4>
    <div class="conditions-container-global pdf-terms">
      <div class="conditions-container containerTerms">
        <div class="scroll-instruction pb-2">
          <!-- Debe leer y desplazarse hasta el final del documento para aceptar los términos y condiciones. -->
        </div>
        <div class="pdf-container" id="pdfCanvasTermsContainer">
          <!-- <canvas id="pdfCanvasTerms"></canvas> -->
        </div>
      </div>
    </div>
  </ng-container>

  <!-- Privacy Policies PDF -->
  <ng-container *ngIf="showPolicy">
    <h4 class="ps-4 conditions-title">{{'PRIVACY_POLICIES.HEADER' | translate}}</h4>
    <div class="conditions-container-global pdf-policy">
      <div class="conditions-container containerPolicy">
        <div class="scroll-instruction pb-2">
          <!-- Debe leer y desplazarse hasta el final del documento para aceptar las políticas de privacidad. -->
        </div>
        <div class="pdf-container" id="pdfCanvasPolicyContainer">
          <!-- <canvas id="pdfCanvasPolicy"></canvas> -->
        </div>
      </div>
    </div>
  </ng-container>
</div>
<!-- Acceptance Checkbox and Buttons -->
<div class="conditions-footer-buttons" *ngIf="loadedPdfs">
  <div class="check-conditions pb-2" *ngIf="showTerms">
    <label for="acceptedId" class="checkbox-label pe-2">{{'TERMS_CONDITIONS.ACCEPT_TERMS' | translate}}</label>
    <p-checkbox [disabled]="!showButton" [binary]="true" [(ngModel)]="isAcceptedTerms" [inputId]="'acceptedId'"> </p-checkbox>
  </div>
  <div class="check-conditions pb-2" *ngIf="showPolicy">
    <label for="acceptedIdPolicy" class="checkbox-label pe-2">{{'TERMS_CONDITIONS.ACCEPT_POLICY' | translate}}</label>
    <p-checkbox [disabled]="!showButton" [binary]="true" [(ngModel)]="isAcceptedPolicy" [inputId]="'acceptedIdPolicy'"> </p-checkbox>
  </div>
  <div class="conditions-footer-buttons-buttons">
    <p-button class="btn-accept" [disabled]="!showButton" (onClick)="acceptTerms()" [value]="'Aceptar'"> {{'ACTION.ACCEPT' | translate}} </p-button>
    <p-button cssClass="o-btn-cancel" (onClick)="logout()" [value]="'Cerrar sesión'"> {{'SIDENAV.SESSION.LOGOUT' | translate}} </p-button>
  </div>
</div>
