<ngx-spinner></ngx-spinner>
<p-confirmDialog
  styleClass="containerConfirmDialog"
  acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"
></p-confirmDialog>
<p-toast></p-toast>
<div class="card offerComparative">
  <div class="row divTableChatofferComparative">
    <div class="button-show-hidden-chat">
      <span
        role="button"
        (click)="showChat = !showChat"
        class="material-icons corporate-color notranslate"
        [pTooltip]="showChat ? ( 'ACTION.HIDDEN_CHAT'| translate) : ( 'ACTION.SHOW_CHAT'| translate) "
        tooltipPosition="bottom"
        tooltipStyleClass="tooltipCLH"
      >
        chat_bubble_outline
      </span>
      <!-- <i class="pi pi-window-maximize" (click)="showChat = !showChat"></i> -->
    </div>
    <!-- [style.width]="!showChat ? '100%' : '66.66%'" -->
    <div class="col-12 col-sm-12 col-md-12 divTableofferComparative" [ngClass]="!showChat ? 'col-xl-12' : 'col-xl-8'">
      <div class="table-container-comparative">
        <table class="tableofferComparative">
          <thead class="theadofferComparative">
            <tr class="tr-header-comparative">
              <th class="th-header-comparative th-header-left sticky"><div>{{'STEP1_OFFER.TABLE.HEAD.HEAD_1' | translate}}</div></th>
              <th class="th-header-comparative th-header-center sticky"><div>{{'STEP1_OFFER.TABLE.HEAD.HEAD_2' | translate}}</div></th>
              <th class="th-header-comparative th-header-right sticky">
                <div class="d-flex justify-content-around align-items-center">
                  <div>{{offerDto?.refOffer}}</div>
                  <div class="text-center thLine labelRed" *ngIf="offerDto?.releaseDate">{{offerDto?.releaseDate | date:'dd/MM/yy HH:mm'}}</div>
                </div>
              </th>

              <th class="thLine th-shipownername" *ngFor="let offerShipowner of offerDto?.offerShipOwners; index as i" [ngClass]="">
                <div class="d-flex flex-column">
                  <label *ngIf="offerShipowner.shipOwnerName">{{offerShipowner.shipOwnerName}}</label>
                </div>
              </th>
            </tr>
            <tr class="row-blank-small"></tr>

            <tr class="trofferComparative">
              <th class="sticky th-first-numbers"></th>
              <th class="sticky"></th>
              <th class="pr-2 th3offerComparative sticky"><div>{{'COMPARATIVE_OFFER.DEADLINE_LABEL' | translate}}</div></th>

              <th class="thLine th-shipownername" *ngFor="let offerShipowner of offerDto?.offerShipOwners">
                <ng-container *ngIf="offerShipowner.indicationDate else empty2">
                  <div class="div-input">
                    <span [ngClass]="offerShipowner.inTime ? 'in-time' : 'off-time' "
                      >{{offerShipowner.indicationDate | date:'dd/MM/yy HH:mm'}}
                    </span>
                  </div>
                </ng-container>
                <ng-template #empty2>
                  <div class="div-input"></div>
                </ng-template>
              </th>
            </tr>
            <tr class="row-blank"></tr>
          </thead>
          <tbody class="tbodyofferComparative" *ngIf="offerDto">
            <tr class="tr1offerComparative">
              <th scope="row">1</th>
              <td class="labelOffer">{{'STEP1_OFFER.TABLE.ROW.ROW_NAME' | translate}}</td>
              <td>
                <div class="d-flex justify-content-start" *ngIf="offerDto">
                  <label overaellipsis *ngIf="offerDto.chargerName"> {{offerDto.chargerName}}</label>
                </div>
              </td>

              <td
                class="tdLine"
                *ngFor="let offerShipowner of offerDto.offerShipOwners"
                [ngStyle]="{'background-color': offerShipowner.color,'opacity': offerShipowner.opacity}"
              >
                <label overaellipsis class="labelLine" *ngIf="offerShipowner.showOriginalFieldOffer && !offerShipowner.isDeclinedLabel"
                  >{{offerDto.chargerName}}</label
                >
                <label overaellipsis class="labelDecline" *ngIf="offerShipowner.showOriginalFieldOffer && offerShipowner.isDeclinedLabel"
                  >{{offerDto.chargerName}}</label
                >
              </td>
            </tr>
            <tr class="tr2offerComparative">
              <th scope="row">2</th>
              <td class="labelOffer">{{'STEP1_OFFER.TABLE.ROW.ROW_CODE' | translate}}</td>
              <td>
                <div class="d-flex justify-content-start" *ngIf="offerDto">
                  <label overaellipsis *ngIf="offerDto.code"> {{offerDto.code}}</label>
                </div>
              </td>
              <td
                class="tdLine"
                *ngFor="let offerShipowner of offerDto.offerShipOwners"
                [ngStyle]="{'background-color': offerShipowner.color,'opacity': offerShipowner.opacity}"
              >
                <label overaellipsis class="labelLine" *ngIf="offerShipowner.showOriginalFieldOffer && !offerShipowner.isDeclinedLabel"
                  >{{offerDto.code}}</label
                >
                <label overaellipsis class="labelDecline" *ngIf="offerShipowner.showOriginalFieldOffer && offerShipowner.isDeclinedLabel"
                  >{{offerDto.code}}</label
                >
              </td>
            </tr>

            <!-- <tr class="tr3offerComparative">
      <th scope="row">3</th>
      <td class="labelOffer">{{'STEP1_OFFER.TABLE.ROW.ROW_CUSTOMER_NAME' | translate}}</td>
      <td>
        <div class="d-flex justify-content-start" *ngIf="offerDto">
          <label *ngIf="offerDto.customerName"> {{offerDto.customerName}}</label>
        </div>
      </td>
      <td
        class="tdLine"
        *ngFor="let offerShipowner of offerDto.offerShipOwners"
        [ngStyle]="{'background-color': offerShipowner.color,'opacity': offerShipowner.opacity}"
      >
        <label></label>
      </td>
      </tr> -->
            <tr class="tr3offerComparative">
              <th scope="row">3</th>
              <td class="labelOffer">
                {{'STEP1_OFFER.TABLE.ROW.ROW_CARGO_QUANTITY' | translate}} / {{'STEP1_OFFER.TABLE.ROW.ROW_STOWAGE' | translate}} /
                {{'STEP1_OFFER.TABLE.ROW.ROW_COMODITY' | translate}}
              </td>
              <td>
                <div class="field" *ngIf="offerDto">
                  <div class="row">
                    <label overaellipsis>
                      {{offerDto.quantity | number: '1.0-0' }} {{QuantityType[offerDto.quantityType]}} / {{offerDto.stowage}} <br />
                      {{offerDto.commodity}}
                    </label>
                  </div>
                  <!-- <div class="row">
                    <label overaellipsis>{{offerDto.commodity}}</label>
                  </div> -->
                </div>
              </td>
              <td
                class="tdLine"
                *ngFor="let offerShipowner of offerDto.offerShipOwners"
                [ngStyle]="{'background-color': offerShipowner.color,'opacity': offerShipowner.opacity}"
              >
                <ng-container *ngIf="offerShipowner else emptyLine">
                  <div class="row">
                    <label overaellipsis class="labelLine" *ngIf="offerShipowner.showOriginalFieldOffer && !offerShipowner.isDeclinedLabel">
                      {{offerDto.quantity | number: '1.0-0' }} {{QuantityType[offerDto.quantityType]}} / {{offerDto.stowage}} <br />
                      {{offerDto.commodity}}
                    </label>
                    <label overaellipsis class="labelDecline" *ngIf="offerShipowner.showOriginalFieldOffer && offerShipowner.isDeclinedLabel">
                      {{offerDto.quantity | number: '1.0-0' }} {{QuantityType[offerDto.quantityType]}} / {{offerDto.stowage}} <br />
                      {{offerDto.commodity}}
                    </label>
                  </div>
                  <!-- <div class="row">
                    <label overaellipsis class="labelLine" *ngIf="offerShipowner.showOriginalFieldOffer && !offerShipowner.isDeclinedLabel"
                      >{{offerDto.commodity}}</label
                    >
                    <label overaellipsis class="labelDecline" *ngIf="offerShipowner.showOriginalFieldOffer && offerShipowner.isDeclinedLabel"
                      >{{offerDto.commodity}}</label
                    >
                  </div> -->
                </ng-container>
                <ng-template #emptyLine>
                  <label></label>
                </ng-template>
              </td>
            </tr>
            <tr class="tr4offerComparative">
              <th scope="row">4</th>
              <td class="labelOffer">{{'STEP1_OFFER.TABLE.ROW.ROW_LOADING' | translate}}</td>
              <td>
                <div class="d-flex justify-content-start" *ngIf="offerDto">
                  <label overaellipsis *ngIf="offerDto.loadingPorts"> {{offerDto.loadingPorts.join(',')}}</label>
                </div>
              </td>
              <td
                class="tdLine"
                *ngFor="let offerShipowner of offerDto.offerShipOwners"
                [ngStyle]="{'background-color': offerShipowner.color,'opacity': offerShipowner.opacity}"
              >
                <ng-container *ngIf="offerShipowner else emptyLine">
                  <ng-container *ngIf="!offerShipowner.isDeclinedLabel else declinedLabel">
                    <label
                      overaellipsis
                      class="labelLine boldLabel"
                      *ngIf="offerShipowner.loadingPorts.length > 0"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerShipowner.loadingPorts.join(',')}}</label
                    >
                    <label
                      overaellipsis
                      class="labelLine"
                      *ngIf="!offerShipowner.loadingPorts.length && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerDto.loadingPorts.join(',')}}</label
                    >
                  </ng-container>
                  <ng-template #declinedLabel>
                    <label
                      overaellipsis
                      class="labelDecline boldLabel"
                      *ngIf="offerShipowner.loadingPorts.length > 0"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerShipowner.loadingPorts.join(',')}}</label
                    >
                    <label
                      overaellipsis
                      class="labelDecline"
                      *ngIf="!offerShipowner.loadingPorts.length && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerDto.loadingPorts.join(',')}}</label
                    >
                  </ng-template>
                </ng-container>
                <ng-template #emptyLine>
                  <label></label>
                </ng-template>
              </td>
            </tr>
            <tr class="tr5offerComparative">
              <th scope="row">5</th>
              <td class="labelOffer">{{'COMPARATIVE_OFFER.ROW.ROW_LOADING_CONDITIONS' | translate}}</td>
              <td>
                <div class="d-flex justify-content-start" *ngIf="offerDto">
                  <label overaellipsis *ngIf="offerDto.loadingPortConditions">{{offerDto.loadingPortConditions}}</label>
                </div>
              </td>
              <td
                class="tdLine"
                *ngFor="let offerShipowner of offerDto.offerShipOwners"
                [ngStyle]="{'background-color': offerShipowner.color,'opacity': offerShipowner.opacity}"
              >
                <ng-container *ngIf="offerShipowner else emptyLine">
                  <ng-container *ngIf="!offerShipowner.isDeclinedLabel else declinedLabel">
                    <label
                      overaellipsis
                      class="labelLine boldLabel"
                      *ngIf="offerShipowner.loadingPortConditions"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerShipowner.loadingPortConditions}}</label
                    >
                    <label
                      overaellipsis
                      class="labelLine"
                      *ngIf="!offerShipowner.loadingPortConditions && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerDto.loadingPortConditions}}</label
                    >
                  </ng-container>
                  <ng-template #declinedLabel>
                    <label
                      overaellipsis
                      class="labelDecline boldLabel"
                      *ngIf="offerShipowner.loadingPortConditions"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerShipowner.loadingPortConditions}}</label
                    >
                    <label
                      overaellipsis
                      class="labelDecline"
                      *ngIf="!offerShipowner.loadingPortConditions && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerDto.loadingPortConditions}}</label
                    >
                  </ng-template>
                </ng-container>
                <ng-template #emptyLine>
                  <label></label>
                </ng-template>
              </td>
            </tr>
            <tr class="tr6offerComparative">
              <th scope="row">6</th>
              <td class="labelOffer">{{'STEP1_OFFER.TABLE.ROW.ROW_DISCHARGING' | translate}}</td>
              <td>
                <div class="d-flex justify-content-start" *ngIf="offerDto">
                  <label overaellipsis *ngIf="offerDto.dischargingPorts">{{offerDto.dischargingPorts.join(',')}}</label>
                </div>
              </td>
              <td
                class="tdLine"
                *ngFor="let offerShipowner of offerDto.offerShipOwners"
                [ngStyle]="{'background-color': offerShipowner.color,'opacity': offerShipowner.opacity}"
              >
                <ng-container *ngIf="offerShipowner else emptyLine">
                  <ng-container *ngIf="!offerShipowner.isDeclinedLabel else declinedLabel">
                    <label
                      overaellipsis
                      class="labelLine boldLabel"
                      *ngIf="offerShipowner.dischargingPorts.length > 0"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerShipowner.dischargingPorts.join(',')}}</label
                    >
                    <label
                      overaellipsis
                      class="labelLine"
                      *ngIf="!offerShipowner.dischargingPorts.length && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerDto.dischargingPorts.join(',')}}</label
                    >
                  </ng-container>
                  <ng-template #declinedLabel>
                    <label
                      overaellipsis
                      class="labelDecline boldLabel"
                      *ngIf="offerShipowner.dischargingPorts.length > 0"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerShipowner.dischargingPorts.join(',')}}</label
                    >
                    <label
                      overaellipsis
                      class="labelDecline"
                      *ngIf="!offerShipowner.dischargingPorts.length && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerDto.dischargingPorts.join(',')}}</label
                    >
                  </ng-template>
                </ng-container>
                <ng-template #emptyLine>
                  <label></label>
                </ng-template>
              </td>
            </tr>
            <tr class="tr7offerComparative">
              <th scope="row">7</th>
              <td class="labelOffer">{{'COMPARATIVE_OFFER.ROW.ROW_DISCHARGING_CONDITIONS' | translate}}</td>
              <td>
                <div class="d-flex justify-content-start" *ngIf="offerDto">
                  <label *ngIf="offerDto.dischargingPortConditions">{{offerDto.dischargingPortConditions}}</label>
                </div>
              </td>
              <td
                class="tdLine"
                *ngFor="let offerShipowner of offerDto.offerShipOwners"
                [ngStyle]="{'background-color': offerShipowner.color,'opacity': offerShipowner.opacity}"
              >
                <ng-container *ngIf="offerShipowner else emptyLine">
                  <ng-container *ngIf="!offerShipowner.isDeclinedLabel else declinedLabel">
                    <label
                      overaellipsis
                      class="labelLine boldLabel"
                      *ngIf="offerShipowner.dischargingPortConditions"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerShipowner.dischargingPortConditions}}</label
                    >
                    <label
                      overaellipsis
                      class="labelLine"
                      *ngIf="!offerShipowner.dischargingPortConditions && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerDto.dischargingPortConditions}}</label
                    >
                  </ng-container>
                  <ng-template #declinedLabel>
                    <label
                      overaellipsis
                      class="labelDecline boldLabel"
                      *ngIf="offerShipowner.dischargingPortConditions"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerShipowner.dischargingPortConditions}}</label
                    >
                    <label
                      overaellipsis
                      class="labelDecline"
                      *ngIf="!offerShipowner.dischargingPortConditions && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerDto.dischargingPortConditions}}</label
                    >
                  </ng-template>
                </ng-container>
                <ng-template #emptyLine>
                  <label></label>
                </ng-template>
              </td>
            </tr>
            <tr class="tr8offerComparative">
              <th scope="row">8</th>
              <td class="labelOffer">{{'STEP1_OFFER.TABLE.ROW.ROW_LAYCAN' | translate}}</td>
              <td>
                <div class="d-flex justify-content-start" *ngIf="offerDto">
                  <label overaellipsis>{{offerDto.layCanFrom | date: 'dd/MM/yy'}} - {{offerDto.layCanTo | date: 'dd/MM/yy'}} </label>
                </div>
              </td>
              <td
                *ngFor="let offerShipowner of offerDto.offerShipOwners"
                [ngStyle]="{'background-color': offerShipowner.color,'opacity': offerShipowner.opacity}"
              >
                <ng-container *ngIf="offerShipowner else emptyLine">
                  <ng-container *ngIf="!offerShipowner.isDeclinedLabel else declinedLabel">
                    <label
                      overaellipsis
                      class="labelLine boldLabel"
                      *ngIf="offerShipowner.layCanFrom && offerShipowner.layCanTo"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                    >
                      {{offerShipowner.layCanFrom | date: 'dd/MM/yy'}} - {{offerShipowner.layCanTo | date: 'dd/MM/yy'}}
                    </label>
                    <label
                      overaellipsis
                      class="labelLine"
                      *ngIf="((!offerShipowner.layCanFrom && offerShipowner.layCanTo) || (offerShipowner.layCanFrom && !offerShipowner.layCanTo) || (!offerShipowner.layCanFrom && !offerShipowner.layCanTo))&& offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                    >
                      {{offerDto.layCanFrom | date: 'dd/MM/yy'}} - {{offerDto.layCanTo | date: 'dd/MM/yy'}}
                    </label>
                  </ng-container>
                  <ng-template #declinedLabel>
                    <label
                      overaellipsis
                      class="labelDecline boldLabel"
                      *ngIf="offerShipowner.layCanFrom && offerShipowner.layCanTo"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                    >
                      {{offerShipowner.layCanFrom | date: 'dd/MM/yy'}} - {{offerShipowner.layCanTo | date: 'dd/MM/yy'}}
                    </label>
                    <label
                      overaellipsis
                      class="labelDecline"
                      *ngIf="((!offerShipowner.layCanFrom && offerShipowner.layCanTo) || (offerShipowner.layCanFrom && !offerShipowner.layCanTo) || (!offerShipowner.layCanFrom && !offerShipowner.layCanTo))&& offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                    >
                      {{offerDto.layCanFrom | date: 'dd/MM/yy'}} - {{offerDto.layCanTo | date: 'dd/MM/yy'}}
                    </label>
                  </ng-template>
                </ng-container>

                <ng-template #emptyLine>
                  <label>HOL</label>
                </ng-template>
              </td>
            </tr>
            <tr class="tr9offerComparative">
              <th scope="row">9</th>
              <td class="labelOffer">{{'STEP1_OFFER.TABLE.ROW.ROW_CHARTERING' | translate}}</td>
              <td>
                <div class="d-flex justify-content-start" *ngIf="offerDto">
                  <label overaellipsis *ngIf="offerDto.chargeringTerm"> {{offerDto.chargeringTerm}}</label>
                </div>
              </td>
              <td
                class="tdLine"
                *ngFor="let offerShipowner of offerDto.offerShipOwners"
                [ngStyle]="{'background-color': offerShipowner.color,'opacity': offerShipowner.opacity}"
              >
                <ng-container *ngIf="offerShipowner else emptyLine">
                  <ng-container *ngIf="!offerShipowner.isDeclinedLabel else declinedLabel">
                    <label
                      overaellipsis
                      class="labelLine boldLabel"
                      *ngIf="offerShipowner.chargeringTerm"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerShipowner.chargeringTerm}}</label
                    >
                    <label
                      overaellipsis
                      class="labelLine"
                      *ngIf="!offerShipowner.chargeringTerm && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerDto.chargeringTerm}}</label
                    >
                  </ng-container>
                  <ng-template #declinedLabel>
                    <label
                      overaellipsis
                      class="labelDecline boldLabel"
                      *ngIf="offerShipowner.chargeringTerm"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerShipowner.chargeringTerm}}</label
                    >
                    <label
                      overaellipsis
                      class="labelDecline"
                      *ngIf="!offerShipowner.chargeringTerm && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerDto.chargeringTerm}}</label
                    >
                  </ng-template>
                </ng-container>
                <ng-template #emptyLine>
                  <label></label>
                </ng-template>
              </td>
            </tr>
            <tr class="tr10offerComparative">
              <th scope="row">10</th>
              <td class="labelOffer">{{'STEP1_OFFER.TABLE.ROW.ROW_LAYTIME' | translate}}</td>
              <td>
                <div class="d-flex justify-content-start" *ngIf="offerDto">
                  <label overaellipsis *ngIf="offerDto.layTimeTerm"> {{offerDto.layTimeTerm}}</label>
                </div>
              </td>
              <td
                class="tdLine"
                *ngFor="let offerShipowner of offerDto.offerShipOwners"
                [ngStyle]="{'background-color': offerShipowner.color,'opacity': offerShipowner.opacity}"
              >
                <ng-container *ngIf="offerShipowner else emptyLine">
                  <ng-container *ngIf="!offerShipowner.isDeclinedLabel else declinedLabel">
                    <label overaellipsis class="labelLine boldLabel" *ngIf="offerShipowner.layTimeTerm" [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerShipowner.layTimeTerm}}</label
                    >
                    <label
                      overaellipsis
                      class="labelLine"
                      *ngIf="!offerShipowner.layTimeTerm && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerDto.layTimeTerm}}</label
                    >
                  </ng-container>
                  <ng-template #declinedLabel>
                    <label
                      overaellipsis
                      class="labelDecline boldLabel"
                      *ngIf="offerShipowner.layTimeTerm"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerShipowner.layTimeTerm}}</label
                    >
                    <label
                      overaellipsis
                      class="labelDecline"
                      *ngIf="!offerShipowner.layTimeTerm && offerShipowner.showOriginalFieldOffer
                      "
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerDto.layTimeTerm}}</label
                    >
                  </ng-template>
                </ng-container>
                <ng-template #emptyLine>
                  <label></label>
                </ng-template>
              </td>
            </tr>
            <tr class="tr11offerComparative">
              <th scope="row">11</th>
              <td class="labelOffer">{{'STEP1_OFFER.TABLE.ROW.ROW_CP' | translate}}</td>
              <td>
                <div class="d-flex justify-content-start" *ngIf="offerDto">
                  <label overaellipsis *ngIf="offerDto.cpForm"> {{offerDto.cpForm}}</label>
                </div>
              </td>
              <td
                class="tdLine"
                *ngFor="let offerShipowner of offerDto.offerShipOwners"
                [ngStyle]="{'background-color': offerShipowner.color,'opacity': offerShipowner.opacity}"
              >
                <ng-container *ngIf="offerShipowner else emptyLine">
                  <ng-container *ngIf="!offerShipowner.isDeclinedLabel else declinedLabel">
                    <label overaellipsis class="labelLine boldLabel" *ngIf="offerShipowner.cpForm" [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerShipowner.cpForm}}</label
                    >
                    <label
                      overaellipsis
                      class="labelLine"
                      *ngIf="!offerShipowner.cpForm && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerDto.cpForm}}</label
                    >
                  </ng-container>
                  <ng-template #declinedLabel>
                    <label overaellipsis class="labelDecline boldLabel" *ngIf="offerShipowner.cpForm" [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerShipowner.cpForm}}</label
                    >
                    <label
                      overaellipsis
                      class="labelDecline"
                      *ngIf="!offerShipowner.cpForm && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerDto.cpForm}}</label
                    >
                  </ng-template>
                </ng-container>
                <ng-template #emptyLine>
                  <label></label>
                </ng-template>
              </td>
            </tr>
            <tr class="tr12offerComparative">
              <th scope="row">12</th>
              <td class="labelOffer">{{'STEP1_OFFER.TABLE.ROW.ROW_RESTRICTIONS' | translate}}</td>
              <td>
                <div class="d-flex justify-content-start" *ngIf="offerDto">
                  <label overaellipsis *ngIf="offerDto.restrictionsRegarding"> {{offerDto.restrictionsRegarding}}</label>
                </div>
              </td>
              <td
                class="tdLine"
                *ngFor="let offerShipowner of offerDto.offerShipOwners"
                [ngStyle]="{'background-color': offerShipowner.color,'opacity': offerShipowner.opacity}"
              >
                <ng-container *ngIf="offerShipowner else emptyLine">
                  <ng-container *ngIf="!offerShipowner.isDeclinedLabel else declinedLabel">
                    <label
                      overaellipsis
                      class="labelLine boldLabel"
                      *ngIf="offerShipowner.restrictionsRegarding"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerShipowner.restrictionsRegarding}}</label
                    >
                    <label
                      overaellipsis
                      class="labelLine"
                      *ngIf="!offerShipowner.restrictionsRegarding && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerDto.restrictionsRegarding}}</label
                    >
                  </ng-container>
                  <ng-template #declinedLabel>
                    <label
                      overaellipsis
                      class="labelDecline boldLabel"
                      *ngIf="offerShipowner.restrictionsRegarding"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerShipowner.restrictionsRegarding}}</label
                    >
                    <label
                      overaellipsis
                      class="labelDecline"
                      *ngIf="!offerShipowner.restrictionsRegarding && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerDto.restrictionsRegarding}}</label
                    >
                  </ng-template>
                </ng-container>
                <ng-template #emptyLine>
                  <label></label>
                </ng-template>
              </td>
            </tr>
            <tr class="tr13offerComparative">
              <th scope="row">13</th>
              <td class="labelOffer">{{'STEP1_OFFER.TABLE.ROW.ROW_ADD_REQUIREMENTS' | translate}}</td>
              <td>
                <div class="d-flex justify-content-start" *ngIf="offerDto">
                  <label overaellipsis *ngIf="offerDto.aditionalRequirements"> {{offerDto.aditionalRequirements}}</label>
                </div>
              </td>
              <td
                class="tdLine"
                *ngFor="let offerShipowner of offerDto.offerShipOwners"
                [ngStyle]="{'background-color': offerShipowner.color,'opacity': offerShipowner.opacity}"
              >
                <ng-container *ngIf="offerShipowner else emptyLine">
                  <ng-container *ngIf="!offerShipowner.isDeclinedLabel else declinedLabel">
                    <label
                      overaellipsis
                      class="labelLine boldLabel"
                      *ngIf="offerShipowner.aditionalRequirements"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerShipowner.aditionalRequirements}}</label
                    >
                    <label
                      overaellipsis
                      class="labelLine"
                      *ngIf="!offerShipowner.aditionalRequirements && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerDto.aditionalRequirements}}</label
                    >
                  </ng-container>
                  <ng-template #declinedLabel>
                    <label
                      overaellipsis
                      class="labelDecline boldLabel"
                      *ngIf="offerShipowner.aditionalRequirements"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerShipowner.aditionalRequirements}}</label
                    >
                    <label
                      overaellipsis
                      class="labelDecline"
                      *ngIf="!offerShipowner.aditionalRequirements && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerDto.aditionalRequirements}}</label
                    >
                  </ng-template>
                </ng-container>
                <ng-template #emptyLine>
                  <label></label>
                </ng-template>
              </td>
            </tr>
            <tr class="tr14offerComparative">
              <th scope="row">14</th>
              <td class="labelOffer">{{'STEP1_OFFER.TABLE.ROW.ROW_FLETE' | translate}}</td>
              <td>
                <ng-container *ngIf="offerDto && offerDto.freight && !offerDto.freight.startsWith(' ') ">
                  <label overaellipsis> {{offerDto.freight }} </label>
                </ng-container>
              </td>
              <td
                class="tdLine"
                *ngFor="let offerShipowner of offerDto.offerShipOwners"
                [ngStyle]="{'background-color': offerShipowner.color,'opacity': offerShipowner.opacity}"
              >
                <ng-container *ngIf="offerShipowner else emptyLine">
                  <ng-container *ngIf="!offerShipowner.isDeclinedLabel else declinedLabel">
                    <label
                      overaellipsis
                      class="labelLine boldLabel"
                      *ngIf="offerShipowner.freight !== null  && !offerShipowner.freight.startsWith('0 ')"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                    >
                      {{offerShipowner.freight }}</label
                    >
                    <label
                      overaellipsis
                      class="labelLine"
                      *ngIf="(!offerShipowner.freight || offerShipowner.freight.startsWith('0 ')) && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                    >
                      {{offerDto.freight }}</label
                    >
                  </ng-container>
                  <ng-template #declinedLabel>
                    <label overaellipsis class="labelDecline boldLabel" *ngIf="offerShipowner.freight" [attr.datatext]="offerShipowner.shipOwnerName">
                      {{offerShipowner.freight }}</label
                    >
                    <label
                      overaellipsis
                      class="labelDecline"
                      *ngIf="!offerShipowner.freight && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                    >
                      {{offerDto.freight }}</label
                    >
                  </ng-template>
                </ng-container>
                <ng-template #emptyLine>
                  <label></label>
                </ng-template>
              </td>
            </tr>
            <tr class="tr15fferComparative">
              <th scope="row">15</th>

              <td class="labelOffer">{{'COMPARATIVE_OFFER.ROW.ROW_DEMURRAGE' | translate}}</td>
              <td></td>
              <td
                class="tdLine"
                *ngFor="let offerShipowner of offerDto.offerShipOwners"
                [ngStyle]="{'background-color': offerShipowner.color,'opacity': offerShipowner.opacity}"
              >
                <ng-container *ngIf="offerShipowner else emptyLine">
                  <ng-container *ngIf="!offerShipowner.isDeclinedLabel else declinedLabel">
                    <label
                      overaellipsis
                      class="labelLine boldLabel"
                      *ngIf="offerShipowner.demurrage && !offerShipowner.demurrage.startsWith('0 ')"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                    >
                      {{offerShipowner.demurrage }}
                    </label>
                    <label
                      overaellipsis
                      class="labelLine"
                      *ngIf="!offerShipowner.demurrage && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                    >
                      {{offerDto.demurrage }}
                    </label>
                  </ng-container>
                  <ng-template #declinedLabel>
                    <label
                      overaellipsis
                      class="labelDecline boldLabel"
                      *ngIf="offerShipowner.demurrage && offerShipowner.demurrage!=' 0 0'"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                    >
                      {{offerShipowner.demurrage }}
                    </label>
                    <label
                      overaellipsis
                      class="labelDecline"
                      *ngIf="!offerShipowner.demurrage && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                    >
                      {{offerDto.demurrage }}
                    </label>
                  </ng-template>
                </ng-container>
                <ng-template #emptyLine>
                  <label></label>
                </ng-template>
              </td>
            </tr>
            <tr class="tr16offerComparative">
              <th scope="row">16</th>
              <td class="labelOffer">{{'COMPARATIVE_OFFER.ROW.ROW_DEMURRAGE_CONDITIONS' | translate}}</td>
              <td></td>
              <td
                class="tdLine"
                *ngFor="let offerShipowner of offerDto.offerShipOwners"
                [ngStyle]="{'background-color': offerShipowner.color,'opacity': offerShipowner.opacity}"
              >
                <ng-container *ngIf="offerShipowner else emptyLine">
                  <ng-container *ngIf="!offerShipowner.isDeclinedLabel else declinedLabel">
                    <label
                      overaellipsis
                      class="labelLine boldLabel"
                      *ngIf="offerShipowner.demurrageConditions"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerShipowner.demurrageConditions}}
                    </label>
                    <label
                      overaellipsis
                      class="labelLine"
                      *ngIf="!offerShipowner.demurrageConditions && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerDto.demurrageConditions}}
                    </label>
                  </ng-container>
                  <ng-template #declinedLabel>
                    <label
                      overaellipsis
                      class="labelDecline boldLabel"
                      *ngIf="offerShipowner.demurrageConditions"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerShipowner.demurrageConditions}}
                    </label>
                    <label
                      overaellipsis
                      class="labelDecline"
                      *ngIf="!offerShipowner.demurrageConditions && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerDto.demurrageConditions}}
                    </label>
                  </ng-template>
                </ng-container>
                <ng-template #emptyLine>
                  <label></label>
                </ng-template>
              </td>
            </tr>
            <tr class="tr17offerComparative">
              <th scope="row">17</th>
              <td class="labelOffer">{{'COMPARATIVE_OFFER.ROW.ROW_VESSELNAME' | translate}}</td>
              <td></td>
              <td
                class="tdLine"
                *ngFor="let offerShipowner of offerDto.offerShipOwners"
                [ngStyle]="{'background-color': offerShipowner.color,'opacity': offerShipowner.opacity}"
              >
                <ng-container *ngIf="offerShipowner else emptyLine">
                  <ng-container *ngIf="!offerShipowner.isDeclinedLabel else declinedLabel">
                    <label overaellipsis class="labelLine boldLabel" *ngIf="offerShipowner.vesselName" [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerShipowner.vesselName}}
                    </label>
                    <label
                      overaellipsis
                      class="labelLine"
                      *ngIf="!offerShipowner.vesselName && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerDto.vesselName}}
                    </label>
                  </ng-container>
                  <ng-template #declinedLabel>
                    <label overaellipsis class="labelDecline boldLabel" *ngIf="offerShipowner.vesselName" [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerShipowner.vesselName}}
                    </label>
                    <label
                      overaellipsis
                      class="labelDecline"
                      *ngIf="!offerShipowner.vesselName && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerDto.vesselName}}
                    </label>
                  </ng-template>
                </ng-container>
                <ng-template #emptyLine>
                  <label></label>
                </ng-template>
              </td>
            </tr>
            <tr class="tr18offerComparative">
              <th scope="row">18</th>
              <td class="labelOffer">{{'COMPARATIVE_OFFER.ROW.ROW_REMARKS' | translate}}</td>
              <td></td>
              <td
                class="tdLine"
                *ngFor="let offerShipowner of offerDto.offerShipOwners"
                [ngStyle]="{'background-color': offerShipowner.color,'opacity': offerShipowner.opacity}"
              >
                <ng-container *ngIf="offerShipowner else emptyLine">
                  <ng-container *ngIf="!offerShipowner.isDeclinedLabel else declinedLabel">
                    <label overaellipsis class="labelLine boldLabel" *ngIf="offerShipowner.remarks" [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerShipowner.remarks}}
                    </label>
                    <label
                      overaellipsis
                      class="labelLine"
                      *ngIf="!offerShipowner.remarks && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerDto.remarks}}
                    </label>
                  </ng-container>
                  <ng-template #declinedLabel>
                    <label overaellipsis class="labelDecline boldLabel" *ngIf="offerShipowner.remarks" [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerShipowner.remarks}}
                    </label>
                    <label
                      overaellipsis
                      class="labelLine"
                      *ngIf="!offerShipowner.remarks && offerShipowner.showOriginalFieldOffer"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                      >{{offerDto.remarks}}
                    </label>
                  </ng-template>
                </ng-container>
                <ng-template #emptyLine>
                  <label></label>
                </ng-template>
              </td>
            </tr>
            <tr class="row-blank-small"></tr>
            <tr class="new-expiration-date"></tr>
            <!--<tr class="tr19offerComparative">
                            <th scope="row"></th>
                            <td></td>

                            <td class="labelOffer text-end">{{'COMPARATIVE_OFFER.ROW.ROW_DEADLINE' | translate}}</td>
                            <td class="tdLine"
                                *ngFor="let offerShipowner of offerDto.offerShipOwners">
                                <ng-container *ngIf="offerShipowner else emptyLine">
                                    <ng-container *ngIf="!offerShipowner.isDeclinedLabel  else declinedLabel">
                                        <div class="labelLine boldLabel text-center div-input" *ngIf="offerDto?.expirationDate" [attr.datatext]="offerShipowner.shipOwnerName">
                                            {{offerDto?.expirationDate | date:'dd/MM/yy HH:mm'}}
                                        </div>
                                    </ng-container>
                                    <ng-template #declinedLabel>
                                        <div class="labelDecline boldLabel text-center div-input" *ngIf="offerDto?.expirationDate" [attr.datatext]="offerShipowner.shipOwnerName">
                                            {{offerDto?.expirationDate | date:'dd/MM/yy HH:mm'}}
                                        </div>
                                    </ng-template>
                                </ng-container>
                                <ng-template #emptyLine>
                                    <div class="div-input"></div>
                                </ng-template>
                            </td>
                        </tr>
                        <tr class="row-blank"></tr>-->
            <tr class="tr20offerComparative">
              <th scope="row"></th>
              <td></td>

              <td class="labelOffer text-end">{{'COMPARATIVE_OFFER.ROW.ROW_DEADLINE_NEW' | translate}}</td>
              <td class="tdLine" *ngFor="let offerShipowner of offerDto.offerShipOwners">
                <ng-container *ngIf="offerShipowner else emptyLine">
                  <ng-container *ngIf="!offerShipowner.isDeclinedLabel  else declinedLabel">
                    <div
                      class="labelLine boldLabel text-center div-input"
                      *ngIf="offerShipowner.expirationDate"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                    >
                      {{offerShipowner.expirationDate | date:'dd/MM/yy HH:mm'}}
                    </div>
                    <div class="div-input" *ngIf="!offerShipowner.expirationDate"></div>
                  </ng-container>
                  <ng-template #declinedLabel>
                    <div
                      class="labelDecline boldLabel text-center div-input"
                      *ngIf="offerShipowner.expirationDate"
                      [attr.datatext]="offerShipowner.shipOwnerName"
                    >
                      {{offerShipowner.expirationDate | date:'dd/MM/yy HH:mm'}}
                    </div>
                    <div class="div-input" *ngIf="!offerShipowner.expirationDate"></div>
                  </ng-template>
                </ng-container>
                <ng-template #emptyLine>
                  <label></label>
                </ng-template>
              </td>
            </tr>
            <tr class="tr21offerComparative">
              <th scope="row"></th>
              <td></td>
              <td></td>
              <td *ngFor="let offerShipowner of offerDto.offerShipOwners; index as i" class="td16offerComparative tdLine">
                <!-- commented because it is no longer declined -->
                <ng-container *ngIf="!offerShipowner.isDeclinedLabel  else declinedOffer">
                  <div class="row row-cols-1">
                    <div class="d-flex flex-column align-content-between align-items-center" *ngIf="!readonlyComparative">
                      <ng-container *ngIf="!offerShipowner.showButtonRemember  else showRemember">
                        <p-button
                          #pButtonSig
                          label="{{'COMMONS.BUTTON_OFFER' | translate}}"
                          styleClass="buttonOfferFirme"
                          [routerLink]="['/offerFirmBinding/',offerShipowner.id]"
                          routerLinkActive="active"
                          *ngIf="offerShipowner.demurrage"
                        ></p-button>
                        <!-- <p-button label="{{'COMMONS.BUTTON_DECLINE' | translate}}"
                            [routerLink]="['/home']" routerLinkActive="active"
                            styleClass="buttonDeclineComparative" (click)="decline(offerShipowner)">
                        </p-button> -->
                      </ng-container>
                      <ng-template #showRemember>
                        <p-button
                          #pButtonSig
                          label="{{'COMMONS.REMEMBER' | translate}}"
                          styleClass="buttonOfferFirme remember"
                          (onClick)="sendMailRemember(offerShipowner)"
                        ></p-button>
                      </ng-template>
                    </div>
                  </div>
                </ng-container>
                <ng-template #declinedOffer>
                  <div class="d-flex flex-column align-content-between align-items-center" *ngIf="!readonlyComparative">
                    <p-button
                      [label]="offerShipowner.state ===   StateOffershipowner.REFUSED   ? ( 'COMPARATIVE_OFFER.DECLINED' | translate) :  ('COMPARATIVE_OFFER.DISABLED' | translate) "
                      styleClass="buttonDeclineComparative"
                      [disabled]="true"
                    >
                    </p-button>
                  </div>
                </ng-template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- [style.display]="showChat ? 'inline' : 'none'" -->
    <!-- [hidden]="!showChat" [style.opacity]="!showChat ? '0' : '1'" -->
    <div
      class="col-12 col-sm-12 col-md-11 col-xl-4 divChatofferComparative overflowInX div-chat-small"
      [ngClass]="showChat ? 'assigned-display-block' : 'assigned-display-none'"
    >
      <div class="col-12">
        <p-tabView *ngIf="chatValues && (chatValues.length > 0) && allMethodsCompleted;else loadingSpinner">
          <ng-container *ngFor="let group of chatValues">
            <p-tabPanel header="{{group.windowName}}">
              <app-chat-room
                [offerShipOwnerId]="group.offerShipOwnerId"
                [offerId]="group.offerId"
                [windowName]="group.windowName"
                [currentParticipant]="group.currentParticipant"
                [group]="group.group"
                [messages]="group.historicMessages"
                [unReadMessages]="group.unReadMessages"
                (unReadMessagesUpdated)="group.unReadMessages = $event"
              ></app-chat-room>
            </p-tabPanel>
          </ng-container>
        </p-tabView>
        <ng-template #loadingSpinner>
          <div class="spinner-container">
            <mat-spinner></mat-spinner>
          </div>
        </ng-template>
      </div>
      <p-accordion styleClass="accordionResponse">
        <p-accordionTab *ngIf="offerId">
          <ng-template pTemplate="header">
            <p
              [escape]="false"
              [pTooltip]="'TOOLTIP.NOTES'| translate"
              tooltipPosition="bottom"
              tooltipStyleClass="tooltipCLH"
              [showDelay]="1000"
              [hideDelay]="1000"
            >
              {{'NOTE.HEADER' | translate}}
            </p>
          </ng-template>
          <ng-template pTemplate="content">
            <overa-notes [entityId]="offerId"> </overa-notes>
          </ng-template>
        </p-accordionTab>
      </p-accordion>
    </div>

    <div class="containerButtonStep1">
      <div class="div-btn-cancel">
        <div *ngIf="!readonlyComparative">
          <p-button label="{{'COMPARATIVE_OFFER.CANCEL_ARCHIVE' | translate}}" styleClass="borrar" (onClick)="cancelAndArchiveOffer()"></p-button>
        </div>
      </div>
    </div>
  </div>
</div>
