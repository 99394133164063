import { Component, OnInit, Input } from '@angular/core';
import { ClauseNegotiation } from 'src/app/models/clause/clause-negotiation.model';
import { NegotiationOfferShipOwner } from 'src/app/models/offers/negotiation-offer-shipowner.model';
import { NegotiationOfferShipOwnerHistory } from 'src/app/models/offers/negotiation-offer-shipowner-history.model';
import { NegotiationFieldState } from 'src/app/models/offers/negotiation-field-state.model';
import { StateOfferShipOwner } from 'src/app/models/enum/state-offer-shipowner.model';

@Component({
  selector: 'app-histories-offer',
  templateUrl: './histories-offer.component.html',
  styleUrls: ['./histories-offer.component.scss'],
})
export class HistoriesOfferComponent implements OnInit {
  @Input() fieldName: string = '';
  @Input() negotiationOfferShipOwner: NegotiationOfferShipOwner;
  @Input() clauseSend: ClauseNegotiation;
  histories: NegotiationOfferShipOwnerHistory[] = [];
  isClause: boolean = false;
  fieldNameClause: string = '';
  constructor() {}
  loadedHistories = false;
  readonly NegotiationFieldState = NegotiationFieldState;
  stateReopened: NegotiationFieldState[] = [NegotiationFieldState.CHARGER_PENDING_REOPENED, NegotiationFieldState.SHIPOWNER_PENDING_REOPENED];
  statePending: NegotiationFieldState[] = [NegotiationFieldState.CHARGER_PENDING, NegotiationFieldState.SHIPOWNER_PENDING];
  stateConfirmed: NegotiationFieldState[] = [NegotiationFieldState.CONFIRMED];
  ngOnInit(): void {
    // console.log('NEGOTIATION', this.negotiationOfferShipOwner);
    // console.log('NEGOTIATION', this.clauseSend);
    // console.log('NEGOTIATION', this.fieldName);
    if (!this.fieldName.includes('chargerClauses') && !this.fieldName.includes('shipOwnerClauses')) {
      let historiesLabel = this.fieldName + 'Histories';
      this.isClause = false;
      this.histories = this.negotiationOfferShipOwner[historiesLabel];
    } else {
      this.isClause = true;
      this.histories = this.clauseSend.clauseHistories;
    }
    // console.log('HISTORIES', this.histories);
    if (this.histories) {
      this.histories.forEach((h, index) => {
        if (index > 0) {
          if (
            this.histories[index - 1].state != h.state &&
            this.stateConfirmed.includes(this.histories[index - 1].state) &&
            !this.stateReopened.includes(h.state)
          ) {
            const history = { ...this.histories[index - 1] };
            history.status = 'Accepted';
            this.histories[index - 1] = history;
          }
          if (h.observations) {
            h.status = h.observations;
          } else {
            if (this.histories[index + 1] && this.histories[index + 1].state != h.state) {
              if (this.stateConfirmed.includes(this.histories[index + 1].state) && this.stateReopened.includes(h.state)) {
                h.status = 'Reopened';
              }
              if (
                this.stateConfirmed.includes(h.state) &&
                (this.statePending.includes(this.histories[index + 1].state) || this.stateReopened.includes(this.histories[index + 1].state))
              ) {
                h.status = 'Accepted';
              }
            }
          }

          if (h.offerShipOwnerState != null) {
            h.statusOffershipowner = 'STATE_HISTORY_OFFERSHIPOWNER.' + StateOfferShipOwner[h.offerShipOwnerState].toString().toUpperCase();
          }
        } else {
          if (h.observations) {
            h.status = h.observations;
          } else {
            if (this.histories[index + 1]) {
              if (this.stateReopened.includes(h.state) && this.stateConfirmed.includes(this.histories[index + 1].state)) {
                h.status = 'Reopened';
              }
              if (
                this.stateConfirmed.includes(h.state) &&
                (this.statePending.includes(this.histories[index + 1].state) || this.stateReopened.includes(this.histories[index + 1].state))
              ) {
                h.status = 'Accepted';
              }
            }
          }

          if (h.offerShipOwnerState != null) {
            h.statusOffershipowner = 'STATE_HISTORY_OFFERSHIPOWNER.' + StateOfferShipOwner[h.offerShipOwnerState].toString().toUpperCase();
          }
        }
        if (this.histories[index + 1]) {
          if (this.histories[index + 1].state != h.state && h.state == 2) {
          }
        }
      });
      if (this.histories.length > 1) {
        if (this.histories[0].value != this.histories[1].value && this.histories[0].label != 'Quantity') {
          this.histories[0].haveChange = true;
        }
        if (this.isClause) {
          if (this.histories[0].isRefused != this.histories[1].isRefused) {
            this.histories[0].haveChange = true;
          }
        }
      }
    }
    // console.log(this.histories);
    this.loadedHistories = true;
  }
}
