import { OfferShipOwner } from "src/app/models/offers/offer-shipowner.model";
import { ChatGroupParticipant } from "./chatGroupParticipant.model";
import { Offer } from "../offers/offer.model";

export class ChatGroup {
    id:string;
    groupName: string;
    offerId?: string;
    offerShipOwnerId?: string;
    offerShipOwner?: OfferShipOwner;
    offer?:Offer;
    groupParticipants: ChatGroupParticipant[];
}