<ngx-spinner></ngx-spinner>
<p-toast></p-toast>
<p-confirmDialog
  styleClass="containerConfirmDialog"
  acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"
></p-confirmDialog>
<div class="card" *ngIf="isShow">
  <div class="row">
    <div class="col-12 col-md-6 step3-left step-3-table" *ngIf="offer">
      <ng-container>
        <app-table-offer [offer]="offerUpdate" [readonly]="true" [form]="form"></app-table-offer>
      </ng-container>
    </div>
    <div class="col-12 col-md-6 step3-right">
      <div class="card" *ngIf="offer">
        <ng-container *ngFor="let offerShip of offer.offerShipOwners">
          <div class="row">
            <span class="col-1">
              <i class="pi pi-check" style="color: orange"></i>
            </span>
            <div class="col-11" *ngIf="offerShip">
              <span class="bold" *ngIf="!readonlyStep3">{{'COMMONS.SENT_EMAIL' | translate}} {{offerShip.shipOwner?.charteringCompany}} </span>
              <span class="bold" *ngIf="readonlyStep3"
                >{{'COMMONS.HAS_BEEN_SENT_EMAIL' | translate}} {{offerShip.shipOwner?.charteringCompany}}
              </span>
              <span *ngIf="!offerShip.comment"></span>
              <div *ngIf="offerShip.comment">
                <span>{{'COMMONS.PERSONALIZED_MESSAGE' | translate}} : </span>
                <span *ngIf="offerShip.comment" [innerHtml]="offerShip.comment | safeHtml: true"></span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="step3-filemanager">
        <div class="documents-accordion mb-3 mt-3" *ngIf="offer && offer.id">
          <p-accordion styleClass="accordionResponse">
            <p-accordionTab>
              <ng-template pTemplate="header"
                ><p
                  [escape]="false"
                  [pTooltip]="'TOOLTIP.DOCUMENT'| translate"
                  tooltipPosition="bottom"
                  tooltipStyleClass="tooltipCLH"
                  [showDelay]="1000"
                  [hideDelay]="1000"
                >
                  {{'DOCUMENT.HEADER' | translate}}
                </p></ng-template
              >
              <ng-template pTemplate="content">
                <app-documents
                  [form]="form"
                  [entityId]="offer.id"
                  [placeholder]="readonlyStep3 ? ('DOCUMENT.HEADER' | translate) : ('DOCUMENT.SELECT' | translate)"
                  [readonly]="readonlyStep3"
                >
                </app-documents>
                <!--<overa-file-upload *ngIf="offer?.id" [rootId]="offer?.id" [rootKey]="'offer'"></overa-file-upload>-->
              </ng-template>
            </p-accordionTab>
            <p-accordionTab *ngIf="isCharger">
              <ng-template pTemplate="header">
                <p
                  [escape]="false"
                  [pTooltip]="'TOOLTIP.NOTES'| translate"
                  tooltipPosition="bottom"
                  tooltipStyleClass="tooltipCLH"
                  [showDelay]="1000"
                  [hideDelay]="1000"
                >
                  {{'NOTE.HEADER' | translate}}
                </p></ng-template
              >
              <ng-template pTemplate="content">
                <overa-notes [entityId]="offer.id"> </overa-notes>
              </ng-template>
            </p-accordionTab>
          </p-accordion>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-5 mb-5" *ngIf="form" [formGroup]="form">
    <!-- DEADLINE -->

    <div
      *ngIf="!readonlyStep3 || (readonlyStep3 && checked)"
      [ngClass]="!readonlyStep3 ? 'divDeadLine d-flex justify-content-center' : 'divDeadLine d-flex justify-content-center disabledToggle'"
    >
      <h6
        [escape]="false"
        [pTooltip]="'TOOLTIP.SET_VALIDITY'| translate"
        tooltipPosition="bottom"
        tooltipStyleClass="tooltipCLH"
        [showDelay]="1000"
        [hideDelay]="1000"
      >
        <strong>{{'BINDING_OFFER.DEADLINE_LABEL' | translate}}</strong>

        <p-calendar
          #calendarExpiration
          formControlName="expirationDate"
          dateFormat="dd/mm/yy"
          styleClass="calendarBinding calendarBindingMargin"
          [showIcon]="true"
          [showTime]="true"
          [showSeconds]="false"
          [minDate]="today"
        ></p-calendar>
        <p-inputSwitch
          formControlName="checkedExpirationDate"
          styleClass="switchCLH"
          *ngIf="!readonlyStep3"
          (onChange)="sendExpirationDate($event);($event.checked ? calendarExpiration.showOverlay() : null)"
        ></p-inputSwitch>
      </h6>
    </div>
    <div class="d-flex d-flex justify-content-center">
      <div *ngIf="form.get('expirationDate').touched  && form.errors?.['expirationDate']" class="error-label labelError">
        {{'MESSAGES.VALIDATION.EXPIRATION_DATE' | translate}}
      </div>
    </div>
  </div>

  <div class="containerButtonDeleteLeft">
    <div *ngIf="canDeleted" class="containerButtonStep1">
      <div>
        <button type="button" pButton label="{{'COMMONS.DELETE_2' | translate}}" class="p-button p-ripple borrar" (click)="deleteOffer()"></button>
      </div>
    </div>
    <div class="containerButtonStep1">
      <div class="div-btn-cancel">
        <div>
          <button pButton label="{{'ACTION.PREVIOUS' | translate}}" class="p-button cancelar p-ripple" (click)="back()"></button>
        </div>
      </div>
      <div class="div-btn-next">
        <div *ngIf="!readonlyStep3">
          <button
            pButton
            type="button"
            pButtonSig
            label="{{'ACTION.NEXT' | translate}}"
            (click)="continue()"
            class="p-button siguiente actualizar p-ripple"
          ></button>
        </div>
        <div *ngIf="readonlyStep3">
          <button
            pButton
            type="button"
            icon="pi pi-home"
            iconPos="left"
            class="p-button home custom-button p-ripple"
            [disabled]="isLoading"
            (click)="continue()"
          ></button>
        </div>
      </div>
    </div>
  </div>
  <!--<div class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center" align="center">
            <p-button pButtonCan label="{{'ACTION.PREVIOUS' | translate}}" styleClass="p-button btn-back" (click)="back()"></p-button>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center" align="center">
            <p-button *ngIf="!readonlyStep3"
                      pButtonSig
                      label="{{'ACTION.SEND' | translate}}"
                      styleClass="p-button btn-continue"
                      [disabled]="isLoading"
                      (onClick)="continue()"></p-button>
            <p-button *ngIf="readonlyStep3"
                      pButtonSig
                      icon="pi pi-home"
                      iconPos="center"
                      styleClass="p-button btn-continue"
                      [disabled]="isLoading"
                      (onClick)="continue()"></p-button>
        </div>
    </div>-->
</div>
