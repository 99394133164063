import { environment } from "src/environments/environment";
import { dynamic_registry } from "./utils/dynamic_registry";
import * as CryptoJS from "crypto-js";

/**
 * Represents the application configuration.
 */
export const AppConfig = {
  /**
   * The name of the application.
   */
  appName: environment.app,

  /**
   * The version of the application.
   */
  appVersion: environment.version,

  /**
   * Custom configuration function that modifies the config data based on app settings.
   * @param configData - The configuration data object.
   * @param appSettingsData - The application settings data.
   */
  customConfig: (configData: { [key: string]: any }, appSettingsData: any) => {
    configData["overa-components"]["overa-error-dialog"].showToast =
      environment.production;
    configData["overa-dynamic"]["registry"] = dynamic_registry;
    configData["overa-components"]["overa-show-dialog-errors"] = false;
    //TODO: REMOVE
    // configData["overa-storage"]["storageBaseUrl"] = "https://sarapaz-cargolighthouse-clh-sara.live.develop.overa.net/storage-api/v1";
    // configData["overa-security"]["apiBaseUrl"] = configData["overa-security"]["apiBaseUrl"] + "/v1";

    // configData["overa-security"]["redirect_uri"] = location.href;
    const url = location.href;
    const passwordEncrypt = "@vEr4ClH";
    let offerId: string | null = null;
    const offerIdRegex = /\/(offerReceived|offerFirmBinding)\/([a-z0-9-]+)/i;
    const offerIdMatch = url.match(offerIdRegex);


    const extraQueryParams = {};

    if (offerIdMatch) {
      offerId = offerIdMatch[2]; // El offerId es el segundo grupo de la expresión regular
      console.log("Extracted offerId:", offerId);

      // Añadir offerId a configData
      extraQueryParams["offerId"] = offerId;

    }
    if (url.includes("?")) {
      const queryString = url.split("?")[1];
      const queryParams = new URLSearchParams(queryString);


      // Iterar sobre todos los parámetros del queryString
      queryParams.forEach((value, key) => {
        let decryptedValue = value;

        // Intentar desencriptar el valor
        try {
          const decrypted = CryptoJS.AES.decrypt(value.trim(), passwordEncrypt).toString(CryptoJS.enc.Utf8);
          if (decrypted) {
            decryptedValue = decrypted;
          }
        } catch (error) {
          console.warn(`Error decrypting parameter "${key}":`, error);
        }

        // Añadir el parámetro al objeto de extraQueryParams
        extraQueryParams[key] = decryptedValue;
      });

      console.log("Extracted and processed query parameters:", extraQueryParams);

      // Si hay parámetros extraídos, añadirlos a configData
      if (Object.keys(extraQueryParams).length > 0) {
        configData["overa-security"]["extraQueryParams"] = extraQueryParams;
      }
    }


  },
};
