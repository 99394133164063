<ng-container [formGroup]="form">
  <ng-container *ngIf="showLabelFloat else withoutLabel" style="width: 100%">
    <span class="p-float-label spanMargin">
      <textarea
        [formControlName]="nameControl"
        [style]="style"
        [placeholder]="placeholder ? (placeholder | translate): ''"
        [id]="idTextarea"
        [ngClass]="classTextarea"
        [required]="required"
        [readOnly]="readOnly"
        pInputTextarea
        (click)="onTextareaClick()"
        (blur)="onTextareaBlur()"
        [class.tooltipDisabled]="true"
      ></textarea>
      <label
        [for]="idTextarea"
        [escape]="escape"
        [pTooltip]=" pTooltip | translate"
        [tooltipPosition]="tooltipPosition"
        [tooltipStyleClass]="tooltipStyleClass"
        [showDelay]="showDelay"
        [hideDelay]="hideDelay"
        >{{ label | translate}}</label
      >
    </span>
  </ng-container>
  <ng-template #withoutLabel>
    <textarea
      [formControlName]="nameControl"
      [style]="style"
      [placeholder]="placeholder ? (placeholder | translate): ''"
      [id]="idTextarea"
      [ngClass]="classTextarea"
      [required]="required"
      [readOnly]="readOnly"
      (click)="onTextareaClick()"
      (blur)="onTextareaBlur()"
      pInputTextarea
      [escape]="escape"
      [pTooltip]=" pTooltip | translate"
      [tooltipPosition]="tooltipPosition"
      [tooltipStyleClass]="tooltipStyleClass"
      [showDelay]="showDelay"
      [hideDelay]="hideDelay"
    ></textarea>
  </ng-template>
  <div *ngIf="form.get(nameControl).touched  && form.get(nameControl).hasError('required')">
    <label class="error-label labelError" style="color: red">{{errorLabel | translate}} </label>
  </div>
</ng-container>
