import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgxTranslateService } from "@overa/shared";
import { NgxSpinnerService } from "ngx-spinner";
import { Shipowner } from "src/app/models/shipowner/shipowner.model";
import { ShipownerService } from "src/app/services/shipowner.service";
import { CLHGlobals } from "src/app/utils/globals";

@Component({
  selector: "app-shipowner-list",
  templateUrl: "./shipowner-list.component.html",
  styleUrls: ["./shipowner-list.component.scss"],
})
export class ShipownerListComponent implements OnInit {
  columns: any[] = [];
  shipowners: Shipowner[] = [];
  showTable: boolean = false;
  @Output() onSelectShipowner: EventEmitter<any> = new EventEmitter();
  @Input() showButton = true;

  constructor(
    private translate: NgxTranslateService,
    private globals: CLHGlobals,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private shipOwnerService: ShipownerService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.showTable = false;
    this.columns = [
      { id: "CHARTERER_LIST.TABLE.HEAD_COL0", sort: "refCharger" },
      { id: "CHARTERER_LIST.TABLE.HEAD_COL1", sort: "name" },
      { id: "CHARTERER_LIST.TABLE.HEAD_COL3", sort: "dischargeDate" },
      { id: "CHARTERER_DATA_SHEET.CIF_CHARGER", sort: "cif" },
      { id: "SHIPOWNER_LIST.TABLE.HEAD_COL5", sort: "address" },
      { id: "CHARTERER_DATA_SHEET.TOWN", sort: "town" },
      { id: "CHARTERER_DATA_SHEET.PROVINCE", sort: "province" },
      { id: "CHARTERER_DATA_SHEET.COUNTRIES", sort: "countryName" },
    ];

    this.columns = [
      { id: "SHIPOWNER_LIST.TABLE.HEAD_COL0", sort: "refShipOwner" },
      { id: "SHIPOWNER_LIST.TABLE.HEAD_COL1", sort: "charteringCompany" },
      { id: "SHIPOWNER_LIST.TABLE.HEAD_COL2", sort: "dischargeDate" },
      { id: "CHARTERER_DATA_SHEET.CIF_CHARGER", sort: "cifVat" },
      { id: "SHIPOWNER_LIST.TABLE.HEAD_COL5", sort: "address" },
      { id: "CHARTERER_DATA_SHEET.TOWN", sort: "town" },
      { id: "CHARTERER_DATA_SHEET.PROVINCE", sort: "province" },
      { id: "CHARTERER_DATA_SHEET.COUNTRIES", sort: "countryName" },
      { id: "SHIPOWNER_LIST.TABLE.HEAD_COL6", sort: "isBanned" },
    ];
    this.spinner.show();
    this.shipOwnerService.get().subscribe({
      next: (shipownersResult) => {
        if (shipownersResult) {
          // shipownersResult.forEach(item=>{
          //   item.dischargeDate=new Date(item.dischargeDate);
          // }

          //  );
          this.shipowners = shipownersResult;
          this.shipowners.forEach((s) => {
            //var arrAdress = s.address ? [s.address] : [];
            //if (s.town) {
            //    arrAdress.push(s.town);
            //}
            //arrAdress.push(s.province);
            //if (s.country) {
            //    arrAdress.push(s.country.name);
            //}
            //s.address = arrAdress.join(', ');
            if (!s.country && s.countryId) {
              s.country = this.globals.countriesES.find(
                (x) => x.id == s.countryId
              );
            }
            if (s.country) {
              s.countryName = s.country.name;
              if (this.translate.currentLang == "en") {
                s.countryName = s.country.nameEnglish;
              }
            }
          });
          console.log(this.shipowners);
          this.showTable = true;
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      error: (err) => {
        console.error(err);
        this.spinner.hide();
      },
    });
  }
  selectShipowner(shipOwner: Shipowner) {
    console.log(shipOwner);
    this.onSelectShipowner.emit(shipOwner);
  }
  isNew(string: string) {
    console.log("new");
    this.onSelectShipowner.emit(string);
  }
}
