import { inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AccountService } from "@overa/security";
import { BehaviorSubject } from "rxjs";
import { OfferFilter } from "../models/searcher/offer-filter.model";
import { CLHBaseService } from "./clh-base.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class PermissionsService extends CLHBaseService {
  accountService = inject(AccountService);
  route = inject(ActivatedRoute);

  hasPermissionToEdit(activatedRoute?: ActivatedRoute) {
    var rolesNames = this.accountService.currentUser.roles.map((x) =>
      x.name.trim()
    );
    if (this.accountService.currentUser.isGlobalAdmin) {
      return true;
    }
    var dataRoles = activatedRoute
      ? activatedRoute.snapshot.data?.["editRoles"]
      : this.route.snapshot.data?.["editRoles"];
    if (dataRoles) {
      return rolesNames.some((r) => dataRoles.includes(r.trim()));
    } else {
      return true;
    }
    // console.log(this.dataSearch);
  }
}
