import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { EChartsOption } from "echarts";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  chartOption: EChartsOption;
  chartOption1: EChartsOption;
  chartOption2: EChartsOption;
  constructor() {}

  ngOnInit() {
    this.chartOption = {
      legend: {
        top: "bottom",
      },
      toolbox: {
        show: false,
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      series: [
        {
          name: "Graphic 0",
          type: "pie",
          radius: [30, 100],
          center: ["50%", "50%"],
          roseType: "area",
          itemStyle: {
            borderRadius: 8,
          },
          data: [
            { value: 40, name: "Web Oficial" },
            { value: 38, name: "Google" },
            { value: 32, name: "Linkedin" },
          ],
        },
      ],
    };

    this.chartOption1 = {
      // title: {
      //   text: 'Stacked Area Chart'
      // },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      legend: {
        data: ["Envio_1", "Envio_2"],
      },
      // toolbox: {
      //   feature: {
      //     saveAsImage: {}
      //   }
      // },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          boundaryGap: false,
          data: ["Jan", "Feb", "Marc", "Apr", "May", "Jun", "Jul"],
        },
      ],
      yAxis: [
        {
          type: "value",
        },
      ],
      series: [
        {
          name: "Envio_1",
          type: "line",
          smooth: true,
          stack: "Total",
          areaStyle: {},
          emphasis: {
            focus: "series",
          },
          data: [20, 32, 1, 4, 9, 23, 21],
        },
        {
          name: "Envio_2",
          type: "line",
          smooth: true,
          stack: "Total",
          areaStyle: {},
          emphasis: {
            focus: "series",
          },
          data: [22, 18, 19, 23, 9, 3, 3],
        },
      ],
    };

    this.chartOption2 = {
      xAxis: {
        type: "category",
        data: ["2018", "2019", "2020", "2021", "2022"],
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: [
            50,
            {
              value: 50,
              itemStyle: {
                color: "#a90000",
              },
            },
            100,
            {
              value: 100,
              itemStyle: {
                color: "#EEF42C",
              },
            },
            250,
            {
              value: 250,
              itemStyle: {
                color: "#F5FD00",
              },
            },
            300,
            {
              value: 300,
              itemStyle: {
                color: "#F5FD00",
              },
            },
            50,
            {
              value: 50,
              itemStyle: {
                color: "#F5FD00",
              },
            },
          ],
          type: "bar",
        },
      ],
    };
  }
}
