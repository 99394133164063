import { StateOfferShipOwner } from "../enum/state-offer-shipowner.model";
import { UserType } from "../enum/user-type.model";

export class OfferShipOwnerState {
  offerShipOwnerId?: string;
  state?: StateOfferShipOwner;
  userType?: UserType;
  chargerContactNameToRecapConfirm?: string;
  shipOwnerContactNameToRecapConfirm?: string;

  constructor() {}
}
