import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, ReplaySubject } from "rxjs";
import { ConfigsService } from "@overa/shared";
import { Route } from "../models/charger/route.model";
import { CLHBaseService } from "./clh-base.service";

@Injectable({ providedIn: "root" })
export class RouteService extends CLHBaseService {
  addRoute(route: Route) {
    const endpointUrl = `${this.baseUrl}/route`;
    return this.http.post<Route>(endpointUrl, route);
  }
  deleteRoute(id: string) {
    const endpointUrl = `${this.baseUrl}/route/${id}`;
    return this.http.delete(endpointUrl);
  }
  editRoute(route: Route) {
    const endpointUrl = `${this.baseUrl}/route`;
    return this.http.put<Route>(endpointUrl, route);
  }
  getRoutesByChargerId(chargerId: string) {
    const endpointUrl = `${this.baseUrl}/route/getByCharger/${chargerId}`;
    return this.http.get<Route[]>(endpointUrl);
  }
}
