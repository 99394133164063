import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Shipowner } from "src/app/models/shipowner/shipowner.model";
import { ShipownerService } from "src/app/services/shipowner.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { StorageService } from "@overa/storage";
import { _MatTabGroupBase } from "@angular/material/tabs";
import { NgxSpinnerService } from "ngx-spinner";
import { CLHGlobals } from "src/app/utils/globals";
import { CatalogueType } from "src/app/models/enum/catalogue-type";
import { CatalogueService } from "src/app/services/catalogue.service";
import { ClhValidators } from "src/app/utils/validators";
import { ComponentCanDeactivate } from "src/app/services/canDeactivateGuard.service";
import { Observable } from "rxjs";
import { LanguageCode } from "src/app/models/enum/language-code.model";
import { Country } from "src/app/models/country/country.model";
import { Constants } from "../../../utils/constants";
import { PermissionsService } from "../../../services/permissions.service";
import { Location } from "@angular/common";
import { Utils } from "src/app/utils/utils";
import { OveraMessagesService } from "@overa/components/info-dialog";
import { NgxTranslateService } from "@overa/shared";

@Component({
  selector: "app-shipowner-form",
  templateUrl: "./shipowner-form.component.html",
  styleUrls: ["./shipowner-form.component.scss"],
  providers: [MessageService, ConfirmationService],
})
export class ShipownerFormComponent
  implements OnInit, OnDestroy, ComponentCanDeactivate {
  countries: string[] = ["Rusia", "Alemania", "Reino Unido", "España"];
  today: Date = new Date();
  valueVesselFrom: number;
  valueVesselTo: number;
  id: string;
  isNew: boolean = true;
  isUpdate: boolean;
  shipownerToForm: Shipowner;
  loaded = false;

  shipOwnerLogoFile = null;
  shipOwnerLogoPhoto = null;
  loadedPhoto = false;
  geoAreas: string[];
  selectedGeoAreas = [];
  hasUnsavedChanges: boolean = false;
  changesInLogo: boolean = false;
  initialGeoAreas: string[] = [];

  styleButtonYesConfirmDialog: string = "styleButtonYesConfirmDialog";
  styleButtonNoConfirmDialog: string = "styleButtonNoConfirmDialog";
  containerConfirmDialog: string = "containerConfirmDialog";
  showButtonAfterSave: boolean = true;
  noConfirmationServiceToExit: boolean = false;
  isVerify: boolean = false;
  form: FormGroup;
  localeLanguage: string;
  listCountries: Country[];
  listCountriesAll: Country[];
  selectedCountry: Country;
  onTheList: boolean = false;
  canViewPanels = false;
  readonly regexValidator = this.validators.myRegexValidator;
  readonly = false;
  isClh = false;
  itemUpdate: Shipowner;
  entityDefinition = {
    displayName: "Charger",
    entityDefinitionId: null,
    internalCode: null,
    description: null,
    isExtension: null,
    isAbstract: false,
    hasIdentityUser: null,
    hasGlobalAccessLevel: null,
    hasAccessLevel: null,
    entityAttributes: null,
    entityDefinitionRelations: null,
    accessLevel: null,
  };
  storageId: string = "";

  constructor(
    private translate: NgxTranslateService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private shipOwnerService: ShipownerService,
    public messagesService: OveraMessagesService,
    private confirmationService: ConfirmationService,
    private storageService: StorageService,
    private spinner: NgxSpinnerService,
    private globals: CLHGlobals,
    private catalogueService: CatalogueService,
    private validators: ClhValidators,
    private fb: FormBuilder,
    private location: Location,
    private permissionsService: PermissionsService,
    private utils: Utils
  ) { }

  ngOnInit() {
    this.storageId = this.globals.storageId;
    this.isClh = this.globals.isClh;
    this.loaded = false;
    this.canViewPanels = false;
    const rolesToViewTabs = [];
    if (!this.permissionsService.hasPermissionToEdit(this.activatedRoute)) {
      this.readonly = true;
    }
    if (this.globals.user.isGlobalAdmin) {
      this.canViewPanels = true;
    }
    if (this.globals.currentShipOwner) {
      const currentContact = this.globals.currentShipOwner.contacts.find(
        (x) => x.email == this.globals.user.email
      );
      if (!currentContact.isPrincipal) {
        this.readonly = true;
      }
    }

    if (
      this.globals.user.roles
        .map((x) => x.name)
        .some((r) => rolesToViewTabs.includes(r))
    ) {
      this.canViewPanels = true;
    }
    if (
      this.globals.user.roles
        .map((x) => x.name)
        .includes(this.globals.clhRoleName)
    ) {
      this.canViewPanels = true;
    }
    this.localeLanguage = this.translate.currentLang;
    if (this.localeLanguage) {
      if (this.localeLanguage == "es") {
        this.listCountriesAll = this.globals?.countriesES?.length
          ? this.globals.countriesES
          : [];
      }
      if (this.localeLanguage == "en") {
        this.listCountriesAll = this.globals?.countriesEN?.length
          ? this.globals.countriesEN
          : [];
      }
      this.listCountries = [...this.listCountriesAll];
    }
    this.noConfirmationServiceToExit = false;
    this.showButtonAfterSave = true;
    this.initialForm();
    this.resetForm();
    //this.form.get('logo').valueChanges.subscribe((file) => {
    //  if (file && file.files && file.files.length > 0) {
    //    this.shipOwnerLogoFile = file.files[0];
    //    this.changesInLogo = true;
    //  } else if (file === null) {
    //    this.shipOwnerLogoFile = null;
    //    this.shipOwnerLogoPhoto = null;
    //  }
    //});
    this.spinner.show();
    this.catalogueService.getGeoareas().subscribe({
      next: (geoareas) => {
        // console.log(geoareas);
        this.geoAreas = geoareas
          .map((x) => x.value)
          .sort((a, b) => a.localeCompare(b));
        this.globals.geoAreas = [...this.geoAreas];
        // console.log(this.geoAreas);

        this.activatedRoute.params.subscribe({
          next: (paramsId) => {
            console.log(paramsId?.["id"]);
            if (paramsId?.["id"] == "new") {
              this.isNew = true;
              this.loaded = true;

              this.shipownerToForm = new Shipowner();
              this.shipOwnerLogoFile = null;
              // this.shipOwnerLogoPhoto = null;
              this.shipOwnerLogoPhoto = Constants.NO_LOGO_SHEET_DATA;
              this.setForm();
              this.form.get("refShipOwner").disable();
              this.spinner.hide();
            } else {
              this.isNew = false;
              this.id = paramsId["id"];
              console.log(this.id);
              this.shipOwnerService.getById(this.id).subscribe({
                next: (data) => {
                  this.shipownerToForm = data;
                  this.loaded = true;
                  this.setForm();
                  this.form.get("refShipOwner").disable();
                  this.isUpdate = true;
                  if (data.logoId != null) {
                    try {
                      this.storageService
                        .getFileContent(data.logoId)
                        .subscribe((file) => {
                          this.shipOwnerLogoPhoto =
                            "data:image/jpeg;base64," + file.content;
                          this.loadedPhoto = true;
                        });
                    } catch (ex) {
                      console.log(ex);
                    }
                  } else {
                    this.shipOwnerLogoFile = null;
                    // this.shipOwnerLogoPhoto = null;
                    this.shipOwnerLogoPhoto = Constants.NO_LOGO_SHEET_DATA;
                  }
                  this.shipownerToForm.logoId = data.logoId;
                  this.isVerify = this.shipownerToForm.isBanned;
                  this.spinner.hide();
                },
                error: (err) => {
                  console.error(err);
                  this.spinner.hide();
                },
              });
            }
          },
          error: (err) => {
            console.error(err);
            this.spinner.hide();
          },
        });
      },
      error: (err) => {
        console.error(err);
        this.spinner.hide();
      },
    });

    this.spinner.hide();
  }

  initialForm() {
    this.form = this.fb.group(
      {
        refShipOwner: new FormControl("", [Validators.required]),
        charteringCompany: new FormControl("", [Validators.required]),
        companyIMONumber: new FormControl("", [Validators.required]),
        dischargeDate: new FormControl(new Date()),
        account: new FormControl("", [this.regexValidator("account")]),
        web: new FormControl("", [
          Validators.required,
          this.regexValidator("web"),
        ]),
        address: new FormControl("", [Validators.required]),
        // contacts: new FormControl('', [Validators.required]),
        country: new FormControl("", [Validators.required]),
        geoAreas: new FormControl([], []),
        vesselRangeFrom: new FormControl(0, [Validators.required]),
        vesselRangeTo: new FormControl(0, [Validators.required]),
        cifVat: new FormControl("", [Validators.required]),
        postalCode: new FormControl("", [Validators.required]),
        province: new FormControl("", [Validators.required]),
        logo: new FormControl(""),
        town: new FormControl("", [Validators.required]),
        genericEmail: new FormControl(""),
        alias: new FormControl(""),
      },
      {
        validators: [
          this.validators.checkGreatherThan("vesselRangeTo", "vesselRangeFrom"),
        ],
      }
    );
  }

  setForm() {
    this.selectedGeoAreas = this.geoAreas.filter((x) =>
      this.shipownerToForm.geoAreas.includes(x)
    );

    this.initialGeoAreas = this.shipownerToForm.geoAreas;
    // this.geoAreas=this.globals.geoAreas.filter(
    //   (x)=> !this.shipownerToForm.geoAreas.includes(x)
    // );
    // this.geoAreas = this.globals.catalogue
    //   .filter(
    //     (x) =>
    //       x.type == CatalogueType.GEO &&
    //       !this.shipownerToForm.geoAreas.includes(x.value)
    //   )
    //   .map((x) => x.value);
    if (this.shipownerToForm?.country) {
      const countryLanguage = this.shipownerToForm?.country;
      if (this.translate.currentLang == "en") {
        countryLanguage.name = countryLanguage.nameEnglish;
      }
    }
    this.onTheList = this.shipownerToForm?.country ? true : false;
    console.log(this.onTheList);
    this.form.setValue({
      refShipOwner: this.shipownerToForm.refShipOwner || "",
      charteringCompany: this.shipownerToForm.charteringCompany || "",
      companyIMONumber: this.shipownerToForm.companyIMONumber || "",
      dischargeDate: new Date(this.shipownerToForm.dischargeDate) || this.today,
      account: this.shipownerToForm.account || "",
      web: this.shipownerToForm.web || "",
      vesselRangeFrom: this.shipownerToForm.vesselRangeFrom || 0,
      vesselRangeTo: this.shipownerToForm.vesselRangeTo || 0,
      address: this.shipownerToForm.address || "",
      cifVat: this.shipownerToForm.cifVat || "",
      postalCode: this.shipownerToForm.postalCode || "",
      province: this.shipownerToForm.province || "",
      logo: "logo.png",
      country: this.shipownerToForm.country || "",
      geoAreas: this.shipownerToForm.geoAreas || [],
      town: this.shipownerToForm.town || "",
      genericEmail: this.shipownerToForm.genericEmail || "",
      alias: this.shipownerToForm.alias || "",
    });
  }

  resetForm() {
    this.form.reset();
    if (this.readonly) {
      this.form.disable();
    }
  }
  setShipOwner() {
    //let address = this.form.get('address').value + ',' + this.form.get('province').value + ',' + this.form.get('codePostal').value;
    //console.log(address);
    //this.form.get("address").setValue(address);
    //TODO FIX IN UILIB
    const areas = [...this.shipownerToForm.geoAreas];
    this.shipownerToForm = { ...this.shipownerToForm, ...this.form.value };
    // this.shipownerToForm.address = address;
    this.shipownerToForm.geoAreas = areas;
    this.shipownerToForm.countryId = this.form.get("country").value.id;
    this.shipownerToForm.country = null;
    delete this.shipownerToForm.logo;
    console.log(this.shipownerToForm);
  }

  cancel() {
    //this.shipownerToForm = null;
    this.location.back();
  }

  editShipOwner() {
    this.shipOwnerService.edit(this.shipownerToForm).subscribe({
      next: (shipownerResult) => {
        this.spinner.hide();
        console.log(shipownerResult);
        this.messagesService.showToast(
          this.translate.get("MESSAGES.SUCCESS.UPDATE"),
          this.translate.get("MESSAGES.SUCCESS.UPDATE_LONG", {
            type: this.translate.get("SHIPOWNER_DATA_SHEET.SHIPOWNE"),
            code: this.shipownerToForm.charteringCompany,
          }),
          "success",
          ""
        );
        this.noConfirmationServiceToExit = true;
        const currentUrl = "/shipowners/" + shipownerResult.id;
        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => {
            this.router.navigate([currentUrl]);
          });
      },
      error: (err) => {
        this.spinner.hide();
        console.error(err);
        this.showButtonAfterSave = true;
        // this.messagesService.showToast(
        //   'ERROR',
        //   this.translate.get(err),
        //   "error",
        //   ""
        // );
      },
    });
  }

  addShipOwner() {
    this.shipOwnerService.add(this.shipownerToForm).subscribe({
      next: (shipOwnerResult) => {
        this.spinner.hide();
        this.messagesService.showToast(
          this.translate.get("MESSAGES.SUCCESS.CREATE"),
          this.translate.get("MESSAGES.SUCCESS.CREATE_LONG", {
            type: this.translate.get("SHIPOWNER_DATA.SHIPOWNER_FORM.SHIPOWNER"),
            code: shipOwnerResult.charteringCompany,
          }),
          "success",
          ""
        );
        this.noConfirmationServiceToExit = true;
        const currentUrl = "/shipowners/" + shipOwnerResult.id;
        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => {
            this.router.navigate([currentUrl]);
          });
        // this.router.navigateByUrl("/shipowners/" + shipOwnerResult.id, {skipLocationChange: true});
      },
      error: (err) => {
        this.spinner.hide();
        console.error(err);
        this.showButtonAfterSave = true;
        // this.messagesService.showToast(
        //   'ERROR',
        //   this.translate.get(err),
        //   "error",
        //   ""
        // );
      },
    });
  }
  verifyShipowner() {
    if (this.form.valid) {
      const trmessage = this.translate.get("COMMONS.ASK_VERIFY");
      const messageAccept = this.translate.get("ACTION.YES");

      this.confirmationService.confirm({
        message: trmessage,
        header: "",
        acceptLabel: messageAccept,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.shipownerToForm.isBanned = true;
          this.showButtonAfterSave = false;
          this.updateShipowner();
        },
        reject: () => { },
      });
    }
  }

  /********** CRUD ***************/
  createShipowner() {
    this.checkCountryId();
    this.updateItemUpdate(() => {
      let errorSameMailContacts = false;
      const { errorMail, errorDomain } =
        this.getErrorInMailAndErrorCheckDomain();
      const { errorAlias, messageError } = this.getErrorAlias();
      errorSameMailContacts = this.checkSameMailContacts();

      if (!errorMail && !errorDomain && !errorSameMailContacts) {
        if (!errorAlias) {
          if (this.form.valid && this.onTheList) {
            this.spinner.show();
            this.showButtonAfterSave = false;
            this.setShipOwner();

            if (this.shipOwnerLogoFile != null) {
              this.storageService
                .uploadFile(this.shipOwnerLogoFile, this.storageId)
                .subscribe({
                  next: (uploadFileRes) => {
                    this.shipownerToForm.logoId = uploadFileRes.id;
                    this.addShipOwner();
                  },
                  error: (err) => {
                    this.spinner.hide();
                    console.error(err);
                    this.showButtonAfterSave = true;
                  },
                });
            } else {
              this.addShipOwner();
            }
          } else {
            if (!this.onTheList) {
              let message = "";
              this.translate
                .get("MESSAGES.ERROR.COUNTRY")
                .subscribe((a: string) => {
                  message = a;
                });
              this.messagesService.showToast("ERROR", message, "error", "");
            }
          }
        } else {
          this.messagesService.showToast("ERROR", messageError, "error", "");
        }
      } else {
        const message = [];
        let messageTrans = "";
        if (errorDomain) {
          message.push("MESSAGES.VALIDATION.MAIL_DOMAIN");
        }
        if (errorMail) {
          message.push("MESSAGES.VALIDATION.MAIL");
        }
        if (errorSameMailContacts) {
          message.push("MESSAGES.VALIDATION.CHECK_SAME_MAIL_CONTACTS");
        }
        if (message.length > 0) {
          message.forEach((x) => {
            messageTrans = this.translate.get(x);
            this.messagesService.showToast("ERROR", messageTrans, "error", "");
          });
        }
      }
    });
  }

  updateShipowner() {
    this.checkCountryId();
    this.updateItemUpdate(() => {
      const { errorMail, errorDomain } =
        this.getErrorInMailAndErrorCheckDomain();
      const { errorAlias, messageError } = this.getErrorAlias();
      let errorSameMailContacts = false;
      errorSameMailContacts = this.checkSameMailContacts();
      if (!errorMail && !errorDomain && !errorSameMailContacts) {
        if (!errorAlias) {
          if (this.form.valid && this.onTheList) {
            this.spinner.show();
            this.showButtonAfterSave = false;
            this.setShipOwner();
            if (this.shipownerToForm.logoId != null) {
              if (this.shipOwnerLogoFile != null) {
                //tenía y se ha sustituido
                this.storageService
                  .deleteFile(this.shipownerToForm.logoId)
                  .subscribe({
                    next: (deleteFileRes) => {
                      this.storageService
                        .uploadFile(this.shipOwnerLogoFile, this.storageId)
                        .subscribe({
                          next: (uploadFileRes) => {
                            this.shipownerToForm.logoId = uploadFileRes.id;
                            this.editShipOwner();
                          },
                          error: (err) => {
                            this.spinner.hide();
                            console.error(err);
                            this.showButtonAfterSave = true;
                          },
                        });
                    },
                    error: (err) => {
                      this.spinner.hide();
                      console.error(err);
                      this.showButtonAfterSave = true;
                    },
                  });
              } else {
                this.editShipOwner();
                //tenía y se le ha quitado
                //this.storageService.deleteFile(this.shipownerToForm.logoId).subscribe({
                //    next: (deleteFileRes) => {
                //        this.shipownerToForm.logoId = null;
                //        this.editShipOwner();
                //    },
                //    error: (err) => {
                //        this.spinner.hide();
                //        console.error(err);
                //        this.showButtonAfterSave = true;
                //    },
                //});
              }
            } else {
              if (this.shipOwnerLogoFile != null) {
                //no tenía y se le añade
                this.storageService
                  .uploadFile(this.shipOwnerLogoFile, this.storageId)
                  .subscribe({
                    next: (uploadFileRes) => {
                      this.shipownerToForm.logoId = uploadFileRes.id;
                      this.editShipOwner();
                    },
                    error: (err) => {
                      this.spinner.hide();
                      console.error(err);
                      this.showButtonAfterSave = true;
                    },
                  });
              } else {
                //no tenía y se queda sin tener
                this.editShipOwner();
              }
            }
          } else {
            if (!this.onTheList) {
              this.messagesService.showToast(
                "ERROR",
                "MESSAGES.ERROR.COUNTRY",
                "error",
                ""
              );
            }
          }
        } else {
          this.messagesService.showToast("ERROR", messageError, "error", "");
        }
      } else {
        const message = [];
        let messageTrans = "";
        if (errorDomain) {
          message.push("MESSAGES.VALIDATION.MAIL_DOMAIN");
        }
        if (errorMail) {
          message.push("MESSAGES.VALIDATION.MAIL");
        }
        if (errorSameMailContacts) {
          message.push("MESSAGES.VALIDATION.CHECK_SAME_MAIL_CONTACTS");
        }
        if (message.length > 0) {
          message.forEach((x) => {
            messageTrans = this.translate.get(x);
            this.messagesService.showToast("ERROR", messageTrans, "error", "");
          });
        }
      }
    });
  }

  deleteShipowner() {
    this.showButtonAfterSave = false;
    const trmessage = this.translate.get("COMMONS.ASK_DELETE_RECORD");
    const messageAccept = this.translate.get("ACTION.YES");

    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.spinner.show();
        this.shipOwnerService.delete(this.id).subscribe({
          next: (data) => {
            // this.showButtonAfterSave = true;
            this.spinner.hide();
            this.router.navigate(["shipowners"]);
            this.messagesService.showToast(
              this.translate.get("MESSAGES.SUCCESS.DELETE"),
              this.translate.get("MESSAGES.SUCCESS.DELETE_LONG", {
                type: this.translate.get(
                  "SHIPOWNER_DATA.SHIPOWNER_FORM.SHIPOWNER"
                ),
                code: this.shipownerToForm.charteringCompany,
              }),
              "success",
              ""
            );
          },
          error: (err) => {
            this.spinner.hide();
            console.error(err);
            this.showButtonAfterSave = true;
          },
        });
      },
      reject: () => {
        this.showButtonAfterSave = true;
      },
    });
  }
  selectedGeoAreasChange(event) {
    this.shipownerToForm.geoAreas = event;
  }

  ngOnDestroy() { }
  checkToSaveToExit() {
    if (!this.noConfirmationServiceToExit) {
      Object.keys(this.form.controls).forEach((ctrl) => {
        if (this.form.get(ctrl).value) {
          if (ctrl === "dischargeDate") {
            if (
              this.form.get(ctrl).value >
              new Date(this.shipownerToForm[ctrl]) ||
              this.form.get(ctrl).value < new Date(this.shipownerToForm[ctrl])
            ) {
              this.hasUnsavedChanges = true;
            }
            //} else if (ctrl === 'address') {
            //    if (this.form.get(ctrl).value != arrayAddress[0]) {
            //        this.hasUnsavedChanges = true;
            //    }
            //} else if (ctrl === 'codePostal') {
            //    if (this.form.get(ctrl).value != arrayAddress[2]) {
            //        this.hasUnsavedChanges = true;
            //    }
            //} else if (ctrl === 'province') {
            //    if (this.form.get(ctrl).value != arrayAddress[1]) {
            //        this.hasUnsavedChanges = true;
            //    }
          } else if (ctrl === "country") {
            let name = this.form.get(ctrl).value;
            if (name?.id) {
              name = name.name.toLowerCase();
            } else {
              name = name.toLowerCase().trim();
            }
            if (name != this.shipownerToForm[ctrl]?.name?.toLowerCase()) {
              this.hasUnsavedChanges = true;
            }
          } else if (ctrl === "logo") {
            if (this.changesInLogo) {
              this.hasUnsavedChanges = true;
            }
          } else if (ctrl === "geoAreas") {
            const value = this.form.get(ctrl).value;
            if (Array.isArray(value)) {
              if (
                JSON.stringify([...this.initialGeoAreas].sort()) !=
                JSON.stringify([...value].sort())
              ) {
                this.hasUnsavedChanges = true;
              }
            }
          } else {
            if (this.form.get(ctrl).value != this.shipownerToForm[ctrl]) {
              this.hasUnsavedChanges = true;
            }
          }
        }
      });
    } else {
      this.hasUnsavedChanges = false;
    }
  }
  checkCanDeactive(): boolean | Observable<boolean> {
    this.checkToSaveToExit();

    if (this.hasUnsavedChanges) {
      const messageAccept = this.translate.get("ACTION.YES");
      const trmessage = this.translate.get("COMMONS.ASK_SAVE_TO_EXIT");

      return new Observable((observer) => {
        this.confirmationService.confirm({
          message: trmessage,
          header: "",
          acceptLabel: messageAccept,
          icon: "pi pi-exclamation-triangle",
          accept: () => {
            observer.next(true);
            observer.complete();
          },
          reject: () => {
            observer.next(false);
            observer.complete();
          },
        });
      });
    } else {
      return true;
    }
  }
  onFileSelected(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    this.shipOwnerLogoFile = file;
    reader.onload = () => {
      const dataImage = reader.result;
      this.shipOwnerLogoPhoto = dataImage;
      this.loadedPhoto = true;
    };
  }
  filterlistCountries(event) {
    // console.log(event);
    this.onTheList = false;
    const filtered: any[] = [];
    const query = event.query;
    // console.log(this.listCountries);
    this.listCountries = [...this.listCountriesAll];
    for (const country of this.listCountries) {
      if (
        country.name
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .indexOf(query.toLowerCase()) == 0
      ) {
        filtered.push(country);
      }
    }
    this.listCountries = filtered;
  }
  onCountrySelect(event) {
    // console.log(event);
    // console.log(this.form.get('country').value);
    this.onTheList = true;
  }
  checkCountryId() {
    let name = this.form.get("country").value;
    if (name?.id) {
      name = name.name.toLowerCase();
    } else {
      name = name.toLowerCase().trim();
    }
    if (!this.onTheList) {
      if (!this.form.get("country").value.id) {
        this.listCountriesAll.forEach((country) => {
          if (country.name.toLowerCase() === name) {
            this.form.get("country").setValue(country);
            this.onTheList = true;
          }
        });
      }
    }
  }
  checkDomain(testEmail: string) {
    let domain = "";
    const domainTestEmail = this.utils.getEmailDomain(testEmail);
    const domainContactPrincipal = this.getDomainPrincipal();
    const item =
      this.itemUpdate != null ? this.itemUpdate : this.shipownerToForm;
    if (item != null && domainContactPrincipal === "") {
      if (item.genericEmail != "" && item.genericEmail != null) {
        domain = this.utils.getEmailDomain(this.shipownerToForm.genericEmail);
      }
    } else {
      if (domainContactPrincipal != "" && domainContactPrincipal != null) {
        domain = domainContactPrincipal;
      }
    }

    if (domain != "") {
      return domain !== domainTestEmail;
    } else {
      return false;
    }
  }
  getDomainPrincipal(): string {
    let domainPrincipal = "";
    let emailPrincipal = "";
    let thereIsMainContact: boolean = false;
    const item =
      this.itemUpdate != null ? this.itemUpdate : this.shipownerToForm;
    if (item != null) {
      if (item.contacts.length > 0) {
        thereIsMainContact = item.contacts.some((x) => x.isPrincipal);
        if (thereIsMainContact)
          emailPrincipal =
            item.contacts.find((x) => x.isPrincipal)?.email ?? "";
      }
      if (emailPrincipal != "")
        domainPrincipal = this.utils.getEmailDomain(emailPrincipal);
    }

    return domainPrincipal;
  }
  getErrorInMailAndErrorCheckDomain(): {
    errorMail: boolean;
    errorDomain: boolean;
  } {
    let errorMail = false;
    let errorDomain = false;
    const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const testEmailPrincipal = this.form.get("genericEmail").value;
    if (testEmailPrincipal != "") {
      errorMail = !regexEmail.test(testEmailPrincipal);
      errorDomain = this.checkDomain(testEmailPrincipal);
    }
    return { errorMail, errorDomain };
  }
  getErrorAlias(): { errorAlias: boolean; messageError: string } {
    let errorAlias = false;
    let messageError = "";
    if (this.form.get("genericEmail").value != "") {
      if (this.form.get("alias").value === "") {
        errorAlias = true;
        messageError = this.translate.get("MESSAGES.VALIDATION.NEED_NEED_ALIAS")
      }
    }

    return { errorAlias, messageError };
  }

  updateItemUpdate(callback: () => void): void {
    this.itemUpdate = null;

    this.shipOwnerService.getById(this.id).subscribe({
      next: (value) => {
        if (value) {
          this.itemUpdate = value;
        }
        callback();
      },
      error: (err) => {
        console.log("errorUpdateItemContact", err);
        callback();
      },
    });
  }
  checkSameMailContacts(): boolean {
    const testMail = this.form.get("genericEmail").value;
    let sameMailContacts = false;
    const item =
      this.itemUpdate != null ? this.itemUpdate : this.shipownerToForm;
    if (item !== null) {
      if (item.contacts.length > 0) {
        return (sameMailContacts = item.contacts.some(
          (contact) => contact.email === testMail
        ));
      } else {
        return sameMailContacts;
      }
    } else {
      return sameMailContacts;
    }
  }
}
