<ngx-spinner></ngx-spinner>
<p-toast></p-toast>
<p-confirmDialog
  styleClass="containerConfirmDialog"
  acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"
></p-confirmDialog>
<div class="offerResponse main offerReceived" *ngIf="form" [formGroup]="form">
  <!-- <ng-container *ngIf="!expirationOffer else showInfoExpiration "> -->
  <ng-container *ngIf="!expirationOffer">
    <ng-container *ngIf="offer && expirationDate && !isReadOnlyResponse">
      <div class="d-flex justify-content-end divMargin">
        <h4>
          <i class="pi pi-history" style="font-size: 2rem"></i><strong> {{'BINDING_OFFER.DEADLINE_EXPIRES' | translate}} </strong> {{expirationDate |
          date: 'dd/MM/yy HH:mm' }}
        </h4>
      </div>
    </ng-container>
    <ng-container>
      <div class="row">
        <!-- TABLE -->
        <div class="p-3 col-12 col-sm-12 col-md-12 col-xl-8">
          <p-table
            [value]="offers"
            responsiveLayout="stack"
            [breakpoint]="'960px'"
            styleClass="p-datatable-striped  tableofferBinding"
            [responsive]="true"
            [autoLayout]="true"
            [columns]="columns"
            [tableStyle]="{'min-width': '50rem'}"
            *ngIf="showTable"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" class="styleTextTable thOfferResponse">
                  <ng-container *ngIf="col.colName === 'STEP1_OFFER.TABLE.HEAD.HEAD_1'"> {{col.colName | translate}} </ng-container>
                  <ng-container *ngIf="col.colName === 'STEP1_OFFER.TABLE.HEAD.HEAD_2'"> {{col.colName | translate}} </ng-container>
                  <ng-container *ngIf="col.colName === ''"> {{offer.refOffer}} </ng-container>
                  <ng-container *ngIf="col.colName === 'RESPONSE_SHIP_OFFER.TABLE.HEAD.HEAD_1'"
                    ><div class="row">
                      <div class="col text-left" *ngIf="nameShipOwner!=null">{{col.colName | translate}} : {{offer.nameShipOwner}}</div>
                      <!--<div class="col text-end mr-0" *ngIf="!isReadOnlyResponse"><i class="pi pi-paperclip"></i></div>-->
                    </div></ng-container
                  >
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-offer>
              <tr *ngFor="let title of columnsTitle;let index = index">
                <ng-container *ngFor="let col of columns">
                  <!-- ALL ROWS EXCEPT ADDRESCOM -->
                  <ng-container *ngIf="title.control !== 'addressComm' else templateA">
                    <td *ngIf="col.colName==='STEP1_OFFER.TABLE.HEAD.HEAD_1' && isScreenLarge" class="styleTextTable">{{index +1}}</td>
                    <td *ngIf="col.colName==='STEP1_OFFER.TABLE.HEAD.HEAD_1' && !isScreenLarge && title.control !== 'quantity'" class="styleTextTable">
                      {{index +1}} -
                      <p
                        [escape]="false"
                        [pTooltip]="title.tooltip  ? (title.tooltip | translate) : '' "
                        tooltipPosition="bottom"
                        tooltipStyleClass="tooltipCLH"
                        [showDelay]="1000"
                        [hideDelay]="1000"
                      >
                        {{title.field | translate}}
                      </p>
                    </td>
                    <td
                      *ngIf="col.colName==='STEP1_OFFER.TABLE.HEAD.HEAD_1' && !isScreenLarge && title.control === 'quantity'"
                      class="styleTextTable"
                    >
                      {{index +1}} -
                      <p
                        [escape]="false"
                        [pTooltip]="title.tooltip  ? (title.tooltip | translate) : '' "
                        tooltipPosition="bottom"
                        tooltipStyleClass="tooltipCLH"
                        [showDelay]="1000"
                        [hideDelay]="1000"
                      >
                        {{title.moreFields.quantity | translate}} / {{title.moreFields.stowage | translate}} / {{title.moreFields.commodity |
                        translate}}
                      </p>
                    </td>
                    <td *ngIf="col.colName==='STEP1_OFFER.TABLE.HEAD.HEAD_2' && isScreenLarge" class="styleTextTable">
                      <ng-container *ngIf="title.control !== 'quantity' else templateQuantity"
                        ><p
                          [escape]="false"
                          [pTooltip]="title.tooltip  ? (title.tooltip | translate) : '' "
                          tooltipPosition="bottom"
                          tooltipStyleClass="tooltipCLH"
                          [showDelay]="1000"
                          [hideDelay]="1000"
                        >
                          {{title.field | translate}}
                        </p>
                      </ng-container>
                      <ng-template #templateQuantity>
                        {{title.moreFields.quantity | translate}} / {{title.moreFields.stowage | translate}} / {{title.moreFields.commodity |
                        translate}}</ng-template
                      >
                    </td>
                    <td *ngIf="col.colName===''" class="styleTextTable">
                      <label
                        *ngIf="title.control !== 'dischargingPorts' && title.control !== 'loadingPorts' &&  title.control !== 'rangeDates' && title.control !== 'flete' && title.control !== 'quantity'"
                      >
                        <span *ngIf="!isScreenLarge" class="icon-response-charterer"> <img src="assets/icons/cargador.svg" width="20" />: </span>
                        <!--<i class="pi pi-user" *ngIf="!isScreenLarge"> : </i>-->
                        {{offer[title.label]}}</label
                      >
                      <label *ngIf="title.control === 'dischargingPorts' || title.control === 'loadingPorts'">
                        <span *ngIf="!isScreenLarge" class="icon-response-charterer"> <img src="assets/icons/cargador.svg" width="20" />: </span>
                        {{offer[title.label].join(',')}}
                      </label>
                      <label *ngIf="title.control === 'rangeDates'">
                        <span *ngIf="!isScreenLarge" class="icon-response-charterer"> <img src="assets/icons/cargador.svg" width="20" />: </span>
                        {{offer[title.label.fieldFrom] | date: 'dd/MM/yy'}} - {{offer[title.label.fieldTo] | date: 'dd/MM/yy'}}
                      </label>
                      <label *ngIf="title.control === 'flete' && offer[title.moreLabels.quantity] > 0">
                        <span *ngIf="!isScreenLarge" class="icon-response-charterer"> <img src="assets/icons/cargador.svg" width="20" />: </span>
                        {{FreightTargetCurrency[offer[title.moreLabels.currency]]}} {{offer[title.moreLabels.quantity] | number: '1.2-2'}} -
                        {{FreightTargetType[offer[title.moreLabels.type]]}}
                      </label>
                      <label *ngIf="title.control === 'quantity'">
                        <span *ngIf="!isScreenLarge" class="icon-response-charterer"> <img src="assets/icons/cargador.svg" width="20" />: </span>
                        {{offer[title.moreLabels.quantity] | number: '1.0-0' }} {{QuantityType[offer[title.moreLabels.quantityType]]}} /
                        {{offer[title.moreLabels.stowage]}} / {{offer[title.moreLabels.commodity]}}
                      </label>
                    </td>
                    <td *ngIf="col.colName==='RESPONSE_SHIP_OFFER.TABLE.HEAD.HEAD_1'" class="styleTextTable">
                      <!-- ROWS IF NOT READONLY -->
                      <ng-container *ngIf="!isReadOnlyResponse else isReadonlyTable">
                        <!-- ROWS ONLY WITH INPUT OR TEXTAREA  -->
                        <ng-container
                          *ngIf="( title.control !== 'dischargingPorts' && title.control !== 'loadingPorts' &&  title.control !== 'rangeDates' && title.control !== 'flete' &&  title.control !== 'demurrage' ) else templateFieldWithMoreInputs"
                        >
                          <ng-container *ngIf="title.control === 'chargerName' || title.control === 'quantity' "><label></label> </ng-container>
                          <ng-container
                            *ngIf="title.control !== 'aditionalRequirements' && title.control !== 'restrictionsRegarding' && title.control !== 'chargerName' && title.control !== 'quantity'"
                          >
                            <div class="d-flex flex-column justify-content-start">
                              <div class="d-flex justify-content-start">
                                <input
                                  type="text"
                                  formControlName="{{title.control}}"
                                  pInputText
                                  size="100"
                                  placeholder="{{title?.placeholder | translate}}"
                                  class="w-100"
                                />
                              </div>
                              <div
                                *ngIf="form.get(title.control).touched  && !form.get(title.control).value  && form.get(title.control).hasError('required')"
                                class="error-label labelError"
                              >
                                {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                              </div>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="title.control ==='aditionalRequirements' || title.control ==='restrictionsRegarding' ">
                            <clh-textarea
                              [form]="form"
                              [nameControl]="title.control"
                              [style]="'width:100%;'"
                              [errorLabel]="''"
                              [placeholder]="title.placeholder | translate"
                              [label]="title.labelTextarea"
                              [idTextarea]="title.idTextarea"
                              [classTextarea]="title.classTextarea"
                              [required]="title.required"
                              [showLabelFloat]="title.showLabelFloat"
                            ></clh-textarea>
                          </ng-container>
                        </ng-container>
                        <!-- END ROWS ONLY WITH INPUT OR TEXTAREA  -->
                        <!-- ROWS WITH DIFFERENT CONDITIONS  -->
                        <ng-template #templateFieldWithMoreInputs>
                          <ng-container *ngIf="title.control === 'dischargingPorts'">
                            <div class="d-flex flex-column justify-content-start">
                              <div class="d-flex justify-content-start">
                                <overa-autocomplete
                                [formGroup]="form"
                                [controlName]="title.control"
                                [options]="portsD"
                                [selectedItems]="selectedDischargingPorts"
                                [filterStartIndex]="3"
                                [multiple]="true"
                                (selectedItemsChange)="selectedDischargingPortChange($event)"
                                [validators]="[arrValidator]"
                                [required]="title.required"
                                [readonly]="false"
        
                              ></overa-autocomplete>
                                <!-- <overa-autocomplete
                                  [form]="form"
                                  *ngIf="ports && offer"
                                  [readonly]="false"
                                  (onSelectionChange)="selectedDischargingPortChange($event)"
                                  [name]="title.control"
                                  style="width: 100%"
                                  [required]="title.required"
                                  [items]="portsD"
                                  [selectedItems]="selectedDischargingPorts"
                                  [multiple]="true"
                                  [filterStartIndex]="3"
                                  [validators]="[arrValidator]"
                                >
                                </overa-autocomplete> -->
                              </div>
                              <div
                                *ngIf="form.get(title.control).touched  && form.get(title.control).hasError('required')"
                                class="error-label labelError"
                              >
                                {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                              </div>
                              <div
                                *ngIf="form.get(title.control).touched && form.get(title.control).hasError('notInList')"
                                class="error-label labelError"
                              >
                                {{'MESSAGES.VALIDATION.PORTS_INVALID' | translate}}
                              </div>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="title.control === 'loadingPorts'">
                            <div class="d-flex flex-column justify-content-start">
                              <div class="d-flex justify-content-start">
                                <overa-autocomplete
                                [formGroup]="form"
                                [controlName]="title.control"
                                [options]="ports"
                                [selectedItems]="selectedLoadingPorts"
                                [filterStartIndex]="3"
                                [multiple]="true"
                                (selectedItemsChange)="selectedLoadingPortChange($event)"
                                [validators]="[arrValidator]"
                                [required]="title.required"
                                [readonly]="false"
        
                              ></overa-autocomplete>
                                <!-- <overa-autocomplete
                                  [form]="form"
                                  *ngIf="ports && offer"
                                  [readonly]="false"
                                  [name]="title.control"
                                  style="width: 100%"
                                  [required]="title.required"
                                  [items]="ports"
                                  [selectedItems]="selectedLoadingPorts"
                                  (onSelectionChange)="selectedLoadingPortChange($event)"
                                  [multiple]="true"
                                  [filterStartIndex]="3"
                                  [validators]="[arrValidator]"
                                >
                                </overa-autocomplete> -->
                              </div>
                              <div
                                *ngIf="form.get(title.control).touched  && form.get(title.control).hasError('required')"
                                class="error-label labelError"
                              >
                                {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                              </div>
                              <div
                                *ngIf="form.get(title.control).touched && form.get(title.control).hasError('notInList')"
                                class="error-label labelError"
                              >
                                {{'MESSAGES.VALIDATION.PORTS_INVALID' | translate}}
                              </div>
                            </div>
                          </ng-container>
                          <!--END ROW PORTS -->
                          <!-- ROW FLETE -->
                          <ng-container *ngIf="title.control === 'flete'">
                            <div class="d-flex flex-column justify-content-start">
                              <div class="d-flex flex-wrap flex-row justify-content-start align-content-center divFlete">
                                <p-dropdown
                                  class="dropdownCoin"
                                  [options]="coins"
                                  formControlName="{{title.moreControls.currency}}"
                                  appendTo="body"
                                  (ngModelChange)="changeFreightTargetCurrency($event)"
                                ></p-dropdown>
                                <p-inputNumber
                                  class="freightTarget"
                                  styleClass="inputFreightTargetQuantity"
                                  inputStyleClass="inputFreightTargetQuantityResponse"
                                  formControlName="{{title.moreControls.quantity}}"
                                  mode="decimal"
                                  [minFractionDigits]="2"
                                  [maxFractionDigits]="2"
                                  locale="de-DE"
                                ></p-inputNumber>
                                <p-dropdown class="dropdownFlete" [options]="fletes" formControlName="{{title.moreControls.type}}" appendTo="body">
                                </p-dropdown>
                              </div>
                              <div
                                *ngIf="form.get(title.moreControls.quantity).touched  && !form.get(title.moreControls.quantity).value  && form.get(title.moreControls.quantity).hasError('required')"
                                class="error-label labelError"
                              >
                                {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                              </div>
                            </div>
                          </ng-container>
                          <!--END ROW FLETE -->
                          <!-- ROW DEMURRAGE -->
                          <ng-container *ngIf="title.control === 'demurrage'">
                            <div class="d-flex flex-column justify-content-start">
                              <div class="d-flex flex-wrap flex-row justify-content-start divDemurrage">
                                <p-dropdown
                                  class="dropdownCoin"
                                  [options]="coins"
                                  formControlName="{{title.moreControls.currency}}"
                                  appendTo="body"
                                ></p-dropdown>
                                <p-inputNumber
                                  inputId="demurrageQuantity"
                                  styleClass="inputdemurrageQuantity"
                                  inputStyleClass="inputdemurrageQuantityResponse"
                                  formControlName="{{title.moreControls.quantity}}"
                                  mode="decimal"
                                  [minFractionDigits]="2"
                                  [maxFractionDigits]="2"
                                  locale="de-DE"
                                ></p-inputNumber>
                                <p-dropdown
                                  class="dropdownDemurrageFlete"
                                  [options]="demurrageTypes"
                                  formControlName="{{title.moreControls.type}}"
                                  appendTo="body"
                                >
                                </p-dropdown>
                              </div>
                              <div
                                *ngIf="form.get(title.moreControls.quantity).touched  && !form.get(title.moreControls.quantity).value  && form.get(title.moreControls.quantity).hasError('required')"
                                class="error-label labelError"
                              >
                                {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                              </div>
                              <div *ngIf="form.errors?.['isMatch']" class="error-label labelError">
                                {{'MESSAGES.VALIDATION.MATCH_DEMURRAGE' | translate}}
                              </div>
                            </div>
                          </ng-container>
                          <!--END ROW DEMURRAGE -->
                          <!--ROW RANGEDATES -->
                          <ng-container *ngIf="title.control === 'rangeDates'">
                            <div class="d-flex justify-content-start">
                              <p-calendar
                                dateFormat="dd/mm/yy"
                                formControlName="{{title.control}}"
                                selectionMode="range"
                                [utc]="true"
                                inputId="range"
                                styleClass="calendarTableOffer"
                              ></p-calendar>
                            </div>
                            <div class="d-flex justify-content-start" *ngIf="!isReadOnlyResponse">
                              <div
                                *ngIf="form.get(title.control).touched  && form.get(title.control).hasError('required')"
                                class="error-label labelError"
                              >
                                {{'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate}}
                              </div>
                              <div *ngIf="form.get(title.control).hasError('errorDates') " class="error-label labelError">
                                {{'MESSAGES.VALIDATION.RANGE_DATES' | translate}}
                              </div>
                            </div>
                          </ng-container>
                          <!--END ROW RANGEDATES -->
                        </ng-template>
                        <!-- END ROWS WITH DIFFERENT CONDITIONS  -->
                      </ng-container>
                      <!-- END ROWS IF NOT READONLY -->
                      <!-- ROWS IF READONLY -->
                      <ng-template #isReadonlyTable>
                        <label
                          *ngIf="title.control !== 'dischargingPorts' && title.control !== 'loadingPorts' &&  title.control !== 'rangeDates' && title.control !== 'flete' && title.control !== 'quantity' && title.control !== 'demurrage'"
                        >
                          <span *ngIf="!isScreenLarge" class="icon-response-charterer"> <img src="assets/icons/armador.svg" width="20" />: </span>
                          <!--<i class="pi pi-send" *ngIf="!isScreenLarge"> : </i>-->
                          {{offer.offerShipownerIndication[title.label]}}
                        </label>
                        <label *ngIf="title.control === 'dischargingPorts' || title.control === 'loadingPorts'">
                          <span *ngIf="!isScreenLarge" class="icon-response-charterer"> <img src="assets/icons/armador.svg" width="20" />: </span>
                          {{offer.offerShipownerIndication[title.label].join(',')}}
                        </label>
                        <label *ngIf="title.control === 'rangeDates'">
                          <span *ngIf="!isScreenLarge" class="icon-response-charterer"> <img src="assets/icons/armador.svg" width="20" />: </span>
                          {{offer.offerShipownerIndication[title.label.fieldFrom] | date: 'dd/MM/yy'}} -
                          {{offer.offerShipownerIndication[title.label.fieldTo] | date: 'dd/MM/yy'}}</label
                        >
                        <label *ngIf="title.control === 'flete' && offer.offerShipownerIndication[title.moreLabels.quantity] > 0">
                          <span *ngIf="!isScreenLarge" class="icon-response-charterer"> <img src="assets/icons/armador.svg" width="20" />: </span>
                          {{FreightTargetCurrency[offer.offerShipownerIndication[title.moreLabels.currency]]}}
                          {{offer.offerShipownerIndication[title.moreLabels.quantity] | number: '1.2-2'}}
                          {{FreightTargetType[offer.offerShipownerIndication[title.moreLabels.type]]}}</label
                        >
                        <label *ngIf="title.control === 'quantity'"></label>
                        <label *ngIf="title.control === 'demurrage' && offer.offerShipownerIndication[title.moreLabels.quantity] > 0">
                          <span *ngIf="!isScreenLarge" class="icon-response-charterer"> <img src="assets/icons/armador.svg" width="20" />: </span>
                          {{FreightTargetCurrency[offer.offerShipownerIndication[title.moreLabels.currency]]}}
                          {{offer.offerShipownerIndication[title.moreLabels.quantity] | number: '1.2-2'}}
                          {{DemurrageType[offer.offerShipownerIndication[title.moreLabels.type]]}}</label
                        >
                      </ng-template>
                      <!-- END ROWS IF READONLY -->
                    </td>
                  </ng-container>
                  <!--END ALL ROWS EXCEPT ADDRESCOM -->
                  <!-- ROW ADDRESSCOM -->
                  <ng-template #templateA>
                    <td *ngIf="col.colName==='STEP1_OFFER.TABLE.HEAD.HEAD_1' && isScreenLarge" class="styleTextTable">A</td>
                    <td *ngIf="col.colName==='STEP1_OFFER.TABLE.HEAD.HEAD_1' && !isScreenLarge" class="styleTextTable">
                      A - {{title.field | translate}}
                    </td>
                    <td *ngIf="col.colName==='STEP1_OFFER.TABLE.HEAD.HEAD_2' && isScreenLarge" class="styleTextTable">{{title.field | translate}}</td>
                    <td *ngIf="col.colName===''" class="styleTextTable">
                      <span *ngIf="!isScreenLarge" class="icon-response-charterer"> <img src="assets/icons/cargador.svg" width="20" />: </span>
                    </td>
                    <td *ngIf="col.colName==='RESPONSE_SHIP_OFFER.TABLE.HEAD.HEAD_1'" class="styleTextTable">
                      <ng-container *ngIf="!isReadOnlyResponse else isReadonlyTable">
                        <div class="d-flex justify-content-start">
                          <span class="spanMargin">
                            <p-inputNumber
                              class="inputAddressComm"
                              inputStyleClass="inputAddressCommResponse"
                              id="{{title.idTextarea}}"
                              [readonly]="true"
                              formControlName="{{title.control}}"
                              mode="decimal"
                              [minFractionDigits]="2"
                              [maxFractionDigits]="2"
                              locale="de-DE"
                            ></p-inputNumber>
                            <span class="span-percent"> %</span>
                          </span>
                        </div>
                      </ng-container>
                      <ng-template #isReadonlyTable>
                        <label>
                          <span *ngIf="!isScreenLarge" class="icon-response-charterer"> <img src="assets/icons/armador.svg" width="20" />: </span>
                          {{offer[title.label]}} %
                        </label>
                      </ng-template>
                    </td>
                  </ng-template>
                  <!-- END ROW ADDRESSCOM -->
                </ng-container>
              </tr>
            </ng-template>
          </p-table>
          <!-- ADDITIONAL COMMENTS -->
          <ng-container>
            <div class="container-fluid" *ngIf="offer">
              <label *ngIf="offer.comments"> {{'STEP1_OFFER.ADDITIONAL_COMMENTS'| translate}} : {{offer.comments}}</label>
            </div>
          </ng-container>
          <!-- END ADDITIONAL COMMENTS -->
        </div>
        <!-- END TABLE -->
        <!-- CHAT + ACCORDION -->
        <div class="p-3 col-12 col-sm-12 col-md-12 col-xl-4">
          <!-- CHAT -->
          <div class="col-12 overflowInX">
            <ng-container *ngIf="offerShipOwner">
              <p-tabView *ngIf="chatValues && (chatValues.length > 0); else loadingSpinner">
                <ng-container *ngFor="let group of chatValues">
                  <p-tabPanel header="{{group.windowName}}">
                    <app-chat-room
                      [offerShipOwnerId]="group.offerShipOwnerId"
                      [windowName]="group.windowName"
                      [currentParticipant]="group.currentParticipant"
                      [group]="group.group"
                      [messages]="group.historicMessages"
                      [unReadMessages]="group.unReadMessages"
                      (unReadMessagesUpdated)="group.unReadMessages = $event"
                    ></app-chat-room>
                  </p-tabPanel>
                </ng-container>
              </p-tabView>
              <ng-template #loadingSpinner>
                <div class="spinner-container">
                  <mat-spinner></mat-spinner>
                </div>
              </ng-template>
            </ng-container>
          </div>
          <!-- END CHAT -->
          <!-- ACCORDION -->
          <div class="col-12 mt-3">
            <p-accordion styleClass="accordionResponse">
              <p-accordionTab>
                <ng-template pTemplate="header"> {{'DOCUMENT.HEADER_SHIPOWNER' | translate}}</ng-template>
                <ng-template pTemplate="content">
                  <div class="d-flex justify-content-end align-items-end" *ngIf="!isReadOnlyResponse">
                    <button
                      pButton
                      pRipple
                      type="button"
                      icon="pi pi-user-plus"
                      class="p-button-rounded addButtonRounded"
                      [pTooltip]="'ACTION.ADD'| translate"
                      tooltipPosition="bottom"
                      tooltipStyleClass="tooltipCLH"
                      (click)="openContact()"
                      [disabled]="isEditting"
                    ></button>
                  </div>
                  <ul>
                    <li *ngFor="let contact of listContactsAccordion">
                      <div class="row d-flex flex-row align-items-center">
                        <div class="col">{{contact.email}}</div>
                        <div class="col d-flex justify-content-end align-items-end" *ngIf="!isReadOnlyResponse">
                          <button
                            type="button"
                            icon="pi pi-trash"
                            pButton
                            pRipple
                            class="p-button-rounded deleteButtonRounded p-ml-auto"
                            [pTooltip]="'ACTION.DELETE'| translate"
                            tooltipPosition="bottom"
                            tooltipStyleClass="tooltipCLH"
                            (click)="deleteContact(contact)"
                            [disabled]="isEditting"
                          ></button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </ng-template>
              </p-accordionTab>
              <p-accordionTab>
                <ng-template pTemplate="header"> {{'DOCUMENT.HEADER' | translate}}</ng-template>
                <ng-template pTemplate="content">
                  <app-documents
                    *ngIf="offerShipOwner && offerShipOwner.id"
                    [form]="form"
                    [entityId]="offerShipOwner.offerId"
                    [groupId]="offerShipOwner.id"
                    [placeholder]="isReadOnlyResponse ? ('DOCUMENT.HEADER' | translate) : ('DOCUMENT.SELECT' | translate)"
                    [readonly]="isReadOnlyResponse"
                  >
                  </app-documents>
                </ng-template>
              </p-accordionTab>
              <p-accordionTab>
                <ng-template pTemplate="header"> {{'NOTE.HEADER' | translate}}</ng-template>
                <ng-template pTemplate="content">
                  <overa-notes [entityId]="offerShipOwner.offerId" [groupId]="offerShipOwner.id"> </overa-notes>
                </ng-template>
              </p-accordionTab>
            </p-accordion>
          </div>
          <!-- END ACCORDION -->
        </div>
        <!-- END CHAT + ACCORDION -->
      </div>
    </ng-container>
    <!-- DEADLINE -->
    <ng-container>
      <div
        *ngIf="!isReadOnlyResponse || (isReadOnlyResponse && offerShipownerIndication?.expirationDate)"
        [ngClass]="!isReadOnlyResponse ? 'col-8 divDeadLine d-flex justify-content-center' : 'col-8 divDeadLine d-flex justify-content-center w-75 disabledToggle'"
      >
        <div>
          <strong>{{'RESPONSE_SHIP_OFFER.EXPIRATION_DATE' | translate}}</strong
          ><p-calendar
            #calendarExpiration
            formControlName="expirationDate"
            dateFormat="dd/mm/yy"
            styleClass="calendarBinding calendarBindingMargin"
            [showIcon]="true"
            [showTime]="true"
            [showSeconds]="false"
            [minDate]="today"
          ></p-calendar>
          <p-inputSwitch
            styleClass="switchCLH"
            *ngIf="!isReadOnlyResponse"
            formControlName="checkedExpirationDate"
            (onChange)="sendExpirationDate($event);($event.checked ? calendarExpiration.showOverlay() : null)"
          ></p-inputSwitch>
        </div>
      </div>
      <div class="col-8 d-flex d-flex justify-content-center">
        <div *ngIf="form.get('expirationDate').touched  && form.errors?.['expirationDate']" class="error-label labelError">
          {{'MESSAGES.VALIDATION.EXPIRATION_DATE' | translate}}
        </div>
      </div>
    </ng-container>
    <!-- END DEADLINE -->
    <!-- BUTTONS -->
    <ng-container *ngIf="!isReadOnlyResponse else readOnlyButtons">
      <div class="containerButtonStep1">
        <div class="div-btn-cancel">
          <div>
            <p-button
              #pButtonCan
              label="{{'ACTION.BACK' | translate}}"
              [routerLink]="['/offerReceived/', offerShipOwner.id ]"
              routerLinkActive="active"
              styleClass="p-button cancelar"
              *ngIf="offer"
            >
            </p-button>
          </div>
        </div>
        <div class="div-btn-next">
          <div>
            <p-button
              #pButtonSig
              label="{{'ACTION.SEND' | translate}}"
              (onClick)="send()"
              styleClass="p-button actualizar"
              [disabled]="!form.valid || isLoading"
            ></p-button>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #readOnlyButtons>
      <div class="containerButtonStep1">
        <div class="div-btn-next">
          <div>
            <button pButton type="button" icon="pi pi-home" iconPos="left" class="p-button home custom-button p-ripple" (click)="send()"></button>
          </div>
        </div>
      </div>
    </ng-template>
    <!--END BUTTONS -->
  </ng-container>
  <!-- <ng-template #showInfoExpiration>
    <div class="d-flex justify-content-center">
      <h6>{{'BINDING_OFFER.DEADLINE_EXPIRED' | translate}}</h6>
    </div>
  </ng-template> -->
</div>
