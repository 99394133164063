import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { map, Observable, ReplaySubject } from "rxjs";
import { ConfigsService } from "@overa/shared";
import { Charger } from "../models/charger/charger.model";
import { Offer } from "../models/offers/offer.model";
import { CLHGlobals } from "../utils/globals";
import { Shipowner } from "../models/shipowner/shipowner.model";
import { CLHBaseService } from "./clh-base.service";

@Injectable({ providedIn: "root" })
export class ChooseUserService extends CLHBaseService {
  private $chooseUserSubject: ReplaySubject<any> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public chooseUserObservable: Observable<boolean> =
    this.$chooseUserSubject.asObservable();
  globals = inject(CLHGlobals);

  setCharger(charger: Charger) {
    this.globals.currentCharger = charger;
    this.globals.currentShipOwner = undefined;
    localStorage.setItem("currentEntity", JSON.stringify(charger));
    this.$chooseUserSubject.next(charger);
  }
  setShipOwner(shipowner: Shipowner) {
    this.globals.currentShipOwner = shipowner;
    this.globals.currentCharger = undefined;
    localStorage.setItem("currentEntity", JSON.stringify(shipowner));
    this.$chooseUserSubject.next(shipowner);
  }
  setNull() {
    this.globals.currentShipOwner = undefined;
    this.globals.currentCharger = undefined;
    localStorage.removeItem("currentEntity");
    this.$chooseUserSubject.next(null);
  }
  getUser() {
    return this.$chooseUserSubject;
  }
}
