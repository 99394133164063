import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";
import { StepsModule } from "primeng/steps";
import { filter, Observable, Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { DropdownModule } from "primeng/dropdown";
import { InputTextareaModule } from "primeng/inputtextarea";
import { ButtonModule } from "primeng/button";
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
  ValidatorFn,
} from "@angular/forms";
import { OfferService } from "src/app/services/offer.service";
import { Offer } from "src/app/models/offers/offer.model";
import { CLHGlobals } from "src/app/utils/globals";
import { QuantityType } from "src/app/models/enum/quantity-type.model";
import { FreightTargetType } from "src/app/models/enum/freight-target-type.model";
import { FreightTargetCurrency } from "src/app/models/enum/freight-target-currency.model";
import { OveraMessagesService } from "@overa/components/info-dialog";
import { ClhValidators } from "src/app/utils/validators";
import { NgxSpinnerService } from "ngx-spinner";
import { StateOfferShipOwner } from "src/app/models/enum/state-offer-shipowner.model";
import { AccountService } from "@overa/security";
import { PermissionsService } from "../../../../services/permissions.service";
import { NgxTranslateService } from "@overa/shared";

@Component({
  selector: "app-step1-offer",
  templateUrl: "./step1-offer.component.html",
  styleUrls: ["./step1-offer.component.scss"],
  providers: [MessageService, ConfirmationService],
})
export class Step1OfferComponent implements OnInit, OnDestroy {
  form!: FormGroup;
  readonly: boolean = false;
  readonlyStep1: boolean = false;
  subscription!: Subscription;
  submitted: boolean = false;
  offer!: Offer;
  originalOffer!: Offer;
  DEFAULT_QUANTITYTYPE = 0;
  DEFAULT_TARGETTYPE = 0;
  DEFAULT_TARGETCURRENCY = 0;
  isNew: boolean = false;
  today: Date = new Date();
  isLoading: boolean = false;
  statusNone = [StateOfferShipOwner.NONE];
  readonly arrValidator = this.validators.portsValidator;
  hasUnsavedChanges: boolean = false;
  noConfirmationServiceToExit: boolean = false;
  isClh: boolean = false;

  @Input() isValidateForm: boolean = false;
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public messageService: OveraMessagesService,
    private translate: NgxTranslateService,
    private offerService: OfferService,
    private globals: CLHGlobals,
    private validators: ClhValidators,
    private spinner: NgxSpinnerService,
    private confirmationService: ConfirmationService,
    public accountService: AccountService,
    private permissionsService: PermissionsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.isNew = false;
    if (
      !this.globals.currentCharger &&
      !this.globals.currentShipOwner &&
      this.accountService.currentUser.roles
        .map((x) => x.name)
        .includes("CLH Admin")
    ) {
      this.isClh = true;
    }
    if (this.globals.currentCharger || this.isClh) {
      this.route.params.subscribe((paramsId) => {
        this.readonlyStep1 = false;
        if (paramsId?.["id"]) {
          if (paramsId["id"] == "new") {
            this.offer = new Offer();
            console.log(paramsId);
            this.originalOffer = { ...this.offer };
            //TODO si clh está como cargador??
            this.offer.charger = this.globals.currentCharger;
            this.isNew = true;
            this.setForm();
          } else {
            this.offerService.getById(paramsId["id"]).subscribe((x) => {
              this.offer = x;
              this.originalOffer = { ...this.offer };
              if (this.offer.offerShipOwners?.length) {
                this.offer.offerShipOwners.forEach((itemShipOwner) => {
                  if (!this.statusNone.includes(itemShipOwner.state)) {
                    this.readonlyStep1 = true;
                  }
                });
              }
              this.setForm();
            });
          }
          if (!this.permissionsService.hasPermissionToEdit(this.route)) {
            this.readonlyStep1 = true;
          }
        }
      });
    } else {
      this.messageService.showToast(
        "ERROR",
        "MESSAGES.ERROR.UNAUTHORIZED_USER",
        "error",
        ""
      );
      this.router.navigate(["home"]);
    }
  }
  nextPage() {
    if (!this.readonlyStep1) {
      if (this.form.valid && !this.isLoading) {
        this.isLoading = true;
        this.spinner.show();
        var formValue = { ...this.form.value };
        console.log("formValue", formValue);

        this.offer = { ...this.offer, ...formValue };
        if (formValue.rangeDates != undefined) {
          formValue.rangeDates[0].setHours(0, 0, 0);
          formValue.rangeDates[1].setHours(0, 0, 0);

          this.offer.layCanFrom = formValue.rangeDates[0]
            ? new Date(
                Date.UTC(
                  formValue.rangeDates[0].getFullYear(),
                  formValue.rangeDates[0].getMonth(),
                  formValue.rangeDates[0].getDate()
                )
              )
            : null;
          this.offer.layCanTo = formValue.rangeDates[1]
            ? new Date(
                Date.UTC(
                  formValue.rangeDates[1].getFullYear(),
                  formValue.rangeDates[1].getMonth(),
                  formValue.rangeDates[1].getDate()
                )
              )
            : null;
          console.log("offerDateFrom", this.offer.layCanFrom);
          console.log("offerDateTo", this.offer.layCanTo);
        }

        //console.log('OfferAntesGuardar',this.offer);
        if (this.offer.charger && !this.offer.chargerId) {
          this.offer.chargerId = this.offer.charger.id;
        }
        this.offer.charger = undefined;
        var subscription = this.offerService.add(this.offer);
        if (this.offer.id) {
          subscription = this.offerService.edit(this.offer);
        }
        console.log("OfferAntesGuardar", this.offer);

        subscription.subscribe((offerResult) => {
          console.log(offerResult);
          console.log(this.isLoading);
          // this.messageService.add({ severity: 'info', summary: 'Ok', detail: 'Oferta guardada correctamente' });
          this.offerService
            .getById(offerResult.id || "")
            .subscribe((offerres) => {
              // offerres.offerShipOwners = this.globals.currentCharger
              this.noConfirmationServiceToExit = true;
              this.spinner.hide();
              this.router.navigateByUrl(
                "firmOffer/shipownersSelector/" + offerres.id
              );
              this.isLoading = false;
            });
        });
      }
    } else {
      this.noConfirmationServiceToExit = true;
      this.router.navigateByUrl(
        "firmOffer/shipownersSelector/" + this.offer.id
      );
    }
  }

  ngOnDestroy(): void {}

  // sendForm(form: FormGroup) {
  //   this.isValidateForm = form.valid;
  //   var formValue = { ...form.value };

  //   console.log(formValue);

  //   this.offer = { ...this.offer, ...formValue };
  //   if (formValue.rangeDates != undefined) {
  //     this.offer.layCanFrom = formValue.rangeDates[0] ? formValue.rangeDates[0] : null;
  //     this.offer.layCanTo = formValue.rangeDates[1] ? formValue.rangeDates[1] : null;
  //   }

  //   console.log(this.offer);

  // }

  setForm() {
    this.form = new FormGroup({
      refCharger: new FormControl(this.offer.charger?.refCharger, [
        Validators.required,
      ]),
      chargerName: new FormControl(this.offer.charger?.name, [
        Validators.required,
      ]),
      code: new FormControl(this.offer.code, []),
      // customerName: new FormControl(this.offer.customerName, []),
      quantityType: new FormControl(this.offer.quantityType, [
        Validators.required,
      ]),
      quantity: new FormControl(this.offer.quantity, [Validators.required]),
      commodity: new FormControl(this.offer.commodity, [Validators.required]),
      stowage: new FormControl(this.offer.stowage, []),
      loadingPorts: new FormControl(this.offer.loadingPorts, [
        Validators.required,
        this.arrValidator,
      ]),
      dischargingPorts: new FormControl(this.offer.dischargingPorts, [
        Validators.required,
        this.arrValidator,
      ]),
      cpForm: new FormControl(this.offer.cpForm, [Validators.required]),
      chargeringTerm: new FormControl(this.offer.chargeringTerm, [
        Validators.required,
      ]),
      layTimeTerm: new FormControl(this.offer.layTimeTerm, [
        Validators.required,
      ]),
      freightTargetQuantity: new FormControl(
        this.offer.freightTargetQuantity,
        []
      ),
      restrictionsRegarding: new FormControl(
        this.offer.restrictionsRegarding,
        []
      ),
      aditionalRequirements: new FormControl(
        this.offer.aditionalRequirements,
        []
      ),
      // comments: new FormControl(this.offer.comments, []),
      freightTargetCurrency: new FormControl(
        this.offer.freightTargetCurrency,
        []
      ),
      freightTargetType: new FormControl(this.offer.freightTargetType, []),
      rangeDates: new FormControl(
        [this.offer.layCanFrom, this.offer.layCanTo],
        [Validators.required, this.validators.rangeValidator]
      ),
    });
    this.form.get("chargerName")?.enable();
    this.form.get("refCharger")?.enable();
    if (this.isNew) {
      this.form
        .get("quantityType")
        ?.setValue(QuantityType[this.DEFAULT_QUANTITYTYPE]);
      this.form
        .get("freightTargetCurrency")
        ?.setValue(FreightTargetCurrency[this.DEFAULT_TARGETCURRENCY]);
      this.form
        .get("freightTargetType")
        ?.setValue(FreightTargetType[this.DEFAULT_TARGETTYPE]);
      this.form.get("rangeDates").setValue([this.today, this.today]);
    } else {
      this.form
        .get("quantityType")
        ?.setValue(QuantityType[this.offer.quantityType]);
      this.form
        .get("freightTargetCurrency")
        ?.setValue(FreightTargetCurrency[this.offer.freightTargetCurrency]);
      this.form
        .get("freightTargetType")
        ?.setValue(FreightTargetType[this.offer.freightTargetType]);
    }
    if (this.offer.layCanFrom != null && this.offer.layCanTo != null) {
      this.form
        .get("rangeDates")
        ?.setValue(
          [new Date(this.offer.layCanFrom), new Date(this.offer.layCanTo)],
          { emitEvent: false }
        );
    }
    this.form.valueChanges.subscribe((value) => {
      console.log(value);
    });
  }

  cancelForm() {
    this.router.navigate(["home"]);
  }
  checkToSaveToExit() {
    this.hasUnsavedChanges = false;
    if (
      !this.noConfirmationServiceToExit &&
      !this.readonlyStep1 &&
      !this.isClh
    ) {
      Object.keys(this.form.controls).forEach((ctrl) => {
        if (this.form.get(ctrl).value) {
          if (ctrl === "rangeDates") {
            var formValue = { ...this.form.value };
            let dateFrom = new Date(this.offer.layCanFrom);
            let dateTo = new Date(this.offer.layCanTo);
            const isDateOutOfRangeFrom =
              formValue[0] > dateFrom ||
              formValue[0] < dateFrom ||
              formValue[0] > this.today ||
              formValue[0] < this.today;
            const isDateOutOfRangeTo =
              formValue[1] > dateTo ||
              formValue[1] < dateTo ||
              formValue[1] > this.today ||
              formValue[1] < this.today;
            if (isDateOutOfRangeFrom || isDateOutOfRangeTo) {
              this.hasUnsavedChanges = true;
              console.log("cambio", ctrl);
            }
          } else if (ctrl === "quantityType") {
            console.log(
              this.form.get(ctrl).value,
              QuantityType[this.DEFAULT_QUANTITYTYPE]
            );
            if (
              this.form.get(ctrl).value !=
              QuantityType[this.DEFAULT_QUANTITYTYPE]
            ) {
              this.hasUnsavedChanges = true;
              console.log("cambio", ctrl);
            }
          } else if (ctrl === "freightTargetType") {
            console.log(
              this.form.get(ctrl).value,
              FreightTargetType[this.DEFAULT_TARGETTYPE]
            );
            if (
              this.form.get(ctrl).value !=
              FreightTargetType[this.DEFAULT_TARGETTYPE]
            ) {
              this.hasUnsavedChanges = true;
              console.log("cambio", ctrl);
            }
          } else if (ctrl === "freightTargetCurrency") {
            console.log(
              this.form.get(ctrl).value,
              FreightTargetCurrency[this.DEFAULT_TARGETCURRENCY]
            );
            if (
              this.form.get(ctrl).value !=
              FreightTargetCurrency[this.DEFAULT_TARGETCURRENCY]
            ) {
              this.hasUnsavedChanges = true;
              console.log("cambio", ctrl);
            }
          } else if (ctrl === "loadingPorts" || ctrl === "dischargingPorts") {
            console.log(
              ctrl,
              this.form.get(ctrl).value,
              this.originalOffer[ctrl]
            );
            if (
              JSON.stringify(this.originalOffer[ctrl]) !=
              JSON.stringify(this.form.get(ctrl).value)
            ) {
              this.hasUnsavedChanges = true;
              console.log("cambio", ctrl);
            }
          } else {
            if (ctrl != "chargerName" && ctrl != "refCharger") {
              if (this.form.get(ctrl).value != this.originalOffer[ctrl]) {
                this.hasUnsavedChanges = true;
                console.log("cambio", ctrl);
              }
            }
          }
        }
      });
    } else {
      this.hasUnsavedChanges = false;
    }
  }
  checkCanDeactive(): boolean | Observable<boolean> {
    this.checkToSaveToExit();

    if (this.hasUnsavedChanges) {
      let messageAccept = this.translate.get("ACTION.YES");
      let trmessage = this.translate.get("COMMONS.ASK_SAVE_TO_EXIT");

      return new Observable((observer) => {
        this.confirmationService.confirm({
          message: trmessage,
          header: "",
          acceptLabel: messageAccept,
          icon: "pi pi-exclamation-triangle",
          accept: () => {
            observer.next(true);
            observer.complete();
          },
          reject: () => {
            observer.next(false);
            observer.complete();
          },
        });
      });
    } else {
      return true;
    }
  }
}
