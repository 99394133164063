import { DemurrageType } from '../models/enum/demurrage-type.model';
import { FreightTargetCurrency } from '../models/enum/freight-target-currency.model';
import { FreightTargetType } from '../models/enum/freight-target-type.model';
import { QuantityType } from '../models/enum/quantity-type.model';

export class Template {
    value: string;
    properties: string[];
}

export function mapTemplate(template: string, entity: any, excludedKeys = [], excludedValues = []) {
    var matches = template.match(/([${]+((\w)|((\w)+[.]+(\w)))+[}])/g);
    var returnedText = template;
    var properties = [];
    if (matches && matches.length > 0) {
        for (let matchAux of matches) {
            var reg = /(\W|^)[$](\W|$)/g;
            var reg2 = /(\b)[}]/g;
            var match = matchAux.replace('${', '').replace('}', '');
            properties.push(match);
            if (!excludedKeys.includes(match)) {
                var value = getPropByString(entity, match);
                if (value) {
                    if (match === 'offer.quantityType' || match === 'quantityType' || match === 'originalOffer.quantityType') {
                        value = QuantityType[value];
                        returnedText = returnedText.replace(matchAux, value);
                    } else if (match === 'offer.freightTargetType' || match === 'freightTargetType' || match === 'originalOffer.freightTargetType') {
                        value = FreightTargetType[value];
                        returnedText = returnedText.replace(matchAux, value);
                    } else if (
                        match === 'offer.freightTargetCurrency' ||
                        match === 'freightTargetCurrency' ||
                        match === 'originalOffer.freightTargetCurrency'
                    ) {
                        value = FreightTargetCurrency[value];
                        returnedText = returnedText.replace(matchAux, value);
                    } else if (match === 'offer.layCanFrom' || match === 'layCanFrom' || match === 'originalOffer.layCanFrom') {
                        var date = new Date(value);
                        value = formatDate(date);
                        returnedText = returnedText.replace(matchAux, value);
                    } else if (match === 'offer.layCanTo' || match === 'layCanTo' || match === 'originalOffer.layCanTo') {
                        var date = new Date(value);
                        value = formatDate(date);
                        returnedText = returnedText.replace(matchAux, value);
                    } else if (match === 'dateRecap') {
                        var date = new Date(value);
                        var utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes());
                        value = formatDateWithHoursAndMonthWithLetter(utcDate);
                        returnedText = returnedText.replace(matchAux, value);
                    }
                    else if (match === 'offer.demurrageType' || match === 'demurrageType' || match === 'originalOffer.demurrageType') {
                        value = DemurrageType[value];
                        returnedText = returnedText.replace(matchAux, value);
                    } else if (match === 'offer.demurrageCurrency' || match === 'demurrageCurrency' || match === 'originalOffer.demurrageCurrency') {
                        value = FreightTargetCurrency[value];
                        returnedText = returnedText.replace(matchAux, value);
                    } if (match === 'offer.restrictionsRegarding' || match === 'restrictionsRegarding' || match === 'originalOffer.restrictionsRegarding' || match === 'offer.aditionalRequirements' || match === 'aditionalRequirements' || match === 'originalOffer.aditionalRequirements') {
                        value = value.replace(/\n/g, '<br>');
                        returnedText = returnedText.replace(matchAux, value);
                    }
                    else {
                        if (!Array.isArray(value)) {
                            returnedText = returnedText.replace(matchAux, value);
                        } else {
                            if (value.length && typeof value[0] === 'string') {
                                if (matchAux === '${chargerClausesText}' || matchAux === '${shipOwnerClausesText}') {
                                    returnedText = returnedText.replace(matchAux, value.join('<br>'));
                                } else {
                                    returnedText = returnedText.replace(matchAux, value.join(', '));
                                }
                            } else {
                                returnedText = returnedText.replace(matchAux, '');
                            }
                        }
                    }
                } else {
                    if (match === 'offer.quantityType' || match === 'quantityType' || match === 'originalOffer.quantityType') {
                        value = QuantityType[value];
                        returnedText = returnedText.replace(matchAux, value);
                    } else if (match === 'offer.freightTargetType' || match === 'freightTargetType' || match === 'originalOffer.sfreightTargetType') {
                        value = FreightTargetType[value];
                        returnedText = returnedText.replace(matchAux, value);
                    } else if (
                        match === 'offer.freightTargetCurrency' ||
                        match === 'freightTargetCurrency' ||
                        match === 'originalOffer.freightTargetCurrency'
                    ) {
                        value = FreightTargetCurrency[value];
                        returnedText = returnedText.replace(matchAux, value);
                    } else if (match === 'offer.demurrageType' || match === 'demurrageType' || match === 'originalOffer.demurrageType') {
                        value = DemurrageType[value];
                        returnedText = returnedText.replace(matchAux, value);
                    } else if (match === 'offer.demurrageCurrency' || match === 'demurrageCurrency' || match === 'originalOffer.demurrageCurrency') {
                        value = FreightTargetCurrency[value];
                        returnedText = returnedText.replace(matchAux, value);
                    } else {
                        returnedText = returnedText.replace(matchAux, '');
                    }
                }
            } else if (!excludedValues.includes(match)) {
                var value = getPropByString(entity, match);
                if (match === 'comment' && !value) {
                    returnedText = returnedText.replace(matchAux, '');
                }
            }
        }
    }
    return returnedText;
}

export function getPropByString(obj, propString) {
    if (!propString) return obj;

    var prop,
        props = propString.split('.');

    for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
        prop = props[i];

        var candidate = obj[prop];
        if (candidate !== undefined) {
            obj = candidate;
        } else {
            break;
        }
    }
    return obj[props[i]];
}
function formatDate(date: Date) {
    return (
        [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join('-') + ' '
    );
}
function formatDateWithHours(date: Date) {
    return (
        [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join('-') + ' '
        +
        [
            padTo2Digits(date.getHours()),
            padTo2Digits(date.getMinutes()),
        ].join(':')
    );
}
function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
}
function formatDateWithHoursAndMonthWithLetter(date) {
    return (
        [padTo2Digits(date.getDate()), new Intl.DateTimeFormat('en', { month: 'short' }).format(date), date.getFullYear()].join('/') + ' ' +
        [
            padTo2Digits(date.getHours()),
            padTo2Digits(date.getMinutes()),
        ].join(':')
    );
}
